import { useEffect, useMemo } from 'react';
import { OptionGroupModel } from 'models/option-group';
import { DropdownOptionModel, flatDropdownOptions, } from 'helpers/dropdown-options';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import optionGroupApi from 'api/option-group';
import { isValidFile, mapFileFromUrl } from 'helpers/file';
import mapOptions from './map-options';
import { validationRules } from './validations';

export { mapOptions, validationRules };

interface OptionGroupFormModel {
  name: string;
  nameFr: string;
  description: string;
  descriptionFr: string;
  image: File[];
  option: DropdownOptionModel[];
  defaultOption: DropdownOptionModel;
  hideShowModel: boolean;
}

const defaultOptionGroup: OptionGroupFormModel = {
  name: '',
  nameFr: null,
  description: '',
  descriptionFr: null,
  image: [],
  option: [],
  defaultOption: undefined,
  hideShowModel: false,
};

const getPayload = (optionGroup: OptionGroupFormModel) => {
  const { option, image, defaultOption, ...rest } = optionGroup;
  return {
    ...rest,
    option: flatDropdownOptions(option),
    defaultOption: defaultOption?.value || undefined,
    image: image && isValidFile(image[0]) ? image : undefined,
  };
};

const getFormData = (optionGroup: OptionGroupModel) => {
  if (optionGroup) {
    const { option, image, defaultOption, ...rest } = optionGroup;

    return {
      ...rest,
      option: mapOptions(option as any),
      defaultOption: defaultOption ? { value: defaultOption.url, label: defaultOption.name } : undefined,
      image: mapFileFromUrl(image),
    };
  }

  return defaultOptionGroup;
};

export default function useOptionGroupFormState(optionGroupUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: optionGroupApi,
    eventChannel: EventChannelList.OPTION_GROUPS_LIST_CHANGED,
    param: optionGroupUrl,
    getPayload,
    getFormData,
    validationRules
  }), [optionGroupUrl]);

  const {
    state: {
      formData: optionGroup,
      errors,
      saving,
      errorFetchingEntity: errorFetchingOptionGroup,
      fetchingEntity: fetchingOptionGroup,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<OptionGroupModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingOptionGroup) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingOptionGroup,
      });
    }
  }, [errorFetchingOptionGroup]);

  return {
    state: {
      optionGroup,
      fetchingFormData: fetchingOptionGroup,
      savingOptionGroup: saving,
      errors,
    },
    actions: {
      setFormValue,
      saveOptionGroup: saveEntity,
      setErrors,
    },
  };
}
