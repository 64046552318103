import { resolveApiErrorMessage } from 'api/base';
import { AccountModel } from 'models/account';
import { FlowPlannerModel } from 'models/flow-planner';
import productApi from 'api/product';
import accountApi from 'api/account';
import projectsApi from 'api/projects';
import quoteApi from 'api/quote';
import orderProductApi from 'api/order-product';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { getLoggedUser } from 'helpers/get-logged-user';
import { BuilderFormModel } from '../state';
import getPayload, { getProductPayload } from '../../../forms/AddProduct3D/state/actions/get-payload';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/accounts/`;

const getPayloadProject = (flowPlanner, repOwner) => ({
  account: flowPlanner.account,
  jobTitle: flowPlanner.projectName,
  client: flowPlanner.client,
  repOwner,
  lead_source: '',
});

const getPayloadQuotes = (project, flowplannerId) => ({
  project,
  flowplannerId,
  comments: 'Initial Quote'
});

async function saveProduct(orderProduct, quote, productPrice: any[], discount = 0) {
  const price = productPrice.reduce((result, item) => ({ ...result, [item.model]: item.price }), {});
  const promises = orderProduct.map((product) => {
    const payload = getPayload(product, quote, productPrice);
    return productApi.getByModel(payload.model)
      .then((response) => {
        const productPayload = getProductPayload(
          { ...response.data, price: price[response?.data?.model] },
          discount * 100,
          payload
        );
        return orderProductApi.create(productPayload);
      });
  });
  return Promise.all(promises);
}

export async function createQuoteFromDesign(
  projectId: number,
  flowPlanner: FlowPlannerModel,
  products: BuilderFormModel[],
  productPrice,
  account: AccountModel,
) {
  let quoteId = null;
  const getQuoterPromiseFunction = ({ data }) => {
    return new Promise((resolve) => {
      quoteId = data.id;
      resolve(true);
    });
  };
  const quoterIdPromiseFunction = () => {
    return new Promise((resolve) => {
      resolve(quoteId);
    });
  };

  return quoteApi.create(getPayloadQuotes(projectId, flowPlanner.id))
    .then(getQuoterPromiseFunction)
    .then(() => saveProduct(products, quoteId, productPrice, parseFloat(account?.groupDetail?.discount)))
    .then(quoterIdPromiseFunction);
}

export default function addToCart(
  productPrice,
  flowPlanner: FlowPlannerModel,
  products,
  setLoading: ((loading: boolean) => void),
  goBack,
) {
  return async () => {
    setLoading(true);
    const loggedUser = getLoggedUser();

    const onSuccess = (quoteId) => {
      goBack();
      notification({
        type: NotificationType.SUCCESS,
        message: 'Cart was updated successfully',
      });
      const data = { ...flowPlanner, quoteId, fromAddToCart: true };
      notifyEventChannel(EventChannelList.BUILDER_REQ_SAVE_JSON, { products, flowPlanner: data });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      setLoading(false);
    };
    let account: AccountModel = null;
    const getAccountPromiseFunction = ({ data }) => {
      return new Promise((resolve) => {
        account = data;
        resolve(true);
      });
    };

    return accountApi.get(`${endpoint}${flowPlanner.account}/`)
      .then(getAccountPromiseFunction)
      .then(() => projectsApi.create(getPayloadProject(
        flowPlanner,
        loggedUser.isStaff ? loggedUser.id : account?.salesRepDetail?.cognitoId
      )))
      .then(({ data }) => createQuoteFromDesign(data.id, flowPlanner, products, productPrice, account))
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
