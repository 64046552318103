/* eslint-disable */
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Items from "./items";
import Item from "./items/item";

export default class SceneManager {
  scene = null;
  camera = null;

  model = null;

  renderer = null;
  controls = null;

  uuidList = [];

  itemSelectedCallbacks = [];

  overlapDetectedCallbacks = [];
  /**
   *
   * @param {HTMLElement} container
   */
  constructor(container) {
    this.scene = new THREE.Scene();
    this.scene.removeItem = this.removeItem;
    this.scene.background = new THREE.Color(0xe0e0e0);

    this.camera = new THREE.PerspectiveCamera(
      45,
      container.clientWidth / container.clientHeight,
      1,
      20
    );
    this.camera.position.set(-2, 2, 2);
    this.scene.add(this.camera);

    const light = new THREE.HemisphereLight(0xffffff, 0x888888, 1.1);
    light.position.set(0, 50, 0);
    this.scene.add(light);

    const dirLight = new THREE.DirectionalLight(0xffffff, 0);
    dirLight.color.setHSL(1, 1, 0.1);
    dirLight.castShadow = true;

    dirLight.shadowMapWidth = 1024;
    dirLight.shadowMapHeight = 1024;

    dirLight.shadowCameraFar = 50;
    dirLight.shadowBias = -0.0001;
    dirLight.shadowDarkness = 0.2;
    dirLight.visible = true;
    dirLight.shadowCameraVisible = false;

    this.scene.add(dirLight);
    this.scene.add(dirLight.target);

    // const box = new THREE.Mesh(new THREE.BoxGeometry(0.3, 0.6, 0.9), new THREE.MeshStandardMaterial());
    // this.scene.add(box);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true, // required to support .toDataURL()
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(this.renderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.controls.maxPolarAngle = Math.PI / 2 + 0.3;
    // this.controls.minDistance = 3;
    this.controls.maxDistance = 10;

    this.GLTFLoader = new GLTFLoader();

    window.addEventListener("resize", () => {
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      this.camera.aspect = container.clientWidth / container.clientHeight;
      this.camera.updateProjectionMatrix();
    });

    this.renderer.setAnimationLoop(() => {
      this.renderer.render(this.scene, this.camera);
      this.controls.update();
    });
  }

  exportItems = () => {
    const items = [];
    const res = [];
    this.scene.traverse((child) => {
      if (child instanceof Item) {
        items.push(child);
      }
    });

    if (items.length) {
      for (const [_i, object] of items.entries()) {
        if (object.visible === false) continue;
        res.push({
          item_name: object.metadata.itemName,
          item_type: object.metadata.itemType,
          model_url: object.metadata.modelUrl || object.metadata.threeModel,
          xpos: object.position.x,
          ypos: object.position.y,
          zpos: object.position.z,
          rotation: object.rotation.y,
          metadata: object.metadata,
          options: object.getOptions(),
          initialData: {
            position: {
              x: object.position.x,
              y: object.position.y,
              z: object.position.z,
            },
            rotation: object.rotation.y,
            options: object.getOptions(),
          }
        });
      }
    }

    return res;
  };

  findModelByUUID(uuid) {
    let model = null;
    this.scene.traverse((child) => {
      if (child instanceof THREE.Mesh && child.uuid === uuid) {
        model = child;
      }
    });
    return model;
  }

  /**
   * Removes an item.
   * @param item The item to be removed.
   * @param dontRemove If not set, also remove the item from the items list.
   */
  removeItem = (item, _dontRemove) => {
    // dontRemove = dontRemove || false;
    // use this for item meshes
    // this.itemRemovedCallbacks.fire(item);
    item.removed();
    this.scene.remove(item);
  };
  /**
   * Creates an item and adds it to the scene.
   * @param itemType The type of the item given by an enumerator.
   * @param fileName The name of the file to load.
   * @param metadata TODO
   * @param position The initial position.
   * @param rotation The initial rotation around the y axis.
   * @param options The initial options.
   */
  async addItem(itemType, fileName, metadata, position, rotation, options) {
    return new Promise((resolve) => {
      itemType = itemType || 1;
      const scope = this;

      this.GLTFLoader.load(
        fileName,
        (gltf) => {
          const meshList = [];

          const morphHeight = {};
          const morphWidth = {};
          const morphDepth = {};

          gltf.scene.traverse((node) => {
            if (node.isMesh) {
              if (!node.name.includes("morph-")) {
                meshList.push(node);
              }

              if (node.name.includes("morph-height")) {
                const name = node.name.replace("-morph-height", "");
                const tmp = [];
                node.geometry.attributes.uv.array.forEach((v) => tmp.push(v));
                morphHeight[name] = tmp;
              } else if (node.name.includes("morph-width")) {
                const name = node.name.replace("-morph-width", "");
                const tmp = [];
                node.geometry.attributes.uv.array.forEach((v) => tmp.push(v));
                morphWidth[name] = tmp;
              } else if (node.name.includes("morph-depth")) {
                const name = node.name.replace("-morph-depth", "");
                const tmp = [];
                node.geometry.attributes.uv.array.forEach((v) => tmp.push(v));
                morphDepth[name] = tmp;
              }
            }
          });
          const morphUVs = [morphHeight, morphWidth, morphDepth];
          const item = new (Items.Factory.getClass(itemType))(
            { scene: scope.scene },
            { ...metadata, morphUVs },
            meshList,
            position,
            rotation,
            options
          );
          item.name = metadata.name;
          item.initObject(meshList, position, rotation, options);

          item.restriction = options.restriction;
          item.morphAlign = options.restriction.morphAlign;

          scope.scene.add(item);
          console.log(item);

          resolve(item);
        },
        () => {},
        () => {
          resolve(null);
        }
      );
    });
  }

  setMinDistance = (dist) => {
    console.log(dist);
  };

  /**
   * Set Camera Position Preset
   * @param {Object} preset {x: number, y: number: z: number}
   */
  setCameraPositionPreset(preset) {
    const current = this.camera.position;
    const dist = current.distanceTo(new THREE.Vector3());
    const newPos = new THREE.Vector3(
      preset.x,
      preset.y,
      preset.z
    ).multiplyScalar(dist);
    this.camera.position.copy(newPos);
  }

  getWholeBounding(items = []) {
    let minX = +Infinity;
    let minZ = +Infinity;
    let maxX = -Infinity;
    let maxZ = -Infinity;
    for (var item of items) {
      if (!item) continue;
      let halfSize = new THREE.Vector3().copy(item.halfSize);
      const transform = new THREE.Matrix4();
      transform.makeRotationY(item.rotation.y);
      halfSize.applyMatrix4(transform);
      halfSize.x = Math.abs(halfSize.x);
      halfSize.y = Math.abs(halfSize.y);
      halfSize.z = Math.abs(halfSize.z);

      if (halfSize.x + item.position.x > maxX)
        maxX = halfSize.x + item.position.x;
      if (halfSize.z + item.position.z > maxZ)
        maxZ = halfSize.z + item.position.z;

      if (-halfSize.x + item.position.x < minX)
        minX = -halfSize.x + item.position.x;
      if (-halfSize.z + item.position.z < minZ)
        minZ = -halfSize.z + item.position.z;
    }
    minX === +Infinity && (minX = 0);
    minZ === +Infinity && (minZ = 0);
    maxX === -Infinity && (maxX = 0);
    maxZ === -Infinity && (maxZ = 0);
    return { minX, minZ, maxX, maxZ };
  }

  /**
   *
   */
  updateModelsByRestriction() {
    const items = [];
    if (this.uuidList.length) {
      this.uuidList.forEach((uuid) => {
        const model = this.findModelByUUID(uuid);
        if (model) items.push(model);
      });
    } else {
      this.scene.traverse(
        (model) => model instanceof Item && items.push(model)
      );
    }

    for (var item of items) {
      if (!item.restriction) continue;
      const {
        validation,
        parentUUID,
        marginLeft,
        marginRight,
        marginBack,
        marginFront,
        hidden,
      } = item.restriction;

      const parentModel = this.findModelByUUID(parentUUID);
      if (parentModel && parentModel.visible) {
        const { minX, minZ, maxX, maxZ } = this.getWholeBounding([parentModel]);
        // check margin
        let halfSize = new THREE.Vector3().copy(item.halfSize);
        const transform = new THREE.Matrix4();
        transform.makeRotationY(item.rotation.y);
        halfSize.applyMatrix4(transform);
        halfSize.x = Math.abs(halfSize.x);
        halfSize.y = Math.abs(halfSize.y);
        halfSize.z = Math.abs(halfSize.z);

        const pos = new THREE.Vector3().copy(item.position);
        if (marginLeft) {
          let x = minX + marginLeft + halfSize.x;
          pos.x = x;
        }
        if (marginRight) {
          let x = maxX - marginRight - halfSize.x;
          pos.x = x;
        }
        if (marginBack) {
          let z = minZ + marginBack + halfSize.z;
          pos.z = z;
        }
        if (marginFront) {
          let z = maxZ - marginFront - halfSize.z;
          pos.z = z;
        }
        item.setPosition(pos);
      }
      // check hidden
      if (hidden) {
        item.visible = false;
        item.dimensionHelper.visible = false;
      } else {
        item.visible = true;
        item.dimensionHelper.visible = true;
      }

      // check validation
      if (validation) {
        // check overlap
        if (item.visible && Array.isArray(validation.overlapCheck)) {
          const models = [];
          validation.overlapCheck.forEach((uuid) => {
            const model = this.findModelByUUID(uuid);
            model && model.visible && models.push(model);
          });
          try {
            const isOverlapped = item.isOverlapped(models);
            item.hideError();
            if (isOverlapped) {
              this.overlapDetectedCallbacks.forEach((cb) => cb());
              item.showError(item.position);
            } else {
            }
          } catch (_) {
            console.log(_);
          }
        }
        // check combine
        if (validation.combineCheck) {
          const name = item.name.toLowerCase();
          let isValid = false;
          try {
            const info = item.restriction;
            if (info.includes) {
              for (const key in info.includes) {
                if (name.includes(key.toLowerCase())) {
                  if (Array.isArray(info.includes[key])) {
                    for (const subName of info.includes[key]) {
                      if (name.includes(subName.toLowerCase())) {
                        isValid = true;
                      }
                    }
                  }
                }
              }
            }
            item.hideError();
            !isValid && item.showError(item.position);
          } catch (_) {}
        }
      }
    }
  }
}
