import getEventValue from 'helpers/get-event-value';
import { BuilderFormModel } from 'components/pages/Builder/state';
import { OptionDropdownModel } from '../reducer';
import { updateModelTextures } from './update-model-texture';

export default function handleWidthChange(
  widthRatio: number,
  model: BuilderFormModel,
  option: OptionDropdownModel,
) {
  return (event, setFields) => {
    const width = getEventValue(event);
    const height = width / widthRatio;

    if (width > 0 && height > 0) {
      const updatedOption = { ...option, textureWidth: width, textureHeight: height };
      updateModelTextures(model, updatedOption);
    }

    setFields({ width, height });
  };
}
