import { resolveApiErrorMessage } from 'api/base';
import cutListApi from 'api/cut-list';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { cutListFormActions } from '../reducer';

export default function setTargetKeys(dispatch, cutListId: number, setKeys: string[]) {
  return (keys: string[], dataRow: any[], direction: string, moveKeys: string[]) => {
    const someSetKeyIsMoved = direction === 'left' && moveKeys.some(e => setKeys.includes(e));
    if (!!cutListId && someSetKeyIsMoved) {
      dispatch({ type: cutListFormActions.START_UPDATING });
      const mappedSources = keys.map(key => {
        const [sourceType, sourceId] = key.split('-');
        return { sourceType, sourceId };
      });
      const sourcesPayload = { sources: mappedSources };

      const onSuccess = () => {
        notifyEventChannel(EventChannelList.CNC_ORDER_LIST_CHANGED);
        dispatch({
          type: cutListFormActions.SET_TARGET_KEYS,
          payload: { keys, dataRow },
        });
      };

      const onError = (error) => {
        const message = resolveApiErrorMessage((error as any).response);
        notification({ type: NotificationType.ERROR, message });
      };

      const onFinally = () => {
        dispatch({ type: cutListFormActions.FINISH_UPDATING });
      };

      return cutListApi.defineCutList(cutListId, sourcesPayload)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    }
    return dispatch({
      type: cutListFormActions.SET_TARGET_KEYS,
      payload: { keys, dataRow },
    });
  };
}
