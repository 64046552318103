import { AxiosResponse } from 'axios';
import { ApiContentType, get, post, barePost } from 'api/base';
import { FileViewerModel } from 'models/file-viewer';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/file-viewer/`;

const filesViewerApi = {
  list({ quoteId, params }): Promise<AxiosResponse<FileViewerModel>> {
    return get(`${endpoint}list-quote-files/${quoteId}/${params}`);
  },
  read({ quoteId, params }) {
    return get(`${endpoint}read-quote-files/${quoteId}/${params}`)
      .then((readResponse) => {
        window.open(readResponse.data.filename, '_blank');
      });
  },
  upload({ quoteId, params, file }) {
    return post(`${endpoint}create-quote-files/${quoteId}/${params}`, { file }, ApiContentType.MULTIPART);
  },
  createFolder({ quoteId, params, folderName }) {
    return post(`${endpoint}make-quote-folder/${quoteId}/${params}`, { folderName });
  },
  listQuotePath(quoteId) {
    return get(`${endpoint}list_quote_path/${quoteId}/`);
  },
  processChanges(quoteId, payload) {
    return barePost(`${endpoint}process_changes/${quoteId}/`, payload);
  }
};

export default filesViewerApi;
