import getEventValue from 'helpers/get-event-value';

export default function handleProjectChange(event, setFields, filters) {
  const { project: oldProject } = filters;
  const nextProject = getEventValue(event);

  if (oldProject?.value !== nextProject?.value) {
    setFields({
      project: nextProject,
      quote: undefined,
    });
  }
}
