import React, { FunctionComponent, useCallback, useState } from 'react';
import getEventValue from 'helpers/get-event-value';
import Checkbox, { CheckboxGroup } from 'components/base-components/Checkbox';
import Divider from 'components/base-components/Divider';
import RenderIf from 'components/base-components/RenderIf';
import FileViewer from 'components/base-components/FileViewer';
import { handleOnchange } from './handle-on-change';
import './styles.scss';

interface Props{
  onChange: (something: any) => any;
  value: any;
  quoteId: number;
  onUpload: (params: string, fileName: string) => any;
}

const S3Files: FunctionComponent<Props> = (props) => {
  const { value, onChange, quoteId, onUpload } = props;
  const [checkAll, setCheckAll] = useState(false);
  const filesRender = useCallback((files, fetchFiles, params) => {
    const options = files.map(file => ({
      label: file,
      value: params?.includes('?directory=') ? `${params.slice(11)}/${file}` : file,
    }));
    const valueOptions = options.map(opt => opt.value);
    const currentFilesValue = value.filter(file => valueOptions.includes(file));
    setCheckAll(valueOptions.length === currentFilesValue.length);
    const onChangeInstance = handleOnchange(value, valueOptions, onChange);
    const handleCheckAll = (newValue) => {
      const payload = getEventValue(newValue);
      setCheckAll(payload);
      onChangeInstance(payload ? valueOptions : []);
    };

    return (
      <div className="send-email__checkbox-group">
        <RenderIf isTrue={!!files.length}>
          <Divider className="s3-file__divider" />
          <Checkbox
            className="s3-file__check-all"
            indeterminate={!!currentFilesValue?.length && valueOptions.length > currentFilesValue.length}
            onChange={handleCheckAll}
            value={checkAll}
            id="checkAll"
            label="Check All"
          />
          <Divider className="s3-file__divider" />
        </RenderIf>
        <CheckboxGroup
          value={value}
          options={options}
          onChange={onChangeInstance}
          disabled={fetchFiles}
        />
      </div>
    );
  }, [value, onChange, checkAll]);

  return (
    <FileViewer quoteId={quoteId} filesRender={filesRender} onUploadFile={onUpload} />
  );
};

export default S3Files;
