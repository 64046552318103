import React from 'react';
import { Link } from '../../Typography';

export default function LinkColumnRender(link) {
  return (text: string, other) => {
    const href = typeof link === 'function' ? link(other) : link;

    return (
      <Link href={href}>
        {text}
      </Link>
    );
  };
}
