import React, { FunctionComponent } from 'react';
import { Divider as AntDivider } from 'antd';
import { DividerProps } from 'antd/lib/divider';

const Divider: FunctionComponent<DividerProps> = (props) => {
  return (
    <AntDivider {...props} />
  );
};

export default Divider;
