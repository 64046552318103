import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import RenderIf from 'components/base-components/RenderIf';
import TextArea from 'components/base-components/TextArea';
import NumberInput from 'components/base-components/NumberInput';
import useDeficiencyOrderFormState from './state';

export interface DeficiencyItemFormHistorySateModel {
  deficiencyItemForm: {
    id?: number;
    orderProductId?: number;
  };
}

export default function DeficiencyOrderForm() {
  const { goBack, location: { state } } = useHistory();

  const { deficiencyItemForm: { id, orderProductId } } = state as DeficiencyItemFormHistorySateModel;

  const {
    state: {
      deficiencyOrder,
      savingDeficiencyOrder,
      fetchingDeficiencyOrder,
      errors
    },
    actions: {
      setFormValue,
      saveDeficiencyOrder,
      setErrors,
    },
  } = useDeficiencyOrderFormState(id, orderProductId);

  const modalTitle = useMemo(() => id ? 'Edit Deficiency Order' : 'Create Deficiency Order', [id]);

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveDeficiencyOrder}
      confirmLoading={savingDeficiencyOrder}
    >
      <RenderIf isTrue={!fetchingDeficiencyOrder} fallback={<Spinner />}>
        <Form
          state={deficiencyOrder}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
        >
          <Field
            component={TextArea}
            id="comment"
            name="comment"
            label="Comment"
            placeholder="Description of problem"
            rows={4}
          />
          <Field
            component={NumberInput}
            id="quantity"
            name="quantity"
            label="Quantity"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
}
