export enum TableActions {
  HANDLE_PAGE_CHANGE = 'HANDLE_PAGE_CHANGE',
  HANDLE_ON_CHANGE = 'HANDLE_ON_CHANGE',
  HANDLE_FILTER_CHANGE = 'HANDLE_FILTER_CHANGE',
  HANDLE_DELETE_ROW = 'HANDLE_DELETE_ROW',
  RESET_STATE = 'RESET_STATE',
}

export interface DataTableStateModel<T = any> {
  page: number;
  filter: any;
  columnFilter: any;
  sortField: string;
  entryToDelete: T;
}

export default function tableReducer(state: DataTableStateModel, action): DataTableStateModel {
  const { type: actionType, payload } = action;
  switch (actionType as TableActions) {
    case TableActions.HANDLE_PAGE_CHANGE:
      return { ...state, page: payload };
    case TableActions.HANDLE_FILTER_CHANGE:
      return { ...state, page: 1, filter: payload };
    case TableActions.HANDLE_DELETE_ROW:
      return {
        ...state,
        entryToDelete: payload,
      };
    case TableActions.HANDLE_ON_CHANGE: {
      return {
        ...state,
        page: payload.page || state.page,
        columnFilter: payload.columnFilter || state.columnFilter,
        sortField: payload.sortField || state.sortField,
      };
    }
    case TableActions.RESET_STATE:
      return { ...payload };
    default:
      return state;
  }
}
