import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { OptionModel } from 'models/option';
import optionApi from 'api/option';
import notification, { NotificationType } from 'helpers/notification';
import useGetList from 'hooks/base/get-list';
import fetchModel from './actions/fetch-model';
import setTexture from './actions/set-texture';
import mapOptions from './actions/map-options';
import handleWidthChange from './actions/handle-width-change';
import handleOptionChange from './actions/handle-option-change';
import handleTextureImageLoad from './actions/handle-texture-image-load';
import saveOption from './actions/save-option';
import setErrors from './actions/set-errors';
import textureSettingsReducer, { Actions, TextureSettingsState } from './reducer';
import { validationRules } from './rules';

export { validationRules };

const initialState: TextureSettingsState = {
  texture: { widthRatio: 1 } as any,
  model: undefined,
  loading: true,
  imageLoaded: false,
  callingAPI: false,
  errors: {},
};

export default function useSettingsState() {
  const [state, dispatch] = useReducer(textureSettingsReducer, initialState);
  const imageRef = useRef(undefined);
  const { model, texture, imageLoaded } = state;
  const { widthRatio, option } = texture;

  const {
    fetching: fetchingOptions,
    data: options,
    error: optionsError,
  } = useGetList<OptionModel>(optionApi);

  useEffect(() => {
    fetchModel(dispatch);
  }, []);

  useEffect(() => {
    if (optionsError) {
      notification({
        type: NotificationType.ERROR,
        message: 'We could not load the textures',
      });
    }
  }, [optionsError]);

  useEffect(() => {
    if (option && imageLoaded) {
      dispatch({ type: Actions.START_LOADING_IMAGE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  return {
    state: {
      ...state,
      loading: state.loading || fetchingOptions,
      options: useMemo(() => mapOptions(options), [options]),
      imageRef,
    },
    actions: {
      setTexture: useCallback(setTexture(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      handleOptionChange: useCallback(handleOptionChange(model), [model]),
      handleTextureImageLoad: useCallback(
        handleTextureImageLoad(dispatch, imageRef),
        [],
      ),
      handleWidthChange: useCallback(
        handleWidthChange(widthRatio, model, option),
        [widthRatio, option],
      ),
      saveOption: useCallback(saveOption(dispatch, texture), [texture]),
    },
  };
}
