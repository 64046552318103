import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import filesViewerApi from 'api/file-viewer';
import getEventValue from 'helpers/get-event-value';
import { FileViewerFormActions } from '../reducer';

async function upload(params: string, quoteId: number, files: any[], onUpload) {
  // eslint-disable-next-line no-restricted-syntax
  for (const file of files) {
    // eslint-disable-next-line no-await-in-loop
    await filesViewerApi.upload({ quoteId, params, file });
    if (onUpload) {
      onUpload(params, file.name);
    }
  }
}

export default function uploadFile(params: string, quoteId: number, dispatch, onUpload, callApi?) {
  return (event) => {
    const files = event.dataTransfer?.files || getEventValue(event);
    dispatch({ type: FileViewerFormActions.START_UPLOAD, payload: event });

    const onSuccess = async () => {
      const message = 'File Successfully uploaded';
      notification({ type: NotificationType.SUCCESS, message });
      if (callApi) {
        callApi();
      } else {
        dispatch({ type: FileViewerFormActions.SET_PARAMS, payload: params });
      }
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: FileViewerFormActions.FINISH_UPLOAD });
    };

    return upload(params, quoteId, files, onUpload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
