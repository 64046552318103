import { AxiosResponse } from 'axios';
import { BuilderModel } from 'models/builder';
import builderApi from 'api/builder';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import normalizeModel from 'components/pages/Builder/state/actions/normalize-model';
import { CategoryImageFormActions } from '../reducer';

export default function handleStyleChange(dispatch) {
  return (_key, event) => {
    dispatch({ type: CategoryImageFormActions.START_FETCHING_PRODUCT });
    const { node: { url, title, image } } = event;
    const style = { url, name: title };

    const onSuccess = (response: AxiosResponse<BuilderModel>) => {
      const product = normalizeModel(response.data);
      dispatch({ type: CategoryImageFormActions.SET_PRODUCT, payload: { style, product, image } });
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
      dispatch({ type: CategoryImageFormActions.STOP_FETCHING_PRODUCT });
    };

    return builderApi.getDetailsByModel(title)
      .then(onSuccess)
      .catch(onError);
  };
}
