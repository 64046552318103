import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import purchaseOrderApi from 'api/purchase-order';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Modal from 'components/base-components/ModalV2';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import PODataTable from 'components/pages/POItems/List/PODataTable';
import { Text } from 'components/base-components/Typography';
import usePOItemsState from './state';
import './styles.scss';

const optionMapDropdown = {
  fields: {
    value: 'id',
    label: 'id',
  },
  copyFullItem: true,
};
const filters = {
  filters: {
    searchFields: 'id',
  }
};

const mapPOOptions = (subOptions) => mapGenericDropdownOptions(subOptions, optionMapDropdown);
const hiddenExtra = ['search', 'quantity', 'price', 'edit', 'delete'];

const POItemsForm: FunctionComponent = () => {
  const { goBack } = useHistory();
  const {
    state: {
      purchaseOrder,
      purchaseOrderId,
      formState
    },
    actions: {
      handleOnChange,
    }
  } = usePOItemsState();

  return (
    <Modal
      visible
      onCancel={goBack}
      okText="Submit"
      title="Purchase Order"
      footer={false}
    >
      <div className="prod-manager__po-form__modal-wrapper">
        <div className="prod-manager__po-form__input-wrapper">
          <Form className="prod-manager__po-form__input" onChange={handleOnChange} state={formState}>
            <Field
              component={AsyncSelectV2}
              listAction={purchaseOrderApi.list}
              mapFunction={mapPOOptions}
              name="purchaseOrder"
              id="purchaseOrder"
              filters={filters}
            />
          </Form>
          <RenderIf isTrue={!!purchaseOrder}>
            <div className="prod-manager__po-form__modal-header">
              <div className="prod-manager__po-form__manufacturer-text">
                <Text strong>Manufacturer: </Text>
                <Text>{purchaseOrder?.manufacturer}</Text>
              </div>
              <div className="prod-manager__po-form__manufacturer-text">
                <Text strong>Order Id: </Text>
                <Text>{purchaseOrder?.order || 'None'}</Text>
              </div>
              <div className="prod-manager__po-form__manufacturer-text">
                <Text strong>Tag: </Text>
                <Text>{purchaseOrder?.tag}</Text>
              </div>
            </div>
          </RenderIf>
        </div>
        <PODataTable
          purchaseOrderId={purchaseOrderId}
          fetchingPurchaseOrder={false}
          purchaseOrder={purchaseOrder}
          hiddenExtra={hiddenExtra}
        />
      </div>
    </Modal>
  );
};

export default POItemsForm;
