import React, { FunctionComponent } from 'react';
import Drawer from 'components/base-components/Drawer';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Calendar from 'components/base-components/Calendar';
import ScheduleProvider from './ScheduleProvider';
import Cell from './Cell';
import Header from './Header';
import useCalendarState from './state';
import './styles.scss';
import TaskForm from '../TaskManager/Form';

function dateCellRender(value, openEditTaskModal) {
  return (
    <Cell date={value} openForm={openEditTaskModal} />
  );
}

function headerRender(props) {
  return (
    <Header {...props} />
  );
}

const CalendarPage: FunctionComponent = () => {
  const {
    state: {
      schedule,
      generatingSchedule,
      task,
      isShowTaskForm
    },
    actions: {
      onClose,
      goBack,
      openEditTaskModal
    }
  } = useCalendarState();

  return (
    <>
      <Drawer
        title="Calendar"
        visible
        onClose={goBack}
      >
        <RenderIf isTrue={!generatingSchedule} fallback={<Spinner />}>
          <ScheduleProvider schedule={schedule}>
            <Calendar
              headerRender={props => headerRender({ ...props, openForm: openEditTaskModal })}
              dateCellRender={value => dateCellRender(value, openEditTaskModal)}
            />
          </ScheduleProvider>
        </RenderIf>
      </Drawer>
      <TaskForm visible={isShowTaskForm} taskInstance={task} onCancel={onClose} />
    </>
  );
};

export default CalendarPage;
