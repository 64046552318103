import React, { FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import deficiencyItemApi from 'api/deficiency-item';
import { DeficiencyItemModel } from 'models/order';
import hasPermissions from 'helpers/permissons';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Page from 'components/base-components/Page';
import Help from 'components/forms/CommonHelp';
import Checkbox from 'components/base-components/Checkbox';
import descriptionTemplate from './templates/description-template';
import modelTemplate from './templates/model-template';
import handleOnSubmit from './handle-on-submit';
import { permissions } from '../List/permissions';

const DeficiencyItemDetails: FunctionComponent = () => {
  const { projectId } = useParams<any>();

  const useTableStateParams: UseTableStateParams = useMemo(() => ({
    api: deficiencyItemApi,
    overrideListFunction: deficiencyItemApi.getDeficienciesByProject,
    queryId: projectId,
    defaultSortField: '-id',
  }), [projectId]);

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleFilter,
      handleOnChange,
    }
  } = useTableState<DeficiencyItemModel>(useTableStateParams);

  const hasEditPermission = useMemo(() => hasPermissions(permissions.editDeficiencyItems), []);

  return (
    <Page title="Deficiency Item Details">
      <Help view="deficiency-order-details" title="Deficiency Orders Details" />
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        onSubmit={handleOnSubmit}
        rowKey="id"
      >
        <DataTableColumn render={modelTemplate} columnKey="model" title="Model" />
        <DataTableColumn
          render={descriptionTemplate}
          columnKey="description"
          title="Description"
          showBreakpoint={1588}
        />
        <DataTableColumn dataIndex="quantity" title="Quantity" />
        <DataTableColumn dataIndex="comment" title="Deficiency Comment" />
        <DataTableColumn
          title="Corrected"
          dataIndex="corrected"
          columnKey="corrected"
          component={Checkbox}
          align="center"
          alwaysShowInput
          editable
          disabled={!hasEditPermission}
        />
      </DataTable>
    </Page>
  );
};

export default DeficiencyItemDetails;
