import { PurchaseOrderModel } from 'models/order';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { PurchaseOrderItemFormHistorySateModel } from '../../../Form';

export default function openFormModal(history, purchaseOrderId: string, fromProjectCoordinator?) {
  return (purchaseOrder?: PurchaseOrderModel | any) => {
    const historyLocationState: PurchaseOrderItemFormHistorySateModel = purchaseOrder ? {
      purchaseOrderItemForm: {
        id: purchaseOrder?.id,
        purchaseOrderId,
        fromProjectCoordinator
      },
    } : null;
    history.push(ModalRouteHash.PurchaseOrderItemForm, historyLocationState);
  };
}
