import { ThreeFormActions } from '../reducer';

export default function setModel(dispatch) {
  return (model) => {
    dispatch({
      type: ThreeFormActions.SET_MODEL,
      payload: model
    });
  };
}
