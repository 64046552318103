import React, { FunctionComponent } from 'react';
import { TreeProps } from 'antd/lib/tree';
import { Tree as AntTree } from 'antd';

const Tree: FunctionComponent<TreeProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntTree {...rest}>
      {children}
    </AntTree>
  );
};

export default Tree;
