import { generateUID } from 'helpers/generators';
import { BuilderActions } from '../reducer';
import { BuilderFormModel } from '../types';

export default function handleCopyProduct(
  dispatch,
  selectedProduct: BuilderFormModel,
  goBack,
) {
  return ({ quantity }) => {
    const products = [...new Array(parseInt(quantity, 10))].map(() => ({
      ...selectedProduct,
      id: generateUID()
    }));
    dispatch({
      type: BuilderActions.ADD_PRODUCTS,
      payload: products,
    });
    goBack();
  };
}
