import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { changeLang } from 'store/actions';

import usFlag from 'assets/images/flags/us.jpg';
import frFlag from 'assets/images/flags/french.jpg';

const langMap = {
  en: { lng: 'English', flag: usFlag },
  fr: { lng: 'French', flag: frFlag },
};

export default function LanguageDropdown() {
  const dispatch = useDispatch();
  const lang = useSelector(({ login }: any) => login.lang);
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguageAction = lng => {
    dispatch(changeLang(lng));
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { lng, flag } = langMap[lang];

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className="d-inline-block">
      <DropdownToggle className="btn header-item waves-effect" tag="button">
        <img src={flag} alt="Language Flag" height="16" className="mr-1" />
        <span className="align-middle">{lng}</span>
      </DropdownToggle>
      <DropdownMenu className="language-switch" right>
        <DropdownItem
          tag="a"
          href="#"
          onClick={() => changeLanguageAction('en')}
          className="notify-item"
        >
          <img src={usFlag} alt="English" className="mr-1" height="12" />
          <span className="align-middle">English</span>
        </DropdownItem>
        <DropdownItem
          tag="a"
          href="#"
          onClick={() => changeLanguageAction('fr')}
          className="notify-item"
        >
          <img src={frFlag} alt="French" className="mr-1" height="12" />
          <span className="align-middle">French</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
