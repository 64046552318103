import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Page from 'components/base-components/Page';
import Help from 'components/forms/CommonHelp';
import Collapse, { Panel } from 'components/base-components/Collapse';
import Button from 'components/base-components/Button';
import './styles.scss';

export enum ReportsComponents {
  CustomerGroupIncomeCharts = 'customer-group-income',
  CustomerIncomeChart = 'customer-income',
  TotalSalesTable = 'total-sales',
}
const defaultActiveKey = ['customer', 'sales'];

function goToReport(push, report: ReportsComponents) {
  return () => {
    push(`/report-view/${report}`);
  };
}

const Reports: FunctionComponent = () => {
  const history = useHistory();

  const goToCustomerGroupIncomeCharts = useCallback(
    goToReport(history.push, ReportsComponents.CustomerGroupIncomeCharts), [history]
  );

  const goToTotalSalesTable = useCallback(
    goToReport(history.push, ReportsComponents.TotalSalesTable), [history]
  );

  const goToCustomerIncomeChart = useCallback(
    goToReport(history.push, ReportsComponents.CustomerIncomeChart), [history]
  );

  return (
    <Page title="Reports">
      <Help view="summary-reports" title="Reports" />
      <Collapse defaultActiveKey={defaultActiveKey}>
        <Panel key="customer" header="Customer">
          <dl>
            <dd><Button id="customerGroupIncome" type="text" label="Customer Group Income" onClick={goToCustomerGroupIncomeCharts} /></dd>
            <dd><Button id="customerIncome" type="text" label="Customer Income" onClick={goToCustomerIncomeChart} /></dd>
          </dl>
        </Panel>
        <Panel key="sales" header="Sales">
          <dl>
            <dd><Button id="totalSales" type="text" label="Total Sales" onClick={goToTotalSalesTable} /></dd>
          </dl>
        </Panel>
      </Collapse>
    </Page>
  );
};

export default Reports;
