import { AxiosResponse } from 'axios';
import { ApiContentType, ApiModel, get, post, barePost, ListResponseModel } from 'api/base';
import getApi from 'api/base/get-api';
import { ProductModel, PriceXlsModel } from 'models/product';
import downloadBlob from './base/download-blob';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/products/`;

interface ProductApiModel extends ApiModel<ProductModel> {
  fetchByCategory: (params) => Promise<AxiosResponse<ListResponseModel<ProductModel>>>;
  readXlsFile: (xlsFile) => Promise<AxiosResponse<PriceXlsModel[]>>;
  getByModel: (model) => Promise<AxiosResponse<ProductModel>>;
  getPrice: (params, payload) => Promise<AxiosResponse>;
  getXlsTemplate: () => Promise<void>;
}

const productApi: ProductApiModel = {
  ...getApi<ProductModel>(endpoint, ApiContentType.MULTIPART),
  fetchByCategory(params) {
    const { filters: { category, ...otherFilters }, ...otherParams } = params;
    const queryParams = {
      ...otherParams,
      filters: otherFilters,
    };
    return get(`${endpoint}branch/${category}/`, queryParams);
  },
  readXlsFile(xlsFile) {
    return post(`${endpoint}price_xls/`, { xlsFile }, ApiContentType.MULTIPART);
  },
  getByModel(model) {
    return get(`${endpoint}model/${model}/`);
  },
  getPrice(_params, payload) {
    return barePost(`${endpoint}product-price/`, payload);
  },
  getXlsTemplate() {
    return downloadBlob(
      `${endpoint}excel_import_template/`,
      'template.xlsx'
    );
  }
};

export default productApi;
