import React, { FunctionComponent } from 'react';
import tfgLogo from 'assets/images/tfg_logo-black.png';

interface Props {
  title: string;
  workOrder: number;
  jobTitle: string;
}

const Header: FunctionComponent<Props> = (props) => {
  const { title, workOrder, jobTitle } = props;

  return (
    <header className="work-order__header">
      <div className="work-order__header__section stretch">
        <h1>{`${title} ${workOrder}`}</h1>
        <h3>{jobTitle}</h3>
      </div>
      <div className="work-order__header__section">
        <img src={tfgLogo} alt="tfg-logo" className="work-order__header__img" />
        <div className="work-order__header__company-section">
          <span>
            <i className="fa fa-phone" />
            416-360-1555
          </span>
          <span> | </span>
          <span>
            <i className="fa fa-envelope" />
            sales@thefurnitureguys.ca
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
