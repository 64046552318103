import { AxiosResponse } from 'axios';
import { get } from 'api/base';
import { IncomeCustomer, IncomeCustomerGroup } from 'models/report';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/reports/`;

const reportApi = {
  incomeCustomerGroup(params): Promise<AxiosResponse<IncomeCustomerGroup[]>> {
    return get(`${endpoint}income-customer-group/`, params);
  },
  incomeCustomer(params): Promise<AxiosResponse<IncomeCustomer[]>> {
    return get(`${endpoint}income-customer/`, params);
  },
};

export default reportApi;
