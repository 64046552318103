import React, { FunctionComponent } from 'react';
import RenderIf from 'components/base-components/RenderIf';
import './styles.scss';

interface Props {
  forInput?: string;
  text: string;
  required?: boolean;
}

const Label: FunctionComponent<Props> = (props) => {
  const { text, forInput, required } = props;

  if (text) {
    return (
      <label className="input__label" htmlFor={forInput}>
        {text}
        <RenderIf isTrue={required}>
          <sup className="input__label__required-mark">*</sup>
        </RenderIf>
      </label>
    );
  }

  return null;
};

export default Label;
