import { AxiosResponse } from 'axios';
import { ApiContentType, get, post } from 'api/base';
import { getEmailPayload } from 'api/base/get-email-payload';
import getApi from 'api/base/get-api';
import { CopyQuotePayloadModel, QuoteModel } from 'models/quote';
import { OrderOption } from 'models/order-option';
import downloadBlob from './base/download-blob';

export interface CopyQuoteResponse {
  id: number;
  discount: number;
  project: number;
  shippingPrice: number;
  comments: string;
  createdDate: string;
  expireDate: string;
  modifiedDate: string;
  quoteDate: string;
}
export interface ApplyOptionsPayload {
  categories: {[x: string]: string[]};
  groups: string[];
  options: OrderOption[];
}
const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/quotes/`;

const quoteApi = {
  ...getApi<QuoteModel>(endpoint, ApiContentType.MULTIPART, true),
  reuseQuote(
    quoteId: number,
    payload: CopyQuotePayloadModel,
  ): Promise<AxiosResponse<CopyQuoteResponse>> {
    return post(`${endpoint}${quoteId}/reuse/`, payload, ApiContentType.MULTIPART);
  },
  copyQuote(
    quoteId: number,
    payload: {comments: string},
  ) {
    return post(`${endpoint}${quoteId}/copy/`, payload, ApiContentType.MULTIPART);
  },
  sendEmail(quoteId: number, recipients: string[], extras) {
    const payload = getEmailPayload(recipients);
    return post(`${endpoint}${quoteId}/email/`, { ...payload, ...extras });
  },
  sendProposalEmail(quoteId: number, recipients: string[], extras) {
    const { html, ...rest } = extras;
    const payload = getEmailPayload(recipients, html);
    return post(`${endpoint}${quoteId}/email_proposal/`, { ...payload, ...rest });
  },
  print(quoteId: number) {
    return downloadBlob(
      `${endpoint}${quoteId}/view/`,
      `Quote ${quoteId}.pdf`
    );
  },
  printProposal(quoteId: number) {
    return downloadBlob(
      `${endpoint}${quoteId}/view_proposal/`,
      `Quote ${quoteId} Proposal.pdf`
    );
  },
  applyOptions(
    quoteId: number,
    payload: ApplyOptionsPayload,
  ) {
    return post(`${endpoint}${quoteId}/apply_options/`, payload, ApiContentType.JSON);
  },
  emailTemplate(
    quoteId: number,
  ) {
    return get(`${REACT_APP_ORDERS_API}/email/default-text/quote/${quoteId}/`);
  },
  emailProposalTemplate(
    quoteId: number,
  ) {
    return get(`${REACT_APP_ORDERS_API}/email/default-text/proposal/${quoteId}/`);
  },
  sendEmailV2(quoteId: number, recipients: string[], { html, ...extras }) {
    const payload = getEmailPayload(recipients, html);
    return post(`${REACT_APP_ORDERS_API}/email/send/${quoteId}/`, {
      ...payload,
      ...extras,
    });
  },
  getLeadTimeCalculated(quoteId: number) {
    return get(`${endpoint}${quoteId}/lead-time-calculated/`);
  }
};

export default quoteApi;
