import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useGetOne from 'hooks/base/get-one';
import { OptionModel } from 'models/option';
import optionApi from 'api/option';
import notification, { NotificationType } from 'helpers/notification';
import ShowDetails from 'components/forms/showDetails';
import { OptionFormHistorySateModel } from '../Form';

const OptionDetails: FunctionComponent = () => {
  const { location: { state } } = useHistory();

  const { optionForm: { optionUrl } } = state as OptionFormHistorySateModel;

  const { fetching, error, data: option } = useGetOne<OptionModel>(optionApi, optionUrl);

  useEffect(() => {
    if (error) {
      notification({ type: NotificationType.ERROR, message: error });
    }
  }, [error]);

  const image = useMemo(() => option ? option.image : '', [option]);

  const title = useMemo(() => option ? option.name : 'Option', [option]);

  const description = useMemo(() => option ? option.description : '', [option]);

  return (
    <ShowDetails
      description={description}
      fetching={fetching}
      image={image}
      title={title}
    />
  );
};

export default OptionDetails;
