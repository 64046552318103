/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FunctionComponent, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import './styles.scss';
import { Units } from 'models/builder';

const UnitList = [
  { label: 'In', value: Units.inches },
  { label: 'Ft', value: Units.feets },
  { label: 'Cm', value: Units.centimeters }
];

interface Props {
  viewMode?: string;
  locked?: boolean;
  onShow2DPlanner?: () => void;
  onShow3DViewClicked?: () => void;
  onUnitChanged?: (unit: string) => void;
  onLockSceneToggled?: (locked: boolean) => void;
  onShowDimensionsToggled?: (show: boolean) => void;
  onSnapToggled?: (snap: boolean) => void;
  onXRayToggled?: (xRayMode: boolean) => void;
  onAddProductClicked?: () => void;
  onImportFromConfiguratorClicked?: () => void;
  onSelectStyle?: (style) => void;
  onSaveClicked?: () => void;
  onLoadClicked?: () => void;
  openAddToCartForm?: () => void;
  hiddenAddToCart?: boolean;
  loading?: boolean;
  // onSnapshotClicked?: () => void;
}

interface State {
  unitIndex: number;
  locked: boolean;
  showDimension: boolean;
  snap: boolean;
  xRay: boolean;
}

const LeftToolbar: FunctionComponent<Props> = (props) => {
  const [state, setStateDefault] = useState<State>(
    {
      unitIndex: 0,
      locked: props.locked,
      showDimension: true,
      snap: true,
      xRay: false,
    }
  );

  const getButtons = useMemo(() => {
    const {
      loading,
      locked,
      openAddToCartForm,
      onLoadClicked,
      onSaveClicked,
      onLockSceneToggled,
      viewMode,
      hiddenAddToCart,
      onAddProductClicked,
      onShow2DPlanner,
      onShow3DViewClicked,
      onShowDimensionsToggled,
      onSnapToggled,
      onUnitChanged,
      onXRayToggled,
      onImportFromConfiguratorClicked
    } = props;

    const {
      locked: lockedState,
      snap,
      showDimension,
      xRay,
      unitIndex
    } = state;
    const setState = (data) => setStateDefault((prevState => ({ ...prevState, ...data })));

    const handleUnitChanged = () => {
      // const { unitIndex } = state;
      // let index = unitIndex;
      // index = (index + 1) % UnitList.length;
      const index = (state.unitIndex + 1) % UnitList.length;
      setState({ unitIndex: index });
      onUnitChanged(UnitList[index].value);
    };

    const handleToggleLock = () => {
      onLockSceneToggled(!state.locked);
      setState({ locked: !state.locked });
    };

    const handleToggleDimensions = () => {
      onShowDimensionsToggled(!state.showDimension);
      setState({ showDimension: !state.showDimension });
    };

    const handleToggleSnap = () => {
      onSnapToggled(!state.snap);
      setState({ snap: !state.snap });
    };

    const handleToggleXRay = () => {
      onXRayToggled(!state.xRay);
      setState({ xRay: !state.xRay });
    };

    const buttons = [
      viewMode === '3d'
        ? {
          font: 'fa fa-border-all',
          tooltip: 'Show 2D Floor Planner',
          callback: onShow2DPlanner,
        }
        : {
          font: 'fa fa-cube',
          tooltip: 'Show 3D View',
          callback: onShow3DViewClicked,
        },
      null,
      {
        toggled: lockedState,
        font: lockedState ? 'fas fa-unlock' : 'fas fa-lock',
        tooltip: lockedState ? 'Unlock Scene' : 'Lock Scene',
        callback: handleToggleLock,
        hideIfLocked: true,
      },
      {
        toggled: showDimension,
        font: 'fa fa-ruler-combined',
        tooltip: `${showDimension ? 'Show' : 'Hide'} Dimensions`,
        callback: handleToggleDimensions,
      },
      {
        toggled: snap,
        font: 'fas fa-magnet',
        tooltip: 'Snap Objects',
        callback: handleToggleSnap,
      },
      {
        toggled: xRay,
        font: 'far fa-square',
        tooltip: 'X-Ray Wall',
        callback: handleToggleXRay,
      },
      null,
      {
        font: 'fas fa-plus-square',
        tooltip: 'Add Product',
        callback: onAddProductClicked,
        hideIfLocked: true,
      },
      {
        font: 'fas fa-file-import',
        tooltip: 'Import From Set Configurator',
        callback: onImportFromConfiguratorClicked,
      },
      null,
      {
        font: 'fa fa-save',
        tooltip: 'Save Design',
        callback: onSaveClicked,
        hideIfLocked: true,
      },
      {
        font: 'fas fa-cart-plus',
        tooltip: 'Add To Cart',
        callback: openAddToCartForm,
        hide: hiddenAddToCart,
      },
      {
        font: 'fas fa-upload',
        tooltip: 'Load',
        callback: onLoadClicked,
      },
      // {
      //   font: 'far fa-image',
      //   tooltip: 'Screenshot',
      //   callback: this.props.onSnapshotClicked,
      // },
      null,
      {
        font: '',
        label: UnitList[unitIndex].label,
        tooltip: 'Change Unit',
        callback: handleUnitChanged,
      },
    ];

    return buttons
      .filter((button) => (!button?.hide && (!locked || !button?.hideIfLocked)))
      .map(((button: any, index) => {
        if (!button) return <div key={index} className="hr" />;
        return (
          <div
            key={index}
            className={`left-toolbar-button ${
              button.toggled ? 'toggled' : ''
            }`}
            data-tip={button.tooltip}
            onClick={() => (!loading && typeof button.callback === 'function') && button.callback()}
          >
            <span className={button.font} style={button.fontStyle}>
              {button.label}
            </span>
          </div>
        );
      }));
  }, [state, props]);

  return (
    <aside className="builder__left-toobar-container">
      <div className="left-toolbar">
        {getButtons}
        <ReactTooltip />
      </div>
    </aside>
  );
};

export default LeftToolbar;
