import React, { FunctionComponent } from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';

export interface DatatableColumnProps extends ColumnProps<any>{
  isActionColumn?: boolean;
  viewPermissions?: string[];
  onShowDetails?: (data) => void;
  editPermissions?: string[];
  onEdit?: (data) => void;
  deletePermissions?: string[];
  onDelete?: (data) => void;
  isCurrency?: boolean;
  isDate?: boolean;
  children?: any;
  columnKey?: string;
  showTooltip?: boolean;
  editable?: boolean;
  placeholder?: string;
  component?: any;
  alwaysShowInput?: any;
  allowDecimals?: boolean;
  hideActions?: (rowData) => 0 | 1;
  hideColumn?: boolean;
  trimAt?: number;
  link?: any;
  showBreakpoint?: number;
  columnGroup?: boolean;
  [x: string]: any;
}

const DataTableColumn: FunctionComponent<DatatableColumnProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Table.Column {...rest}>
      {children}
    </Table.Column>
  );
};

export default DataTableColumn;
