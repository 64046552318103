import moment from 'moment';
import { PurchaseOrderItemModel } from 'models/order';

export default function getFormData(purchaseOrderItem: PurchaseOrderItemModel, purchaseOrderId: string) {
  if (purchaseOrderItem) {
    const {
      orderProduct,
      sentToPaint,
      ...rest
    } = purchaseOrderItem;

    return {
      ...rest,
      orderProduct: { value: orderProduct.id, label: orderProduct.name },
      purchaseOrder: purchaseOrderId,
      sentToPaint: sentToPaint ? moment(sentToPaint) : null,
    };
  }

  return {
    partName: '',
    description: '',
    quantity: 1,
    price: '',
    received: false,
    model: '',
    mpn: '',
    orderProduct: undefined,
    purchaseOrder: purchaseOrderId,
    sentToPaint: null,
  };
}
