import React from 'react';
import Spinner from '../../../base-components/Spinner';

export const noDataScreen = (
  <section className="work-order__no-data">
    <h1>
      Oops, looks like you should not be here...
    </h1>
    <h4>
      We can find the information we need to work with,
      so probably you got here by error, please go back and try again.
    </h4>
  </section>
);

export const errorScreen = (
  <section className="work-order__no-data">
    <h1>
      Oops!? ...this is embarrassing.
    </h1>
    <h4>
      Something went wrong.
      <br />
      If the issue is on our side, we will deal with it,
      but just in case,
      <br />
      please check your internet connection.
    </h4>
  </section>
);

export const fetchingScreen = (
  <section className="work-order__no-data">
    <Spinner centered tip="Loading" size="large" />
  </section>
);
