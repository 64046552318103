import React, { FunctionComponent, useMemo } from 'react';
import accountApi from 'api/account';
import userApi from 'api/user';
import projectsApi from 'api/projects';
import quoteApi from 'api/quote';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Button from 'components/base-components/Button';
import BackTop from 'components/base-components/BackTop';
import Page from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import Dropdown, { DropdownItem } from 'components/base-components/Dropdown';
import PopConfirm from 'components/base-components/PopConfirm';
import EditInPlaceV2 from 'components/base-components/EditInPlaceV2';
import { Text } from 'components/base-components/Typography';
import NumberInput from 'components/base-components/NumberInput';
import Input from 'components/base-components/Input';
import CreateGroup from './Form/CreateGroup';
import CreateCustomOption from './Form/CreateOption';
import CreateManufacturer from './Form/CreateManufacturer';
import ConvertToOrder from './ConvertToOrder';
import QuoteProductsList from './QuoteProductsList';
import useQuoteViewState, {
  mapAccounts,
  mapProjects,
  mapQuotes,
} from './state';
import './styles.scss';
import InfoSubHeader from './InfoSubHeader';
import { permissions } from './permissions';
import EspecialInstructions from './SpecialInstructions';
import Help from './Help';

const Quote: FunctionComponent = () => {
  const {
    state: {
      fetchingQuote,
      fetchingShipment,
      shipment,
      dataQuote,
      nameGroupForSelect,
      showGroupModal,
      showCustomOptionModal,
      showManufacturerModal,
      customOptionParent,
      filters,
      updatingFilters,
      contactFilterParams,
      projectFilterParams,
      quoteFilterParams,
      convertingToOrder,
      isIncompleteOrder,
      showConvertModal,
      missingFields,
      isEditable,
      fetchingHash,
      printingQuote,
      extraCharges,
      rowSelection,
      selectedRows,
      bulkDeleting,
      isDealer,
      customOptionRow,
    },
    actions: {
      setFilters,
      openCreateAccountModal,
      openCopyQuoteModal,
      openCreatUserModal,
      openAddProductModal,
      open3DAddProductModal,
      openCustomProductModal,
      openCreateQuoteModal,
      openCreateProjectModal,
      openUpdateShipmentModal,
      openApplyOptionsModal,
      openShipmentDQ,
      openWorkOrder,
      openPackingSlip,
      openThirdPartyPaint,
      openSendEmailModal,
      openSendProposalEmailModal,
      openSendDQEmailModal,
      openAssignFilesModal,
      printQuote,
      printQuestionnaire,
      printProposalQuote,
      handleAccountChange,
      handleContactChange,
      handleProjectChange,
      openGroupModal,
      openCustomOptionModal,
      openManufacturerModal,
      toggleConvertModal,
      convertToOrder,
      updateDataQuoteManually,
      openFileViewerModal,
      bulkDelete,
      openCopyProductModal,
      geLeadTimePayload,
      geCustomerPOPayload,
      onCustomerPOSuccess,
      onLeadTimeSuccess,
      reFetchLeadTime,
      mapUsers
    },
  } = useQuoteViewState();

  const {
    account,
    contact,
    project,
    quote: quoteFilter
  } = filters;

  const isAnOrder = useMemo(
    () => !!dataQuote && !isEditable,
    [dataQuote, isEditable],
  );

  const cardTitle = useMemo(() => {
    const quoteDescription = quoteFilter && dataQuote
      ? `Quote #${dataQuote.id} - ${dataQuote.comments}`
      : 'Quote';

    if (quoteFilter && isAnOrder) {
      const orderId = dataQuote.orderNum ? dataQuote.orderNum.split('-')[1] : '';
      return `Order ${orderId} / ${quoteDescription}`;
    }

    return quoteDescription;
  }, [isAnOrder, dataQuote, quoteFilter]);

  const convertedShippingOptions = useMemo<DropdownItem[]>(() => ([
    { key: 'email', label: 'Send Questionnaire', onClick: openSendDQEmailModal, hidden: isDealer },
    { key: 'view', label: 'View Questionnaire', onClick: openShipmentDQ },
    {
      key: 'print-questionnaire',
      label: 'Print Questionnaire',
      onClick: printQuestionnaire,
    },
  ]), [openSendDQEmailModal, openShipmentDQ, printQuestionnaire, isDealer]);

  const filesOptions = useMemo<DropdownItem[]>(() => ([
    { key: 'file-viewer', label: 'File Viewer', onClick: openFileViewerModal },
    { key: 'assign-files', label: 'Assign Files', onClick: openAssignFilesModal, hidden: isDealer },
  ]), [openFileViewerModal, openAssignFilesModal, isDealer]);

  const shippingOptions = useMemo<DropdownItem[]>(() => ([
    { key: 'shipping', label: 'Update Shipping', onClick: openUpdateShipmentModal },
    ...convertedShippingOptions,
  ]), [convertedShippingOptions, openUpdateShipmentModal]);

  const convertedSenderOptions = useMemo<DropdownItem[]>(() => ([
    { key: 'email', label: 'Send by email', onClick: openSendEmailModal, hidden: isDealer },
    { key: 'print', label: `Print ${isAnOrder ? 'Order' : 'Quote'}`, onClick: printQuote },
  ]), [openSendEmailModal, printQuote, isAnOrder, isDealer]);

  const senderOptions = useMemo<DropdownItem[]>(() => ([
    ...convertedSenderOptions,
    {
      key: 'proposal',
      label: 'Send Proposal by email',
      onClick: openSendProposalEmailModal,
      hidden: isDealer
    },
    { key: 'print-proposal', label: 'Print Proposal', onClick: printProposalQuote },
  ]), [printProposalQuote, openSendProposalEmailModal, convertedSenderOptions, isDealer]);

  const printoutOptions = useMemo<DropdownItem[]>(
    () => ([
      {
        key: 'work-order',
        label: 'Work Order',
        onClick: openWorkOrder,
      },
      {
        key: 'packing-slip',
        label: 'Packing Slip',
        onClick: openPackingSlip,
      },
      {
        key: 'third-paint',
        label: 'Third Party Paint',
        onClick: openThirdPartyPaint,
      },
    ]),
    [
      openWorkOrder,
      openPackingSlip,
      openThirdPartyPaint,
    ],
  );

  const productOptions = useMemo<DropdownItem[]>(
    () => ([
      {
        key: 'add-product',
        label: 'Add Product',
        onClick: openAddProductModal,
      },
      {
        key: 'add-product-3D',
        label: '3D Add Product',
        onClick: open3DAddProductModal,
      },
      {
        key: 'create-group',
        label: 'Create Group',
        onClick: openGroupModal,
      },
      {
        key: 'apply-options',
        label: 'Apply Options',
        onClick: openApplyOptionsModal,
      },
      {
        key: 'custom-product',
        label: 'Custom Product',
        onClick: openCustomProductModal,
        hidden: isDealer,
      },
    ]),
    [
      openAddProductModal,
      openGroupModal,
      openApplyOptionsModal,
      open3DAddProductModal,
      openCustomProductModal,
      isDealer
    ],
  );

  const showQuoteDetails = (!!quoteFilter && !!dataQuote) || fetchingQuote;

  return (
    <>
      <BackTop />
      <Help isAnOrder={isAnOrder} />
      <Page title={cardTitle}>
        <div className="quote-view__sub-header">
          <RenderIf isTrue={!!dataQuote}>
            <InfoSubHeader project={dataQuote?.project} />
          </RenderIf>
          <div>
            <RenderIf isTrue={!!quoteFilter && !!dataQuote && isEditable}>
              <AccessControl permissions={permissions.addQuote}>
                <Button
                  type="default"
                  label="Copy Quote"
                  onClick={openCopyQuoteModal}
                  mR
                  mT
                />
              </AccessControl>
              <AccessControl permissions={permissions.addOrder}>
                <Button
                  label="Convert to Order"
                  onClick={toggleConvertModal}
                  disabled={!shipment}
                  mT
                />
              </AccessControl>
            </RenderIf>
          </div>
        </div>
        <hr />
        <Form
          onChange={setFilters}
          state={filters}
          className="quote-view__filters"
        >
          <RenderIf isTrue={!isDealer}>
            <Field
              name="account"
              id="account"
              label="Account"
              component={AsyncSelectV2}
              listAction={accountApi.list}
              mapFunction={mapAccounts}
              disabled={updatingFilters}
              onAddClick={openCreateAccountModal}
              onChange={handleAccountChange}
              checkNewValue
              allowClear
              mR
            />
            <Field
              name="contact"
              id="contact"
              label="Contact"
              className="contact-select"
              component={AsyncSelectV2}
              listAction={userApi.list}
              mapFunction={mapUsers}
              filters={contactFilterParams}
              disabledAdd={!account}
              disabled={updatingFilters}
              onAddClick={openCreatUserModal}
              onChange={handleContactChange}
              checkNewValue
              allowClear
              mR
            />
          </RenderIf>
          <Field
            name="project"
            id="project"
            label="Project"
            component={AsyncSelectV2}
            listAction={projectsApi.list}
            mapFunction={mapProjects}
            filters={projectFilterParams}
            disabledAdd={!contact}
            disabled={updatingFilters}
            onAddClick={openCreateProjectModal}
            onChange={handleProjectChange}
            checkNewValue
            allowClear
            mR
          />
          <Field
            name="quote"
            id="quote"
            label="Quote"
            component={AsyncSelectV2}
            listAction={quoteApi.list}
            mapFunction={mapQuotes}
            filters={quoteFilterParams}
            disabledAdd={!project || !isEditable}
            disabled={updatingFilters}
            onAddClick={openCreateQuoteModal}
            checkNewValue
            allowClear
          />
        </Form>
        <hr />
        <RenderIf isTrue={!showQuoteDetails}>
          <div className="d-flex justify-content-center m-3 align-items-center">
            <h5>
              Please select a quote
            </h5>
          </div>
        </RenderIf>
        <RenderIf isTrue={showQuoteDetails}>
          <RenderIf isTrue={!updatingFilters && quoteFilter && isEditable}>
            <div className="quote-view__buttons-panel">
              <div className="quote-view__edit-places-header-wrapper">
                <div className="quote-view__edit-places-header">
                  <Text strong>Customer PO:</Text>
                  <div className="quote-view__customer-po__edit-in-place">
                    <EditInPlaceV2
                      data={dataQuote?.project}
                      field="customerPo"
                      onSubmit={projectsApi.patch}
                      getPayload={geCustomerPOPayload}
                      onSubmitSuccess={onCustomerPOSuccess}
                      component={Input}
                      placeholder="Click to Add"
                    />
                  </div>
                </div>
                <div className="quote-view__edit-places-header">
                  <Text strong>Lead Time:</Text>
                  <div className="quote-view__lead-time__edit-in-place">
                    <EditInPlaceV2
                      data={dataQuote}
                      field="leadTimeCalculated"
                      onSubmit={quoteApi.patch}
                      getPayload={geLeadTimePayload}
                      onSubmitSuccess={onLeadTimeSuccess}
                      component={NumberInput}
                    />
                  </div>
                  <RenderIf isTrue={!!dataQuote?.leadTime}>
                    <Text type="danger">*edited*</Text>
                  </RenderIf>
                </div>
              </div>
              <div className="quote-view__button-group">
                <RenderIf isTrue={!!selectedRows.length}>
                  <AccessControl permissions={permissions.deleteOrderProducts}>
                    <PopConfirm title="Sure to delete?" onConfirm={bulkDelete}>
                      <Button
                        label="Bulk Delete"
                        loading={bulkDeleting}
                        disabled={!selectedRows.length}
                        mR
                        mB
                      />
                    </PopConfirm>
                  </AccessControl>
                  <AccessControl permissions={permissions.editQuote}>
                    <Button
                      label="Copy Lines"
                      disabled={!selectedRows.length}
                      onClick={openCopyProductModal}
                      mR
                      mB
                    />
                  </AccessControl>
                </RenderIf>
                <AccessControl permissions={permissions.editQuote}>
                  <Dropdown items={productOptions}>
                    <Button id="product-dropdown" label="Product" mR mB />
                  </Dropdown>
                </AccessControl>
                <AccessControl permissions={permissions.editShipping}>
                  <Dropdown disabled={!shipment} items={shippingOptions}>
                    <Button
                      id="shipping-dropdown"
                      label="Shipping"
                      loading={fetchingHash || fetchingShipment}
                      mR
                      mB
                    />
                  </Dropdown>
                </AccessControl>
                <AccessControl permissions={permissions.editQuote}>
                  <Dropdown items={senderOptions} disabled={!shipment}>
                    <Button
                      id="send-dropdown"
                      label="Send"
                      loading={printingQuote}
                      mR
                      mB
                    />
                  </Dropdown>
                </AccessControl>
                <AccessControl permissions={permissions.viewQuoteFiles}>
                  <Dropdown items={filesOptions}>
                    <Button
                      id="files"
                      label="Files"
                      mB
                    />
                  </Dropdown>
                </AccessControl>
              </div>
            </div>
          </RenderIf>
          <RenderIf isTrue={!updatingFilters && quoteFilter && isAnOrder}>
            <div className="quote-view__buttons-panel">
              <div className="quote-view__edit-places-header-wrapper">
                <div className="quote-view__edit-places-header">
                  <Text strong>Customer PO:</Text>
                  <div className="quote-view__customer-po__edit-in-place">
                    <EditInPlaceV2
                      data={dataQuote?.project}
                      field="customerPo"
                      onSubmit={projectsApi.patch}
                      getPayload={geCustomerPOPayload}
                      onSubmitSuccess={onCustomerPOSuccess}
                      component={Input}
                      placeholder="Click to Add"
                    />
                  </div>
                </div>
              </div>
              <div className="quote-view__button-group">
                <Dropdown items={printoutOptions}>
                  <Button label="Printouts" mB mR />
                </Dropdown>
                <AccessControl permissions={permissions.editOrder}>
                  <Dropdown items={convertedSenderOptions} disabled={!shipment}>
                    <Button id="send-dropdown" label="Send" loading={printingQuote} mR mB />
                  </Dropdown>
                </AccessControl>
                <AccessControl permissions={permissions.viewQuoteFiles}>
                  <Dropdown items={convertedShippingOptions} disabled={!shipment}>
                    <Button id="shipping-dropdown" label="Questionnaire" loading={printingQuote} mR mB />
                  </Dropdown>
                </AccessControl>
                <AccessControl permissions={permissions.viewQuoteFiles}>
                  <Dropdown items={filesOptions}>
                    <Button
                      id="files"
                      label="Files"
                      mB
                    />
                  </Dropdown>
                </AccessControl>
              </div>
            </div>
          </RenderIf>
          <QuoteProductsList
            quote={dataQuote}
            loading={fetchingQuote}
            groupOptions={nameGroupForSelect}
            isQuote={isEditable}
            shipment={shipment}
            taxes={account?.taxDetails}
            updateDataQuoteManually={updateDataQuoteManually}
            reFetchLeadTime={reFetchLeadTime}
            charges={extraCharges}
            rowSelection={isAnOrder || !isEditable ? null : rowSelection}
            isDealer={!!isDealer}
            account={account}
          />
          <EspecialInstructions
            quote={dataQuote}
            loading={fetchingQuote}
            updateDataQuoteManually={updateDataQuoteManually}
          />
        </RenderIf>
      </Page>
      <CreateGroup
        visible={showGroupModal}
        onClose={openGroupModal}
      />
      <CreateCustomOption
        visible={showCustomOptionModal}
        parent={customOptionParent}
        onClose={openCustomOptionModal}
        row={customOptionRow}
      />
      <CreateManufacturer
        visible={showManufacturerModal}
        onClose={openManufacturerModal}
      />
      <ConvertToOrder
        isVisible={showConvertModal}
        isIncompleteOrder={isIncompleteOrder}
        convertingToOrder={convertingToOrder}
        quote={dataQuote}
        shipment={shipment}
        missingFields={missingFields}
        onSubmit={convertToOrder}
        onClose={toggleConvertModal}
      />
    </>
  );
};

export default Quote;
