import React, { FunctionComponent, useEffect } from 'react';
import { useHelp } from 'components/providers/Help';
import Modal from 'components/base-components/ModalV2';
import FlexBox from 'components/base-components/FlexBox';
import Button from 'components/base-components/Button';

interface Props {
  title: string;
  name: string;
  width?: string;
  className?: string;
}

const HelpModal: FunctionComponent<Props> = (props) => {
  const { title, name, width, className, children } = props;
  const {
    state: {
      activeModal,
    },
    actions: {
      registerModal,
      removeModal,
      closeModal,
    },
  } = useHelp();

  useEffect(() => {
    registerModal(name);
    return () => removeModal(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const isVisible = name === activeModal;

  if (isVisible) {
    const footer = (
      <FlexBox grow justify="flex-end">
        <Button label="Ok" onClick={closeModal} />
      </FlexBox>
    );

    return (
      <Modal
        visible
        onCancel={closeModal}
        title={title}
        footer={footer}
        width={width}
        className={className}
      >
        {children}
      </Modal>
    );
  }

  return null;
};

export default HelpModal;
