import React, { FunctionComponent } from 'react';
import { QuoteModel } from 'models/quote';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import renderContent from './templates/render-content';
import modelTemplate from './templates/model-template';
import rowClassName from './row-class-name';
import useProductsListState from './state';

interface Props {
  quote: QuoteModel;
}

const ProductsList: FunctionComponent<Props> = (props) => {
  const { quote } = props;

  const {
    state: {
      expandedRowKeys,
      quoteProducts,
    }
  } = useProductsListState(quote);

  return (
    <DataTable
      rowKey="id"
      dataSource={quoteProducts}
      rowClassName={rowClassName}
      pagination={false}
      childrenColumnName="products"
      indentSize={0}
      expandIconColumnIndex={-1}
      expandedRowKeys={expandedRowKeys}
    >
      <DataTableColumn
        columnKey="model"
        title="Model"
        render={modelTemplate}
        className="quote-product-list__model-column"
      />
      <DataTableColumn
        dataIndex="name"
        columnKey="name"
        title="Product"
        render={renderContent}
        className="work-order__column-name"
      />
      <DataTableColumn
        dataIndex="quantity"
        columnKey="quantity"
        title="Quantity"
        render={renderContent}
      />
      <DataTableColumn
        dataIndex="check"
        columnKey="check"
        title="Check"
        render={renderContent}
      />
    </DataTable>
  );
};

export default ProductsList;
