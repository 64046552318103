import { isValidFile } from 'helpers/file';
import { AccountApplicationFormModel } from '..';

export default function getPayload(accountApplication: AccountApplicationFormModel) {
  const {
    creditAmount,
    yearsInBusiness,
    ref1BusinessName,
    ref1ContactEmail,
    ref1ContactPhone,
    ref2BusinessName,
    ref2ContactEmail,
    ref2ContactPhone,
    ref3BusinessName,
    ref3ContactEmail,
    ref3ContactPhone,
    taxExempt,
    ...rest
  } = accountApplication;

  return {
    ...rest,
    creditAmount: parseFloat(creditAmount),
    yearsInBusiness: parseFloat(yearsInBusiness),
    ref1_business_name: ref1BusinessName,
    ref1_contact_email: ref1ContactEmail,
    ref1_contact_phone: ref1ContactPhone,
    ref2_business_name: ref2BusinessName,
    ref2_contact_email: ref2ContactEmail,
    ref2_contact_phone: ref2ContactPhone,
    ref3_business_name: ref3BusinessName,
    ref3_contact_email: ref3ContactEmail,
    ref3_contact_phone: ref3ContactPhone,
    taxExempt: taxExempt && isValidFile(taxExempt[0]) ? taxExempt : undefined,
  };
}
