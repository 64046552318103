import React, { FunctionComponent } from 'react';
import HelpProvider from './Help';

const AppProviders: FunctionComponent = (props) => {
  const { children } = props;

  return (
    <HelpProvider>
      {children}
    </HelpProvider>
  );
};

export default AppProviders;
