import { useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import useGetList from 'hooks/base/get-list';
import { UserModel } from 'models/user';
import userApi from 'api/user';
import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import {
  setFormValue,
  setValidationErrors,
  reuseQuote,
} from './actions';
import reuseQuoteFormReducer from './reducer';

export default function useReuseQuoteFormState(quoteId: number, repOwner: string) {
  const { push } = useHistory();
  const [state, dispatch] = useReducer(
    reuseQuoteFormReducer,
    {
      formData: {
        comments: '',
        jobTitle: '',
        repOwner,
        client: undefined,
      },
      errors: {},
      coping: false,
    },
  );

  const { formData } = state;

  const {
    fetching: fetchingClients,
    data: clients,
    error: clientError
  } = useGetList<UserModel>(userApi.getAccountUsers);

  useEffect(() => {
    if (clientError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(clientError),
      });
    }
  }, [clientError]);

  const clientOptions = useMemo(() => {
    return clients.map((client) => ({
      label: `${client.firstName} ${client.lastName}`,
      value: client.cognitoId,
    }));
  }, [clients]);

  return {
    state: {
      ...state,
      fetchingClients,
      clientOptions,
    },
    actions: {
      setFormValue: setFormValue(dispatch),
      setValidationErrors: setValidationErrors(dispatch),
      reuseQuote: reuseQuote(dispatch, formData, quoteId, push),
    },
  };
}
