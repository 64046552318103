import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QuoteModel } from 'models/quote';
import quoteApi from 'api/quote';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import { isDealerLoggedUser } from 'helpers/get-logged-user';
import printQuote from './actions/print-quote';
import openSendEmailModal from './actions/open-send-email';
import openCopyQuoteModal from './actions/open-copy-quote';

const getQueryParams = (showAll, isDealer) => {
  const result = {} as any;
  if (!showAll) {
    result.isconverted = false;
  }
  if (isDealer) {
    result.project__client = isDealer;
  }
  return result;
};

export default function useQuoteListState() {
  const { push, location: { search } } = useHistory();

  const [showAll, setShowAll] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const isDealer = useMemo(() => isDealerLoggedUser(true), []);

  const tableStateParams = useMemo<UseTableStateParams>(() => ({
    api: quoteApi,
    queryParams: getQueryParams(showAll, isDealer),
    deleteModalTitle: 'Delete Quote',
    deleteModalContent: (quoteToDelete: QuoteModel) => (
      `Are you sure that you want to delete
      the quote ${quoteToDelete ? quoteToDelete.id : ''}?`
    ),
    searchFields: ['comments', 'id', 'jobTitle'],
    eventChannel: EventChannelList.QUOTES_LIST_CHANGED,
    defaultSortField: '-id',
    reference: 'quotes'
  }), [showAll, isDealer]);

  const {
    state: tableState,
    actions: tableActions,
  } = useTableState<QuoteModel>(tableStateParams);

  return {
    state: {
      ...tableState,
      isPrinting,
      showAll,
      isDealer,
    },
    actions: {
      ...tableActions,
      hiddenAction: useCallback(() => !!isDealer, [isDealer]),
      toggleShowAll: useCallback(() => setShowAll(!showAll), [showAll]),
      openCopyQuoteModal: useCallback(openCopyQuoteModal(push, search), [push, search]),
      openSendEmail: useCallback(openSendEmailModal(push, false, search), [push, search]),
      openSendProposalEmail: useCallback(openSendEmailModal(push, true, search), [push, search]),
      printQuote: useCallback(printQuote(quoteApi.print, setIsPrinting), []),
      printQuoteProposal: useCallback(
        printQuote(quoteApi.printProposal, setIsPrinting),
        [],
      ),
    },
  };
}
