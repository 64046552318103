import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FlowPlannerModel } from 'models/flow-planner';
import flowPlannerApi from 'api/flow-planner';
import { EventChannelList } from 'helpers/event-center';
import { isDealerLoggedUser } from 'helpers/get-logged-user';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import Page, { PageActions } from 'components/base-components/Page';
import Button from 'components/base-components/Button';
import DataTable, { DataTableColumn } from 'components/base-components/DataTableV2';

const FlowPlannerList: FunctionComponent = () => {
  const { push } = useHistory();
  const tableStateParams = useMemo<UseTableStateParams>(() => ({
    api: flowPlannerApi,
    queryParams: { client: isDealerLoggedUser(true) },
    deleteModalTitle: 'Delete Design',
    deleteModalContent: (flowPlanner: FlowPlannerModel) => (
      `Are you sure that you want to delete the design for ${flowPlanner.projectName}?`
    ),
    eventChannel: EventChannelList.FLOW_PLANNER_LIST_CHANGED,
    defaultSortField: '-id',
    searchFields: ['project_name', 'account', 'modified_date']
  }), []);

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter
    }
  } = useTableState<FlowPlannerModel>(tableStateParams);

  const openFormModal = (flowPlanner: FlowPlannerModel | any) => {
    const navigationState = {
      flowPlannerForm: {
        flowPlanner: flowPlanner.id
          ? {
            ...flowPlanner,
            plan: JSON.stringify(flowPlanner.plan)
          }
          : undefined
      },
    };
    push('/builder', navigationState);
  };

  return (
    <Page title="Project Design">
      <PageActions primary>
        <Button
          onClick={openFormModal}
          label="Add Design"
        />
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn
          title="Project"
          dataIndex="projectName"
          columnKey="project_name"
          sorter
        />
        <DataTableColumn
          title="Account"
          dataIndex="account"
          columnKey="account"
          sorter
        />
        <DataTableColumn
          title="Modified Date"
          dataIndex="modifiedDate"
          columnKey="modified_date"
          isDate
          sorter
        />
        <DataTableColumn
          isActionColumn
          onShowDetails={openFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default FlowPlannerList;
