import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import './styles.scss';

interface Props {
  onDrop: (event) => any;
  onDragOver?: (event) => any;
  onDragLeave?: (event) => any;
  onDragEnter?: (event) => any;
  className?: string;
  mayBeEmpty?: boolean;
}

const preventDefault = (e) => { e.preventDefault(); };

const DropZone: FunctionComponent<Props> = (props) => {
  const { className, onDragLeave, children, onDragOver, onDrop, onDragEnter, mayBeEmpty } = props;
  const [dragging, setDragging] = useState(false);
  const wrapDragClassName = useMemo(
    () => classnames('dropZone', { dragging, isEmpty: mayBeEmpty }, className),
    [dragging, className, mayBeEmpty]
  );

  const onOver = useCallback((e) => {
    setDragging(true);
    preventDefault(e);
    if (onDragOver) {
      onDragOver(e);
    }
  }, [onDragOver]);

  const onEnter = useCallback((e) => {
    setDragging(false);
    preventDefault(e);
    if (onDragEnter) {
      onDragEnter(e);
    }
  }, [onDragEnter]);

  const onLeave = useCallback((e) => {
    setDragging(false);
    preventDefault(e);
    if (onDragLeave) {
      onDragLeave(e);
    }
  }, [onDragLeave]);

  const onDropFunction = useCallback((e) => {
    setDragging(false);
    onDrop(e);
  }, [onDrop]);

  return (
    <div
      onDrop={onDropFunction}
      onDragOver={onOver}
      onDragEnter={onEnter}
      onDragLeave={onLeave}
      className={wrapDragClassName}
    >
      {children}
    </div>
  );
};

export default DropZone;
