import { AccountModel } from 'models/account';
import { QuestionnaireActions } from '../reducer';

function getStreetInfo(address: string) {
  const parts = address.split(' ');

  if (!isNaN(parseInt(parts[0], 10))) {
    return {
      streetNumber: parseInt(parts[0], 10),
      address: parts.slice(1).join(' '),
    };
  }

  return { streetNumber: undefined, address };
}

export default function copyAccountAddress(dispatch, account: AccountModel) {
  return () => {
    const {
      businessAddress,
      businessCity,
      businessPostCode,
      businessTradeName,
    } = account;
    const { streetNumber, address } = getStreetInfo(businessAddress);

    dispatch({
      type: QuestionnaireActions.SET_ADDRESS_INFO,
      payload: {
        locationName: businessTradeName,
        streetNumber,
        streetName: address,
        unitNumber: undefined,
        postalCode: businessPostCode,
        city: businessCity,
        province: undefined,
      },
    });
  };
}
