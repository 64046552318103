import { AxiosResponse } from 'axios';
import { ShipmentDTO } from 'models/shipment';
import { resolveApiErrorMessage } from 'api/base';
import shipmentApi from 'api/shipment';
import notification, { NotificationType } from 'helpers/notification';
import { ConvertToOrderProps } from '../..';
import { ConvertToOrderActions } from '../reducer';

export default function openShipmentDQ(
  dispatch,
  shipment: ShipmentDTO,
  props: ConvertToOrderProps,
) {
  return () => {
    dispatch({ type: ConvertToOrderActions.START_FETCHING_HASH });

    const { onSubmit } = props;

    const onSuccess = (response: AxiosResponse<string>) => {
      const { data: hash } = response;
      const baseUrl = window.location.origin;

      window.open(
        `${baseUrl}/questionnaire?cli=${hash}&notify=true`,
        '_blank',
        'resizable,scrollbars'
      );
      window.addEventListener('message', (event) => {
        const { data } = event;

        if (data && data.source && data.source === 'dq') {
          onSubmit();
        }
      });
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const onFinally = () => {
      dispatch({ type: ConvertToOrderActions.STOP_FETCHING_HASH });
    };

    return shipmentApi
      .getShipmentHash(shipment.id)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
