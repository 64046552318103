import { useCallback, useMemo } from 'react';
import { EventChannelList } from 'helpers/event-center';
import { useHistory } from 'react-router-dom';
import { PurchaseOrderItemDTOModel, PurchaseOrderModel } from 'models/order';
import purchaseOrderItemApi from 'api/purchase-order-item';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import openFormModal from '../state/actions/open-form-modal';

export default function usePOIDataTableState(
  purchaseOrderId: string, fetchingPurchaseOrder: boolean, purchaseOrder: PurchaseOrderModel, pageSize: number
) {
  const history = useHistory();

  const useTableStateParams: UseTableStateParams = useMemo(() => ({
    api: purchaseOrderItemApi,
    deleteModalTitle: 'Delete Purchase Order Item',
    deleteModalContent: () => 'Are you sure that you want to delete this purchase order item?',
    eventChannel: EventChannelList.PURCHASE_ORDER_ITEMS_LIST_CHANGED,
    queryParams: { purchaseOrder: purchaseOrderId },
    preventAutoFetch: !purchaseOrderId,
    pageSize,
    defaultSortField: '-id',
  }), [purchaseOrderId, pageSize]);

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
    }
  } = useTableState<PurchaseOrderItemDTOModel>(useTableStateParams);

  return {
    state: {
      data: useMemo(() => {
        if (purchaseOrder?.order) {
          return data.map(item => {
            return {
              ...item,
              orderProduct: purchaseOrder.order?.quote?.orderproducts?.find(oP => oP.id === item.orderProduct),
            };
          });
        }
        return data;
      }, [purchaseOrder, data]),
      fetching: fetching || fetchingPurchaseOrder,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
      openFormModal: useCallback(
        openFormModal(history, purchaseOrderId),
        [history, purchaseOrderId],
      ),
    },
  };
}
