import React, { FunctionComponent, useMemo } from 'react';

interface Props {
  hidden: boolean;
  onDomLoaded: (ref: any) => void;
}

const SceneViewer: FunctionComponent<Props> = (props) => {
  const { hidden, onDomLoaded } = props;
  const styles = useMemo(() => ({
    display: hidden ? 'none' : 'inherit',
    width: '100%',
    height: '100%',
  }), [hidden]);

  return (
    <div style={styles}>
      <div
        style={{ width: '100%', height: '100%' }}
        ref={ref => onDomLoaded(ref)}
      />
    </div>
  );
};

export default SceneViewer;
