import projectCoordinatorApi from 'api/project-coordinator';
import { EventChannelList } from 'helpers/event-center';

export const helpDescription = 'this view have join data and actions from different views';

export const columnOptions = [
  { value: 'id', label: 'Order' },
  { value: 'account', label: 'Company' },
  { value: 'job_name', label: 'Job Title' },
  { value: 'rep', label: 'Rep. Owner' },
  { value: 'production_status', label: 'Shipping' },
  { value: 'status', label: 'Status' },
  { value: 'ordered', label: 'Ordered' },
  { value: 'received', label: 'Received' },
  { value: 'installer', label: 'Installer' },
  { value: 'inst_date', label: 'Inst. Date' },
  { value: 'ready_date', label: 'Ready Date' },
  { value: 'task', label: 'Task' },
  { value: 'paid', label: 'Paid' },
  { value: 'outst', label: 'Outstanding' },
  { value: 'total', label: 'Total' },
];

export const breakpoints = {
  status: 1800,
  materials: 1350,
  ordered: 1350,
  received: 1350,
  shipping: 1600,
  installer: 1600,
  inst_date: 1830,
  ready_date: 1930,
  paid: 2010,
  outst: 2109,
  total: 2232,
  id: 0,
  account: 0,
  rep: 0,
  job_name: 0,
  production_status: 0,
  task: 0,
  job_info: 0,
};

export const parentColumns = {
  job_info: ['id', 'account', 'rep', 'job_name', 'production_status', 'status'],
  materials: ['ordered', 'received'],
  shipping: ['installer', 'ready_date', 'inst_date'],
};

export const tableStateParams = {
  api: projectCoordinatorApi,
  eventChannel: EventChannelList.ORDER_PAYMENT_DONE,
  defaultSortField: '-id',
};

export const mapOptions = { fields: { value: 'id', label: 'status' } };
