import React, { FunctionComponent, useMemo } from 'react';
import { Col, Row } from 'antd';
import Page from 'components/base-components/Page';
import Card from 'components/base-components/Card';
import Tree from 'components/base-components/Tree';
import Help from 'components/forms/CommonHelp';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import ProductViewer from 'components/base-components/ProductViewer';
import PropertiesPanel from 'components/base-components/PropertiesPanel';
import Button from 'components/base-components/Button';
import useCategoryImageFormState from './state';
import './styles.scss';

const helpDescription = 'This view is to save an image of a Category of the 3D models of the system';

const CategoriesList: FunctionComponent = () => {
  const {
    state: {
      categories,
      selectedProduct,
      fetchingProductData,
      loadingCategories,
      image,
      stageRef,
      saving
    },
    actions: {
      onStyleChange,
      updateProperties,
      updateOptions,
      takePic,
      saveImage
    }
  } = useCategoryImageFormState();

  const disabledSave = useMemo(() => !image || image.includes('http'), [image]);

  return (
    <Page title="Save Category Image">
      <Help view="category-image" title="Save Category Image" description={helpDescription} />
      <Row justify="space-between">
        <Col span={4}>
          <Card className="category-image__card">
            <RenderIf isTrue={!loadingCategories} fallback={<Spinner />}>
              <Tree treeData={categories} onSelect={onStyleChange} />
            </RenderIf>
          </Card>
        </Col>
        <Col span={5}>
          <Card className="category-image__card">
            <PropertiesPanel
              blockUI={fetchingProductData}
              product={selectedProduct}
              onOptionsChange={updateOptions}
              onPropertiesChange={updateProperties}
            />
          </Card>
        </Col>
        <Col span={9}>
          <Card className="category-image__card">
            <div className="category-image__image-panel">
              <div className="category-image__product-viewer-aspect-ratio">
                <div className="category-image__product-viewer-wrapper">
                  <ProductViewer blockUI={fetchingProductData} product={selectedProduct} externalRef={stageRef} />
                </div>
              </div>
              <Button
                id="takePic"
                disabled={!selectedProduct}
                icon="fa fa-camera"
                label="Take Pic"
                onClick={takePic}
                mT
              />
            </div>
          </Card>
        </Col>
        <Col span={5}>
          <Card className="category-image__card">
            <div className="category-image__image-panel">
              <div className="category-image__no-image-wrap">
                <RenderIf isTrue={!!image}>
                  <img className="category-image__image" src={image} alt="product-preview" />
                </RenderIf>
              </div>
              <Button id="save" label="Save" disabled={disabledSave} onClick={saveImage} mT loading={saving} />
            </div>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default CategoriesList;
