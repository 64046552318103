import React, { FunctionComponent } from 'react';
import { Switch as AntdSwitch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import Label from 'components/base-components/Label';

interface Props extends SwitchProps {
  label?: string;
  reverse?: boolean;
}

const Switch: FunctionComponent<Props> = (props) => {
  const { label, reverse, className, ...rest } = props;
  const isReverse = reverse ? '-reverse' : '';

  return (
    <div className={`d-flex flex-row${isReverse} align-items-end ${className}`}>
      <AntdSwitch {...rest} className="m-1" />
      <Label text={label} />
    </div>
  );
};

Switch.defaultProps = {
  reverse: false,
};

export default Switch;
