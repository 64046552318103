import { validateEntity } from 'helpers/form-validations';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { validationRules } from '../validations';
import { TutorialFormActions, TutorialFormModel } from '../reducer';
import getPayload from './get-payload';
import tutorialsApi from '../../../../../../api/tutorials';

export default function saveTutorial(
  dispatch,
  tutorial: TutorialFormModel,
  goBack,
) {
  return async () => {
    dispatch({ type: TutorialFormActions.START_SAVING_TUTORIAL });

    const {
      hasErrors: modelHasErrors,
      errors: modelErrors,
    } = validateEntity(tutorial, validationRules);

    if (modelHasErrors) {
      return dispatch({
        type: TutorialFormActions.SET_ERRORS,
        payload: modelErrors,
      });
    }

    const onSuccess = () => {
      const message = tutorial.id
        ? 'Tutorial updated.'
        : 'Tutorial created.';

      notifyEventChannel(EventChannelList.THREE_MODEL_LIST_CHANGED);
      notification({
        type: NotificationType.SUCCESS,
        message,
      });
      goBack();
    };

    const onError = () => {
      const message = tutorial.id
        ? 'Tutorial failed to update.'
        : 'Tutorial failed to create.';

      notification({
        type: NotificationType.ERROR,
        message,
      });
    };

    const onFinally = () => {
      dispatch({ type: TutorialFormActions.FINISH_SAVING_TUTORIAL });
    };

    const payload = await getPayload(tutorial);
    const action = tutorial.url
      ? tutorialsApi.patch
      : tutorialsApi.create;

    return action(payload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
