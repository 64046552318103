import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import subOptionApi from 'api/sub-option';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import RenderIf from 'components/base-components/RenderIf';
import Select from 'components/base-components/Select';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import useEditOptionsState from './state';

export interface EditOptionsHistorySateModel {
  editOptionsForm: {
    order: any;
    discount: number;
  };
}
const optionMapDropdown = { fields: { value: 'name', label: 'name' }, copyFullItem: true };
const mapSubOptions = (subOptions) => mapGenericDropdownOptions(subOptions, optionMapDropdown);

export default function EditOptions() {
  const { goBack, location: { state: stateNavigation } } = useHistory();
  const { editOptionsForm: { order, discount } } = stateNavigation as EditOptionsHistorySateModel;
  const {
    state: {
      formData,
      fetchingOptionGroups,
      editing,
      productOptions,
    },
    actions: {
      openCustomOptionModal,
      handleValueChange,
      handleOnSubmit,
    },
  } = useEditOptionsState(order, discount);

  const productOptionsSelect = useMemo(() => (
    productOptions && productOptions.map(productOption => {
      const hasSubOptions = formData[productOption.name]?.dropdownItem?.haveSuboptions;
      const filters = {
        filters: {
          option__name: formData[productOption.name]?.value,
        }
      };
      return (
        <>
          <Field
            key={productOption.name}
            component={Select}
            name={productOption.name}
            id={productOption.name}
            label={productOption.name}
            options={[{ label: 'None', value: null }, ...mapGenericDropdownOptions(productOption.option, optionMapDropdown)]}
            addButtonLabel="Add custom option"
            onAddClick={() => openCustomOptionModal(productOption.name)}
            checkNewValue
          />
          <RenderIf isTrue={hasSubOptions}>
            <Field
              name={`sub-${productOption.name}`}
              id={`sub-${productOption.name}`}
              label={`${formData[productOption.name]?.label} Options`}
              component={AsyncSelectV2}
              listAction={subOptionApi.list}
              mapFunction={mapSubOptions}
              preventAutoFetch={!hasSubOptions}
              filters={filters}
            />
          </RenderIf>
        </>
      );
    })
  ), [productOptions, formData, openCustomOptionModal]);

  return (
    <Modal
      visible
      title="Edit Product Options"
      okText="Submit"
      onCancel={goBack}
      onOk={handleOnSubmit}
      confirmLoading={editing}
    >
      <RenderIf isTrue={!fetchingOptionGroups} fallback={<Spinner centered />}>
        <Form state={formData || {}} onChange={handleValueChange}>
          {productOptionsSelect}
        </Form>
      </RenderIf>
    </Modal>
  );
}
