import { DropdownOptionModel } from 'helpers/dropdown-options';
import { Moment } from 'moment';

export enum PaymentFormActions {
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_ERRORS = 'SET_ERRORS',
  SET_AMOUNT = 'SET_AMOUNT',
  START_SAVING_PAYMENT = 'START_SAVING_PAYMENT',
  FINISH_SAVING_PAYMENT = 'FINISH_SAVING_PAYMENT',
}

export interface PaymentFormModel {
  type: DropdownOptionModel;
  name: string;
  amount: any;
  comments: string;
  chequeNumber: number;
  checkDate: Moment;
  receivedDate: Moment;
  front: any;
  back: any;
}

export interface PaymentFormState {
  payment: PaymentFormModel;
  savingPayment: boolean;
  errors: any;
}

export default function paymentFormReducer(state: PaymentFormState, action): PaymentFormState {
  const { type, payload } = action;

  switch (type as PaymentFormActions) {
    case PaymentFormActions.SET_AMOUNT:
      return { ...state, payment: { ...state.payment, amount: payload } };
    case PaymentFormActions.SET_FORM_VALUE:
      return { ...state, payment: payload };
    case PaymentFormActions.SET_ERRORS:
      return { ...state, errors: payload, savingPayment: false };
    case PaymentFormActions.START_SAVING_PAYMENT:
      return { ...state, savingPayment: true };
    case PaymentFormActions.FINISH_SAVING_PAYMENT:
      return { ...state, savingPayment: false };
    default:
      return state;
  }
}
