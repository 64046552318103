import React from 'react';
import ReactTooltip from 'react-tooltip';

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface iButton {
  font: string;
  tooltip: string;
  toggled?: boolean;
  label?: string;
  fontStyle?: any;
  callback?: () => void;
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface iProps {
  onClickAddSet?: () => void;
}

function LeftToolbar(props: iProps) {
  const { onClickAddSet } = props;
  const buttons: iButton[] = [
    {
      font: 'fas fa-cart-plus',
      tooltip: 'Select Set',
      callback: onClickAddSet,
    },
    null,
  ];

  return (
    <div className="set-group-builder__left-toolbar-container">
      <div className="set-group-builder__left-toolbar">
        {buttons.map((button: iButton, index: number) => {
          if (!button) return <div key={index} className="hr" />;
          return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              key={index}
              className={`left-toolbar-button ${
                button.toggled ? 'toggled' : ''
              }`}
              data-tip={button.tooltip}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                typeof button.callback === 'function' && button.callback();
              }}
            >
              <span className={button.font} style={button.fontStyle}>
                {button.label}
              </span>
            </div>
          );
        })}
      </div>
      <ReactTooltip />
    </div>
  );
}

export default LeftToolbar;
