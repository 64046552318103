import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import useCallApiAction from 'hooks/base/call-api-action';
import useDebounce from 'hooks/ui/use-debounce';
import handleOnSearch from './actions/handle-on-search';
import handleOnClear from './actions/handle-on-clear';
import getNextPage from './actions/get-next-page';
import asyncSelectReducer, { AsyncSelectActions, AsyncSelectState } from './reducer';

const initialState: AsyncSelectState = {
  page: 1,
  searchValue: '',
  searchTerm: '',
  filters: {},
  preventAutoFetch: false,
  data: [],
  options: [],
};

export default function useAutoCompleteState(
  listAction,
  mapFunction,
  filters,
  preventAutoFetch,
) {
  const { debounceCall } = useDebounce(250);
  const [state, dispatch] = useReducer(asyncSelectReducer, { ...initialState, filters, preventAutoFetch });
  const {
    page,
    data,
    searchTerm,
    searchValue,
    options,
    filters: innerFilters,
    preventAutoFetch: innerPreventAutoFetch,
  } = state;

  const params = useMemo(() => {
    const { search, ...otherFilters } = innerFilters;
    return {
      filters: {
        ...otherFilters,
        page,
        search: search ? [search, searchTerm] : searchTerm,
      },
    };
  }, [page, searchTerm, innerFilters]);

  const {
    fetching,
    data: { results, count },
    callAction,
  } = useCallApiAction(
    listAction,
    params,
    { results: [], count: 0 },
    innerPreventAutoFetch,
  );

  const refetchData = useCallback(() => {
    callAction(params);
  }, [callAction, params]);

  useEffect(() => {
    dispatch({ type: AsyncSelectActions.SET_FILTERS, payload: filters });
  }, [filters]);

  useEffect(() => {
    dispatch({ type: AsyncSelectActions.SET_PREVENT, payload: preventAutoFetch });
  }, [preventAutoFetch]);

  useEffect(() => {
    dispatch({
      type: page === 1
        ? AsyncSelectActions.SET_DATA
        : AsyncSelectActions.PUSH_DATA,
      payload: results,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  useEffect(() => {
    dispatch({
      type: AsyncSelectActions.SET_OPTIONS,
      payload: mapFunction(data),
    });
  }, [mapFunction, data]);

  useEffect(() => {
    const updateSearch = () => dispatch({
      type: AsyncSelectActions.SET_SEARCH_TERM,
      payload: searchValue,
    });

    debounceCall(updateSearch);
  }, [debounceCall, searchValue]);

  useEventCenterUpdate(EventChannelList.ASYNC_SELECT_REFETCH, refetchData);

  return {
    state: {
      options,
      loading: fetching,
      keepFetching: useMemo(() => (page * 10) < count, [page, count]),
    },
    actions: {
      handleOnSearch: useCallback(handleOnSearch(dispatch), []),
      handleOnClear: useCallback(handleOnClear(dispatch), []),
      getNextPage: useCallback(getNextPage(dispatch), []),
    },
  };
}
