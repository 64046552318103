import { commonRules, RuleType } from 'helpers/form-validations';
import { TextureModel } from './reducer';

const { required } = commonRules;
const minRule = { type: RuleType.Min, value: 0, message: 'The width can not be 0' };

function validateHeight(width, texture: TextureModel) {
  const { widthRatio } = texture;
  const height = width / widthRatio;

  if (height < 1) {
    return 'This value generates a height that is invalid';
  }
  return undefined;
}

export const validationRules = {
  option: [required],
  width: [required, minRule, validateHeight],
};
