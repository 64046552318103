/* eslint-disable */
import * as THREE from 'three';

export const IgnorableMeshNames = ['grommet', 'glide', 'handle', 'Box001'];
export const strVerticalBlockExtended = '-ver-arr-ext-';
export const strHorizontalBlockExtended = '-hor-arr-ext-';
export const colorPresets = {
  "black": 0x121212,
  "silver": 0xeeeeee,
  "nickel": 0xeeee66,
}

export const getRealMorphValue = (object, index, value) => {
  const minStrRegex = /\(min(\d+)\)/g;
  const matches = minStrRegex.exec(object.name);
  if (matches && matches[1]) {
    const min = parseInt(matches[1]);
    const tmp = 5 + value * (300 - 5);
    value = (tmp - min) / (300 - min);
  }
  return value;
}

export const getCenterOfPolygon = (points) => {
  let center = new THREE.Vector3();
  points.forEach(point => center = center.add(point));
  center = center.multiplyScalar(1 / points.length);
  return center;
}

export const getBounding = (mesh, meshList) => {
  let minX = +Infinity;
  let minY = +Infinity;
  let minZ = +Infinity;

  let maxX = -Infinity;
  let maxY = -Infinity;
  let maxZ = -Infinity;

  let meshes = [];
  if (mesh) meshes = mesh.children;
  if (Array.isArray(meshList) && meshList.length) meshes = meshList;
  for (const item of meshes) {
    if (!item.visible) continue;
    let skippable = false;
    IgnorableMeshNames.forEach(str => {
      if (item.name.toLowerCase().includes(str)) skippable = true;
    })
    if (skippable) continue;

    const vector = [];
    item.geometry.attributes.position.array.forEach(item => vector.push(item));
    try {
      if (Array.isArray(item.morphTargetInfluences)) {
        const morphingCount = item.morphTargetInfluences.length;
        for (var i = 0; i < morphingCount; i++) {
          const targetVector = item.geometry.morphAttributes.position[i].array;
          for (let j = 0; j < vector.length; j++) {
            vector[j] = vector[j] + (targetVector[j]) * item.morphTargetInfluences[i];
          }
        }
      }
    } catch (_) { }

    const position = new THREE.Vector3().copy(item.position);
    const transform = new THREE.Matrix4();
    if (mesh && mesh.rotation && mesh.rotation.y) transform.makeRotationY(mesh.rotation.y);
    position.applyMatrix4(transform);

    for (i = 3; i < vector.length; i += 3) {
      vector[i] += position.x;
      vector[i + 1] += position.y;
      vector[i + 2] += position.z;
      if (minX > vector[i]) minX = vector[i];
      if (minY > vector[i + 1]) minY = vector[i + 1];
      if (minZ > vector[i + 2]) minZ = vector[i + 2];

      if (maxX < vector[i]) maxX = vector[i];
      if (maxY < vector[i + 1]) maxY = vector[i + 1];
      if (maxZ < vector[i + 2]) maxZ = vector[i + 2];
    }
  }

  return {
    min: (new THREE.Vector3(minX, minY, minZ)).add(mesh.position),
    max: new THREE.Vector3(maxX, maxY, maxZ).add(mesh.position),
  };
}

export const updateUV = (morphData, meshList, morphUVs) => {
  for (const child of meshList) {
    child.geometry.attributes.uv.needsUpdate = true;
    const uv = child.geometry.attributes.uv.array;
    const originUV = child.geometryBackup.attributes.uv.array;

    for (var v = 0; v < originUV.length; v++) uv[v] = originUV[v];

    for (const morphIndex in morphData) {
      if (!morphUVs[morphIndex]) continue;
      let name = child.name;
      if (name.includes(strVerticalBlockExtended)) name = name.split(strVerticalBlockExtended)[0];
      if (name.includes(strHorizontalBlockExtended)) name = name.split(strHorizontalBlockExtended)[0];

      if (!Array.isArray(morphUVs[morphIndex][name])) continue;

      if (morphUVs[morphIndex][name].length === uv.length) {
        const morphValue = getRealMorphValue(child, morphIndex, morphData[morphIndex]);
        const targetUV = morphUVs[morphIndex][name];
        for (v in uv) {
          uv[v] += (targetUV[v] - originUV[v]) * morphValue;
        }
      }
    }
  }
}

export const getSnapPoints = (position, mesh, skipDiffHeight) => {
  const corners = [];
  const threshold = 0.005;
  for (const item of mesh.children) {
    let skippable = false;
    IgnorableMeshNames.forEach(str => {
      if (item.name.toLowerCase().includes(str)) skippable = true;
    })
    if (skippable) continue;
    const vector = [];
    item.geometry.attributes.position.array.forEach(item => vector.push(item));
    try {
      if (Array.isArray(item.morphTargetInfluences)) {
        const morphingCount = item.morphTargetInfluences.length;
        for (var i = 0; i < morphingCount; i++) {
          const targetVector = item.geometry.morphAttributes.position[i].array;
          for (let j = 0; j < vector.length; j++) {
            vector[j] = vector[j] + (targetVector[j]) * item.morphTargetInfluences[i];
          }
        }
      }
    } catch (_) { }

    for (i = 3; i < vector.length; i += 3) {
      const vec = new THREE.Vector3(vector[i], 0, vector[i + 2])
      let isDuplicated = false;
      if (skipDiffHeight) {
        for (var corner of corners) {
          if (corner.distanceTo(vec) < threshold) {
            isDuplicated = true;
            break;
          }
        }
      }

      !isDuplicated && corners.push(vec);
    }
  }
  position = position || mesh.position;

  const transform = new THREE.Matrix4();
  transform.makeRotationY(mesh.rotation.y);

  const offset = mesh.centerOffset.clone();
  offset.applyMatrix4(transform);

  for (var corner of corners) {
    corner.applyMatrix4(transform);
    corner.add(position);
    corner.add(offset);
  }

  return corners.map(corner => {
    return { x: corner.x, y: corner.z }
  });
}