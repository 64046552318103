import { useCallback, useRef } from 'react';

export default function useDebounce(countDown) {
  const timerRef = useRef(undefined);

  const debounceCall = useCallback((callback) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
    }
    timerRef.current = setTimeout(callback, countDown);
  }, [countDown]);

  return { timer: timerRef.current, debounceCall };
}
