import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { CalendarEventModel } from 'models/calendar-event';
import calendarEventApi from 'api/calendar-event';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import useCallApiAction from 'hooks/base/call-api-action';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { capitalizeFirstLetter, formatDateTimeToString } from 'helpers/formatting';
import { getLoggedUserId } from 'helpers/get-logged-user';
import { validationRules } from './validations';

const getOptionInitialValue = {
  actions: {
    POST: {
      event_type: { choices: [] },
      status: { choices: [] },
    }
  }
};

const defaultEvent = {
  scheduled: moment().set({ hour: 9, minute: 0, second: 0 }),
  details: '',
  completed: false,
  title: '',
  eventType: { value: 'meeting', label: 'Meeting' },
};

const getPayload = (event) => ({
  ...event,
  eventType: event.eventType.value,
  scheduled: formatDateTimeToString(event.scheduled),
  createdById: getLoggedUserId(),
});

const getFormData = (setDate) => {
  return (event?: CalendarEventModel) => {
    if (event) {
      return {
        ...event,
        eventType: { value: event.eventType, label: capitalizeFirstLetter(event.eventType) },
        scheduled: moment(event.scheduled)
      };
    }
    return setDate ? { ...defaultEvent, scheduled: moment(setDate) } : defaultEvent;
  };
};

export default function useCustomEventFormState(eventId: string, setDate) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: calendarEventApi,
    eventChannel: EventChannelList.CUSTOM_EVENT_LIST_CHANGED,
    param: eventId,
    getPayload,
    getFormData: getFormData(setDate),
    validationRules
  }), [eventId, setDate]);

  const {
    data: options,
    fetching: fetchingOptions,
  } = useCallApiAction(calendarEventApi.getOptions, undefined, getOptionInitialValue);

  const {
    state: {
      formData,
      errors,
      saving,
      errorFetchingEntity,
      fetchingEntity,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<CalendarEventModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingEntity) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingEntity,
      });
    }
  }, [errorFetchingEntity]);

  return {
    state: {
      formData,
      saving,
      errors,
      fetchingEntity: fetchingEntity || fetchingOptions,
      eventTypesOptions: useMemo(() => options.actions.POST.event_type.choices.map(action => (
        { value: action.value, label: capitalizeFirstLetter(action.value) })), [options]),
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  };
}
