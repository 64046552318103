import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row, Card, CardBody, Alert } from 'reactstrap';
import profile from 'assets/images/profile-img.png';
import logo from 'assets/images/tfg-logo.svg';
import { forgetUserConfirmation } from 'store/actions';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Button from 'components/base-components/Button';

const ForgetPasswordConfirmation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { username, forgetError, forgetSuccessMsg } = useSelector((state: any) => ({
    username: state.forgetPassword.username,
    forgetError: state.forgetPassword.forgetError,
    forgetSuccessMsg: state.forgetPassword.forgetSuccessMsg
  }));

  const [state, setState] = useState({
    code: '',
    password: ''
  });

  const handleSubmit = () => dispatch(forgetUserConfirmation({ ...state, username }, history));

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Confirm New Password!</h5>
                      <p>Sign in to continue to ERP.</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img src={logo} alt="" className="rounded-circle" height="34" />
                    </span>
                  </div>
                </div>
                <div className="p-2">
                  {forgetError && (
                    <Alert color="danger" style={{ marginTop: '13px' }}>
                      {forgetError}
                    </Alert>
                  )}
                  {forgetSuccessMsg && (
                    <Alert color="success" style={{ marginTop: '13px' }}>
                      {forgetSuccessMsg}
                    </Alert>
                  )}
                  <Form onChange={setState} state={state}>
                    <Field name="code" label="Code" required />
                    <Field name="password" label="Password" required />
                  </Form>
                  <Button label="Submit" onClick={handleSubmit} />
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Go back to
                {' '}
                <Link to="login" className="font-weight-medium text-primary">
                  Login
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPasswordConfirmation;
