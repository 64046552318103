import { commonRules } from 'helpers/form-validations';

export const validationRules = {
  name: [commonRules.required],
  manufacturer: [commonRules.required],
  units: [commonRules.required],
  price: [commonRules.required],
  standardOrder: [commonRules.required],
  minOrder: [commonRules.required],
};
