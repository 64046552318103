import projectCoordinatorApi from 'api/project-coordinator';

const actions = {
  orderStatus: projectCoordinatorApi.setOrderStatus,
};

export default function onSubmit(data, dataIndex) {
  const index = Array.isArray(dataIndex) ? dataIndex[0] : dataIndex;
  const action = actions[index];
  return action(data.id, { [index]: data[index] });
}
