import React, { FunctionComponent } from 'react';
import { Transfer as AntdTransfer } from 'antd';
import { TransferProps } from 'antd/lib/transfer';

const Transfer: FunctionComponent<TransferProps<any>> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntdTransfer {...rest}>
      {children}
    </AntdTransfer>
  );
};

export default Transfer;
