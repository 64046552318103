import React, { FunctionComponent } from 'react';
import './styles.scss';

interface Props {
  error: string;
}

const InputError: FunctionComponent<Props> = (props) => {
  const { error } = props;

  if (error) {
    return <span className="input__error">{error}</span>;
  }

  return null;
};

export default InputError;
