import React, { FunctionComponent, useMemo } from 'react';
import hasPermissions from 'helpers/permissons';
import Page from 'components/base-components/Page';
import Card from 'components/base-components/Card';
import Button from 'components/base-components/Button';
import Select from 'components/base-components/Select';
import Spinner from 'components/base-components/Spinner';
import RenderIf from 'components/base-components/RenderIf';
import Form, { Field } from 'components/base-components/Form';
import NumberInput from 'components/base-components/NumberInput';
import ProductViewer from 'components/base-components/ProductViewer';
import Help from 'components/forms/CommonHelp';
import Empty, { PRESENTED_IMAGE_SIMPLE } from 'components/base-components/Empty';
import useSettingsState, { validationRules } from './state';
import { permissions } from './permissions';
import './styles.scss';

const noImage = (
  <Empty image={PRESENTED_IMAGE_SIMPLE} description="No image selected" />
);

const TextureSettingsPage: FunctionComponent = () => {
  const {
    state: {
      loading,
      options,
      texture,
      imageRef,
      imageLoaded,
      model,
      callingAPI,
      errors,
    },
    actions: {
      setTexture,
      setErrors,
      handleOptionChange,
      handleTextureImageLoad,
      handleWidthChange,
      saveOption,
    },
  } = useSettingsState();

  const disableSubmit = useMemo(() => (!texture.option ||
    texture.width < 1 ||
    texture.height < 1 ||
    !hasPermissions(permissions.editOption)),
  [texture]);

  if (loading) {
    return (
      <Page title="Texture Settings">
        <Spinner />
      </Page>
    );
  }

  return (
    <Page title="Texture Settings">
      <Help view="textures" title="Texture Settings" />
      <div className="texture-settings">
        <div className="texture-settings__block">
          <Card>
            <Form
              onChange={setTexture}
              state={texture}
              errors={errors}
              onError={setErrors}
              rules={validationRules}
            >
              <Field
                id="option"
                name="option"
                label="Option"
                component={Select}
                options={options}
                onChange={handleOptionChange}
              />
              <Field
                id="width"
                name="width"
                label="Texture Width"
                component={NumberInput}
                min={1}
                onChange={handleWidthChange}
                disabled={!texture.option}
              />
              <div className="texture-settings__height">
                <span>Texture height:</span>
                <span>{texture.height}</span>
              </div>
            </Form>
          </Card>
          <div className="texture-settings__preview_box">
            <RenderIf isTrue={!!texture.option?.image} fallback={noImage}>
              <Spinner />
            </RenderIf>
            <RenderIf isTrue={!!texture.option?.image}>
              <img
                className="texture-settings__preview"
                ref={imageRef}
                data-loaded={imageLoaded}
                onLoad={handleTextureImageLoad}
                src={texture.option?.image}
                alt="texture"
                crossOrigin="anonymous"
              />
            </RenderIf>
          </div>
          <div className="texture-settings__actions_box">
            <Button
              label="Submit"
              onClick={saveOption}
              loading={callingAPI}
              disabled={disableSubmit}
            />
          </div>
        </div>
        <div className="texture-settings__block">
          <ProductViewer product={model} />
        </div>
      </div>
    </Page>
  );
};

export default TextureSettingsPage;
