import snakeCaseKeys from 'snakecase-keys';
// eslint-disable-next-line @typescript-eslint/camelcase
import snake_case from 'lodash.snakecase';
import hasValue from 'helpers/has-value';
import { QueryParams } from 'helpers/query-params';
import getAxiosInstance from './get-instance';

export * from './models';

export enum ApiContentType {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
}

const axios = getAxiosInstance();

function generateData(data, contentType) {
  if (contentType === ApiContentType.JSON) {
    return JSON.stringify(snakeCaseKeys(data));
  }

  const formData = new FormData();
  Object.keys(data)
    .forEach((key) => {
      const value = data[key];
      if (hasValue(value)) {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            const payloadKey = /\d/.test(key) ? key : snake_case(key);
            formData.append(payloadKey, val);
          });
        } else {
          const payloadKey = /\d/.test(key) ? key : snake_case(key);
          formData.append(payloadKey, value);
        }
      }
    });
  return formData;
}

export function resolveApiErrorMessage(error?) {
  return error?.data && typeof error.data === 'object'
    ? Object.values(error.data).flat().join('. ')
    : 'Sorry, something went wrong';
}

export const get = async (url, params: QueryParams = undefined, headers = {}) => {
  return axios.get(url, { params, headers });
};

export const post = async (
  url,
  data,
  contentType = ApiContentType.JSON,
  headers = {},
) => {
  return axios.post(
    url,
    generateData(data, contentType),
    {
      headers: {
        'Content-Type': contentType,
        ...headers,
      },
    }
  );
};

export const barePost = async (
  url,
  data,
  contentType = ApiContentType.JSON,
  headers = {},
) => {
  return axios.post(
    url,
    data,
    {
      headers: {
        'Content-Type': contentType,
        ...headers,
      },
    }
  );
};

export const put = async (
  url,
  data,
  contentType = ApiContentType.JSON,
  headers = {},
) => {
  return axios.put(
    url,
    generateData(data, contentType),
    {
      headers: {
        'Content-Type': contentType,
        ...headers,
      },
    }
  );
};

export const patch = async (
  url,
  data,
  contentType = ApiContentType.JSON,
  headers = {},
) => {
  return axios.patch(
    url,
    generateData(data, contentType),
    {
      headers: {
        'Content-Type': contentType,
        ...headers,
      },
    }
  );
};

export const barePatch = async (
  url,
  data,
  contentType = ApiContentType.JSON,
  headers = {},
) => {
  return axios.patch(
    url,
    data,
    {
      headers: {
        'Content-Type': contentType,
        ...headers,
      },
    }
  );
};

export const del = async (url, headers = {}) => {
  return axios.delete(url, { headers });
};
