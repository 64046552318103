import { DropdownOptionModel } from 'helpers/dropdown-options';

export function getOrderPayload(formData) {
  const { product: { dropdownItem: product }, ...rest } = formData;
  const { outsidePaint, name: category, cnc } = product.category;
  const group = formData.group.value;

  return {
    ...product,
    ...rest,
    cnc,
    outsidePaint,
    manufacturer: product.manufacturer?.companyName,
    category,
    group,
  };
}

export function getOptionPayload(
  { value: optionName, dropdownItem: option }: DropdownOptionModel,
  optionType: string,
  orderProduct: number,
  subOption: DropdownOptionModel,
) {
  return {
    optionType,
    orderProduct,
    optionSelected: subOption?.value ? `${optionName}_${subOption?.value}` : optionName,
    description: option.description || '',
    descriptionFr: option.descriptionFr || '',
    code: subOption?.dropdownItem ? subOption?.dropdownItem?.code : option.code,
    brand: option.brand || '',
    userInput: option?.userInput || false,
    price: option.price,
    cost: option.cost,
    userComment: option.userComment || null,
    additionalCost: option.additionalCost || null,
  };
}
