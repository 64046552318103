import { TaskModel } from 'models/task';

export enum CalendarActions {
  SET_SCHEDULE = 'SET_SCHEDULE',
  OPEN_TASK_FORM = 'OPEN_TASK_FORM',
  CLOSE_TASK_FORM = 'CLOSE_TASK_FORM',
}

export interface Schedule {
  [date: string]: TaskModel[];
}

export interface CalendarState {
  schedule: Schedule;
  generatingSchedule: boolean;
  isShowTaskForm: boolean;
  task: TaskModel;
}

export default function calendarReducer(state: CalendarState, action): CalendarState {
  const { type, payload } = action;

  switch (type as CalendarActions) {
    case CalendarActions.SET_SCHEDULE:
      return {
        ...state,
        schedule: payload,
        generatingSchedule: false,
      };
    case CalendarActions.OPEN_TASK_FORM:
      return { ...state, isShowTaskForm: true, task: payload };
    case CalendarActions.CLOSE_TASK_FORM:
      return { ...state, isShowTaskForm: false };
    default:
      return state;
  }
}
