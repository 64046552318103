import { ThreeFormModel } from '../reducer';

export default async function getPayload(model: ThreeFormModel) {
  const {
    modelPosition,
    modelName,
    threeFileChanged,
    morphs: _morphs,
    initialMorphs: _initialMorphs,
    ...rest
  } = model;

  if (model.id) {
    const threeModelFile = threeFileChanged ? model.threeModel : undefined;

    return {
      id: model.id,
      modelName,
      threeModel: threeModelFile,
      modelPosition: modelPosition.value,
    };
  }

  return {
    ...rest,
    modelName,
    modelPosition: modelPosition.value,
  };
}
