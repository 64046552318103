import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { InputNumber as AntInputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import Label from 'components/base-components/Label';
import InputError from 'components/base-components/InputError';
import RenderIf from 'components/base-components/RenderIf';
import 'components/base-components/Input/styles.scss';

export interface Props extends InputNumberProps {
  label?: string;
  inputClassName?: string;
  mR?: boolean;
  notMB?: boolean;
  error?: string;
}

const InputNumber: FunctionComponent<Props> = (props) => {
  const { label, id, className, inputClassName, disabled, mR, notMB, error, ...inputProps } = props;
  const wrapperClassNames = classnames('input__wrapper', { mR, error, notMB }, className);

  return (
    <div className={wrapperClassNames}>
      <RenderIf isTrue={!!label}>
        <Label text={label} forInput={id} />
      </RenderIf>
      <AntInputNumber
        className={inputClassName}
        id={id}
        disabled={disabled}
        {...inputProps}
      />
      <InputError error={error} />
    </div>
  );
};

InputNumber.defaultProps = {
  placeholder: '',
};

export default InputNumber;
