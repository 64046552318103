import React, { FunctionComponent, ReactNode } from 'react';
import { ButtonType } from 'antd/lib/button';

export interface DataTableActionProps {
  icon?: ReactNode | ((data) => ReactNode);
  label?: string | ((data) => string);
  onClick: (rowData) => void;
  disabled?: (rowData) => boolean;
  loading?: (rowData) => boolean;
  permissions?: string[];
  type?: ButtonType;
  danger?: boolean;
  hide?: (rowData) => boolean;
  badge?: string;
}

const DataTableAction: FunctionComponent<DataTableActionProps> = () => {
  return <></>;
};

DataTableAction.defaultProps = {
  type: 'primary',
};

export default DataTableAction;
