import React from 'react';
import { OrderOptionModel } from 'models/order';

export default function optionsTemplate(options: OrderOptionModel[]) {
  const optionsTags = options.map((option) => (
    <li key={`${option.optionType}-${option.code}`}>
      {`${option.optionType}: ${option.optionSelected}`}
    </li>
  ));

  return (
    <ul className="third-party-paint__product-list__options-list">
      {optionsTags}
    </ul>
  );
}
