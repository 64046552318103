import React, { FunctionComponent, useMemo } from 'react';
import { Row, Typography } from 'antd';
import { ProjectDTOModel } from 'models/project';
import RenderIf from 'components/base-components/RenderIf';
import './styles.scss';

interface Props{
  project: ProjectDTOModel;
}

const hasValueRender = (value: string, first = false) => value ? `${!first ? ', ' : ''}${value}` : '';
const renderAddress = (value: string) => value.replaceAll(',', ' ');

const InfoSubHeader: FunctionComponent<Props> = (props) => {
  const { project } = props;
  const clientInfo = project?.clientInfo;
  const companyInfo = project?.companyInfo;
  const terms = project?.terms;
  const addressTemplate = useMemo(() => renderAddress(companyInfo ? companyInfo.businessAddress : ''), [companyInfo]);
  const restAddressTemplate = useMemo(() => companyInfo
    ? hasValueRender(companyInfo.businessCity, true) +
    hasValueRender(companyInfo.businessStateProvince) +
    hasValueRender(companyInfo.businessPostCode)
    : '',
  [companyInfo]);

  return (
    <Row justify="start">
      <RenderIf isTrue={!!companyInfo}>
        <dl className="quote-info__list">
          <dt>
            <Typography.Title className="quote-info__tittle" level={3}>Account Info:</Typography.Title>
          </dt>
          <div className="quote-info__item-list">
            <dd>
              <Typography.Title level={4}>
                {companyInfo?.businessLegalName}
              </Typography.Title>
            </dd>
            <dd className="quote-info__term-item">
              {addressTemplate}
            </dd>
            <dd>{restAddressTemplate}</dd>
            <dd>
              <Typography.Text strong>
                {'Tel: '}
              </Typography.Text>
              {companyInfo?.businessPhone}
            </dd>
            <dd className="quote-info__term-item">
              <Typography.Text strong>
                {'Term: '}
              </Typography.Text>
              {terms?.termsName}
            </dd>
          </div>
        </dl>
      </RenderIf>
      <RenderIf isTrue={!!clientInfo}>
        <dl className="quote-info__list">
          <dt>
            <Typography.Title className="quote-info__tittle" level={3}>Customer Info:</Typography.Title>
          </dt>
          <div className="quote-info__item-list">
            <dd>
              <Typography.Title level={4}>
                {`${clientInfo?.firstName} ${clientInfo?.lastName}`}
              </Typography.Title>
            </dd>
            <dd className="quote-info__term-item">
              <Typography.Text strong>
                {'Email: '}
              </Typography.Text>
              {clientInfo?.email}
            </dd>
          </div>
        </dl>
      </RenderIf>
    </Row>
  );
};

export default InfoSubHeader;
