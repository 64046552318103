import { Column } from 'components/base-components/KanbanBoard';
import taskApi from 'api/task';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { resolveApiErrorMessage } from 'api/base';
import { TaskManagerActions } from '../reducer';

export default function onCardDragEnd(oldColumns: Column[], dispatch) {
  return (card, { fromColumnId, fromPosition }, { toColumnId, toPosition }) => {
    const columns = oldColumns;
    columns[fromColumnId].cards.splice(fromPosition, 1);
    columns[toColumnId].cards.splice(toPosition, 0, card);
    dispatch({ type: TaskManagerActions.SET_COLUMNS, payload: columns });

    if (fromColumnId !== toColumnId) {
      const taskPayload = { ...card, status: columns[toColumnId].title };
      const onSuccess = () => {
        const message = 'Task Successfully Moved';
        notification({ type: NotificationType.SUCCESS, message });
      };

      const onError = (error) => {
        const message = resolveApiErrorMessage((error as any).response);
        notification({ type: NotificationType.ERROR, message });
      };

      const onFinally = () => {
        notifyEventChannel(EventChannelList.TASK_PAGE_LIST_CHANGED);
      };

      taskApi.update(taskPayload)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    }
  };
}
