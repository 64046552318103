import getEventValue from 'helpers/get-event-value';
import { BuilderFormModel } from 'components/pages/Builder/state';
import { updateModelTextures } from './update-model-texture';

export default function handleOptionChange(model: BuilderFormModel) {
  return (event, setFields) => {
    const option = getEventValue(event);

    updateModelTextures(model, option);
    setFields({
      option,
      width: option.textureWidth,
      height: option.textureHeight,
    });
  };
}
