import { useCallback, useEffect, useState } from 'react';
import { resolveApiErrorMessage } from 'api/base';

interface CallApiActionState<T> {
  fetching: boolean;
  data: T;
  error: string;
}

const callApiAction = async (action, params, payload, setState, fetching: boolean) => {
  if (!fetching) {
    setState((oldState) => ({ ...oldState, fetching: true }));
  }

  try {
    const response = await action(params, payload);
    setState({
      fetching: false,
      data: response.data,
      error: undefined,
    });
  } catch (error) {
    const message = resolveApiErrorMessage((error as any).response);
    setState(oldState => ({
      fetching: false,
      data: oldState.data,
      error: message,
    }));
  }
};

export default function useCallApiAction<T = any>(
  action,
  params,
  initialValue: T,
  preventAutoFetch?: boolean,
  payload?,
) {
  const [state, setState] = useState<CallApiActionState<T>>({
    fetching: !preventAutoFetch,
    data: initialValue,
    error: undefined,
  });

  useEffect(() => {
    if (!preventAutoFetch) {
      callApiAction(action, params, payload, setState, state.fetching);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, params, preventAutoFetch, payload]);

  return {
    ...state,
    callAction: useCallback(
      (newParams?, newPayload?) => {
        setState((oldState) => ({ ...oldState, fetching: false }));
        callApiAction(action, newParams || params, newPayload || payload, setState, state.fetching);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [action, params, payload, state.fetching],
    ),
  };
}
