import { commonRules } from 'helpers/form-validations';

const { required, email } = commonRules;

export const validationRules = {
  companyName: [required],
  firstName: [required],
  lastName: [required],
  email: [required, email],
};
