import { FunctionComponent, ReactNode } from 'react';
import hasPermissions from 'helpers/permissons';
import { PermissionLabels } from 'constants/permissions';

interface Props {
  permissions: PermissionLabels[];
  renderNoAuthorized?: ReactNode;
}

const AccessControl: FunctionComponent<Props> = (props): any => {
  const { children, renderNoAuthorized, permissions } = props;

  if (hasPermissions(permissions)) {
    return children;
  }

  return renderNoAuthorized || null;
};

AccessControl.defaultProps = {
  renderNoAuthorized: null,
};

export default AccessControl;
