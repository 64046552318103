import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Blueprint3DProductViewer from 'lib/Blueprint3D-product-viewer';
import RenderIf from 'components/base-components/RenderIf';
import { BuilderFormModel } from '../../Builder/state';
import './styles.scss';

interface Props {
  blockUI: boolean;
  models: BuilderFormModel[];
}

const stopPropagation = (event) => event.stopPropagation();

const Stage: FunctionComponent<Props> = (props) => {
  const { models, blockUI } = props;
  const [addedModels, setAddedModels] = useState([]);
  const refBp3d = useRef(undefined);

  useEffect(() => {
    if (models?.length > 0) {
      const newModels = models.filter(
        (model) => !addedModels.some((added) => added.id === model.id)
      );
      newModels.forEach((model) => refBp3d.current.addItem(model));
      const nextAdded = addedModels.concat(newModels);
      setAddedModels(nextAdded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  return (
    <main className="builder-stage">
      <Blueprint3DProductViewer
        ref={refBp3d}
        data={{}}
      />
      <RenderIf isTrue={blockUI}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="builder__loading-screen" onClick={stopPropagation} />
      </RenderIf>
    </main>
  );
};

export default Stage;
