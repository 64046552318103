import { AxiosResponse } from 'axios';
import { BuilderModel, BuilderPropertyType } from 'models/builder';
import { resolveApiErrorMessage } from 'api/base';
import builderApi from 'api/builder';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import normalizeModel from './normalize-model';
import { BuilderActions, BuilderFormModel } from '..';

function getMorphUpdates(
  productId: string,
  property: string,
  updatedProperties,
  builderOptions,
  type: BuilderPropertyType
) {
  const {
    morphLabel,
    morphIndex,
  } = (builderOptions[property] as any);
  const updatedValue = updatedProperties[property];

  return {
    productId,
    name: property,
    useMorphs: true,
    value: (updatedValue - 5) / 295,
    morphLabel,
    morphIndex,
    type
  };
}

function getPieceUpdates(
  productId: string,
  property: string,
  updatedProperties,
  builderOptions,
  type: BuilderPropertyType
) {
  const {
    nameInModel,
  } = (builderOptions[property] as any);
  const updatedValue = updatedProperties[property];

  return {
    productId,
    name: property,
    useMorphs: false,
    value: updatedValue.value,
    groupNameInModel: nameInModel,
    nameInModel: updatedValue.nameInModel,
    type,
  };
}

function resolveUpdates(
  product: BuilderFormModel,
  updatedProperties,
) {
  const { modelDescription, builderOptions } = product;
  const updatedProperty = (
    Object.keys(modelDescription)
      .find((property) => {
        const { propertyType: type } = (builderOptions[property] as any);
        const oldValue = modelDescription[property];
        const updatedValue = updatedProperties[property];
        const isWatchedType = (
          type === BuilderPropertyType.RANGE ||
          type === BuilderPropertyType.SELECT_REFRESH ||
          type === BuilderPropertyType.SELECT
        );

        return isWatchedType && oldValue !== updatedValue;
      })
  );

  if (!updatedProperty) {
    return { hasUpdates: false };
  }

  const {
    propertyType: type,
  } = (builderOptions[updatedProperty] as any);

  const updatesResolver = (
    type === BuilderPropertyType.RANGE
      ? getMorphUpdates
      : getPieceUpdates
  );
  const updates = updatesResolver(
    product.id,
    updatedProperty,
    updatedProperties,
    builderOptions,
    type
  );

  return {
    hasUpdates: true,
    updatedProperty: updates,
  };
}

const rangeProperties = ['height', 'depth', 'width'];

export default function updateProperties(dispatch, product: BuilderFormModel) {
  return (properties) => {
    const {
      hasUpdates,
      updatedProperty,
    } = resolveUpdates(product, properties);

    if (hasUpdates) {
      notifyEventChannel(
        EventChannelList.BUILDER_PRODUCT_PROPERTY_CHANGED,
        updatedProperty,
      );
    }
    if (updatedProperty.type === BuilderPropertyType.SELECT_REFRESH) {
      dispatch({ type: BuilderActions.START_FETCHING_PRODUCT });

      const onSuccess = (response: AxiosResponse<BuilderModel>) => {
        const { options, optionGroups } = normalizeModel(response.data);
        const payload = { ...product, modelDescription: properties, options, optionGroups };
        dispatch({ type: BuilderActions.UPDATE_PRODUCT, payload });
      };

      const onError = (error) => {
        notification({
          type: NotificationType.ERROR,
          message: resolveApiErrorMessage((error as any).response),
        });
        dispatch({ type: BuilderActions.STOP_FETCHING_PRODUCT });
      };

      const name = Object.keys(properties as any)
        .filter(e => !rangeProperties.includes(e))
        .map(e => ((properties[e] as any).value || properties[e]))
        .join('-');

      builderApi.getDetailsByModel(name)
        .then(onSuccess)
        .catch(onError);
    } else {
      dispatch({
        type: BuilderActions.UPDATE_PRODUCT,
        payload: { ...product, modelDescription: properties },
      });
    }
  };
}
