/* eslint-disable */
import * as THREE from "three";

export function Lights(scene, floorplan) {
  // var scope = this;
  // var scene = scene;
  // var floorplan = floorplan;

  // const tol = 0.1;
  const height = 5; // TODO: share with Blueprint.Wall

  let dirLight;
  let pointLight;

  this.getDirLight = () => {
    return dirLight;
  };

  function updateShadowCamera() {
    const center = floorplan.getCenter();
    const pos = new THREE.Vector3(center.x, height, center.z);
    pointLight.position.copy(pos);
  }

  function init() {
    const light = new THREE.HemisphereLight(0xffffff, 0x888888, 1.1);
    light.position.set(4, height, 0);
    scene.add(light);

    pointLight = new THREE.PointLight(0xffffff, 0.4, 100, 2);
    pointLight.position.set(0, height, 0);
    pointLight.castShadow = true;
    pointLight.shadow.mapSize.width = 1024;
    pointLight.shadow.mapSize.height = 1024;
    pointLight.shadow.radius = 2;
    pointLight.shadow.bias = 0.0001;
    scene.add(pointLight);

    // dirLight = new THREE.DirectionalLight(0xffffff);
    // dirLight.position.set(4, height, 0);
    // dirLight.color.setHSL(1, 1, 0.1);
    // dirLight.castShadow = true;
    // dirLight.shadow.mapSize.width = 10;
    // dirLight.shadow.mapSize.height = 10;
    // dirLight.shadow.camera.far = height + tol;
    // dirLight.shadow.bias = -0.0001;
    // dirLight.shadowDarkness = 0.2;
    // dirLight.visible = true;
    // dirLight.shadowCameraVisible = false;
    // scene.add(dirLight);
    // scene.add(dirLight.target);
    floorplan.fireOnUpdatedRooms(updateShadowCamera);
  }

  init();
}
