import { QuoteModel } from 'models/quote';
import { QuoteProductsDataModel } from '..';

export default function prepareQuoteProductsData(quote: QuoteModel) {
  let groupId = 1;
  const expandedRowKeys = ['group-0'];
  const initialOrderProducts = [
    {
      id: 'group-0',
      group: '',
      products: [],
    },
  ];

  const quoteProducts: QuoteProductsDataModel[] = quote.orderproducts?.reduce((orderProducts, orderProduct) => {
    const groupIndex = orderProducts.findIndex((op) => op.group === orderProduct.group);
    if (groupIndex === -1) {
      const newOrderProducts = orderProducts.concat(
        [
          {
            id: `group-${groupId}`,
            group: orderProduct.group,
            products: [orderProduct],
          }
        ]
      );
      expandedRowKeys.push(`group-${groupId}`);
      groupId += 1;
      return newOrderProducts;
    }
    orderProducts[groupIndex].products.push(orderProduct);
    return orderProducts;
  }, initialOrderProducts);

  return { quoteProducts, expandedRowKeys };
}
