import { TutorialModel } from 'models/tutorial';
import { mapFileFromUrl } from 'helpers/file';
import { TutorialFormActions } from '../reducer';

const defaultTutorial: TutorialModel = {
  video: undefined,
  videoName: '',
};

export default function initFormData(
  dispatch,
  tutorial: TutorialModel,
) {
  if (tutorial) {
    dispatch({
      type: TutorialFormActions.SET_TUTORIAL,
      payload: {
        ...tutorial,
        video: mapFileFromUrl(tutorial.video)
      },
    });
  } else {
    dispatch({
      type: TutorialFormActions.SET_TUTORIAL,
      payload: tutorial || defaultTutorial,
    });
  }
}
