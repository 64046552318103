import React from 'react';
import Button from 'components/base-components/Button';
import Tag from 'components/base-components/Tag';

const formatValue = (value) => value % 1 !== 0 ? `${parseFloat(value).toFixed(2)}%` : `${value}%`;

export default function orderedTemplate(handleShow) {
  return (data) => {
    const material = data.materials?.ordered;
    const onClick = () => handleShow(data.id);
    const value = isNaN(material?.percentage) ? material?.percentage : formatValue(material?.percentage);
    const color = data?.statuses?.materials?.ordered !== 'FFFFFF' ? data?.statuses?.materials?.ordered : undefined;

    return (
      <Button className="button-cell__button" type="text" onClick={onClick}>
        <Tag color={color}>
          {value}
        </Tag>
      </Button>
    );
  };
}
