import React, { FunctionComponent } from 'react';
import { formatCurrency } from 'helpers/formatting';
import { ExtraChargeModel } from 'models/extra-charge';
import { Summary } from 'components/base-components/DataTableV2';

interface Props {
  extraCharges: ExtraChargeModel[];
  colSpan: number;
}

function amountRender(amount: number) {
  return formatCurrency(amount || 0);
}

const ExtraCharges: FunctionComponent<Props> = (props): any => {
  const {
    extraCharges,
    colSpan,
  } = props;

  return extraCharges.map((charge) => {
    const data = {
      ...charge,
      amount: parseFloat(charge.amount),
    };

    return (
      <Summary.Row
        key={`quote-charge-${charge.id || 'id'}`}
        className="quote-products-table__summary-row"
      >
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          <span className="quote-charge__title">
            <b>{data.chargeName}</b>
          </span>
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          <b>{amountRender(data.amount)}</b>
        </Summary.Cell>
      </Summary.Row>
    );
  });
};

ExtraCharges.defaultProps = {
  extraCharges: [],
};

export default ExtraCharges;
