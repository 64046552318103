import React, { FunctionComponent, useCallback, useState } from 'react';
import moment from 'moment';
import { CalendarEventModel } from 'models/calendar-event';
import Tag from 'components/base-components/Tag';
import FlexBox from 'components/base-components/FlexBox';
import { Text } from 'components/base-components/Typography';
import Popover from 'components/base-components/Popover';
import Tooltip from 'components/base-components/Tooltip';
import Button from 'components/base-components/Button';
import Switch from 'components/base-components/Switch';
import { capitalizeFirstLetter } from 'helpers/formatting';
import deleteCustomEvent from './actions/delete-custom-event';
import setCustomEventCompleted from './actions/set-custom-event-completed';

interface Props {
  item: CalendarEventModel;
  hasOrderPermission?: boolean;
}

const textStyle = { marginBottom: '8px' };

const CustomEvent: FunctionComponent<Props> = (props) => {
  const { item } = props;
  const { id, completed, details, eventType, title, scheduled, createdBy: { firstName, lastName } } = item;
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [completedLoading, setCompletedLoading] = useState(false);
  const onDelete = useCallback(deleteCustomEvent(item, setDeleteLoading), [item]);
  const onSetCompleted = useCallback(setCustomEventCompleted(item, setCompletedLoading), [item]);

  const content = (
    <FlexBox direction="column">
      <Text strong>Time</Text>
      <Text style={textStyle}>{moment(scheduled)?.format('hh:mm:ss a') || 'N/A'}</Text>
      <Text strong>Event Type</Text>
      <Text style={textStyle}>{capitalizeFirstLetter(eventType) || 'N/A'}</Text>
      <Text strong>Details</Text>
      <Text style={textStyle}>{details || 'N/A'}</Text>
      <Text strong>Created By</Text>
      <Text style={textStyle}>{firstName || lastName ? `${firstName} ${lastName}` : 'N/A'}</Text>
      <FlexBox>
        <Text strong type={completed ? 'success' : 'danger'}>{completed ? 'Complete' : 'Incomplete'}</Text>
        <Switch size="small" checked={item.completed} onChange={onSetCompleted} loading={completedLoading} />
      </FlexBox>
    </FlexBox>
  );

  const renderTitle = (
    <FlexBox justify="space-between">
      <Text strong>{title}</Text>
      <Tooltip title="Delete Event">
        <Button
          size="small"
          type="text"
          shape="circle"
          onClick={onDelete}
          icon="fas fa-trash"
          loading={deleteLoading}
          mL
        />
      </Tooltip>
    </FlexBox>
  );

  return (
    <Popover
      content={content}
      overlayClassName="production-calendar__popovers"
      title={renderTitle}
      placement="bottomLeft"
      trigger="hover"
    >
      <Tag
        color={completed ? '#139272' : '#f60605'}
        className="production-calendar__tag"
        id={id.toString(10)}
        draggable
      >
        <i className="fa fa-user-clock" />
        <span style={{ marginLeft: '8px', fontSize: '14px' }}>
          {`${title} - ${eventType}`}
        </span>
      </Tag>
    </Popover>
  );
};

export default CustomEvent;
