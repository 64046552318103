import React, { FunctionComponent, useMemo } from 'react';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import NumberInput from 'components/base-components/NumberInput';
import InputError from 'components/base-components/InputError';
import Button from 'components/base-components/Button';
import Select from 'components/base-components/Select';
import { ThreeMorphFormModel } from './state';

interface Props {
  properties: DropdownOptionModel[];
  morphs: { [index: number]: ThreeMorphFormModel };
  onChange: (index, morph) => void;
  onRemove: (index) => void;
  onAdd: () => void;
  error?: string;
  savingModel?: boolean;
}

const MorphInputs: FunctionComponent<Props> = (props) => {
  const {
    properties,
    morphs,
    onChange,
    onAdd,
    onRemove,
    error,
    savingModel,
  } = props;

  const morphInputs = useMemo(() => {
    return Object.keys(morphs)
      .map((morphKey, index) => {
        const morph = morphs[morphKey];

        const handleMorphChange = (updatedMorph) => {
          onChange(morphKey, updatedMorph);
        };

        const handleRemoveMorph = () => {
          onRemove(morphKey);
        };

        return (
          <Form key={morphKey} state={morph} onChange={handleMorphChange}>
            <div className="morphs__input-group">
              <Field
                name="morphLabel"
                label={index === 0 ? 'Label' : undefined}
              />
              <Field
                name="morphIndex"
                label={index === 0 ? 'Index' : undefined}
                component={NumberInput}
              />
              <Field
                name="property"
                label={index === 0 ? 'Property' : undefined}
                component={Select}
                options={properties}
              />
              <Button
                onClick={handleRemoveMorph}
                icon="fa fa-trash-alt"
                disabled={savingModel}
                danger
              />
            </div>
          </Form>
        );
      });
  }, [morphs, properties, savingModel, onChange, onRemove]);

  return (
    <>
      {morphInputs}
      <InputError error={error} />
      <div className="morphs__actions">
        <Button label="Add morph" onClick={onAdd} disabled={savingModel} mT />
      </div>
    </>
  );
};

export default MorphInputs;
