import { EditOptionsFormActions } from '../reducer';

export function completeState(dispatch, oldState, productOptions) {
  const formData = { ...oldState };
  productOptions.forEach(productOption => {
    if (!formData[productOption.name]) {
      formData[productOption.name] = { value: null, label: 'None' };
    }
  });
  const subs = Object.keys(oldState).filter(e => e.includes('sub'));
  const hasSubs = subs.map(e => e.split('-')[1]);
  hasSubs.forEach(e => {
    const haveSuboptions = productOptions.find(productOption => productOption.name === e)
      ?.option
      ?.find(opt => opt.name === formData[e].value)
      ?.haveSuboptions || undefined;
    formData[e] = { ...formData[e], dropdownItem: { haveSuboptions } };
  });

  dispatch({ type: EditOptionsFormActions.SET_FORM_VALUE, payload: { ...formData } });
}
