import { AppPermissions } from 'constants/permissions';
import { permissions as UsersPermissions } from 'components/pages/Users/List/permissions';
import { permissions as UserGroupsPermissions } from 'components/pages/UserGroups/List/permissions';
import { permissions as AccountPermissions } from 'components/pages/Account/List/permissions';
import { permissions as AccountGroupsPermissions } from 'components/pages/AccountGroups/List/permissions';
import { permissions as TermsPermissions } from 'components/pages/Terms/List/permissions';
import { permissions as AccountApplicationsPermissions } from 'components/pages/AccountApplications/List/permissions';
import { permissions as ManufacturersPermissions } from 'components/pages/Manufacturers/List/permissions';
import { permissions as ProductsPermissions } from 'components/pages/Products/List/permissions';
import { permissions as OptionsPermissions } from 'components/pages/Options/List/permissions';
import { permissions as OptionGroupsPermissions } from 'components/pages/OptionGroups/List/permissions';
import { permissions as CategoriesPermissions } from 'components/pages/Categories/List/permissions';
import { permissions as QuotesPermissions } from 'components/pages/Quotes/List/permissions';
import { permissions as QuoteDetailsPermissions } from 'components/pages/Quotes/Quote/permissions';
import { permissions as OrdersPermissions } from 'components/pages/Orders/List/permissions';
import { permissions as DeficiencyItemsPermissions } from 'components/pages/DeficiencyItems/List/permissions';
import { permissions as PurchaseOrdersPermissions } from 'components/pages/PurchaseOrders/List/permissions';
import { permissions as PurchaseOrdersItemsPermissions } from 'components/pages/POItems/List/permissions';
import { permissions as CNCCalcPermissions } from 'components/pages/CNCCalc/List/permissions';
import { permissions as InvoicesPermissions } from 'components/pages/Invoices/permissions';
import { permissions as CreateProjectPermissions } from 'components/pages/CreateProject/permissions';
import { permissions as ThreeModelsPermissions } from 'components/pages/ThreeModels/List/permissions';
import { permissions as ShippingZonesSettingPermissions } from 'components/pages/ShippingZonesSetting/List/permissions';
import { permissions as StorePermissions } from 'components/pages/Store/List/permissions';
import { permissions as InstallerPermissions } from 'components/pages/Installers/List/permissions';
import { permissions as InstallerBookingPermissions } from 'components/pages/InstallersBooking/List/permissions';
import { permissions as ProductionManagerPermissions } from 'components/pages/ProductionManager/permissions';
import { permissions as ProjectCoordinatorPermissions } from 'components/pages/ProjectCoordinator/permission';
import { permissions as PartsPermissions } from 'components/pages/Parts/List/permissions';
import { permissions as PaymentsPermissions } from 'components/pages/Payment/List/permissions';
import { permissions as TexturePermissions } from 'components/pages/TextureSettings/permissions';
import { permissions as GeneratePermissions } from 'components/pages/GenerateProduct/permissions';
import { permissions as InventoryPermissions } from 'components/pages/Inventory/List/permissions';
import { permissions as CutlistPermissions } from 'components/pages/CutList/List/permissions';
import { permissions as CNCWorkerPermissions } from 'components/pages/CNCWorker/permissions';

const dealerPermissions = {
  ...QuotesPermissions,
  ...QuoteDetailsPermissions,
  ...OrdersPermissions,
  ...InvoicesPermissions,
  viewProduct: [AppPermissions.view_product]
};

const salesPermissions = {
  ...OptionGroupsPermissions,
  ...AccountApplicationsPermissions,
  ...CreateProjectPermissions,
  ...AccountPermissions,
  ...QuotesPermissions,
  ...QuoteDetailsPermissions,
  ...OrdersPermissions,
  ...CategoriesPermissions,
  ...InvoicesPermissions,
  ...ProductsPermissions,
  ...ManufacturersPermissions
};

const shopPermissions = {
  ...InventoryPermissions,
  ...ManufacturersPermissions,
  ...ProductsPermissions,
  ...OptionsPermissions,
  ...OptionGroupsPermissions,
  ...CategoriesPermissions,
  ...ProductionManagerPermissions,
  ...ProjectCoordinatorPermissions,
  ...InstallerBookingPermissions,
  ...CutlistPermissions,
  ...PurchaseOrdersPermissions,
  ...PurchaseOrdersItemsPermissions,
  ...CNCCalcPermissions,
  ...PartsPermissions,
  ...CNCWorkerPermissions,
};

export const dataViews = [
  // Account
  { groupname: 'Account-Users', permissions: UsersPermissions },
  { groupname: 'Account-User_Groups', permissions: UserGroupsPermissions },
  { groupname: 'Account-Accounts', permissions: AccountPermissions },
  { groupname: 'Account-Account_Groups', permissions: AccountGroupsPermissions },
  { groupname: 'Account-Terms', permissions: TermsPermissions },
  { groupname: 'Account-Account_Applications', permissions: AccountApplicationsPermissions },
  // Catalog
  { groupname: 'Catalog-Manufacturers', permissions: ManufacturersPermissions },
  { groupname: 'Catalog-Products', permissions: ProductsPermissions },
  { groupname: 'Catalog-Options', permissions: OptionsPermissions },
  { groupname: 'Catalog-Option_Groups', permissions: OptionGroupsPermissions },
  { groupname: 'Catalog-Categories', permissions: CategoriesPermissions },
  // Inventory
  { groupname: 'Inventory-Inventory', permissions: InventoryPermissions },
  // Productions
  { groupname: 'Productions-Production_Manager', permissions: ProductionManagerPermissions },
  { groupname: 'Productions-Project_Coordinator', permissions: ProjectCoordinatorPermissions },
  { groupname: 'Productions-Installers_Booking', permissions: InstallerBookingPermissions },
  { groupname: 'Productions-Cutlist', permissions: CutlistPermissions },
  { groupname: 'Productions-Purchase_Orders', permissions: { ...PurchaseOrdersPermissions, ...PurchaseOrdersItemsPermissions } },
  { groupname: 'Productions-CNC_Calc', permissions: CNCCalcPermissions },
  { groupname: 'Productions-Parts', permissions: PartsPermissions },
  { groupname: 'Productions-CNC_Worker', permissions: CNCWorkerPermissions },
  // Sales
  { groupname: 'Sales-Quotes', permissions: { ...QuotesPermissions, ...QuoteDetailsPermissions } },
  { groupname: 'Sales-Orders', permissions: OrdersPermissions },
  { groupname: 'Sales-Deficiency_Orders', permissions: DeficiencyItemsPermissions },
  { groupname: 'Sales-Invoices', permissions: InvoicesPermissions },
  { groupname: 'Sales-Payments', permissions: PaymentsPermissions },
  // Sales_Pipeline
  { groupname: 'Sales_Pipeline-Create_Project', permissions: CreateProjectPermissions },
  // Settings
  { groupname: 'Settings-Account-Stores', permissions: StorePermissions },
  { groupname: 'Settings-Three_Models', permissions: ThreeModelsPermissions },
  { groupname: 'Settings-Shipping-Shipping_Zones', permissions: ShippingZonesSettingPermissions },
  { groupname: 'Settings-Shipping-Installer', permissions: InstallerPermissions },
  { groupname: 'Settings-Textures', permissions: TexturePermissions },
  { groupname: 'Settings-Catalog-Generate_Products', permissions: GeneratePermissions },
  // Roles
  { groupname: 'Dealer', permissions: dealerPermissions },
  { groupname: 'Sales', permissions: salesPermissions },
  { groupname: 'Shop', permissions: shopPermissions },
];
