import { OptionModel } from 'models/option';
import { GenericMapOptions, mapGenericDropdownOptions } from 'helpers/dropdown-options';

const optionMapDropdown: GenericMapOptions = {
  fields: { value: 'name', label: 'name' },
  extra: ['textureHeight', 'textureWidth', 'image'],
  copyFullItem: true,
};

export default function mapOptions(options: OptionModel[]) {
  const filtered = options.filter(option => !!option.image);
  return mapGenericDropdownOptions(filtered, optionMapDropdown);
}
