import { useCallback, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import filesViewerApi from 'api/file-viewer';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import useCallApiAction from 'hooks/base/call-api-action';
import assignFilesFormReducer, { AssignFilesFormActions } from './reducer';
import { setFormValue } from './actions/set-form-value';
import { updateAssignedFiles } from './actions/update-assigned-files';

export default function useAssignFilesState(quoteId: number) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(assignFilesFormReducer, {
    data: {},
    updating: false,
    errors: undefined,
  });

  const { data } = state;

  const {
    data: { files },
    fetching,
    error,
  } = useCallApiAction(filesViewerApi.listQuotePath, quoteId, { files: {} });

  useEffect(() => {
    if (!!files && !fetching) {
      dispatch({
        type: AssignFilesFormActions.UPDATE_FORM_VALUE,
        payload: files
      });
    }
  }, [files, fetching]);

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(error),
      });
    }
  }, [error]);

  return {
    state: {
      ...state,
      fetching
    },
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      updateAssignedFiles: useCallback(updateAssignedFiles(dispatch, data, quoteId, goBack), [data, quoteId]),
    }
  };
}
