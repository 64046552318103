import Auth from '@aws-amplify/auth';
import { createSlice } from '@reduxjs/toolkit';

import { COGNITO_USER } from '../../constants';

const initialState = {
  loading: false,
  error: null
};

const setPasswordSlice = createSlice({
  name: 'setPassword',
  initialState,
  reducers: {
    setPasswordStart(state) {
      state.loading = true;
    },
    setPasswordSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    setPasswordFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

const { setPasswordStart, setPasswordSuccess, setPasswordFail } = setPasswordSlice.actions;

export const setPassword = (password, history) => async dispatch => {
  try {
    dispatch(setPasswordStart());

    await Auth.completeNewPassword(Auth[COGNITO_USER], password, undefined, undefined);

    dispatch(setPasswordSuccess());

    history.push('/dashboard');
  } catch (error) {
    dispatch(setPasswordFail((error as any).message || ''));
  }
};

export default setPasswordSlice.reducer;
