import { QuoteDTO } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { EmailSender } from 'components/forms/SendEmail/state';

export default function openSendEmailModal(push, asProposal, search) {
  return (quote: QuoteDTO) => {
    const { id, clientInfo } = quote;
    const recipients = clientInfo ? [clientInfo.email] : undefined;

    push(
      `${search}${ModalRouteHash.SendEmailForm}`,
      {
        sendEmailForm: {
          id,
          recipients,
          from: asProposal ? EmailSender.QuoteProposal : EmailSender.Quote,
          title: asProposal ? 'Send Quote Proposal by email' : 'Send Quote by email',
        },
      },
    );
  };
}
