import { UpdateShippingActions } from '../reducer';

export function setFlatRate(dispatch) {
  return (flatRate) => {
    dispatch({
      type: UpdateShippingActions.SET_FLAT_RATE,
      payload: { ...flatRate, change: true },
    });
  };
}
