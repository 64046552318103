import React, { Component } from 'react';
import classnames from 'classnames';
import ChartJS from 'chart.js';
import resolveOptions from './resolveOptions';
import ChartContext from './context';

interface Props {
  type: (
    'bar' |
    'horizontalBar' |
    'line' |
    'radar' |
    'pie' |
    'doughnut' |
    'polarArea' |
    'bubble'
  );
  /** Defines the names of the sections for the corresponding values. */
  labels: string[];
  /** Determines whether to show the stacked bars in a bar chart. */
  showStacked?: boolean;
  /** Defines if the legend is shown. */
  showLegend?: boolean;
  /** If true, do not display grid lines for x axis. */
  disableXAxisGridLines?: boolean;
  /** If true, do not display grid lines for y axis. */
  disableYAxisGridLines?: boolean;
  /** If true, do not draw border at the edge between the x axis and the chart area. */
  disableXAxisBorders?: boolean;
  /** If true, do not draw border at the edge between the y axis and the chart area. */
  disableYAxisBorders?: boolean;
  /** If true, do not show tick labels for x axis. */
  disableXAxisTickLabels?: boolean;
  /** If true, do not show tick labels for y axis. */
  disableYAxisTickLabels?: boolean;
  /** The position of the legend. */
  legendPosition?: 'top' | 'bottom' | 'right' | 'left';
  /** Determines whether it should perform animations when rendering the chart,
   * this would improve general performance
   * and is recommended for high data volumes charts. */
  disableAnimations?: boolean;
  /** Determines whether to draw the lines that join the dots in a line chart,
   * is recommended for high data volumes charts. */
  disableLines?: boolean;
  /** Determines whether the lines that join the dots in a
   * line chart should be curved or straight.  */
  disableCurves?: boolean;
  /** Maintain the original canvas aspect ratio. */
  maintainAspectRatio?: boolean;
  /** An object with options to pass to Chart.js; Options in this object
   * will have precedence over any other option.
   * See: https://www.chartjs.org/docs/2.7.3/general/
   */
  options?: object;
  className?: string;
  style?: any;
}

export default class Chart extends Component<Props> {
  chartRef;
  chartInstance;
  datasets: { [id: string]: any };

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.datasets = {};
    this.registerDataset = this.registerDataset.bind(this);
    this.unregisterDataset = this.unregisterDataset.bind(this);
    this.updateDataset = this.updateDataset.bind(this);
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  updateChart() {
    const { labels, type, ...conditions } = this.props;
    this.chartInstance.config.type = type;
    this.chartInstance.data.labels = labels;
    this.chartInstance.data.datasets = Object.values(this.datasets);
    this.chartInstance.options = resolveOptions({ type, ...conditions });
    this.chartInstance.update();
  }

  registerDataset(id, dataset) {
    this.datasets[id] = dataset;
    this.updateChart();
  }

  unregisterDataset(id) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: remove, ...rest } = this.datasets;
    this.datasets = rest;
    this.chartInstance.update();
  }

  updateDataset(id, dataset) {
    const keys = Object.keys(dataset);
    keys.forEach(key => {
      this.datasets[id][key] = dataset[key];
    });
    this.updateChart();
  }

  renderChart() {
    const { type, labels, ...conditions } = this.props;
    const node = this.chartRef.current;

    this.chartInstance = new ChartJS(node, {
      type,
      data: {
        labels,
      },
      options: resolveOptions({ type, ...conditions }),
    });
  }

  render() {
    const { style, className, children } = this.props;
    const context = {
      registerDataset: this.registerDataset,
      unregisterDataset: this.unregisterDataset,
      updateDataset: this.updateDataset,
    };

    const chartsClassName = classnames('chart', className);

    return (
      <ChartContext.Provider value={context}>
        <div className={chartsClassName} style={style}>
          <canvas ref={this.chartRef} />
          <div className="charts__datasets">{children}</div>
        </div>
      </ChartContext.Provider>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
Chart.defaultProps = {
  type: 'bar',
  labels: [],
  showStacked: false,
  showLegend: true,
  legendPosition: 'bottom',
  disableAnimations: false,
  disableLines: false,
  disableCurves: false,
  disableXAxisGridLines: false,
  disableYAxisGridLines: false,
  disableXAxisBorders: false,
  disableYAxisBorders: false,
  disableXAxisTickLabels: false,
  disableYAxisTickLabels: false,
  maintainAspectRatio: true,
  options: {},
  className: undefined,
  style: undefined,
};
