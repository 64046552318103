import { CategoryModel } from 'models/category';

export default function getPayload(category: CategoryModel, image, url) {
  const { threeModel, categories, optionGrp, parent, ...rest } = category;
  return {
    ...rest,
    url,
    image,
    threeModel: threeModel?.url,
    parent: parent.url,
    categories: categories.map(cat => cat.url),
    optionGrp: optionGrp.map(opt => opt.url),
  };
}
