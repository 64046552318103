import { formatCurrency } from 'helpers/formatting';

export default function totalTemplate(row, cantEditProduct) {
  if (typeof row.id === 'string') {
    if (row.id.includes('subtotal') && row.subtotal && cantEditProduct) {
      return {
        children: formatCurrency(row.subtotal),
        props: {
          align: 'right',
          className: 'quote-product-list__totals',
        },
      };
    }

    return {
      props: {
        colSpan: 0,
      },
    };
  }

  return formatCurrency(row.priceWithOptions * row.quantity);
}
