import { AxiosResponse } from 'axios';
import { AccountModel } from 'models/account';
import { ApiContentType, ApiModel, post } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/accounts/`;

interface AccountApiModel extends ApiModel<AccountModel> {
  quickCreate: (account) => Promise<AxiosResponse<AccountModel>>;
}

const accountApi: AccountApiModel = {
  ...getApi<AccountModel>(endpoint, ApiContentType.MULTIPART),
  quickCreate(account) {
    return post(`${endpoint}quick_create/`, account);
  },
};

export default accountApi;
