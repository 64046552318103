import { TaskSubscriptionModel } from 'models/task-subscription';
import { getLoggedUserId } from 'helpers/get-logged-user';
import { TaskFormModel } from '../reducer';

export default function getSubsPayload(taskId: number, taskForm: TaskFormModel) {
  const {
    notifyByEmail,
    notifyBySms,
    verbosity,
  } = taskForm;
  const loggedUserId = getLoggedUserId();

  const smsSubscription: TaskSubscriptionModel = notifyBySms
    ? { protocol: 'sms', task: taskId, task_user: loggedUserId, verbosity: verbosity.value }
    : undefined;

  const emailSubscription: TaskSubscriptionModel = notifyByEmail
    ? { protocol: 'email', task: taskId, task_user: loggedUserId, verbosity: verbosity.value }
    : undefined;

  return { smsSubscription, emailSubscription };
}
