import { commonRules } from 'helpers/form-validations';

const { required } = commonRules;

export const validationRateRules = {
  name: [required],
};

export const validationGeneratingRules = {
  category: [commonRules.required],
};
