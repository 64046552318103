import { InvoiceModel } from 'models/invoice';
import invoiceApi from 'api/invoice';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function printInvoice(invoice: InvoiceModel) {
  const { id } = invoice;
  invoiceApi.print(id)
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}
