import { AccountModel } from 'models/account';
import { mapFileFromUrl } from 'helpers/file';
import {
  mapGenericDropdownOptions,
  mapUserDropdownOptions,
} from 'helpers/dropdown-options';

export const defaultFullAccount = {
  businessTradeName: '',
  businessLegalName: '',
  webSite: '',
  businessEmail: '',
  businessPhone: '',
  businessAddress: '',
  businessCity: '',
  businessStateProvince: '',
  businessPostCode: '',
  accountsPayableName: '',
  accountsPayableEmail: '',
  store: undefined,
  owner: undefined,
  salesRep: undefined,
  group: undefined,
  users: [],
  tax: [],
  paymentTerms: null,
  logo: null,
};

export const defaultQuickAccount = {
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
  paymentTerms: { value: 'Prepaid', label: 'Prepaid' },
};

export function getInitialState(location) {
  const showFullForm = !(location.state as any)?.accountForm?.showQuickForm;
  const salesRep = (location.state as any)?.accountForm?.salesRep;

  return {
    showFullForm,
    account: showFullForm ? defaultFullAccount : { ...defaultQuickAccount, salesRep },
    savingAccount: false,
    errors: {},
  };
}

export function getFormData(account: AccountModel) {
  const {
    storeDetail,
    salesRepDetail,
    ownerDetail,
    groupDetail,
    allUsersDetails,
    taxDetails,
    paymentTermsDetail,
    logo,
    ...rest
  } = account;

  const tax = mapGenericDropdownOptions(taxDetails, {
    fields: {
      value: 'url',
      label: 'taxCode',
    },
  });

  return {
    ...rest,
    store: {
      value: storeDetail?.url,
      label: storeDetail?.name,
    },
    salesRep: {
      value: salesRepDetail?.url,
      label: salesRepDetail
        ? `${salesRepDetail.firstName} ${salesRepDetail.lastName}`
        : '',
    },
    owner: {
      value: ownerDetail.url,
      label: `${ownerDetail.firstName} ${ownerDetail.lastName}`,
    },
    group: {
      value: groupDetail?.url,
      label: groupDetail?.type,
    },
    users: mapUserDropdownOptions(allUsersDetails),
    tax,
    paymentTerms: {
      value: paymentTermsDetail?.url,
      label: paymentTermsDetail?.termsName,
    },
    logo: mapFileFromUrl(logo),
  };
}
