import React, { FunctionComponent } from 'react';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Help from 'components/forms/CommonHelp';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import Page, { PageActions } from 'components/base-components/Page';
import Button from 'components/base-components/Button';
import useViewsPermissionsListState from './state';

const refreshIcon = <i className="fa fa-code" />;
const helpDescription = 'This View is for Updating the permissions by code';

const UsersGroupList: FunctionComponent = () => {
  const {
    state: {
      rowSelection,
      data,
      selectedRows,
      updating
    },
    actions: {
      checkIsProcessingView,
      updateBulkViews,
      updatingPermissions,
    }
  } = useViewsPermissionsListState();

  return (
    <Page title="Views's Permissions">
      <Help view="view-permissions" title="Views's Permissions" description={helpDescription} />
      <PageActions primary>
        <Button
          label="Bulk Updating"
          onClick={updateBulkViews}
          loading={updating}
          disabled={selectedRows.length === 0}
        />
      </PageActions>
      <DataTable
        rowKey="groupname"
        dataSource={data}
        rowSelection={rowSelection}
      >
        <DataTableColumn dataIndex="groupname" columnKey="groupname" title="Name" sorter />
        <DataTableColumn isActionColumn>
          <DataTableAction
            onClick={updatingPermissions}
            icon={refreshIcon}
            loading={checkIsProcessingView}
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default UsersGroupList;
