import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { AxiosResponse } from 'axios';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import AutoComplete from 'components/base-components/AutoComplete';
import useAutoCompleteState from './state';

interface Props {
  listAction: (params) => Promise<AxiosResponse>;
  mapFunction: (data) => DropdownOptionModel[];
  filters?: { [field: string]: string | number };
  id?: string;
  label?: string;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  allowIncompleteValue?: boolean;
  allowClear?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  suffixIcon?: ReactNode;
  tagRender?: (props) => ReactElement;
  value: DropdownOptionModel;
  onChange: (event) => void;
  onFocus?: (event) => void;
  onBlur?: (event) => void;
  disabledAdd?: boolean;
  onAddClick?: () => void;
  className?: string;
  inputClassName?: string;
  onInfinityScroll?: () => void;
  required?: boolean;
  error?: string;
  mR?: boolean;
  preventAutoFetch?: boolean;
  dropdownMatchSelectWidth?: boolean;
}

const AsyncAutoComplete: FunctionComponent<Props> = (props) => {
  const {
    state: {
      value,
      otherProps,
      loading,
      options,
      keepFetching,
    },
    actions: {
      handleOnClear,
      handleOnSearch,
      handleOnChange,
      handleOnBlur,
      getNextPage,
    },
  } = useAutoCompleteState(props);

  return (
    <AutoComplete
      {...otherProps}
      value={value}
      filterOption={false}
      loading={loading}
      options={options}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onClear={handleOnClear}
      onSearch={handleOnSearch}
      onInfinityScroll={getNextPage}
      useInfinityScroll={keepFetching}
    />
  );
};

AsyncAutoComplete.defaultProps = {
  filters: {},
  preventAutoFetch: false,
};

export default AsyncAutoComplete;
