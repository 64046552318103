import { flatTreeSelectLabel } from 'helpers/dropdown-options';

export const pricePayload = ({ options, productOptions, model, price }) => {
  const normalizedOptions = productOptions
    .reduce((opt, option) => {
      if (option?.value && options.includes(option?.value)) {
        const { parent, child } = flatTreeSelectLabel(option?.value);
        const optionInfo = {
          name: option.item?.name,
          custom: option.item?.custom,
          cost: option.item?.cost,
        };

        return {
          ...opt,
          [parent]: child !== 'TBD' ? optionInfo : undefined,
        };
      }
      return opt;
    }, {});
  return {
    products: [{
      model,
      options: normalizedOptions,
      price,
    }]
  };
};
