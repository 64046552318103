import { mapGenericDropdownData } from 'helpers/dropdown-options';
import { mapFileFromUrl } from 'helpers/file';
import { ProductModel } from 'models/product';
import { ProductFormModel } from '..';

const defaultProduct: ProductFormModel = {
  name: '',
  nameFr: '',
  model: '',
  mpn: '',
  price: '0',
  cost: '0',
  weight: '0',
  description: '',
  descriptionFr: '',
  status: false,
  category: undefined,
  manufacturer: undefined,
  image: []
};

export default function getFormData(product: ProductModel) {
  if (product) {
    const { category, manufacturer, image, ...rest } = product;

    return {
      ...rest,
      category: mapGenericDropdownData(category, { fields: { value: 'url', label: 'name' } }),
      manufacturer: mapGenericDropdownData(manufacturer, { fields: { value: 'url', label: 'companyName' } }),
      image: mapFileFromUrl(image),
    };
  }

  return defaultProduct;
}
