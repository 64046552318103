import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Button from 'components/base-components/Button';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Empty from 'components/base-components/Empty';
import Drawer from 'components/base-components/Drawer';
import { CheckboxGroup } from 'components/base-components/Checkbox';
import OnetimeTaskForm from './Form';
import './styles.scss';
import useNotesState from './state';

export interface TasksHistorySateModel {
  taskForm: {
    orderId: number;
    jobTitle: string;
  };
}

export function mapOrderStatusOptions(statuses) {
  return mapGenericDropdownOptions(statuses, { fields: { value: 'id', label: 'status' } });
}

const TaskForm: FunctionComponent = () => {
  const { goBack, location: { state: stateNavigation } } = useHistory();
  const {
    taskForm: { orderId, jobTitle },
  } = stateNavigation as TasksHistorySateModel;

  const {
    state: {
      fetching,
      task,
      taskDone,
      formData,
      saving,
      errors,
      onetimeForm
    },
    actions: {
      setFormValue,
      setErrors,
      handleOnetimeForm,
      onSave,
      handleOnChange,
    }
  } = useNotesState(orderId);

  return (
    <Drawer
      title={`${orderId} - ${jobTitle} - Tasks`}
      visible
      onClose={goBack}
      width="30vw"
    >
      <div className="task__container-list">
        <RenderIf isTrue={fetching}>
          <div className="task__loading-container">
            <Spinner size="small" centered={false} />
          </div>
        </RenderIf>
        <RenderIf isTrue={!task.length && !fetching}>
          <Empty />
        </RenderIf>
        <CheckboxGroup
          value={taskDone}
          options={task}
          onChange={handleOnChange}
        />
      </div>
      <Button onClick={handleOnetimeForm} label="Add One Time Task" />
      <OnetimeTaskForm
        formData={formData}
        errors={errors}
        setFormValue={setFormValue}
        setErrors={setErrors}
        onSubmit={onSave}
        onCancel={handleOnetimeForm}
        visible={onetimeForm}
        saving={saving}
      />
    </Drawer>
  );
};

export default TaskForm;
