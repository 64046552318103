import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import hiddenColumnsHandlerReducer, { FormActions, ParentColumns, Breakpoints } from './reducer';
import onChangeHiddenColumns from './actions/on-change-hidden-columns';
import columnsManager from './actions/columns-manager';

export default function useHiddenColumnsHandler(
  view: string,
  columnOptions: DropdownOptionModel[],
  originBreakpoints: Breakpoints,
  originParentsColumns: ParentColumns
) {
  const [state, dispatch] = useReducer(
    hiddenColumnsHandlerReducer,
    {
      parentColumns: originParentsColumns,
      breakpoints: originBreakpoints,
      localStorageKey: `${view}-columns-data`,
      columnOptions,
      hiddenColumns: [],
    }
  );

  const { parentColumns, localStorageKey, breakpoints, hiddenColumns } = state;

  useEffect(() => {
    dispatch({ type: FormActions.GET_HIDDEN_COLUMNS });
  }, []);

  return {
    hiddenColumns,
    handleHiddenColumns: useCallback(onChangeHiddenColumns(dispatch, localStorageKey), [localStorageKey]),
    breakpoint: useMemo(
      () => columnsManager(hiddenColumns, breakpoints, parentColumns), [hiddenColumns, breakpoints, parentColumns]
    ),
  };
}
