import { useCallback, useState } from 'react';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';

export default function useCreateGroup(onClose) {
  const [group, setGroup] = useState({ group: '' });

  const handleOnSubmit = useCallback(() => {
    notifyEventChannel(EventChannelList.QUOTE_PAGE_NEW_GROUP, group);
    setGroup({ group: '' });
    onClose();
  }, [group, onClose]);

  return {
    state: {
      group,
    },
    actions: {
      handleValueChange: setGroup,
      handleOnSubmit,
    },
  };
}
