import projectsApi from 'api/projects';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { QuoteViewActions } from '../reducer';

export default function updateContactProject(dispatch, projectId) {
  return (user) => {
    if (!projectId) {
      notification({
        type: NotificationType.ERROR,
        message: 'Select a Project to switch Contact',
      });
      return;
    }

    dispatch({ type: QuoteViewActions.START_UPDATING_FILTERS });
    const payload = {
      id: projectId,
      client: user.cognitoId,
    };

    const onSuccess = () => {
      dispatch({
        type: QuoteViewActions.UPDATE_CONTACT_FILTER,
        payload: { value: user.cognitoId, label: `${user.firstName} ${user.lastName}`, update: true }
      });
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const onFinally = () => {
      dispatch({ type: QuoteViewActions.STOP_UPDATING_FILTERS });
    };

    // eslint-disable-next-line consistent-return
    return projectsApi.patch(payload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
