import { AccountApplicationModel, AccountApplicationStatus } from 'models/account-application';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import accountApplicationApi from 'api/account-application';
import { AccountApplicationTableActions } from '../reducer';

export function handleAccountApproval(dispatch) {
  return (accountApplicationToChange: AccountApplicationModel, newStatus: AccountApplicationStatus) => {
    dispatch({ type: AccountApplicationTableActions.START_SAVING });

    const {
      ref1BusinessName,
      ref1ContactEmail,
      ref1ContactPhone,
      ref2BusinessName,
      ref2ContactEmail,
      ref2ContactPhone,
      ref3BusinessName,
      ref3ContactEmail,
      ref3ContactPhone,
      taxExempt,
      ...rest
    } = accountApplicationToChange;

    const accountApplicationChanged = {
      ...rest,
      status: newStatus,
      ref1_business_name: ref1BusinessName,
      ref1_contact_email: ref1ContactEmail,
      ref1_contact_phone: ref1ContactPhone,
      ref2_business_name: ref2BusinessName,
      ref2_contact_email: ref2ContactEmail,
      ref2_contact_phone: ref2ContactPhone,
      ref3_business_name: ref3BusinessName,
      ref3_contact_email: ref3ContactEmail,
      ref3_contact_phone: ref3ContactPhone,
      taxExempt: taxExempt instanceof File ? taxExempt : undefined,
    };

    const onSuccess = () => {
      notifyEventChannel(EventChannelList.ACCOUNT_APPLICATIONS_LIST_CHANGED);
      const message = `Account application was ${newStatus}`;
      notification({ type: NotificationType.INFO, message });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: AccountApplicationTableActions.FINISH_SAVING });
    };

    return accountApplicationApi.update(accountApplicationChanged)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
