import { useCallback, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { CutListModel } from 'models/cut-list';
import cutListApi from 'api/cut-list';
import notification, { NotificationType } from 'helpers/notification';
import useGetOne from 'hooks/base/get-one';
import setErrors from './actions/set-errors';
import setFormValue from './actions/set-form-value';
import setTargetKeys from './actions/set-target-keys';
import onSubmit from './actions/on-submit';
import cutListReducer, { CutListFormState, cutListFormActions } from './reducer';

const initialState: CutListFormState = {
  targetKeys: [],
  createForm: { name: '' },
  errors: {},
  updating: false,
  dataRow: [],
  setKeys: [],
};

export default function useCutListFormState(cutListId) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(cutListReducer, initialState);
  const { createForm, targetKeys, setKeys } = state;

  const {
    fetching: fetchingFormData,
    data: cutList,
    error: cutListError,
  } = useGetOne<CutListModel>(cutListApi, cutListId);

  useEffect(() => {
    if (cutListId && cutList) {
      const dataRow = cutList.cutlistsource.map(e => ({
        id: e.sourceId,
        jobName: e.jobTitle,
        key: `${e.sourceType}-${e.sourceId}`,
        type: e.sourceType,
        source: {
          sourceType: e.sourceType,
          sourcesId: e.sourceId
        }
      }));
      dispatch({
        type: cutListFormActions.UPDATE_STATE,
        payload: {
          dataRow,
          createForm: { name: cutList.name, id: cutList.id },
          keys: dataRow.map(e => e.key),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cutList]);

  useEffect(() => {
    if (cutListError) {
      notification({
        type: NotificationType.ERROR,
        message: cutListError,
      });
    }
  }, [cutListError]);

  return {
    state: {
      ...state,
      fetchingFormData
    },
    actions: {
      setErrors: useCallback(setErrors(dispatch), []),
      setFormValue: useCallback(setFormValue(dispatch), []),
      setTargetKeys: useCallback(setTargetKeys(dispatch, createForm.id, setKeys), [createForm.id, setKeys]),
      onSubmit: useCallback(onSubmit(dispatch, createForm, targetKeys, goBack), [createForm, targetKeys]),
    },
  };
}
