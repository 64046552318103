import { AxiosResponse } from 'axios';
import { BuilderModel } from 'models/builder';
import builderApi from 'api/builder';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import getEventValue from 'helpers/get-event-value';
import normalizeModel from 'components/pages/Builder/state/actions/normalize-model';
import { AddProduct3DFormActions } from '../reducer';

export default function handleStyleChange(dispatch) {
  return (event) => {
    dispatch({ type: AddProduct3DFormActions.START_FETCHING_PRODUCT });
    const style = getEventValue(event);
    dispatch({ type: AddProduct3DFormActions.SET_STYLE, payload: style });

    const onSuccess = (response: AxiosResponse<BuilderModel>) => {
      const payload = normalizeModel(response.data, true);
      const action = AddProduct3DFormActions.ADD_PRODUCTS;

      dispatch({ type: action, payload });
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
      dispatch({ type: AddProduct3DFormActions.STOP_FETCHING_PRODUCT });
    };

    return builderApi.getDetailsByModel(style.label)
      .then(onSuccess)
      .catch(onError);
  };
}
