/* eslint-disable */
import * as THREE from "three";
import Core from "../core";
import HalfEdge from "./half_edge";

const defaultRoomTexture = {
  url: `${Core.Configuration.getStringValue(
    "defaultPathPrefix"
  )}/rooms/textures/marbletiles.jpg`,
  scale: 4,
};

/**
 * A Room is the combination of a Floorplan with a floor plane.
 */
export default class Room {
  /** */
  interiorCorners = [];

  /** */
  edgePointer = null;

  /** floor plane for intersection testing */
  floorPlane = null;

  /** */
  customTexture = false;

  /** */
  floorChangeCallbacks = [];

  /**
   *  ordered CCW
   */
  constructor(floorplan, corners) {
    this.floorplan = floorplan;
    this.corners = corners;
    this.updateWalls();
    this.updateInteriorCorners();
    this.generatePlane();
  }

  getUuid() {
    const cornerUuids = Core.Utils.map(this.corners, (c) => {
      return c.id;
    });
    cornerUuids.sort();
    return cornerUuids.join();
  }

  fireOnFloorChange(callback) {
    this.floorChangeCallbacks.push(callback);
  }

  getTexture() {
    const uuid = this.getUuid();
    const tex = this.floorplan.getFloorTexture(uuid);
    return tex || defaultRoomTexture;
  }

  /**
   * textureStretch always true, just an argument for consistency with walls
   */
  setTexture(
    textureUrl,
    textureStretch = true,
    textureScale = 1,
    textureWidth = 0.5,
    textureHeight = 0.5
  ) {
    console.log(
      "set texture of room",
      textureUrl,
      textureStretch,
      textureScale
    );
    const uuid = this.getUuid();
    this.floorplan.setFloorTexture(
      uuid,
      textureUrl,
      textureScale,
      textureWidth,
      textureHeight
    );
    // this.floorChangeCallbacks.fire();
    this.floorChangeCallbacks.forEach((cb) => typeof cb === "function" && cb());
  }

  generatePlane() {
    const points = [];
    this.interiorCorners.forEach((corner) => {
      points.push(new THREE.Vector2(corner.x, corner.y));
    });
    const shape = new THREE.Shape(points);
    const geometry = new THREE.ShapeGeometry(shape);
    this.floorPlane = new THREE.Mesh(
      geometry,
      new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
      })
    );
    this.floorPlane.visible = false;
    this.floorPlane.rotation.set(Math.PI / 2, 0, 0);
    this.floorPlane.room = this; // js monkey patch
  }

  cycleIndex(index) {
    if (index < 0) {
      return (index += this.corners.length);
    }
    return index % this.corners.length;
  }

  updateInteriorCorners() {
    let edge = this.edgePointer;
    while (true) {
      this.interiorCorners.push(edge.interiorStart());
      edge.generatePlane();
      if (edge.next === this.edgePointer) {
        break;
      } else {
        edge = edge.next;
      }
    }
  }

  /**
   * Populates each wall's half edge relating to this room
   * this creates a fancy doubly connected edge list (DCEL)
   */
  updateWalls() {
    let prevEdge = null;
    let firstEdge = null;

    for (let i = 0; i < this.corners.length; i++) {
      const firstCorner = this.corners[i];
      const secondCorner = this.corners[(i + 1) % this.corners.length];

      // find if wall is heading in that direction
      const wallTo = firstCorner.wallTo(secondCorner);
      const wallFrom = firstCorner.wallFrom(secondCorner);

      if (wallTo) {
        var edge = new HalfEdge(this, wallTo, true);
      } else if (wallFrom) {
        edge = new HalfEdge(this, wallFrom, false);
      } else {
        // something horrible has happened
        console.log("corners arent connected by a wall, uh oh");
      }

      if (i === 0) {
        firstEdge = edge;
      } else {
        edge.prev = prevEdge;
        prevEdge.next = edge;
        if (i + 1 === this.corners.length) {
          firstEdge.prev = edge;
          edge.next = firstEdge;
        }
      }
      prevEdge = edge;
    }

    // hold on to an edge reference
    this.edgePointer = firstEdge;
  }
}
