import { CalendarActions } from '../reducer';

export default function handleViewModeChange(dispatch) {
  return (viewMode) => {
    dispatch({
      type: CalendarActions.SET_VIEW_MODE,
      payload: viewMode,
    });
  };
}
