import axios, { AxiosResponse } from 'axios';
import { AttachmentDocument } from 'models/attachment-document';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/attachment-document/`;

interface AttachmentDocumentApiModel extends ApiModel<AttachmentDocument> {
  getOption: () => Promise<AxiosResponse | void>;
}

const attachmentDocumentApi: AttachmentDocumentApiModel = {
  ...getApi(endpoint, ApiContentType.MULTIPART, true),
  getOption() {
    return axios.options(endpoint);
  }
};

export default attachmentDocumentApi;
