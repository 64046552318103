import React, { FunctionComponent, useCallback, useState } from 'react';
import { Row } from 'antd';
import { SubOption } from 'models/option';
import { Text } from 'components/base-components/Typography';
import Button from 'components/base-components/Button';
import PopConfirm from 'components/base-components/PopConfirm';

interface Props {
  data: SubOption;
  handleOnDelete: (setDeleting, subOption) => any;
  handleOnSelect: (subOption: SubOption) => any;
  isSelected: boolean;
}

const SubOptionItem: FunctionComponent<Props> = (props) => {
  const { handleOnDelete, data, handleOnSelect, isSelected } = props;
  const [deleting, setDeleting] = useState(false);
  const onDelete = useCallback(() => handleOnDelete(setDeleting, data), [handleOnDelete, data]);
  const onSelect = useCallback(() => handleOnSelect(data), [handleOnSelect, data]);
  const type = isSelected ? 'success' : undefined;

  return (
    <dd id={data.name}>
      <Row justify="space-between">
        <Button type="text" onClick={onSelect}>
          <Text type={type} strong>{data.name}</Text>
        </Button>
        <PopConfirm title="Sure to delete?" onConfirm={onDelete}>
          <Button size="small" danger loading={deleting} icon="fa fa-trash" mR />
        </PopConfirm>
      </Row>
    </dd>
  );
};

export default SubOptionItem;
