import { ConvertToOrderActions } from '../reducer';

export default function setProductValues(dispatch) {
  return (productId: number, options) => {
    dispatch({
      type: ConvertToOrderActions.SET_PRODUCT_VALUES,
      payload: { [productId]: options },
    });
  };
}
