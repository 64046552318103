import React from 'react';
import { useHistory } from 'react-router';
import { AccountModel } from 'models/account';
import { UserModel } from 'models/user';
import { ProjectModel } from 'models/project';
import { QuoteModel } from 'models/quote';
import {
  mapGenericDropdownOptions,
  mapQuotesDropdownOptions,
} from 'helpers/dropdown-options';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { AccountFormHistorySate } from 'components/pages/Account/Form';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  label: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export const AccountLabel = ({ label, url }) => {
  const { push } = useHistory();

  const openEditAccountForm = () => {
    const historyLocationState: AccountFormHistorySate = {
      accountForm: {
        showQuickForm: false,
        url,
      },
    };
    push(ModalRouteHash.AccountForm, historyLocationState);
  };

  return (
    <div style={styles.container as any}>
      <span data-el="account-name" style={styles.label as any}>{label}</span>
      <Button icon="fa fa-pen" type="link" onClick={openEditAccountForm} />
    </div>
  );
};

export const UserLabel = ({ user, onChange }) => {
  const onCLick = (e) => {
    e.stopPropagation();
    onChange(user);
  };

  return (
    <div style={styles.container as any}>
      <span style={styles.label as any}>{`${user.firstName} ${user.lastName}`}</span>
      <Button icon="fa fa-sync" type="link" onClick={onCLick} />
    </div>
  );
};

export function mapAccounts(accounts: AccountModel[]) {
  const accountOptions = mapGenericDropdownOptions(
    accounts,
    {
      fields: { label: 'businessLegalName', value: 'url' },
      extra: [
        'businessTradeName',
        'allUsersDetails',
        'taxDetails',
        'groupDetail',
        'businessAddress',
        'businessCity',
        'businessPostCode',
      ],
    },
  );

  return accountOptions.map((account) => ({
    ...account,
    label: <AccountLabel label={account.label} url={account.value} />,
  }));
}

export function mapUsers(onChange) {
  return (users: UserModel[]) => users.map(user => ({
    value: user.cognitoId,
    label: <UserLabel key={user.cognitoId} user={user} onChange={onChange} />
  }));
}

export function mapProjects(projects: ProjectModel[]) {
  return mapGenericDropdownOptions(
    projects,
    {
      fields: { value: 'id', label: 'jobTitle' },
      extra: ['client'],
    },
  );
}

export function mapQuotes(quotes: QuoteModel) {
  return mapQuotesDropdownOptions(quotes);
}
