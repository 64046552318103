import { TableActions } from '../reducer';

export function handleFilter(dispatch) {
  return (filter: any) => {
    dispatch({
      type: TableActions.HANDLE_FILTER_CHANGE,
      payload: filter,
    });
  };
}
