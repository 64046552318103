import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { checkAuthMiddleware } from './middlewares/checkAuth';

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware(), checkAuthMiddleware],
    preloadedState
  });

  // @ts-ignore
  if (process.env.ENV === 'dev' && module.hot) {
    // @ts-ignore
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const newRootReducer = require('./reducers').default;
      store.replaceReducer(newRootReducer);
    });
  }

  return store;
}
