import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router';
import categoriesApi from 'api/category';
import productApi from 'api/product';
import useCallApiAction from 'hooks/base/call-api-action';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import { getModel } from 'helpers/builder-util';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import { capitalizeFirstLetter } from 'helpers/formatting';
import updateProperties from 'components/pages/Builder/state/actions/update-properties';
import updateOptions from 'components/pages/Builder/state/actions/update-options';
import onChangeCategory from './actions/on-change-category';
import onStyleChange from './actions/on-change-style';
import onChangeSecondLevel from './actions/on-change-second-level';
import setStaticFormData from './actions/set-static-form-data';
import addProduct from './actions/save-product';
import handleNewCustomOption from './actions/handle-new-custom-option';
import openCustomOptionModal from './actions/open-custom-option-modal';
import addProduct3DFormReducer, { initialState } from './reducer';
import { setGroupValue } from './actions/set-group-value';
import { AddProductsMandatory } from '..';

export default function useAddProduct3DFormState(
  historyState: AddProductsMandatory = { groups: [], quote: null },
) {
  const { quote, groups, discount } = historyState;
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    addProduct3DFormReducer,
    { ...initialState, groupForSelect: groups },
  );

  const { category, selectedProduct, groupForSelect, staticForm, secondLevel } = state;

  const haveAnotherLevelProperty = useMemo(
    () => (category?.dropdownItem?.levelProperties && category?.dropdownItem?.levelProperties?.[1] !== 'style')
      ? capitalizeFirstLetter(category?.dropdownItem?.levelProperties?.[1])
      : false, [category]
  );
  const {
    fetching: loadingThirdLevel,
    data: thirdLevelData,
  } = useGetOne(categoriesApi, secondLevel?.value, { preventAutoFetch: !haveAnotherLevelProperty });

  const {
    fetching: loadingCategories,
    data: categories,
  } = useCallApiAction(categoriesApi.getTopLevel, null, []);

  const pricePayload = useMemo(() => {
    if (selectedProduct) {
      const { options, modelDescription } = selectedProduct;
      const model = getModel(modelDescription);
      const normalizedOptions = Object.keys(options).reduce((opt, optionType) => {
        return ({
          ...opt,
          [optionType]: {
            name: options[optionType]?.value,
            cost: options[optionType]?.dropdownItem
              ? options[optionType].dropdownItem?.cost
              : options[optionType]?.cost,
          },
        });
      }, {});
      return {
        products: [{
          model,
          options: normalizedOptions,
          price: null,
        }]
      };
    }
    return [];
  }, [selectedProduct]);

  const {
    fetching: fetchingPrice,
    data: { data: priceData },
    error: errorPrice,
  } = useCallApiAction<any>(
    productApi.getPrice,
    null,
    { data: [] },
    !selectedProduct,
    pricePayload,
  );

  const handleNewGroup = useCallback(setGroupValue(dispatch), []);
  const handleCustomOption = useCallback(handleNewCustomOption(dispatch), []);
  useEventCenterUpdate(
    EventChannelList.QUOTE_PAGE_NEW_CUSTOM_OPTION,
    handleCustomOption,
  );
  useEventCenterUpdate(EventChannelList.QUOTE_PAGE_NEW_GROUP, handleNewGroup);
  useEffect(() => {
    if (errorPrice) {
      notification({
        type: NotificationType.ERROR,
        message: errorPrice,
      });
    }
  }, [errorPrice]);

  return {
    state: {
      ...state,
      loadingCategories,
      loadingThirdLevel,
      fetchingPrice,
      haveAnotherLevelProperty,
      totalPrice: useMemo(() => {
        const price = priceData.length > 0 ? priceData[0].price : 0;
        const totalPrice = discount ? price - (price * (discount / 100)) : price;
        return (totalPrice).toFixed(2);
      }, [discount, priceData]),
      categories: useMemo(() => mapGenericDropdownOptions(categories, {
        copyFullItem: true,
        fields: { value: 'url', label: 'name' },
      }), [categories]),
      secondLevelOptions: useMemo(
        () => category?.dropdownItem
          ? mapGenericDropdownOptions(
            category.dropdownItem.categories,
            { fields: { value: 'url', label: 'name' } },
          )
          : [], [category]
      ),
      thirdLevelOptions: useMemo(
        () => !!thirdLevelData
          ? mapGenericDropdownOptions(
            thirdLevelData.categories,
            { fields: { value: 'url', label: 'name' } },
          )
          : [], [thirdLevelData]
      ),
      groupsOptions: useMemo(() => (
        groupForSelect.map(group => ({
          value: group,
          label: group === '' ? 'None' : group,
        }))
      ), [groupForSelect]),
    },
    actions: {
      openCustomOptionModal,
      onChangeCategory: useCallback(onChangeCategory(dispatch), []),
      onStyleChange: useCallback(onStyleChange(dispatch), []),
      onChangeSecondLevel: useCallback(onChangeSecondLevel(dispatch), []),
      setStaticFormData: useCallback(setStaticFormData(dispatch), []),
      saveProduct: useCallback(
        addProduct(dispatch, selectedProduct, discount, quote, staticForm, priceData, goBack),
        [selectedProduct, staticForm, quote, discount, priceData]
      ),
      updateProperties: useCallback(
        updateProperties(dispatch, selectedProduct),
        [selectedProduct],
      ),
      updateOptions: useCallback(
        updateOptions(dispatch, selectedProduct),
        [selectedProduct],
      ),
    }
  };
}
