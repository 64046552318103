import { warning } from 'components/base-components/ModalV2';
import { OrderProductModel } from 'models/order';
import { EventChannelList, listenToEventChannel } from 'helpers/event-center';
import { deleteEntry } from './delete-entry';

export function handleDeleteRow() {
  return (entryToDelete: OrderProductModel) => {
    const handleOnOk = () => deleteEntry(entryToDelete);

    const modal = warning({
      title: 'Delete Order Product',
      content: `Are you sure that you want to delete ${entryToDelete.model}`,
      okText: 'Delete',
      okType: 'danger',
      okButtonProps: {
        type: 'primary',
      },
      autoFocusButton: null,
      onOk: handleOnOk,
      okCancel: true,
      centered: true,
      zIndex: 1050,
      maskClosable: true,
    });

    listenToEventChannel(EventChannelList.DATATABLE_DELETING, (deleting) => {
      modal.update({
        cancelButtonProps: ({ disabled: deleting }),
        keyboard: !deleting,
        maskClosable: !deleting,
      });
    });
  };
}
