import {
  BuilderOptionGroup,
  BuilderProperties,
  BuilderPropertyType,
  BuilderRangeProperty,
} from 'models/builder';
import {
  BuilderFormModel,
  BuilderFormModelDescription,
  BuilderFormModelBuilderOptions, BuilderFormModelOptions,
} from '../types';

function normalizeValue(value, builderOption: BuilderProperties) {
  const { propertyType } = builderOption;

  if (!value || propertyType !== BuilderPropertyType.RANGE) {
    return value;
  }

  const { minValue, maxValue } = builderOption as BuilderRangeProperty;
  return Math.min(maxValue, Math.max(minValue, value));
}

function normalizeModelDescription(
  nextBuilderOptions: BuilderFormModelBuilderOptions,
  nextModelDescription: BuilderFormModelDescription,
  prevModelDescription: BuilderFormModelDescription,
) {
  return Object.keys(nextBuilderOptions)
    .filter((property) => (
      nextBuilderOptions[property].propertyType === BuilderPropertyType.RANGE ||
      nextBuilderOptions[property].propertyType === BuilderPropertyType.SELECT ||
      nextBuilderOptions[property].propertyType === BuilderPropertyType.SELECT_REFRESH
    ))
    .reduce((properties, property) => {
      const value = normalizeValue(
        prevModelDescription[property],
        nextBuilderOptions[property],
      );

      return {
        ...properties,
        [property]: value || properties[property],
      };
    }, nextModelDescription);
}

function normalizeOptions(
  nextOptionGroups: BuilderOptionGroup[],
  nextOptions: BuilderFormModelOptions,
  prevOptions: BuilderFormModelOptions,
) {
  return nextOptionGroups.reduce((options, group) => {
    const { name } = group;
    const prevOption = prevOptions[name];

    return {
      ...options,
      [name]: prevOption || options[name],
    };
  }, nextOptions);
}

export default function normalizeSwapModel(
  prevProduct: BuilderFormModel,
  nextProduct: BuilderFormModel,
): BuilderFormModel {
  const {
    modelDescription: prevModelDescription,
    options: prevOptions,
  } = prevProduct;

  const {
    builderOptions: nextBuilderOptions,
    modelDescription: nextModelDescription,
    optionGroups: nextOptionGroups,
    options: nextOptions,
  } = nextProduct;

  const normalizedProperties = normalizeModelDescription(
    nextBuilderOptions,
    nextModelDescription,
    prevModelDescription,
  );

  const normalizedOptions = normalizeOptions(
    nextOptionGroups,
    nextOptions,
    prevOptions,
  );

  return {
    ...nextProduct,
    id: prevProduct.id,
    sceneOptions: prevProduct.sceneOptions,
    modelDescription: normalizedProperties,
    options: normalizedOptions,
  };
}
