import { InstallerBookingDTO } from 'models/project-coordinator';
import { resolveApiErrorMessage } from 'api/base';
import installerBookingApi from 'api/installer-booking';
import shipmentApi from 'api/shipment';
import { validateEntity } from 'helpers/form-validations';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import getPayload, { installerBookingToDTO } from './get-payload';
import { installerFormActions, InstallerFormModel } from '../reducer';
import { validationRules } from '../validation-rules';

function onCreate(payload) {
  const { projectId, ...rest } = payload;
  const queryParams = {
    filters: {
      project: projectId,
    }
  };

  return shipmentApi.list(queryParams)
    .then((response) => installerBookingApi.create({
      ...rest,
      shipment: response?.data.results[0].id,
    }));
}

export default function onSave(
  dispatch, formData: InstallerFormModel, oldData: InstallerBookingDTO[], orderId: number
) {
  return () => {
    dispatch({ type: installerFormActions.START_SAVING });

    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: installerFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    const onSuccess = ({ data }) => {
      const message = 'Installer Successfully Saved';
      notification({ type: NotificationType.SUCCESS, message });
      const dataFormatted = installerBookingToDTO(data);

      const payload = formData.id
        ? oldData.map(inst => formData.id === inst.id ? dataFormatted : inst)
        : oldData.concat(dataFormatted);

      notifyEventChannel(EventChannelList.PROJECT_COORDINATOR_UPDATE, { id: orderId, installerBookings: payload });
      dispatch({ type: installerFormActions.SET_DATA, payload });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: installerFormActions.FINISH_SAVING });
    };

    const action = !!formData.id ? installerBookingApi.patch : onCreate;
    const payload = getPayload(formData);

    return action(payload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
