import React, { FunctionComponent } from 'react';
import { ShipmentAddressModel, ShipmentContactModel } from 'models/shipment';
import { AccountModel } from 'models/account';
import { formatDisplayDate } from 'helpers/formatting';

interface Props {
  createdDate: string;
  account: AccountModel;
  contact: ShipmentContactModel;
  address: ShipmentAddressModel;
}

const Details: FunctionComponent<Props> = (props) => {
  const {
    createdDate,
    account: {
      businessPostCode,
      businessTradeName,
      businessAddress,
      businessCity,
    },
    contact: {
      firstName,
      lastName,
    },
    address: {
      locationName,
      postalCode,
      province: { name: province },
      streetName,
      streetNumber,
    },
  } = props;

  return (
    <div className="quote-report__details">
      <div className="quote-report__details__row">
        <div className="quote-report__details__row__cell">
          <b>Billed To:</b>
          <span>{`${businessTradeName || ''}`}</span>
          <span>{`${businessAddress || ''}`}</span>
          <span>{`${businessCity || ''} ${businessPostCode || ''}`}</span>
        </div>
        <div className="quote-report__details__row__cell align-end">
          <b>Shipped To:</b>
          <span>{firstName && lastName ? `${firstName} ${lastName}` : ''}</span>
          <span>{`${streetNumber || ''} ${streetName || ''}`}</span>
          <span>{locationName || ''}</span>
          <span>{`${province || ''} ${postalCode || ''}`}</span>
        </div>
      </div>
      <div className="quote-report__details__row">
        <div className="quote-report__details__row__cell">
          <b>Payment Method:</b>
          <span>Visa ending **** 4242</span>
          <span>jsmith@email.com</span>
        </div>
        <div className="quote-report__details__row__cell align-end">
          <b>Quote Date</b>
          <span>{formatDisplayDate(createdDate)}</span>
        </div>
      </div>
    </div>
  );
};

export default Details;
