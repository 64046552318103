import quoteApi from 'api/quote';
import purchaseOrderApi from 'api/purchase-order';
import shipmentApi from 'api/shipment';
import orderApi from 'api/orders';
import invoiceApi from 'api/invoice';
import invoiceProformaApi from 'api/proforma-invoice';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { validateEntity } from 'helpers/form-validations';
import { EmailSender } from '..';
import { SendEmailFormActions } from '../reducer';
import { validationRules } from '../validation-rules';

export default function sendEmail(
  dispatch,
  emailSender: EmailSender,
  quoteId: number,
  email: { recipients: string[]; [x: string]: any },
  closeModal,
) {
  return () => {
    dispatch({ type: SendEmailFormActions.START_SENDING_EMAIL });
    const { hasErrors, errors } = validateEntity(email, validationRules);

    if (hasErrors) {
      dispatch({
        type: SendEmailFormActions.SET_ERRORS,
        payload: errors,
      });

      return;
    }

    const onSuccess = () => {
      notification({
        type: NotificationType.SUCCESS,
        message: 'Emails sent successfully'
      });
      closeModal();
    };

    const onError = (error) => {
      dispatch({ type: SendEmailFormActions.STOP_SENDING_EMAIL });
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const apiMap: { [sender: string]: any } = {
      [EmailSender.Quote]: quoteApi.sendEmailV2,
      [EmailSender.QuoteProposal]: quoteApi.sendEmailV2,
      [EmailSender.QuoteQuestionnaire]: shipmentApi.emailQuestionnaire,
      [EmailSender.PurchaseOrder]: purchaseOrderApi.sendEmail,
      [EmailSender.PurchaseOrderFromProjectCoordinator]: quoteApi.sendEmailV2,
      [EmailSender.Order]: orderApi.sendEmail,
      [EmailSender.Invoice]: invoiceApi.sendEmail,
      [EmailSender.ProformaInvoice]: invoiceProformaApi.sendEmail,
    };

    const action = apiMap[emailSender];
    const { recipients, ...rest } = email;

    action(quoteId, recipients, rest)
      .then(onSuccess)
      .catch(onError);
  };
}
