import React from 'react';

export default function nameTemplate(data) {
  const { firstName, lastName, title } = data;
  return (
    <div>
      <div>{`${firstName} ${lastName}`}</div>
      <div className="text-secondary">{title}</div>
    </div>
  );
}
