import { TaskFormActions } from '../reducer';

export default function setErrors(dispatch) {
  return (errors) => {
    dispatch({
      type: TaskFormActions.SET_ERRORS,
      payload: errors,
    });
  };
}
