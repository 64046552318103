import React, { FunctionComponent, useCallback, useState } from 'react';
import { Row } from 'antd';
import { InstallerBookingDTO } from 'models/project-coordinator';
import { formatDisplayDate } from 'helpers/formatting';
import { Text } from 'components/base-components/Typography';
import Button from 'components/base-components/Button';
import PopConfirm from 'components/base-components/PopConfirm';

interface Props {
  data: InstallerBookingDTO;
  handleOnDelete: (setDeleting, installer: InstallerBookingDTO) => any;
  handleOnSelect: (installer: InstallerBookingDTO) => any;
  isSelected: boolean;
}

const InstallerItem: FunctionComponent<Props> = (props) => {
  const { handleOnDelete, data, handleOnSelect, isSelected } = props;
  const [deleting, setDeleting] = useState(false);
  const onDelete = useCallback(() => handleOnDelete(setDeleting, data), [handleOnDelete, data]);
  const onSelect = useCallback(() => handleOnSelect(data), [handleOnSelect, data]);
  const type = isSelected ? 'success' : undefined;

  return (
    <dd>
      <Row justify="space-between">
        <Button type="text" onClick={onSelect}>
          <Text type={type} strong>{`${data.installer.companyName} - ${formatDisplayDate(data.installationDate)}`}</Text>
        </Button>
        <PopConfirm title="Sure to delete?" onConfirm={onDelete}>
          <Button size="small" danger loading={deleting} icon="fa fa-trash" mR />
        </PopConfirm>
      </Row>
    </dd>
  );
};

export default InstallerItem;
