import useCallApiAction from 'hooks/base/call-api-action';
import { useMemo, useState } from 'react';
import { buildDatasets } from './util';

interface AxisY{
  title: string;
  borderColor: string;
}

export interface UseBarChartParams{
  action;
  queryParams: {};
  label: string;
  axisY?: AxisY[];
  pieFrame?: string;
}

export default function useCharts(params: UseBarChartParams) {
  const { action, label, axisY, queryParams, pieFrame } = params;
  const {
    fetching,
    data,
  } = useCallApiAction(action, queryParams, []);
  const [colors, setColors] = useState([]);

  const state = useMemo(() => (buildDatasets(
    data,
    label,
    axisY,
    pieFrame,
    colors,
    setColors,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  )), [data, label, axisY, pieFrame]);

  return {
    ...state,
    fetching,
    data,
  };
}
