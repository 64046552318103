import React from 'react';
import { Table } from 'antd';
import { QueryResults } from 'hooks/ui/use-media-queries';
import getColumnProps from './get-column-props';

function resolveColumn(props, queryResults: QueryResults) {
  const {
    columnKey,
    editable,
    onSubmit,
    onSubmitSuccess,
    hideColumn,
    showBreakpoint,
    ...otherColumnProps
  } = props;

  const normalizedProps = getColumnProps(otherColumnProps);
  const matchesBreakPoint = showBreakpoint && queryResults[showBreakpoint];

  if (hideColumn || (showBreakpoint && !matchesBreakPoint)) {
    return null;
  }

  const finalColumnProps = editable
    ? {
      ...normalizedProps,
      onCell: (record, index) => ({
        data: record,
        editable,
        render: normalizedProps.render,
        width: 140,
        onSubmit,
        onSubmitSuccess,
        id: `${index}-${otherColumnProps.dataIndex}`,
        ...otherColumnProps,
      }),
    }
    : normalizedProps;

  return <Table.Column {...finalColumnProps} key={columnKey} />;
}

export default function getColumns(children, onSubmit, onSubmitSuccess, queryResults) {
  return React.Children.map(children, ({ props }: any) => {
    const {
      children: childrenColumn,
      columnGroup,
      ...rest
    } = props;

    if (childrenColumn && columnGroup) {
      const childrenColumns = getColumns(
        childrenColumn,
        onSubmit,
        onSubmitSuccess,
        queryResults,
      );

      const matchesBreakPoint = rest.showBreakpoint && queryResults[rest.showBreakpoint];

      if (rest.hideColumn || (rest.showBreakpoint && !matchesBreakPoint)) {
        return null;
      }

      return (
        <Table.ColumnGroup {...rest}>
          {childrenColumns}
        </Table.ColumnGroup>
      );
    }
    return resolveColumn(
      {
        ...rest,
        children: childrenColumn,
        onSubmit,
        onSubmitSuccess,
      },
      queryResults,
    );
  });
}
