import { useEffect, useMemo } from 'react';
import { SheetModel } from 'models/sheet';
import sheetApi from 'api/sheet';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { validationRules } from './validations';

const defaultSheet: SheetModel = {
  code: '',
  x: '',
  y: '',
};

const getPayload = (sheet) => ({
  ...sheet,
  x: parseFloat(sheet.x).toFixed(4),
  y: parseFloat(sheet.y).toFixed(4)
});

const getFormData = (sheet?: SheetModel) => sheet || defaultSheet;

export default function useSheetFormState(sheetId: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: sheetApi,
    eventChannel: EventChannelList.SHEET_LIST_CHANGED,
    param: sheetId,
    getPayload,
    getFormData,
    validationRules
  }), [sheetId]);

  const {
    state: {
      formData,
      errors,
      saving,
      errorFetchingEntity,
      fetchingEntity,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<SheetModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingEntity) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingEntity,
      });
    }
  }, [errorFetchingEntity]);

  return {
    state: {
      formData,
      saving,
      fetchingEntity,
      errors,
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  };
}
