import React, { FunctionComponent, useMemo } from 'react';
import Button from 'components/base-components/Button';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Upload from 'components/base-components/Upload';
import DropZone from 'components/base-components/DropZone';
import Breadcrumb from 'components/base-components/BreadcrumbV2';
import BreadcrumbItem from 'components/base-components/BreadcrumbV2/BreadcrumbItem';
import InputAddFolder from './InputAddFolder';
import useFileViewerState from './state';
import './styles.scss';

interface Props{
  quoteId: number;
  filesRender: (files: string[], fetchingFiles: boolean, params?: string) => any;
  onUploadFile?: (params: string, fileName: string) => any;
}
const FileViewer: FunctionComponent<Props> = (props) => {
  const { quoteId, filesRender, onUploadFile } = props;
  const {
    state: {
      fetchingFiles,
      data,
      params,
      file,
      uploading,
      addFolder,
      addingFolder,
      route
    },
    actions: {
      goBackFile,
      openDirectory,
      uploadFile,
      dropInsideFolder,
      addNewFolder,
      showNewFolderInput,
      onCancel
    },
  } = useFileViewerState(quoteId, onUploadFile);

  const directories = useMemo(() => (
    data.directories.map((directoryName, index) => (
      <DropZone
        key={`directory-${index}`}
        onDrop={dropInsideFolder(directoryName)}
      >
        <dd>
          <Button
            type="text"
            onClick={() => openDirectory(directoryName)}
            onDoubleClick={() => openDirectory(directoryName)}
            disabled={fetchingFiles}
          >
            <i className="fa fa-folder directory-icon" />
            {directoryName}
          </Button>
        </dd>
      </DropZone>
    ))
  ), [data, openDirectory, fetchingFiles, dropInsideFolder]);

  const breadcrumbItems = useMemo(() => route.map((directory, index) => (
    <BreadcrumbItem key={index}>{directory}</BreadcrumbItem>
  )), [route]);

  const files = useMemo(
    () => filesRender(data.files, fetchingFiles, params), [data, fetchingFiles, filesRender, params]
  );

  return (
    <div className="fix-height-wrap">
      <dl>
        <dt>
          <div className="route">
            <Breadcrumb>
              <BreadcrumbItem>Quote</BreadcrumbItem>
              {breadcrumbItems}
            </Breadcrumb>
          </div>
          <div>
            <Button
              onClick={showNewFolderInput}
              disabled={fetchingFiles || addFolder}
              loading={addingFolder}
              mR
            >
              Add Folder
            </Button>
            <div className="upload-wrapper">
              <Upload
                onChange={uploadFile}
                value={file}
                loading={uploading}
                disabled={fetchingFiles}
              />
            </div>
          </div>
        </dt>
        <div className="scrollable-wrap">
          <RenderIf isTrue={!!params}>
            <dd>
              <Button type="text" onClick={goBackFile}>
                <i className="fa fa fa-arrow-left" />
              </Button>
            </dd>
          </RenderIf>
          <RenderIf isTrue={addFolder}>
            <dd>
              <InputAddFolder
                onCreate={addNewFolder}
                onCancel={onCancel}
                loading={addingFolder}
              />
            </dd>
          </RenderIf>
          <RenderIf isTrue={!fetchingFiles} fallback={<Spinner centered />}>
            {directories}
            <DropZone onDrop={uploadFile} mayBeEmpty>
              {files}
            </DropZone>
          </RenderIf>
        </div>
      </dl>
    </div>
  );
};

export default FileViewer;
