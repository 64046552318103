import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router';
import { ThreeModelPosition } from 'models/three-model';
import { PropertySettingModel } from 'models/property-setting';
import { resolveApiErrorMessage } from 'api/base';
import threeModelPositionApi from 'api/three-model-position';
import propertySettingApi from 'api/property-setting';
import useGetList from 'hooks/base/get-list';
import notification, { NotificationType } from 'helpers/notification';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import setModel from './actions/set-model';
import setErrors from './actions/set-errors';
import initFormData from './actions/init-form-data';
import saveThreeModel from './actions/save-three-model';
import handleFileChange from './actions/handle-file-change';
import addMorph from './actions/add-morph';
import changeMorph from './actions/change-morph';
import removeMorph from './actions/remove-morph';
import threeModelFormReducer, { ThreeFormState } from './reducer';

export * from './validations';
export * from './reducer';

const initialState: ThreeFormState = {
  threeModel: {} as any,
  fetchingFormData: true,
  savingModel: false,
  errors: {},
};

export default function use3DModelFormState() {
  const { location: { state: navigationState }, goBack } = useHistory();
  const [state, dispatch] = useReducer(threeModelFormReducer, initialState);

  const {
    fetching: fetchingPositions,
    data: positions,
    error: positionErrors,
  } = useGetList<ThreeModelPosition>(threeModelPositionApi);

  const {
    fetching: fetchingProperties,
    data: properties,
    error: propertiesErrors,
  } = useGetList<PropertySettingModel>(propertySettingApi);

  useEffect(() => {
    if (positionErrors) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(positionErrors),
      });
    }
  }, [positionErrors]);

  useEffect(() => {
    if (propertiesErrors) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(propertiesErrors),
      });
    }
  }, [propertiesErrors]);

  useEffect(() => {
    const model = (navigationState as any)?.threeModelForm?.model;
    initFormData(dispatch, model);
  }, [navigationState]);

  return {
    state: {
      ...state,
      fetchingFormData: (
        state.fetchingFormData ||
        fetchingPositions ||
        fetchingProperties
      ),
      positions: useMemo(() => (
        mapGenericDropdownOptions(
          positions,
          { fields: { value: 'url', label: 'description' } }
        )
      ), [positions]),
      properties: useMemo(() => (
        mapGenericDropdownOptions(
          properties,
          { fields: { value: 'url', label: 'name' } }
        )
      ), [properties]),
    },
    actions: {
      handleFileChange,
      setModel: useCallback(setModel(dispatch), []),
      addMorph: useCallback(addMorph(dispatch), []),
      changeMorph: useCallback(changeMorph(dispatch), []),
      removeMorph: useCallback(removeMorph(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      saveThreeModel: useCallback(
        saveThreeModel(dispatch, state.threeModel, goBack),
        [state.threeModel],
      ),
    },
  };
}
