import { AssignFilesModel } from 'models/file-viewer';

export enum AssignFilesFormActions {
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE',
  START_UPDATING = 'START_COPPING',
  FINISH_UPDATING = 'FINISH_COPPING',
}

interface AssignFilesFormState {
  data: { [x: string]: AssignFilesModel };
  updating: boolean;
  errors: any;
}

export default function assignFilesFormReducer(
  state: AssignFilesFormState,
  action
): AssignFilesFormState {
  const { type, payload } = action;

  switch (type as AssignFilesFormActions) {
    case AssignFilesFormActions.SET_FORM_VALUE:
      return { ...state, data: { ...state.data, ...payload } };
    case AssignFilesFormActions.UPDATE_FORM_VALUE:
      return { ...state, data: payload };
    case AssignFilesFormActions.START_UPDATING:
      return { ...state, updating: true };
    case AssignFilesFormActions.FINISH_UPDATING:
      return { ...state, updating: false };
    default:
      return state;
  }
}
