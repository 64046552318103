import React from 'react';

function gatherColumnQueriesRecursive(children, result: string[]) {
  React.Children
    .forEach(children, ({ props }: any) => {
      if (props.children && props.columnGroup) {
        gatherColumnQueriesRecursive(props.children, result);
      }
      if (props.showBreakpoint) {
        result.push(props.showBreakpoint);
      }
    });
  return result;
}

export function gatherColumnQueries(childrenColumn) {
  const result = [];
  return gatherColumnQueriesRecursive(childrenColumn, result);
}

export function queryBuilder(query: number) {
  return `(min-width: ${query}px)`;
}
