import { RuleType } from 'helpers/form-validations';

const requiredRule = { type: RuleType.Required, message: 'This field is required' };
const minRule = { type: RuleType.Min, message: 'This field must be greater than 0', value: 0 };

export const validationRules = {
  name: [requiredRule],
  description: [requiredRule],
  code: [requiredRule],
  manufacturer: [requiredRule],
  textureHeight: [minRule],
  textureWidth: [minRule],
};
