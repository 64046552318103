import { UpdateShippingActions } from '../reducer';

export function setShipmentValue(dispatch) {
  return (shipment) => {
    dispatch({
      type: UpdateShippingActions.SET_SHIPMENT,
      payload: { ...shipment, change: true },
    });
  };
}
