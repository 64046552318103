const REACT_APP_BLUEPRINT_ASSETS_PREFIX = 'https://blueprint3d-react-dist.netlify.app/';

export const data = {
  floorplan: {
    corners: {
      'f90da5e3-9e0e-eba7-173d-eb0b071e838e': {
        x: -3,
        y: 2
      },
      'da026c08-d76a-a944-8e7b-096b752da9ed': {
        x: 3,
        y: 2
      },
      '4e3d65cb-54c0-0681-28bf-bddcc7bdb571': {
        x: 3,
        y: -2
      },
      '71d4f128-ae80-3d58-9bd2-711c6ce6cdf2': {
        x: -3,
        y: -2
      }
    },
    walls: [
      {
        corner1: '71d4f128-ae80-3d58-9bd2-711c6ce6cdf2',
        corner2: 'f90da5e3-9e0e-eba7-173d-eb0b071e838e',
        frontTexture: {
          url: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/rooms/textures/blank.png`,
          stretch: true,
          scale: 0
        },
        backTexture: {
          url: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/rooms/textures/blank.png`,
          stretch: true,
          scale: 0
        }
      },
      {
        corner1: 'f90da5e3-9e0e-eba7-173d-eb0b071e838e',
        corner2: 'da026c08-d76a-a944-8e7b-096b752da9ed',
        frontTexture: {
          url: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/rooms/textures/blank.png`,
          stretch: true,
          scale: 0
        },
        backTexture: {
          url: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/rooms/textures/blank.png`,
          stretch: true,
          scale: 0
        }
      },
      {
        corner1: 'da026c08-d76a-a944-8e7b-096b752da9ed',
        corner2: '4e3d65cb-54c0-0681-28bf-bddcc7bdb571',
        frontTexture: {
          url: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/rooms/textures/blank.png`,
          stretch: true,
          scale: 0
        },
        backTexture: {
          url: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/rooms/textures/blank.png`,
          stretch: true,
          scale: 0
        }
      },
      {
        corner1: '4e3d65cb-54c0-0681-28bf-bddcc7bdb571',
        corner2: '71d4f128-ae80-3d58-9bd2-711c6ce6cdf2',
        frontTexture: {
          url: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/rooms/textures/blank.png`,
          stretch: true,
          scale: 0
        },
        backTexture: {
          url: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/rooms/textures/blank.png`,
          stretch: true,
          scale: 0
        }
      }
    ],
    wallTextures: [],
    floorTextures: {},
    newFloorTextures: {}
  },
  items: []
};
