import React, { FunctionComponent, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import Blueprint3DProductViewer from 'lib/Blueprint3D-cart-set-builder';
import * as Categories from 'lib/products/category';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import Modal from 'components/base-components/ModalV2';
import SetList from './SetList';
import PropertySection from './propertySection';
import './style.scss';
import LeftToolbar from './leftToolbar';

const SetModal: FunctionComponent = () => {
  const { goBack } = useHistory();
  const [setInfo, setSetInfo] = useState(null);
  const [showSetList, setShowSetList] = useState(false);
  const elViewer = useRef(null);

  const onListClose = useCallback(() => setShowSetList(false), []);

  const replaceModel = useCallback((id: string, product: object, option?: any) => {
    if (!elViewer || !elViewer.current) return;
    elViewer.current.replaceModel(id, product, option);
  }, [elViewer]);

  const setMorph = useCallback((id: string, index: number, value: number) => {
    if (!elViewer || !elViewer.current) return;
    elViewer.current.setMorph(id, index, value);
  }, [elViewer]);

  const setMaterial = useCallback((id: string, target: string, material: string) => {
    if (!elViewer || !elViewer.current) return;
    elViewer.current.setMaterial(id, target, material);
  }, [elViewer]);

  const setStyle = useCallback((id: string, hideName: string, showName: string) => {
    if (!elViewer || !elViewer.current) return;
    elViewer.current.setStyle(id, hideName, showName);
  }, [elViewer]);

  const updateProperties = useCallback((id: string, properties: object) => {
    if (!id || !properties) return;
    elViewer.current.updateRestrictions(id, properties);
  }, [elViewer]);

  const handleOverlapDetected = () => {
    // console.log("overlap detected");
  };

  const onOk = useCallback(() => {
    const items = elViewer.current.exportSetData();
    notifyEventChannel(EventChannelList.BUILDER_ADD_NEW_SET_PRODUCT, items);
    goBack();
  }, [goBack]);

  return (
    <Modal
      title="Set Product Group"
      visible
      onCancel={goBack}
      onOk={onOk}
      width="80vw"
    >
      <div className="set-group-builder__wrap">
        <LeftToolbar onClickAddSet={() => setShowSetList((oldValue) => !oldValue)} />
        <div className="set-group-builder__product-viewer-container">
          <Blueprint3DProductViewer
            ref={elViewer}
            data={Categories.CAT_PDESK.styles[2]}
            set={setInfo}
            onOverlapDetected={handleOverlapDetected}
          />
          <div className="set-group-builder__set-list-container" hidden={!showSetList}>
            <SetList
              onSetClicked={setSetInfo}
              onClose={onListClose}
            />
          </div>
        </div>
        <div className="set-group-builder__property-section">
          <PropertySection
            setInfo={setInfo}
            productChanged={replaceModel}
            morphChanged={setMorph}
            materialChanged={setMaterial}
            styleChanged={setStyle}
            propertiesChanged={updateProperties}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SetModal;
