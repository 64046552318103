import optionApi from 'api/option';
import notification, { NotificationType } from 'helpers/notification';
import { Actions, TextureModel } from '../reducer';

export default function saveOption(dispatch, texture: TextureModel) {
  return () => {
    dispatch({ type: Actions.START_CALLING_API });

    const onSuccess = () => {
      notification({
        type: NotificationType.SUCCESS,
        message: 'Texture updated'
      });
    };

    const onError = () => {
      notification({
        type: NotificationType.ERROR,
        message: 'Failed to update the texture',
      });
    };

    const onFinally = () => {
      dispatch({ type: Actions.FINISH_CALLING_API });
    };

    const { option, width, height } = texture;
    const payload = {
      url: option.dropdownItem.url,
      textureWidth: width,
      textureHeight: height,
    };

    optionApi.patch(payload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
