import { OrderDTO } from 'models/order';
import orderApi from 'api/orders';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function printMaterial(order: OrderDTO) {
  const { id } = order;
  orderApi.printMaterial(id)
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}
