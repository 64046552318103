import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import productApi from 'api/product';
import getEventValue from 'helpers/get-event-value';
import { CustomProductFormActions } from '../reducer';
import { buildRow } from './build-row';

export function readExcel(dispatch, formData, quote, optionsGroupList, discount) {
  return (event) => {
    const excel = getEventValue(event)[0];
    dispatch({ type: CustomProductFormActions.SET_EXCEL, payload: event });
    dispatch({ type: CustomProductFormActions.START_READING });

    const onSuccess = async (response) => {
      const newData = formData.filter(item => item.model);
      const dataSource = newData.filter(row => !row.model || !row.name);
      const { data } = response;
      let key = newData.length;
      // eslint-disable-next-line no-restricted-syntax
      for (const productResponse of data) {
        // eslint-disable-next-line no-await-in-loop
        const row = await buildRow({ ...productResponse, key }, optionsGroupList, quote, discount);
        key += 1;
        newData.push(row);
      }
      dispatch({
        type: CustomProductFormActions.SET_ROW_ERROR, payload: dataSource.map(row => row.key)
      });
      dispatch({ type: CustomProductFormActions.SET_FORM_VALUE, payload: newData });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CustomProductFormActions.FINISH_READING });
    };

    return productApi.readXlsFile(excel)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
