import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import installerApi from 'api/installer';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import DataTable from 'components/base-components/DataTableV2';
import Help from 'components/forms/CommonHelp';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { InstallerFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: installerApi,
  deleteModalTitle: 'Delete Installer',
  deleteModalContent: (installerToDelete) => `Are you sure that you want to delete the Installer ${installerToDelete ? installerToDelete.companyName : ''}?`,
  eventChannel: EventChannelList.INSTALLER_LIST_CHANGE,
  defaultSortField: '-id'
};

const InstallerList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<{ companyName: string; id: number }>(useTableStateParams);

  const openInstallerFormModal = useCallback((installer?: any) => {
    const historyLocationState: InstallerFormHistorySateModel = {
      installerForm: {
        installerId: installer ? installer.id : undefined,
      },
    };
    push(ModalRouteHash.InstallerForm, historyLocationState);
  }, [push]);

  return (
    <Page title="Installers">
      <Help view="installers" title="Installers List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addInstaller}>
          <Button
            onClick={openInstallerFormModal}
            label="Add Installer"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="companyName" columnKey="companyName" title="Name" sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editInstaller}
          deletePermissions={permissions.deleteInstaller}
          onEdit={openInstallerFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default InstallerList;
