import { GroupedReportModel, InvoiceReportModel } from 'models/invoice-report';

export default function buildFilterOptions(
  data: (InvoiceReportModel | GroupedReportModel)[],
  grouped: boolean,
  mapFunction: (item) => string,
) {
  const values = grouped
    ? (data as GroupedReportModel[]).flatMap(batch => batch.data.map(mapFunction))
    : (data as InvoiceReportModel[]).map(mapFunction);

  return [...new Set(['None', ...values])]
    .map(value => ({ value, label: value }));
}
