import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router';
import useHiddenColumnsHandler from 'hooks/ui/hidden-columns-handler';
import DataTable from 'components/base-components/DataTableV2';
import Page, { PageActions } from 'components/base-components/Page';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import Select from 'components/base-components/Select';
import NumberInput from 'components/base-components/NumberInput';
import Help from 'components/forms/CommonHelp';
import AccessControl from 'components/base-components/AccessControl';
import Button from 'components/base-components/Button';
import useProductionManagerState from './state';
import materialsRender from './templates/materials-templates';
import readyDateRender from './templates/ready-date-template';
import installerBookingRender from './templates/installers-template';
import progressTemplate from './templates/progress-template';
import ProductionCalendar from './Calendar';
import { Owner } from './ConfirmReadyDate';
import { permissions } from './permissions';
import {
  helpDescription,
  parentColumns,
  breakpoints,
  columnOptions
} from './constants';
import './styles.scss';

const ProductionManager: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
      productionStatusOptions,
      showCalender,
      filtersProductOption,
      linkToOrder
    },
    actions: {
      handleOnChange,
      handleFilter,
      printInstallerOrder,
      printShopOrder,
      printDeficiencyReport,
      onSubmit,
      handleShowCalendar,
      updateDataTable,
      openPOItemsProd,
      openNotesModal,
    }
  } = useProductionManagerState();

  const {
    breakpoint,
    handleHiddenColumns,
    hiddenColumns,
  } = useHiddenColumnsHandler('production-manager', columnOptions, breakpoints, parentColumns);

  const orderedRender = useCallback(materialsRender('Ordered', push), []);
  const receivedRender = useCallback(materialsRender('Received', push), []);
  const cncPercentageRender = useCallback(progressTemplate('cncPercentage'), []);
  const fabricationPercentageRender = useCallback(progressTemplate('fabricationPercentage'), []);
  const installerRender = useCallback(installerBookingRender('installer'), []);
  const installationDateRender = useCallback(installerBookingRender('installationDate'), []);
  const dateRender = useCallback(readyDateRender(push, Owner.Production), []);

  return (
    <Page title="Production Manager">
      <Help view="production-manager" title="Production Manager" description={helpDescription} />
      <PageActions primary>
        <AccessControl permissions={permissions.changeOrder}>
          <Button
            onClick={handleShowCalendar}
            label="Calendar"
            mR
            mT
          />
          <Button
            onClick={openPOItemsProd}
            label="Purchase Order"
            mT
          />
        </AccessControl>
        <Select
          className="fixed-column-select"
          placeholder="Hidden Columns"
          onChange={handleHiddenColumns}
          value={hiddenColumns}
          maxTagCount="responsive"
          mode="multiple"
          options={columnOptions}
          label="Hidden Columns"
          size="middle"
        />
      </PageActions>
      <DataTable
        loading={fetching}
        dataSource={data}
        totalRecords={count}
        page={page}
        onChange={handleOnChange}
        className="production-manager__table"
        onSubmit={onSubmit}
        onFilter={handleFilter}
        onSubmitSuccess={updateDataTable}
      >
        <DataTableColumn
          columnGroup
          title="JOB INFO"
          className="force-border-top"
          hideColumn={breakpoint.job_info}
        >
          <DataTableColumn
            title="Order"
            dataIndex="id"
            columnKey="id"
            width={65}
            sorter
            hideColumn={breakpoint.id}
            link={linkToOrder}
          />
          <DataTableColumn
            title="Company"
            dataIndex="company"
            columnKey="company"
            trimAt={17}
            hideColumn={breakpoint.company}
          />
          <DataTableColumn
            title="Job Title"
            dataIndex="jobTitle"
            columnKey="job_title"
            trimAt={25}
            hideColumn={breakpoint.job_title}
          />
          <DataTableColumn
            title="Rep. Owner"
            dataIndex="repOwner"
            columnKey="rep_owner"
            trimAt={17}
            hideColumn={breakpoint.rep_owner}
          />
        </DataTableColumn>
        <DataTableColumn
          hideColumn={breakpoint.job_info}
          className="no-border-bottom"
        />
        <DataTableColumn
          columnGroup
          title="MATERIALS"
          hideColumn={breakpoint.materials}
          className="force-border-top"
        >
          <DataTableColumn
            title="Ordered"
            className="button-cell"
            columnKey="ordered"
            render={orderedRender}
            hideColumn={breakpoint.ordered}
          />
          <DataTableColumn
            title="Received"
            columnKey="received"
            className="button-cell"
            render={receivedRender}
            hideColumn={breakpoint.received}
          />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
          hideColumn={breakpoint.materials}
        />
        <DataTableColumn
          columnGroup
          title="SHIPPING"
          className="force-border-top"
          hideColumn={breakpoint.shipping}
        >
          <DataTableColumn
            title="Installer"
            render={installerRender}
            hideColumn={breakpoint.installer}
          />
          <DataTableColumn
            title="Inst. Date"
            render={installationDateRender}
            hideColumn={breakpoint.inst_date}
          />
          <DataTableColumn
            title="Ready Date"
            className="button-cell"
            columnKey="ready_date"
            render={dateRender}
            width="120px"
            sorter
            hideColumn={breakpoint.ready_date}
          />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
          hideColumn={breakpoint.shipping}
        />
        <DataTableColumn columnGroup title="DETAILS" className="force-border-top">
          <DataTableColumn
            title="Cutlist"
            dataIndex="readyDate"
            isDate
            hideColumn
          />
          <DataTableColumn
            title="CNC"
            dataIndex="cncPercentage"
            columnKey="cnc_percentage"
            render={cncPercentageRender}
            editable
            sorter
            allowDecimals
            component={NumberInput}
            width={70}
            min={0}
            max={100}
            hideColumn={breakpoint.cnc_percentage}
          />
          <DataTableColumn
            title="Fab"
            render={fabricationPercentageRender}
            dataIndex="fabricationPercentage"
            columnKey="fabrication_percentage"
            editable
            allowDecimals
            component={NumberInput}
            width={70}
            min={0}
            max={100}
            sorter
            hideColumn={breakpoint.fabrication_percentage}
          />
          <DataTableColumn
            title="Shipping"
            dataIndex="productionStatus"
            columnKey="production_status"
            component={Select}
            options={productionStatusOptions}
            filters={filtersProductOption}
            editable
            alwaysShowInput
            sorter
            hideColumn={breakpoint.production_status}
          />
          <DataTableColumn
            title="Package"
            isActionColumn
            width="110px"
          >
            <DataTableAction
              onClick={openNotesModal}
              icon="fas fa-lightbulb"
              label="Notes"
              badge="noteCount"
              permissions={permissions.changeNote}
            />
            <DataTableAction
              onClick={printInstallerOrder}
              label="Install"
              icon="fa fa-tools"
            />
            <DataTableAction
              onClick={printShopOrder}
              label="Shop"
              icon="fa fa-shopping-bag"
            />
            <DataTableAction
              onClick={printDeficiencyReport}
              label="Deficiency Report"
              icon="bx bxs-report"
            />
          </DataTableColumn>
        </DataTableColumn>
      </DataTable>
      <ProductionCalendar
        visible={showCalender}
        handleVisible={handleShowCalendar}
      />
    </Page>
  );
};

export default ProductionManager;
