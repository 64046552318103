import { ApplyOptionsFormActions } from '../reducer';

export function handleNewCustomOption(dispatch) {
  return ({ option, parent }) => {
    const mappedOption = {
      key: option.name,
      label: option.name,
      value: option.name,
      dropdownItem: option,
    };

    dispatch({
      type: ApplyOptionsFormActions.SET_CUSTOM_OPTION,
      payload: { option: mappedOption, parent },
    });
  };
}
