import { useCallback, useEffect, useMemo, useReducer } from 'react';
import useCallApiAction from 'hooks/base/call-api-action';
import useDebounce from 'hooks/ui/use-debounce';
import asyncSelectReducer, { AsyncSelectActions, AsyncSelectState } from './reducer';
import handleOnSearch from './actions/handle-on-search';
import handleOnClear from './actions/handle-on-clear';
import getNextPage from './actions/get-next-page';
import handleOnBlur from './actions/handle-on-blur';

const initialState: AsyncSelectState = {
  page: 1,
  searchValue: '',
  searchTerm: '',
  preventAutoFetch: false,
  data: [],
};

export default function useAutoCompleteState(options) {
  const { debounceCall } = useDebounce(250);

  const {
    value,
    onChange,
    onBlur,
    allowIncompleteValue,
    listAction,
    mapFunction,
    filters,
    preventAutoFetch,
    ...otherProps
  } = options;

  const [state, dispatch] = useReducer(asyncSelectReducer, initialState);

  const {
    page,
    data,
    searchTerm,
    searchValue,
    preventAutoFetch: innerPreventAutoFetch,
  } = state;

  const params = useMemo(() => {
    const { search, ...otherFilters } = filters;
    return {
      filters: {
        ...otherFilters,
        page,
        search: search ? [search, searchTerm] : searchTerm,
      },
    };
  }, [page, searchTerm, filters]);

  const {
    fetching,
    data: { results, count },
  } = useCallApiAction(
    listAction,
    params,
    { results: [], count: 0 },
    innerPreventAutoFetch,
  );

  useEffect(() => {
    dispatch({ type: AsyncSelectActions.SET_PREVENT, payload: preventAutoFetch });
  }, [preventAutoFetch]);

  useEffect(() => {
    dispatch({
      type: page === 1 ? AsyncSelectActions.SET_DATA : AsyncSelectActions.PUSH_DATA,
      payload: mapFunction(results),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  useEffect(() => {
    const updateSearch = () => dispatch({
      type: AsyncSelectActions.SET_SEARCH_TERM,
      payload: searchValue,
    });

    debounceCall(updateSearch);
  }, [debounceCall, searchValue]);

  useEffect(() => {
    if (value && value.label !== searchTerm) {
      dispatch({
        type: AsyncSelectActions.SET_SEARCH_TERM,
        payload: value.label,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return {
    state: {
      value,
      otherProps,
      loading: fetching,
      options: data,
      keepFetching: useMemo(() => (page * 10) < count, [page, count]),
    },
    actions: {
      handleOnSearch: useCallback(handleOnSearch(dispatch), []),
      handleOnClear: useCallback(handleOnClear(dispatch), []),
      handleOnChange: onChange,
      handleOnBlur: useCallback(
        handleOnBlur(onChange, onBlur, value, searchValue, allowIncompleteValue),
        [onChange, onBlur, value, searchValue, allowIncompleteValue],
      ),
      getNextPage: useCallback(getNextPage(dispatch), []),
    },
  };
}
