import { DropdownOptionModel } from 'helpers/dropdown-options';
import { BuilderFormModel } from 'components/pages/Builder/state';

export enum AddProduct3DFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_CATEGORY = 'SET_CATEGORY',
  SET_SECOND_LEVEL = 'SET_SECOND_LEVEL',
  SET_STYLE = 'SET_STYLE',
  ADD_PRODUCTS = 'ADD_PRODUCTS',
  START_FETCHING_PRODUCT = 'START_FETCHING_PRODUCT',
  STOP_FETCHING_PRODUCT = 'START_FETCHING_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  SET_STATIC_FOR_DATA = 'SET_STATIC_FOR_DATA',
  SET_GROUP_FOR_SELECT = 'SET_GROUP_FOR_SELECT',
  START_ADDING = 'START_SAVING',
  FINISH_ADDING = 'STOP_SAVING',
  SET_CUSTOM_OPTION = 'SET_CUSTOM_OPTION',
}

interface AddProduct3DFormState {
  category: any;
  secondLevel?: any;
  style: any;
  selectedProduct: BuilderFormModel;
  fetchingProductData: boolean;
  errors: any;
  groupForSelect: string [];
  staticForm: {
    quantity: number;
    group: DropdownOptionModel;
  };
  adding: boolean;
}

export const initialState: AddProduct3DFormState = {
  secondLevel: undefined,
  style: undefined,
  category: undefined,
  errors: false,
  selectedProduct: undefined,
  fetchingProductData: false,
  staticForm: {
    quantity: 1,
    group: undefined,
  },
  groupForSelect: [],
  adding: false
};

export default function addProduct3DFormReducer(
  state: AddProduct3DFormState,
  action
): AddProduct3DFormState {
  const { type, payload } = action;

  switch (type as AddProduct3DFormActions) {
    case AddProduct3DFormActions.SET_CATEGORY:
      return {
        ...initialState,
        groupForSelect: state.groupForSelect,
        category: payload
      };
    case AddProduct3DFormActions.SET_SECOND_LEVEL:
      return {
        ...initialState,
        groupForSelect: state.groupForSelect,
        category: state.category,
        secondLevel: payload
      };
    case AddProduct3DFormActions.SET_STYLE:
      return { ...state, style: payload };
    case AddProduct3DFormActions.ADD_PRODUCTS:
      return {
        ...state,
        selectedProduct: payload,
        fetchingProductData: false,
      };
    case AddProduct3DFormActions.START_FETCHING_PRODUCT:
      return { ...state, fetchingProductData: true };
    case AddProduct3DFormActions.SET_STATIC_FOR_DATA:
      return { ...state, staticForm: payload };
    case AddProduct3DFormActions.SET_GROUP_FOR_SELECT:
      return { ...state, groupForSelect: [...new Set([...state.groupForSelect, payload])] };
    case AddProduct3DFormActions.STOP_FETCHING_PRODUCT:
      return { ...state, fetchingProductData: false, };
    case AddProduct3DFormActions.UPDATE_PRODUCT: {
      return {
        ...state,
        selectedProduct: payload,
        fetchingProductData: false,
      };
    }
    case AddProduct3DFormActions.SET_CUSTOM_OPTION: {
      const { selectedProduct } = state;
      const { options } = selectedProduct;
      const { option, parent } = payload;

      return {
        ...state,
        selectedProduct: {
          ...selectedProduct,
          options: {
            ...options,
            [parent]: option,
          }
        },
      };
    }
    case AddProduct3DFormActions.START_ADDING:
      return { ...state, adding: true };
    case AddProduct3DFormActions.FINISH_ADDING:
      return { ...state, adding: false };
    default:
      return state;
  }
}
