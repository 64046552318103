import { CategoryModel } from 'models/category';

export default function mapCategories(categories: CategoryModel[]) {
  return categories.map(category => ({
    title: category.name,
    key: category.name,
    selectable: false,
    children: category.categories.map(subCategory => ({
      title: subCategory.name,
      key: `${category.name}-${subCategory.name}`,
      url: subCategory.url,
      image: subCategory.image,
      selectable: true,
      isLeaf: true,
    })),
  }));
}
