import React, { FunctionComponent } from 'react';
import ErrorBoundary from 'components/base-components/ErrorBoundry';
import { errorScreen, fetchingScreen, noDataScreen } from '../WorkOrder/screens';
import Header from '../WorkOrder/Header';
import Details from './Details';
import ProductsList from './ProductsList';
import usePrintoutState from './state';
import './styles.scss';

const QuoteReport: FunctionComponent = () => {
  const {
    noData,
    fetching,
    error,
    quote,
    shipment,
    contact,
    account,
    address,
    extraCharges,
  } = usePrintoutState();

  if (noData) {
    return noDataScreen;
  }

  if (error) {
    return errorScreen;
  }

  if (fetching) {
    return fetchingScreen;
  }

  const { id, project: { jobTitle }, createdDate } = quote;

  return (
    <ErrorBoundary errorMessage={errorScreen}>
      <section className="work-order">
        <Header title="Quote" workOrder={id} jobTitle={jobTitle} />
        <Details
          createdDate={createdDate}
          contact={contact}
          account={account}
          address={address}
        />
        <ProductsList
          quote={quote}
          shipment={shipment}
          charges={extraCharges}
          taxes={account?.taxDetails}
        />
      </section>
    </ErrorBoundary>
  );
};

export default QuoteReport;
