import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ManufacturerModel } from 'models/manufacturer';
import { PurchaseOrderModel } from 'models/order';
import { resolveApiErrorMessage } from 'api/base';
import purchaseOrderApi from 'api/purchase-order';
import useCallApiAction from 'hooks/base/call-api-action';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';
import openFormModal from './actions/open-form-modal';
import getManufacturer from './actions/get-manufacturer';
import printPdf from './actions/print-pdf';

export default function usePOItemsListState(purchaseOrderId: string) {
  const history = useHistory();

  const {
    fetching: fetchingPurchaseOrder,
    data: purchaseOrder,
    error: purchaseOrderError,
  } = useGetOne<PurchaseOrderModel>(
    purchaseOrderApi,
    purchaseOrderId,
  );

  const {
    fetching: fetchingManufacturer,
    data: manufacturer,
    error: manufacturerError,
  } = useCallApiAction<ManufacturerModel>(
    getManufacturer,
    purchaseOrder?.manufacturer,
    {} as ManufacturerModel,
    fetchingPurchaseOrder || !purchaseOrder
  );

  useEffect(() => {
    if (purchaseOrderError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(purchaseOrderError),
      });
    }
  }, [purchaseOrderError]);

  useEffect(() => {
    if (manufacturerError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(manufacturerError),
      });
    }
  }, [manufacturerError]);

  return {
    state: {
      fetchingManufacturer,
      manufacturer,
      purchaseOrder
    },
    actions: {
      openFormModal: useCallback(
        openFormModal(history, purchaseOrderId),
        [history, purchaseOrderId],
      ),
      printPDF: useCallback(
        printPdf(purchaseOrderId),
        [purchaseOrderId],
      ),
    },
  };
}
