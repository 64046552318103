import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router';
import { ShippingOptions } from 'models/shipment';
import { resolveApiErrorMessage } from 'api/base';
import shipmentApi from 'api/shipment';
import shippingOptionsApi from 'api/shipment-options';
import useGetOne from 'hooks/base/get-one';
import useCallApiAction from 'hooks/base/call-api-action';
import notification, { NotificationType } from 'helpers/notification';
import {
  GenericMapOptions,
  mapDropdownOptions,
  mapGenericDropdownOptions,
} from 'helpers/dropdown-options';
import updateShippingFormReducer from './reducer';
import {
  copyAddressFromAccount,
  filterProvinces,
  handleCountryChange,
  initializeInitialState,
  setAddress,
  setAddressErrors,
  setFlatRate,
  setFlatRateErrors,
  setShipmentValue,
  updateShipping,
} from './actions';

const countryMapOptions: GenericMapOptions = {
  fields: { value: 'id', label: 'country' },
};
const shippingZoneMapOptions: GenericMapOptions = {
  fields: { value: 'id', label: 'zoneName' },
};
const shipmentTypeMapOptions: GenericMapOptions = {
  fields: { value: 'id', label: 'name' },
  copyFullItem: true,
};
const defaultDropdownOptions = { value: null, label: '' };
const defaultAddress = {
  locationName: null,
  streetNumber: null,
  streetName: null,
  unitNumber: null,
  postalCode: null,
  city: null,
  province: defaultDropdownOptions
};
const defaultShipment = {
  id: undefined,
  shipmentType: defaultDropdownOptions,
  shippingZone: defaultDropdownOptions,
};
const defaultFlatRate = {
  price: 0,
  shipment: null,
};
const initialState = {
  address: defaultAddress,
  changeAddress: false,
  changeShipment: false,
  shipment: defaultShipment,
  addressErrors: {},
  updating: false,
  selectedCountry: undefined,
  flatRate: defaultFlatRate,
  flatRateErrors: {},
};

const initialOptions: ShippingOptions = {
  shippingzones: [],
  shipmenttypes: [],
  countries: [],
  provinces: [],
};

export default function useUpdateShipping(shipmentId, accountAddress) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(updateShippingFormReducer, initialState);

  const {
    fetching: fetchingShipmentData,
    data: shipmentData,
    error: shipmentError
  } = useGetOne(shipmentApi, shipmentId);

  const {
    fetching: fetchingOptions,
    data: { countries, provinces, shippingzones, shipmenttypes },
    error: optionsError,
  } = useCallApiAction<ShippingOptions>(
    shippingOptionsApi.list,
    null,
    initialOptions,
  );

  const { address, shipment, flatRate } = state;
  const fetchError = shipmentError || optionsError;

  useEffect(() => {
    if (shipmentData) {
      initializeInitialState(dispatch, shipmentData);
    }
  }, [shipmentData]);

  useEffect(() => {
    if (fetchError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(fetchError),
      });
    }
  }, [fetchError]);

  return {
    state: {
      ...state,
      countries: useMemo(() => (
        mapGenericDropdownOptions(countries, countryMapOptions)
      ), [countries]),
      provinces: useMemo(() => (
        mapDropdownOptions(filterProvinces(provinces, state.selectedCountry))
      ), [provinces, state.selectedCountry]),
      shippingZones: useMemo(() => (
        mapGenericDropdownOptions(shippingzones, shippingZoneMapOptions)
      ), [shippingzones]),
      shipmentTypes: useMemo(() => (
        mapGenericDropdownOptions(shipmenttypes.filter(e => !!e.active), shipmentTypeMapOptions)
      ), [shipmenttypes]),
      fetchingShipmentData: useMemo(
        () => fetchingOptions || fetchingShipmentData,
        [fetchingShipmentData, fetchingOptions],
      ),
    },
    actions: {
      handleCountryChange: useCallback(handleCountryChange(dispatch), []),
      handleAddressChange: useCallback(setAddress(dispatch), []),
      handleShipmentChange: useCallback(setShipmentValue(dispatch), []),
      setAddressErrors: useCallback(setAddressErrors(dispatch), []),
      setFlatRateErrors: useCallback(setFlatRateErrors(dispatch), []),
      setFlatRate: useCallback(setFlatRate(dispatch), []),
      copyAddressFromAccount: useCallback(
        copyAddressFromAccount(dispatch, accountAddress),
        [],
      ),
      handleOnSubmit: useCallback(
        updateShipping(dispatch, shipment, address, flatRate, goBack),
        [address, shipment, flatRate],
      ),
    },
  };
}
