import { DropdownOptionModel } from 'helpers/dropdown-options';
import handleNewValue from './actions/handle-new-value';

export enum SelectActions {
  SET_PREVIEW_VALUE = 'SET_PREVIEW_VALUE',
  SET_INTERNAL_OPTIONS = 'SET_INTERNAL_OPTIONS',
}

export interface SelectState {
  prevValue: DropdownOptionModel[];
  internalOptions: DropdownOptionModel[];
}

export default function selectReducer(state: SelectState, action): SelectState {
  const { type, payload } = action;

  switch (type as SelectActions) {
    case SelectActions.SET_INTERNAL_OPTIONS:
      return { ...state, internalOptions: payload };
    case SelectActions.SET_PREVIEW_VALUE:
      return handleNewValue(state, payload);
    default:
      return state;
  }
}
