import { InvoiceReportModel } from 'models/invoice-report';
import isEdgeRow from './is-edge-row';

export default function repTemplate(row) {
  const { type } = row;

  if (isEdgeRow(type)) {
    return {
      children: null,
      props: { colSpan: 0 },
    };
  }

  const { repOwnerData } = row as InvoiceReportModel;

  return repOwnerData
    ? `${repOwnerData.firstName} ${repOwnerData.lastName}`
    : '-';
}
