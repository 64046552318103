import { resolveApiErrorMessage } from 'api/base';
import { FlowPlannerModel } from 'models/flow-planner';
import accountApi from 'api/account';
import flowPlannerApi from 'api/flow-planner';
import quoteApi from 'api/quote';
import notification, { NotificationType } from 'helpers/notification';
import { BuilderActions } from '../reducer';
import { BuilderFormModel } from '../types';
import { createQuoteFromDesign } from '../../AddToCartForm/on-add-to-cart';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/accounts/`;

const fakePromise = (data) => {
  return new Promise((resolve) => {
    resolve({ data });
  });
};

async function updateFlowPlanner(flowPlanner: FlowPlannerModel, products: BuilderFormModel[], productPrice: any[]) {
  let projectId = null;

  return quoteApi.get(flowPlanner.quoteId).then(({ data }) => {
    projectId = data.project.id;
    return quoteApi.delete(flowPlanner.quoteId);
  })
    .then(() => accountApi.get(`${endpoint}${flowPlanner.account}/`))
    .then(({ data }) => createQuoteFromDesign(projectId, flowPlanner, products, productPrice, data))
    .then(() => fakePromise(flowPlanner));
}

async function updateProject(flowPlanner, products: BuilderFormModel[], productPrice: any[]) {
  const conditionalActions = ({ data }) => !!data?.quoteId && !flowPlanner?.fromAddToCart
    ? updateFlowPlanner(data, products, productPrice)
    : fakePromise(data);

  return flowPlannerApi.patch(flowPlanner).then(conditionalActions);
}

export default function saveFlowPlanner(
  dispatch,
  products: BuilderFormModel[],
  productPrice: any[],
) {
  return async (response: FlowPlannerModel) => {
    const { plan, ...flowPlanner } = response;
    dispatch({ type: BuilderActions.START_FETCHING_PRODUCT });
    const payloadFlowPlanner = {
      ...flowPlanner,
      plan: JSON.stringify(plan)
    };

    const onSuccess = (axiosResponse) => {
      notification({
        type: NotificationType.SUCCESS,
        message: 'Design was updated successfully',
      });
      if (axiosResponse?.data) {
        dispatch({ type: BuilderActions.SET_FLOOR_PLANNER, payload: axiosResponse?.data });
      }
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: BuilderActions.STOP_FETCHING_PRODUCT });
    };

    const action = () => payloadFlowPlanner.id
      ? updateProject(payloadFlowPlanner, products, productPrice)
      : flowPlannerApi.create(payloadFlowPlanner);

    return action()
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
