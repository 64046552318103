import { FlatRate } from 'models/shipment';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_SHIPMENT_API } = process.env;

const endpoint = `${REACT_APP_SHIPMENT_API}/flatrate/`;

const flatRateApi: ApiModel<FlatRate> = {
  ...getApi<FlatRate>(endpoint, ApiContentType.JSON, true),
};

export default flatRateApi;
