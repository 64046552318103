import React, { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import { Units } from 'models/builder';
import Divider from 'components/base-components/Divider';
import Spinner from 'components/base-components/Spinner';
import { BuilderFormModel } from 'components/pages/Builder/state/types';
import { Options } from './Options';
import { Properties } from './Properties';
import './styles.scss';
import RenderIf from '../RenderIf';

export * from './Properties';
export * from './Options';
export * from './LabelOption';

interface Props {
  blockUI: boolean;
  product: BuilderFormModel;
  onOptionsChange: (product: BuilderFormModel) => void;
  onPropertiesChange: (product: BuilderFormModel) => void;
  onCustomOption?: (name: string) => void;
  asRow?: boolean;
}

const PropertiesPanel: FunctionComponent<Props> = (props) => {
  const { product, blockUI, onOptionsChange, onPropertiesChange, onCustomOption, asRow } = props;
  const className = useMemo(
    () => classnames('product-viewer__section', { asRow }),
    [asRow],
  );

  if (blockUI) {
    return (
      <div className="product-viewer__section">
        <Spinner />
      </div>
    );
  }

  if (!product) {
    return (
      <div className="product-viewer__section" />
    );
  }
  const { builderOptions, modelDescription, optionGroups, options } = product;

  return (
    <div className={className}>
      <div className="product-viewer__form">
        <RenderIf isTrue={!asRow}>
          <Divider className="product-viewer__divider">Properties</Divider>
        </RenderIf>
        <Properties
          modelDescription={modelDescription}
          builderOptions={builderOptions}
          onChange={onPropertiesChange}
          unit={Units.inches}
        />
      </div>
      <div className="product-viewer__form">
        <RenderIf isTrue={!asRow}>
          <Divider className="product-viewer__divider">Options</Divider>
        </RenderIf>
        <Options
          options={options}
          productOptions={optionGroups}
          onChange={onOptionsChange}
          onCustomOption={onCustomOption}
        />
      </div>
    </div>
  );
};

export default PropertiesPanel;
