import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router';
import useHiddenColumnsHandler from 'hooks/ui/hidden-columns-handler';
import Page, { PageActions } from 'components/base-components/Page';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Select from 'components/base-components/Select';
import Help from 'components/forms/CommonHelp';
import AccessControl from 'components/base-components/AccessControl';
import Button from 'components/base-components/Button';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import materialsRender from '../ProductionManager/templates/materials-templates';
import readyDateRender from '../ProductionManager/templates/ready-date-template';
import { Owner } from '../ProductionManager/ConfirmReadyDate';
import ProductionCalendar from '../ProductionManager/Calendar';
import installerBookingRender from './templates/installers-template';
import repTemplate from './templates/rep-template';
import progressTemplate from './templates/progress-template';
import orderedTemplate from './templates/ordered-template';
import { outstandingTemplate, paidTemplate } from './templates/transaction-template';
import useProjectCoordinator from './state';
import PurchaseOrderPC from './Materials';
import { permissions } from './permission';
import {
  columnOptions,
  breakpoints,
  helpDescription,
  parentColumns,
} from './constants';
import './styles.scss';

function getStatusPayload(data, newValue) {
  return ({
    ...data,
    orderStatus: newValue,
  });
}

const ProjectCoordinator: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
      orderStatusOptions,
      showCalender,
      showPO,
      filtersOrderStatus,
      filtersProductOption,
      linkToOrder,
    },
    actions: {
      handleOnChange,
      handleFilter,
      handleShowCalendar,
      openReceivePaymentModal,
      openPOItemsProd,
      openNotesModal,
      openTaskModal,
      handleShowPO,
      onSubmit,
      printShopOrder,
      printInstallerOrder,
      printQuestionnaire,
      openFileViewerModal,
    }
  } = useProjectCoordinator();

  const {
    breakpoint,
    handleHiddenColumns,
    hiddenColumns,
  } = useHiddenColumnsHandler('project-coordinator', columnOptions, breakpoints, parentColumns);

  const receivedRender = useCallback(materialsRender('Received', push), []);
  const dateRender = useCallback(readyDateRender(push, Owner.Project), []);
  const taskRender = useCallback(progressTemplate(openTaskModal), []);
  const orderedRender = useCallback(orderedTemplate(handleShowPO), []);
  const installerRender = useCallback(installerBookingRender('installer', push), []);
  const installationDateRender = useCallback(installerBookingRender('installationDate', push), []);

  return (
    <Page title="Project Coordinator">
      <Help view="project-coordinator" title="Project Coordinator" description={helpDescription} />
      <PageActions primary>
        <AccessControl permissions={permissions.changeOrder}>
          <Button
            onClick={handleShowCalendar}
            label="Calendar"
            mR
            mT
          />
          <Button
            onClick={openPOItemsProd}
            label="Purchase Order"
            mT
          />
        </AccessControl>
        <Select
          className="fixed-column-select"
          placeholder="Hidden Columns"
          onChange={handleHiddenColumns}
          value={hiddenColumns}
          maxTagCount="responsive"
          mode="multiple"
          options={columnOptions}
          label="Hidden Columns"
          size="middle"
        />
      </PageActions>
      <DataTable
        loading={fetching}
        dataSource={data}
        totalRecords={count}
        page={page}
        onChange={handleOnChange}
        className="project-coordinator__table"
        onFilter={handleFilter}
        onSubmit={onSubmit}
      >
        <DataTableColumn
          columnGroup
          title="JOB INFO"
          className="force-border-top"
          hideColumn={breakpoint.job_info}
        >
          <DataTableColumn
            title="Order"
            dataIndex="id"
            columnKey="id"
            width={65}
            sorter
            hideColumn={breakpoint.id}
            link={linkToOrder}
          />
          <DataTableColumn
            title="Company"
            dataIndex="account"
            columnKey="account"
            trimAt={17}
            hideColumn={breakpoint.account}
          />
          <DataTableColumn
            title="Job Title"
            dataIndex="jobName"
            columnKey="job_name"
            trimAt={25}
            hideColumn={breakpoint.job_name}
          />
          <DataTableColumn
            title="Rep. Owner"
            dataIndex="rep"
            render={repTemplate}
            columnKey="rep"
            hideColumn={breakpoint.rep}
          />
          <DataTableColumn
            title="Shipping"
            dataIndex="productionStatus"
            columnKey="production_status"
            hideColumn={breakpoint.production_status}
            filters={filtersProductOption}
          />
          <DataTableColumn
            title="Status"
            dataIndex={['orderStatus', 'id']}
            columnKey="order_status"
            component={Select}
            inputClassName="project-coordinator__status-column"
            options={orderStatusOptions}
            getPayload={getStatusPayload}
            editable
            alwaysShowInput
            sorter
            hideColumn={breakpoint.status}
            filters={filtersOrderStatus}
          />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
          hideColumn={breakpoint.job_info}
        />
        <DataTableColumn
          columnGroup
          title="MATERIALS"
          className="force-border-top"
          hideColumn={breakpoint.materials}
        >
          <DataTableColumn
            title="Ordered"
            className="button-cell"
            columnKey="ordered"
            render={orderedRender}
            hideColumn={breakpoint.ordered}
          />
          <DataTableColumn
            title="Received"
            columnKey="received"
            className="button-cell"
            render={receivedRender}
            hideColumn={breakpoint.received}
          />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
          hideColumn={breakpoint.materials}
        />
        <DataTableColumn
          columnGroup
          title="SHIPPING"
          className="force-border-top"
          hideColumn={breakpoint.shipping}
        >
          <DataTableColumn
            title="Installer"
            render={installerRender}
            className="project-coordinator__installer-cell"
            hideColumn={breakpoint.installer}
          />
          <DataTableColumn
            title="Inst. Date"
            render={installationDateRender}
            className="project-coordinator__installer-cell"
            hideColumn={breakpoint.inst_date}
          />
          <DataTableColumn
            title="Ready Date"
            className="button-cell"
            columnKey="ready_date"
            render={dateRender}
            width="120px"
            sorter
            hideColumn={breakpoint.ready_date}
          />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
          hideColumn={breakpoint.shipping}
        />
        <DataTableColumn columnGroup title="TRANSACTION" className="force-border-top">
          <DataTableColumn
            title="Task"
            render={taskRender}
            sorter
            hideColumn={breakpoint.task}
          />
          <DataTableColumn
            dataIndex={['transactions', 'paid']}
            title="Paid"
            render={paidTemplate}
            sorter
            hideColumn={breakpoint.paid}
          />
          <DataTableColumn
            dataIndex={['transactions', 'outstanding']}
            title="Outst."
            render={outstandingTemplate}
            hideColumn={breakpoint.outst}
          />
          <DataTableColumn
            dataIndex="total"
            title="Total"
            isCurrency
            sorter
            hideColumn={breakpoint.total}
          />
          <DataTableColumn
            title="Package"
            isActionColumn
            width="150px"
          >
            <DataTableAction
              onClick={openNotesModal}
              icon="fas fa-lightbulb"
              label="Notes"
              badge="noteCount"
              permissions={permissions.changeNote}
            />
            <DataTableAction
              permissions={permissions.viewTransaction}
              onClick={openReceivePaymentModal}
              icon="fas fa-dollar-sign"
              label="Transactions"
            />
            <DataTableAction
              onClick={openFileViewerModal}
              icon="fas fa-folder"
              label="File Viewer"
            />
            <DataTableAction
              onClick={printInstallerOrder}
              label="Install"
              icon="fa fa-tools"
            />
            <DataTableAction
              onClick={printShopOrder}
              label="Shop"
              icon="fa fa-shopping-bag"
              badge="id"
            />
            <DataTableAction
              onClick={printQuestionnaire}
              icon="fa fa-print"
              label="Print Questionnaire"
            />
          </DataTableColumn>
        </DataTableColumn>
      </DataTable>
      <ProductionCalendar
        visible={showCalender}
        handleVisible={handleShowCalendar}
      />
      <PurchaseOrderPC visible={showPO.visible} orderId={showPO.order} onClose={handleShowPO} />
    </Page>
  );
};

export default ProjectCoordinator;
