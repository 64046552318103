import { ApiModel, ApiContentType } from './base';
import getApi from './base/get-api';

const { REACT_APP_SHIPMENT_API } = process.env;

const endpoint = `${REACT_APP_SHIPMENT_API}/installer/`;

const installerApi: ApiModel = {
  ...getApi(endpoint, ApiContentType.JSON, true),
};

export default installerApi;
