import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';
import Drawer from 'components/base-components/Drawer';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import Header from './Header';
import Cell from './Cell';
import useCalendarState from './state';
import useResponsiveWidth from './use-responsive-width';
import './style.scss';

interface Props{
  visible: boolean;
  handleVisible: () => any;
}

const plugins = [BootstrapTheme, dayGridPlugin, interactionPlugin];
const headerConf = {
  left: '',
  center: '',
  right: ''
};

const ProductionCalendar: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();

  const { visible, handleVisible } = props;
  const {
    state: {
      date,
      view,
      fetchingData,
      schedule,
      sources: selectedSources,
      calendarRef,
      eventTypesOptions,
      fetchingOptions
    },
    actions: {
      handleDayLinkClick,
      handleWeekLinkClick,
      handleDateChange,
      handleViewModeChange,
      handleEventClick,
      handleOnDrop,
      handleSourcesChange,
    }
  } = useCalendarState(visible);

  const drawerWidth = useResponsiveWidth();

  const openEventFormModal = (e) => {
    const locationState = {
      customEventForm: {
        customEventId: undefined,
        setDate: e.dateStr
      }
    };
    push(ModalRouteHash.CustomEventFrom, locationState);
  };

  return (
    <Drawer
      title="Production Calendar"
      visible={visible}
      onClose={handleVisible}
      width={drawerWidth}
      maskClosable={false}
    >
      <div className="production-calendar">
        <Header
          date={date}
          view={view}
          calendarRef={calendarRef}
          fetchingData={fetchingData}
          selectedSources={selectedSources}
          onDateChange={handleDateChange}
          onViewModeChange={handleViewModeChange}
          onSourcesChange={handleSourcesChange}
          eventTypesOptions={eventTypesOptions}
          fetchingOptions={fetchingOptions}
        />
        <FullCalendar
          ref={calendarRef}
          plugins={plugins}
          headerToolbar={headerConf}
          eventContent={Cell}
          events={schedule}
          eventClick={handleEventClick}
          eventChange={handleOnDrop}
          navLinkDayClick={handleDayLinkClick}
          navLinkWeekClick={handleWeekLinkClick}
          dateClick={openEventFormModal}
          eventClassNames="production-calendar__event"
          initialView="dayGridMonth"
          themeSystem="bootstrap"
          handleWindowResize
          editable
          droppable
          selectable
          weekNumbers
          navLinks
        />
      </div>
    </Drawer>
  );
};

export default ProductionCalendar;
