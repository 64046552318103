import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import TextArea from 'components/base-components/TextArea';
import Checkbox from 'components/base-components/Checkbox';
import Select from 'components/base-components/Select';
import DatePicker from 'components/base-components/DatePicker';
import { validationRules } from './validations';
import useSheetFormState from './state';

export interface CustomEventFormHistorySateModel {
  customEventForm: {
    customEventId: string;
    setDate?: string;
  };
}

const CustomEventForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { customEventForm: { customEventId, setDate } } = state as CustomEventFormHistorySateModel;

  const {
    state: {
      saving,
      formData,
      fetchingEntity,
      errors,
      eventTypesOptions,
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  } = useSheetFormState(customEventId, setDate);

  const modalTitle = useMemo(
    () => customEventId ? 'Edit Event' : 'Create Event',
    [customEventId],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveEntity}
      confirmLoading={saving}
    >
      <RenderIf isTrue={!fetchingEntity} fallback={<Spinner />}>
        <Form
          state={formData}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="title"
            name="title"
            label="title"
            placeholder="Enter title"
          />
          <Field
            id="details"
            name="details"
            label="Details"
            placeholder="Enter Details"
            component={TextArea}
          />
          <Field
            id="eventType"
            name="eventType"
            label="Event Type"
            placeholder="Select Event Type"
            component={Select}
            options={eventTypesOptions}
          />
          <Field
            component={DatePicker}
            name="scheduled"
            id="scheduled"
            label="Scheduled"
            showTime
          />
          <Field
            id="completed"
            name="completed"
            label="Completed"
            component={Checkbox}
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default CustomEventForm;
