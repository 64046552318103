import React from 'react';
import { Title } from 'components/base-components/Typography';
import isEdgeRow from './is-edge-row';

export default function batchTemplate(row) {
  const { type, label, account } = row;

  if (isEdgeRow(type)) {
    return {
      children: (
        <Title level={5} className="sales-report__table__group__header">
          {label}
        </Title>
      ),
      props: {
        colSpan: 3,
      },
    };
  }

  return account;
}
