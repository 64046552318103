import React, { FunctionComponent } from 'react';
import manufacturerApi from 'api/manufacturer';
import Modal from 'components/base-components/ModalV2';
import Form, { Field } from 'components/base-components/Form';
import NumberInput from 'components/base-components/NumberInput';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import useCreateGroup from './state';
import './styles.scss';

interface Props {
  visible: boolean;
  parent: string;
  onClose: () => void;
  row: number;
}

const CreateCustomOption: FunctionComponent<Props> = (props) => {
  const { visible, parent, onClose, row } = props;
  const {
    state: {
      option,
      errors,
    },
    actions: {
      setOption,
      setErrors,
      mapManufacturers,
      handleOnSubmit,
      openManufacturerModal
    },
  } = useCreateGroup(parent, onClose, row);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={handleOnSubmit}
      okText="Submit"
      title="Create Custom Option"
      zIndex={9998}
    >
      <Form state={option} onChange={setOption} errors={errors} onError={setErrors}>
        <Field id="name" name="name" label="Name" />
        <Field id="code" name="code" label="Code" />
        <Field
          dropdownClassName="custom-option__manufacturer"
          component={AsyncSelectV2}
          id="manufacturer"
          name="manufacturer"
          label="Manufacturer"
          listAction={manufacturerApi.list}
          mapFunction={mapManufacturers}
          onAddClick={openManufacturerModal}
        />
        <Field id="cost" name="cost" label="cost" component={NumberInput} />
      </Form>
    </Modal>
  );
};

export default CreateCustomOption;
