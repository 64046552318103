import { InstallerBookingModel } from 'models/installer-booking';
import { CalendarEvent, EventItemType } from '../reducer';

export default function buildBookingsSchedule(
  bookings: InstallerBookingModel[],
): CalendarEvent[] {
  return bookings.map((booking) => {
    const {
      id,
      orderNumber,
      installationDate,
      installer: { companyName },
    } = booking;

    return {
      id: `booking-${id.toString(10)}`,
      title: `${orderNumber} - ${companyName}`,
      start: new Date(installationDate),
      extendedProps: {
        type: EventItemType.BOOKING,
        item: booking,
      },
    } as CalendarEvent;
  });
}
