import { useCallback, useMemo, useReducer, useRef } from 'react';
import categoriesApi from 'api/category';
import useCallApiAction from 'hooks/base/call-api-action';
import updateProperties from 'components/pages/Builder/state/actions/update-properties';
import updateOptions from 'components/pages/Builder/state/actions/update-options';
import onStyleChange from './actions/on-change-style';
import mapCategories from './actions/map-categories';
import takePic from './actions/handle-take-pic';
import saveImage from './actions/save-image';
import categoryImageFormReducer, { initialState } from './reducer';

export default function useCategoryImageFormState() {
  const [state, dispatch] = useReducer(categoryImageFormReducer, initialState);
  const stageRef = useRef(undefined);
  const { selectedProduct, style, image } = state;
  const {
    fetching: loadingCategories,
    data: categories,
  } = useCallApiAction(categoriesApi.getTopLevel, null, []);

  return {
    state: {
      ...state,
      loadingCategories,
      categories: useMemo(() => mapCategories(categories), [categories]),
      stageRef,
    },
    actions: {
      onStyleChange: useCallback(onStyleChange(dispatch), []),
      updateProperties: useCallback(
        updateProperties(dispatch, selectedProduct),
        [selectedProduct],
      ),
      updateOptions: useCallback(
        updateOptions(dispatch, selectedProduct),
        [selectedProduct],
      ),
      takePic: useCallback(
        takePic(dispatch, stageRef),
        [stageRef],
      ),
      saveImage: useCallback(
        saveImage(dispatch, image, style),
        [image, style],
      ),
    }
  };
}
