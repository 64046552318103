import { CreateOrderErrors } from 'api/orders';

export default function resolveShipmentFields(missingFields: CreateOrderErrors) {
  if (missingFields?.shipping?.missing) {
    const { shipping: { missing } } = missingFields;
    return {
      showProjectSelect: missing.some(field => field === 'project_id'),
      showAddressForm: missing.some(field => field === 'shipping_address'),
      showZoneSelect: missing.some(field => field === 'shipping_zone'),
      showDQLink: missing.some(field => field === 'shipping_questionnaire'),
    };
  }
  return {
    showProjectSelect: false,
    showAddressForm: false,
    showZoneSelect: false,
    showDQLink: false,
  };
}
