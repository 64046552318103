import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import FullForm from './FullForm';
import QuickForm from './QuickForm';
import useAccountFormState from './state';
import { getFullFormRules, quickFormRules } from './state/validations';
import './styles.scss';
import Help from './Help';

export interface AccountFormHistorySate {
  accountForm: {
    url?: string;
    salesRep?: string;
    showQuickForm?: boolean;
  };
}

const spinner = <Spinner size="large" centered />;

const AccountForm: FunctionComponent = () => {
  const { goBack } = useHistory();
  const {
    state: {
      showFullForm,
      isEditing,
      account,
      fetchingFormData,
      savingAccount,
      errors,
    },
    actions: {
      setFormValue,
      setErrors,
      saveAccount,
    },
  } = useAccountFormState();

  const modalTitle = useMemo(() => (
    `${isEditing ? 'Edit' : 'Create'} Account`
  ), [isEditing]);
  const rules = useMemo(() => (
    showFullForm ? getFullFormRules(isEditing) : quickFormRules
  ), [showFullForm, isEditing]);

  return (
    <Modal
      visible
      showHelp
      title={modalTitle}
      onCancel={goBack}
      onOk={saveAccount}
      confirmLoading={savingAccount}
      okText="Submit"
    >
      <RenderIf isTrue={!fetchingFormData} fallback={spinner}>
        <Form
          state={account}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={rules}
        >
          <RenderIf isTrue={showFullForm}>
            <FullForm showTradeName={!isEditing} />
          </RenderIf>
          <RenderIf isTrue={!showFullForm}>
            <QuickForm />
          </RenderIf>
        </Form>
      </RenderIf>
      <Help />
    </Modal>
  );
};

export default AccountForm;
