import React, { FunctionComponent, useMemo } from 'react';
import extraChargeApi from 'api/extra-charge';
import { formatCurrency } from 'helpers/formatting';
import hasPermissions from 'helpers/permissons';
import { ExtraChargeModel } from 'models/extra-charge';
import { Summary } from 'components/base-components/DataTableV2';
import EditInPlaceV2 from 'components/base-components/EditInPlaceV2';
import NumberInput from 'components/base-components/NumberInput';
import Button from 'components/base-components/Button';
import Spinner from 'components/base-components/Spinner';
import AccessControl from 'components/base-components/AccessControl';
import { permissions } from '../../permissions';

interface Props {
  extraCharges: ExtraChargeModel[];
  colSpan: number;
  removeCharge: (chargeIndex: number, id: number) => void;
  handleExtraChargeChanged: any;
  deletingExtraChargeIndex: number;
}

function amountRender(amount: number) {
  return formatCurrency(amount || 0);
}

const ExtraCharges: FunctionComponent<Props> = (props): any => {
  const {
    extraCharges,
    colSpan,
    removeCharge,
    handleExtraChargeChanged,
    deletingExtraChargeIndex,
  } = props;

  const hasEditExtraChargePermission = useMemo(() => hasPermissions(permissions.editExtraCharge), []);

  return extraCharges.map((charge, index) => {
    const handleRemoveCharge = () => {
      removeCharge(index, charge.id);
    };

    const data = {
      ...charge,
      amount: parseFloat(charge.amount) || 0,
    };

    const onSubmit = charge.id ? extraChargeApi.update : extraChargeApi.create;

    const deleting = deletingExtraChargeIndex === index;

    return (
      <Summary.Row key={`quote-charge-${charge.id || 'id'}`} className="quote-charge__row">
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          <div className="quote-charge__title-wrap">
            <div className="quote-charge__delete-button__wrap">
              <Spinner spinning={deleting} centered={false}>
                <AccessControl permissions={permissions.deleteExtraCharge}>
                  <Button
                    icon="fa fa-minus"
                    shape="circle"
                    type="default"
                    size="small"
                    danger
                    onClick={handleRemoveCharge}
                  />
                </AccessControl>
              </Spinner>
            </div>
            <EditInPlaceV2
              id={`quote-charge-title-${charge.id || 'id'}`}
              field="chargeName"
              data={data}
              onSubmit={onSubmit}
              onSubmitSuccess={handleExtraChargeChanged}
              placeholder="Enter Name"
              className="quote-charge__title"
              disabled={deleting || !hasEditExtraChargePermission}
            />
          </div>
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          <EditInPlaceV2
            id={`quote-charge-amount-${charge.id || 'id'}`}
            field="amount"
            data={data}
            onSubmit={onSubmit}
            onSubmitSuccess={handleExtraChargeChanged}
            placeholder="Enter Amount"
            component={NumberInput}
            allowDecimals
            render={amountRender}
            disabled={!charge.chargeName || deleting || !hasEditExtraChargePermission}
          />
        </Summary.Cell>
      </Summary.Row>
    );
  });
};

ExtraCharges.defaultProps = {
  extraCharges: [],
};

export default ExtraCharges;
