export enum SpecialInstructionsFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_EDITING = 'START_EDITING',
  FINISH_EDITING = 'FINISH_EDITING',
}

interface SpecialInstructionsFormState {
  formData: {specialInstructions: string};
  editing: boolean;
  errors: any;
}

export default function SpecialInstructionsFormReducer(
  state: SpecialInstructionsFormState, action
): SpecialInstructionsFormState {
  const { type, payload } = action;

  switch (type as SpecialInstructionsFormActions) {
    case SpecialInstructionsFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case SpecialInstructionsFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, editing: false };
    case SpecialInstructionsFormActions.START_EDITING:
      return { ...state, editing: true };
    case SpecialInstructionsFormActions.FINISH_EDITING:
      return { ...state, editing: false };
    default:
      return state;
  }
}
