import React, { FunctionComponent } from 'react';
import { Menu as AntdMenu } from 'antd';
import { MenuProps } from 'antd/lib/menu';

export * from './MenuItem';
export * from './MenuDivider';

const Menu: FunctionComponent<MenuProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntdMenu {...rest}>
      {children}
    </AntdMenu>
  );
};

export default Menu;
