import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import purchaseOrderApi from 'api/purchase-order';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { MaterialsModel, MaterialsItemModel } from 'models/production-manager';
import Drawer from 'components/base-components/Drawer';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTable from 'components/base-components/DataTableV2';
import Switch from 'components/base-components/Switch';
import Tag from 'components/base-components/Tag';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import { permissions } from './permissions';

export interface MaterialsDetailsHistorySateModel {
  details: {
    material: MaterialsModel;
    title: string;
  };
}

function printPDF(materialsItem: MaterialsItemModel) {
  const { purchaseOrderId } = materialsItem;
  purchaseOrderApi.getPDF(purchaseOrderId)
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}

const MaterialsDetails: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { details: { material, title } } = state as MaterialsDetailsHistorySateModel;
  const [switchState, setSwitchState] = useState(false);
  const data = useMemo(() => switchState ? material.complete : material.incomplete, [material, switchState]);
  const labelSwitch = useMemo(() => switchState ? 'complete' : 'incomplete', [switchState]);
  const statusRender = useCallback((value) => {
    const color = value ? 'green' : 'red';
    const label = value ? title : `Not ${title}`;
    return (<Tag label={label} color={color} />);
  }, [title]);

  return (
    <Drawer
      title={title}
      visible
      onClose={goBack}
      width="40vw"
    >
      <Switch
        checked={switchState}
        onChange={setSwitchState}
        label={labelSwitch}
      />
      <DataTable
        dataSource={data}
      >
        <DataTableColumn
          title="Supplier"
          dataIndex="manufacturer"
        />
        <DataTableColumn
          title="Item"
          dataIndex="partName"
        />
        <DataTableColumn
          title="Ordered"
          dataIndex="quantity"
        />
        <DataTableColumn
          title="Status"
          dataIndex="received"
          render={statusRender}
        />
        <DataTableColumn
          isActionColumn
        >
          <DataTableAction
            onClick={printPDF}
            label="Print"
            icon="fa fa-print"
            permissions={permissions.viewPurchaseOrder}
          />
        </DataTableColumn>
      </DataTable>
    </Drawer>
  );
};

export default MaterialsDetails;
