import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import projectApi from 'api/projects';
import installerApi from 'api/installer';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import { ProjectModel } from 'models/project';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import DatePicker from 'components/base-components/DatePicker';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import { validationRules } from './validations';
import useInstallerBookingFormState from './state';

export interface InstallerBookingFormHistorySateModel {
  installerBookingForm: {
    installerBookingEntity: any;
    hideProject?: boolean;
  };
}

const getProjectId = (item) => item.id;
const getProjectName = (item) => item.jobTitle;

export function mapOrderOptions(orders: ProjectModel[]) {
  return mapGenericDropdownOptions(
    orders,
    {
      fields: {
        value: getProjectId,
        label: getProjectName,
      },
    },
  );
}

export function mapInstallerOptions(orders: ProjectModel[]) {
  return mapGenericDropdownOptions(
    orders,
    {
      fields: {
        value: 'id',
        label: 'companyName',
      },
    },
  );
}

const InstallerBookingForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const {
    installerBookingForm: {
      installerBookingEntity,
      hideProject,
    },
  } = state as InstallerBookingFormHistorySateModel;

  const {
    state: {
      savingInstallerBooking,
      installerBooking,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveInstallerBooking,
      setErrors,
    },
  } = useInstallerBookingFormState(installerBookingEntity);

  const modalTitle = useMemo(() => (
    installerBookingEntity
      ? 'Edit Installer Booking'
      : 'Create InstallerBooking'
  ), [installerBookingEntity]);

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveInstallerBooking}
      confirmLoading={savingInstallerBooking}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={installerBooking}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            component={AsyncSelectV2}
            name="installer"
            id="installer"
            placeholder="Select an Installer"
            label="Installer"
            listAction={installerApi.list}
            mapFunction={mapInstallerOptions}
          />
          <Field
            component={DatePicker}
            name="installationDate"
            id="installationDate"
            label="Installation Date"
            showTime
          />
          <RenderIf isTrue={!hideProject}>
            <Field
              component={AsyncSelectV2}
              name="project"
              id="project"
              placeholder="Select an order"
              label="Project"
              listAction={projectApi.list}
              mapFunction={mapOrderOptions}
            />
          </RenderIf>
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default InstallerBookingForm;
