import { DropdownOptionModel } from 'helpers/dropdown-options';
import getHiddenColumn from './actions/get-hidden-coulumns';

export interface Breakpoints{
  [x: string]: number;
}

export interface ParentColumns {
  [x: string]: string[];
}

export enum FormActions {
  SET_HIDDEN_COLUMNS = 'SET_HIDDEN_COLUMNS',
  GET_HIDDEN_COLUMNS = 'GET_HIDDEN_COLUMNS',
}

interface FormState {
  hiddenColumns: DropdownOptionModel[];
  localStorageKey: string;
  breakpoints: Breakpoints;
  parentColumns: ParentColumns;
  columnOptions: DropdownOptionModel[];
}

export default function hiddenColumnsHandlerReducer(state: FormState, action): FormState {
  const { type, payload } = action;

  switch (type as FormActions) {
    case FormActions.SET_HIDDEN_COLUMNS:
      return { ...state, hiddenColumns: payload };
    case FormActions.GET_HIDDEN_COLUMNS: {
      const columnsKeys = getHiddenColumn(state.localStorageKey, state.breakpoints, state.parentColumns);
      return {
        ...state,
        hiddenColumns: state.columnOptions.filter(c => columnsKeys.includes(c.value)),
      };
    }
    default:
      return state;
  }
}
