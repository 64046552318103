import { QuickUserModel } from 'models/user';
import userApi from 'api/user';
import accountApi from 'api/account';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { userDropdownData } from 'helpers/dropdown-options';
import { validateEntity } from 'helpers/form-validations';
import { CreateQuickUserFormActions } from '../reducer';
import { getAccountPayload, getUserPayload } from './get-payload';
import { validationRules } from '../validations';

async function createUser(userPayload, account) {
  const { data } = await userApi.create(userPayload);
  const { data: accountData } = await accountApi.get(account);
  const accountPayload = getAccountPayload(accountData, data.url);
  await accountApi.update(accountPayload);
  return data;
}

export function createQuickUser(dispatch, formData: QuickUserModel, account: string, goBack) {
  return () => {
    dispatch({ type: CreateQuickUserFormActions.START_CREATING });

    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      dispatch({
        type: CreateQuickUserFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
      return;
    }

    const payload = getUserPayload(formData);

    const onSuccess = (newUser) => {
      const user = userDropdownData(newUser, true);
      notifyEventChannel(EventChannelList.QUOTE_PAGE_NEW_USER, user);

      const message = 'User Successfully created';
      notification({ type: NotificationType.SUCCESS, message });
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CreateQuickUserFormActions.FINISH_CREATING });
    };

    // eslint-disable-next-line consistent-return
    return createUser(payload, account)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
