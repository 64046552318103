import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import I18nProvider from 'i18n';
import AppProviders from 'components/providers';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import initAWSCognito from 'auth';
import configureAppStore from 'store/store';
import 'antd/dist/antd.css';
import 'styles/index.scss';

initAWSCognito();

const store = configureAppStore({});

const app = (
  <Provider store={store}>
    <I18nProvider>
      <AppProviders>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProviders>
    </I18nProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
