import React, { useCallback, FunctionComponent } from 'react';
import { TreeSelect as AntTreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';
import classnames from 'classnames';
import { DropdownOptionModel, flatTreeSelectLabel } from 'helpers/dropdown-options';
import Label from '../Label';
import '../Select/styles.scss';
import InputError from '../InputError';

export const { SHOW_PARENT, SHOW_ALL, SHOW_CHILD } = AntTreeSelect;

interface Props extends TreeSelectProps<any>{
  id?: string;
  label?: string;
  value: DropdownOptionModel | DropdownOptionModel[];
  loadData?: (treeNode) => any;
  treeData: DropdownOptionModel[];
  onChange: (event) => void;
  className?: string;
  mR?: boolean;
  oneChild?: boolean;
  repeatedValues?: boolean;
  inputClassName?: string;
  error?: string;
}

const TreeSelect: FunctionComponent<Props> = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    treeData,
    loadData,
    className,
    mR,
    multiple,
    oneChild,
    repeatedValues,
    inputClassName,
    error,
    ...rest
  } = props;

  const wrapperClassNames = classnames('select', { mR }, className);
  const selectClassName = classnames('select__input', inputClassName);

  const handleOnChange = useCallback(
    (valueSelected) => {
      if (multiple && oneChild && valueSelected.length > 0) {
        const newValue = valueSelected[valueSelected.length - 1];
        const parent = repeatedValues
          ? flatTreeSelectLabel(valueSelected[valueSelected.length - 1]).parent
          : treeData.find(e => e.value === newValue).pId;
        const otherChild = treeData.find(
          e => (e.pId === parent && e.value !== newValue && valueSelected.includes(e.value))
        );
        if (otherChild) {
          valueSelected.splice(valueSelected.findIndex(e => (e === otherChild.value)), 1);
        }
      }
      onChange({ target: { value: valueSelected } });
    },
    [onChange, multiple, oneChild, treeData, repeatedValues],
  );

  return (
    <div className={wrapperClassNames}>
      <Label text={label} forInput={id} />
      <AntTreeSelect
        id={id}
        {...rest}
        treeDataSimpleMode
        className={selectClassName}
        loadData={loadData}
        treeData={treeData}
        value={value}
        onChange={handleOnChange}
        multiple={multiple}
      />
      <InputError error={error} />
    </div>
  );
};

TreeSelect.defaultProps = {
  placeholder: 'Please select',
};

export default TreeSelect;
