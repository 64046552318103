import getEventValue from 'helpers/get-event-value';

export default function handleContactChange(event, setFields, filters) {
  const { contact: oldContact } = filters;
  const nextContact = getEventValue(event);

  if (oldContact?.value !== nextContact?.value) {
    setFields({
      contact: nextContact,
      project: null,
      quote: null,
    });
  }
}
