import React, { FunctionComponent } from 'react';
import { ShipmentDTO } from 'models/shipment';
import { QuoteModel } from 'models/quote';
import { CreateOrderErrors } from 'api/orders';
import Modal from 'components/base-components/ModalV2';
import Content from './Content';
import useConvertToOrderState from './state';
import './styles.scss';

export interface ConvertToOrderProps {
  isVisible: boolean;
  isIncompleteOrder: boolean;
  convertingToOrder: boolean;
  quote: QuoteModel;
  shipment: ShipmentDTO;
  missingFields: CreateOrderErrors;
  onSubmit: () => void;
  onClose: () => void;
}

const ConvertToOrder: FunctionComponent<ConvertToOrderProps> = (props) => {
  const {
    isVisible,
    isIncompleteOrder,
    onClose,
  } = props;

  const {
    state: {
      convertingToOrder,
      ...otherStates
    },
    actions: {
      handleOnSubmit,
      ...otherActions
    },
  } = useConvertToOrderState(props);

  if (isVisible) {
    return (
      <Modal
        visible
        title="Convert to Order"
        okText="Convert"
        cancelText="Cancel"
        onOk={handleOnSubmit}
        onCancel={onClose}
        confirmLoading={convertingToOrder}
      >
        <Content
          isIncompleteOrder={isIncompleteOrder}
          {...otherStates}
          {...otherActions}
        />
      </Modal>
    );
  }

  return null;
};

export default ConvertToOrder;
