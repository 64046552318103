import React, { FunctionComponent } from 'react';
import EditInPlaceV2 from 'components/base-components/EditInPlaceV2';
import { PermissionLabels } from 'constants/permissions';
import { AxiosResponse } from 'axios';

interface Props {
  id?: string;
  data: any;
  dataIndex: string;
  editPermissions?: PermissionLabels[];
  onChange: () => void;
  component: any;
  onSubmit: (payload, dataIndex?) => Promise<AxiosResponse>;
  onSubmitSuccess: (payload) => void;
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  [x: string]: any;
}

const EditableCell: FunctionComponent<Props> = (props) => {
  const {
    id,
    dataIndex,
    onChange,
    data,
    editPermissions,
    onSubmit,
    onSubmitSuccess,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title,
    editable,
    children,
    render,
    ...rest
  } = props;

  const inputId = id;

  let childNode = children;

  if (editable) {
    childNode = (
      <EditInPlaceV2
        id={inputId}
        field={dataIndex}
        data={data}
        render={render}
        onChange={onChange}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        permissions={editPermissions}
        {...rest}
      />
    );
  }

  return <td {...rest}>{childNode}</td>;
};

export default EditableCell;
