import React, { FunctionComponent, useMemo } from 'react';
import { AccessMethodModel } from 'models/access-methods';
import RenderIf from 'components/base-components/RenderIf';
import Field from 'components/base-components/Form/Field';
import TextArea from 'components/base-components/TextArea';
import InputRow from 'components/base-components/InputRow';
import TimePicker from 'components/base-components/TimePicker';
import Radio from 'components/base-components/Radio';
import Form from 'components/base-components/Form';
import Section from './Section';
import { Answers, AnswersModel } from './types';

interface Props {
  answers: AnswersModel;
  setAnswers: (answers) => void;
  accessMethods: AccessMethodModel[];
}

const textAreaSize = { minRows: 3, maxRows: 3 };

const AnswersForm: FunctionComponent<Props> = (props) => {
  const {
    answers,
    setAnswers,
    accessMethods,
  } = props;

  const accessMethodOptions = useMemo(() => accessMethods.map((accessMethod) => (
    <Field
      key={accessMethod.id}
      id={`access-method-${accessMethod.id}`}
      name="accessMethod"
      component={Radio}
      label={accessMethod.name}
      expected={accessMethod}
      skipDebounce
    />
  )), [accessMethods]);

  return (
    <Form
      state={answers}
      onChange={setAnswers}
    >
      <h1 className="questionnaire__header">Location Details</h1>
      <p className="questionnaire__p mB">Please select all that apply:</p>
      <Section
        label="Is the delivery/install after regular business hours?"
        name="afterHours"
      />
      <Section
        label="Will the area be prepared and ready for install?"
        name="areaPrepared"
      >
        <RenderIf isTrue={answers.areaPrepared === Answers.No}>
          <Field
            name="areaPreparedDetails"
            component={TextArea}
            placeholder="Please specify details..."
            autoSize={textAreaSize}
          />
        </RenderIf>
      </Section>
      <Section
        label="Will the installer have to reconfigure furniture?"
        name="furnitureReconfigurationRequired"
      >
        <RenderIf isTrue={answers.furnitureReconfigurationRequired === Answers.Yes}>
          <Field
            name="furnitureReconfigurationDetails"
            component={TextArea}
            placeholder="Please specify details..."
            autoSize={textAreaSize}
          />
        </RenderIf>
      </Section>
      <Section
        label="Are there specific delivery hours?"
        name="specificDeliveryHours"
      >
        <RenderIf isTrue={answers.specificDeliveryHours === Answers.Yes}>
          <InputRow>
            <Field
              name="specificHoursStart"
              component={TimePicker}
              label="From"
              mode="time"
              mR
            />
            <Field
              name="specificHoursEnd"
              component={TimePicker}
              label="To"
            />
          </InputRow>
        </RenderIf>
      </Section>
      <Section
        label="Is this a street Offload?"
        name="streetOffload"
      />
      <Section
        label="Is there parking for a small truck?"
        name="parkingAvailable"
      />
      <Section
        label="Do the installer need access cards?"
        name="accessCardsRequired"
      />
      <Section
        label="Is there a loading Dock?"
        name="loadingDockAvailable"
      >
        <RenderIf isTrue={answers.loadingDockAvailable === Answers.No}>
          <Field
            name="loadingDetails"
            component={TextArea}
            placeholder="Where should we offload the order?"
            autoSize={textAreaSize}
          />
        </RenderIf>
      </Section>
      <RenderIf isTrue={answers.loadingDockAvailable === Answers.Yes}>
        <Section
          label="Does the loading dock have to be booked?"
          name="loadingDockBookingRequired"
        >
          <RenderIf isTrue={answers.loadingDockBookingRequired === Answers.Yes}>
            <Field
              name="loadingDockBookingRequiredDetails"
              component={TextArea}
              placeholder="Please specify details..."
              autoSize={textAreaSize}
            />
          </RenderIf>
        </Section>
      </RenderIf>
      <div className="questionnaire__section">
        <div className="questionnaire__section__question">
          <span>Access method:</span>
        </div>
        <div className="questionnaire__access-methods">
          {accessMethodOptions}
        </div>
        <RenderIf isTrue={answers.accessMethod.id === 2}>
          <Field
            name="stairs"
            label="How many floors"
          />
        </RenderIf>
        <RenderIf isTrue={answers.accessMethod.id === 3}>
          <Section
            label="Elevators need to be reserved?"
            name="elevatorReservationRequired"
          />
        </RenderIf>
        <RenderIf isTrue={answers.accessMethod.id === 4}>
          <Field
            name="accessMethodDetails"
            component={TextArea}
            placeholder="Please specify details..."
            autoSize={textAreaSize}
          />
        </RenderIf>
      </div>
    </Form>
  );
};

export default AnswersForm;
