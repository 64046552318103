import {
  BuilderState,
  StyleProviders
} from '../../Builder/state';

export enum BuilderActions {
  SET_CATEGORY_SELECTED = 'SET_CATEGORY_SELECTED',
  ADD_PRODUCTS = 'ADD_PRODUCTS',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  SWAP_PRODUCT = 'SWAP_PRODUCT',
  START_FETCHING_PRODUCT = 'START_FETCHING_PRODUCT',
  STOP_FETCHING_PRODUCT = 'STOP_FETCHING_PRODUCT',
  SET_VIEW_MODE = 'SET_VIEW_MODE',
  SET_ERROR = 'SET_ERROR',
}

export default function ProductViewerReducer(
  state: BuilderState,
  action,
): BuilderState {
  const { type, payload } = action;

  switch (type as BuilderActions) {
    case BuilderActions.SET_CATEGORY_SELECTED:
      return {
        ...state,
        selectedCategory: payload,
        selectedProduct: undefined,
        styleProvider: StyleProviders.Category,
      };
    case BuilderActions.ADD_PRODUCTS:
      return {
        ...state,
        products: [payload],
        selectedProduct: payload,
        fetchingProductData: false,
      };
    case BuilderActions.UPDATE_PRODUCT: {
      const { selectedProduct: { id: productToUpdate } } = state;

      const nextProducts = state.products.map((product) => (
        product.id === productToUpdate ? payload : product
      ));

      return {
        ...state,
        products: nextProducts,
        selectedProduct: payload,
        fetchingProductData: false,
      };
    }
    case BuilderActions.SWAP_PRODUCT: {
      const { selectedProduct } = state;
      const updatedProduct = { ...selectedProduct, model: payload };

      const nextProducts = state.products.map((product) => (
        product.id === selectedProduct.id ? updatedProduct : product
      ));

      return {
        ...state,
        products: nextProducts,
        selectedProduct: updatedProduct,
        fetchingProductData: false,
      };
    }
    case BuilderActions.START_FETCHING_PRODUCT:
      return { ...state, fetchingProductData: true };
    case BuilderActions.STOP_FETCHING_PRODUCT:
      return { ...state, fetchingProductData: false, };
    case BuilderActions.SET_VIEW_MODE:
      return { ...state, viewMode: payload };
    case BuilderActions.SET_ERROR:
      return { ...state, error: payload, fetchingProductData: false };
    default:
      return state;
  }
}
