import { AxiosPromise } from 'axios';
import { ProjectCoordinatorModel } from 'models/project-coordinator';
import { ApiContentType, ApiModel, put, get, post } from './base';
import getApi from './base/get-api';

const { REACT_APP_ORDERS_API } = process.env;

const endpoint = `${REACT_APP_ORDERS_API}/project-coordinator/`;

interface ProductionManagerApiModel extends ApiModel<ProjectCoordinatorModel> {
  setReadyDateConfirmation: (orderId, payload) => AxiosPromise<ProjectCoordinatorModel>;
  setReadyDate: (orderId, payload) => AxiosPromise<ProjectCoordinatorModel>;
  setOrderStatus: (orderId, payload) => AxiosPromise<ProjectCoordinatorModel>;
  getNotes: (orderId) => AxiosPromise;
  addNotes: (orderId, payload) => AxiosPromise;
  getTask: (orderId) => AxiosPromise;
  addOnetimeTask: (orderId, payload) => AxiosPromise;
}

const projectCoordinatorApi: ProductionManagerApiModel = {
  ...getApi<ProjectCoordinatorModel>(endpoint, ApiContentType.JSON, true),
  setReadyDateConfirmation(orderId, payload) {
    return put(`${endpoint}${orderId}/ready-date-confirmation/`, payload);
  },
  setReadyDate(orderId, payload) {
    return put(`${endpoint}${orderId}/set-ready-date/`, payload);
  },
  setOrderStatus(orderId, payload) {
    return put(`${endpoint}${orderId}/set-order-status/`, payload);
  },
  getNotes(orderId) {
    return get(`${endpoint}${orderId}/get-notes/`);
  },
  addNotes(orderId, payload) {
    return post(`${endpoint}${orderId}/add-note/`, payload);
  },
  getTask(orderId) {
    return get(`${endpoint}${orderId}/get-task/`);
  },
  addOnetimeTask(orderId, payload) {
    return post(`${endpoint}${orderId}/add-onetime-task/`, payload);
  },
};

export default projectCoordinatorApi;
