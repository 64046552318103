import React, { FunctionComponent } from 'react';
import { ShipmentContactModel } from 'models/shipment';
import { OrderModel } from 'models/order';
import { formatDisplayDate } from 'helpers/formatting';
import RenderIf from 'components/base-components/RenderIf';

interface Props {
  order: OrderModel;
  contact: ShipmentContactModel;
}

const OrderInfo: FunctionComponent<Props> = (props) => {
  const {
    order: { id: orderId, createdDate },
    contact,
  } = props;

  return (
    <table className="work-order__order-info">
      <tr>
        <td className="work-order__order-info__header">Order Details</td>
        <td className="work-order__order-info__header">Contact</td>
      </tr>
      <tr>
        <td>
          <div className="work-order__order-info__items">
            <span>
              <b>Date Added: </b>
              {formatDisplayDate(createdDate)}
            </span>
            <span>
              {/* TODO: how to get this */}
              <b>Created By: </b>
              Josh roberts
            </span>
            <span>
              <b>Order ID: </b>
              {orderId}
            </span>
            <span>
              {/* TODO: how to get this */}
              <b>Shipping Method: </b>
              Delivery
            </span>
          </div>
        </td>
        <td>
          <div className="work-order__order-info__items">
            <RenderIf isTrue={!!contact}>
              <span>{`${contact?.firstName} ${contact?.lastName}`}</span>
              <span>{contact?.email}</span>
              <span>{contact?.telephone}</span>
            </RenderIf>
          </div>
        </td>
      </tr>
    </table>
  );
};

export default OrderInfo;
