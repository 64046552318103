import { TaskFormActions } from '../reducer';

export default function prepareState(dispatch, data: any[]) {
  const task = data.map(t => ({ label: t.details, value: t.id, priority: t.priority }));
  const taskDone = data.filter(t => t.completed).map(t => t.id);
  dispatch({
    type: TaskFormActions.SET_STATE,
    payload: { task, taskDone },
  });
}
