export enum SendEmailFormActions {
  SET_RECIPIENTS = 'SET_RECIPIENTS',
  SET_EMAIL = 'SET_EMAIL',
  SET_FILES= 'SET_FILES',
  SET_ERRORS = 'SET_ERRORS',
  START_SENDING_EMAIL = 'START_SENDING_EMAIL',
  STOP_SENDING_EMAIL = 'STOP_SENDING_EMAIL',
  START_FETCHING_DATA = 'START_FETCHING_DATA',
  STOP_FETCHING_DATA = 'STOP_FETCHING_DATA',
}

interface Email{
  recipients: string[];
  html: string;
  s3Attachments: string[];
  subject: string;
  attachmentCheck: string[];
  [x: string]: any;
}

export interface SendEmailFormState {
  email: Email;
  errors: { [id: string]: string };
  sendingEmail: boolean;
  fetchingData: boolean;
}

export default function sendEmailFormReducer(
  state: SendEmailFormState,
  action
): SendEmailFormState {
  const { type, payload } = action;

  switch (type as SendEmailFormActions) {
    case SendEmailFormActions.SET_RECIPIENTS:
      return { ...state, email: payload };
    case SendEmailFormActions.SET_EMAIL:
      return { ...state, email: { ...state.email, ...payload } };
    case SendEmailFormActions.SET_FILES:
      return { ...state, email: { ...state.email, s3Attachments: payload } };
    case SendEmailFormActions.START_FETCHING_DATA:
      return { ...state, fetchingData: true };
    case SendEmailFormActions.STOP_FETCHING_DATA:
      return { ...state, fetchingData: false };
    case SendEmailFormActions.SET_ERRORS:
      return { ...state, errors: payload, sendingEmail: false };
    case SendEmailFormActions.START_SENDING_EMAIL:
      return { ...state, sendingEmail: true };
    case SendEmailFormActions.STOP_SENDING_EMAIL:
      return { ...state, sendingEmail: false };
    default:
      return state;
  }
}
