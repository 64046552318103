import purchaseOrderApi from 'api/purchase-order';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function printPdf(poID) {
  return () => {
    purchaseOrderApi.getPDF(poID)
      .catch((error) => {
        notification({
          type: NotificationType.ERROR,
          message: resolveApiErrorMessage((error as any).response),
        });
      });
  };
}
