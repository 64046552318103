import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import { ButtonGroupProps } from 'antd/lib/button';

const ButtonGroup: FunctionComponent<ButtonGroupProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Button.Group {...rest}>
      {children}
    </Button.Group>
  );
};

export default ButtonGroup;
