import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router';
import { PaymentMethodModel } from 'models/payment-method';
import paymentMethodApi from 'api/payment-method';
import invoiceApi from 'api/invoice';
import useCallApiAction from 'hooks/base/call-api-action';
import { TransactionSummary } from 'models/invoice';
import useGetList from 'hooks/base/get-list';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import setFormValue from './actions/set-form-value';
import setErrors from './actions/set-errors';
import getState from './actions/get-state';
import { paymentMethodsOptions } from './actions/map-options';
import paymentFormReducer, { PaymentFormActions } from './reducer';
import savePayment from './actions/save-payment';

export interface DataTransaction {
  jobTitle: string;
  companyName: string;
  totalWithTax: number;
  orderId: number;
}

export interface PaymentFormNavigationState {
  paymentForm: {
    data: DataTransaction;
  };
}

export default function usePaymentFormState() {
  const { location: { state: navigationState }, goBack } = useHistory();
  const { paymentForm: { data } } = navigationState as PaymentFormNavigationState;
  const [state, dispatch] = useReducer(paymentFormReducer, null, getState);

  const {
    fetching: fetchingPaymentMethods,
    data: paymentMethods,
  } = useGetList<PaymentMethodModel>(paymentMethodApi);

  const {
    fetching: fetchingSummary,
    data: summary,
  } = useCallApiAction<TransactionSummary>(
    invoiceApi.getTransactionSummary, data.orderId, {} as TransactionSummary
  );

  useEffect(() => {
    if (summary) {
      dispatch({
        type: PaymentFormActions.SET_AMOUNT,
        payload: summary.outstanding || '0.00'
      });
    }
  }, [summary]);

  return {
    state: {
      ...state,
      summary,
      data,
      fetchingPaymentMethods,
      fetchingData: fetchingSummary,
      paymentMethods: useMemo(
        () => mapGenericDropdownOptions(paymentMethods, paymentMethodsOptions),
        [paymentMethods],
      ),
    },
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      savePayment: useCallback(
        savePayment(dispatch, data, state.payment, goBack),
        [navigationState, state.payment, data],
      ),
    },
  };
}
