import React, { FunctionComponent } from 'react';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import { validationRules } from 'components/pages/Manufacturers/Form/validations';
import ContentForm from 'components/pages/Manufacturers/Form/Content';
import useManufacturerFormState from 'components/pages/Manufacturers/Form/state';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const CreateManufacturer: FunctionComponent<Props> = (props) => {
  const { visible, onClose } = props;
  const {
    state: {
      manufacturer,
      savingManufacturer,
      errors,
    },
    actions: {
      setFormValue,
      saveManufacturer,
      setErrors,
    },
  } = useManufacturerFormState(undefined, onClose);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={saveManufacturer}
      confirmLoading={savingManufacturer}
      okText="Submit"
      title="Create Manufacturer"
      zIndex={9998}
    >
      <Form
        state={manufacturer}
        onChange={setFormValue}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <ContentForm disableNameInput={manufacturer.url} />
      </Form>
    </Modal>
  );
};

export default CreateManufacturer;
