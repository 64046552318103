import { TaskStatusModel } from 'models/task-status';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_TASKS_API } = process.env;
const endpoint = `${REACT_APP_TASKS_API}/status/`;

const taskStatusApi: ApiModel<TaskStatusModel> = {
  ...getApi(endpoint, ApiContentType.JSON, true),
};

export default taskStatusApi;
