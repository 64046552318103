import { useEffect, useMemo, useState } from 'react';
import { QuoteModel } from 'models/quote';
import { OrderOptionModel } from 'models/order';
import { ShipmentContactModel, ShipmentDTO } from 'models/shipment';
import shipmentContactApi from 'api/shipment-contact';
import useGetOne from 'hooks/base/get-one';
import resolveProducts from './actions/resolve-products';

interface PrintoutInfo {
  quote: QuoteModel;
  shipment: ShipmentDTO;
}

export interface PaintProductModel {
  model: string;
  description: string;
  options: OrderOptionModel[];
  quantity: number;
  dateSent: string;
}

function loadData() {
  const data = localStorage.getItem('third-party-paint-data');

  if (data) {
    return JSON.parse(data);
  }

  return {};
}

export interface ThirdPartyPaintState {
  fetching: boolean;
  products: PaintProductModel[];
  error: any;
}

const initialState: ThirdPartyPaintState = {
  fetching: true,
  products: [],
  error: undefined,
};

export default function useThirdPartyPaintState() {
  const { quote, shipment } = useMemo<PrintoutInfo>(loadData, []);
  const [state, setState] = useState<ThirdPartyPaintState>(initialState);
  const { fetching, products, error } = state;

  const {
    fetching: fetchingContact,
    data: contact,
    error: contactError,
  } = useGetOne<ShipmentContactModel>(shipmentContactApi, shipment?.contact);

  useEffect(() => {
    if (quote) {
      resolveProducts(setState, quote);
    }
  }, [quote]);

  return {
    noData: !quote && !shipment,
    error: contactError || error,
    fetching: fetchingContact || fetching,
    products,
    quote,
    contact
  };
}
