import { AccountTaxModel } from 'models/account';
import { ExtraChargeModel } from 'models/extra-charge';

function getTotalExtraCharges(extraCharges: ExtraChargeModel[]) {
  return extraCharges && extraCharges.length > 0
    ? extraCharges.reduce(
      (total, extraCharge) => total + parseFloat(extraCharge.amount),
      0,
    )
    : 0;
}

export function calculateTax(shippingPrice, orderTotal, taxPercentage, extraCharges: ExtraChargeModel[]) {
  const totalExtraCharges = getTotalExtraCharges(extraCharges);
  return (shippingPrice + orderTotal + totalExtraCharges) * taxPercentage;
}

export function calculateSubTotal(orderTotal, extraCharges: ExtraChargeModel[], shippingPrice) {
  const totalExtraCharges = getTotalExtraCharges(extraCharges);
  return orderTotal + totalExtraCharges + shippingPrice;
}

export function calculateTotal(
  taxes: AccountTaxModel[],
  shippingPrice: number,
  orderTotal: number,
  extraCharges: ExtraChargeModel[],
) {
  const totalExtraCharges = getTotalExtraCharges(extraCharges);
  const totalTax = taxes && taxes.length > 0
    ? taxes.reduce((total, tax) => (
      total + calculateTax(shippingPrice, orderTotal, tax.taxPercentage, extraCharges)
    ), 0)
    : 0;

  return orderTotal + shippingPrice + totalTax + totalExtraCharges;
}
