import { GenerateProductsActions, Rate } from '../reducer';

export default function onFilter(dispatch, allItems: Rate[], filter: string) {
  const payload = !filter
    ? allItems
    : allItems.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()));

  dispatch({
    type: GenerateProductsActions.SET_DATA,
    payload,
  });
}
