import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { PropertySettingModel } from 'models/property-setting';
import propertySettingApi from 'api/property-setting';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { PropertyFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: propertySettingApi,
  deleteModalTitle: 'Delete Property Setting',
  deleteModalContent: (propertyToDelete: PropertySettingModel) => `Are you sure that you want to delete the Property Setting ${propertyToDelete ? propertyToDelete.name : ''}?`,
  searchFields: ['name', 'property_types'],
  eventChannel: EventChannelList.PROPERTY_SETTING_CHANGED,
};

const PropertyList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<PropertySettingModel>(useTableStateParams);

  const openPropertyFormModal = (property?: PropertySettingModel | any) => {
    const historyLocationState: PropertyFormHistorySateModel = {
      propertyForm: {
        propertyUrl: property ? property.url : undefined,
      },
    };
    push(ModalRouteHash.PropertySetting, historyLocationState);
  };

  return (
    <Page title="Property Setting">
      <PageActions primary>
        <AccessControl permissions={permissions.addProperty}>
          <Button
            onClick={openPropertyFormModal}
            label="Add Property"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn dataIndex="name" columnKey="name" title="Name" sorter />
        <DataTableColumn dataIndex="propertyTypes" columnKey="property_types" title="Types" sorter />
        <DataTableColumn dataIndex="increment" columnKey="increment" title="Increment" sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editProperty}
          deletePermissions={permissions.deleteProperty}
          onEdit={openPropertyFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default PropertyList;
