import { useCallback, useMemo, useReducer } from 'react';
import orderApi from 'api/orders';
import { OrderDTO } from 'models/order';
import { EventChannelList } from 'helpers/event-center';
import hasPermissions from 'helpers/permissons';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import calculateCNC from './actions/calculate';
import calculateBulkCNC from './actions/calculate-bulk';
import checkIsProcessingOrder from './actions/check-is-processing-order';
import cncListReducer, { CNCListActions } from './reducer';

const orderTableStateParams: UseTableStateParams = {
  api: orderApi,
  eventChannel: EventChannelList.ORDERS_LIST_CHANGED,
  searchFields: ['quote', 'order_status'],
  queryParams: { orderStatus: 2 },
  defaultSortField: '-id',
};

export default function useCNCListState() {
  const [state, dispatch] = useReducer(cncListReducer, {
    selectedRows: [],
    ordersInProcess: [],
    calculatingBulk: false,
  });
  const {
    state: tableState,
    actions: tableActions,
  } = useTableState<OrderDTO>(orderTableStateParams);

  const rowSelection = useMemo(() => ({
    selectedRowKeys: state.selectedRows,
    onChange: (selectedRowKeys) => {
      dispatch({
        type: CNCListActions.SET_SELECTED_ROWS,
        payload: selectedRowKeys,
      });
    },
  }), [state.selectedRows]);

  return {
    state: {
      ...tableState,
      ...state,
      rowSelection,
      linkToOrder: useMemo(() => hasPermissions(['Sales-Orders', 'Dealer', 'Sales']) ? (cnc) => `quote/${cnc?.quote?.id}` : undefined, [])
    },
    actions: {
      ...tableActions,
      calculate: useCallback(calculateCNC(dispatch), []),
      calculateBulk: useCallback(
        calculateBulkCNC(dispatch, tableState.data, state.selectedRows),
        [tableState.data, state.selectedRows],
      ),
      checkIsProcessingOrder: useCallback(
        checkIsProcessingOrder(state.ordersInProcess),
        [state.ordersInProcess],
      ),
    },
  };
}
