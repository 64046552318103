import React, { FunctionComponent, useMemo } from 'react';
import projectsApi from 'api/projects';
import taskUserApi from 'api/task-user';
import { TaskModel } from 'models/task';
import Modal from 'components/base-components/ModalV2';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import DatePicker from 'components/base-components/DatePicker';
import Select from 'components/base-components/Select';
import TextArea from 'components/base-components/TextArea';
import Checkbox from 'components/base-components/Checkbox';
import useTaskFormSate, { mapProjects, mapUsers, validationRules } from './state';

interface Props{
  visible: boolean;
  taskInstance: TaskModel;
  onCancel: any;
}

const TaskForm: FunctionComponent<Props> = (props) => {
  const { visible, onCancel } = props;
  const {
    state: {
      task,
      statusList,
      verbosityOptions,
      fetching,
      errors,
      savingTask,
    },
    actions: {
      setFormValue,
      handleSubsChange,
      handleSmsSubChange,
      handleEmailSubChange,
      handleVerbosityChange,
      setErrors,
      saveTask,
    },
  } = useTaskFormSate(props);

  const title = useMemo(() => {
    if (task) {
      return task.id ? 'Edit Task' : 'Create Task';
    }
    return 'Task';
  }, [task]);

  return (
    <Modal
      visible={visible}
      title={title}
      okText="Submit"
      onOk={saveTask}
      onCancel={onCancel}
      confirmLoading={savingTask}
    >
      <RenderIf isTrue={!fetching} fallback={<Spinner />}>
        <Form
          state={task}
          errors={errors}
          onChange={setFormValue}
          onError={setErrors}
          rules={validationRules}
        >
          <Field name="title" label="Title" required />
          <Field
            name="project"
            label="Project"
            component={AsyncSelectV2}
            listAction={projectsApi.list}
            mapFunction={mapProjects}
          />
          <Field
            name="assignee"
            label="Assigned to"
            component={AsyncSelectV2}
            listAction={taskUserApi.list}
            mapFunction={mapUsers}
            required
          />
          <Field
            name="status"
            label="Status"
            component={Select}
            options={statusList}
          />
          <Field
            name="dueDate"
            label="Due date"
            component={DatePicker}
            required
          />
          <Field
            name="description"
            label="Description"
            component={TextArea}
            required
          />
          <Field
            name="notifyAssigner"
            label="Notify Assigner"
            component={Checkbox}
            onChange={handleSubsChange}
          />
          <RenderIf isTrue={task?.notifyAssigner}>
            <Field
              name="notifyBySms"
              label="Notify by sms"
              component={Checkbox}
              onChange={handleSmsSubChange}
            />
            <Field
              name="notifyByEmail"
              label="Notify by email"
              component={Checkbox}
              onChange={handleEmailSubChange}
            />
            <Field
              name="verbosity"
              label="How many notifications"
              component={Select}
              options={verbosityOptions}
              onChange={handleVerbosityChange}
            />
          </RenderIf>
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default TaskForm;
