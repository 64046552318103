import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum AccountFormActions {
  RESET_STATE = 'RESET_STATE',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_ERRORS = 'SET_ERRORS',
  START_SAVING_ACCOUNT = 'START_SAVING_ACCOUNT',
  STOP_SAVING_ACCOUNT = 'STOP_SAVING_ACCOUNT',
}

export interface FullAccountModel {
  businessTradeName: string;
  businessLegalName: string;
  website: string;
  businessEmail: string;
  businessPhone: string;
  businessAddress: string;
  businessCity: string;
  businessStateProvince: string;
  businessPostCode: string;
  accountsPayableName: string;
  accountPayableEmail: string;
  store: DropdownOptionModel;
  owner: DropdownOptionModel;
  salesRep: DropdownOptionModel;
  group: DropdownOptionModel;
  users: DropdownOptionModel;
  tax: DropdownOptionModel;
  paymentTerms: DropdownOptionModel;
}
interface QuickAccountFormModel {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  salesRep: string;
  paymentTerms: DropdownOptionModel;
}

export interface AccountFormState {
  account: FullAccountModel | QuickAccountFormModel;
  showFullForm: boolean;
  savingAccount: boolean;
  errors: any;
}

export default function accountFormState(state: AccountFormState, action) {
  const { type, payload } = action;

  switch (type as AccountFormActions) {
    case AccountFormActions.RESET_STATE:
      return { ...state, account: payload, errors: {}, savingAccount: false };
    case AccountFormActions.SET_FORM_VALUE:
      return { ...state, account: payload };
    case AccountFormActions.SET_ERRORS:
      return { ...state, errors: payload, savingAccount: false };
    case AccountFormActions.START_SAVING_ACCOUNT:
      return { ...state, savingAccount: true };
    case AccountFormActions.STOP_SAVING_ACCOUNT:
      return { ...state, savingAccount: false };
    default:
      return state;
  }
}
