import { EditOptionsFormActions } from '../reducer';

const defaultValue = { value: null, label: 'None' };

export function setFormValue(dispatch) {
  return (value, change) => {
    const fieldChanged = Object.keys(change)[0];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [`sub-${fieldChanged}`]: subOptionsKey, ...rest } = value;
    const payload = change[fieldChanged].dropdownItem?.haveSuboptions
      ? {
        ...value,
        [`sub-${fieldChanged}`]: defaultValue,
        [fieldChanged]: {
          ...change[fieldChanged],
        }
      }
      : rest;

    dispatch({ type: EditOptionsFormActions.SET_FORM_VALUE, payload });
  };
}
