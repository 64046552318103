import React, { FunctionComponent } from 'react';
import orderStatusApi from 'api/orde-status';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import { validationRules } from './state';
import { mapOrderStatusOptions } from '.';

interface Props {
  formData: object;
  errors: any;
  setFormValue: (task) => void;
  setErrors: (errors) => void;
  onSubmit: () => void;
  onCancel: () => void;
  visible: boolean;
  saving: boolean;
}

const OnetimeTaskForm: FunctionComponent<Props> = (props) => {
  const { formData, errors, visible, setFormValue, setErrors, onCancel, onSubmit, saving } = props;

  return (
    <Modal
      visible={visible}
      zIndex={1048}
      title="Add Onetime Task"
      okText="Submit"
      onOk={onSubmit}
      onCancel={onCancel}
      confirmLoading={saving}
    >
      <Form
        state={formData}
        onChange={setFormValue}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <Field
          id="details"
          name="details"
          label="Details"
          placeholder="Enter Details"
          required
        />
        <Field
          component={AsyncSelectV2}
          name="startingStage"
          id="startingStage"
          label="Starting Stage"
          listAction={orderStatusApi.list}
          mapFunction={mapOrderStatusOptions}
          required
        />
        <Field
          component={AsyncSelectV2}
          name="endingStage"
          id="endingStage"
          label="Ending Stage"
          listAction={orderStatusApi.list}
          mapFunction={mapOrderStatusOptions}
          required
        />
      </Form>
    </Modal>
  );
};

export default OnetimeTaskForm;
