import get from 'lodash.get';

export default function getValue(data, field, options) {
  const value = get(data, field);

  if (options) {
    if (Array.isArray(value)) {
      return value.reduce((list, val) => {
        const option = options.find(opt => opt.value === val);
        return option ? list.concat(option) : list;
      }, []);
    }

    return options.find(opt => opt.value === value);
  }

  return value;
}
