import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Modal from 'components/base-components/ModalV2';
import useAssignFilesState from './state';
import FileItem from './Item';
import './style.scss';

export interface AssignFilesHistorySateModel {
  assignFilesForm: {
    quoteId: number;
  };
}

export default function AssignFiles() {
  const { goBack, location: { state } } = useHistory();
  const { assignFilesForm: { quoteId } } = state as AssignFilesHistorySateModel;
  const {
    state: {
      fetching,
      updating,
      data,
    },
    actions: {
      setFormValue,
      updateAssignedFiles
    }
  } = useAssignFilesState(quoteId);

  const Files = useMemo(
    () => Object.keys(data).map((e) => (<FileItem key={e} itemData={data[e]} fileName={e} onChange={setFormValue} />)),
    [data, setFormValue]
  );

  return (
    <Modal
      title="Assign Files"
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={updateAssignedFiles}
      confirmLoading={updating}
    >
      <div className="assign-file__container-list">
        <RenderIf isTrue={!fetching} fallback={<Spinner />}>
          {Files}
        </RenderIf>
      </div>
    </Modal>
  );
}
