import { AxiosResponse } from 'axios';
import { TaskModel } from 'models/task';
import taskApi from 'api/task';
import taskSubscriptionApi from 'api/task-subscription';
import { validateEntity } from 'helpers/form-validations';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { TaskFormActions, TaskFormModel } from '../reducer';
import { validationRules } from '../validation-rules';
import getPayload from './get-payload';
import getSubsPayload from './get-subscriptions-payload';

async function deleteSubs(assignerId, taskId) {
  const { data: { results: subs } } = await taskSubscriptionApi.list({
    filters: {
      taskUser: assignerId,
      task: taskId,
    },
  });
  const promises = subs.map(sub => taskSubscriptionApi.delete(sub.id));

  return Promise.all(promises);
}

async function createSubs(taskId: number, task: TaskFormModel) {
  await deleteSubs(task.assigner, taskId);

  const {
    emailSubscription,
    smsSubscription,
  } = getSubsPayload(taskId, task);
  const subs = [];
  if (emailSubscription) {
    subs.push(emailSubscription);
  }
  if (smsSubscription) {
    subs.push(smsSubscription);
  }
  const promises = subs.map((sub) => taskSubscriptionApi.create(sub));

  return Promise.all(promises);
}

export default function saveTask(dispatch, task: TaskFormModel, goBack) {
  return () => {
    dispatch({ type: TaskFormActions.START_SAVING });

    const { hasErrors, errors } = validateEntity(task, validationRules);

    if (hasErrors) {
      return dispatch({
        type: TaskFormActions.SET_ERRORS,
        payload: errors,
      });
    }

    const onTaskSaved = (response: AxiosResponse<TaskModel>) => {
      const { data: { id: taskId, notifyAssigner, assigner } } = response;

      if (task.updateSubs) {
        return notifyAssigner
          ? createSubs(taskId, task)
          : deleteSubs(assigner, taskId);
      }

      return undefined;
    };

    const onSuccess = () => {
      const message = task.id
        ? 'Task edited successfully'
        : 'Task created successfully';

      notification({
        type: NotificationType.SUCCESS,
        message,
      });
      notifyEventChannel(EventChannelList.TASK_PAGE_LIST_CHANGED);
      goBack();
    };

    const onError = () => {
      const message = task.id
        ? 'Task failed to edit'
        : 'Task failed to create';

      notification({
        type: NotificationType.ERROR,
        message,
      });
    };

    const onFinally = () => {
      dispatch({ type: TaskFormActions.FINISH_SAVING });
    };

    const payload = getPayload(task);
    const action = task.id ? taskApi.update : taskApi.create;

    return action(payload)
      .then(onTaskSaved)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
