import React, { useMemo } from 'react';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import Upload from 'components/base-components/Upload';
import { useHistory } from 'react-router-dom';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import Checkbox from 'components/base-components/Checkbox';
import userGroupApi from 'api/user-group';
import permissionApi from 'api/permission';
import useUserFormState, { mapOptions } from './state';
import getValidations from './state/validations';

export interface UserFormHistorySateModel {
  userForm: {
    userUrl: string;
  };
}

const permissionFilters = {
  filters: {
    searchFields: ['codename', 'name'],
  }
};

const userGroupFilters = {
  filters: {
    searchFields: 'name',
  }
};

export default function UserForm() {
  const { goBack, location: { state } } = useHistory();

  const { userForm: { userUrl } } = state as UserFormHistorySateModel;

  const {
    state: {
      user,
      fetchingFormData,
      savingUser,
      errors,
    },
    actions: {
      setFormValue,
      saveUser,
      setErrors,
    },
  } = useUserFormState(userUrl);

  const validationRules = useMemo(
    () => getValidations(!user.cognitoId),
    [user.cognitoId]
  );

  const formModalTitle = useMemo(
    () => userUrl ? 'Edit User' : 'Create User',
    [userUrl],
  );

  return (
    <Modal
      visible
      onCancel={goBack}
      okText="Submit"
      title={formModalTitle}
      onOk={saveUser}
      confirmLoading={savingUser}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={user}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            type="email"
            id="email"
            name="email"
            label="Email address"
            placeholder="Enter email"
            required
          />
          <RenderIf isTrue={!user.url}>
            <Field
              id="username"
              name="username"
              label="Username"
              placeholder="Enter username"
              required
            />
          </RenderIf>
          <Field
            type="text"
            id="lastName"
            name="lastName"
            label="Last name"
            placeholder="Enter last name"
            required
          />
          <Field
            type="text"
            name="firstName"
            id="firstName"
            label="First name"
            placeholder="Enter first name"
            required
          />
          <Field
            type="text"
            name="title"
            id="title"
            label="Title"
            placeholder="Enter title"
            required
          />
          <Field
            type="password"
            name="password"
            id="password"
            label="Enter password"
            placeholder="Password"
          />
          <Field
            type="password"
            name="passwordRepeat"
            id="passwordRepeat"
            placeholder="Repeat password"
            label="Repeat Password"
          />
          <div>
            <Field
              component={Checkbox}
              name="isStaff"
              id="isStaff"
              label="is Staff"
            />
            <Field
              component={Checkbox}
              name="isSuperuser"
              id="isSuperuser"
              label="is Superuser"
            />
          </div>
          <Field
            component={AsyncSelectV2}
            name="groups"
            id="groups"
            placeholder="Select groups"
            label="Groups"
            mode="multiple"
            listAction={userGroupApi.list}
            mapFunction={mapOptions}
            filters={userGroupFilters}
          />
          <Field
            component={AsyncSelectV2}
            name="userPermissions"
            id="permissions"
            placeholder="Select permissions"
            label="Permissions"
            mode="multiple"
            listAction={permissionApi.list}
            mapFunction={mapOptions}
            filters={permissionFilters}
          />
          <Field
            component={Upload}
            name="image"
            id="image"
            label="Choose Image"
            accept="image/png, .jpeg, .jpg"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
}
