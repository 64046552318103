import React, { FunctionComponent } from 'react';
import { Summary } from 'components/base-components/DataTableV2';
import { formatCurrency } from 'helpers/formatting';
import { AccountTaxModel } from 'models/account';
import { calculateTax } from './util';

interface Props {
  orderTotal: number;
  shippingPrice: number;
  taxes: AccountTaxModel[];
  colSpan: number;
}

const Taxes: FunctionComponent<Props> = (props): any => {
  const { orderTotal, shippingPrice, taxes, colSpan } = props;

  return taxes.map((tax) => {
    const { taxCode, taxPercentage } = tax;
    return (
      <Summary.Row key={`account-tax-${taxCode}`} className="quote-products-table__summary-row">
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          <b>{taxCode}</b>
        </Summary.Cell>
        <Summary.Cell index={1} align="right" className="quote-products-table__summary-row">
          <b>
            {formatCurrency(calculateTax(shippingPrice, orderTotal, taxPercentage))}
          </b>
        </Summary.Cell>
      </Summary.Row>
    );
  });
};

Taxes.defaultProps = {
  taxes: [],
};

export default Taxes;
