import { IncomeCustomerForm } from '../../common';

export enum IncomeCustomerActions {
  SET_FORM_INCOME_CUSTOMER_VALUE = 'SET_FORM_INCOME_CUSTOMER_VALUE',
}

interface IncomeCustomerState {
  formIncomeCustomerData: IncomeCustomerForm;
}

export default function incomeCustomerReducer(
  state: IncomeCustomerState, action
): IncomeCustomerState {
  const { type, payload } = action;

  switch (type as IncomeCustomerActions) {
    case IncomeCustomerActions.SET_FORM_INCOME_CUSTOMER_VALUE:
      return { ...state, formIncomeCustomerData: payload };
    default:
      return state;
  }
}
