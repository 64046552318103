import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import orderProductApi from 'api/order-product';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import TextArea from 'components/base-components/TextArea';
import NumberInput from 'components/base-components/NumberInput';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import DatePicker from 'components/base-components/DatePicker';
import validationRules from './state/validations';
import usePurchaseOrderItemFormState, { mapOrderProductsOptions } from './state';

export interface PurchaseOrderItemFormHistorySateModel {
  purchaseOrderItemForm: {
    id: string;
    purchaseOrderId: string;
    fromProjectCoordinator?: boolean;
  };
}

export default function PurchaseOrderItemForm() {
  const { location: { state } } = useHistory();

  const {
    purchaseOrderItemForm: { id, purchaseOrderId, fromProjectCoordinator }
  } = state as PurchaseOrderItemFormHistorySateModel;

  const {
    state: {
      purchaseOrderItem,
      fetchingFormData,
      savingPurchaseOrderItem,
      errors,
    },
    actions: {
      setFormValue,
      savePurchaseOrder,
      setErrors,
      goBack
    },
  } = usePurchaseOrderItemFormState(id, purchaseOrderId, fromProjectCoordinator);

  const formModalTitle = useMemo(() => id ? 'Edit Purchase Order Item' : 'Create Purchase Order Item', [id]);

  return (
    <Modal
      visible
      onCancel={goBack}
      okText="Submit"
      cancelText="Done"
      title={formModalTitle}
      onOk={savePurchaseOrder}
      confirmLoading={savingPurchaseOrderItem}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={purchaseOrderItem}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="partName"
            name="partName"
            label="Part Name"
            placeholder="Part Name"
          />
          <Field
            component={TextArea}
            id="description"
            name="description"
            label="Description"
            placeholder="Description"
            rows={4}
          />
          <Field
            component={NumberInput}
            id="quantity"
            name="quantity"
            label="Quantity"
            placeholder="Quantity"
          />
          <Field
            component={NumberInput}
            id="price"
            name="price"
            label="Price"
            placeholder="Price"
            allowDecimals
          />
          <Field
            id="model"
            name="model"
            label="Model"
            placeholder="Model Code"
          />
          <Field
            id="mpn"
            name="mpn"
            label="MPN"
            placeholder="Manufacturer's code for product"
          />
          <Field
            component={DatePicker}
            name="sentToPaint"
            id="sentToPaint"
            label="Sent To Paint"
          />
          <Field
            component={AsyncSelectV2}
            id="orderProduct"
            name="orderProduct"
            label="Order Product"
            listAction={orderProductApi.list}
            mapFunction={mapOrderProductsOptions}
          />
        </Form>
      </RenderIf>
    </Modal>
  );
}
