import { QuoteModel } from 'models/quote';

export default function getPayload(quote: QuoteModel, formData: {specialInstructions: string}) {
  return {
    id: quote.id,
    comments: quote.comments,
    shippingPrice: quote.shippingPrice,
    specialInstructions: formData.specialInstructions,
    project: quote.project.id
  };
}
