import { ApplyOptionsFormActions } from '../reducer';

const defaultValue = { value: '', label: 'None' };

export function setFormValue(dispatch) {
  return (value, change) => {
    const fieldChanged = Object.keys(change)[0];
    const payload = change[fieldChanged].dropdownItem?.haveSuboptions
      ? {
        ...value,
        [`sub-${fieldChanged}`]: defaultValue,
        [fieldChanged]: {
          ...change[fieldChanged],
        }
      }
      : value;

    dispatch({ type: ApplyOptionsFormActions.SET_FORM_VALUE, payload });
  };
}
