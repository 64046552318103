import { ExtraChargeModel } from 'models/extra-charge';
import { QuoteProductListActions } from '../reducer';

export default function handleExtraChargeChanged(dispatch, extraCharges: ExtraChargeModel[]) {
  return (updatedExtraCharge: ExtraChargeModel) => {
    const payload = extraCharges.reduce((list, extraCharge) => {
      if (!extraCharge.id || extraCharge.id === updatedExtraCharge.id) {
        return list.concat([{
          ...updatedExtraCharge,
          amount: updatedExtraCharge.amount.toString(),
        }]);
      }
      return list.concat([extraCharge]);
    }, []);

    dispatch({ type: QuoteProductListActions.SET_EXTRA_CHARGES, payload });
  };
}
