import { TablePaginationConfig } from 'antd/lib/table';
import { AccountApplicationTableActions } from '../reducer';

export function handleOnChange(dispatch) {
  return (
    pagination: TablePaginationConfig,
    _,
    sorter: any,
  ) => {
    const { current } = pagination;
    const { column, order } = sorter;

    const newOrder = order === 'descend' ? '-' : '';
    let newSortField = '';
    if (column) {
      newSortField = column.dataIndex || column.key;
    }

    dispatch({
      type: AccountApplicationTableActions.HANDLE_ON_CHANGE,
      payload: {
        page: current,
        sortField: `${newOrder}${newSortField}`,
      },
    });
  };
}
