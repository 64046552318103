import moment from 'moment';

export default function getState(outstanding: number) {
  const amount = !outstanding || outstanding < 0 ? '0.00' : outstanding;
  return {
    payment: {
      type: { key: 1, value: 1, label: 'Cash' },
      name: '',
      comments: '',
      checkDate: moment(),
      receivedDate: moment(),
      amount,
      chequeNumber: 0,
      back: undefined,
      front: undefined,
    },
    savingPayment: false,
    errors: {},
  };
}
