import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import filesViewerApi from 'api/file-viewer';
import getEventValue from 'helpers/get-event-value';
import { FileViewerFormActions } from '../reducer';

export default function addNewFolder(params: string, quoteId: number, dispatch, callApi) {
  return (event) => {
    const folderName = getEventValue(event);
    if (folderName) {
      dispatch({ type: FileViewerFormActions.START_ADDING, payload: folderName });

      const onSuccess = async () => {
        const message = 'Folder Successfully Created';
        notification({ type: NotificationType.SUCCESS, message });
        dispatch({ type: FileViewerFormActions.SET_ADD_FOLDER });
        callApi();
      };

      const onError = (error) => {
        const message = resolveApiErrorMessage((error as any).response);
        notification({ type: NotificationType.ERROR, message });
      };

      const onFinally = () => {
        dispatch({ type: FileViewerFormActions.FINISH_ADDING, payload: folderName });
      };

      return filesViewerApi.createFolder({ quoteId, params, folderName })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    }
    return dispatch({ type: FileViewerFormActions.SET_ADD_FOLDER });
  };
}
