import { IncomeCustomerForm } from '../../common';

export enum IncomeCustomerGroupFormAction {
  SET_FORM_INCOME_CUSTOMER_GROUP_VALUE = 'SET_FORM_INCOME_CUSTOMER_GROUP_VALUE',
}

interface IncomeCustomerGroupState {
  formIncomeCustomerGroupData: IncomeCustomerForm;
}

export default function incomeCustomerGroupReducer(
  state: IncomeCustomerGroupState, action
): IncomeCustomerGroupState {
  const { type, payload } = action;

  switch (type as IncomeCustomerGroupFormAction) {
    case IncomeCustomerGroupFormAction.SET_FORM_INCOME_CUSTOMER_GROUP_VALUE:
      return { ...state, formIncomeCustomerGroupData: payload };
    default:
      return state;
  }
}
