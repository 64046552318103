export enum AppPermissions {
  'frontend_is_superuper' = 'frontend_is_superuper',
  'Account-Users' = 'Account-Users',
  'Account-User_Groups' = 'Account-User_Groups',
  'Account-Accounts' = 'Account-Accounts',
  'Account-Account_Groups' = 'Account-Account_Groups',
  'Account-Terms' = 'Account-Terms',
  'Account-Account_Applications' = 'Account-Account_Applications',
  'Catalog-Manufacturers' = 'Catalog-Manufacturers',
  'Catalog-Products' = 'Catalog-Products',
  'Catalog-Options' = 'Catalog-Options',
  'Catalog-Option_Groups' = 'Catalog-Option_Groups',
  'Catalog-Categories' = 'Catalog-Categories',
  'Sales-Quotes' = 'Sales-Quotes',
  'Sales-Orders' = 'Sales-Orders',
  'Sales-Deficiency_Orders' = 'Sales-Deficiency_Orders',
  'Sales-Invoices' = 'Sales-Invoices',
  'Sales-Payments' = 'Sales-Payments',
  'Sales-Project_Designs' = 'Sales-Project_Designs',
  'Sales_Pipeline-Create_Project' = 'Sales_Pipeline-Create_Project',
  'Reports' = 'Reports',
  'Settings-Three_Models' = 'Settings-Three_Models',
  'Settings-Views_Permissions' = 'Settings-Views_Permissions',
  'Settings-Shipping-Shipping_Zones' = 'Settings-Shipping-Shipping_Zones',
  'Settings-Shipping-Installer' = 'Settings-Shipping-Installer',
  'Settings-Account-Stores' = 'Settings-Account-Stores',
  'Settings-Catalog-Category_Image' = 'Settings-Catalog-Category_Image',
  'Settings-Catalog-Generate_Products' = 'Settings-Catalog-Generate_Products',
  'Settings-Materials-Sheet_Setting' = 'Settings-Materials-Sheet_Setting',
  'Settings-Textures' = 'Settings-Textures',
  'Settings-Tutorials' = 'Settings-Tutorials',
  'Settings-Attachment_Document' = 'Settings-Attachment_Document',
  'Settings-Task_Templates' = 'Settings-Task_Templates',
  'Settings-Property_Setting' = 'Settings-Property_Setting',
  'Settings-QBO_Sync' = 'Settings-QBO_Sync',
  'Productions-Production_Manager' = 'Productions-Production_Manager',
  'Productions-Installers_Booking' = 'Productions-Installers_Booking',
  'Productions-Project_Coordinator' = 'Productions-Project_Coordinator',
  'Productions-Cutlist' = 'Productions-Cutlist',
  'Productions-CNC_Calc' = 'Productions-CNC_Calc',
  'Productions-CNC_Worker' = 'Productions-CNC_Worker',
  'Productions-Purchase_Orders' = 'Productions-Purchase_Orders',
  'Productions-Parts' = 'Productions-Parts',
  'Inventory-Inventory' = 'Inventory-Inventory',
  'Dealer' = 'Dealer',
  'Sales' = 'Sales',
  'Shop' = 'Shop',
  'view_logentry' = 'view_logentry',
  'add_permission' = 'add_permission',
  'change_permission' = 'change_permission',
  'delete_permission' = 'delete_permission',
  'view_permission' = 'view_permission',
  'add_group' = 'add_group',
  'change_group' = 'change_group',
  'delete_group' = 'delete_group',
  'view_group' = 'view_group',
  'add_contenttype' = 'add_contenttype',
  'change_contenttype' = 'change_contenttype',
  'delete_contenttype' = 'delete_contenttype',
  'view_contenttype' = 'view_contenttype',
  'add_session' = 'add_session',
  'change_session' = 'change_session',
  'delete_session' = 'delete_session',
  'view_session' = 'view_session',
  'add_user' = 'add_user',
  'change_user' = 'change_user',
  'delete_user' = 'delete_user',
  'view_user' = 'view_user',
  'add_category' = 'add_category',
  'change_category' = 'change_category',
  'delete_category' = 'delete_category',
  'view_category' = 'view_category',
  'add_manufacturer' = 'add_manufacturer',
  'change_manufacturer' = 'change_manufacturer',
  'delete_manufacturer' = 'delete_manufacturer',
  'view_manufacturer' = 'view_manufacturer',
  'add_product' = 'add_product',
  'change_product' = 'change_product',
  'delete_product' = 'delete_product',
  'view_product' = 'view_product',
  'add_options' = 'add_options',
  'change_options' = 'change_options',
  'delete_options' = 'delete_options',
  'view_options' = 'view_options',
  'add_optiongroup' = 'add_optiongroup',
  'change_optiongroup' = 'change_optiongroup',
  'delete_optiongroup' = 'delete_optiongroup',
  'view_optiongroup' = 'view_optiongroup',
  'add_terms' = 'add_terms',
  'change_terms' = 'change_terms',
  'delete_terms' = 'delete_terms',
  'view_terms' = 'view_terms',
  'add_account' = 'add_account',
  'change_account' = 'change_account',
  'delete_account' = 'delete_account',
  'view_account' = 'view_account',
  'add_accountapplication' = 'add_accountapplication',
  'change_accountapplication' = 'change_accountapplication',
  'delete_accountapplication' = 'delete_accountapplication',
  'view_accountapplication' = 'view_accountapplication',
  'add_accountgroup' = 'add_accountgroup',
  'change_accountgroup' = 'change_accountgroup',
  'delete_accountgroup' = 'delete_accountgroup',
  'view_accountgroup' = 'view_accountgroup',
  'add_store' = 'add_store',
  'change_store' = 'change_store',
  'delete_store' = 'delete_store',
  'view_store' = 'view_store',
  'add_shippingaddress' = 'add_shippingaddress',
  'change_shippingaddress' = 'change_shippingaddress',
  'delete_shippingaddress' = 'delete_shippingaddress',
  'view_shippingaddress' = 'view_shippingaddress',
  'add_shippingquestionnaire' = 'add_shippingquestionnaire',
  'change_shippingquestionnaire' = 'change_shippingquestionnaire',
  'delete_shippingquestionnaire' = 'delete_shippingquestionnaire',
  'view_shippingquestionnaire' = 'view_shippingquestionnaire',
  'add_shippingzones' = 'add_shippingzones',
  'change_shippingzones' = 'change_shippingzones',
  'delete_shippingzones' = 'delete_shippingzones',
  'view_shippingzones' = 'view_shippingzones',
  'add_shipment' = 'add_shipment',
  'change_shipment' = 'change_shipment',
  'delete_shipment' = 'delete_shipment',
  'view_shipment' = 'view_shipment',
  'add_offload' = 'add_offload',
  'change_offload' = 'change_offload',
  'delete_offload' = 'delete_offload',
  'view_offload' = 'view_offload',
  'add_shipmenttypes' = 'add_shipmenttypes',
  'change_shipmenttypes' = 'change_shipmenttypes',
  'delete_shipmenttypes' = 'delete_shipmenttypes',
  'view_shipmenttypes' = 'view_shipmenttypes',
  'add_countries' = 'add_countries',
  'change_countries' = 'change_countries',
  'delete_countries' = 'delete_countries',
  'view_countries' = 'view_countries',
  'add_provinces' = 'add_provinces',
  'change_provinces' = 'change_provinces',
  'delete_provinces' = 'delete_provinces',
  'view_provinces' = 'view_provinces',
  'add_orderstatus' = 'add_orderstatus',
  'change_orderstatus' = 'change_orderstatus',
  'delete_orderstatus' = 'delete_orderstatus',
  'view_orderstatus' = 'view_orderstatus',
  'add_projects' = 'add_projects',
  'change_projects' = 'change_projects',
  'delete_projects' = 'delete_projects',
  'view_projects' = 'view_projects',
  'add_quotes' = 'add_quotes',
  'change_quotes' = 'change_quotes',
  'delete_quotes' = 'delete_quotes',
  'view_quotes' = 'view_quotes',
  'add_projectfiles' = 'add_projectfiles',
  'change_projectfiles' = 'change_projectfiles',
  'delete_projectfiles' = 'delete_projectfiles',
  'view_projectfiles' = 'view_projectfiles',
  'add_orders' = 'add_orders',
  'change_orders' = 'change_orders',
  'delete_orders' = 'delete_orders',
  'view_orders' = 'view_orders',
  'add_orderproducts' = 'add_orderproducts',
  'change_orderproducts' = 'change_orderproducts',
  'delete_orderproducts' = 'delete_orderproducts',
  'view_orderproducts' = 'view_orderproducts',
  'add_quotefiles' = 'add_quotefiles',
  'change_quotefiles' = 'change_quotefiles',
  'delete_quotefiles' = 'delete_quotefiles',
  'view_quotefiles' = 'view_quotefiles',
  'add_flatrate' = 'add_flatrate',
  'change_flatrate' = 'change_flatrate',
  'delete_flatrate' = 'delete_flatrate',
  'view_flatrate' = 'view_flatrate',
  'add_accessmethods' = 'add_accessmethods',
  'change_accessmethods' = 'change_accessmethods',
  'delete_accessmethods' = 'delete_accessmethods',
  'view_accessmethods' = 'view_accessmethods',
  'add_choice' = 'add_choice',
  'change_choice' = 'change_choice',
  'delete_choice' = 'delete_choice',
  'view_choice' = 'view_choice',
  'add_field' = 'add_field',
  'change_field' = 'change_field',
  'delete_field' = 'delete_field',
  'view_field' = 'view_field',
  'add_composition' = 'add_composition',
  'change_composition' = 'change_composition',
  'delete_composition' = 'delete_composition',
  'view_composition' = 'view_composition',
  'add_productline' = 'add_productline',
  'change_productline' = 'change_productline',
  'delete_productline' = 'delete_productline',
  'view_productline' = 'view_productline',
  'add_shipmentcontact' = 'add_shipmentcontact',
  'change_shipmentcontact' = 'change_shipmentcontact',
  'delete_shipmentcontact' = 'delete_shipmentcontact',
  'view_shipmentcontact' = 'view_shipmentcontact',
  'add_builder' = 'add_builder',
  'change_builder' = 'change_builder',
  'delete_builder' = 'delete_builder',
  'view_builder' = 'view_builder',
  'add_level' = 'add_level',
  'change_level' = 'change_level',
  'delete_level' = 'delete_level',
  'view_level' = 'view_level',
  'add_deficiencyitems' = 'add_deficiencyitems',
  'change_deficiencyitems' = 'change_deficiencyitems',
  'delete_deficiencyitems' = 'delete_deficiencyitems',
  'view_deficiencyitems' = 'view_deficiencyitems',
  'add_changeonconvert' = 'add_changeonconvert',
  'change_changeonconvert' = 'change_changeonconvert',
  'delete_changeonconvert' = 'delete_changeonconvert',
  'view_changeonconvert' = 'view_changeonconvert',
  'add_purchaseorder' = 'add_purchaseorder',
  'change_purchaseorder' = 'change_purchaseorder',
  'delete_purchaseorder' = 'delete_purchaseorder',
  'view_purchaseorder' = 'view_purchaseorder',
  'add_poitem' = 'add_poitem',
  'change_poitem' = 'change_poitem',
  'delete_poitem' = 'delete_poitem',
  'view_poitem' = 'view_poitem',
  'add_paintshop' = 'add_paintshop',
  'change_paintshop' = 'change_paintshop',
  'delete_paintshop' = 'delete_paintshop',
  'view_paintshop' = 'view_paintshop',
  'add_optioncalc' = 'add_optioncalc',
  'change_optioncalc' = 'change_optioncalc',
  'delete_optioncalc' = 'delete_optioncalc',
  'view_optioncalc' = 'view_optioncalc',
  'add_compositions' = 'add_compositions',
  'change_compositions' = 'change_compositions',
  'delete_compositions' = 'delete_compositions',
  'view_compositions' = 'view_compositions',
  'add_parts' = 'add_parts',
  'change_parts' = 'change_parts',
  'delete_parts' = 'delete_parts',
  'view_parts' = 'view_parts',
  'add_articles' = 'add_articles',
  'change_articles' = 'change_articles',
  'delete_articles' = 'delete_articles',
  'view_articles' = 'view_articles',
  'add_cnccalc' = 'add_cnccalc',
  'change_cnccalc' = 'change_cnccalc',
  'delete_cnccalc' = 'delete_cnccalc',
  'view_cnccalc' = 'view_cnccalc',
  'add_tax' = 'add_tax',
  'change_tax' = 'change_tax',
  'delete_tax' = 'delete_tax',
  'view_tax' = 'view_tax',
  'add_invoice' = 'add_invoice',
  'change_invoice' = 'change_invoice',
  'delete_invoice' = 'delete_invoice',
  'view_invoice' = 'view_invoice',
  'add_item' = 'add_item',
  'change_item' = 'change_item',
  'delete_item' = 'delete_item',
  'view_item' = 'view_item',
  'add_extracharges' = 'add_extracharges',
  'change_extracharges' = 'change_extracharges',
  'delete_extracharges' = 'delete_extracharges',
  'view_extracharges' = 'view_extracharges',
  'add_propertysetting' = 'add_propertysetting',
  'change_propertysetting' = 'change_propertysetting',
  'delete_propertysetting' = 'delete_propertysetting',
  'view_propertysetting' = 'view_propertysetting',
  'add_syncmap' = 'add_syncmap',
  'change_syncmap' = 'change_syncmap',
  'delete_syncmap' = 'delete_syncmap',
  'view_syncmap' = 'view_syncmap',
  'add_paymenttype' = 'add_paymenttype',
  'change_paymenttype' = 'change_paymenttype',
  'delete_paymenttype' = 'delete_paymenttype',
  'view_paymenttype' = 'view_paymenttype',
  'add_payment' = 'add_payment',
  'change_payment' = 'change_payment',
  'delete_payment' = 'delete_payment',
  'view_payment' = 'view_payment',
  'add_taxagency' = 'add_taxagency',
  'change_taxagency' = 'change_taxagency',
  'delete_taxagency' = 'delete_taxagency',
  'view_taxagency' = 'view_taxagency',
  'add_proformainvoice' = 'add_proformainvoice',
  'change_proformainvoice' = 'change_proformainvoice',
  'delete_proformainvoice' = 'delete_proformainvoice',
  'view_proformainvoice' = 'view_proformainvoice',
  'add_invoicesignalmap' = 'add_invoicesignalmap',
  'change_invoicesignalmap' = 'change_invoicesignalmap',
  'delete_invoicesignalmap' = 'delete_invoicesignalmap',
  'view_invoicesignalmap' = 'view_invoicesignalmap',
  'add_invoiceline' = 'add_invoiceline',
  'change_invoiceline' = 'change_invoiceline',
  'delete_invoiceline' = 'delete_invoiceline',
  'view_invoiceline' = 'view_invoiceline',
  'add_bill' = 'add_bill',
  'change_bill' = 'change_bill',
  'delete_bill' = 'delete_bill',
  'view_bill' = 'view_bill',
  'add_image' = 'add_image',
  'change_image' = 'change_image',
  'delete_image' = 'delete_image',
  'view_image' = 'view_image',
  'add_template' = 'add_template',
  'change_template' = 'change_template',
  'delete_template' = 'delete_template',
  'view_template' = 'view_template',
  'add_webcontent' = 'add_webcontent',
  'change_webcontent' = 'change_webcontent',
  'delete_webcontent' = 'delete_webcontent',
  'view_webcontent' = 'view_webcontent',
  'add_text' = 'add_text',
  'change_text' = 'change_text',
  'delete_text' = 'delete_text',
  'view_text' = 'view_text',
  'add_page' = 'add_page',
  'change_page' = 'change_page',
  'delete_page' = 'delete_page',
  'view_page' = 'view_page',
  'add_modelposition' = 'add_modelposition',
  'change_modelposition' = 'change_modelposition',
  'delete_modelposition' = 'delete_modelposition',
  'view_modelposition' = 'view_modelposition',
  'add_threemodel' = 'add_threemodel',
  'change_threemodel' = 'change_threemodel',
  'delete_threemodel' = 'delete_threemodel',
  'view_threemodel' = 'view_threemodel',
  'add_morphs' = 'add_morphs',
  'change_morphs' = 'change_morphs',
  'delete_morphs' = 'delete_morphs',
  'view_morphs' = 'view_morphs',
  'add_status' = 'add_status',
  'change_status' = 'change_status',
  'delete_status' = 'delete_status',
  'view_status' = 'view_status',
  'add_task' = 'add_task',
  'change_task' = 'change_task',
  'delete_task' = 'delete_task',
  'view_task' = 'view_task',
  'add_comment' = 'add_comment',
  'change_comment' = 'change_comment',
  'delete_comment' = 'delete_comment',
  'view_comment' = 'view_comment',
  'add_taskuser' = 'add_taskuser',
  'change_taskuser' = 'change_taskuser',
  'delete_taskuser' = 'delete_taskuser',
  'view_taskuser' = 'view_taskuser',
  'add_notifications' = 'add_notifications',
  'change_notifications' = 'change_notifications',
  'delete_notifications' = 'delete_notifications',
  'view_notifications' = 'view_notifications',
  'add_subscription' = 'add_subscription',
  'change_subscription' = 'change_subscription',
  'delete_subscription' = 'delete_subscription',
  'view_subscription' = 'view_subscription',
  'add_emailattachmentreminder' = 'add_emailattachmentreminder',
  'change_emailattachmentreminder' = 'change_emailattachmentreminder',
  'delete_emailattachmentreminder' = 'delete_emailattachmentreminder',
  'view_emailattachmentreminder' = 'view_emailattachmentreminder',
  'add_component' = 'add_component',
  'change_component' = 'change_component',
  'delete_component' = 'delete_component',
  'view_component' = 'view_component',
  'add_componentimage' = 'add_componentimage',
  'change_componentimage' = 'change_componentimage',
  'delete_componentimage' = 'delete_componentimage',
  'view_componentimage' = 'view_componentimage',
  'add_componenttext' = 'add_componenttext',
  'change_componenttext' = 'change_componenttext',
  'delete_componenttext' = 'delete_componenttext',
  'view_componenttext' = 'view_componenttext',
  'add_webpage' = 'add_webpage',
  'change_webpage' = 'change_webpage',
  'delete_webpage' = 'delete_webpage',
  'view_webpage' = 'view_webpage',
  'add_compositiontext' = 'add_compositiontext',
  'change_compositiontext' = 'change_compositiontext',
  'delete_compositiontext' = 'delete_compositiontext',
  'view_compositiontext' = 'view_compositiontext',
  'add_compositionimage' = 'add_compositionimage',
  'change_compositionimage' = 'change_compositionimage',
  'delete_compositionimage' = 'delete_compositionimage',
  'view_compositionimage' = 'view_compositionimage',
  'add_webpageelements' = 'add_webpageelements',
  'change_webpageelements' = 'change_webpageelements',
  'delete_webpageelements' = 'delete_webpageelements',
  'view_webpageelements' = 'view_webpageelements',
  'add_templateelements' = 'add_templateelements',
  'change_templateelements' = 'change_templateelements',
  'delete_templateelements' = 'delete_templateelements',
  'view_templateelements' = 'view_templateelements',
  'add_componentcss' = 'add_componentcss',
  'change_componentcss' = 'change_componentcss',
  'delete_componentcss' = 'delete_componentcss',
  'view_componentcss' = 'view_componentcss',
  'add_compositioncss' = 'add_compositioncss',
  'change_compositioncss' = 'change_compositioncss',
  'delete_compositioncss' = 'delete_compositioncss',
  'view_compositioncss' = 'view_compositioncss',
  'add_defaultcss' = 'add_defaultcss',
  'change_defaultcss' = 'change_defaultcss',
  'delete_defaultcss' = 'delete_defaultcss',
  'view_defaultcss' = 'view_defaultcss',
  'add_buildercategory' = 'add_buildercategory',
  'change_buildercategory' = 'change_buildercategory',
  'delete_buildercategory' = 'delete_buildercategory',
  'view_buildercategory' = 'view_buildercategory',
  'add_builderproducts' = 'add_builderproducts',
  'change_builderproducts' = 'change_builderproducts',
  'delete_builderproducts' = 'delete_builderproducts',
  'view_builderproducts' = 'view_builderproducts',
  'add_contact' = 'add_contact',
  'change_contact' = 'change_contact',
  'delete_contact' = 'delete_contact',
  'view_contact' = 'view_contact',
  'add_installer' = 'add_installer',
  'change_installer' = 'change_installer',
  'delete_installer' = 'delete_installer',
  'view_installer' = 'view_installer',
  'add_installerbooking' = 'add_installerbooking',
  'change_installerbooking' = 'change_installerbooking',
  'delete_installerbooking' = 'delete_installerbooking',
  'view_installerbooking' = 'view_installerbooking',
  'add_material' = 'add_material',
  'change_material' = 'change_material',
  'delete_material' = 'delete_material',
  'view_material' = 'view_material',
  'add_part' = 'add_part',
  'change_part' = 'change_part',
  'delete_part' = 'delete_part',
  'view_part' = 'view_part',
  'add_attachmentdocument' = 'add_attachmentdocument',
  'change_attachmentdocument' = 'change_attachmentdocument',
  'delete_attachmentdocument' = 'delete_attachmentdocument',
  'view_attachmentdocument' = 'view_attachmentdocument',
  'add_suboption' = 'add_suboption',
  'change_suboption' = 'change_suboption',
  'delete_suboption' = 'delete_suboption',
  'view_suboption' = 'view_suboption',
  'add_rate' = 'add_rate',
  'change_rate' = 'change_rate',
  'delete_rate' = 'delete_rate',
  'view_rate' = 'view_rate',
  'add_cncproducts' = 'add_cncproducts',
  'change_cncproducts' = 'change_cncproducts',
  'delete_cncproducts' = 'delete_cncproducts',
  'view_cncproducts' = 'view_cncproducts',
  'add_nesting' = 'add_nesting',
  'change_nesting' = 'change_nesting',
  'delete_nesting' = 'delete_nesting',
  'view_nesting' = 'view_nesting',
  'add_orderparts' = 'add_orderparts',
  'change_orderparts' = 'change_orderparts',
  'delete_orderparts' = 'delete_orderparts',
  'view_orderparts' = 'view_orderparts',
  'add_cncoptions' = 'add_cncoptions',
  'change_cncoptions' = 'change_cncoptions',
  'delete_cncoptions' = 'delete_cncoptions',
  'view_cncoptions' = 'view_cncoptions',
  'add_qbosync' = 'add_qbosync',
  'change_qbosync' = 'change_qbosync',
  'delete_qbosync' = 'delete_qbosync',
  'view_qbosync' = 'view_qbosync',
  'add_note' = 'add_note',
  'change_note' = 'change_note',
  'delete_note' = 'delete_note',
  'view_note' = 'view_note',
  'add_taskname' = 'add_taskname',
  'change_taskname' = 'change_taskname',
  'delete_taskname' = 'delete_taskname',
  'view_taskname' = 'view_taskname',
  'add_tasktemplate' = 'add_tasktemplate',
  'change_tasktemplate' = 'change_tasktemplate',
  'delete_tasktemplate' = 'delete_tasktemplate',
  'view_tasktemplate' = 'view_tasktemplate',
  'add_inventory' = 'add_inventory',
  'change_inventory' = 'change_inventory',
  'delete_inventory' = 'delete_inventory',
  'view_inventory' = 'view_inventory',
  'add_sheetsettings' = 'add_sheetsettings',
  'change_sheetsettings' = 'change_sheetsettings',
  'delete_sheetsettings' = 'delete_sheetsettings',
  'view_sheetsettings' = 'view_sheetsettings',
  'add_cutlist' = 'add_cutlist',
  'change_cutlist' = 'change_cutlist',
  'delete_cutlist' = 'delete_cutlist',
  'view_cutlist' = 'view_cutlist',
}

export type PermissionLabels = keyof (typeof AppPermissions);
