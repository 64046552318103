export interface AccountApplicationModel {
  url?: string;
  businessTradeName: string;
  status: AccountApplicationStatus;
  ownerFirstName: string;
  ownerLastName: string;
  ownerEmail: string;
  businessLegalName: string;
  website: string;
  businessType: AccountApplicationBusinessType;
  businessPhone: string;
  businessAddress: string;
  businessCity: string;
  businessStateProvince: string;
  businessPostCode: string;
  businessEmail: string;
  businessNumber: string;
  irsGstNumber: string;
  yearsInBusiness: number;
  creditAmount: number;
  apName: string;
  apEmail: string;
  taxExempt: any;
  bankName: string;
  accountNumber: string;
  bankAddress: string;
  bankCity: string;
  bankStateProvince: string;
  bankPostCode: string;
  bankPhone: string;
  ref1BusinessName: string;
  ref1ContactEmail: string;
  ref1ContactPhone: string;
  ref2BusinessName: string;
  ref2ContactEmail: string;
  ref2ContactPhone: string;
  ref3BusinessName: string;
  ref3ContactEmail: string;
  ref3ContactPhone: string;
}

export enum AccountApplicationStatus {
  pending = 'pending',
  approved = 'approved',
  denied = 'denied',
}

export enum AccountApplicationBusinessType {
  Proprietorship = 'Proprietorship',
  Partnership = 'Partnership',
  Corporation = 'Corporation',
}
