import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import classnames from 'classnames';
import { Divider, Select as AntSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import Label from 'components/base-components/Label';
import Button from 'components/base-components/Button';
import InputError from 'components/base-components/InputError';
import RenderIf from 'components/base-components/RenderIf';
import useOptionHandler from './useOptionHandler';
import './styles.scss';

const { Option, OptGroup } = AntSelect;
export { Option, OptGroup };

export interface Props extends SelectProps<any>{
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  allowClear?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  loading?: boolean;
  showSearch?: boolean;
  showArrow?: boolean;
  suffixIcon?: ReactNode;
  tagRender?: (props) => ReactElement;
  value: DropdownOptionModel | DropdownOptionModel[];
  options?: DropdownOptionModel[];
  onChange: (event) => void;
  onFocus?: (event) => void;
  onBlur?: (event) => void;
  onClear?: () => void;
  disabledAdd?: boolean;
  onAddClick?: () => void;
  addButtonLabel?: string;
  onSearch?: (search: string) => void;
  className?: string;
  inputClassName?: string;
  useInfinityScroll?: boolean;
  onInfinityScroll?: () => void;
  required?: boolean;
  error?: string;
  mR?: boolean;
  mB?: boolean;
  mT?: boolean;
  checkNewValue?: boolean;
}

const addIcon = <i className="fa fa-plus" />;
const dividerStyles = { margin: '8px 0' };

const dropdownRenderer = (
  id: string,
  disableAdd: boolean,
  addButtonLabel: string,
  onAdd: () => void,
) => {
  return (menu) => (
    <div data-el={`${id}-dropdown`} className="select__add-option">
      <RenderIf isTrue={!!onAdd}>
        <Button
          type="link"
          size="middle"
          label={addButtonLabel || 'Add New'}
          icon={addIcon}
          disabled={disableAdd}
          onClick={onAdd}
        />
        <Divider style={dividerStyles} />
      </RenderIf>
      {menu}
    </div>
  );
};

const Select: FunctionComponent<Props> = (props) => {
  const {
    mR,
    mB,
    mT,
    id,
    mode,
    label,
    disabledAdd,
    onAddClick,
    addButtonLabel,
    className,
    inputClassName,
    options,
    loading,
    useInfinityScroll,
    onInfinityScroll,
    onChange,
    required,
    error,
    children,
    value,
    checkNewValue,
    ...rest
  } = props;
  const wrapperClassNames = classnames('select', { mR, mB, mT, error }, className);
  const selectClassName = classnames('select__input', inputClassName);
  const internalOptions = useOptionHandler(
    onInfinityScroll,
    useInfinityScroll,
    options,
    children,
    value,
    checkNewValue,
  );

  const dropdownRender = useCallback(
    dropdownRenderer(id, disabledAdd, addButtonLabel, onAddClick),
    [id, disabledAdd, addButtonLabel, onAddClick],
  );

  const handleOnChange = useCallback(
    (_value, option) => {
      if (mode) {
        onChange({ target: { value: _value } });
      } else {
        onChange({ target: { value: option } });
      }
    },
    [onChange, mode],
  );

  return (
    <div className={wrapperClassNames}>
      <Label text={label} forInput={id} required={required} />
      <AntSelect
        {...rest}
        id={id}
        mode={mode}
        options={internalOptions}
        loading={loading}
        className={selectClassName}
        dropdownRender={dropdownRender}
        onChange={handleOnChange}
        value={value}
      >
        {children}
      </AntSelect>
      <InputError error={error} />
    </div>
  );
};

Select.defaultProps = {
  showArrow: true,
  showSearch: false,
  labelInValue: true,
  defaultActiveFirstOption: false,
};

export default Select;
