import { CalendarActions, ViewMode } from '../reducer';

export default function handleDayLinkClick(dispatch, calendarRef) {
  return (date: Date) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(ViewMode.DAY, date);

    dispatch({
      type: CalendarActions.SWITCH_VIEW_AND_DATE,
      payload: {
        view: ViewMode.DAY,
        date,
      },
    });
  };
}
