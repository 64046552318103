import { useEffect, useMemo } from 'react';
import { ShippingZoneModel } from 'models/shipment';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import shippingZoneApi from 'api/shipping-zone';

const defaultShippingZone: ShippingZoneModel = {
  zoneName: '',
  zoneBaseFee: 0,
  zonePercentage: 0,
};

const getPayload = (shippingZone: ShippingZoneModel) => ({
  ...shippingZone,
  zonePercentage: (shippingZone.zonePercentage / 100)
});

const getFormData = (shippingZone: ShippingZoneModel) => {
  return shippingZone
    ? {
      ...shippingZone,
      zonePercentage: shippingZone.zonePercentage * 100
    }
    : defaultShippingZone;
};

export default function useShippingZoneFormState(shippingZoneId: number) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: shippingZoneApi,
    eventChannel: EventChannelList.SHIPPING_ZONE_SETTING_CHANGED,
    param: shippingZoneId,
    getPayload,
    getFormData,
  }), [shippingZoneId]);

  const {
    state: {
      formData: shippingZone,
      errors,
      saving,
      errorFetchingEntity: errorFetchingShippingZone,
      fetchingEntity: fetchingShippingZone,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<ShippingZoneModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingShippingZone) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingShippingZone,
      });
    }
  }, [errorFetchingShippingZone]);

  return {
    state: {
      shippingZone,
      savingShippingZone: saving,
      fetchingFormData: fetchingShippingZone,
      errors,
    },
    actions: {
      setFormValue,
      saveShippingZone: saveEntity,
      setErrors,
    },
  };
}
