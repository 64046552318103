import { Moment } from 'moment';
import { DropdownOptionModel } from 'helpers/dropdown-options';

export interface IncomeCustomerForm {
  startDate: Moment;
  endDate: Moment;
  repOwner: DropdownOptionModel;
}

export const initialState = {
  startDate: null,
  endDate: null,
  repOwner: null,
};
