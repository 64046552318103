/* eslint-disable */
import InputWithUnit from '../components/inputWithUnit';

export default class WallContextMenu {
  container = null;
  dimensionInput = null;
  btnDelete = null;

  visible = false;
  wall = null;

  constructor(parent, planner) {
    this.planner = planner;

    this.container = document.createElement('div');
    this.container.setAttribute('class', 'custom-context-menu');

    const inputContainer = document.createElement('div');
    inputContainer.setAttribute('class', 'item-container');

    this.dimensionInput = new InputWithUnit('Length');
    this.dimensionInput.addEventListener('change', (value) => {
      this.handleResize(value);
    });

    inputContainer.append(this.dimensionInput.dom);
    this.container.append(inputContainer);

    let tmp = document.createElement('div');
    tmp.setAttribute('class', 'item-container');
    this.checkLock = document.createElement('input');
    this.checkLock.setAttribute('type', 'checkbox');
    this.checkLock.style.width = 'initial';
    this.checkLock.addEventListener('click', e => {
      this.wall && this.wall.setLocked(this.checkLock.checked);
      this.dimensionInput.setDisabled(this.checkLock.checked ? true : false);
    })

    tmp.append(this.checkLock);
    tmp.insertAdjacentHTML('beforeend', '<label>Lock</label>');
    this.container.append(tmp);

    tmp = document.createElement('div');
    tmp.setAttribute('class', 'item-container');
    this.btnDelete = document.createElement('button');
    this.btnDelete.innerHTML = 'Delete';

    /**
     * Delete event
     */
    this.btnDelete.addEventListener('click', () => {
      this.wall && this.wall.remove();
      this.setVisible(false);
      planner.view.draw();
    });

    tmp.append(this.btnDelete);
    this.container.append(tmp);

    parent.append(this.container);
  }

  setVisible(visible) {
    this.visible = visible;
    this.container.style.display = visible ? 'inherit' : 'none';
  }

  isVisible = () => this.visible;

  setPosition(x, y) {
    this.container.style.left = `${x}px`;
    this.container.style.top = `${y}px`;
  }

  getWallCenter(wall) {
    const start = { x: wall.start.x, y: wall.start.y };
    const end = { x: wall.end.x, y: wall.end.y };
    return {
      x: (start.x + end.x) / 2,
      y: (start.y + end.y) / 2,
    };
  }

  getWallLength(wall) {
    const start = { x: wall.start.x, y: wall.start.y };
    const end = { x: wall.end.x, y: wall.end.y };
    const length = Math.sqrt((start.x - end.x) ** 2 + (start.y - end.y) ** 2);
    return length;
  }

  setWall = (wall) => {
    this.wall = wall;

    // lock checkbox
    this.checkLock.checked = wall.locked ? true : false;

    // length input
    this.dimensionInput.setValue(this.getWallLength(wall));
    this.dimensionInput.setDisabled(wall.locked ? true : false);
  }

  handleResize(value) {
    if (value > 0) {
      const rate = value / this.getWallLength(this.wall);
      let center = { x: 0, y: 0 };

      if (!this.wall.start.locked && !this.wall.end.locked) {
        center = this.getWallCenter(this.wall);
      } else if (this.wall.start.locked && !this.wall.end.locked) {
        center = {
          x: this.wall.getStartX(),
          y: this.wall.getStartY(),
        }
      } else if (!this.wall.start.locked && this.wall.end.locked) {
        center = {
          x: this.wall.getEndX(),
          y: this.wall.getEndY(),
        }
      } else {
        return;
      }
      /** Start */
      let newX = 0;
      let newY = 0;
      newX = center.x + (this.wall.start.x - center.x) * rate;
      newY = center.y + (this.wall.start.y - center.y) * rate;
      this.wall.start.move(newX, newY, false);

      /** End */
      newX = center.x + (this.wall.end.x - center.x) * rate;
      newY = center.y + (this.wall.end.y - center.y) * rate;
      this.wall.end.move(newX, newY, false);

      this.planner.view.draw();
    }
  }
}
