import React, { FunctionComponent } from 'react';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { CheckboxGroup } from 'components/base-components/Checkbox';
import Button from 'components/base-components/Button';

interface Props {
  options: DropdownOptionModel[];
  setFilters: (filters) => void;
  selectedKeys: string[];
  setSelectedKeys: (keys) => void;
  confirm: () => void;
  clearFilters: () => void;
}

const FilterDropdown: FunctionComponent<Props> = (props) => {
  const {
    options,
    setFilters,
    selectedKeys,
    setSelectedKeys,
    confirm,
    clearFilters,
  } = props;

  const filter = () => {
    setFilters(selectedKeys);
    confirm();
  };

  const reset = () => {
    setFilters([]);
    clearFilters();
  };

  return (
    <div className="sales-report__table__filter">
      <CheckboxGroup
        options={options}
        value={selectedKeys}
        onChange={setSelectedKeys}
        className="sales-report__table__filter__options"
      />
      <div className="sales-report__table__filter__actions">
        <Button
          type="default"
          label="Reset"
          onClick={reset}
          disabled={selectedKeys.length === 0}
        />
        <Button
          mL
          label="Filter"
          onClick={filter}
        />
      </div>
    </div>
  );
};

export default FilterDropdown;
