import { commonRules } from 'helpers/form-validations';

const { required } = commonRules;

export const rules = {
  name: [required],
  code: [required],
  manufacturer: [required],
  cost: [required],
};
