import React, { FunctionComponent, useMemo } from 'react';
import { BuilderOption, BuilderOptionGroup } from 'models/builder';
import { DropdownOptionModel, GenericMapOptions, mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Collapse, { Panel } from 'components/base-components/Collapse';
import RadioAvatar from './RadioAvatar';
import './styles.scss';

interface Props {
  options: { [option: string]: DropdownOptionModel };
  productOptions: BuilderOptionGroup[];
  onChange: (options) => void;
}

const optionMapDropdown: GenericMapOptions = {
  fields: { value: 'name', label: 'name' },
  extra: ['textureHeight', 'textureWidth', 'image', 'nameInModel', 'group'],
  copyFullItem: true,
};

export function mapOptions(
  options: BuilderOption[],
  nameInModel: string,
  mode: string,
) {
  const mappedOptions = options.map(option => ({
    ...option,
    group: {
      nameInModel,
      mode,
    },
  }));

  return mapGenericDropdownOptions(mappedOptions, optionMapDropdown);
}

const Options: FunctionComponent<Props> = (props) => {
  const { productOptions, options: activeOptions, onChange } = props;

  const productOptionsFields = useMemo(() => (
    productOptions.map(({ name, options, nameInModel, mode }) => {
      return (
        <Panel
          key={name}
          id={name}
          header={name}
          showArrow={false}
        >
          <Field
            name={name}
            component={RadioAvatar}
            options={mapOptions(options, nameInModel, mode)}
          />
        </Panel>
      );
    })
  ), [productOptions]);

  return (
    <Form state={activeOptions} onChange={onChange}>
      <Collapse className="builder__product-panel__collapse">
        {productOptionsFields}
      </Collapse>
    </Form>
  );
};

export default Options;
