import { AxiosPromise } from 'axios';
import { BuilderModel } from 'models/builder';
import { get } from './base';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/category/`;

interface BuilderApiModel {
  getDetails: (model: string) => AxiosPromise<BuilderModel>;
  getDetailsByModel: (model: string) => AxiosPromise<BuilderModel>;
}

const builderApi: BuilderApiModel = {
  getDetails: (url: string) => {
    return get(`${url}defaultproduct/`);
  },
  getDetailsByModel: (model: string) => {
    return get(`${endpoint}${model}/defaultproduct/`);
  },
};

export default builderApi;
