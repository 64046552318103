import React, { FunctionComponent, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { FlowPlannerModel } from 'models/flow-planner';
import Button from 'components/base-components/Button';
import PopConfirm from 'components/base-components/PopConfirm';
import Modal from 'components/base-components/ModalV2';
import addToCart from './on-add-to-cart';
import { ProductTable } from '../ProductList';
import './styles.scss';
import { BuilderFormModel } from '../state';

export interface AddToCartHistorySateModel {
  addToCartForm: {
    flowPlanner: FlowPlannerModel;
    products: BuilderFormModel[];
    productPrice: any;
  };
}

const AddToCartFrom: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { addToCartForm: { flowPlanner, productPrice, products } } = state as AddToCartHistorySateModel;
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    addToCart(productPrice, flowPlanner, products, setLoading, goBack),
    [flowPlanner, productPrice, products]
  );

  const footer = (
    <div className="add-to-cart__footer">
      <div className="add-to-cart__expand" />
      <Button onClick={goBack} label="Cancel" type="default" />
      <PopConfirm
        title="Are you sure?"
        onConfirm={handleSubmit}
        okText="Yes"
        cancelText="No"
      >
        <Button label="Add" loading={loading} />
      </PopConfirm>
    </div>
  );

  return (
    <Modal
      title="Add to Cart"
      visible
      onCancel={goBack}
      footer={footer}
    >
      <ProductTable products={productPrice} />
    </Modal>
  );
};

export default AddToCartFrom;
