import { TaskModel } from '../models/task';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_TASKS_API } = process.env;
const endpoint = `${REACT_APP_TASKS_API}/task/`;

const taskApi: ApiModel<TaskModel> = {
  ...getApi(endpoint, ApiContentType.JSON, true),
};

export default taskApi;
