import productApi from 'api/product';
import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import { CustomProductFormActions } from '../reducer';
import { buildRow } from './build-row';
import { pricePayload } from './get-payload-price';

export function saveRow(dispatch, data, quote, optionsGroupList, discount) {
  return async (row, dataIndex) => {
    try {
      let product = row.product?.name ? row.product : undefined;
      const price = {} as any;
      if (dataIndex === 'model' && row.model && row.successful === undefined) {
        try {
          const { data: dataProduct } = await productApi.getByModel(row.model);
          product = dataProduct;
        } catch (error) {
          product = false;
        }
      }
      if (dataIndex === 'options' && row.model && row.product?.name) {
        try {
          const { data: { data: priceData } } = await productApi.getPrice('', pricePayload(row));
          price.price = parseFloat(priceData[0].totalPrice).toFixed(2);
        } catch (error) {
          notification({ type: NotificationType.ERROR, message: resolveApiErrorMessage((error as any).response) });
        }
      }
      const newData = [...data];
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      let newRow = row;
      if (dataIndex === 'model') {
        newRow = await buildRow({ ...row, product }, optionsGroupList, quote, discount);
      }
      newData.splice(index, 1, {
        ...item,
        ...newRow,
        ...price,
      });
      dispatch({ type: CustomProductFormActions.SET_FORM_VALUE, payload: newData });
    } catch (error) {
      notification({ type: NotificationType.ERROR, message: resolveApiErrorMessage((error as any).response) });
    }
  };
}
