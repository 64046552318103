import { AxiosResponse } from 'axios';
import { OrderModel } from 'models/order';
import { QueryParams } from 'helpers/query-params';
import { ApiContentType, ApiModel, get, ListResponseModel, post } from './base';
import getApi from './base/get-api';
import { getEmailPayload } from './base/get-email-payload';
import downloadBlob from './base/download-blob';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/orders/`;

export interface CreateOrderPayload {
  quote: number;
  orderStatus: number;
}

export interface CreateOrderErrors {
  error?: string[];
  productOptions?: {
    missing: {
      [productId: string]: string[];
    };
  };
  shipping?: {
    missing: (
      'project_id' |
      'shipping_questionnaire' |
      'shipping_address' |
      'shipping_zone'
    )[];
  };
}

export interface CreateOrderResponse {
  success: boolean;
  error?: CreateOrderErrors;
}

interface OrderApiModel extends ApiModel<OrderModel> {
  sendEmail: (order: number, recipients: string[]) => Promise<AxiosResponse>;
  print: (order: number) => Promise<void>;
  printMaterial: (order: number) => Promise<void>;
  printDeficiencyReport: (order: number) => Promise<void>;
  listIncompletes: (params: QueryParams) => Promise<AxiosResponse<ListResponseModel<OrderModel>>>;
}

const orderApi: OrderApiModel = {
  ...getApi<OrderModel>(endpoint, ApiContentType.JSON, true),
  create(payload: CreateOrderPayload): Promise<AxiosResponse<CreateOrderResponse>> {
    return post(endpoint, payload);
  },
  sendEmail(order: number, recipients: string[]) {
    const payload = getEmailPayload(recipients);
    return post(`${endpoint}${order}/email/`, payload);
  },
  print(order: number) {
    return downloadBlob(
      `${endpoint}${order}/view/`,
      `Order #${order}.pdf`
    );
  },
  printMaterial(order: number) {
    return downloadBlob(
      `${endpoint}${order}/materials/`,
      `Materials #${order}.pdf`
    );
  },
  printDeficiencyReport(order: number) {
    return downloadBlob(
      `${endpoint}${order}/deficiency-report/`,
      `Deficiency Report #${order}.pdf`
    );
  },
  listIncompletes(params) {
    return get(`${endpoint}incomplete/`, params);
  },
};

export default orderApi;
