import shipmentApi from 'api/shipment';
import { QueryParams } from 'helpers/query-params';

export default async function getShipmentByProject(projectId: number) {
  const params: QueryParams = { filters: { project: projectId } };
  let response = await shipmentApi.list(params);
  if (response.data.results.length === 0) {
    const newShipment = {
      shippingZone: null,
      shippingAddress: null,
      shipmentType: 1,
      shippingQuestionnaire: null,
      project: projectId,
    };

    await shipmentApi.create(newShipment);
    response = await shipmentApi.list(params);
  }

  const shipment = response.data.results[0];

  return { data: shipment };
}
