import { InvoiceModel } from 'models/invoice';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { EmailSender } from 'components/forms/SendEmail/state';

export default function openSendInvoiceEmailModal(push) {
  return (invoice: InvoiceModel) => {
    const { id } = invoice;

    push(
      ModalRouteHash.SendEmailForm,
      {
        sendEmailForm: {
          id,
          from: EmailSender.Invoice,
          title: 'Send Invoice by email',
        },
      },
    );
  };
}
