import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import classnames from 'classnames';
import Spinner from 'components/base-components/Spinner';
import CheckboxGroup from './CheckboxGroup';
import './styles.scss';

export { CheckboxGroup };

interface Props {
  id?: string;
  name?: string;
  label?: ReactNode;
  value: boolean;
  onChange: (event) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  mR?: boolean;
  className?: string;
  loading?: boolean;
}

const Checkbox: FunctionComponent<Props> = (props) => {
  const { label, mR, className, value, loading, ...rest } = props;
  const checkboxClassName = useMemo(
    () => classnames('checkbox_input', { mR }, className),
    [className, mR],
  );

  if (loading) {
    return <Spinner size="small" centered={false} />;
  }

  return (
    <AntCheckbox
      className={checkboxClassName}
      checked={value}
      {...rest}
    >
      {label}
    </AntCheckbox>
  );
};

export default Checkbox;
