import React, { FunctionComponent } from 'react';
import hasPermissions from 'helpers/permissons';
import ButtonGroup from 'components/base-components/ButtonGroup';
import { PermissionLabels } from 'constants/permissions';
import DataTableActions from './Actions';

interface Props {
  data: any;
  viewPermissions: PermissionLabels[];
  onShowDetails: (data) => void;
  editPermissions: PermissionLabels[];
  onEdit: (data) => void;
  deletePermissions: PermissionLabels[];
  onDelete: (data) => void;
}

const DataTableActionColumnRender: FunctionComponent<Props> = (props) => {
  const {
    data,
    editPermissions,
    onEdit,
    deletePermissions,
    onDelete,
    viewPermissions,
    onShowDetails,
    children,
  } = props;

  const handleOnShowDetails = () => {
    onShowDetails(data);
  };

  const handleOnEdit = () => {
    onEdit(data);
  };

  const handleOnDelete = () => {
    onDelete(data);
  };

  let actions = [];

  if (onShowDetails) {
    const shouldShowViewAction = (
      !viewPermissions ||
      hasPermissions(viewPermissions)
    );

    if (shouldShowViewAction) {
      actions.push({
        key: `table-action-view-${data.url || data.id}`,
        icon: 'fas fa-eye',
        onClick: handleOnShowDetails,
        type: 'default'
      });
    }
  }

  if (children) {
    const customActions = React.Children.map(children, (child: any, index) => {
      const {
        onClick,
        type,
        label,
        icon,
        permissions,
        danger,
        loading,
        disabled,
        hide,
        badge,
      } = child.props;

      return {
        key: `table-custom-action-${data.url || data.id}-${index}`,
        icon,
        label,
        permissions,
        type,
        danger,
        badge: data[badge],
        loading: loading && loading(data),
        disabled: disabled && disabled(data),
        onClick: () => onClick(data),
        hide,
      };
    }).filter((action) => (
      (!action.permissions ||
      hasPermissions(action.permissions)) &&
      (!action.hide || !action.hide(data))
    ));
    actions = actions.concat(customActions);
  }

  if (onEdit) {
    const shouldShowEditAction = (
      !editPermissions ||
      hasPermissions(editPermissions)
    );

    if (shouldShowEditAction) {
      actions.push({
        key: `table-action-edit-${data.url || data.id}`,
        icon: 'fas fa-pen',
        onClick: handleOnEdit,
        label: 'Edit'
      });
    }
  }

  if (onDelete) {
    const shouldShowDeleteAction = (
      !deletePermissions ||
      hasPermissions(deletePermissions)
    );

    if (shouldShowDeleteAction) {
      actions.push({
        key: `table-action-delete-${data.url || data.id}`,
        icon: 'fas fa-trash',
        danger: true,
        onClick: handleOnDelete,
        label: 'Delete'
      });
    }
  }

  return (
    <ButtonGroup>
      <DataTableActions actions={actions} />
    </ButtonGroup>
  );
};

export default DataTableActionColumnRender;
