import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import shippingZoneApi from 'api/shipping-zone';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { ShippingZoneModel } from 'models/shipment';
import { EventChannelList } from 'helpers/event-center';
import DataTable from 'components/base-components/DataTableV2';
import Help from 'components/forms/CommonHelp';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { ShippingZoneFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: shippingZoneApi,
  deleteModalTitle: 'Delete Shipping Zone',
  deleteModalContent: (shippingZoneToDelete: ShippingZoneModel) => `Are you sure that you want to delete the Shipping Zone ${shippingZoneToDelete ? shippingZoneToDelete.zoneName : ''}?`,
  searchFields: ['zone_name'],
  eventChannel: EventChannelList.SHIPPING_ZONE_SETTING_CHANGED,
  defaultSortField: '-id'
};
const zonePercentageRender = (value) => `${value * 100}%`;

const ShippingZoneList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<ShippingZoneModel>(useTableStateParams);

  const openShippingZoneFormModal = (shippingZone?: ShippingZoneModel | any) => {
    const historyLocationState: ShippingZoneFormHistorySateModel = {
      shippingZoneForm: {
        shippingZoneId: shippingZone ? shippingZone.id : undefined,
      },
    };
    push(ModalRouteHash.ShippingZoneSetting, historyLocationState);
  };

  return (
    <Page title="Shipping Zone">
      <Help view="shipping-zones" title="Shipping Zone List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addShippingZone}>
          <Button
            onClick={openShippingZoneFormModal}
            label="Add Shipping Zone"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="zoneName" columnKey="zoneName" title="Name" sorter />
        <DataTableColumn dataIndex="zoneBaseFee" columnKey="zoneBaseFee" title="Base Fee" sorter isCurrency />
        <DataTableColumn
          dataIndex="zonePercentage"
          columnKey="zonePercentage"
          title="Percentage"
          render={zonePercentageRender}
          sorter
          isCurrency
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editShippingZone}
          onEdit={openShippingZoneFormModal}
          deletePermissions={permissions.deleteShippingZone}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default ShippingZoneList;
