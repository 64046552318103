import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { Owner } from '../../../ConfirmReadyDate';
import { CalendarEvent, EventItemType } from '../reducer';

export default function handleEventClick(push) {
  return ({ event }) => {
    const { extendedProps: { type, item } } = event as CalendarEvent;

    // eslint-disable-next-line default-case
    switch (type) {
      case EventItemType.BOOKING: {
        const locationState = {
          installerBookingForm: {
            installerBookingEntity: item,
            hideProject: true,
          }
        };
        push(ModalRouteHash.InstallerBookingForm, locationState);
      } break;
      case EventItemType.ORDER: {
        const locationState = {
          confirmReadyDate: {
            orderId: item.id,
            owner: Owner.Calendar
          }
        };
        push(ModalRouteHash.ConfirmReadyDate, locationState);
      } break;
      case EventItemType.CUSTOM: {
        const locationState = {
          customEventForm: {
            customEventId: item.id,
          }
        };
        push(ModalRouteHash.CustomEventFrom, locationState);
      } break;
    }
  };
}
