import { AxiosResponse } from 'axios';
import { AccountUserModel, UserModel } from 'models/user';
import { ApiContentType, ApiModel, get, ListResponseModel } from 'api/base';
import getApi from 'api/base/get-api';
import { QueryParams } from 'helpers/query-params';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/users/`;

interface UserApiModel extends ApiModel<UserModel> {
  getById: (id: string) => Promise<AxiosResponse<UserModel>>;
  getAccountUsers?: (params?: QueryParams) => Promise<AxiosResponse<ListResponseModel<AccountUserModel>>>;
}

const userApi: UserApiModel = {
  ...getApi<UserModel>(endpoint, ApiContentType.MULTIPART),
  getById(id: string): Promise<AxiosResponse<UserModel>> {
    return get(`${endpoint}${id}`);
  },
  getAccountUsers(params?) {
    return get(`${endpoint}account_users`, params);
  },
};

export default userApi;
