import moment from 'moment';

const dateFormatter = new Intl.DateTimeFormat('default', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
});

export function formatDisplayDate(date: Date | string): string {
  const dateTimeZone = date?.toString()?.includes('T') ? date : `${date}T00:00:00.000`;
  return date ? dateFormatter.format(new Date(dateTimeZone)) : '';
}

const dateTimeFormatter = new Intl.DateTimeFormat('default', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
});

export function formatDisplayDateTime(date: Date | string): string {
  return date ? dateTimeFormatter.format(new Date(date)) : '';
}

export function formatDateToString(date, format?: string) {
  return date ? moment(date).format(format || 'YYYY-MM-DD') : '';
}

export function formatDateTimeToString(date, format?: string) {
  return date ? moment(date).utcOffset('z').format(format) : null;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function formatCurrency(currency) {
  return currency !== undefined && currency !== null && currency !== ''
    ? currencyFormatter.format(currency)
    : '-';
}

export function capitalizeFirstLetter(str: string): string {
  return str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}
