import React from 'react';
import Tag from 'components/base-components/Tag';

export default function statusTemplate(isDeficient, row) {
  if (typeof row.id === 'string') {
    return {
      props: {
        colSpan: 0
      },
    };
  }
  return isDeficient
    ? <Tag label="Deficient" color="warning" />
    : null;
}
