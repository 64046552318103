import { TableFilters } from '../types';

function cleanFilters(filters: TableFilters) {
  const activeFilters = Object
    .keys(filters)
    .filter(filter => filters[filter].length > 0);

  return activeFilters.reduce((obj, field) => {
    return { ...obj, [field]: filters[field] };
  }, {} as any);
}

export default function handleTableFilters(field: string, setFilters) {
  return (values: string[]) => {
    setFilters(old => cleanFilters({ ...old, [field]: values }));
  };
}
