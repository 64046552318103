export enum CreateQuoteFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_CREATING = 'START_CREATING',
  FINISH_CREATING = 'FINISH_CREATING',
}

interface CreateQuoteFormState {
  formData: { comments: string};
  creating: boolean;
  errors: any;
}

export default function createQuoteFormReducer(
  state: CreateQuoteFormState, action
): CreateQuoteFormState {
  const { type, payload } = action;

  switch (type as CreateQuoteFormActions) {
    case CreateQuoteFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case CreateQuoteFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, creating: false };
    case CreateQuoteFormActions.START_CREATING:
      return { ...state, creating: true };
    case CreateQuoteFormActions.FINISH_CREATING:
      return { ...state, creating: false };
    default:
      return state;
  }
}
