import React, { FunctionComponent } from 'react';
import ErrorBoundary from 'components/base-components/ErrorBoundry';
import Header from '../WorkOrder/Header';
import OrderInfo from '../WorkOrder/OrderInfo';
import ProductsList from '../WorkOrder/ProductsList';
import Instructions from '../WorkOrder/Instructions';
import { errorScreen, fetchingScreen, noDataScreen } from '../WorkOrder/screens';
import usePrintoutState from '../WorkOrder/state';
import Footer from './Footer';
import './styles.scss';

const storageKey = 'packing-slip-data';

const PackingSlip: FunctionComponent = () => {
  const {
    noData,
    fetching,
    error,
    quote,
    contact,
    address,
  } = usePrintoutState(storageKey);

  if (noData) {
    return noDataScreen;
  }

  if (error) {
    return errorScreen;
  }

  if (fetching) {
    return fetchingScreen;
  }

  const { project: { jobTitle }, orders: [order] } = quote;
  const { id: workOrder } = order;

  return (
    <ErrorBoundary errorMessage={errorScreen}>
      <section className="packing-slip">
        <Header title="Packing Slip" workOrder={workOrder} jobTitle={jobTitle} />
        <OrderInfo
          order={order}
          products={quote.orderproducts}
          contact={contact}
          address={address}
        />
        <ProductsList quote={quote} />
        <Instructions instructions={quote.specialInstructions} />
        <Footer />
      </section>
    </ErrorBoundary>
  );
};

export default PackingSlip;
