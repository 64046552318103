import React from 'react';

export default function modelTemplate(row, columns: number, isQuote) {
  if (typeof row.id === 'string') {
    const isSubtotal = row.id.includes('subtotal');

    let colSpan = 0;
    if (row.group || row.subtotal) {
      const IfAnyColumnHidden = window?.innerWidth < 1444 ? 1 : 0;
      if (isQuote) {
        colSpan = (isSubtotal ? columns : columns + 1) - IfAnyColumnHidden;
      } else {
        colSpan = (isSubtotal ? columns - 1 : columns) - IfAnyColumnHidden;
      }
    }

    return {
      children: isSubtotal
        ? 'Group-Total'
        : <span data-el="group-name">{row.group}</span>,
      props: {
        colSpan,
        align: isSubtotal ? 'right' : undefined,
        className: isSubtotal ? 'quote-product-list__subtotal quote-product-list__totals' : null,
      },
    };
  }

  const options = row.options?.map((option) => (
    <li key={`${option.optionType}-${row.id}`}>
      {`${option.optionType}: ${option.code ? `${option.code}-` : ''}${option.optionSelected}`}
    </li>
  ));

  return (
    <>
      <span data-el="product-model" className="text-muted">{row.model}</span>
      <ul data-el="product-model-options">
        {options}
      </ul>
    </>
  );
}
