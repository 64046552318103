import React, { FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import RenderIf from 'components/base-components/RenderIf';
import { usePickListContext } from './context';

interface Props {
  value: string;
}

const PickItem: FunctionComponent<Props> = (props) => {
  const { value, children } = props;
  const {
    value: selectedValue,
    onChange,
    size,
    multiple,
  } = usePickListContext();

  const handleOnClick = useCallback(() => onChange(value), [onChange, value]);

  const isSelected = (
    multiple
      ? (selectedValue as string[]).some(v => v === value)
      : selectedValue === value
  );

  const itemClassName = classnames('tfg-pick-list__item', `size-${size}`);
  const touchableClassName = classnames(
    'tfg-pick-list__item__touchable',
    { is_selected: isSelected },
  );
  const markClassName = classnames(
    'tfg-pick-list__item__mark',
    { is_selected: isSelected },
  );

  return (
    <li tabIndex={-1} className={itemClassName}>
      <button
        className={touchableClassName}
        onClick={handleOnClick}
        data-pick-item-value={value}
        data-pick-item-selected={isSelected}
      >
        {children}
        <div className={markClassName} data-el="pick_item-mark">
          <RenderIf isTrue={isSelected}>
            <i className="fa fa-check" />
          </RenderIf>
        </div>
      </button>
    </li>
  );
};

export default PickItem;
