import React, { FunctionComponent } from 'react';
import storeApi from 'api/store';
import userApi from 'api/user';
import accountGroupApi from 'api/account-group';
import taxApi from 'api/tax';
import paymentTermsApi from 'api/term';
import Field from 'components/base-components/Form/Field';
import RenderIf from 'components/base-components/RenderIf';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import Upload from 'components/base-components/Upload';
import {
  mapAccountGroupsOptions,
  mapPaymentTermsOptions,
  mapStoresOptions,
  mapTaxesOptions,
  mapUsersOptions,
} from './state';

interface Props {
  showTradeName: boolean;
}

const salesRepresentativeSelectFilter = { isStaff: true };

const FullForm: FunctionComponent<Props> = (props) => {
  const { showTradeName } = props;

  return (
    <>
      <RenderIf isTrue={showTradeName}>
        <Field
          id="businessTradeName"
          name="businessTradeName"
          label="Business trade name"
          placeholder="Enter business trade name"
          required
        />
      </RenderIf>
      <Field
        id="businessLegalName"
        name="businessLegalName"
        label="Business legal name"
        placeholder="Enter business name"
        required
      />
      <Field
        id="website"
        name="website"
        label="Website"
        placeholder="Enter Website"
      />
      <Field
        id="businessEmail"
        name="businessEmail"
        label="Business Email"
        placeholder="Enter business email"
        required
      />
      {/* TODO: make a mask field for phone numbers */}
      <Field
        id="businessPhone"
        name="businessPhone"
        label="Business Phone"
        placeholder="Enter business phone"
      />
      <Field
        id="businessAddress"
        name="businessAddress"
        label="Business Address"
        placeholder="Enter business address"
      />
      <Field
        id="businessCity"
        name="businessCity"
        label="Business City"
        placeholder="Enter business city"
      />
      <Field
        id="businessStateProvince"
        name="businessStateProvince"
        label="Business State Province"
        placeholder="Enter business state province"
      />
      <Field
        id="businessPostCode"
        name="businessPostCode"
        label="Business Post Code"
        placeholder="Enter business post code"
      />
      <Field
        id="accountsPayableName"
        name="accountsPayableName"
        label="Accounts Payable Name"
        placeholder="Enter business payable name"
      />
      <Field
        id="accountsPayableEmail"
        name="accountsPayableEmail"
        label="Accounts Payable Email"
        placeholder="Enter business payable email"
      />
      <Field
        component={AsyncSelectV2}
        name="store"
        id="store"
        label="Store"
        listAction={storeApi.list}
        mapFunction={mapStoresOptions}
      />
      <Field
        component={AsyncSelectV2}
        id="owner"
        name="owner"
        label="Owner"
        listAction={userApi.list}
        mapFunction={mapUsersOptions}
        required
      />
      <Field
        component={AsyncSelectV2}
        name="salesRep"
        id="salesRep"
        label="Sales Representative"
        listAction={userApi.list}
        mapFunction={mapUsersOptions}
        filters={salesRepresentativeSelectFilter}
      />
      <Field
        component={AsyncSelectV2}
        name="group"
        id="group"
        label="Group"
        listAction={accountGroupApi.list}
        mapFunction={mapAccountGroupsOptions}
      />
      <Field
        component={AsyncSelectV2}
        name="users"
        id="users"
        label="Users"
        listAction={userApi.list}
        mapFunction={mapUsersOptions}
        mode="multiple"
      />
      <Field
        component={AsyncSelectV2}
        name="tax"
        id="tax"
        label="Taxes"
        listAction={taxApi.list}
        mode="multiple"
        mapFunction={mapTaxesOptions}
      />
      <Field
        component={AsyncSelectV2}
        name="paymentTerms"
        id="paymentTerms"
        label="Payment Term"
        listAction={paymentTermsApi.list}
        mapFunction={mapPaymentTermsOptions}
        required
      />
      <Field
        component={Upload}
        name="logo"
        id="logo"
        label="Choose Logo"
        accept="image/png, .jpeg, .jpg"
      />
    </>
  );
};

export default FullForm;
