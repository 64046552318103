import React, { FunctionComponent } from 'react';
import { Tag as AntTag } from 'antd';
import { TagProps } from 'antd/lib/tag';

interface Props extends TagProps {
  label?: string;
}

const Tag: FunctionComponent<Props> = (props) => {
  const { children, label, ...rest } = props;

  return (
    <AntTag {...rest}>
      {children || label}
    </AntTag>
  );
};

export default Tag;
