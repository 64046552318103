import React, { FunctionComponent, useMemo } from 'react';
import { BuilderOptionGroup } from 'models/builder';
import subOptionApi from 'api/sub-option';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import RenderIf from 'components/base-components/RenderIf';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import { mapOptions } from './util';

interface Props {
  options: { [option: string]: DropdownOptionModel };
  productOptions: BuilderOptionGroup[];
  onChange: (options) => void;
  onCustomOption?: (name: string) => void;
}

export const Options: FunctionComponent<Props> = (props) => {
  const { productOptions, options: activeOptions, onChange, onCustomOption } = props;

  const productOptionsFields = useMemo(() => (
    productOptions.map(({ name, options, nameInModel, mode }) => {
      const addButtonLabel = !!onCustomOption ? 'Add custom option' : undefined;
      const onAddClick = !!onCustomOption ? () => onCustomOption(name) : undefined;
      const hasSubOptions = activeOptions[name]?.dropdownItem?.suboptions?.length > 0;
      const mapSubOptions = (subOptions) => (
        mapOptions(subOptions || [], nameInModel, mode)
      );
      const filters = {
        filters: {
          option__name: activeOptions[name]?.value,
        }
      };
      return (
        <>
          <Field
            component={Select}
            key={name}
            id={name}
            name={name}
            label={name}
            options={mapOptions(options, nameInModel, mode)}
            addButtonLabel={addButtonLabel}
            onAddClick={onAddClick}
            checkNewValue
          />
          <RenderIf isTrue={hasSubOptions}>
            <Field
              name={`sub-${name}`}
              id={`sub-${name}`}
              label={`${activeOptions[name]?.label} Options`}
              component={AsyncSelectV2}
              listAction={subOptionApi.list}
              mapFunction={mapSubOptions}
              preventAutoFetch={!hasSubOptions}
              filters={filters}
            />
          </RenderIf>
        </>
      );
    })
  ), [productOptions, activeOptions, onCustomOption]);

  return (
    <Form state={activeOptions} onChange={onChange}>
      {productOptionsFields}
    </Form>
  );
};
