import { ProjectModel } from 'models/project';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_ORDERS_API } = process.env;

const endpoint = `${REACT_APP_ORDERS_API}/projects/`;

const projectsApi: ApiModel<ProjectModel> = {
  ...getApi<ProjectModel>(endpoint, ApiContentType.JSON, true),
};

export default projectsApi;
