export enum CreateProjectFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_CREATING = 'START_CREATING',
  FINISH_CREATING = 'FINISH_CREATING',
}

interface CreateProjectFormState {
  formData: { jobTitle: string };
  creating: boolean;
  errors: any;
}

export default function createProjectFormReducer(
  state: CreateProjectFormState, action
): CreateProjectFormState {
  const { type, payload } = action;

  switch (type as CreateProjectFormActions) {
    case CreateProjectFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case CreateProjectFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, creating: false };
    case CreateProjectFormActions.START_CREATING:
      return { ...state, creating: true };
    case CreateProjectFormActions.FINISH_CREATING:
      return { ...state, creating: false };
    default:
      return state;
  }
}
