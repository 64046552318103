import React, { FunctionComponent } from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';

const Breadcrumb: FunctionComponent<BreadcrumbProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntBreadcrumb {...rest}>
      {children}
    </AntBreadcrumb>
  );
};

export default Breadcrumb;
