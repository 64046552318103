import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ProductModel } from 'models/product';
import productApi from 'api/product';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import Help from 'components/forms/CommonHelp';
import Button from 'components/base-components/Button';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import { AddProduct3DHistorySateModel } from 'components/forms/AddProduct3D';
import AccessControl from 'components/base-components/AccessControl';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: productApi,
  deleteModalTitle: 'Delete Product',
  deleteModalContent: (productToDelete: ProductModel) => `Are you sure that you want to delete the product ${productToDelete ? productToDelete.name : ''}?`,
  searchFields: ['name'],
  eventChannel: EventChannelList.PRODUCTS_LIST_CHANGED,
};

export interface ProductHistorySateModel {
  product: {
    productUrl: string;
  };
}

const ProductsList: FunctionComponent = () => {
  const { push } = useHistory();

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
    }
  } = useTableState<ProductModel>(useTableStateParams);

  const openProductFormModal = useCallback((product?: ProductModel | any) => {
    const historyLocationState: ProductHistorySateModel = {
      product: {
        productUrl: product ? product.url : undefined,
      },
    };
    push(ModalRouteHash.ProductForm, historyLocationState);
  }, [push]);

  const openProductDetailsModal = useCallback((product: ProductModel) => {
    const historyLocationState: ProductHistorySateModel = {
      product: {
        productUrl: product ? product.url : undefined,
      },
    };
    push(ModalRouteHash.ProductDetails, historyLocationState);
  }, [push]);

  const open3DProductModal = useCallback(() => {
    const historyLocationState: AddProduct3DHistorySateModel = {
      readonly: true,
    };
    push(ModalRouteHash.AddProduct3D, historyLocationState);
  }, [push]);

  return (
    <Page title="Products">
      <Help view="product" title="Products List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addProduct}>
          <Button
            onClick={openProductFormModal}
            label="Add Product"
          />
        </AccessControl>
        <Button
          onClick={open3DProductModal}
          label="3D Product"
        />
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn
          dataIndex="name"
          columnKey="name"
          title="Product Name"
          sorter
        />
        <DataTableColumn
          dataIndex="model"
          columnKey="model"
          title="Model"
          sorter
          showBreakpoint={1220}
        />
        <DataTableColumn
          dataIndex="price"
          columnKey="price"
          title="Price"
          sorter
          isCurrency
          showBreakpoint={1324}
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editProduct}
          deletePermissions={permissions.deleteProduct}
          viewPermissions={permissions.viewProduct}
          onShowDetails={openProductDetailsModal}
          onEdit={openProductFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default ProductsList;
