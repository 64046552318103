import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum AsyncSelectActions {
  SET_PAGE = 'SET_PAGE',
  NEXT_PAGE = 'NEXT_PAGE',
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  SET_FILTERS = 'SET_FILTERS',
  SET_PREVENT = 'SET_PREVENT',
  SET_DATA = 'SET_DATA',
  PUSH_DATA = 'PUSH_DATA',
  SET_OPTIONS = 'SET_OPTIONS',
  ADD_DATA = 'ADD_DATA',
}

export interface AsyncSelectState {
  page: number;
  searchValue: string;
  searchTerm: string;
  filters: any;
  preventAutoFetch: boolean;
  data: DropdownOptionModel[];
  options: any[];
}

export default function asyncSelectReducer(state: AsyncSelectState, action): AsyncSelectState {
  const { type, payload } = action;

  switch (type as AsyncSelectActions) {
    case AsyncSelectActions.SET_PAGE:
      return { ...state, page: payload };
    case AsyncSelectActions.NEXT_PAGE:
      return { ...state, page: state.page + 1 };
    case AsyncSelectActions.SET_SEARCH_VALUE:
      return { ...state, searchValue: payload };
    case AsyncSelectActions.SET_SEARCH_TERM:
      return { ...state, searchTerm: payload, page: 1 };
    case AsyncSelectActions.SET_FILTERS:
      return { ...state, filters: payload, page: 1 };
    case AsyncSelectActions.SET_PREVENT:
      return { ...state, preventAutoFetch: payload, page: 1 };
    case AsyncSelectActions.SET_DATA:
      return { ...state, data: payload };
    case AsyncSelectActions.SET_OPTIONS:
      return { ...state, options: payload };
    case AsyncSelectActions.PUSH_DATA:
      return { ...state, data: state.data.concat(payload) };
    case AsyncSelectActions.ADD_DATA:
      return { ...state, data: [payload].concat(state.data) };
    default:
      return state;
  }
}
