import { TutorialFormModel } from '../reducer';

export default async function getPayload(tutorial: TutorialFormModel) {
  const {
    videoName,
    video,
    videoChanged,
    ...rest
  } = tutorial;

  if (tutorial.id) {
    const videoFile = videoChanged ? video : undefined;

    return {
      id: tutorial.id,
      videoName,
      video: videoFile,
    };
  }

  return {
    ...rest,
    video,
    videoName,
  };
}
