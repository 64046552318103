import React, { FunctionComponent } from 'react';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import Field from 'components/base-components/Form/Field';
import InputRow from 'components/base-components/InputRow';
import Select from 'components/base-components/Select';
import Form from 'components/base-components/Form';
import NumberInput from 'components/base-components/NumberInput';
import Button from 'components/base-components/Button';
import { FormAddressModel } from './state/reducer';
import { addressRules } from './state/validations';

interface Props {
  addressInfo: FormAddressModel;
  setAddressInfo: (address) => void;
  addressErrors: { [field: string]: string };
  setAddressErrors: (errors) => void;
  countries: DropdownOptionModel[];
  provinces: DropdownOptionModel[];
  selectedCountry: DropdownOptionModel;
  handleCountryChange: (country) => void;
  fetchingAccount: boolean;
  disableAccount: boolean;
  copyAccountAddress: () => void;
}

const AddressInfo: FunctionComponent<Props> = (props) => {
  const {
    addressInfo,
    selectedCountry,
    countries,
    provinces,
    addressErrors,
    setAddressInfo,
    handleCountryChange,
    setAddressErrors,
    fetchingAccount,
    disableAccount,
    copyAccountAddress,
  } = props;

  return (
    <Form
      state={addressInfo}
      onChange={setAddressInfo}
      errors={addressErrors}
      onError={setAddressErrors}
      rules={addressRules}
    >
      <div className="questionnaire__info-group__header">
        <h1 className="questionnaire__header">
          Delivery Address
        </h1>
        <Button
          type="default"
          label="Copy from Account"
          loading={fetchingAccount}
          disabled={disableAccount}
          onClick={copyAccountAddress}
          mB
        />
      </div>
      <Field name="locationName" label="Company" />
      <InputRow>
        <Field
          component={NumberInput}
          name="streetNumber"
          label="Street Number"
          className="questionnaire__address__street-number"
        />
        <Field name="streetName" label="Street Name" />
      </InputRow>
      <Field name="unitNumber" label="Unit" />
      <InputRow>
        <Field name="city" label="City" mR />
        <Field name="postalCode" label="Post Code" />
      </InputRow>
      <Field
        name="province"
        label="Province"
        component={Select}
        options={provinces}
      />
      <Select
        label="Country"
        options={countries}
        value={selectedCountry}
        onChange={handleCountryChange}
      />
    </Form>
  );
};

export default AddressInfo;
