import { useCallback, useState } from 'react';
import { ManufacturerModel } from 'models/manufacturer';
import { EventChannelList, notifyEventChannel, useEventCenterUpdate } from 'helpers/event-center';
import { validateEntity } from 'helpers/form-validations';
import {
  DropdownOptionModel,
  mapGenericDropdownOptions,
} from 'helpers/dropdown-options';
import { rules } from './rules';

interface CustomOptionModel {
  name: string;
  code: string;
  manufacturer: DropdownOptionModel;
  cost: number;
}

function mapManufacturers(manufacturers: ManufacturerModel[]) {
  return mapGenericDropdownOptions(
    manufacturers,
    {
      fields: {
        value: 'companyName',
        label: 'companyName',
      },
    },
  );
}

function openManufacturerModal() {
  notifyEventChannel(EventChannelList.QUOTE_PAGE_OPEN_MANUFACTURER_MODAL);
}

export default function useCustomOptionState(parent: string, onClose, row: number) {
  const [option, setOption] = useState<CustomOptionModel>({} as any);
  const [errors, setErrors] = useState<any>({});

  // eslint-disable-next-line consistent-return
  const handleOnSubmit = useCallback(() => {
    const { hasErrors, errors: validationErrors } = validateEntity(option, rules);

    if (hasErrors) {
      return setErrors(validationErrors);
    }

    const { manufacturer } = option;
    const payload = {
      ...option,
      manufacturer: manufacturer.value,
      custom: true,
    };

    notifyEventChannel(
      EventChannelList.QUOTE_PAGE_NEW_CUSTOM_OPTION,
      { option: payload, parent, row },
    );
    setOption({} as any);
    onClose();
  }, [option, parent, onClose, row]);

  const handleNewManufacturer = useCallback(({ data }) => {
    setOption(prevState => ({
      ...prevState,
      manufacturer: { value: data.companyName, label: data.companyName }
    }));
  }, []);

  useEventCenterUpdate(
    EventChannelList.MANUFACTURERS_LIST_CHANGED,
    handleNewManufacturer,
  );

  return {
    state: {
      option,
      errors,
    },
    actions: {
      setOption,
      setErrors,
      mapManufacturers,
      handleOnSubmit,
      openManufacturerModal
    },
  };
}
