import React, { FunctionComponent, useMemo } from 'react';
import { InvoiceModel } from 'models/invoice';
import Page from 'components/base-components/Page';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Help from 'components/forms/CommonHelp';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import RenderIf from 'components/base-components/RenderIf';
import useInvoiceState from './state';
import { permissions } from './permissions';
import './styles.scss';

const disabledFunction = (invoice: InvoiceModel) => !invoice.complete;

const InvoiceList: FunctionComponent = () => {
  const {
    state: {
      data,
      count,
      page,
      loading,
      linkToOrder
    },
    actions: {
      hiddenAction,
      handleFilter,
      handleOnChange,
      printInvoice,
      openSendInvoiceEmailModal,
      openSendProformaInvoiceEmailModal,
      printProformaInvoice,
      openReceivePaymentModal,
    }
  } = useInvoiceState();

  const expandableConf = useMemo(() => ({
    expandedRowRender: (invoice: InvoiceModel) => (
      <>
        <RenderIf isTrue={invoice.proformainvoice.length > 0}>
          <DataTable
            dataSource={invoice.proformainvoice}
            pagination={false}
            size="small"
            showHeader={false}
          >
            <DataTableColumn
              title="Stage"
              dataIndex="invoiceStage"
              columnKey="invoiceStage"
              align="right"
            />
            <DataTableColumn
              title="Amount"
              dataIndex="amount"
              columnKey="amount"
              isCurrency
              width="150px"
            />
            <DataTableColumn
              isActionColumn
            >
              <DataTableAction
                icon="fa fa-print"
                onClick={printProformaInvoice}
                type="default"
                permissions={permissions.viewProformaInvoice}
              />
              <DataTableAction
                icon="fa fa-envelope"
                onClick={openSendProformaInvoiceEmailModal}
                type="default"
                permissions={permissions.changeProformaInvoice}
                hide={hiddenAction}
              />
            </DataTableColumn>
          </DataTable>
        </RenderIf>
      </>
    ),
    expandedRowClassName: (invoice: InvoiceModel) => invoice.proformainvoice.length > 0 ? 'expanded-row' : undefined,
  }), [openSendProformaInvoiceEmailModal, printProformaInvoice, hiddenAction]);

  return (
    <Page title="Invoices">
      <Help view="invoice" title="Invoices List" />
      <DataTable
        dataSource={data}
        loading={loading}
        page={page}
        totalRecords={count}
        onChange={handleOnChange}
        onFilter={handleFilter}
        expandable={expandableConf}
      >
        <DataTableColumn
          title="Id"
          dataIndex="id"
          columnKey="id"
          sorter
        />
        <DataTableColumn
          title="Order Id"
          dataIndex="orderId"
          columnKey="orderId"
          sorter
          showBreakpoint={777}
          link={linkToOrder}
        />
        <DataTableColumn
          title="Company"
          dataIndex="account"
          columnKey="account"
          sorter
        />
        <DataTableColumn
          title="Subtotal"
          dataIndex="subtotal"
          columnKey="subtotal"
          isCurrency
          sorter
          showBreakpoint={1135}
        />
        <DataTableColumn
          title="Shipping"
          dataIndex="shipping"
          columnKey="shipping"
          isCurrency
          showBreakpoint={1227}
        />
        <DataTableColumn
          title="Taxes"
          dataIndex="taxes"
          columnKey="taxes"
          isCurrency
          showBreakpoint={1321}
        />
        <DataTableColumn
          title="Paid"
          dataIndex="paid"
          columnKey="paid"
          isCurrency
          sorter
          showBreakpoint={1403}
        />
        <DataTableColumn
          title="Balance"
          dataIndex="balance"
          columnKey="balance"
          isCurrency
          sorter
          showBreakpoint={1588}
        />
        <DataTableColumn
          title="Total"
          dataIndex="total"
          columnKey="total"
          isCurrency
          sorter
          width="150px"
        />
        <DataTableColumn
          isActionColumn
        >
          <DataTableAction
            disabled={disabledFunction}
            icon="fa fa-print"
            onClick={printInvoice}
            permissions={permissions.viewInvoice}
          />
          <DataTableAction
            disabled={disabledFunction}
            icon="fa fa-envelope"
            onClick={openSendInvoiceEmailModal}
            permissions={permissions.changeInvoice}
            hide={hiddenAction}
          />
          <DataTableAction
            onClick={openReceivePaymentModal}
            icon="fas fa-dollar-sign"
            label="Transactions"
            permissions={permissions.viewTransaction}
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default InvoiceList;
