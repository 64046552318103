import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { ProductListHistorySateModel } from '../../ProductList';
import { BuilderFormModel } from '../types';

export function openProductListForm(push, products: BuilderFormModel[]) {
  return () => {
    const historyLocationState: ProductListHistorySateModel = {
      productListForm: {
        products,
      },
    };
    push(ModalRouteHash.ProductList, historyLocationState);
  };
}
