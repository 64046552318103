const commonTextures = [
  {
    label: 'Oak',
    texture: 'Blueprint3D-assets/textures/163 Aged Oak.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'Mahogany',
    texture: 'Blueprint3D-assets/textures/Mahogany-w365.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'W155',
    texture: 'Blueprint3D-assets/textures/W155.jpg',
    width: 35,
    height: 35,
  },
];

const commonModestyStyles = [
  {
    label: '12m',
    name_in_model: 'desk-modesty-12m',
  },
  {
    label: '18m',
    name_in_model: 'desk-modesty-18m',
  },
  {
    label: '28m',
    name_in_model: 'desk-modesty-28m',
  },
];

const tLegModestyStyles = [
  ...commonModestyStyles,
  {
    label: 'TLEG',
    name_in_model: 'desk-modesty-tleg',
  },
];

const cogGrommetStyles = [
  {
    label: 'NG',
    name_in_model: '',
  },
  {
    label: 'COG',
    name_in_model: 'grommet-cog',
  },
];

const data = {
  category: 'PDESK',
  styles: [
    {
      name: 'PDESK-ARKL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-arkl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-ARKL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-ARKR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-arkr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-ARKR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-BOOML',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-booml.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-BOOML.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-BOOMR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-boomr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-BOOMR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-DTL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-dtl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-DTL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-DTR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-dtr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-DTR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-JTL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-jtl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-JTL.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-JTR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-jtr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-JTR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-PTL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-ptl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-PTL.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-PTR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-ptr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-PTR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-RTL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-rtl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-RTL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'PDESK-RTR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-rtr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PDESK-RTR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: tLegModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
  ],
};

export default data;
