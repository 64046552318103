import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { RowSelectionType } from 'antd/lib/table/interface';
import { PurchaseOrderDTO } from 'models/order';
import { ManufacturerModel } from 'models/manufacturer';
import purchaseOrderApi from 'api/purchase-order';
import manufacturerApi from 'api/manufacturer';
import { resolveApiErrorMessage } from 'api/base';
import useGetList from 'hooks/base/get-list';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList } from 'helpers/event-center';
import openSendEmailModal from '../../PurchaseOrders/List/state/actions/open-sed-email-modal';
import createBill from '../../PurchaseOrders/List/state/actions/create-bill';
import deleteBill from '../../PurchaseOrders/List/state/actions/delete-bill';
import printPDF from '../../PurchaseOrders/List/state/actions/print-pdf';
import openFormModalPO from '../../PurchaseOrders/List/state/actions/open-form-modal';
import openFormModalPOItem from '../../POItems/List/state/actions/open-form-modal';

export default function useMaterialsForm(order, onClose) {
  const history = useHistory();
  const useTableStateParams: UseTableStateParams = useMemo(() => ({
    api: purchaseOrderApi,
    defaultSortField: '-id',
    eventChannel: EventChannelList.PURCHASE_ORDERS_LIST_CHANGED,
    queryParams: { order },
    pageSize: 3,
    preventAutoFetch: !order,
  }), [order]);

  const {
    fetching: fetchingManufacturers,
    data: manufacturers,
    error: fetchingManufacturersErrors,
  } = useGetList<ManufacturerModel>(manufacturerApi);

  useEffect(() => {
    if (fetchingManufacturersErrors) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(fetchingManufacturersErrors),
      });
    }
  }, [fetchingManufacturersErrors]);

  const { state, actions } = useTableState<PurchaseOrderDTO>(useTableStateParams);
  const { data: purchaseOrders, fetching } = state;
  const { resetState, updateDataManually } = actions;
  const [selectedRow, setSelectedRow] = useState([]);
  const [updating, setUpdating] = useState(false);

  const {
    data: purchaseOrder,
    fetching: purchaseOrderFetching,
  } = useGetOne(purchaseOrderApi, selectedRow[0], { preventAutoFetch: !selectedRow.length });

  return {
    state: {
      ...state,
      fetching: fetching || fetchingManufacturers || updating,
      data: useMemo(() => purchaseOrders.map((pO) => {
        const { manufacturer, ...rest } = pO;

        return {
          ...rest,
          manufacturer: manufacturers.find((m) => m.companyName === manufacturer),
          dateSent: pO.dateSent ? moment(pO.dateSent) : undefined,
        };
      }), [manufacturers, purchaseOrders]),
      rowSelection: useMemo(() => ({
        selectedRowKeys: selectedRow,
        onChange: (key) => setSelectedRow(key),
        type: 'radio' as RowSelectionType,
      }), [selectedRow]),
      selectedRow,
      purchaseOrder,
      purchaseOrderFetching,
    },
    actions: {
      ...actions,
      printPDF,
      openSendEmailModal: useCallback(openSendEmailModal(history, true), [history]),
      createBill: useCallback(createBill(setUpdating), []),
      deleteBill: useCallback(deleteBill(setUpdating), []),
      openFormModalPO: useCallback(openFormModalPO(history, order, true), [history, order]),
      openFormModalPOItems: useCallback(
        openFormModalPOItem(history, selectedRow[0], true),
        [history, selectedRow[0]],
      ),
      handleOnClose: useCallback(() => {
        updateDataManually([]);
        resetState();
        setSelectedRow([]);
        onClose();
      }, [onClose, updateDataManually, resetState]),
    }
  };
}
