/* eslint-disable */
import React, { useState } from 'react';
import { CASES } from 'lib/products/sets/restrictions';
import OptionSection, { getMorphValue } from './optionSection';

export const getChanges = async (_setId, itemId, itemName) => {
  return new Promise((resolve) => {
    // console.log("get changes", setId, itemId, itemName);
    const res = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of CASES) {
      if (item.id === itemId) {
        if (item.product_names.length > 1) {
          if (item.product_names.includes(itemName)) {
            res.push(...item.changes);
          }
        } else if (item.product_names.length === 1) {
          if (item.product_names[0] === '*') {
            res.push(...item.changes);
          }
          if (!itemName && !item.product_names[0]) {
            res.push(...item.changes);
          }
        }
      }
    }
    resolve(res);
  });
};

function CustomAccordion(props: any) {
  const [opened, setOpened] = useState(false);
  const { label, children } = props;
  return (
    <div className="set-group-builder__accordion-container">
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="title" onClick={() => setOpened(!opened)}>
        {label}
      </div>
      {opened && children}
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface iProps {
  setInfo: any;
  productChanged: (id: string, product: object, option?: any) => void;
  morphChanged: (id: string, index: number, value: number) => void;
  materialChanged: (id: string, target: string, material: string) => void;
  // eslint-disable-next-line @typescript-eslint/camelcase
  styleChanged: (id: string, hide_name: string, show_name: string) => void;
  propertiesChanged: (id: string, properties: object) => void;
}
// eslint-disable-next-line @typescript-eslint/class-name-casing
interface iState {
  setInfo: any;
  setInfoBackup: any;
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface iSetInfoNode {
  id?: string;
  parentUUID?: string;
  label: string;
  products: any[];
  validation?: any;
  includes: any;
  optionGroup: any;
  morphAlign?: number;
  rotation?: number;
  position?: object;
  marginLeft?: number;
  marginRight?: number;
  marginBack?: number;
  marginFront?: number;
  hidden?: boolean;
  children?: Array<any>;
}

class PropertySection extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props);

    this.state = {
      setInfo: null,
      // eslint-disable-next-line react/no-unused-state
      setInfoBackup: null,
    };
  }

  UNSAFE_componentWillReceiveProps = (newProps: iProps) => {
    if (
      JSON.stringify(this.props.setInfo) !== JSON.stringify(newProps.setInfo)
    ) {
      this.setState({
        setInfo: JSON.parse(JSON.stringify(newProps.setInfo)),
        // eslint-disable-next-line react/no-unused-state
        setInfoBackup: JSON.parse(JSON.stringify(newProps.setInfo)),
      });
    }
  };

  recursiveFindItem = (setItem: any, id: string) => {
    if (setItem.id === id) return setItem;
    if (Array.isArray(setItem.children)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < setItem.children.length; i++) {
        const tmp = this.recursiveFindItem(setItem.children[i], id);
        if (tmp) return tmp;
      }
    }
    return null;
  };

  recursiveUpdateProperties = (item: any) => {
    this.props.propertiesChanged(item.id, {
      rotation: item.rotation,
      position: item.position,
      validation: item.validation,
      includes: item.includes,
      morphAlign: item.morphAlign,
      marginLeft: item.marginLeft,
      marginRight: item.marginRight,
      marginBack: item.marginBack,
      marginFront: item.marginFront,
      hidden: item.hidden,
    });

    if (Array.isArray(item.children)) item.children.forEach((child) => this.recursiveUpdateProperties(child));
  };

  updateSetInfo = (changes: any[] | null | undefined) => {
    if (!Array.isArray(changes)) return;
    const { setInfo } = this.state;
    const newSetInfo = JSON.parse(JSON.stringify(setInfo));
    // eslint-disable-next-line no-restricted-syntax
    for (const change of changes) {
      const item = this.recursiveFindItem(newSetInfo.item, change.id);

      // eslint-disable-next-line no-continue
      if (!item) continue;
      console.log('get changes', change);
      // eslint-disable-next-line no-prototype-builtins
      if (change.hasOwnProperty('marginFront')) {
        item.marginFront = change.marginFront;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (change.hasOwnProperty('hidden')) {
        item.hidden = change.hidden;
      }
      if (Array.isArray(change.morph)) {
        // item.optionGroup.morph = change.morph;
        for (let j = 0; j < change.morph.length; j++) {
          item.optionGroup.morph[j] = {
            ...item.optionGroup.morph[j],
            ...change.morph[j],
          };
        }
      }
      if (change.includes) {
        item.includes = change.includes;
      }
    }

    if (changes.length) this.recursiveUpdateProperties(newSetInfo.item);

    this.setState({ setInfo: newSetInfo });
  };

  handleProductChanged = async (info: any, e: any) => {
    const { setInfo } = this.state;
    const option = {
      parentUUID: info.parentUUID,
      rotation: info.rotation,
      position: info.position,
      validation: info.validation,
      includes: info.includes,
      morphAlign: info.morphAlign,
      marginLeft: info.marginLeft,
      marginRight: info.marginRight,
      marginBack: info.marginBack,
      marginFront: info.marginFront,
      hidden: info.hidden,
    };
    const itemInfo = JSON.parse(e.target.value);
    const changes = await getChanges(
      setInfo.id,
      info.id,
      itemInfo ? itemInfo.name : null
    );
    this.updateSetInfo(changes as any);

    const item = this.recursiveFindItem(this.state.setInfo.item, info.id);
    if (item) {
      let productName = '';
      if (itemInfo) productName = itemInfo.name;
      item.product = productName;
    }
    // overlap morph, styles and materials
    try {
      itemInfo.morph = info.optionGroup.morph;
      itemInfo.styles = info.optionGroup.styles;
      itemInfo.materials = info.optionGroup.materials;
    } catch (_) {}
    this.props.productChanged(info.id, itemInfo, option);
    this.forceUpdate();
  };

  getDeltaValue = (delta: any) => {
    if (!isNaN(delta) && typeof delta === 'number') return delta;
    if (typeof delta === 'object') {
      if (delta.target && delta.field) {
        const item = this.recursiveFindItem(
          this.state.setInfo.item,
          delta.target
        );
        if (item) {
          let value = 0;
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const j in item.optionGroup.morph) {
            const morph = item.optionGroup.morph[j];
            if (morph.label.toLowerCase() === delta.field) {
              if (morph.value) value = morph.value;
              else value = morph.min;
            }
          }
          if (delta.delta) value += this.getDeltaValue(delta.delta);
          if (delta.isAddValue) value = -value;
          if (item.product) return value;
        }
      }
    }
    if (delta && delta.default) return delta.default;
    return 0;
  };

  recursiveRenderProduct = (info: iSetInfoNode) => {
    const optionGroup = JSON.parse(JSON.stringify(info.optionGroup));
    if (optionGroup.morph) {
      for (let i = 0; i < optionGroup.morph.length; i++) {
        const morph = optionGroup.morph[i];
        if (isNaN(morph.max)) {
          const { target, field } = morph.max;
          const parentItem = this.recursiveFindItem(
            this.state.setInfo.item,
            target
          );
          let value = 0;
          if (parentItem) {
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const j in parentItem.optionGroup.morph) {
              const parentMorph = parentItem.optionGroup.morph[j];
              if (parentMorph.label.toLowerCase() === field) {
                if (parentMorph.value) value = parentMorph.value;
                else value = parentMorph.min;
              }
            }
          }
          if (value > 0) {
            const delta = this.getDeltaValue(morph.max.delta);
            morph.max = value - delta;
          } else {
            morph.max = 100;
          }
        }

        // // follow max value
        if (morph.followMaxValue) morph.min = morph.max;

        if (morph.value > morph.max) {
          morph.value = morph.max;
          this.props.morphChanged(
            info.id,
            morph.index,
            getMorphValue(morph.max)
          );
        }
      }
    }

    return (
      <div>
        <CustomAccordion label={info.label}>
          <span>Product</span>
          <select
            defaultValue="null"
            onChange={(e) => {
              this.handleProductChanged(info, e);
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <option value="null" />
            {info.products.map((product, index) => {
              let shouldRender = false;
              if (info.includes) {
                // eslint-disable-next-line guard-for-in,no-restricted-syntax
                for (const key in info.includes) {
                  if (product.name.includes(key)) {
                    if (Array.isArray(info.includes[key])) {
                      // eslint-disable-next-line guard-for-in,no-restricted-syntax
                      for (const subName of info.includes[key]) {
                        if (product.name.includes(subName)) {
                          shouldRender = true;
                        }
                      }
                    }
                  }
                }
              } else {
                shouldRender = true;
              }
              if (!shouldRender) return null;
              return (
                <option key={index} value={JSON.stringify(product)}>
                  {product.name}
                </option>
              );
            })}
          </select>
          <OptionSection
            id={info.id}
            info={optionGroup}
            onMorphChanged={(index, value, rawValue, forceUpdate = true) => {
              const item = this.recursiveFindItem(
                this.state.setInfo.item,
                info.id
              );
              for (let i = 0; i < item.optionGroup.morph.length; i++) {
                const morph = item.optionGroup.morph[i];
                if (morph.index === index) {
                  morph.value = rawValue;
                }
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              forceUpdate && this.forceUpdate();
              this.props.morphChanged(info.id, index, value);
            }}
            onMaterialChanged={(target: string, material: string) => {
              this.props.materialChanged(info.id, target, material);
            }}
            /* eslint-disable-next-line @typescript-eslint/camelcase */
            onStyleChanged={(hide_name, show_name) => {
              this.props.materialChanged(info.id, hide_name, show_name);
            }}
          />
        </CustomAccordion>
        {Array.isArray(info.children) &&
          info.children.map((children, index) => (
            <div key={index}>{this.recursiveRenderProduct(children)}</div>
          ))}
      </div>
    );
  };

  render() {
    const { setInfo } = this.state;
    return (
      <div style={{ padding: 10 }}>
        {setInfo && this.recursiveRenderProduct(setInfo.item)}
      </div>
    );
  }
}

export default PropertySection;
