import { ProformaInvoiceModel } from 'models/proforma-invoice';
import proformaInvoiceApi from 'api/proforma-invoice';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function printProformaInvoice(proformaInvoice: ProformaInvoiceModel) {
  const { id } = proformaInvoice;
  proformaInvoiceApi.print(id)
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}
