import React, { useMemo, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { logoutUser } from 'store/actions';
import { getLoggedUser } from 'helpers/get-logged-user';
import Avatar from 'components/base-components/Avatar';

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { image: profileImg, name, username } = useMemo(getLoggedUser, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <Dropdown isOpen={isOpen} toggle={handleToggle} className="d-inline-block">
      <DropdownToggle
        className="btn header-item waves-effect"
        id="page-header-user-dropdown"
        tag="button"
      >
        <Avatar
          src={profileImg}
          alt="Header Avatar"
        >
          {(name || username).charAt(0)}
        </Avatar>
        <span className="d-none d-xl-inline-block ml-2 mr-1">{name || username}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag="span" onClick={handleLogout}>
          <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
          <span>
            <FormattedMessage id="Logout" />
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withRouter(ProfileMenu);
