import React, { FunctionComponent, useCallback } from 'react';
import DataTable from 'components/base-components/DataTableV2';
import Page, { PageActions } from 'components/base-components/Page';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import NumberInput from 'components/base-components/NumberInput';
import Button from 'components/base-components/Button';
import useProductionManagerState from 'components/pages/ProductionManager/state';
import progressTemplate from 'components/pages/ProductionManager/templates/progress-template';
import ProductionCalendar from 'components/pages/ProductionManager/Calendar';
import 'components/pages/ProductionManager/styles.scss';
import AccessControl from 'components/base-components/AccessControl';
import { permissions } from './permissions';

const CNCWorker: FunctionComponent = () => {
  const {
    state: {
      data,
      fetching,
      count,
      page,
      showCalender,
      linkToOrder
    },
    actions: {
      handleOnChange,
      handleFilter,
      printInstallerOrder,
      printShopOrder,
      onSubmit,
      handleShowCalendar,
      updateDataTable,
      openNotesModal,
    }
  } = useProductionManagerState();

  const cncPercentageRender = useCallback(progressTemplate('cncPercentage'), []);

  return (
    <Page title="CNC Worker">
      <PageActions primary>
        <AccessControl permissions={permissions.changeOrder}>
          <Button
            onClick={handleShowCalendar}
            label="Calendar"
            mR
            mT
          />
        </AccessControl>
      </PageActions>
      <DataTable
        loading={fetching}
        dataSource={data}
        totalRecords={count}
        page={page}
        onChange={handleOnChange}
        className="production-manager__table"
        onSubmit={onSubmit}
        onFilter={handleFilter}
        onSubmitSuccess={updateDataTable}
      >
        <DataTableColumn
          columnGroup
          title="JOB INFO"
          className="force-border-top"
        >
          <DataTableColumn
            title="Order"
            dataIndex="id"
            columnKey="id"
            width={65}
            sorter
            link={linkToOrder}
          />
          <DataTableColumn
            title="Company"
            dataIndex="company"
            columnKey="company"
            trimAt={17}
          />
          <DataTableColumn
            title="Job Title"
            dataIndex="jobTitle"
            columnKey="job_title"
            trimAt={25}
          />
          <DataTableColumn
            title="Rep. Owner"
            dataIndex="repOwner"
            columnKey="rep_owner"
            trimAt={17}
          />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
        />
        <DataTableColumn columnGroup title="DETAILS" className="force-border-top">
          <DataTableColumn
            title="Cutlist"
            dataIndex="readyDate"
            isDate
            hideColumn
          />
          <DataTableColumn
            title="CNC"
            dataIndex="cncPercentage"
            columnKey="cnc_percentage"
            render={cncPercentageRender}
            editable
            sorter
            allowDecimals
            component={NumberInput}
            width={70}
            min={0}
            max={100}
          />
          <DataTableColumn
            title="Package"
            isActionColumn
            width="110px"
          >
            <DataTableAction
              onClick={openNotesModal}
              icon="fas fa-lightbulb"
              label="Notes"
              badge="noteCount"
              permissions={permissions.changeNote}
            />
            <DataTableAction
              onClick={printInstallerOrder}
              label="Install"
              icon="fa fa-tools"
            />
            <DataTableAction
              onClick={printShopOrder}
              label="Shop"
              icon="fa fa-shopping-bag"
            />
          </DataTableColumn>
        </DataTableColumn>
      </DataTable>
      <ProductionCalendar
        visible={showCalender}
        handleVisible={handleShowCalendar}
      />
    </Page>
  );
};

export default CNCWorker;
