/* eslint-disable */
import * as THREE from 'three';

import {
  getBounding,
  getRealMorphValue,
  strBlockExtended,
} from './utils';

import * as Utils from './utils';

export default class Item extends THREE.Mesh {
  scene;
  errorGlow = new THREE.Mesh();
  hover = false;
  selected = false;
  highlighted = false;
  error = false;
  resizable = true;
  fixed = false;

  stackable = false;
  stackontop = false;
  overlappable = false;

  allowRotate = true;
  obstructFloorMoves = true;

  /** */
  emissiveColor = 0x444444;
  errorColor = 0xFF0000;

  /** */
  position_set;

  /** dragging */
  dragOffset = new THREE.Vector3();

  /** */
  halfSize;

  /** Constructs an item.
   * @param model TODO
   * @param metadata TODO
   * @param meshList TODO
   * @param position TODO
   * @param rotation TODO
   * @param scale TODO
   */
  constructor(model, metadata, meshList, position, rotation, options) {
    super();

    Array.isArray(meshList) && meshList.forEach(item => this.add(item));

    this.centerOffset = new THREE.Vector3();

    // modifications
    this.morph = {};
    this.textures = {};
    this.styles = {};
    this.blockCount = 1;

    this.model = model;
    this.scene = this.model;
    this.metadata = metadata;

    this.errorColor = 0xFF0000;

    this.resizable = metadata.resizable;

    this.castShadow = true;
    this.receiveShadow = false;

    // center in its bounding box
    const bbox = this.getMaxBounding();

    this.children.forEach(item => {
      item.geometry.applyMatrix(new THREE.Matrix4().makeTranslation(-0.5 * (bbox.max.x + bbox.min.x), -0.5 * (bbox.max.y + bbox.min.y), -0.5 * (bbox.max.z + bbox.min.z)));
    });

    this.prepareMeshes(meshList);

    // load stored configuration
    if (position) {
      this.position.copy(position);
      this.position_set = true;
    } else {
      this.position.set(0, 0, 0);
      this.position_set = false;
    }

    if (rotation) {
      this.rotation.y = rotation;
    }

    if (options) {
      console.log(options);
      options.hasOwnProperty('fixed') && (this.fixed = options.fixed);
      options.hasOwnProperty('stackable') && (this.stackable = options.stackable);
      options.hasOwnProperty('stackontop') && (this.stackontop = options.stackontop);
      options.hasOwnProperty('overlappable') && (this.overlappable = options.overlappable);

      // load morph
      if (options.morph) {
        for (var i in options.morph) {
          this.setMorph(i, options.morph[i]);
        }
      }
      if (options.textures) {
        for (i in options.textures) this.updateMaterial(i, options.textures[i].material, options.textures[i].size);
      }
      if (options.styles) {
        for (i in options.styles) this.updateStyle(i, options.styles[i]);
      }
    }
  }

  prepareMeshes(meshList) {
    if (!Array.isArray(meshList)) return;
    for (const mesh of meshList) {
      try {
        const bufferGeoBackup = new THREE.BufferGeometry().copy(mesh.geometry);
        mesh.geometryBackup = bufferGeoBackup;

        // pre-process
        (() => {
          // opacity
          const minStrRegex = /\(opacity(\d+)\)/g;
          const matches = minStrRegex.exec(mesh.name);
          if (matches && matches[1]) {
            const value = parseInt(matches[1]);
            mesh.material.transparent = true;
            mesh.material.opacity = value / 100;
          }
        })();
      } catch (_) {
        console.log(_);
      }
    }
  }

  getMaxBounding(ignoreExtendedMeshes = false) {
    if (!this.dummy) {
      const dummy = new THREE.Mesh();
      for (const child of this.children) {
        if (ignoreExtendedMeshes && child.name.includes(strBlockExtended)) continue;
        dummy.add(new THREE.Mesh(child.geometry.clone(), null));
      }

      dummy.children.forEach(child => {
        const vector = child.geometry.attributes.position.array;
        try {
          if (Array.isArray(child.morphTargetInfluences)) {
            for (const i in child.morphTargetInfluences) {
              const targetVector = child.geometry.morphAttributes.position[i].array;
              for (let j = 0; j < vector.length; j++) {
                vector[j] += (targetVector[j]) * child.morphTargetInfluences[i];
              }
            }
          }
        } catch (_) {
          console.log(_);
        }
      });
      this.dummy = dummy;
    }
    this.dummy.material = null;
    this.dummy.position.copy(this.position);
    this.dummy.rotation.copy(this.rotation);
    this.dummy.scale.copy(this.scale);
    return this.getBounding(this.dummy);
  }

  /**
   * calculate bounding data according to morphing
   */
  getBounding(mesh = this, meshList = []) {
    try {
      return getBounding(mesh, meshList);
    } catch (_) {
      return this.getMaxBounding();
    }
  }

  /** */
  remove() {
    console.log(this.scene)
    this.scene.remove(this);
  }

  /** */
  resize(height, width, depth) { }

  getMorph(index) {
    return this.morph[index] ? this.morph[index] : 0;
  }

  setMorph(index, value) {
    this.morph[index] = value;
    try {
      for (const child of this.children) {
        try {
          if (Array.isArray(child.morphTargetInfluences) && child.morphTargetInfluences.length > index) {
            child.morphTargetInfluences[index] = getRealMorphValue(child, index, value);
          }
        } catch (_) { console.log(_); }
      }
      this.updateUV();

      // update centeroffset
      const bbox = this.getBounding();
      const center = new THREE.Vector3((bbox.min.x + bbox.max.x) / 2, 0, (bbox.min.z + bbox.max.z) / 2);
      this.centerOffset = center.sub(this.position);
      this.centerOffset.y = 0;

      if (index === this.metadata.blockMorphIndex) {
        this.updateBlocksByHeight((5 + (300 - 5) * value) * 2.54 / 100);
      }
    } catch (_) {
      console.log(_)
    }
  }

  /**
   * update uv according to morph target uv data
   */
  updateUV() {
    try {
      const { morphUVs } = this.metadata;
      if (!Array.isArray(this.metadata.morphUVs)) return;

      Utils.updateUV(this.morph, this.children, morphUVs);
    } catch (_) {
      console.log('failed updating uv', _)
    }
  }

  /** */
  initObject = function () {
    this.scene.needsUpdate = true;
  };

  /** on is a bool */
  updateHighlight() {
    const on = this.hover || this.selected;
    this.highlighted = on;
    const hex = on ? this.emissiveColor : 0x000000;
    this.children.forEach(item => {
      item.material.emissiveIntensity = 0.5;
      item.material.emissive.setHex(hex);
    })
  }

  /** */
  isValidPosition(vec3) { }

  /**
   * update material
   */
  updateMaterial = (target, material, size = { w: 0.5, h: 0.5 }, cb = null) => {
    this.textures[target] = {
      material,
      size,
    };
    this.traverse(obj => {
      if (obj.name.toLowerCase().includes(target.toLowerCase())) {
        const mat = obj.material.clone();
        mat.color.setHex(material.color ? material.color : 0xFFFFFF);
        mat.map = null;

        if (material.texture) {
          // let texture = THREE.ImageUtils.loadTexture(material.texture);
          const texture = new THREE.TextureLoader().load(material.texture);
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(1 / size.w, 1 / size.h);

          mat.map = texture;
          typeof cb === 'function' && cb();
        }
        // obj.material.dispose();
        obj.material = mat;
      }
    });
  };

  /**
   * update style
   */
  updateStyle = (hide_name, show_name, cb) => {
    this.styles[hide_name] = show_name;

    this.traverse(obj => {
      try {
        hide_name.split(',').forEach(name => {
          if (name.length && obj.name.toLowerCase().includes(name.toLowerCase())) obj.visible = false;
        });
        show_name.split(',').forEach(name => {
          if (name.length && obj.name.toLowerCase().includes(name.toLowerCase())) obj.visible = true;
        })
      } catch (_) { console.log(_); }
    });
    typeof cb === 'function' && cb();
  };

  /**
   * update block count
   */
  updateBlocksCount = (count = 1) => {
    // console.log('update block count', count);
    this.blockCount = count;
    try {
      const blockMeshes = [];
      let blockTop = null;

      for (var i = this.children.length - 1; i >= 0; i--) {
        const mesh = this.children[i];
        // delete extended blocks
        if (mesh.name.includes(strBlockExtended)) {
          this.scene.remove(mesh);
          this.children.splice(i, 1);
        }
        // detect block and block-top meshes
        if (mesh.name.includes('(block)') && !mesh.name.includes(strBlockExtended)) {
          blockMeshes.push(mesh);
        }
        else if (mesh.name.includes('(block-top)')) {
          blockTop = mesh;
        }
      }

      blockTop && (blockTop.visible = false);

      const blockBounding = this.getBounding(this, blockMeshes);
      const blockHeight = blockBounding.max.y - blockBounding.min.y;

      for (var i = 1; i < count; i++) {
        blockMeshes.forEach(mesh => {
          const newMesh = new THREE.Mesh().copy(mesh);
          newMesh.geometryBackup = mesh.geometryBackup;
          newMesh.name += `${strBlockExtended}${i}`;
          newMesh.position.y = blockHeight * (i);
          this.add(newMesh);
        })
      }
      if (blockTop) {
        blockTop.visible = true;
        blockTop.position.y = blockHeight * (count - 1);
      }
    } catch (_) {
      console.log(_);
    }
  }

  /**
   * update blocks by height
   * @param {number} height product height by meter
   */
  updateBlocksByHeight = (height) => {
    let blockMesh = null;
    for (var i = this.children.length - 1; i >= 0; i--) {
      const item = this.children[i];

      if (item.name.includes('(block)')) item.visible = true;
      if (item.name.includes(strBlockExtended)) {
        this.scene.remove(item);
        this.children.splice(i, 1);
      }
      if (item.name.includes('(block)') && !item.name.includes(strBlockExtended)) blockMesh = item;
    }
    if (!blockMesh) return;

    let blockMeshes = [];
    blockMeshes.push(blockMesh);
    const blockBounding = this.getBounding(this, [blockMesh]);
    const blockHeight = blockBounding.max.y - blockBounding.min.y;
    console.log(blockHeight)
    let posY = blockMesh.position.y;

    const count = Math.floor((height - posY) / blockHeight) - 1;
    for (i = 0; i < count; i++) {
      const mesh = new THREE.Mesh().copy(blockMesh);
      mesh.geometryBackup = blockMesh.geometryBackup;
      mesh.name += `${strBlockExtended}${i}`;
      mesh.position.y = posY + blockHeight;
      this.add(mesh);
      posY = mesh.position.y;
      blockMeshes.push(mesh);
    }

    const lastMesh = blockMeshes[blockMeshes.length - 1];
    if (lastMesh.position.y > (height - blockHeight * 2)) {
      lastMesh.visible = false;
    }
  }
}
