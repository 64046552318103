import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/notes/`;

const notesApi: ApiModel = {
  ...getApi(endpoint, ApiContentType.JSON, true),
};

export default notesApi;
