import React from 'react';
import { formatDisplayDate } from 'helpers/formatting';

export default function installerBookingRender(dataIndex: string) {
  return (data) => {
    const installers = data?.installersBooking?.map((inst, index) => (
      <dd key={index}>
        {dataIndex === 'installer' ? inst[dataIndex] : formatDisplayDate(inst[dataIndex])}
      </dd>
    ));

    return (
      <dl className="installer-list__dl">
        {installers}
      </dl>
    );
  };
}
