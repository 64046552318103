import { QuickUserModel } from 'models/user';

export enum CreateQuickUserFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_CREATING = 'START_CREATING',
  FINISH_CREATING = 'FINISH_CREATING',
}

interface CreateQuickUserFormState {
  formData: QuickUserModel;
  creating: boolean;
  errors: any;
}

export default function createQuickUserFormReducer(
  state: CreateQuickUserFormState, action
): CreateQuickUserFormState {
  const { type, payload } = action;

  switch (type as CreateQuickUserFormActions) {
    case CreateQuickUserFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case CreateQuickUserFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, creating: false };
    case CreateQuickUserFormActions.START_CREATING:
      return { ...state, creating: true };
    case CreateQuickUserFormActions.FINISH_CREATING:
      return { ...state, creating: false };
    default:
      return state;
  }
}
