import React from 'react';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import useCreateGroup from './state';

export default function CreateGroup(props) {
  const { visible, onClose } = props;

  const {
    state: {
      group,
    },
    actions: {
      handleValueChange,
      handleOnSubmit,
    },
  } = useCreateGroup(onClose);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      okText="Submit"
      onOk={handleOnSubmit}
      title="Create Group"
      zIndex={9999}
      className="group-modal"
    >
      <Form state={group} onChange={handleValueChange}>
        <Field
          type="text"
          id="group"
          name="group"
          label="Group"
          placeholder="Enter New Group"
          onPressEnter={handleOnSubmit}
        />
      </Form>
    </Modal>
  );
}
