import { resolveApiErrorMessage } from 'api/base';
import billApi from 'api/bill';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { PurchaseOrderListModel } from '..';

export default function createBill(setIsUpdating) {
  return (po: PurchaseOrderListModel) => {
    setIsUpdating(true);

    const onSuccess = () => {
      notification({
        type: NotificationType.SUCCESS,
        message: 'Purchase Order Bill deleted.',
      });
      notifyEventChannel(EventChannelList.PURCHASE_ORDERS_LIST_CHANGED);
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const onFinally = () => {
      setIsUpdating(false);
    };

    billApi.delete(po.bill)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
