import React from 'react';
import { formatCurrency } from 'helpers/formatting';

export default function totalTemplate(row) {
  if (typeof row.id === 'string') {
    if (row.id.includes('subtotal') && row.subtotal) {
      return {
        children: <b>{formatCurrency(row.subtotal)}</b>,
        props: {
          align: 'right',
        },
      };
    }

    return {
      props: {
        colSpan: 0,
      },
    };
  }

  return formatCurrency(row.priceWithOptions * row.quantity);
}
