import productApi from 'api/product';
import { AddProductFormActions } from '../reducer';

export default function fetchCustomProduct(dispatch, model) {
  const onSuccess = ({ data }) => {
    dispatch({
      type: AddProductFormActions.UPDATE_FORM_VALUE,
      payload: {
        product: {
          value: 'custom-product',
          label: data.model,
          dropdownItem: data,
        },
      },
    });
  };

  const onError = () => {
    dispatch({
      type: AddProductFormActions.SET_VALIDATION_ERRORS,
      payload: {
        product: 'This model is not valid',
      },
    });
  };

  productApi.getByModel(model)
    .then(onSuccess)
    .catch(onError);
}
