import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import en from './locales/en.json';
import fr from './locales/fr.json';

const locales = {
  en,
  fr
};

export default function I18nProvider(props) {
  const { children } = props;
  const lang = useSelector(({ login }: any) => login.lang);

  return (
    <IntlProvider defaultLocale="en" locale={lang} key={lang} messages={locales[lang]}>
      {children}
    </IntlProvider>
  );
}
