export enum EventChannelList {
  USERS_LIST_CHANGED = 'USERS_LIST_CHANGED',
  USERS_GROUP_LIST_CHANGED = 'USERS_GROUP_LIST_CHANGED',
  ACCOUNTS_LIST_CHANGED = 'ACCOUNTS_LIST_CHANGED',
  ACCOUNT_GROUPS_LIST_CHANGED = 'ACCOUNT_GROUPS_LIST_CHANGED',
  TERMS_LIST_CHANGED = 'TERMS_LIST_CHANGED',
  ACCOUNT_APPLICATIONS_LIST_CHANGED = 'ACCOUNT_APPLICATIONS_LIST_CHANGED',
  MANUFACTURERS_LIST_CHANGED = 'MANUFACTURERS_LIST_CHANGED',
  OPTIONS_LIST_CHANGED = 'OPTIONS_LIST_CHANGED',
  OPTION_GROUPS_LIST_CHANGED = 'OPTIONS_GROUPS_LIST_CHANGED',
  CATEGORIES_LIST_CHANGED = 'CATEGORIES_LIST_CHANGED',
  PRODUCTS_LIST_CHANGED = 'PRODUCTS_LIST_CHANGED',
  QUOTES_LIST_CHANGED = 'QUOTES_LIST_CHANGED',
  PURCHASE_ORDERS_LIST_CHANGED = 'PURCHASE_ORDERS_LIST_CHANGED',
  PURCHASE_ORDER_ITEMS_LIST_CHANGED = 'PURCHASE_ORDER_ITEMS_LIST_CHANGED',
  SHIPPING_PROJECT_CHANGE = 'SHIPPING_PROJECT_CHANGE',
  ORDERS_LIST_CHANGED = 'ORDERS_LIST_CHANGED',
  DEFICIENCY_ITEMS_LIST_CHANGED = 'DEFICIENCY_ITEMS_LIST_CHANGED',
  DATATABLE_DELETING = 'DATATABLE_DELETING',
  QUOTE_PAGE_NEW_GROUP = 'QUOTE_PAGE_NEW_GROUP',
  QUOTE_PAGE_NEW_CUSTOM_OPTION = 'QUOTE_PAGE_NEW_CUSTOM_OPTION',
  QUOTE_PAGE_NEW_ACCOUNT = 'QUOTE_PAGE_NEW_ACCOUNT',
  QUOTE_PAGE_NEW_USER = 'QUOTE_PAGE_NEW_USER',
  QUOTE_PAGE_OPEN_GROUP_MODAL = 'QUOTE_PAGE_OPEN_GROUP_MODAL',
  QUOTE_PAGE_OPEN_CUSTOM_OPTION_MODAL = 'QUOTE_PAGE_OPEN_CUSTOM_OPTION_MODAL',
  QUOTE_PAGE_OPEN_MANUFACTURER_MODAL = 'QUOTE_PAGE_OPEN_MANUFACTURER_MODAL',
  QUOTE_PAGE_NEW_PROJECT = 'QUOTE_PAGE_NEW_PROJECT',
  QUOTE_PAGE_NEW_QUOTE = 'QUOTE_PAGE_NEW_QUOTE',
  QUOTE_PAGE_EDIT_QUOTE = 'QUOTE_PAGE_EDIT_QUOTE',
  BUILDER_SELECTED_PRODUCT_SWAPPED = 'BUILDER_SELECTED_PRODUCT_SWAPPED',
  BUILDER_PRODUCT_PROPERTY_CHANGED = 'BUILDER_PRODUCT_PROPERTY_CHANGED',
  BUILDER_PRODUCT_OPTION_CHANGED = 'BUILDER_PRODUCT_OPTION_CHANGED',
  BUILDER_SCENE_OPTION_CHANGED = 'BUILDER_SCENE_OPTION_CHANGED',
  BUILDER_WALL_CHANGED = 'BUILDER_WALL_CHANGED',
  BUILDER_FLOOR_CHANGED = 'BUILDER_FLOOR_CHANGED',
  BUILDER_SCENE_LOCKED_CHANGE = 'BUILDER_SCENE_LOCKED_CHANGE',
  BUILDER_COPY_PRODUCTS = 'BUILDER_COPY_PRODUCTS',
  BUILDER_LOADED_PRODUCT = 'BUILDER_LOADED_PRODUCT',
  BUILDER_TOGGLE_X_RAY = 'BUILDER_TOGGLE_X_RAY',
  BUILDER_CLEAR_SELECTION = 'BUILDER_CLEAR_SELECTION',
  BUILDER_SET_STRETCH_DIRECTION = 'BUILDER_SET_STRETCH_DIRECTION',
  BUILDER_SET_FIXED = 'BUILDER_SET_FIXED',
  BUILDER_SET_STACKABLE = 'BUILDER_SET_STACKABLE',
  BUILDER_SET_OVERLAPPABLE = 'BUILDER_SET_OVERLAPPABLE',
  BUILDER_FLIP_HORIZONTAL = 'BUILDER_FLIP_HORIZONTAL',
  BUILDER_DUPLICATE = 'BUILDER_DUPLICATE',
  BUILDER_DELETE_ACTIVE_PRODUCT = 'BUILDER_DELETE_ACTIVE_PRODUCT',
  BUILDER_REQ_SAVE_JSON = 'BUILDER_REQ_SAVE_JSON',
  BUILDER_RES_SAVE_JSON = 'BUILDER_RES_SAVE_JSON',
  BUILDER_REQ_LOAD_JSON = 'BUILDER_REQ_LOAD_JSON',
  BUILDER_ADD_NEW_SET_PRODUCT = 'BUILDER_ADD_NEW_SET_PRODUCT',
  BUILDER_UPDATE_FLOW_PLANNER = 'BUILDER_UPDATE_FLOW_PLANNER',
  THREE_MODEL_LIST_CHANGED = 'THREE_MODEL_LIST_CHANGED',
  TUTORIAL_LIST_CHANGED = 'TUTORIAL_LIST_CHANGED',
  FLOW_PLANNER_LIST_CHANGED = 'FLOW_PLANNER_LIST_CHANGED',
  SHIPPING_ZONE_SETTING_CHANGED = 'SHIPPING_ZONE_SETTING_CHANGED',
  STORE_SETTING_CHANGED = 'STORE_SETTING_CHANGED',
  PROPERTY_SETTING_CHANGED = 'PROPERTY_SETTING_CHANGED',
  ORDER_PAYMENT_DONE = 'ORDER_PAYMENT_DONE',
  TASK_PAGE_LIST_CHANGED = 'TASK_PAGE_LIST_CHANGED',
  PARTS_LIST_CHANGE = 'PARTS_LIST_CHANGE',
  PRODUCTION_MANAGER_CHANGED = 'PRODUCTION_MANAGER_CHANGED',
  PRODUCTION_MANAGER_DROPPED = 'PRODUCTION_MANAGER_DROPPED',
  PRODUCTION_CALENDAR_VIEW_CHANGED = 'PRODUCTION_CALENDAR_VIEW_CHANGED',
  INSTALLER_LIST_CHANGE = 'INSTALLER_LIST_CHANGE',
  INSTALLER_BOOKING_LIST_CHANGE = 'INSTALLER_BOOKING_LIST_CHANGE',
  PAYMENTS_LIST_CHANGE = 'PAYMENTS_LIST_CHANGE',
  ATTACHMENT_DOCUMENT_LIST_CHANGE = 'ATTACHMENT_DOCUMENT_LIST_CHANGE',
  PROJECT_COORDINATOR_UPDATE = 'PROJECT_COORDINATOR_UPDATE',
  ASYNC_SELECT_REFETCH = 'ASYNC_SELECT_REFETCH',
  TASK_TEMPLATE_CHANGED = 'TASK_TEMPLATE_CHANGED',
  INVENTORY_LIST_CHANGED = 'INVENTORY_LIST_CHANGED',
  SHEET_LIST_CHANGED = 'SHEET_LIST_CHANGED',
  CUT_LIST_CHANGED = 'CUT_LIST_CHANGED',
  CNC_ORDER_LIST_CHANGED = 'CNC_ORDER_LIST_CHANGED',
  QBO_SYNC_LIST_CHANGED = 'QBO_SYNC_LIST_CHANGED',
  UPDATE_NOTE_COUNT = 'UPDATE_NOTE_COUNT',
  CUSTOM_EVENT_LIST_CHANGED = 'CUSTOM_EVENT_LIST_CHANGED',
}
