import { ShipmentDTO } from 'models/shipment';
import shippingAddressApi from 'api/shipping-address';
import shipmentApi from 'api/shipment';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { FormAddressModel } from '../reducer';
import { getAddressPayload } from './get-payload';

export default async function saveShipment(
  shipment: ShipmentDTO,
  shipmentFormData: {
    project?: DropdownOptionModel;
    zone?: DropdownOptionModel;
    address?: FormAddressModel;
  },
  shipmentFields: {
    showProjectSelect: boolean;
    showZoneSelect: boolean;
    showAddressForm: boolean;
  }
) {
  const { showProjectSelect, showZoneSelect, showAddressForm } = shipmentFields;
  const { address, project, zone } = shipmentFormData;
  const {
    shippingZone,
    project: originalProject,
    ...otherShipmentFields
  } = shipment;
  let addressId;

  if (showAddressForm) {
    const addressPayload = getAddressPayload(address);
    const response = await shippingAddressApi.create(addressPayload);
    addressId = response ? response.data.id : shipment.shippingAddress;
  }

  const payload = {
    ...otherShipmentFields,
    project: showProjectSelect ? project.value : originalProject,
    shippingZone: showZoneSelect ? zone.value : shippingZone,
    shippingAddress: addressId,
  };

  return shipmentApi.update(payload);
}
