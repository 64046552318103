import { PropertySettingModel } from 'models/property-setting';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/property_setting/`;

const propertySettingApi: ApiModel<PropertySettingModel> = getApi(
  endpoint,
  ApiContentType.JSON,
);

export default propertySettingApi;
