import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import Input from 'components/base-components/Input';

interface Props {
  onFilter: (filter) => void;
  loading: boolean;
  defaultValue?: string;
}

const DataTableHeader: FunctionComponent<Props> = (props) => {
  const {
    onFilter,
    loading,
    defaultValue
  } = props;

  const [filter, setFilter] = useState(defaultValue || '');
  const inputRef = useRef(null);
  const onPressEnter = useCallback(() => onFilter(filter), [filter, onFilter]);

  const handleFilter = (event) => {
    setFilter(event.target.value);
    if (event.persist) {
      event.persist();
    }
  };

  useEffect(() => {
    if (!loading) {
      inputRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className="data-table__header">
      <div className="data-table__header__search-input__wrapper">
        <Input
          reference={inputRef}
          placeholder="Search"
          aria-label="Search"
          value={filter}
          onChange={handleFilter}
          size="small"
          disabled={!!loading}
          onPressEnter={onPressEnter}
        />
      </div>
    </div>
  );
};

export default DataTableHeader;
