import { DropdownOptionModel } from 'helpers/dropdown-options';
import { Breakpoints, ParentColumns } from '../reducer';

interface BreakpointsManager{
  [x: string]: boolean;
}

const isParent = (column: string, parentColumns: ParentColumns) => !!parentColumns[column];

const showChildrenBreakPoint = (column: string, fixedColumns: string[]) => fixedColumns.includes(column);

const showParentBreakPoint =
  (column: string, fixedColumns: string[], parentColumns: ParentColumns) => parentColumns[column]
    .filter(item => fixedColumns.includes(item)).length === parentColumns[column].length;

export default function columnsManager(
  fixedColumn: DropdownOptionModel[],
  breakpoints: Breakpoints,
  parentColumns: ParentColumns
): BreakpointsManager {
  const fixedColumnMapped = fixedColumn.map(e => e.value);

  return Object.keys(breakpoints).reduce((breakpointsManaged, column) => {
    const action = isParent(column, parentColumns) ? showParentBreakPoint : showChildrenBreakPoint;
    return {
      ...breakpointsManaged,
      [column]: action(column, fixedColumnMapped, parentColumns),
    };
  }, {} as BreakpointsManager);
}
