import { DropdownOptionModel } from 'helpers/dropdown-options';
import { OrderOption } from 'models/order-option';
import { getOptionPayload } from '../../../AddProduct/state/actions/get-payload';

export function getPayload(formData: {[x: string]: DropdownOptionModel}, response): OrderOption[] {
  const payload = [];
  const formattedCustomPrice = response.reduce((customPrice, option) => ({
    ...customPrice,
    [option.optionGroup]: option.price
  }), {});
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const option in formData) {
    if (formData[option]?.value && !option.includes('sub')) {
      const optionData = formattedCustomPrice[option]
        ? {
          ...formData[option],
          dropdownItem: { ...formData[option]?.dropdownItem, price: formattedCustomPrice[option] }
        }
        : formData[option];
      payload.push(getOptionPayload(optionData, option, undefined, formData[`sub-${option}`]));
    }
  }
  return payload;
}

export function getCustomOptionPricePayload(options) {
  return Object.keys(options).reduce((payload, optionGroup) => {
    const option = options[optionGroup];
    if (option.dropdownItem?.custom) {
      return [
        ...payload,
        {
          optionGroup,
          optionName: option.value,
          cost: option.dropdownItem?.cost
        }
      ];
    }
    return payload;
  }, []);
}
