import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { OrderProductModel } from 'models/order';
import optionGroupsApi from 'api/option-group';
import { resolveApiErrorMessage } from 'api/base';
import useGetList from 'hooks/base/get-list';
import notification, { NotificationType } from 'helpers/notification';
import { setFormValue } from './actions/set-form-value';
import productFormReducer from './reducer';

export default function useProductFormState(product: OrderProductModel, updateProduct) {
  const [state, dispatch] = useReducer(
    productFormReducer,
    {
      formData: {},
    },
  );
  const optionsQueryParams = useMemo(() => ({
    filters: {
      category: product.category
    },
  }), [product]);
  const {
    fetching: fetchingOptionGroups,
    data: productOptions,
    error: optionGroupsError,
  } = useGetList(optionGroupsApi, { queryParams: optionsQueryParams });

  useEffect(() => {
    if (optionGroupsError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(optionGroupsError),
      });
    }
  }, [optionGroupsError]);

  return {
    state: {
      ...state,
      fetchingOptionGroups,
      productOptions,
    },
    actions: {
      setFormValue: useCallback(setFormValue(dispatch, product, updateProduct), []),
    },
  };
}
