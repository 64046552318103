import React, { FunctionComponent } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Checkbox from 'components/base-components/Checkbox';
import Button from 'components/base-components/Button';
import RenderIf from 'components/base-components/RenderIf';
import { Text } from 'components/base-components/Typography';
import ProjectDetails from './ProjectDetails';
import ContactInfo from './ContactInfo';
import AddressInfo from './AddressInfo';
import AnswersForm from './Answers';
import {
  errorScreen,
  loadingScreen,
  headerLabel,
  termsLabel,
  successScreen,
} from './utils';
import useQuestionnaireState from './state';
import './styles.scss';

const DeliveryQuestionnaireForm: FunctionComponent = () => {
  const {
    state: {
      loadingFormData,
      fetchingAccount,
      disableAccount,
      fetchingContact,
      disableContact,
      job,
      accessMethods,
      countries,
      provinces,
      contactInfo,
      addressInfo,
      answers,
      termsAccepted,
      selectedCountry,
      error,
      sendingAnswers,
      answersSent,
      contactErrors,
      addressErrors,
      hasValidationErrors,
    },
    actions: {
      copyContactInfo,
      copyAccountAddress,
      setContactInfo,
      setAnswers,
      setAddressInfo,
      handleCountryChange,
      handleTermsChange,
      handleSendAnswers,
      setContactErrors,
      setAddressErrors,
    },
  } = useQuestionnaireState();

  if (error) {
    return errorScreen;
  }

  if (answersSent) {
    return successScreen;
  }

  if (loadingFormData) {
    return loadingScreen;
  }

  return (
    <div className="page-content questionnaire">
      <div className="container-fluid">
        <Row>
          <Col>
            <Card className="questionnaire__card">
              <CardBody>
                {headerLabel}
                <ProjectDetails job={job} />
                <ContactInfo
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                  contactErrors={contactErrors}
                  setContactErrors={setContactErrors}
                  fetchingContact={fetchingContact}
                  disableContact={disableContact}
                  copyContactInfo={copyContactInfo}
                />
                <AddressInfo
                  addressInfo={addressInfo}
                  setAddressInfo={setAddressInfo}
                  addressErrors={addressErrors}
                  setAddressErrors={setAddressErrors}
                  countries={countries}
                  provinces={provinces}
                  selectedCountry={selectedCountry}
                  handleCountryChange={handleCountryChange}
                  fetchingAccount={fetchingAccount}
                  disableAccount={disableAccount}
                  copyAccountAddress={copyAccountAddress}
                />
                <AnswersForm
                  answers={answers}
                  setAnswers={setAnswers}
                  accessMethods={accessMethods}
                />
                <p className="questionnaire__p centered mT mB">
                  If you have any further questions at this stage,
                  please do not hesitate to contact us. Thank you.
                </p>
                <div className="questionnaire__p centered mT mB">
                  <Checkbox
                    name="termsAccepted"
                    label={termsLabel}
                    value={termsAccepted}
                    onChange={handleTermsChange}
                  />
                </div>
                <RenderIf isTrue={hasValidationErrors}>
                  <div className="questionnaire__error-note">
                    <Text>
                      There are some fields you need to fill before sending
                      the questionnaire
                    </Text>
                  </div>
                </RenderIf>
                <div className="questionnaire__actions">
                  <Button
                    label="Send"
                    type="primary"
                    onClick={handleSendAnswers}
                    disabled={!termsAccepted}
                    loading={sendingAnswers}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DeliveryQuestionnaireForm;
