import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import camelcaseKeys from 'camelcase-keys';
import { CategoryModel } from 'models/category';
import { BuilderCategory, Units } from 'models/builder';
import { OptionGroupModel } from 'models/option-group';
import { FlowPlannerModel } from 'models/flow-planner';
import categoriesApi from 'api/category';
import flowPlannerApi from 'api/flow-planner';
import builderCategoriesApi from 'api/builder-category';
import optionGroupApi, { endpoint as optionGroupEndPoint } from 'api/option-group';
import productApi from 'api/product';
import useCallApiAction from 'hooks/base/call-api-action';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel, useEventCenterUpdate } from 'helpers/event-center';
import { getLoggedUser } from 'helpers/get-logged-user';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import selectCategory from './actions/select-category';
import handleStyleChangeFunction from './actions/handle-style-change';
import selectProduct from './actions/select-product';
import removeProduct from './actions/remove-product';
import updateProperties from './actions/update-properties';
import updateOptions from './actions/update-options';
import updateSceneOptions from './actions/update-scene-options';
import setViewMode from './actions/set-view-mode';
import handleCopyProduct from './actions/handle-copy-products';
import mapTextures from './actions/map-texture';
import setUnit from './actions/handle-unit-cahnge';
import notifyPropertiesToScene from './actions/notify-properties-to-scene';
import handleSelectWall from './actions/handle-select-wall';
import handleSelectFloor from './actions/handle-select-floor';
import handleWallChange from './actions/handle-wall-change';
import handleFloorChange from './actions/handle-floor-change';
import saveFlowPlanner from './actions/on-save-flowplanner';
import handleFlowPlanner from './actions/handle-floor-planer-change';
import openAddToCartForm from './actions/open-add-to-cart';
import builderReducer, { BuilderActions, BuilderState } from './reducer';
import { StyleModel, StyleProviders } from './types';
import { openProductListForm } from './actions/open-product-list';
import { getPayload } from '../ProductList';
import { DESK, HUTCH } from './const';
import normalizeModel from './actions/normalize-model';

export * from './reducer';
export * from './types';

const initialState: BuilderState = {
  products: [],
  selectedProduct: undefined,
  selectedCategory: undefined,
  viewMode: '3d',
  fetchingProductData: false,
  unit: Units.inches
};

export default function useBuilderState(flowPlannerFromNavigation) {
  const { goBack, push } = useHistory();
  const [readOnly, setReadOnly] = useState(false);
  const [sceneLocked, setSceneLocked] = useState(false);
  const [showSaveForm, setShowSaveForm] = useState(false);
  const [showLoadForm, setShowLoadForm] = useState(false);
  const [state, dispatch] = useReducer(builderReducer, { ...initialState, flowPlanner: flowPlannerFromNavigation });
  const user = useMemo(() => getLoggedUser(), []);
  const {
    selectedCategory,
    styleProvider,
    selectedProduct,
    products,
    error,
    flowPlanner
    // viewMode,
  } = state;

  const payload = useMemo(() => ({
    products: getPayload(products)
  }), [products]);

  const {
    fetching: fetchingCategories,
    data: categories,
    error: categoriesError,
  } = useCallApiAction<CategoryModel[]>(categoriesApi.getTopLevel, '', []);

  const {
    fetching: fetchingBuilderCategories,
    data: builderCategories,
    error: builderCategoriesError,
  } = useCallApiAction<BuilderCategory[]>(builderCategoriesApi.getTopLevel, '', []);

  const {
    fetching: fetchingWalls,
    data: walls,
    error: wallsError,
  } = useGetOne<OptionGroupModel>(optionGroupApi, `${optionGroupEndPoint}Walls/`);

  const {
    fetching: fetchingFloors,
    data: floors,
    error: floorsError,
  } = useGetOne<OptionGroupModel>(optionGroupApi, `${optionGroupEndPoint}Floors/`);

  const {
    fetching: fetchingFlowPlanner,
    data: flowPlannerData,
    error: flowPlannerError,
  } = useGetOne<FlowPlannerModel>(flowPlannerApi, flowPlanner?.id, { preventAutoFetch: !flowPlanner?.id });

  const {
    fetching: fetchingProductPrice,
    data: { data: productPrice },
    error: productPriceError,
  } = useCallApiAction<any>(productApi.getPrice, null, { data: [] }, products.length === 0, payload);

  const handleNewProductLoaded = useCallback(
    notifyPropertiesToScene(products),
    [products],
  );

  const addCopiedProducts = useCallback(
    handleCopyProduct(dispatch, selectedProduct, goBack),
    [selectedProduct],
  );

  const handleStyleChange = useCallback(
    handleStyleChangeFunction(dispatch, styleProvider, selectedProduct),
    [styleProvider, selectedProduct],
  );

  const handleOnSaveFlowPlanner = useCallback(
    saveFlowPlanner(dispatch, products, productPrice),
    [products, productPrice]
  );

  const handleUpdateFlowPlanner = useCallback((flowPlannerPayload) => {
    setSceneLocked(user.isStaff ? flowPlannerPayload.staffLocked : !!flowPlannerPayload.quoteId);
    dispatch({
      type: BuilderActions.UPDATE_FLOOR_PLANNER,
      payload: flowPlannerPayload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewSetProduct = useCallback((productsSet) => {
    dispatch({
      type: BuilderActions.ADD_PRODUCTS,
      payload: [{
        ...productsSet[0],
        ...normalizeModel(camelcaseKeys(DESK, {
          deep: true,
          stopPaths: [
            'data.option_calc',
            'data.results.option_calc',
            'data.data.options',
            'data.results.plan',
          ],
        }) as any),
        isFromSet: true,
      }, {
        ...productsSet[1],
        ...normalizeModel(HUTCH as any),
        isFromSet: true,
      },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleShowSaveFlowPlanner = useCallback(() => setShowSaveForm(prevState => !prevState), []);

  useEventCenterUpdate(
    EventChannelList.BUILDER_LOADED_PRODUCT,
    handleNewProductLoaded,
  );

  useEventCenterUpdate(
    EventChannelList.BUILDER_COPY_PRODUCTS,
    addCopiedProducts,
  );
  useEventCenterUpdate(
    EventChannelList.BUILDER_RES_SAVE_JSON,
    handleOnSaveFlowPlanner,
  );
  useEventCenterUpdate(
    EventChannelList.BUILDER_UPDATE_FLOW_PLANNER,
    handleUpdateFlowPlanner,
  );
  useEventCenterUpdate(
    EventChannelList.BUILDER_ADD_NEW_SET_PRODUCT,
    handleNewSetProduct,
  );

  useEffect(() => {
    if (productPriceError) {
      notification({
        type: NotificationType.ERROR,
        message: productPriceError,
      });
    }
  }, [productPriceError]);

  useEffect(() => {
    if (flowPlannerData?.id) {
      dispatch({ type: BuilderActions.SET_FLOOR_PLANNER, payload: flowPlannerData });
      let data = JSON.parse(flowPlannerData.plan);
      notifyEventChannel(EventChannelList.BUILDER_REQ_LOAD_JSON, data);
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      const { items } = data;
      dispatch({
        type: BuilderActions.SET_PRODUCTS,
        payload: items.map(item => ({ ...item.metadata, loaded: true }))
      });
      setSceneLocked(user.isStaff ? flowPlannerData?.staffLocked : !!flowPlannerData?.quoteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowPlannerData]);

  return {
    state: {
      ...state,
      readOnly,
      sceneLocked,
      categories,
      builderCategories,
      showSaveForm,
      showLoadForm,
      fetchingProductPrice,
      fetchingCategories: fetchingBuilderCategories || fetchingCategories,
      floors: useMemo(() => floors ? mapTextures(floors.option) : [], [floors]),
      hiddenAddToCart: useMemo(() => !!flowPlanner?.quoteId || !flowPlanner?.id, [flowPlanner]),
      walls: useMemo(() => walls ? mapTextures(walls.option) : [], [walls]),
      priceCardContent: useMemo(() => ({
        projectName: flowPlanner?.projectName || 'New Project, it\'s not saved',
        type: (!flowPlanner?.id ? 'danger' : undefined) as any,
        title: flowPlanner?.quoteId ? 'Project:' : 'Future Project:',
      }), [flowPlanner]),
      priceCard: useMemo(
        () => (productPrice.reduce((total, instance) => total + instance.totalPrice, 0) as number).toFixed(2),
        [productPrice]
      ),
      styles: useMemo<StyleModel[]>(
        () => (
          styleProvider === StyleProviders.Product
            ? (selectedProduct?.builderOptions?.style as any).styles
            : selectedCategory?.categories
        ),
        [selectedProduct, styleProvider, selectedCategory],
      ),
      error: (
        error ||
        categoriesError ||
        floorsError ||
        wallsError ||
        builderCategoriesError ||
        flowPlannerError
      ),
      loadingStage: fetchingFloors || fetchingWalls || fetchingFlowPlanner,
      hiddenPanelContent: false,
      hiddenAction: useMemo(
        () => user.isStaff ? flowPlanner?.staffLocked : !!flowPlanner?.quoteId,
        [user, flowPlanner]
      ),
    },
    actions: {
      handleStyleChange,
      toggleShowSaveFlowPlanner,
      setSceneLocked,
      toggleShowLoadFlowPlanner: useCallback(() => setShowLoadForm(prevState => !prevState), []),
      selectProduct: useCallback(selectProduct(dispatch), []),
      removeProduct: useCallback(removeProduct(dispatch), []),
      setViewMode: useCallback(setViewMode(dispatch), []),
      onUnitChange: useCallback(setUnit(dispatch), []),
      selectCategory: useCallback(selectCategory(dispatch), []),
      handleSelectWall: useCallback(handleSelectWall(dispatch), []),
      handleSelectFloor: useCallback(handleSelectFloor(dispatch), []),
      handleFlowPlanner: useCallback(handleFlowPlanner(dispatch), []),
      handleWallChange: useCallback(handleWallChange(dispatch), []),
      handleFloorChange: useCallback(handleFloorChange(dispatch), []),
      onImportFromConfiguratorClicked: useCallback(() => push(ModalRouteHash.SetGroupBuilder), [push]),
      openAddToCartForm: useCallback(
        openAddToCartForm(push, flowPlanner, products, productPrice),
        [flowPlanner, products, productPrice]
      ),
      openProductListFrom: useCallback(
        openProductListForm(push, products),
        [push, products],
      ),
      updateProperties: useCallback(
        updateProperties(dispatch, selectedProduct),
        [selectedProduct],
      ),
      updateOptions: useCallback(
        updateOptions(dispatch, selectedProduct),
        [selectedProduct],
      ),
      updateSceneOptions: useCallback(
        updateSceneOptions(dispatch, selectedProduct),
        [selectedProduct],
      ),
      toggleReadOnly: useCallback(() => {
        notifyEventChannel(EventChannelList.BUILDER_SCENE_LOCKED_CHANGE, !readOnly);
        setReadOnly(!readOnly);
      }, [readOnly]),
      onSave: useCallback(() => {
        if (flowPlanner?.id) {
          notifyEventChannel(EventChannelList.BUILDER_REQ_SAVE_JSON, { products, flowPlanner });
        } else {
          toggleShowSaveFlowPlanner();
        }
      }, [flowPlanner, toggleShowSaveFlowPlanner, products]),
    },
  };
}
