import { ThreeMorphFormModel } from '../reducer';

export default function getMorphPayload(
  morph: ThreeMorphFormModel,
  threeModel: string,
) {
  const { property, ...rest } = morph;

  return {
    ...rest,
    threeModel,
    property: property.value,
  };
}
