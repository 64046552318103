import userGroupApi from 'api/user-group';
import notification, { NotificationType } from 'helpers/notification';
import { ViewsPermissionsListActions } from '../reducer';

export default function updateBulkViews(
  dispatch,
  views: any[],
  selectedOrdersKeys: string[],
) {
  return () => {
    dispatch({ type: ViewsPermissionsListActions.START_UPDATING });
    const selectedViews = views.filter(
      (view) => selectedOrdersKeys.some(
        (key) => key === view.groupname
      )
    );

    const onSuccess = () => {
      const message = 'View\'s Permissions Successfully updated';
      notification({ type: NotificationType.SUCCESS, message });
      dispatch({ type: ViewsPermissionsListActions.FINISH_UPDATING });
    };

    const onError = (error) => {
      notification({
        message: error,
        type: NotificationType.ERROR,
      });
      dispatch({ type: ViewsPermissionsListActions.FINISH_UPDATING });
    };

    const postRequests = selectedViews.map(view => userGroupApi.updatePermissions(view));

    return Promise.all(postRequests)
      .then(onSuccess)
      .catch(onError);
  };
}
