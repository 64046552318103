import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import TextEditor from 'components/base-components/TextEditor';
import ChipsInput from 'components/base-components/ChipsInput';
import FileList from 'components/base-components/Upload/Files';
import RenderIf from 'components/base-components/RenderIf';
import Collapse, { Panel } from 'components/base-components/Collapse';
import Spinner from 'components/base-components/Spinner';
import Button from 'components/base-components/Button';
import PopConfirm from 'components/base-components/PopConfirm';
import useSendEmailFormState, { EmailSender } from './state';
import S3Files from './S3Files';
import { validationRules } from './state/validation-rules';
import './styles.scss';

const SendEmailForm: FunctionComponent = () => {
  const { goBack } = useHistory();
  const {
    state: {
      title,
      email,
      errors,
      fetching: fetchingEmailTemplate,
      sendingEmail,
      quoteId,
      emailSender,
      s3Files,
    },
    actions: {
      setRecipients,
      setErrors,
      sendEmail,
      unselectS3Files,
      onUpload
    },
  } = useSendEmailFormState();

  const showFull = (
    emailSender === EmailSender.Quote ||
    emailSender === EmailSender.QuoteProposal ||
    emailSender === EmailSender.PurchaseOrderFromProjectCoordinator
  );

  const confirmContent = useMemo(() => (
    <>
      <p>Have you included all your attachments?</p>
      <ul>
        {email.attachmentCheck.map(item => (<li key={item}>{item}</li>))}
      </ul>
    </>
  ), [email.attachmentCheck]);

  const footer = (
    <div className="send-email__footer">
      <div className="send-email__expand" />
      <Button onClick={goBack} label="Cancel" type="default" />
      <RenderIf isTrue={showFull}>
        <PopConfirm
          title={confirmContent}
          onConfirm={sendEmail}
          okText="Yes"
          cancelText="No"
        >
          <Button label="Send" loading={sendingEmail} />
        </PopConfirm>
      </RenderIf>
      <RenderIf isTrue={!showFull}>
        <Button label="Send" loading={sendingEmail} onClick={sendEmail} />
      </RenderIf>
    </div>
  );

  return (
    <Modal
      visible
      title={title}
      footer={footer}
      onCancel={goBack}
    >
      <Form
        state={email}
        onChange={setRecipients}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <RenderIf isTrue={!fetchingEmailTemplate} fallback={<Spinner />}>
          <RenderIf isTrue={showFull}>
            <Field name="subject" label="Subject" />
          </RenderIf>
          <Field component={ChipsInput} id="recipients" name="recipients" label="Recipients" />
          <RenderIf isTrue={showFull}>
            <Field
              component={TextEditor}
              name="html"
              label="Edit Message"
              loading={fetchingEmailTemplate}
            />
            <FileList
              files={s3Files}
              onUnSelectFile={unselectS3Files}
              byExtraField="path"
            />
            <RenderIf isTrue={quoteId}>
              <Collapse className="collapse-margin">
                <Panel key="s3File" header="S3 Files">
                  <Field
                    component={S3Files}
                    name="s3Attachments"
                    quoteId={quoteId}
                    onUpload={onUpload}
                  />
                </Panel>
              </Collapse>
            </RenderIf>
          </RenderIf>
        </RenderIf>
      </Form>
    </Modal>
  );
};

export default SendEmailForm;
