import { BuilderPropertyType } from 'models/builder';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { BuilderFormModel, OptionDropdownModel } from '../types';

export default function notifyPropertiesToScene(products: BuilderFormModel[]) {
  return (productId: string) => {
    const product = products.find((prod) => prod.id === productId);
    const { modelDescription, builderOptions, options } = product;

    const rangeProperties = (
      Object.keys(modelDescription)
        .filter((property) => {
          const { propertyType: type } = (builderOptions[property] as any);
          return type === BuilderPropertyType.RANGE;
        })
    );

    rangeProperties.forEach((prop) => {
      const {
        morphLabel,
        morphIndex,
      } = (builderOptions[prop] as any);
      const updatedValue = modelDescription[prop];

      notifyEventChannel(EventChannelList.BUILDER_PRODUCT_PROPERTY_CHANGED, {
        name: prop,
        value: updatedValue,
        morphLabel,
        morphIndex,
      });
    });

    Object.values(options)
      .forEach((option) => {
        const {
          value,
          textureHeight,
          textureWidth,
          image,
          nameInModel,
          group: {
            nameInModel: groupNameInModel,
            mode,
          }
        } = option as OptionDropdownModel;

        notifyEventChannel(EventChannelList.BUILDER_PRODUCT_OPTION_CHANGED, {
          name: value,
          mode,
          image,
          value,
          nameInModel,
          textureWidth,
          textureHeight,
          groupNameInModel,
        });
      });
  };
}
