import { resolveApiErrorMessage } from 'api/base';
import { notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { FormActions } from '../reducer';
import { SubmitFormParams } from './handle-on-submit';

export function saveEntity(params: SubmitFormParams) {
  const {
    dispatch,
    api,
    formData,
    eventChannel,
    getPayload,
    goBack,
    onSaveSuccess,
    noGoBackOnSuccess,
    clearOnSuccess,
    pK,
    replaceAction,
    getFormData,
  } = params;
  dispatch({ type: FormActions.START_SAVING });
  const payload = getPayload(formData);
  const isNew = !(!!formData.url || !!formData.id || !!formData[pK]);
  let action = isNew ? api.create : api.update;
  if (replaceAction) {
    action = replaceAction;
  }

  const onSuccess = (response) => {
    notifyEventChannel(eventChannel, response);
    const message = isNew ? 'Successfully created' : 'Successfully updated';
    notification({ type: NotificationType.SUCCESS, message });
    if (clearOnSuccess) {
      const clearPayload = !!getFormData ? getFormData() : {};
      dispatch({ type: FormActions.CLEAR_STATE, payload: clearPayload });
    }
    if (!noGoBackOnSuccess) {
      goBack();
    }
    if (onSaveSuccess) {
      onSaveSuccess(response);
    }
  };

  const onError = (error) => {
    const message = resolveApiErrorMessage((error as any).response);
    notification({ type: NotificationType.ERROR, message });
  };

  const onFinally = () => {
    dispatch({ type: FormActions.FINISH_SAVING });
  };

  return action(payload)
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
}
