import { ManufacturerModel } from 'models/manufacturer';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/manufacturer/`;

const manufacturerApi: ApiModel<ManufacturerModel> = {
  ...getApi<ManufacturerModel>(endpoint, ApiContentType.MULTIPART),
};

export default manufacturerApi;
