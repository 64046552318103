import { TaskFormModel } from '../reducer';

export default function getPayload(form: TaskFormModel, { priority }) {
  return {
    ...form,
    priority: priority + 1,
    startingStage: form.startingStage.value,
    endingStage: form.endingStage.value,
  };
}
