/* eslint-disable */
import Core from "../core";

import Wall from "./wall";
import Corner from "./corner";
import Room from "./room";
import HalfEdge from "./half_edge";

import * as THREE from "three";
import { unitScale } from "../core/dimensioning";

const defaultFloorPlanTolerance = 0.05;

/**
 * A Floorplan represents a number of Walls, Corners and Rooms.
 */
export default class Floorplan {
  scene = null;
  /** */
  walls = [];

  /** */
  corners = [];

  /** */
  rooms = [];

  /** */
  new_wall_callbacks = [];

  /** */
  new_corner_callbacks = [];

  /** */
  redraw_callbacks = [];

  /** */
  updated_rooms = [];

  /** */
  roomLoadedCallbacks = [];

  /**
   * Floor textures are owned by the floorplan, because room objects are
   * destroyed and created each time we change the floorplan.
   * floorTextures is a map of room UUIDs (string) to a object with
   * url and scale attributes.
   */
  floorTextures = {};

  /** Constructs a floorplan. */
  // constructor() { }

  // hack
  wallEdges() {
    var edges = [];

    this.walls.forEach((wall) => {
      if (wall.frontEdge) {
        edges.push(wall.frontEdge);
      }
      if (wall.backEdge) {
        edges.push(wall.backEdge);
      }
    });
    return edges;
  }

  // hack
  wallEdgePlanes() {
    const planes = [];
    this.walls.forEach((wall) => {
      if (wall.frontEdge) {
        planes.push(wall.frontEdge.plane);
      }
      if (wall.backEdge) {
        planes.push(wall.backEdge.plane);
      }
    });
    return planes;
  }

  floorPlanes() {
    return Core.Utils.map(this.rooms, (room) => {
      return room.floorPlane;
    });
  }

  fireOnNewWall(callback) {
    this.new_wall_callbacks.push(callback);
  }

  fireOnNewCorner(callback) {
    this.new_corner_callbacks.push(callback);
  }

  fireOnRedraw(callback) {
    this.redraw_callbacks.push(callback);
  }

  fireOnUpdatedRooms(callback) {
    this.updated_rooms.push(callback);
  }

  /**
   * Creates a new wall.
   * @param start The start corner.
   * @param end he end corner.
   * @returns The new wall.
   */
  newWall(start, end) {
    var wall = new Wall(start, end);
    this.walls.push(wall);
    var scope = this;
    wall.fireOnDelete(() => {
      scope.removeWall(wall);
    });
    // this.new_wall_callbacks.fire(wall);
    this.new_wall_callbacks.forEach(
      (cb) => typeof cb === "function" && cb(wall)
    );
    this.update();
    return wall;
  }

  /** Removes a wall.
   * @param wall The wall to be removed.
   */
  removeWall(wall) {
    Core.Utils.removeValue(this.walls, wall);
    this.update();
  }

  /**
   * Creates a new corner.
   * @param x The x coordinate.
   * @param y The y coordinate.
   * @param id An optional id. If unspecified, the id will be created internally.
   * @returns The new corner.
   */
  newCorner(x, y, id) {
    var corner = new Corner(this, x, y, id);
    this.corners.push(corner);
    corner.fireOnDelete(this.removeCorner);
    // this.new_corner_callbacks.fire(corner);

    this.new_corner_callbacks.forEach(
      (cb) => typeof cb === "function" && cb(corner)
    );
    return corner;
  }

  /** Removes a corner.
   * @param corner The corner to be removed.
   */
  removeCorner = (corner) => {
    Core.Utils.removeValue(this.corners, corner);
  };

  /** Gets the walls. */
  getWalls() {
    return this.walls;
  }

  getItems() {
    return this.scene.items;
  }

  /** Gets the corners. */
  getCorners() {
    return this.corners;
  }

  /** Gets the rooms. */
  getRooms() {
    return this.rooms;
  }

  overlappedCorner(x, y, tolerance) {
    tolerance = tolerance || defaultFloorPlanTolerance;
    for (let i = 0; i < this.corners.length; i++) {
      if (this.corners[i].distanceFrom(x, y) < tolerance) {
        return this.corners[i];
      }
    }
    return null;
  }

  overlappedWall(x, y, tolerance) {
    tolerance = tolerance || defaultFloorPlanTolerance;
    for (let i = 0; i < this.walls.length; i++) {
      if (this.walls[i].distanceFrom(x, y) < tolerance) {
        return this.walls[i];
      }
    }
    return null;
  }

  overlappedItem(x, y) {
    const items = this.getItems();
    let res = null;
    for (const item of items) {
      const hullPointsCollection = item.getSnapPoints();
      let isOverlapped = false;
      for (const corners of hullPointsCollection) {
        if (Core.Utils.pointInPolygon(x, y, corners)) {
          isOverlapped = true;
          break;
        }
      }
      if (isOverlapped) {
        res = item;
        break;
      }
    }
    return res;
  }

  calculateRulerData() {
    const corners = [
      ...this.getCorners().map((corner) => {
        return { x: corner.x, y: corner.y };
      }),
    ];
    this.getItems().forEach((item) => corners.push(...item.getCorners()));
    let xRulers = [];
    let yRulers = [];
    for (var i = 0; i < corners.length; i++) {
      let exist = false;
      for (const corner of xRulers) {
        if (corner.x === corners[i].x) {
          exist = true;
          break;
        }
      }
      !exist && xRulers.push(corners[i]);

      exist = false;
      for (const corner of yRulers) {
        if (corner.y === corners[i].y) {
          exist = true;
          break;
        }
      }
      !exist && yRulers.push(corners[i]);
    }

    xRulers = xRulers.sort((a, b) => a.x - b.x);
    yRulers = yRulers.sort((a, b) => a.y - b.y);

    const rulerData = { x: [], y: [] };
    for (var i = 0; i < xRulers.length - 1; i++) {
      rulerData.x.push({
        start: xRulers[i],
        end: xRulers[i + 1],
        length: Math.abs(xRulers[i].x - xRulers[i + 1].x),
      });
    }
    for (var i = 0; i < yRulers.length - 1; i++) {
      rulerData.y.push({
        start: yRulers[i],
        end: yRulers[i + 1],
        length: Math.abs(yRulers[i].y - yRulers[i + 1].y),
      });
    }
    return rulerData;
  }

  // import and export -- cleanup

  saveFloorplan() {
    const floorplan = {
      corners: {},
      walls: [],
      wallTextures: [],
      floorTextures: {},
      newFloorTextures: {},
    };

    this.corners.forEach((corner) => {
      floorplan.corners[corner.id] = {
        x: corner.x,
        y: corner.y,
      };
    });

    this.walls.forEach((wall) => {
      floorplan.walls.push({
        corner1: wall.getStart().id,
        corner2: wall.getEnd().id,
        frontTexture: wall.frontTexture,
        backTexture: wall.backTexture,
      });
    });
    floorplan.newFloorTextures = this.floorTextures;
    return floorplan;
  }

  loadFloorplan(floorplan) {
    this.reset();

    const corners = {};
    if (
      floorplan === null ||
      !("corners" in floorplan) ||
      !("walls" in floorplan)
    ) {
      return;
    }
    for (let id in floorplan.corners) {
      const corner = floorplan.corners[id];
      corners[id] = this.newCorner(
        corner.x / unitScale,
        corner.y / unitScale,
        id
      );
    }
    const scope = this;
    floorplan.walls.forEach((wall) => {
      const newWall = scope.newWall(
        corners[wall.corner1],
        corners[wall.corner2]
      );
      if (wall.frontTexture) {
        newWall.frontTexture = wall.frontTexture;
      }
      if (wall.backTexture) {
        newWall.backTexture = wall.backTexture;
      }
    });

    if ("newFloorTextures" in floorplan) {
      this.floorTextures = floorplan.newFloorTextures;
    }

    this.update();
    // this.roomLoadedCallbacks.fire();
    this.roomLoadedCallbacks.forEach((cb) => typeof cb === "function" && cb());
  }

  getFloorTexture(uuid) {
    if (uuid in this.floorTextures) {
      return this.floorTextures[uuid];
    } else {
      return null;
    }
  }

  setFloorTexture(uuid, url, scale, textureWidth, textureHeight) {
    this.floorTextures[uuid] = {
      url: url,
      scale: scale,
      width: textureWidth,
      height: textureHeight,
    };
  }

  /** clear out obsolete floor textures */
  updateFloorTextures() {
    const uuids = Core.Utils.map(this.rooms, function (room) {
      return room.getUuid();
    });
    for (let uuid in this.floorTextures) {
      if (!Core.Utils.hasValue(uuids, uuid)) {
        delete this.floorTextures[uuid];
      }
    }
  }

  /** */
  reset() {
    const tmpCorners = this.corners.slice(0);
    const tmpWalls = this.walls.slice(0);
    tmpCorners.forEach((corner) => {
      corner.remove();
    });
    tmpWalls.forEach((wall) => {
      wall.remove();
    });
    this.corners = [];
    this.walls = [];
  }

  /**
   * Update rooms
   */
  update() {
    this.walls.forEach((wall) => {
      wall.resetFrontBack();
    });

    const roomCorners = this.findRooms(this.corners);
    this.rooms = [];
    const scope = this;
    roomCorners.forEach((corners) => {
      scope.rooms.push(new Room(scope, corners));
    });
    this.assignOrphanEdges();

    this.updateFloorTextures();
    // this.updated_rooms.fire();
    this.updated_rooms.forEach((cb) => typeof cb === "function" && cb());
  }

  /**
   * Returns the center of the floorplan in the y plane
   */
  getCenter() {
    return this.getDimensions(true);
  }

  getSize() {
    return this.getDimensions(false);
  }

  getDimensions(center) {
    center = center || false; // otherwise, get size

    let xMin = Infinity;
    let xMax = -Infinity;
    let zMin = Infinity;
    let zMax = -Infinity;
    this.corners.forEach((corner) => {
      if (corner.x < xMin) xMin = corner.x;
      if (corner.x > xMax) xMax = corner.x;
      if (corner.y < zMin) zMin = corner.y;
      if (corner.y > zMax) zMax = corner.y;
    });
    let ret;
    if (
      xMin === Infinity ||
      xMax === -Infinity ||
      zMin === Infinity ||
      zMax === -Infinity
    ) {
      ret = new THREE.Vector3();
    } else {
      if (center) {
        // center
        ret = new THREE.Vector3((xMin + xMax) * 0.5, 0, (zMin + zMax) * 0.5);
      } else {
        // size
        ret = new THREE.Vector3(xMax - xMin, 0, zMax - zMin);
      }
    }
    return ret;
  }

  assignOrphanEdges() {
    // kinda hacky
    // find orphaned wall segments (i.e. not part of rooms) and
    // give them edges
    const orphanWalls = [];
    this.walls.forEach((wall) => {
      if (!wall.backEdge && !wall.frontEdge) {
        wall.orphan = true;
        const back = new HalfEdge(null, wall, false);
        back.generatePlane();
        const front = new HalfEdge(null, wall, true);
        front.generatePlane();
        orphanWalls.push(wall);
      }
    });
  }

  /*
   * Find the "rooms" in our planar straight-line graph.
   * Rooms are set of the smallest (by area) possible cycles in this graph.
   * @param corners The corners of the floorplan.
   * @returns The rooms, each room as an array of corners.
   */
  findRooms(corners) {
    function _calculateTheta(previousCorner, currentCorner, nextCorner) {
      return Core.Utils.angle2pi(
        previousCorner.x - currentCorner.x,
        previousCorner.y - currentCorner.y,
        nextCorner.x - currentCorner.x,
        nextCorner.y - currentCorner.y
      );
    }

    function _removeDuplicateRooms(roomArray) {
      const results = [];
      const lookup = {};
      const hashFunc = function (corner) {
        return corner.id;
      };
      const sep = "-";
      for (let i = 0; i < roomArray.length; i++) {
        // rooms are cycles, shift it around to check uniqueness
        let add = true;
        const room = roomArray[i];
        for (let j = 0; j < room.length; j++) {
          const roomShift = Core.Utils.cycle(room, j);
          var str = Core.Utils.map(roomShift, hashFunc).join(sep);
          if (lookup.hasOwnProperty(str)) {
            add = false;
          }
        }
        if (add) {
          results.push(roomArray[i]);
          lookup[str] = true;
        }
      }
      return results;
    }

    function _findTightestCycle(firstCorner, secondCorner) {
      const stack = [];

      let next = {
        corner: secondCorner,
        previousCorners: [firstCorner],
      };
      const visited = {};
      visited[firstCorner.id] = true;

      while (next) {
        // update previous corners, current corner, and visited corners
        const currentCorner = next.corner;
        visited[currentCorner.id] = true;

        // did we make it back to the startCorner?
        if (next.corner === firstCorner && currentCorner !== secondCorner) {
          return next.previousCorners;
        }

        const addToStack = [];
        const adjacentCorners = next.corner.adjacentCorners();
        for (let i = 0; i < adjacentCorners.length; i++) {
          const nextCorner = adjacentCorners[i];

          // is this where we came from?
          // give an exception if its the first corner and we aren't at the second corner
          if (
            nextCorner.id in visited &&
            !(nextCorner === firstCorner && currentCorner !== secondCorner)
          ) {
            continue;
          }

          // nope, throw it on the queue
          addToStack.push(nextCorner);
        }

        const previousCorners = next.previousCorners.slice(0);
        previousCorners.push(currentCorner);
        if (addToStack.length > 1) {
          // visit the ones with smallest theta first
          const previousCorner =
            next.previousCorners[next.previousCorners.length - 1];
          addToStack.sort(function (a, b) {
            return (
              _calculateTheta(previousCorner, currentCorner, b) -
              _calculateTheta(previousCorner, currentCorner, a)
            );
          });
        }

        if (addToStack.length > 0) {
          // add to the stack
          addToStack.forEach((corner) => {
            stack.push({
              corner: corner,
              previousCorners: previousCorners,
            });
          });
        }

        // pop off the next one
        next = stack.pop();
      }
      return [];
    }

    // find tightest loops, for each corner, for each adjacent
    // TODO: optimize this, only check corners with > 2 adjacents, or isolated cycles
    const loops = [];

    corners.forEach((firstCorner) => {
      firstCorner.adjacentCorners().forEach((secondCorner) => {
        loops.push(_findTightestCycle(firstCorner, secondCorner));
      });
    });

    // remove duplicates
    const uniqueLoops = _removeDuplicateRooms(loops);
    //remove CW loops
    return Core.Utils.removeIf(uniqueLoops, Core.Utils.isClockwise);
  }
}
