import paymentApi from 'api/payment';
import orderApi from 'api/orders';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { validateEntity } from 'helpers/form-validations';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { PaymentFormActions, PaymentFormModel } from '../reducer';
import { paymentRules } from '../validation-rules';
import getPayload from './get-payload';
import { DataTransaction } from '..';

export default function savePayment(
  dispatch,
  data: DataTransaction,
  payment: PaymentFormModel,
  goBack,
) {
  return () => {
    dispatch({ type: PaymentFormActions.START_SAVING_PAYMENT });

    const { hasErrors, errors } = validateEntity(payment, paymentRules);

    if (hasErrors) {
      dispatch({ type: PaymentFormActions.SET_ERRORS, payload: errors });
      return;
    }

    const onSuccess = () => {
      notification({
        type: NotificationType.SUCCESS,
        message: 'Payment processed.',
      });
      notifyEventChannel(EventChannelList.ORDER_PAYMENT_DONE);
      goBack();
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const onFinally = () => {
      dispatch({ type: PaymentFormActions.FINISH_SAVING_PAYMENT });
    };

    const { orderId } = data;

    // eslint-disable-next-line consistent-return
    return orderApi.get(orderId)
      .then(
        resp => paymentApi.create(getPayload(payment, resp.data.quote.project.companyInfo.businessTradeName, orderId))
      )
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
