import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { AccountApplicationModel } from 'models/account-application';
import accountApplicationApi from 'api/account-application';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import RenderIf from 'components/base-components/RenderIf';
import Details from './Details';
import { AccountApplicationHistorySateModel } from '../List';

const AccountApplicationDetails: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const {
    accountApplication: {
      accountApplicationUrl,
    },
  } = state as AccountApplicationHistorySateModel;

  const {
    fetching,
    error,
    data: accountApplication,
  } = useGetOne<AccountApplicationModel>(accountApplicationApi, accountApplicationUrl);

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: error,
      });
    }
  }, [error]);

  return (
    <Modal
      title="Details"
      visible
      onCancel={goBack}
      footer={null}
    >
      <RenderIf isTrue={!fetching} fallback={<Spinner />}>
        <Card>
          <CardBody>
            <div className="d-flex justify-content-start align-items-end mb-3">
              <div>
                <CardTitle>Account Application Details</CardTitle>
                <CardSubtitle>
                  {accountApplication ? accountApplication.businessTradeName : ''}
                </CardSubtitle>
              </div>
            </div>
            <Details accountApplication={accountApplication} />
          </CardBody>
        </Card>
      </RenderIf>
    </Modal>
  );
};

export default AccountApplicationDetails;
