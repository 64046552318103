import moment from 'moment';
import getEventValue from 'helpers/get-event-value';
import { Filters, GroupBy } from '../types';

export default function handleGroupByChange(event, setFields, filters: Filters) {
  const { startDate, endDate } = filters;
  const groupBy = getEventValue(event);
  let fields: Filters = { groupBy };

  const isMissingDateRange = !startDate || !endDate;
  const isGroupByDate = (
    groupBy && (
      groupBy.value === GroupBy.MONTH ||
      groupBy.value === GroupBy.QUARTER ||
      groupBy.value === GroupBy.YEAR
    )
  );

  if (isGroupByDate && isMissingDateRange) {
    fields = {
      groupBy,
      startDate: moment().startOf('year'),
      endDate: moment().endOf('year'),
    };
  }

  setFields(fields);
}
