import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import RenderIf from 'components/base-components/RenderIf';
import Upload from 'components/base-components/Upload';
import useTermFormState, { validationRules } from './state';
import './styles.scss';

const TutorialForm: FunctionComponent = () => {
  const { goBack } = useHistory();

  const {
    state: {
      fetchingFormData,
      tutorial,
      savingTutorial,
      errors,
    },
    actions: {
      handleFileChange,
      setModel,
      saveThreeModel,
      setErrors,
    },
  } = useTermFormState();

  const modalTitle = useMemo(() => (
    tutorial.id ? 'Edit the tutorial video' : 'Upload a tutorial video'
  ), [tutorial]);

  return (
    <Modal
      visible
      okText="Submit"
      title={modalTitle}
      onCancel={goBack}
      onOk={saveThreeModel}
      confirmLoading={savingTutorial}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={tutorial}
          onChange={setModel}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field name="videoName" label="Name" />
          <Field
            id="video"
            name="video"
            label="Video file"
            component={Upload}
            accept=".mp4"
            onChange={handleFileChange}
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default TutorialForm;
