import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Col, Row } from 'antd';
import subOptionApi from 'api/sub-option';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import Checkbox, { CheckboxGroup } from 'components/base-components/Checkbox';
import RenderIf from 'components/base-components/RenderIf';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import useApplyOptions from './state';
import './style.scss';

export interface ApplyOptionsHistorySateModel {
  applyOptionsForm: {
    groups: unknown[];
    groupCategories: {[x: string]: string[]};
    quoteId: number;
  };
}

const optionMapDropdown = {
  fields: {
    value: 'name',
    label: 'name',
  },
  copyFullItem: true,
};

const mapOptions = (options) => {
  return mapGenericDropdownOptions(options, optionMapDropdown);
};

export default function ApplyOptions() {
  const { goBack, location: { state } } = useHistory();
  const { applyOptionsForm } = state as ApplyOptionsHistorySateModel;
  const {
    state: {
      editing,
      fetchingOptionGroups,
      checkAll,
      formData,
      checkedGroups,
      groups,
      indeterminate,
      productOptions,
    },
    actions: {
      handleValueChange,
      handleOnSubmit,
      handleCheckAll,
      handleCheckedGroups,
      openCustomOptionModal
    },
  } = useApplyOptions(applyOptionsForm);

  const productOptionsSelect = useMemo(() => (
    productOptions && productOptions.map(productOption => {
      const hasSubOptions = formData[productOption.name]?.dropdownItem?.haveSuboptions;
      const filters = {
        filters: {
          option__name: formData[productOption.name]?.value,
        }
      };
      return (
        <>
          <Field
            key={productOption.name}
            component={Select}
            name={productOption.name}
            id={productOption.name}
            label={productOption.name}
            options={mapOptions(productOption.option)}
            addButtonLabel="Add custom option"
            onAddClick={() => openCustomOptionModal(productOption.name)}
            checkNewValue
          />
          <RenderIf isTrue={hasSubOptions}>
            <Field
              name={`sub-${productOption.name}`}
              id={`sub-${productOption.name}`}
              label={`${formData[productOption.name]?.label} Options`}
              component={AsyncSelectV2}
              listAction={subOptionApi.list}
              mapFunction={mapOptions}
              preventAutoFetch={!hasSubOptions}
              filters={filters}
            />
          </RenderIf>
        </>
      );
    })
  ), [productOptions, formData, openCustomOptionModal]);

  return (
    <Modal
      title="Apply Options"
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={handleOnSubmit}
      confirmLoading={editing}
    >
      <Row>
        <Col className="apply-option__checkbox-group" xs={7}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={handleCheckAll}
            value={checkAll}
            id="checkAll"
            label="Check all"
          />
          <CheckboxGroup
            options={groups}
            value={checkedGroups}
            onChange={handleCheckedGroups}
          />
        </Col>
        <Col xs={16}>
          <Form state={formData} onChange={handleValueChange}>
            <RenderIf isTrue={!fetchingOptionGroups} fallback={<Spinner centered />}>
              {productOptionsSelect}
            </RenderIf>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}
