import { AxiosResponse } from 'axios';
import { BuilderModel } from 'models/builder';
import builderApi from 'api/builder';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import normalizeModel from './normalize-model';
import normalizeSwapModel from './normalize-swap-model';
import { BuilderFormModel, StyleModel, StyleProviders } from '../types';
import { BuilderActions } from '../reducer';

export default function handleStyleChange(
  dispatch,
  styleProvider: StyleProviders,
  prevProduct: BuilderFormModel,
) {
  return (style: StyleModel) => {
    dispatch({ type: BuilderActions.START_FETCHING_PRODUCT });

    const onSuccess = (response: AxiosResponse<BuilderModel>) => {
      const isUpdating = styleProvider === StyleProviders.Product;
      const nextProduct = normalizeModel(response.data);
      const payload = isUpdating
        ? normalizeSwapModel(prevProduct, nextProduct)
        : nextProduct;
      const action = isUpdating
        ? BuilderActions.SWAP_PRODUCT
        : BuilderActions.ADD_PRODUCT;

      if (style.initialData) payload.initialData = style.initialData;
      if (!!payload.threeModel) {
        dispatch({ type: action, payload });

        if (isUpdating) {
          notifyEventChannel(
            EventChannelList.BUILDER_SELECTED_PRODUCT_SWAPPED,
            payload,
          );
        }
      } else {
        notification({
          type: NotificationType.ERROR,
          message: 'This model isn\'t currently available',
        });
        dispatch({ type: BuilderActions.STOP_FETCHING_PRODUCT });
      }
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
      dispatch({ type: BuilderActions.STOP_FETCHING_PRODUCT });
    };
    const action = style.url?.includes('builder-category') ? builderApi.getDetails(style.url) : builderApi.getDetailsByModel(style.name);

    return action
      .then(onSuccess)
      .catch(onError);
  };
}
