import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { OrderProductModel } from 'models/order';
import orderProductApi from 'api/order-product';

export async function deleteEntry(entryToDelete: OrderProductModel) {
  notifyEventChannel(EventChannelList.DATATABLE_DELETING, true);

  try {
    await orderProductApi.delete(entryToDelete.id);
    const message = 'Product successfully deleted.';
    notification({ type: NotificationType.SUCCESS, message });
    notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED);
  } catch (error) {
    const message = resolveApiErrorMessage((error as any)?.response);
    notification({ type: NotificationType.ERROR, message });
  } finally {
    notifyEventChannel(EventChannelList.DATATABLE_DELETING, false);
  }
}
