import { useEffect, useMemo } from 'react';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import userApi from 'api/user';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { UserModel } from 'models/user';
import getValidationRules from './validations';
import getPayload from './actions/get-payload';
import getFormData from './actions/get-form-data';

export function mapOptions(data: any[]) {
  return mapGenericDropdownOptions(data, { fields: { value: 'url', label: 'name' } });
}

export default function useUserFormState(userUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: userApi,
    eventChannel: EventChannelList.USERS_LIST_CHANGED,
    param: userUrl,
    getPayload,
    getFormData,
    validationRules: getValidationRules(!userUrl),
  }), [userUrl]);

  const {
    state: {
      formData: user,
      errors,
      saving,
      errorFetchingEntity: errorFetchingUser,
      fetchingEntity: fetchingUser,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<UserModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingUser) {
      notification({ type: NotificationType.ERROR, message: errorFetchingUser });
    }
  }, [errorFetchingUser]);

  return {
    state: {
      user,
      savingUser: saving,
      errors,
      fetchingFormData: fetchingUser,
    },
    actions: {
      setFormValue,
      setErrors,
      saveUser: saveEntity,
    },
  };
}
