import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useGetOne from 'hooks/base/get-one';
import { ProductModel } from 'models/product';
import productApi from 'api/product';
import notification, { NotificationType } from 'helpers/notification';
import ShowDetails, { emptyDescription } from 'components/forms/showDetails';
import { ProductHistorySateModel } from '.';

const ProductDetails: FunctionComponent = () => {
  const { location: { state } } = useHistory();

  const { product: { productUrl } } = state as ProductHistorySateModel;

  const { fetching, error, data: product } = useGetOne<ProductModel>(productApi, productUrl);

  useEffect(() => {
    if (error) {
      notification({ type: NotificationType.ERROR, message: error });
    }
  }, [error]);

  const image = useMemo(() => product ? product.image : '', [product]);

  const title = useMemo(() => product ? product.name : 'Product', [product]);

  const description = useMemo(() => product ? JSON.parse(product.description.replace(/'/g, '"')) : emptyDescription, [product]);

  return (
    <ShowDetails
      description={description}
      fetching={fetching}
      image={image}
      title={title}
    />
  );
};

export default ProductDetails;
