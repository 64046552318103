/* eslint-disable */
export default class FloorContextMenu {
  container = null;
  dimensionInput = null;
  btnDelete = null;

  visible = false;
  floor = null;

  texturePresets = [];

  events = {};

  constructor(parent, main) {
    this.main = main;
    this.container = document.createElement('div');
    this.container.setAttribute('class', 'custom-context-menu');

    let tmp = document.createElement('div');
    tmp.setAttribute('class', 'item-container');
    tmp.setAttribute('style', 'display: flex;');

    this.selectTexture = document.createElement('select');
    this.selectTexture.insertAdjacentHTML('beforeend', '<option>asdf</option><option>qwer</option>');
    this.selectTexture.addEventListener('change', e => {
      const index = e.target.value;
      if (index < this.texturePresets.length && this.floor) {
        const texture = this.texturePresets[index];
        console.log(texture)
        const room = this.floor;
        room.setTexture(texture.image, true, 1, (texture.textureWidth || 1) / 100, (texture.textureHeight || 1) / 100);
        this.main.needsUpdate();
      }
    })
    tmp.appendChild(this.selectTexture);
    this.container.appendChild(tmp);

    parent.appendChild(this.container);

    this.setVisible(false);

    this.setTexturePresets([]);
  }

  setVisible(visible) {
    this.visible = visible;
    this.container.style.display = visible ? 'inherit' : 'none';
  }

  setFloor = floor => this.floor = floor;

  setPosition(x, y) {
    this.container.style.left = `${x}px`;
    this.container.style.top = `${y}px`;
  }

  setTexturePresets(presets) {
    if (!Array.isArray(presets)) return;
    this.texturePresets = presets;
    this.selectTexture.innerHTML = '';
    let str = '';
    for (var [i, item] of presets.entries()) {
      str += `<option value=${i}>${item.name}</option>`;
    }
    this.selectTexture.innerHTML = str;
  }
}