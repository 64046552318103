import { flatDropdownOption } from 'helpers/dropdown-options';
import { formatDateToString } from 'helpers/formatting';
import { PurchaseOrderItemFormModel } from '..';

export default function getPayload(purchaseOrderItem: PurchaseOrderItemFormModel) {
  const { orderProduct, quantity, partName, mpn, sentToPaint, ...rest } = purchaseOrderItem;
  return {
    ...rest,
    orderProduct: flatDropdownOption(orderProduct),
    quantity: parseFloat(quantity.toString(10)),
    partName,
    mpn: mpn || partName,
    sentToPaint: formatDateToString(sentToPaint),
  };
}
