import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import Page, { PageActions } from 'components/base-components/Page';
import Button from 'components/base-components/Button';
import Help from 'components/forms/CommonHelp';
import AccessControl from 'components/base-components/AccessControl';
import PODataTable from './PODataTable';
import usePOItemsListState from './state';
import { permissions } from './permissions';

const POItemsList: FunctionComponent = () => {
  const { id: purchaseOrderId } = useParams<any>();

  const {
    state: {
      fetchingManufacturer,
      manufacturer,
      purchaseOrder
    },
    actions: {
      openFormModal,
      printPDF,
    }
  } = usePOItemsListState(purchaseOrderId);

  return (
    <Page title={`Purchase Order ${purchaseOrderId}`}>
      <Help view="purchase-order-items" title="Purchase Order Details" />
      <PageActions primary>
        <AccessControl permissions={permissions.addPOItem}>
          <Button
            onClick={openFormModal}
            label="Create Purchase Order Item"
          />
        </AccessControl>
        <Button
          loading={fetchingManufacturer}
          disabled={!manufacturer?.companyName}
          onClick={printPDF}
          icon="fa fa-print"
          label="Print"
        />
      </PageActions>
      <PODataTable
        purchaseOrderId={purchaseOrderId}
        fetchingPurchaseOrder={fetchingManufacturer}
        purchaseOrder={purchaseOrder}
      />
    </Page>
  );
};

export default POItemsList;
