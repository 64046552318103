import productionManagerApi from 'api/production-manger';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function printShopOrder(orderId) {
  productionManagerApi.printShop(orderId)
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}
