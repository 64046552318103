import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { QuoteDTO } from 'models/quote';
import useHiddenColumnsHandler from 'hooks/ui/hidden-columns-handler';
import Button from 'components/base-components/Button';
import DataTable, {
  DataTableAction,
  DataTableColumn,
} from 'components/base-components/DataTableV2';
import Help from 'components/forms/CommonHelp';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import Switch from 'components/base-components/Switch';
import Select from 'components/base-components/Select';
import customerTemplate from './templates/customer-template';
import statusTemplate from './templates/status-template';
import jobTitleTemplate from './templates/job-title-template';
import useQuoteListState from './state';
import { permissions } from './permissions';
import './styles.scss';
import { breakpoints, columnOptions, parentColumns } from './constants';

const hideIfConverted = (quote: QuoteDTO) => quote.isconverted;

const QuotesList: FunctionComponent = () => {
  const { push } = useHistory();

  const {
    state: {
      data: quotes,
      fetching,
      count,
      page,
      isPrinting,
      showAll,
      isDealer,
      defaultFilter
    },
    actions: {
      hiddenAction,
      handleDeleteRow,
      handleFilter,
      handleOnChange,
      openCopyQuoteModal,
      openSendEmail,
      openSendProposalEmail,
      printQuote,
      printQuoteProposal,
      toggleShowAll,
    }
  } = useQuoteListState();

  const goToCreateQuote = () => {
    push('quote');
  };

  const goToQuoteDetails = useCallback((quote: QuoteDTO) => {
    push(`/quote/${quote.id}`);
  }, [push]);

  const hideIfConvertedAndDealer = useCallback((quote: QuoteDTO) => quote.isconverted || !!isDealer, [isDealer]);

  const {
    breakpoint,
    handleHiddenColumns,
    hiddenColumns,
  } = useHiddenColumnsHandler('quote-list', columnOptions, breakpoints, parentColumns);

  return (
    <Page title="Quotes">
      <Help title="Quote List" view="quotes" />
      <PageActions primary>
        <AccessControl permissions={permissions.addQuote}>
          <Button
            onClick={goToCreateQuote}
            label="Create Quote"
            mT
          />
        </AccessControl>
        <Select
          className="fixed-column-select"
          placeholder="Hidden Columns"
          onChange={handleHiddenColumns}
          value={hiddenColumns}
          maxTagCount="responsive"
          mode="multiple"
          options={columnOptions}
          label="Hidden Columns"
          size="middle"
        />
        <Switch
          className="quote-list__switch"
          checked={showAll}
          onChange={toggleShowAll}
          label="Show All"
        />
      </PageActions>
      <DataTable
        dataSource={quotes}
        loading={fetching || isPrinting}
        onChange={handleOnChange}
        totalRecords={count}
        onFilter={handleFilter}
        page={page}
        defaultFilter={defaultFilter}
      >
        <DataTableColumn
          title="Quote"
          dataIndex="id"
          columnKey="id"
          width={90}
          sorter
          hideColumn={breakpoint.id}
        />
        <DataTableColumn
          title="Sales Rep"
          dataIndex="repOwnerName"
          columnKey="rep_owner"
          hideColumn={breakpoint.rep_owner}
          sorter
        />
        <DataTableColumn
          title="Job Title"
          columnKey="job_title"
          render={jobTitleTemplate}
          width={360}
          sorter
          hideColumn={breakpoint.job_title}
        />
        <DataTableColumn
          title="Company"
          dataIndex="companyName"
          columnKey="company"
          hideColumn={breakpoint.company}
          sorter
        />
        <DataTableColumn
          columnKey="client"
          title="Customer Contact"
          render={customerTemplate}
          hideColumn={breakpoint.client}
          sorter
        />
        <DataTableColumn
          title="Date Added"
          dataIndex="createdDate"
          columnKey="created_date"
          hideColumn={breakpoint.created_date}
          isDate
          sorter
        />
        <DataTableColumn
          title="Date Modified"
          dataIndex="modifiedDate"
          columnKey="modified_date"
          hideColumn={breakpoint.modified_date}
          isDate
          sorter
        />
        <DataTableColumn
          title="Total"
          dataIndex="totalWithTax"
          columnKey="total"
          isCurrency
          hideColumn={breakpoint.totalWithTax}
        />
        <DataTableColumn
          title="Status"
          className="quote-table__status-column"
          dataIndex="isconverted"
          columnKey="isconverted"
          render={statusTemplate}
          hideColumn={!showAll || breakpoint.isconverted}
        />
        <DataTableColumn
          columnKey="quote-actions"
          isActionColumn
          deletePermissions={permissions.deleteQuote}
          viewPermissions={permissions.editQuote}
          onShowDetails={goToQuoteDetails}
          onDelete={handleDeleteRow}
        >
          <DataTableAction
            permissions={permissions.addQuote}
            icon="fa fa-copy"
            onClick={openCopyQuoteModal}
          />
          <DataTableAction
            icon="fa fa-envelope"
            label="Send Proposal by Email"
            hide={hideIfConvertedAndDealer}
            onClick={openSendProposalEmail}
            permissions={permissions.editQuote}
          />
          <DataTableAction
            icon="fa fa-envelope"
            label="Send by Email"
            onClick={openSendEmail}
            permissions={permissions.editQuote}
            hide={hiddenAction}
          />
          <DataTableAction
            icon="fa fa-print"
            label="Print Proposal"
            hide={hideIfConverted}
            onClick={printQuoteProposal}
          />
          <DataTableAction
            icon="fa fa-print"
            label="Print Quote"
            onClick={printQuote}
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default QuotesList;
