import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { FileViewerHistorySateModel } from 'components/pages/Quotes/Quote/Form/FileViewer';

export default function openFileViewerModal(push, quote: QuoteModel) {
  return () => {
    const historyLocationState: FileViewerHistorySateModel = {
      FileViewerForm: {
        quoteId: quote.id,
      },
    };
    push(ModalRouteHash.FileViewer, historyLocationState);
  };
}
