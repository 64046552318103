export enum FormActions {
  SET_ERRORS = 'SET_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  CLEAR_STATE ='CLEAR_STATE',
  START_SAVING = 'START_SAVING',
  FINISH_SAVING = 'FINISH_SAVING',
}

interface FormState {
  formData: any;
  saving: boolean;
  errors: any;
}

export default function formReducer(state: FormState, action): FormState {
  const { type, payload } = action;

  switch (type as FormActions) {
    case FormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case FormActions.CLEAR_STATE:
      return { ...state, formData: payload };
    case FormActions.SET_ERRORS:
      return { ...state, errors: payload, saving: false };
    case FormActions.START_SAVING:
      return { ...state, saving: true };
    case FormActions.FINISH_SAVING:
      return { ...state, saving: false };
    default:
      return state;
  }
}
