import getEventValue from 'helpers/get-event-value';
import { QuestionnaireActions } from '../reducer';

export default function handleTermsChange(dispatch) {
  return (e) => {
    dispatch({
      type: QuestionnaireActions.SET_TERMS_ACCEPTED,
      payload: getEventValue(e),
    });
  };
}
