import { SubOption } from 'models/option';
import { ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/sub-option/`;

const subOptionApi: ApiModel<SubOption> = getApi(endpoint);

export default subOptionApi;
