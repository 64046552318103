import threeModelApi from 'api/three-model';
import morphApi from 'api/three-model-morph';
import { validateEntity } from 'helpers/form-validations';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { modelValidationRules } from '../validations';
import { ThreeFormActions, ThreeFormModel, ThreeMorphFormModel } from '../reducer';
import getPayload from './get-payload';
import getMorphPayload from './get-morph-payload';
import validateMorphs from './validate-morphs';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/morph/`;

async function balanceMorphs(
  initialMorphs: number[],
  morphs: {[index: string]: ThreeMorphFormModel },
  threeModel: string,
) {
  const deleteRequests = initialMorphs
    .filter(morph => !Object.values(morphs).some(m => m.id === morph))
    .map((morph) => morphApi.delete(`${endpoint}${morph}`));
  await Promise.all(deleteRequests);

  const newMorphs = Object.keys(morphs)
    .map(key => getMorphPayload(morphs[key], threeModel));
  const createRequests = newMorphs.map(morph => (
    morph.id ? morphApi.update(morph) : morphApi.create(morph)
  ));
  await Promise.all(createRequests);
}

export default function saveThreeModel(
  dispatch,
  threeModel: ThreeFormModel,
  goBack,
) {
  return async () => {
    dispatch({ type: ThreeFormActions.START_SAVING_MODELS });

    const {
      hasErrors: modelHasErrors,
      errors: modelErrors,
    } = validateEntity(threeModel, modelValidationRules);

    const {
      morphsHaveErrors,
      errors: morphsErrors,
    } = validateMorphs(threeModel.morphs);

    if (modelHasErrors || morphsHaveErrors) {
      return dispatch({
        type: ThreeFormActions.SET_ERRORS,
        payload: { ...modelErrors, ...morphsErrors },
      });
    }

    const onModelSaved = (response) => {
      const { morphs, initialMorphs } = threeModel;
      const modelUrl = threeModel.url || response.data?.url;

      return balanceMorphs(initialMorphs, morphs, modelUrl);
    };

    const onSuccess = () => {
      const message = threeModel.id
        ? '3D Model updated.'
        : '3D Model created.';

      notifyEventChannel(EventChannelList.THREE_MODEL_LIST_CHANGED);
      notification({
        type: NotificationType.SUCCESS,
        message,
      });
      goBack();
    };

    const onError = () => {
      const message = threeModel.id
        ? '3D Model failed to update.'
        : '3D Model failed to create.';

      notification({
        type: NotificationType.ERROR,
        message,
      });
    };

    const onFinally = () => {
      dispatch({ type: ThreeFormActions.FINISH_SAVING_MODELS });
    };

    const payload = await getPayload(threeModel);
    const action = threeModel.url
      ? threeModelApi.patchModel
      : threeModelApi.create;

    return action(payload)
      .then(onModelSaved)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
