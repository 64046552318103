import React, { FunctionComponent, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { TutorialModel } from 'models/tutorial';
import tutorialsApi from 'api/tutorials';
import notification, { NotificationType } from 'helpers/notification';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import DataTable, { DataTableColumn } from 'components/base-components/DataTableV2';
import FlexBox from 'components/base-components/FlexBox';
import RenderIf from 'components/base-components/RenderIf';
import Video from './Video';
import './styles.scss';

interface Props {
  view: string;
}

const videTemplate = (loadVideo, title) => {
  return (row: TutorialModel) => {
    const { videoName } = row;

    return (
      <button onClick={() => loadVideo(row)} className="tutorials-table__watch-btn">
        <RenderIf isTrue={title === videoName}>
          <i className="fa fa-play" />
        </RenderIf>
        {videoName}
      </button>
    );
  };
};

const TutorialsTable: FunctionComponent<Props> = (props) => {
  const { view } = props;

  const params: UseTableStateParams = useMemo(() => ({
    api: tutorialsApi,
    queryParams: {
      erp_view: view,
    },
    defaultSortField: '-id'
  }), [view]);

  const {
    state: {
      data,
      fetching,
      count
    },
    actions: {
      handleOnChange
    }
  } = useTableState<TutorialModel>(params);
  const [{ loading, url, title }, setVideoState] = useState({
    loading: false,
    url: '',
    title: '',
  });

  const loadVideo = ({ id, videoName }: TutorialModel) => {
    if (title && title === videoName) {
      return;
    }

    const onSuccess = (response: AxiosResponse<TutorialModel>) => {
      const { data: tutorial } = response;
      setVideoState({
        loading: false,
        url: tutorial.url,
        title: videoName,
      });
    };

    const onError = () => {
      notification({
        type: NotificationType.ERROR,
        message: 'We could not fetch the video url'
      });
      setVideoState((old) => ({ ...old, loading: false }));
    };

    setVideoState((old) => ({ ...old, loading: true }));
    tutorialsApi.get(id)
      .then(onSuccess)
      .catch(onError);
  };

  return (
    <FlexBox direction="row">
      <div className="tutorials-table">
        <DataTable
          rowKey="id"
          loading={fetching || loading}
          dataSource={data}
          onChange={handleOnChange}
          totalRecords={count}
        >
          <DataTableColumn
            title="Name"
            columnKey="video_name"
            render={videTemplate(loadVideo, title)}
          />
        </DataTable>
      </div>
      <Video url={url} />
    </FlexBox>
  );
};

export default TutorialsTable;
