import getEventValue from 'helpers/get-event-value';
import { UpdateShippingActions } from '../reducer';

export function handleCountryChange(dispatch) {
  return (event) => {
    dispatch({
      type: UpdateShippingActions.SET_SELECTED_COUNTRY,
      payload: getEventValue(event),
    });
  };
}
