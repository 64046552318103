import React, { FunctionComponent, useCallback, useState } from 'react';
import Input, { Props as InputProps } from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import getEventValue from 'helpers/get-event-value';

interface Props extends InputProps {
  onCancel: () => any;
  onCreate: (event) => any;
}

const InputAddFolder: FunctionComponent<Props> = (props) => {
  const { onCancel, onCreate, loading, ...rest } = props;
  const onPressEsc = useCallback((event) => {
    if (event.keyCode === 27) {
      onCancel();
    }
  }, [onCancel]);
  const [value, setValue] = useState('');
  const onCreateButton = useCallback(() => onCreate(value), [value, onCreate]);
  const handleOnChance = useCallback((newValue) => setValue(getEventValue(newValue)), []);

  return (
    <div className="d-flex d-inline-block align-items-end crate-folder-wrap">
      <i className="fa fa-folder directory-icon" />
      <Input
        className="crate-folder-input"
        autoFocus
        value={value}
        onChange={handleOnChance}
        onPressEnter={onCreate}
        onKeyDown={onPressEsc}
        loading={loading}
        size="small"
        {...rest}
      />
      <Button
        mR
        size="small"
        onClick={onCreateButton}
        loading={loading}
      >
        Create
      </Button>
      <Button
        mR
        danger
        size="small"
        onClick={onCancel}
        loading={loading}
      >
        Cancel
      </Button>
    </div>
  );
};

export default InputAddFolder;
