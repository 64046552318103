import { useCallback, useEffect, useMemo, useReducer } from 'react';
import shipmentApi from 'api/shipment';
import { QuoteModel } from 'models/quote';
import { OrderProductModel } from 'models/order';
import { ShipmentDTO } from 'models/shipment';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import useCallApiAction from 'hooks/base/call-api-action';
import { ExtraChargeModel } from 'models/extra-charge';
import prepareQuoteProductsData from './actions/prepare-quote-products-data';
import handleChangeProductInline from './actions/handle-change-product';
import { handleDeleteRow } from './actions/handle-delete-row';
import quoteProductListReducer, { QuoteProductListActions, QuoteProductListState } from './reducer';
import handleExtraChargeChanged from './actions/handle-extra-charge-changed';
import handleAddExtraCharge from './actions/handle-add-extra-charge';
import handleRemoveExtraCharge from './actions/handle-remove-extra-charge';
import getPricePayload from './actions/get-price-payload';

export interface QuoteProductsDataModel {
  id: string;
  group?: string;
  products?: OrderProductModel[];
  subtotal?: number;
}

export default function useQuoteProductsListState(
  quote: QuoteModel,
  shipment: ShipmentDTO,
  updateDataQuoteManually,
  extraCharges: ExtraChargeModel[],
  reFetchLeadTime,
) {
  const initialReducerState: QuoteProductListState = {
    quoteProducts: [],
    expandedRowKeys: [],
    orderTotal: 0,
    extraCharges,
    deletingExtraChargeIndex: undefined,
  };

  const [state, dispatch] = useReducer(quoteProductListReducer, initialReducerState);

  const { orderTotal } = state;

  const {
    data: { price: shippingPrice },
    error: fetchingShippingPriceErrors,
    fetching: fetchingShippingPrice,
    callAction: reFetchShippingPrice,
  } = useCallApiAction(
    shipmentApi.shippingPrice,
    shipment?.id,
    { price: '', shippingType: '' },
    !shipment || !orderTotal,
    orderTotal,
  );

  useEffect(() => {
    const shouldShowNotification = (
      fetchingShippingPriceErrors &&
      fetchingShippingPriceErrors !== 'Must Select a Shipping Zone'
    );
    if (shouldShowNotification) {
      notification({
        type: NotificationType.ERROR,
        message: fetchingShippingPriceErrors
      });
    }
  }, [fetchingShippingPriceErrors]);

  useEffect(() => {
    if (quote) {
      const payload = prepareQuoteProductsData(quote);
      dispatch({ type: QuoteProductListActions.SET_DATA, payload });
    }
  }, [quote]);

  useEffect(() => {
    if (extraCharges.length > 0) {
      dispatch({
        type: QuoteProductListActions.SET_EXTRA_CHARGES,
        payload: extraCharges,
      });
    }
  }, [extraCharges]);

  useEventCenterUpdate(EventChannelList.SHIPPING_PROJECT_CHANGE, reFetchShippingPrice);

  return {
    state: {
      ...state,
      hasOrder: useMemo(() => quote?.orders && quote?.orders.length, [quote]),
      shippingPrice: useMemo(
        () => shippingPrice ? parseFloat(shippingPrice) : 0,
        [shippingPrice],
      ),
      fetchingShippingPrice,
    },
    actions: {
      handleChangeProductInline: useCallback(
        handleChangeProductInline(updateDataQuoteManually, quote, reFetchLeadTime),
        [updateDataQuoteManually, quote, reFetchLeadTime],
      ),
      handleDeleteRow: useCallback(handleDeleteRow(), []),
      handleExtraChargeChanged: useCallback(
        handleExtraChargeChanged(dispatch, state.extraCharges),
        [state.extraCharges],
      ),
      handleAddExtraCharge: useCallback(
        handleAddExtraCharge(dispatch, quote?.id, state.extraCharges),
        [quote, state.extraCharges],
      ),
      handleRemoveExtraCharge: useCallback(
        handleRemoveExtraCharge(dispatch, state.extraCharges),
        [state.extraCharges],
      ),
      getPricePayload
    }
  };
}
