import React, { FunctionComponent, useCallback } from 'react';
import { Row } from 'antd';
import { AssignFilesModel } from 'models/file-viewer';
import Form, { Field } from 'components/base-components/Form';
import Checkbox from 'components/base-components/Checkbox';
import Button from 'components/base-components/Button';
import './style.scss';

interface FileItemProps {
  itemData: AssignFilesModel;
  fileName: string;
  onChange: (formData, fileName) => any;
}

const FileItem: FunctionComponent<FileItemProps> = (props) => {
  const { onChange, itemData, fileName } = props;
  const { folders } = itemData;
  const handleOnChange = useCallback(onChange(itemData, fileName), [itemData, fileName]);
  const downloadFile = useCallback(() => window.open(itemData.url, '_blank'), [itemData]);

  return (
    <Row justify="space-between">
      <Button type="text" onClick={downloadFile}>
        <i className="fa fa-file file-icon" />
        {fileName}
      </Button>
      <Form onChange={handleOnChange} state={folders} className="assign-file__item-file-form">
        <Field
          name="installers"
          label="Installers"
          component={Checkbox}
        />
        <Field
          name="production"
          label="production"
          component={Checkbox}
        />
      </Form>
    </Row>
  );
};

export default FileItem;
