import { QuoteModel } from 'models/quote';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function printQuote(
  printAction: (id: number) => Promise<any>,
  setIsPrinting: (isPrinting: boolean) => void,
) {
  return ({ id }: QuoteModel) => {
    setIsPrinting(true);

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const onFinally = () => {
      setIsPrinting(false);
    };

    return printAction(id)
      .catch(onError)
      .finally(onFinally);
  };
}
