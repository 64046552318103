import { InstallerBookingModel } from 'models/installer-booking';
import { ApiModel, ApiContentType } from './base';
import getApi from './base/get-api';

const { REACT_APP_SHIPMENT_API } = process.env;

const endpoint = `${REACT_APP_SHIPMENT_API}/installer-booking/`;

const installerBookingApi: ApiModel<InstallerBookingModel> = {
  ...getApi<InstallerBookingModel>(endpoint, ApiContentType.JSON, true),
};

export default installerBookingApi;
