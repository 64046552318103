import { SendEmailFormActions } from '../reducer';

export default function onUpload(dispatch, files: string[]) {
  return (params, name) => {
    const newValue = params?.includes('?directory=') ? `${params.slice(11)}/${name}` : name;
    dispatch({
      type: SendEmailFormActions.SET_FILES,
      payload: [...files, newValue],
    });
  };
}
