import React, { FunctionComponent, useMemo, } from 'react';
import { BuilderPropertyType, Units } from 'models/builder';
import { capitalizeFirstLetter } from 'helpers/formatting';
import Form from '../Form';
import Field from '../Form/Field';
import Collapse, { Panel } from '../Collapse';
import RenderIf from '../RenderIf';
import { resolveComponent, resolveProps, shouldShowProperty } from './util';
import './styles.scss';

interface Props {
  modelDescription;
  builderOptions;
  onChange: (properties) => void;
  unit: Units;
  fromBuilder?: boolean;
}

export const Properties: FunctionComponent<Props> = (props) => {
  const { modelDescription, builderOptions, onChange, unit, fromBuilder } = props;

  const fields = useMemo(() => {
    const result = {
      selectsComponents: [],
      others: []
    };
    const propertiesName = Object.keys(modelDescription);
    propertiesName.slice(propertiesName.findIndex(e => e === 'style') + 1)
      .filter((property) => shouldShowProperty(builderOptions[property]))
      .forEach((property) => {
        const commonFieldProps = {
          name: property,
          component: resolveComponent(builderOptions[property].propertyType, fromBuilder),
          ...resolveProps(builderOptions[property], unit)
        };

        if (
          fromBuilder &&
          (builderOptions[property].propertyType === BuilderPropertyType.SELECT ||
            builderOptions[property].propertyType === BuilderPropertyType.SELECT_REFRESH)
        ) {
          result.selectsComponents.push(
            <Panel
              key={property}
              header={capitalizeFirstLetter(property)}
              showArrow={false}
            >
              <Field {...commonFieldProps} square />
            </Panel>
          );
        } else {
          result.others.push(<Field key={property} label={property} {...commonFieldProps} />);
        }
      });
    return result;
  }, [modelDescription, builderOptions, unit, fromBuilder]);

  return (
    <Form onChange={onChange} state={modelDescription}>
      {fields.others}
      <RenderIf isTrue={fromBuilder}>
        <Collapse className="properties-panel__collapse">
          {fields.selectsComponents}
        </Collapse>
      </RenderIf>
    </Form>
  );
};
