import { QuoteModel } from 'models/quote';
import { CreateOrderErrors } from 'api/orders';

export default function resolveProductValues(
  quote: QuoteModel,
  missingFields: CreateOrderErrors,
) {
  if (missingFields && missingFields.productOptions) {
    const productIds = quote.orderproducts
      .filter((product) => !!missingFields.productOptions.missing[product.id])
      .map(product => product.id);

    return productIds.reduce((values, productId) => ({
      ...values,
      [productId]: {},
    }), {} as any);
  }

  return {};
}
