import { TermModel } from 'models/term';
import { ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/terms/`;

const termApi: ApiModel<TermModel> = getApi<TermModel>(endpoint);

export default termApi;
