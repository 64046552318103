import { UserGroupModel } from 'models/user-group';
import { flatDropdownOptions } from 'helpers/dropdown-options';

export default function getPayload(userGroup: UserGroupModel) {
  const { permissions: nextPermissions, ...rest } = userGroup;
  return {
    ...rest,
    permissions: flatDropdownOptions(nextPermissions),
  };
}
