import { resolveApiErrorMessage } from 'api/base';
import orderProductApi from 'api/order-product';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { QuoteViewActions } from '../reducer';

async function bulkDeleteProducts(productsId: number[]) {
  // eslint-disable-next-line no-restricted-syntax
  for (const id of productsId) {
    // eslint-disable-next-line no-await-in-loop
    await orderProductApi.delete(id);
  }
}

export default function bulkDelete(dispatch, productsId: number[]) {
  return () => {
    dispatch({ type: QuoteViewActions.START_BULK_DELETE });

    const onSuccess = () => {
      notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED);
      notification({
        type: NotificationType.SUCCESS,
        message: 'All products have been deleted successfully',
      });
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const onFinally = () => {
      dispatch({ type: QuoteViewActions.STOP_BULK_DELETE });
    };

    return bulkDeleteProducts(productsId)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
