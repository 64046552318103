import { warning } from 'components/base-components/ModalV2';
import { ApiModel } from 'api/base';
import { EventChannelList, listenToEventChannel } from 'helpers/event-center';
import { TableActions } from '../reducer';
import { deleteEntry, DeleteEntryParams } from './delete-entry';

export interface DeleteRowParams {
  dispatch: any;
  api: ApiModel;
  eventChannel: EventChannelList;
  title: string;
  content: (entryToDelete: any) => string;
}

export function handleDeleteRow(params: DeleteRowParams, idField: string) {
  return (entryToDelete: any) => {
    const {
      api,
      content,
      dispatch,
      eventChannel,
      title,
    } = params;
    const deleteEntryParams: DeleteEntryParams = {
      dispatch,
      entryToDelete,
      api,
      eventChannel,
    };

    const handleOnOk = () => deleteEntry(deleteEntryParams, idField);

    const modal = warning({
      title,
      content: content(entryToDelete),
      okText: 'Delete',
      okType: 'danger',
      okButtonProps: {
        type: 'primary',
      },
      autoFocusButton: null,
      onOk: handleOnOk,
      okCancel: true,
      centered: true,
      zIndex: 1050,
      maskClosable: true,
    });

    listenToEventChannel(EventChannelList.DATATABLE_DELETING, (deleting) => {
      modal.update({
        cancelButtonProps: ({ disabled: deleting }),
        keyboard: !deleting,
        maskClosable: !deleting,
      });
      if (!deleting) {
        dispatch({ type: TableActions.HANDLE_DELETE_ROW, payload: undefined });
      }
    });

    dispatch({ type: TableActions.HANDLE_DELETE_ROW, payload: entryToDelete });
  };
}
