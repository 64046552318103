import { useEffect, useMemo } from 'react';
import { PartModel } from 'models/part';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { isValidFile, mapFileFromUrl } from 'helpers/file';
import partApi from 'api/part';
import { validationRules } from './validations';

const defaultPart = {
  name: '',
  image: null,
  description: '',
  minOrder: 1,
  standardOrder: 1,
  mpn: '',
  manufacturer: null,
  price: null,
  units: '',
};

const getPayload = (part) => ({
  ...part,
  image: part.image && isValidFile(part.image[0]) ? part.image[0] : undefined,
  manufacturer: part.manufacturer.value,
});

const getFormData = (part: PartModel) => {
  if (part) {
    const { manufacturer, image, ...rest } = part;
    return {
      ...rest,
      manufacturer: { value: manufacturer, label: manufacturer },
      image: mapFileFromUrl(image),
    };
  }
  return defaultPart;
};

export default function usePartFormState(partId: number) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: partApi,
    eventChannel: EventChannelList.PARTS_LIST_CHANGE,
    param: partId,
    getPayload,
    getFormData,
    validationRules
  }), [partId]);

  const {
    state: {
      formData: part,
      errors,
      saving,
      errorFetchingEntity: errorFetchingPart,
      fetchingEntity: fetchingPart,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<PartModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingPart) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingPart,
      });
    }
  }, [errorFetchingPart]);

  return {
    state: {
      part,
      savingPart: saving,
      fetchingFormData: fetchingPart,
      errors,
    },
    actions: {
      setFormValue,
      savePart: saveEntity,
      setErrors,
    },
  };
}
