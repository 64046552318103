export function renderContent(value, row) {
  if (typeof row.id === 'string') {
    return {
      props: {
        colSpan: 0
      },
    };
  }
  return value;
}
