import { ThreeModelPosition } from 'models/three-model';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/model_position/`;

const threeModelPositionApi: ApiModel<ThreeModelPosition> = getApi(
  endpoint,
  ApiContentType.JSON,
  true,
);

export default threeModelPositionApi;
