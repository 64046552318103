import React, { FunctionComponent } from 'react';
import { Popover as AntdPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

const Popover: FunctionComponent<PopoverProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntdPopover {...rest}>
      {children}
    </AntdPopover>
  );
};

export default Popover;
