export default function buildFilterAction(
  isGroupedBy: boolean,
  filterFunction: (value, record) => boolean,
  filterBatchFunction: (value, record) => boolean,
) {
  return (value, record) => {
    if (isGroupedBy) {
      return filterBatchFunction(value, record);
    }

    return filterFunction(value, record);
  };
}
