import { flatDropdownOption } from 'helpers/dropdown-options';
import { isValidFile } from 'helpers/file';
import { ProductFormModel } from '..';

export default function getPayload(product: ProductFormModel) {
  const { category, manufacturer, image, ...rest } = product;
  return {
    ...rest,
    category: flatDropdownOption(category),
    manufacturer: flatDropdownOption(manufacturer),
    image: image && isValidFile(image[0]) ? image : undefined,
  };
}
