import React, { FunctionComponent } from 'react';
import { Card as AntdCard } from 'antd';
import { CardProps } from 'antd/lib/card';

const Card: FunctionComponent<CardProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntdCard {...rest}>
      {children}
    </AntdCard>
  );
};

export default Card;
