export default function rowClassName(row) {
  if (typeof row.id === 'string') {
    if (row.id.includes('group')) {
      return 'quote-products-table_group-header__row';
    }
    if (row.id.includes('subtotal')) {
      return 'quote-products-table_group-subtotal__row';
    }
  }
  return null;
}
