/* eslint-disable */
import Core from "../core";
import FloorplannerView, {
  floorplannerModes,
  zoomLevel,
} from "./floorplanner_view";
import WallContextMenu from "./wallContextMenu";

const snapTolerance = 0.25;

const cmPerFoot = 30.48;
const pixelsPerFoot = 30;

const zoomStep = 2;

/**
 * The Floorplanner implements an interactive tool for creation of floorplans.
 */
export default class Floorplanner {
  /** */
  parent = null;
  /** */
  mode = 0;

  /** */
  activeWall = null;

  /** */
  activeCorner = null;

  /** */
  activeItem = null;

  /** */
  originX = 0;

  /** */
  originY = 0;

  /** drawing state */
  targetX = 0;

  /** drawing state */
  targetY = 0;

  /** drawing state */
  lastNode = null;

  /** */
  wallWidth;

  /** */
  modeResetCallbacks = [];

  /** */
  canvasElement;

  /** */
  view;

  /** */
  mouseDown = false;

  /** */
  mouseMoved = false;

  /** in ThreeJS coords */
  mouseX = 0;

  /** in ThreeJS coords */
  mouseY = 0;

  /** in ThreeJS coords */
  rawMouseX = 0;

  /** in ThreeJS coords */
  rawMouseY = 0;

  /** mouse position at last click */
  lastX = 0;

  /** mouse position at last click */
  lastY = 0;

  itemSelectedCallbacks = [];
  itemUnselectedCallbacks = [];

  /** */
  cmPerPixel;

  /** */
  pixelsPerCm;

  /** */
  constructor(canvas, floorplan, parent) {
    this.parent = parent;
    this.zoomLevel = zoomLevel;
    this.scale = 100;

    this.floorplan = floorplan;

    this.canvasElement = canvas;

    this.elWallContextMenu = null;

    this.view = new FloorplannerView(this.floorplan, this, canvas);

    const cmPerFoot = 30.48;
    const pixelsPerFoot = 15;
    this.cmPerPixel = cmPerFoot * (1 / pixelsPerFoot);
    this.pixelsPerCm = 1 / this.cmPerPixel;

    this.wallWidth = 10 * this.pixelsPerCm;

    // Initialization:

    this.setMode(floorplannerModes.MOVE);

    const scope = this;

    this.initializeContextMenu();

    this.canvasElement.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log("right click menu");
      if (this.activeWall) {
        this.elWallContextMenu.setVisible(true);
        this.elWallContextMenu.setPosition(e.offsetX, e.offsetY);
        this.elWallContextMenu.setWall(this.activeWall);
      } else {
        this.elWallContextMenu.setVisible(false);
      }
    });

    this.canvasElement.addEventListener("wheel", (event) =>
      scope.mouseWheel(event)
    );

    this.canvasElement.addEventListener("mousedown", (event) => {
      scope.mousedown(event);
    });
    this.canvasElement.addEventListener("mousemove", (event) => {
      scope.mousemove(event);
    });
    this.canvasElement.addEventListener("mouseup", () => {
      scope.mouseup();
    });
    this.canvasElement.addEventListener("mouseleave", () => {
      scope.mouseleave();
    });

    this.canvasElement.addEventListener("click", () => {
      if (this.activeWall) {
        try {
          this.parent.three.wallClicked.forEach(
            (cb) => typeof cb === "function" && cb(this.activeWall)
          );
        } catch (_) {}
      }
    });

    document.addEventListener(Core.BP3D_EVENT_CONFIG_CHANGED, (e) => {
      const { detail } = e;
      if (!detail) return;
      detail.dimUnit && this.view.draw();
    });

    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        scope.escapeKey();
      }
    });

    floorplan.roomLoadedCallbacks.push(() => {
      scope.reset();
    });
  }

  /**
   * Initialize context menu
   */
  initializeContextMenu() {
    this.elWallContextMenu = new WallContextMenu(
      this.canvasElement.parentElement,
      this
    );
    this.elWallContextMenu.setVisible(false);
  }

  /** */
  escapeKey() {
    this.setMode(floorplannerModes.MOVE);
  }

  /** */
  updateTarget() {
    if (this.mode === floorplannerModes.DRAW && this.lastNode) {
      if (Math.abs(this.mouseX - this.lastNode.x) < snapTolerance) {
        this.targetX = this.lastNode.x;
      } else {
        this.targetX = this.mouseX;
      }
      if (Math.abs(this.mouseY - this.lastNode.y) < snapTolerance) {
        this.targetY = this.lastNode.y;
      } else {
        this.targetY = this.mouseY;
      }
    } else {
      this.targetX = this.mouseX;
      this.targetY = this.mouseY;
    }

    this.view.draw();
  }

  mouseWheel(e) {
    let tmp = this.scale;
    if (e.deltaY > 0) {
      tmp -= zoomStep;
      this.scale = Math.max(10, tmp);
    } else {
      tmp += zoomStep;
      this.scale = Math.min(1000, tmp);
    }
    let value = (pixelsPerFoot * this.scale) / 100;
    this.cmPerPixel = cmPerFoot / value;
    this.pixelsPerCm = 1 / this.cmPerPixel;
    this.view.draw();
  }

  /** */
  mousedown(e) {
    if (this.elWallContextMenu.isVisible()) {
      this.elWallContextMenu.setVisible(false);
      return;
    }
    this.mouseDown = true;
    this.mouseMoved = false;
    this.lastX = this.rawMouseX;
    this.lastY = this.rawMouseY;

    // delete
    if (this.mode === floorplannerModes.DELETE) {
      if (this.activeCorner) {
        this.activeCorner.removeAll();
      } else if (this.activeWall) {
        this.activeWall.remove();
      } else {
        this.setMode(floorplannerModes.MOVE);
      }
    }
  }

  /** */
  mousemove(event) {
    if (this.elWallContextMenu.isVisible()) return;
    this.mouseMoved = true;
    // update mouse
    this.rawMouseX = event.clientX;
    this.rawMouseY = event.clientY;

    this.mouseX =
      (event.clientX - this.canvasElement.getBoundingClientRect().left) *
        this.cmPerPixel +
      this.originX * this.cmPerPixel;
    this.mouseY =
      (event.clientY - this.canvasElement.getBoundingClientRect().top) *
        this.cmPerPixel +
      this.originY * this.cmPerPixel;
    this.mouseX /= this.zoomLevel;
    this.mouseY /= this.zoomLevel;

    // update target (snapped position of actual mouse)
    if (
      this.mode === floorplannerModes.DRAW ||
      (this.mode === floorplannerModes.MOVE && this.mouseDown)
    ) {
      this.updateTarget();
    }

    // update object target
    if (this.mode !== floorplannerModes.DRAW && !this.mouseDown) {
      const hoverCorner = this.floorplan.overlappedCorner(
        this.mouseX,
        this.mouseY
      );
      const hoverWall = this.floorplan.overlappedWall(this.mouseX, this.mouseY);

      const hoverItem = this.floorplan.overlappedItem(this.mouseX, this.mouseY);

      let draw = false;
      if (hoverCorner !== this.activeCorner) {
        this.activeCorner = hoverCorner;
        draw = true;
      }
      if (hoverWall !== this.activeWall) {
        this.activeWall = hoverWall;
        draw = true;
      }
      if (hoverItem !== this.activeItem) {
        this.activeItem = hoverItem;
        draw = true;
      }
      if (draw) {
        this.view.draw();
      }
    }

    // panning
    if (
      this.mouseDown &&
      !this.activeCorner &&
      !this.activeWall &&
      !this.activeItem
    ) {
      this.originX += this.lastX - this.rawMouseX;
      this.originY += this.lastY - this.rawMouseY;
      this.lastX = this.rawMouseX;
      this.lastY = this.rawMouseY;
      this.view.draw();
    }

    // dragging
    if (this.mode === floorplannerModes.MOVE && this.mouseDown) {
      if (this.activeCorner) {
        this.activeCorner.move(this.mouseX, this.mouseY);
        // this.activeCorner.snapToAxis(snapTolerance);
      } else if (this.activeWall) {
        this.activeWall.relativeMove(
          ((this.rawMouseX - this.lastX) * this.cmPerPixel) / this.zoomLevel,
          ((this.rawMouseY - this.lastY) * this.cmPerPixel) / this.zoomLevel
        );
        // this.activeWall.snapToAxis(snapTolerance);
      } else if (this.activeItem) {
        if (event.buttons === 1) {
          // move
          this.activeItem.moveToPosition({
            x: this.mouseX,
            y: this.activeItem.position.y,
            z: this.mouseY,
          });
        } else if (event.buttons === 4) {
          // rotate
          let dx = this.rawMouseX - this.lastX;
          let rotation = this.activeItem.rotation.y;
          const step = 0.05;
          rotation += dx * step;
          this.activeItem.rotate({}, rotation);
        }
      }
      this.view.draw();
    }
    this.lastX = this.rawMouseX;
    this.lastY = this.rawMouseY;
  }

  /** */
  mouseup() {
    if (this.elWallContextMenu.isVisible()) return;
    this.mouseDown = false;

    // drawing
    if (this.mode === floorplannerModes.DRAW && !this.mouseMoved) {
      const corner = this.floorplan.newCorner(this.targetX, this.targetY);
      if (this.lastNode != null) {
        this.floorplan.newWall(this.lastNode, corner);
      }
      if (corner.mergeWithIntersected() && this.lastNode != null) {
        this.setMode(floorplannerModes.MOVE);
      }
      this.lastNode = corner;
    }

    // item click
    if (this.activeItem) {
      this.itemSelectedCallbacks.forEach((cb) => cb(this.activeItem));
    } else {
      this.itemUnselectedCallbacks.forEach((cb) => cb());
    }
  }

  /** */
  mouseleave() {
    this.mouseDown = false;
    // scope.setMode(scope.modes.MOVE);
  }

  /** */
  reset() {
    this.resizeView();
    this.setMode(floorplannerModes.MOVE);
    this.resetOrigin();
    this.view.draw();
  }

  /** */
  resizeView() {
    this.view.handleWindowResize();
  }

  /** */
  setMode(mode) {
    this.lastNode = null;
    this.mode = mode;
    // this.modeResetCallbacks.fire(mode);
    this.modeResetCallbacks.forEach(
      (cb) => typeof cb === "function" && cb(mode)
    );
    this.updateTarget();
  }

  /** Sets the origin so that floorplan is centered */
  resetOrigin() {
    const parent = this.canvasElement.parentNode;
    const height = parent.clientHeight;
    const width = parent.clientWidth;

    const centerX = width / 2;
    const centerY = height / 2;
    const centerFloorplan = this.floorplan.getCenter();

    this.originX =
      centerFloorplan.x * this.zoomLevel * this.pixelsPerCm - centerX;
    this.originY =
      centerFloorplan.z * this.zoomLevel * this.pixelsPerCm - centerY;
  }

  /** Convert from THREEjs coords to canvas coords. */
  convertX(x) {
    return (
      (x * this.zoomLevel - this.originX * this.cmPerPixel) * this.pixelsPerCm
    );
  }

  /** Convert from THREEjs coords to canvas coords. */
  convertY(y) {
    return (
      (y * this.zoomLevel - this.originY * this.cmPerPixel) * this.pixelsPerCm
    );
  }
}
