import React, { FunctionComponent } from 'react';
import { Tabs as AntdTabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

export const { TabPane } = AntdTabs;

const Tabs: FunctionComponent<TabsProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntdTabs {...rest}>
      {children}
    </AntdTabs>
  );
};

export default Tabs;
