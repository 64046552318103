import { AxiosResponse } from 'axios';
import { CutListModel } from 'models/cut-list';
import { ApiContentType, ApiModel, post, put } from 'api/base';
import getApi from 'api/base/get-api';
import downloadBlob from './base/download-blob';

const { REACT_APP_MATERIALS_API } = process.env;
const endpoint = `${REACT_APP_MATERIALS_API}/cutlist/`;

interface CutListApiModel extends ApiModel<CutListModel> {
  defineCutList: (id, payload) => Promise<AxiosResponse>;
  download: (cutList: number, name: string) => Promise<void>;
  upload: (cutList: number, sheet: File[]) => Promise<AxiosResponse>;
}

const cutListApi: CutListApiModel = {
  ...getApi<CutListModel>(endpoint, ApiContentType.MULTIPART, true),
  defineCutList(id, payload) {
    return post(`${endpoint}${id}/define-cutsheet/`, payload);
  },
  download(cutList: number, name: string) {
    return downloadBlob(
      `${endpoint}${cutList}/load-cutsheet/`,
      `${name} Cutsheet #${cutList}.xlsx`
    );
  },
  upload(cutList, sheet) {
    return put(`${endpoint}${cutList}/excel-update/`, { sheet }, ApiContentType.MULTIPART);
  },
};

export default cutListApi;
