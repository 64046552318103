import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { TasksHistorySateModel } from '../../Task';

export default function openTaskModal(push) {
  return (order: any) => {
    push(ModalRouteHash.TaskForm, {
      taskForm: {
        orderId: order.id,
        jobTitle: order.jobName,
      }
    } as TasksHistorySateModel);
  };
}
