import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { InvoiceModel } from 'models/invoice';
import invoiceApi from 'api/invoice';
import accountApi from 'api/account';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import useGetList from 'hooks/base/get-list';
import { EventChannelList } from 'helpers/event-center';
import { isDealerLoggedUser } from 'helpers/get-logged-user';
import hasPermissions from 'helpers/permissons';
import printInvoice from './actions/print-invoice';
import openReceivePaymentModal from './actions/open-receive-payment';
import openSendInvoiceEmailModal from './actions/open-send-invoice-email';
import openSendProformaInvoiceEmailModal from './actions/open-send-proforma-invoice-email';
import printProformaInvoice from './actions/print-proforma-invoice';

export default function useInvoiceState() {
  const { push } = useHistory();
  const isDealer = useMemo(() => isDealerLoggedUser(true) as any, []);
  const queryParams = useMemo(() => ({
    filters: {
      search: isDealerLoggedUser(true),
      searchFields: 'users',
    }
  }), []);
  const {
    data: account,
    fetching: fetchingAccount
  } = useGetList(
    accountApi,
    {
      preventAutoFetch: !isDealer,
      queryParams
    }
  );

  const orderTableStateParams = useMemo<UseTableStateParams>(() => ({
    api: invoiceApi,
    searchFields: ['account', 'id', 'order_id'],
    queryParams: {
      filters: {
        ordering: '-id',
        account: account[0]?.businessTradeName,
      },
    },
    eventChannel: EventChannelList.ORDER_PAYMENT_DONE,
    preventAutoFetch: isDealer ? account.length === 0 : undefined,
  }), [isDealer, account]);

  const {
    state,
    actions,
  } = useTableState<InvoiceModel>(orderTableStateParams);

  return {
    state: {
      ...state,
      loading: fetchingAccount || state.fetching,
      linkToOrder: useMemo(() => hasPermissions(['Sales-Orders', 'Dealer', 'Sales']) ? (cnc) => `quote/${cnc?.orderNumber.split('-')[0]}` : undefined, [])
    },
    actions: {
      ...actions,
      hiddenAction: useCallback(() => !!isDealerLoggedUser(true), []),
      printInvoice: useCallback(printInvoice, []),
      printProformaInvoice: useCallback(printProformaInvoice, []),
      openSendInvoiceEmailModal: useCallback(openSendInvoiceEmailModal(push), []),
      openSendProformaInvoiceEmailModal: useCallback(openSendProformaInvoiceEmailModal(push), []),
      openReceivePaymentModal: useCallback(openReceivePaymentModal(push), []),
    }
  };
}
