/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import getEventValue from 'helpers/get-event-value';
import Label from '../Label';
import InputError from '../InputError';
import './styles.scss';

interface Props {
  required?: boolean;
  label: string;
  error: string;
  value: string[];
  onChange: (value: string[]) => undefined;
}

const ChipsInput: FunctionComponent<Props> = (props) => {
  const { label, error, required, value, onChange } = props;
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback((event) => setInputValue(getEventValue(event)), []);

  const handleOnKeyDown = useCallback((event) => {
    const nextValue = getEventValue(event);

    if (event.key === 'Enter') {
      onChange((value || []).concat([nextValue]));
      setInputValue('');
    }

    if (event.keyCode === 8 && !nextValue && value && value.length > 0) {
      onChange(value.slice(0, -1));
    }
  }, [value, onChange]);

  const handleChipDelete = useCallback((event) => {
    const index = parseInt(event.target.dataset.index, 10);

    onChange(value.filter((_email, eIndex) => index !== eIndex));
  }, [value, onChange]);

  const inputClassName = useMemo(() => (
    classnames('chips-input', { error })
  ), [error]);

  const chips = useMemo(() => (
    value && Array.isArray(value)
      ? value.map((chip, index) => (
        <span
          key={`chip-${index}`}
          className="chips-input__chip"
        >
          {chip}
          <span
            className="chips-input__chip__delete-btn"
            data-index={index}
            onClick={handleChipDelete}
          >
            x
          </span>
        </span>
      ))
      : null
  ), [value, handleChipDelete]);

  return (
    <div className="chips-input__container">
      <Label text={label} required={required} />
      <div className={inputClassName}>
        {chips}
        <input
          className="chips-input__input"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleOnKeyDown}
        />
      </div>
      <InputError error={error} />
    </div>
  );
};

export default ChipsInput;
