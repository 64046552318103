import { DeficiencyItemModel } from 'models/order';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import deficiencyOrderApi from 'api/deficiency-item';
import { EventChannelList } from 'helpers/event-center';
import { useCallback, useEffect } from 'react';
import notification, { NotificationType } from 'helpers/notification';
import getFormData from './actions/get-form-data';
import getPayload from './actions/get-payload';

export interface DeficiencyItemFormModel {
  id?: number;
  comment: string;
  quantity: number;
  corrected: boolean;
  orderProduct: number;
}

export default function useDeficiencyOrderFormState(id: number, orderProductId: number) {
  const handleGetFormData = useCallback((deficiencyOrder) => getFormData(deficiencyOrder, orderProductId),
    [orderProductId]);

  const useFormStateParams: UseFormStateParams = {
    api: deficiencyOrderApi,
    eventChannel: EventChannelList.QUOTES_LIST_CHANGED,
    param: id?.toString(),
    getPayload,
    getFormData: handleGetFormData,
  };

  const {
    state: {
      formData: deficiencyOrder,
      errors,
      saving,
      errorFetchingEntity: errorFetchingDeficiencyOrder,
      fetchingEntity: fetchingDeficiencyOrder,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<DeficiencyItemModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingDeficiencyOrder) {
      notification({ type: NotificationType.ERROR, message: errorFetchingDeficiencyOrder });
    }
  }, [errorFetchingDeficiencyOrder]);

  return {
    state: {
      deficiencyOrder,
      savingDeficiencyOrder: saving,
      errors,
      fetchingDeficiencyOrder,
    },
    actions: {
      setFormValue,
      setErrors,
      saveDeficiencyOrder: saveEntity,
    },
  };
}
