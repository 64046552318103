import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import { validationRules } from './validations';
import useManufacturerFormState from './state';
import ContentForm from './Content';

export interface ManufacturerFormHistorySateModel {
  manufacturerForm: {
    manufacturerUrl: string;
  };
}

const ManufacturerForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { manufacturerForm: { manufacturerUrl } } = state as ManufacturerFormHistorySateModel;

  const {
    state: {
      manufacturer,
      savingManufacturer,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveManufacturer,
      setErrors,
    },
  } = useManufacturerFormState(manufacturerUrl);

  const modalTitle = useMemo(
    () => manufacturerUrl ? 'Edit Manufacturer' : 'Create Manufacturer',
    [manufacturerUrl],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveManufacturer}
      confirmLoading={savingManufacturer}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={manufacturer}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <ContentForm disableNameInput={manufacturer.url} />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default ManufacturerForm;
