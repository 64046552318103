import { ExtraChargeModel } from 'models/extra-charge';
import extraChargeApi from 'api/extra-charge';
import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import { QuoteProductListActions } from '../reducer';

export default function handleRemoveExtraCharge(dispatch, extraCharges: ExtraChargeModel[]) {
  return async (chargeIndex, id) => {
    dispatch({ type: QuoteProductListActions.SET_DELETING_EXTRA_CHARGE_INDEX, payload: chargeIndex });
    if (id) {
      try {
        await extraChargeApi.delete(id);
        const payload: ExtraChargeModel[] = extraCharges.filter((extraCharge) => extraCharge.id !== id);
        dispatch({ type: QuoteProductListActions.SET_EXTRA_CHARGES, payload });
        const message = 'Extra Charge successfully deleted.';
        notification({ type: NotificationType.SUCCESS, message });
      } catch (error) {
        dispatch({ type: QuoteProductListActions.SET_DELETING_EXTRA_CHARGE_INDEX, payload: undefined });
        const message = resolveApiErrorMessage((error as any)?.response);
        notification({ type: NotificationType.ERROR, message });
      }
    } else {
      const payload: ExtraChargeModel[] = extraCharges.filter((_, index) => index !== chargeIndex);
      dispatch({ type: QuoteProductListActions.SET_EXTRA_CHARGES, payload });
    }
  };
}
