import { flatDropdownOptions } from 'helpers/dropdown-options';
import { isValidFile } from '../../../../../../helpers/file';

export default function getPayload(account, useFullForm: boolean) {
  if (useFullForm) {
    const {
      store, businessTradeName, salesRep, owner, group, users: formUsers, tax, paymentTerms, logo, ...rest
    } = account;

    return {
      ...rest,
      businessTradeName: businessTradeName.trim(),
      store: store?.value,
      salesRep: salesRep?.value,
      owner: owner?.value,
      group: group?.value,
      users: flatDropdownOptions(formUsers),
      tax: flatDropdownOptions(tax),
      paymentTerms: paymentTerms?.value,
      logo: logo && isValidFile(logo[0]) ? logo : undefined,
    };
  }
  const { companyName, paymentTerms, ...rest } = account;
  return {
    ...rest,
    termName: paymentTerms?.value,
    companyName: companyName.trim(),
  };
}
