import { ShipmentModel } from 'models/shipment';
import { ApiContentType, get, post, put } from './base';

const { REACT_APP_SHIPMENT_API } = process.env;
const questionnaireEndpoint = `${REACT_APP_SHIPMENT_API}/dq`;

export interface ValidQuestionnaireResponse {
  token: string;
  shipment: ShipmentModel;
  job: string;
  contact: string;
  account: string;
}

function validate(hash: string) {
  const url = `${questionnaireEndpoint}/shipment_validate/${hash}`;
  return get(url);
}

function storeContact(token, contact) {
  return post(
    `${questionnaireEndpoint}/shipmentcontact/`,
    contact,
    ApiContentType.JSON,
    { token },
  );
}

function updateContact(token, contact) {
  return put(
    `${questionnaireEndpoint}/shipmentcontact/${contact.id}/`,
    contact,
    ApiContentType.JSON,
    { token },
  );
}

function storeAddress(token, address) {
  return post(
    `${questionnaireEndpoint}/shippingaddress/`,
    address,
    ApiContentType.JSON,
    { token },
  );
}

function updateAddress(token, address) {
  return put(
    `${questionnaireEndpoint}/shippingaddress/${address.id}/`,
    address,
    ApiContentType.JSON,
    { token },
  );
}

function storeAnswers(token, answers) {
  return post(
    `${questionnaireEndpoint}/shippingquestionnaire/`,
    answers,
    ApiContentType.JSON,
    { token },
  );
}

function updateAnswers(token, answers) {
  return put(
    `${questionnaireEndpoint}/shippingquestionnaire/${answers.id}/`,
    answers,
    ApiContentType.JSON,
    { token },
  );
}

function updateShipment(token, shipment) {
  return put(
    `${questionnaireEndpoint}/shipment/${shipment.id}/`,
    shipment,
    ApiContentType.JSON,
    { token },
  );
}

const questionnaireApi = {
  validate,
  storeContact,
  updateContact,
  storeAddress,
  updateAddress,
  storeAnswers,
  updateAnswers,
  updateShipment,
};

export default questionnaireApi;
