import { validateEntity } from 'helpers/form-validations';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { FormAddressModel } from '../reducer';
import { addressRules } from '../validations';

export default function validateShipment(
  shipment: {
    project?: DropdownOptionModel;
    zone?: DropdownOptionModel;
    address?: FormAddressModel;
  },
  shipmentFields: {
    showProjectSelect: boolean;
    showZoneSelect: boolean;
    showAddressForm: boolean;
  }
) {
  const { showAddressForm, showProjectSelect, showZoneSelect } = shipmentFields;
  const { address, project, zone } = shipment;

  return {
    project: { hasErrors: showProjectSelect ? !project?.value : false },
    zone: { hasErrors: showZoneSelect ? !zone?.value : false },
    address: showAddressForm
      ? validateEntity(address, addressRules)
      : { hasErrors: false, errors: undefined },
  };
}
