import React, { FunctionComponent } from 'react';
import Page from 'components/base-components/Page';
import './styles.scss';

const Dashboard: FunctionComponent = () => {
  return (
    <Page>
      <div className="dashboard__message">
        <h3>Welcome to</h3>
        <h1>The Furniture Guys</h1>
      </div>
    </Page>
  );
};

export default Dashboard;
