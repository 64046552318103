import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import { OptionGroupModel } from 'models/option-group';

const { REACT_APP_PRODUCTS_API } = process.env;
export const endpoint = `${REACT_APP_PRODUCTS_API}/option_group/`;

const optionGroupApi: ApiModel<OptionGroupModel> = {
  ...getApi<OptionGroupModel>(endpoint, ApiContentType.MULTIPART),
};

export default optionGroupApi;
