import { Column } from 'components/base-components/KanbanBoard';
import { TaskModel } from 'models/task';

export enum TaskManagerActions {
  SET_COLUMNS = 'SET_COLUMNS',
  OPEN_TASK_FORM = 'OPEN_TASK_FORM',
  CLOSE_TASK_FORM = 'CLOSE_TASK_FORM',
}

export interface TaskManagerState {
  columns: Column[];
  isShowTaskForm: boolean;
  task: TaskModel;
  generatingColumns: boolean;
}

export default function taskManagerReducer(state: TaskManagerState, action): TaskManagerState {
  const { type, payload } = action;

  switch (type as TaskManagerActions) {
    case TaskManagerActions.SET_COLUMNS:
      return { ...state, columns: payload, generatingColumns: false };
    case TaskManagerActions.OPEN_TASK_FORM:
      return { ...state, isShowTaskForm: true, task: payload };
    case TaskManagerActions.CLOSE_TASK_FORM:
      return { ...state, isShowTaskForm: false };
    default:
      return state;
  }
}
