import {
  BuilderOption,
  BuilderPropertyType,
  BuilderRangeProperty,
  BuilderSelectProperty,
  Units,
  BuilderProperties,
} from 'models/builder';
import { GenericMapOptions, mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Select from '../Select';
import Slider from '../Slider';
import Input from '../Input';
import RadioAvatar from '../../pages/Builder/PropertiesPanel/RadioAvatar';
import { getLabelOption } from './LabelOption';

const optionMapDropdown: GenericMapOptions = {
  fields: { value: 'name', label: getLabelOption },
  extra: ['textureHeight', 'textureWidth', 'image', 'nameInModel', 'group'],
  copyFullItem: true,
};

export function mapOptions(
  options: BuilderOption[],
  nameInModel: string,
  mode: string,
) {
  const mappedOptions = options.map(option => ({
    ...option,
    group: {
      nameInModel,
      mode,
    },
  }));

  return mapGenericDropdownOptions(mappedOptions, optionMapDropdown);
}

export function resolveComponent(type: BuilderPropertyType, fromBuilder) {
  switch (type) {
    case BuilderPropertyType.SELECT:
      return fromBuilder ? RadioAvatar : Select;
    case BuilderPropertyType.SELECT_REFRESH:
      return fromBuilder ? RadioAvatar : Select;
    case BuilderPropertyType.RANGE:
      return Slider;
    default:
      return Input;
  }
}

export function shouldShowProperty(fieldOptions: BuilderProperties) {
  switch (fieldOptions.propertyType as BuilderPropertyType) {
    case BuilderPropertyType.RANGE: {
      const { minValue, maxValue } = fieldOptions as BuilderRangeProperty;
      return minValue !== maxValue;
    }
    case BuilderPropertyType.SELECT: {
      const { options } = fieldOptions as BuilderSelectProperty;
      return options.length > 1;
    }
    case BuilderPropertyType.SELECT_REFRESH: {
      const { options } = fieldOptions as BuilderSelectProperty;
      return options.length > 1;
    }
    default:
      return true;
  }
}

function formatter(unit: Units) {
  return (value: number) => {
    switch (unit) {
      case Units.feets: {
        const restInches = value % 12;
        const inchesLabel = restInches ? ` ${restInches}in` : '';
        return `${Math.floor(value / 12)}ft${inchesLabel}`;
      }
      case Units.centimeters:
        return (value * 2.15).toFixed(1);
      default:
        return value;
    }
  };
}

function convertIncrementByUnit(unit: Units, value) {
  switch (unit) {
    case Units.centimeters:
      return value / 2.15;
    default:
      return value;
  }
}

export function resolveProps(fieldOptions: BuilderProperties, unit) {
  switch (fieldOptions.propertyType as BuilderPropertyType) {
    case BuilderPropertyType.RANGE: {
      const { increment, maxValue, minValue } = fieldOptions as BuilderRangeProperty;

      return {
        max: maxValue,
        min: minValue,
        step: convertIncrementByUnit(unit, increment),
        tipFormatter: formatter(unit),
        input: true
      };
    }
    case BuilderPropertyType.SELECT: {
      const { options } = fieldOptions as BuilderSelectProperty;

      return {
        options: options.map(({ name, nameInModel }) => ({
          key: name,
          value: name,
          label: name,
          nameInModel,
          type: fieldOptions.propertyType
        })),
      };
    }
    case BuilderPropertyType.SELECT_REFRESH: {
      const { options } = fieldOptions as BuilderSelectProperty;

      return {
        options: options.map(({ name, nameInModel }) => ({
          key: name,
          value: name,
          label: name,
          nameInModel,
          type: fieldOptions.propertyType
        })),
      };
    }
    default:
      return {};
  }
}
