import { DropdownOptionModel } from 'helpers/dropdown-options';

function resolveErrors(values, requiredFields) {
  return requiredFields.reduce((errors, field) => {
    return {
      ...errors,
      [field]: values[field] ? undefined : 'This field is required',
    };
  }, {} as any);
}

function hasErrors(errorCollection: { [id: string]: { [option: string]: string } }) {
  return Object.values(errorCollection)
    .reduce((flattenErrors, errors) => {
      return flattenErrors.concat(Object.values(errors));
    }, [])
    .some(error => !!error);
}

export default function validateProducts(
  productValues: { [productId: string]: { [option: string]: DropdownOptionModel } },
  missingFields: { [productId: string]: string[] },
) {
  const productsErrors = Object.keys(missingFields)
    .reduce((errors, id) => {
      const values = productValues[id];
      const requiredFields = missingFields[id];
      const err = resolveErrors(values, requiredFields);

      return {
        ...errors,
        [id]: err,
      };
    }, {} as any);

  return {
    hasErrors: hasErrors(productsErrors),
    errors: productsErrors,
  };
}
