import { FunctionRule, Rule, RuleType } from 'helpers/form-validations';
import { UserPayloadModel } from 'models/user';

const requiredRule: Rule = { type: RuleType.Required, message: 'This field is required' };
const minMaxLengthRules: Rule[] = [
  { type: RuleType.MinLength, value: 3, message: 'Must be at least 3 character' },
  { type: RuleType.MaxLength, value: 15, message: 'Must be not more then 15 character' }
];

const validationRules = {
  email: [
    requiredRule,
    { type: RuleType.Email, message: 'The email must be a valid email' },
  ],
  firstName: [requiredRule, ...minMaxLengthRules],
  lastName: [requiredRule, ...minMaxLengthRules],
  title: [requiredRule],
};

const confirmPasswordRule: FunctionRule<UserPayloadModel> = (value, user) => {
  if (!!user.password && value !== user.password) {
    return 'Must be the same as password';
  }
  return null;
};

const PasswordRule: FunctionRule<UserPayloadModel> = (value) => {
  const matchValue = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%&*?@])[\d!$%&*?@A-Za-z]{8,}$/;
  if (!!value && !(matchValue as RegExp).test(value)) {
    return 'Passwords must contain at least 8 characters, including uppercase, lowercase letters, numbers and special characters(!$%&*?@).';
  }
  return null;
};

export default function getValidationRules(creating) {
  if (creating) {
    return {
      ...validationRules,
      username: [requiredRule, ...minMaxLengthRules],
      password: [PasswordRule],
      passwordRepeat: [confirmPasswordRule]
    };
  }

  return validationRules;
}
