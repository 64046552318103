import React, { FunctionComponent } from 'react';
import InputMask from 'react-input-mask';
import Page from 'components/base-components/Page';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Button from 'components/base-components/Button';
import Checkbox from 'components/base-components/Checkbox';
import Input from 'components/base-components/Input';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import useTaskUserFormState from './state';
import validationsRules from './validations';
import './styles.scss';

const TaskSetting: FunctionComponent = () => {
  const {
    state: {
      fetchingFormData,
      savingTaskUser,
      taskUser,
      errors,
    },
    actions: {
      setFormValue,
      saveTaskUser,
      setErrors,
    },
  } = useTaskUserFormState();

  return (
    <Page title="Task Setting">
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={taskUser}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationsRules}
        >
          <Field
            id="email"
            name="email"
            label="Email"
            placeholder="Enter an Email"
          />
          <Field
            type="tel"
            id="mobile"
            name="mobile"
            label="Phone"
            placeholder="Enter Phone"
            mask="(999) 999-9999"
            maskChar="-"
            tag={[Input, InputMask]}
          />
          <div>
            <Field component={Checkbox} name="emailNotifications" id="emailNotifications" label="Email Notifications" />
            <Field component={Checkbox} name="textNotifications" id="textNotifications" label="Text Notifications" />
          </div>
        </Form>
        <div className="wrap-button">
          <Button onClick={saveTaskUser} loading={savingTaskUser}>
            Update
          </Button>
        </div>
      </RenderIf>
    </Page>
  );
};

export default TaskSetting;
