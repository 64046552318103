import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum AddProductFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_OPTION_FORM_VALUE = 'SET_OPTION_FORM_VALUE',
  SET_GROUP_FOR_SELECT = 'SET_GROUP_FOR_SELECT',
  SET_OPTIONS = 'SET_OPTIONS',
  UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE',
  SET_TREE_DATA = 'SET_TREE_DATA',
  START_ADDING = 'START_ADDING',
  FINISH_ADDING = 'FINISH_ADDING',
  SET_CUSTOM_OPTION = 'SET_CUSTOM_OPTION',
}

export interface AddProductFormModel {
  product: DropdownOptionModel;
  optionsModels: any[];
  quantity: number;
  group: DropdownOptionModel;
  recommendedPrice: number;
  price: number;
  category: string;
}

interface AddProductFormState {
  formData: AddProductFormModel;
  formOptions: {
    productOptions: any[];
  };
  groupForSelect: string [];
  treeData: any [];
  adding: boolean;
  errors: any;
}

export const initialOrder: AddProductFormModel = {
  category: '',
  product: undefined,
  optionsModels: [],
  quantity: 1,
  group: { value: '', label: 'None' } as DropdownOptionModel,
  recommendedPrice: 0,
  price: 0,
};

export default function addProductFormReducer(
  state: AddProductFormState,
  action
): AddProductFormState {
  const { type, payload } = action;

  switch (type as AddProductFormActions) {
    case AddProductFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case AddProductFormActions.SET_OPTION_FORM_VALUE:
      return { ...state, formOptions: payload };
    case AddProductFormActions.SET_TREE_DATA:
      return { ...state, treeData: state.treeData.concat(payload) };
    case AddProductFormActions.SET_GROUP_FOR_SELECT: {
      const uniqueGroupsSet = new Set([...state.groupForSelect, payload]);

      return {
        ...state,
        groupForSelect: [...uniqueGroupsSet],
      };
    }
    case AddProductFormActions.UPDATE_FORM_VALUE:
      return { ...state, formData: { ...state.formData, ...payload } };
    case AddProductFormActions.SET_OPTIONS:
      return { ...state, formOptions: payload };
    case AddProductFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, adding: false };
    case AddProductFormActions.START_ADDING:
      return { ...state, adding: true };
    case AddProductFormActions.FINISH_ADDING:
      return { ...state, adding: false };
    case AddProductFormActions.SET_CUSTOM_OPTION: {
      const { formOptions } = state;
      const { option, parent } = payload;

      return {
        ...state,
        formOptions: {
          ...formOptions,
          [parent]: option,
        },
      };
    }
    default:
      return state;
  }
}
