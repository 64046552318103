import { FileViewerHistorySateModel } from 'components/pages/Quotes/Quote/Form/FileViewer';
import { ModalRouteHash } from 'components/base-components/ModalRouter';

export default function openFileViewerModal(push) {
  return (order) => {
    const historyLocationState: FileViewerHistorySateModel = {
      FileViewerForm: {
        quoteId: order.quoteId,
      },
    };
    push(ModalRouteHash.FileViewer, historyLocationState);
  };
}
