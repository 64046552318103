import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import './styles.scss';

interface Props extends SpinProps{
  centered?: boolean;
  className?: string;
}

const Spinner: FunctionComponent<Props> = (props) => {
  const { size, centered, className, ...rest } = props;
  const wrapperClassName = classnames('spinner-wrapper', { centered }, className);

  return (
    <div className={wrapperClassName}>
      <Spin size={size} {...rest} />
    </div>
  );
};

Spinner.defaultProps = {
  spinning: true,
  centered: true,
};

export default Spinner;
