import { getLoggedUserId } from 'helpers/get-logged-user';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { AccountFormHistorySate } from 'components/pages/Account/Form';

export default function openCreateAccountModal(push) {
  return () => {
    const historyLocationState: AccountFormHistorySate = {
      accountForm: {
        showQuickForm: true,
        salesRep: getLoggedUserId(),
      },
    };
    push(ModalRouteHash.AccountForm, historyLocationState);
  };
}
