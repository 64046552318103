/* eslint-disable */
import React from 'react';
import SceneManager from './app';
import * as THREE from 'three';
import './style.scss';

import iconMouseDrag from './icon-mousedrag.png';

interface iProps {
  data: any;
  set?: any;
  onOverlapDetected?: () => void;
}

interface iState {
  tipVisible: boolean;
}

export default class Blueprint3DProductViewer extends React.Component<
  iProps,
  iState
> {
  refContainer = null;
  sceneManager = null;
  constructor(props: iProps) {
    super(props);
    this.state = {
      tipVisible: true,
    };

    window.addEventListener("message", (event) => {
      if (
        typeof event.data === "object" &&
        event.data.call === "req-import-set"
      )
        this.exportSetData();
    });
  }

  componentDidMount = () => {
    this.sceneManager = new SceneManager(this.refContainer);

    this.sceneManager.overlapDetectedCallbacks.push(this.handleOverlapDetected);
    // this.props.data && this.addItem(this.props.data);
    this.handleSetChanged(this.props.set);
  };

  UNSAFE_componentWillReceiveProps = (props: iProps) => {
    if (JSON.stringify(this.props.set) !== JSON.stringify(props.set)) {
      this.handleSetChanged(props.set);
    }
  };

  handleSetChanged = (setInfo: any) => {
    const idList: string[] = [];
    const getIdRecursively = (item) => {
      try {
        idList.push(item.id);
        if (Array.isArray(item.children)) {
          item.children.forEach((child) => getIdRecursively(child));
        }
      } catch (_) {}
    };
    try {
      getIdRecursively(setInfo.item);
      this.sceneManager.uuidList = idList;
    } catch (_) {}
  };

  handleOverlapDetected = (_flag: boolean) => {
    if (typeof this.props.onOverlapDetected === "function")
      this.props.onOverlapDetected();
  };

  exportSetData = () => {
    try {
      const res = this.sceneManager.exportItems();
      window.parent.postMessage(
        {
          call: "res-import-set",
          data: res,
        },
        "*"
      );
      return res;
    } catch (_) {}
  };

  getDefaultData = (item) => {
    const defaultMorph = {};
    if (Array.isArray(item.morph)) {
      for (var morph of item.morph) {
        defaultMorph[morph.index] = (morph.min - 5) / (300 - 5);
      }
    }

    const defaultStyles = {};
    if (Array.isArray(item.styles)) {
      for (var style of item.styles) {
        defaultStyles[style.name_in_model] = style.types[0].name_in_model;
      }
    }
    const metadata = {
      ...item,
      itemName: item.name,
      modelUrl: item.model,
      itemType: item.type,
    };
    return { defaultMorph, defaultStyles, metadata };
  };

  addItem = async (item: any) => {
    try {
      const { defaultMorph, defaultStyles, metadata } =
        this.getDefaultData(item);
      return await this.sceneManager.addItem(
        item.type,
        item.model,
        metadata,
        null,
        null,
        {
          styles: defaultStyles,
          morph: defaultMorph,
          stackable: item.stackable,
          stackontop: item.stackontop,
          overlappable: item.overlappable,
        }
      );
    } catch (_) {
      console.log(_);
      return null;
    }
  };

  async replaceModel(id: string, item: any, restriction?: any) {
    // console.log("replace model", id, item);
    try {
      let oldModel = this.sceneManager.findModelByUUID(id);
      if (oldModel && !item) {
        oldModel.remove();
        // console.log("set hidden");
        // oldModel.restriction.hidden = true;
        // if (oldModel.errorGlow) {
        //   oldModel.scene.remove(oldModel.errorGlow);
        // }
        // this.sceneManager.updateModelsByRestriction();
        return;
      }

      const { defaultMorph, defaultStyles, metadata } =
        this.getDefaultData(item);

      let position =
        restriction && restriction.position
          ? new THREE.Vector3(
              restriction.position.x,
              restriction.position.y,
              restriction.position.z
            )
          : null;
      let rotation =
        restriction && restriction.rotation ? restriction.rotation : null;
      let options = {
        styles: defaultStyles,
        morph: defaultMorph,
        stackable: item.stackable,
        stackontop: item.stackontop,
        overlappable: item.overlappable,

        restriction: restriction,
      };

      if (oldModel) {
        position = oldModel.position;
        rotation = oldModel.rotation.y;
        options = oldModel.getOptions();
        options.restriction = restriction;

        if (oldModel.errorGlow) {
          oldModel.scene.remove(oldModel.errorGlow);
        }
        oldModel.remove();
      }
      const model = await this.sceneManager.addItem(
        item.type,
        item.model,
        metadata,
        position,
        rotation,
        options
      );
      model.uuid = id;
      this.sceneManager.updateModelsByRestriction();

      return model;
    } catch (_) {
      console.log(_);
      return null;
    }
  }

  setMorph(id: string, index: number, value: number) {
    const model = this.sceneManager.findModelByUUID(id);
    if (!model) return;
    model.setMorph(index, value);
    this.sceneManager.updateModelsByRestriction();
  }

  setMaterial(id: string, target: string, material: string) {
    const model = this.sceneManager.findModelByUUID(id);
    if (!model) return;
    model.updateMaterial(target, material);
  }

  setStyle(id: string, hide_name: string, show_name: string) {
    const model = this.sceneManager.findModelByUUID(id);
    if (!model) return;
    model.updateStyle(hide_name, show_name);
  }

  updateRestrictions = (id: string, restriction: any) => {
    if (!restriction) return;
    const model = this.sceneManager.findModelByUUID(id);
    if (!model) return;
    try {
      // console.log("update restriction", id, restriction);
      model.restriction = { ...model.restriction, ...restriction };
      model.morphAlign = restriction.morphAlign;
    } catch (_) {}
  };

  render() {
    const { tipVisible } = this.state;
    return (
      <div
        className="scene-container"
        ref={(ref) => (this.refContainer = ref)}
        onPointerDown={() => this.setState({ tipVisible: false })}
        onTouchStart={() => this.setState({ tipVisible: false })}
      >
        <div className="tip-container" hidden={!Boolean(tipVisible)}>
          <img src={iconMouseDrag} alt="mouse-drag" />
        </div>
      </div>
    );
  }
}
