import React, { FunctionComponent } from 'react';
import { Modal as AntdModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useHelpActions } from 'components/providers/Help';
import FlexBox from 'components/base-components/FlexBox';
import { Text } from 'components/base-components/Typography';
import Button from 'components/base-components/Button';
import './styles.scss';

interface Props extends ModalProps {
  showHelp?: boolean;
}

export const { warning, error, info, success } = AntdModal;

function Header({ title, showHelp }) {
  const { openModal } = useHelpActions();

  if (showHelp) {
    if (title && typeof title === 'string') {
      return (
        <div className="modal__header">
          <Text>{title}</Text>
          <FlexBox>
            <Button
              type="text"
              icon="fa fa-question"
              onClick={openModal}
            />
          </FlexBox>
        </div>
      );
    }

    return title;
  }

  return title;
}

const Modal: FunctionComponent<Props> = (props) => {
  const { title, showHelp, ...rest } = props;
  const header = (
    <Header
      title={title}
      showHelp={showHelp}
    />
  );

  return <AntdModal {...rest} title={header} />;
};

Modal.defaultProps = {
  centered: true,
  zIndex: 1050,
  width: '50%',
  keyboard: false,
  maskClosable: false,
  title: null,
};

export default Modal;
