import { OrderProductModel } from 'models/order';
import { QuoteModel } from 'models/quote';

export default function handleChangeProductInline(
  updateDataQuoteManually,
  quote: QuoteModel,
  reFetchLeadTime,
) {
  return (updatedProduct: OrderProductModel) => {
    reFetchLeadTime();
    const orderProducts = quote.orderproducts.reduce(
      (products, orderProduct) => {
        if (orderProduct.id === updatedProduct.id) {
          const { price, group, quantity } = updatedProduct;
          return products.concat({
            ...orderProduct,
            price,
            group,
            quantity,
            priceWithOptions: orderProduct.optionsPrice + price
          });
        }
        return products.concat([orderProduct]);
      },
      [],
    );

    updateDataQuoteManually({
      fetching: false,
      data: { ...quote, orderproducts: orderProducts },
      error: undefined,
    });
  };
}
