import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { CustomProductHistorySateModel } from '../../Form/CustomProduct';
import { AccountDropdownModel } from '../reducer';

export default function openCustomProductModal(
  push,
  quote: QuoteModel,
  account: AccountDropdownModel,
  groups: string[],
) {
  return () => {
    const historyLocationState: CustomProductHistorySateModel = {
      customProductForm: {
        groups,
        quote: quote.id,
        discount: account?.groupDetail?.discount ? parseFloat(account.groupDetail.discount) : 0,
      },
    };
    push(ModalRouteHash.CustomProduct, historyLocationState);
  };
}
