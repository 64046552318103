import React, { FunctionComponent, ReactNode } from 'react';
import { Space } from 'antd';

interface Props {
  leftSide: ReactNode;
  rightSide: ReactNode;
}

const SubHeader: FunctionComponent<Props> = (props) => {
  const { leftSide, rightSide } = props;

  return (
    <div className="page-header__subheader">
      <Space size="middle" className="page-header__subheader__left-side">
        {leftSide}
      </Space>
      <Space size="middle" className="page-header__subheader__right-side">
        {rightSide}
      </Space>
    </div>
  );
};

export default SubHeader;
