import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Blueprint3DProductViewer from 'lib/Blueprint3D-product-viewer';
import RenderIf from 'components/base-components/RenderIf';
import { BuilderFormModel } from 'components/pages/Builder/state';
import './styles.scss';

interface Props {
  blockUI?: boolean;
  product: BuilderFormModel;
  externalRef?: any;
  height?: number;
}

const stopPropagation = (event) => event.stopPropagation();

const ProductViewer: FunctionComponent<Props> = (props) => {
  const { product, blockUI, externalRef, height } = props;
  const [model, setModel] = useState(undefined);
  const internalRef = useRef(undefined);
  const refBp3d = externalRef || internalRef;

  useEffect(() => {
    if (product && product.id !== model?.id) {
      setModel(product);
      refBp3d.current.addItem(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (height && refBp3d.current) {
      refBp3d?.current?.onChangeSize(height - 20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  return (
    <main className="product-viewer__stage">
      <Blueprint3DProductViewer
        ref={refBp3d}
        data={{}}
      />
      <RenderIf isTrue={blockUI}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="builder__loading-screen" onClick={stopPropagation} />
      </RenderIf>
    </main>
  );
};

export default ProductViewer;
