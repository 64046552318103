import { useCallback, useEffect, useMemo, useReducer } from 'react';
import notification, { NotificationType } from 'helpers/notification';
import useGetList from 'hooks/base/get-list';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import { AccountApplicationModel } from 'models/account-application';
import accountApplicationApi from 'api/account-application';
import { handleAccountApproval, handleFilter, handleOnChange } from './actions';
import tableReducer from './reducer';

export default function useAccountApplicationTableState() {
  const [state, dispatch] = useReducer(
    tableReducer,
    {
      page: 1,
      filter: undefined,
      sortField: undefined,
      accountApplicationChanged: undefined,
      saving: false,
    },
  );

  const { page, filter, sortField } = state;

  const listParams = useMemo(() => {
    return {
      page,
      filters: {
        ordering: sortField,
        search: filter,
        search_fields: filter ? ['business_trade_name'] : undefined,
      }
    };
  }, [page, sortField, filter]);

  const {
    fetching,
    count,
    data,
    error,
    fetchList,
  } = useGetList<AccountApplicationModel>(accountApplicationApi, { queryParams: listParams });

  const updateList = useCallback(() => {
    const updateParams = {
      page: 1,
      filters: {
        ordering: sortField,
        search: filter,
        search_fields: filter ? ['business_trade_name'] : undefined,
      }
    };
    fetchList(updateParams);
  }, [filter, sortField, fetchList]);

  useEventCenterUpdate(EventChannelList.ACCOUNT_APPLICATIONS_LIST_CHANGED, updateList);

  useEffect(() => {
    if (error) {
      notification({ type: NotificationType.ERROR, message: error });
    }
  }, [error]);

  return {
    state: {
      ...state,
      fetching,
      data,
      count,
    },
    actions: {
      handleFilter: useCallback(handleFilter(dispatch), []),
      handleOnChange: useCallback(handleOnChange(dispatch), []),
      handleAccountApproval: useCallback(handleAccountApproval(dispatch), []),
    },
  };
}
