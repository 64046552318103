import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { BuilderActions } from '../reducer';

export default function selectCategory(dispatch) {
  return (category) => {
    dispatch({
      type: BuilderActions.SET_CATEGORY_SELECTED,
      payload: category,
    });
    notifyEventChannel(EventChannelList.BUILDER_CLEAR_SELECTION);
  };
}
