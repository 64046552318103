import React, { FunctionComponent } from 'react';
import Tab, { TabPane } from 'components/base-components/Tab';
import TabInstance from './Tab';

interface TableTransferProps {
  targetKeys: string[];
  onChangeTargetKeys: (nextTargetKeys: any[], dataRow: any [], direction: string, moveKey: string[]) => void;
  dataRow: any[];
}

const TabsTransfer: FunctionComponent<TableTransferProps> = (props) => {
  const { targetKeys, onChangeTargetKeys, dataRow } = props;

  return (
    <Tab defaultActiveKey="1">
      <TabPane tab="Deficiency" key="1">
        <TabInstance type="deficiency" targetKeys={targetKeys} onChange={onChangeTargetKeys} dataRow={dataRow} />
      </TabPane>
      <TabPane tab="Quote" key="2">
        <TabInstance type="quote" targetKeys={targetKeys} onChange={onChangeTargetKeys} dataRow={dataRow} />
      </TabPane>
      <TabPane tab="Order" key="3">
        <TabInstance type="order" targetKeys={targetKeys} onChange={onChangeTargetKeys} dataRow={dataRow} />
      </TabPane>
    </Tab>
  );
};

export default TabsTransfer;
