import { AddProduct3DFormActions } from '../reducer';

export default function handleNewCustomOption(dispatch) {
  return ({ option, parent }) => {
    const mappedOption = {
      dropdownItem: option,
      key: option.name,
      label: option.name,
      value: option.name,
    };

    dispatch({
      type: AddProduct3DFormActions.SET_CUSTOM_OPTION,
      payload: { option: mappedOption, parent },
    });
  };
}
