import { formatDateTimeToString } from 'helpers/formatting';
import moment from 'moment';
import { OrderModel } from 'models/order';
import productionManagerApi from 'api/production-manger';
import projectCoordinatorApi from 'api/project-coordinator';
import orderApi from 'api/orders';

export const getPayload = (order) => {
  const { readyDate, id, readyDateConfirmation } = order;
  return {
    id,
    readyDate: formatDateTimeToString(readyDate),
    readyDateConfirmation: !readyDateConfirmation,
  };
};

export const getFormData = (order: OrderModel) => {
  return {
    ...order,
    readyDate: order?.readyDate ? moment.utc(order?.readyDate) : null,
  };
};

export function onSaveManager(payload) {
  const { id, readyDate, readyDateConfirmation } = payload;
  return productionManagerApi.setReadyDateConfirmation(id, { readyDateConfirmation })
    .then((response) => readyDateConfirmation ? productionManagerApi.setReadyDate(id, { readyDate }) : response);
}

export function onSaveProject(payload) {
  const { id, readyDate, readyDateConfirmation } = payload;
  return projectCoordinatorApi.setReadyDateConfirmation(id, { readyDateConfirmation })
    .then((response) => readyDateConfirmation ? projectCoordinatorApi.setReadyDate(id, { readyDate }) : response);
}

export function onSaveCalendar(payload) {
  return orderApi.patch(payload);
}
