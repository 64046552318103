import { BuilderActions } from '../reducer';

export default function selectProduct(dispatch) {
  return (productID: string) => {
    dispatch({
      type: BuilderActions.SET_SELECTED_PRODUCT,
      payload: productID,
    });
  };
}
