import React, { FunctionComponent, useCallback } from 'react';
import { Result } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../Button';
import Modal from '../ModalV2';

const ErrorModal: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const goToDashboard = useCallback(() => push('/'), [push]);
  const goToOrigin = useCallback(() => push(pathname), [push, pathname]);

  return (
    <Modal footer={null} visible onCancel={goToOrigin}>
      <Result
        status="error"
        title="Modal Failed"
        subTitle="Please. If you are trying to open the modal from a link this will not work otherwise try again from the origin."
        extra={[
          <Button key="goToDashboard" onClick={goToDashboard} type="default">Go to Dashboard</Button>,
          <Button key="goToOrigin" onClick={goToOrigin}>Go to Origin</Button>,
        ]}
      />
    </Modal>
  );
};

export default ErrorModal;
