import { UserGroupModel } from 'models/user-group';
import { mapDropdownOptions } from 'helpers/dropdown-options';

const defaultUserGroup = {
  name: '',
  permissions: [],
};

export default function getFormData(userGroup: UserGroupModel) {
  if (userGroup) {
    const { permissionsDetail, ...rest } = userGroup;

    return {
      ...rest,
      permissions: mapDropdownOptions(permissionsDetail),
    };
  }

  return defaultUserGroup;
}
