import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { PaymentModel } from 'models/payment';
import { PaymentMethodModel } from 'models/payment-method';
import paymentApi from 'api/payment';
import paymentMethodApi from 'api/payment-method';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import useGetList from 'hooks/base/get-list';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { isValidFile, mapFileFromUrl } from 'helpers/file';
import {
  GenericMapOptions,
  mapGenericDropdownOptions,
} from 'helpers/dropdown-options';
import { formatDateToString } from 'helpers/formatting';
import { validationRules } from './validations';

export const paymentMethodsOptions: GenericMapOptions = {
  fields: { label: 'methodName', value: 'id' },
};

const defaultPayment = {
  account: undefined,
  invoice: undefined,
  method: { key: 1, value: 1, label: 'Cash' },
  chequeName: '',
  nameOnCheque: '',
  comments: '',
  checkDate: moment(),
  receivedDate: moment(),
  amount: 0,
  reference: 0,
  checkBackImage: undefined,
  checkFrontImage: undefined,
};

const getPayload = (payment) => {
  const {
    method,
    checkDate,
    checkFrontImage,
    checkBackImage,
    receivedDate,
    account,
    invoice,
    ...rest
  } = payment;

  return {
    ...rest,
    invoice: invoice.value,
    account: account.businessTradeName || account.value,
    method: method.value,
    checkDate: formatDateToString(checkDate),
    receivedDate: formatDateToString(receivedDate),
    checkBackImage: (
      checkBackImage && isValidFile(checkBackImage[0])
        ? checkBackImage
        : undefined
    ),
    checkFrontImage: (
      checkFrontImage && isValidFile(checkFrontImage[0])
        ? checkFrontImage
        : undefined
    ),
  };
};

const getFormData = (payment: PaymentModel) => {
  if (payment) {
    const {
      checkBackImage,
      checkFrontImage,
      account,
      invoice,
      method,
      receivedDate,
      checkDate,
      ...rest
    } = payment;

    return {
      ...rest,
      method: { value: method.id, label: method.methodName },
      invoice: { value: invoice, label: invoice },
      account: { value: account, label: account },
      checkBackImage: mapFileFromUrl(checkBackImage),
      checkFrontImage: mapFileFromUrl(checkFrontImage),
      receivedDate: moment(receivedDate),
      checkDate: moment(checkDate),
    };
  }
  return defaultPayment;
};

export default function usePaymentFormState(paymentId: number) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: paymentApi,
    eventChannel: EventChannelList.PAYMENTS_LIST_CHANGE,
    param: paymentId,
    getPayload,
    getFormData,
    validationRules
  }), [paymentId]);

  const {
    state: {
      formData: payment,
      errors,
      saving,
      errorFetchingEntity,
      fetchingEntity,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<PaymentModel>(useFormStateParams);

  const {
    fetching: fetchingPaymentMethods,
    data: paymentMethods,
  } = useGetList<PaymentMethodModel>(paymentMethodApi);

  useEffect(() => {
    if (errorFetchingEntity) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingEntity,
      });
    }
  }, [errorFetchingEntity]);

  return {
    state: {
      payment,
      saving,
      fetching: fetchingEntity || fetchingPaymentMethods,
      errors,
      paymentMethods: useMemo(
        () => mapGenericDropdownOptions(paymentMethods, paymentMethodsOptions),
        [paymentMethods],
      ),
    },
    actions: {
      setFormValue,
      save: saveEntity,
      setErrors,
    },
  };
}
