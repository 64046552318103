import { PaymentModel } from 'models/payment';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_TRANSACTIONS_API } = process.env;
const paymentEndpoint = `${REACT_APP_TRANSACTIONS_API}/payments/`;

const paymentApi: ApiModel<PaymentModel> = {
  ...getApi(paymentEndpoint, ApiContentType.MULTIPART, true),
};

export default paymentApi;
