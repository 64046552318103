import React, { FunctionComponent } from 'react';
import { Units } from 'models/builder';
import RenderIf from 'components/base-components/RenderIf';
import { Options, Properties } from 'components/base-components/PropertiesPanel';
import { BuilderFormModel } from '../../Builder/state';
import '../../Builder/PropertiesPanel/styles.scss';

interface Props {
  blockUI: boolean;
  product: BuilderFormModel;
  onPropertiesChange: (product: BuilderFormModel) => void;
  onOptionsChange: (product: BuilderFormModel) => void;
}

const ProductPanel: FunctionComponent<Props> = (props) => {
  const { blockUI, product, onPropertiesChange, onOptionsChange } = props;

  if (!product) {
    return (
      <aside className="builder__product-panel">
        <h3>Properties</h3>
        <div className="builder__product-panel__fields" />
        <div className="builder__product-panel__fields" />
      </aside>
    );
  }

  const { optionGroups, options, builderOptions, modelDescription } = product;

  return (
    <aside className="builder__product-panel">
      <div className="builder__product-panel__header">
        <h3>Properties</h3>
      </div>
      <Properties
        modelDescription={modelDescription}
        builderOptions={builderOptions}
        onChange={onPropertiesChange}
        unit={Units.inches}
      />
      <Options
        options={options}
        productOptions={optionGroups}
        onChange={onOptionsChange}
      />
      <RenderIf isTrue={blockUI}>
        <div className="builder__loading-screen" />
      </RenderIf>
    </aside>
  );
};

export default ProductPanel;
