import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountModel } from 'models/account';
import accountApi from 'api/account';
import { EventChannelList } from 'helpers/event-center';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import Help from 'components/forms/CommonHelp';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { logoTemplate, ownerTemplate, salesRepTemplate } from './templates';
import { permissions } from './permissions';

const tableStateParams: UseTableStateParams = {
  api: accountApi,
  eventChannel: EventChannelList.ACCOUNTS_LIST_CHANGED,
  deleteModalTitle: 'Delete Account',
  deleteModalContent: (account: AccountModel) => (
    `Are you sure that you want to delete the account
     for the ${account?.storeDetail?.name || ''} store?`
  ),
  defaultSortField: '-created_at',
  searchFields: ['store', 'owner'],
};

const AccountsList: FunctionComponent = () => {
  const history = useHistory();
  const {
    state: {
      data,
      count,
      page,
      fetching,
    },
    actions: {
      handleOnChange,
      handleFilter,
      handleDeleteRow,
    },
  } = useTableState<AccountModel>(tableStateParams);

  const handleAdd = useCallback(() => (
    history.push('#account-form')
  ), [history]);

  const handleEdit = useCallback((rowData) => (
    history.push('#account-form', { accountForm: { url: rowData.url } })
  ), [history]);

  return (
    <Page title="Accounts">
      <Help view="accounts" title="Account List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addAccount}>
          <Button id="add-account" label="Add Account" onClick={handleAdd} />
        </AccessControl>
      </PageActions>
      <DataTable
        loading={fetching}
        dataSource={data}
        page={page}
        totalRecords={count}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn
          title="Logo"
          render={logoTemplate}
          columnKey="logo"
        />
        <DataTableColumn
          columnKey="businessTradeName"
          dataIndex="businessTradeName"
          title="Business Trade Name"
        />
        <DataTableColumn
          columnKey="owner"
          title="Owner"
          render={ownerTemplate}
        />
        <DataTableColumn
          columnKey="salesRep"
          title="Sales Rep"
          render={salesRepTemplate}
          showBreakpoint={1054}
        />
        <DataTableColumn
          columnKey="group"
          dataIndex={['groupDetail', 'type']}
          title="Group"
          showBreakpoint={1138}
        />
        <DataTableColumn
          columnKey="store"
          dataIndex={['storeDetail', 'name']}
          title="Store"
          showBreakpoint={1290}
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editAccount}
          deletePermissions={permissions.deleteAccount}
          onEdit={handleEdit}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default AccountsList;
