export function handleReplace(reference, setPage, setFilter, replace) {
  return (newState: { page?: number; filter?: string }) => {
    if (reference) {
      const arrayQuery = [];
      const page = newState.page || setPage;
      const filter = newState.filter !== 'noneFilter' ? newState.filter || setFilter : undefined;
      if (page) {
        arrayQuery.push(`${reference}_page=${page}`);
      }
      if (filter) {
        arrayQuery.push(`${reference}_filter=${filter}`);
      }
      replace(`?${arrayQuery.join('&')}`);
    }
  };
}
