import { TaskFormActions } from '../reducer';

export default function setFormValue(dispatch) {
  return (task) => {
    dispatch({
      type: TaskFormActions.SET_FORM_VALUE,
      payload: task,
    });
  };
}
