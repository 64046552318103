import { AppPermissions } from 'constants/permissions';

export const permissions = {
  addOrder: [AppPermissions.add_orders],
  addQuote: [AppPermissions.add_quotes],
  addProject: [AppPermissions.add_projects],
  editQuote: [AppPermissions.change_quotes, AppPermissions.change_orderproducts],
  addOrderProducts: [AppPermissions.add_orderproducts, AppPermissions.change_quotes],
  deleteOrderProducts: [AppPermissions.delete_orderproducts],
  editOrderProducts: [AppPermissions.change_orderproducts],
  addExtraCharge: [AppPermissions.add_extracharges],
  deleteExtraCharge: [AppPermissions.delete_extracharges],
  editExtraCharge: [AppPermissions.change_extracharges],
  editShipping: [AppPermissions.change_shipment, AppPermissions.change_shippingquestionnaire],
  viewQuoteFiles: [AppPermissions.view_quotefiles],
  editOrder: [AppPermissions.change_orders],
  addDeficiencyItems: [AppPermissions.add_deficiencyitems],
  addUser: [AppPermissions.add_user],
  addAccount: [AppPermissions.add_account],
  editAccount: [AppPermissions.change_account],
};
