import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import productApi from 'api/product';
import { CustomProductFormActions } from '../reducer';

export function getTemplate(dispatch) {
  return () => {
    dispatch({ type: CustomProductFormActions.START_GETTING_TEMPLATE });

    const onSuccess = () => {
      const message = 'Template Successfully Downloaded';
      notification({ type: NotificationType.SUCCESS, message });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CustomProductFormActions.FINISH_GETTING_TEMPLATE });
    };

    return productApi.getXlsTemplate()
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
