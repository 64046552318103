import { combineReducers } from '@reduxjs/toolkit';

import login from './reducers/login';
import Account from './reducers/registerReducer';
import forgetPassword from './reducers/forgetPasswordReducer';
import setPassword from './reducers/setPassword';
import accountApplications from './reducers/account-applications';
import { logoutSuccess } from './actions';

const appReducer = combineReducers({
  login,
  setPassword,
  Account,
  forgetPassword,
  accountApplications,
});

const rootReducer = (state, action) => {
  if (action.type === logoutSuccess.type) {
    // Reset redux state on logout
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
