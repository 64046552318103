import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { GroupedReportModel, InvoiceReportModel } from 'models/invoice-report';
import invoiceReportsApi from 'api/invoice-reports';
import { formatDateToString } from 'helpers/formatting';
import notification, { NotificationType } from 'helpers/notification';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import useGetList, { GetListOptions } from 'hooks/base/get-list';
import handleGroupByChange from './actions/handle-group-by-change';
import handleTableFilters from './actions/handle-table-filters';
import prepareGroupedData from './actions/prepare-grouped-data';
import buildFilterOptions from './actions/build-filter-options';
import { mapAccount, mapGroup, mapRepOwner } from './actions/map-functions';
import buildFilterAction from './actions/build-filter-action';
import {
  filterAccounts,
  filterBatches,
  filterGroups,
  filterRepOwners,
} from './actions/filter-functions';
import sortByTotal, { totalSortDirections } from './actions/sort-by-total';
import { Filters, GroupBy, TableFilters } from './types';

export * from './types';

const initialFilters = (): Filters => ({
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month'),
});

export default function useSalesReportState() {
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [tableFilters, setTableFilters] = useState<TableFilters>({});

  const params = useMemo<GetListOptions>(() => ({
    queryParams: {
      filters: {
        groupBy: filters.groupBy,
        ordering: 'created_date',
        created_date__date__range: [filters.startDate, filters.endDate]
          .map((date) => formatDateToString(date))
          .join(','),
      }
    }
  }), [filters]);

  const {
    fetching,
    data,
    error,
  } = useGetList<InvoiceReportModel | GroupedReportModel>(
    invoiceReportsApi.list,
    params,
  );

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: error,
      });
    }
  }, [error]);

  return {
    state: {
      fetching,
      totalSortDirections,
      filters,
      tableFilters,
      data: useMemo(() => (
        filters.groupBy
          ? prepareGroupedData(data as GroupedReportModel[])
          : data
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ), [data]),
      accountOptions: useMemo<DropdownOptionModel[]>(() => (
        buildFilterOptions(data, !!filters.groupBy, mapAccount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ), [data]),
      repOptions: useMemo(() => (
        buildFilterOptions(data, !!filters.groupBy, mapRepOwner)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ), [data]),
      groupOptions: useMemo<DropdownOptionModel[]>(() => (
        buildFilterOptions(data, !!filters.groupBy, mapGroup)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ), [data]),
    },
    actions: {
      setFilters,
      sortByTotal,
      handleGroupByChange,
      filterAccounts: useCallback(
        buildFilterAction(
          filters.groupBy?.value === GroupBy.ACCOUNT,
          filterAccounts,
          filterBatches,
        ),
        [filters.groupBy],
      ),
      filterGroups: useCallback(
        buildFilterAction(
          filters.groupBy?.value === GroupBy.GROUP,
          filterGroups,
          filterBatches,
        ),
        [filters.groupBy],
      ),
      filterReps: useCallback(
        buildFilterAction(
          filters.groupBy?.value === GroupBy.REP,
          filterRepOwners,
          filterBatches,
        ),
        [filters.groupBy],
      ),
      setAccountFilters: useCallback(
        handleTableFilters('accounts', setTableFilters),
        [],
      ),
      setGroupFilters: useCallback(
        handleTableFilters('groups', setTableFilters),
        [],
      ),
      setRepFilters: useCallback(
        handleTableFilters('reps', setTableFilters),
        [],
      ),
    },
  };
}
