import React, { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import { generateUID } from 'helpers/generators';
import Label from 'components/base-components/Label';
import InputError from 'components/base-components/InputError';
import Spinner from 'components/base-components/Spinner';
import './styles.scss';

export interface Props extends InputProps {
  label?: string;
  required?: boolean;
  error?: string;
  inputClassName?: string;
  mR?: boolean;
  loading?: boolean;
  reference?: any;
  notMB?: boolean;
}

const Input: FunctionComponent<Props> = (props) => {
  const {
    id,
    label,
    name,
    className,
    inputClassName,
    required,
    error,
    mR,
    loading,
    suffix,
    disabled,
    reference,
    notMB,
    ...inputProps
  } = props;

  const wrapperClassNames = classnames('input__wrapper', { mR, error, notMB }, className);
  const inputSuffix = loading ? <Spinner size="small" centered={false} /> : suffix;
  const inputName = useMemo(() => `${name}-${generateUID()}`, [name]);

  return (
    <div className={wrapperClassNames}>
      <Label text={label} forInput={id} required={required} />
      <AntInput
        id={id}
        ref={reference}
        name={inputName}
        autoComplete={inputName}
        suffix={inputSuffix}
        className={inputClassName}
        disabled={disabled || loading}
        {...inputProps}
      />
      <InputError error={error} />
    </div>
  );
};

Input.defaultProps = {
  placeholder: '',
};

export default Input;
