import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum ManualCalcFormActions {
  SET_PRODUCT = 'SET_PRODUCT',
  ADD_PRODUCT = 'ADD_PRODUCT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  SET_ERRORS = 'SET_ERRORS',
  START_CALCULATIONS = 'START_CALCULATIONS',
  FINISH_CALCULATIONS = 'FINISH_CALCULATIONS',
}

export interface ManualProductModel {
  model: DropdownOptionModel;
  quantity: number;
}

export interface ManualCalcFormState {
  product: ManualProductModel;
  productList: ManualProductModel[];
  errors: {
    model: string;
    quantity: string;
  };
  calculating: boolean;
}

export default function manualCalcReducer(state: ManualCalcFormState, action): ManualCalcFormState {
  const { type, payload } = action;

  switch (type as ManualCalcFormActions) {
    case ManualCalcFormActions.SET_PRODUCT:
      return { ...state, product: payload };
    case ManualCalcFormActions.ADD_PRODUCT:
      return {
        ...state,
        productList: state.productList.concat(state.product),
        product: { model: { value: '', label: '' } } as ManualProductModel,
        errors: {} as any,
      };
    case ManualCalcFormActions.DELETE_PRODUCT:
      return {
        ...state,
        productList: state.productList.filter((product) => product.model !== payload),
      };
    case ManualCalcFormActions.SET_ERRORS:
      return { ...state, errors: payload };
    case ManualCalcFormActions.START_CALCULATIONS:
      return { ...state, calculating: true };
    case ManualCalcFormActions.FINISH_CALCULATIONS:
      return { ...state, calculating: false };
    default:
      return state;
  }
}
