import { UpdateShippingActions } from '../reducer';

export function initializeInitialState(dispatch, shipmentInstance) {
  const shipment = {
    ...shipmentInstance,
    shipmentType: {
      value: shipmentInstance.shipmentType?.id,
      label: shipmentInstance.shipmentType?.name,
      dropdownItem: shipmentInstance.shipmentType
    },
    shippingZone: {
      value: shipmentInstance.shippingZone?.id,
      label: shipmentInstance.shippingZone?.zoneName,
    },
  };
  let payload = { shipment } as any;
  if (shipment.flatRate) {
    payload = { ...payload, flatRate: shipment.flatRate };
  }
  if (shipmentInstance.shippingAddress) {
    const address = {
      ...shipmentInstance.shippingAddress,
      province: {
        value: shipmentInstance.shippingAddress.province?.id,
        label: shipmentInstance.shippingAddress.province?.name || null
      }
    };
    const selectedCountry = {
      value: shipmentInstance.shippingAddress.province?.country?.id,
      label: shipmentInstance.shippingAddress.province?.country?.country || null
    };
    payload = { ...payload, address, selectedCountry };
  }
  dispatch({ type: UpdateShippingActions.SET_INITIAL_STATE, payload });
}
