import { useEffect, useMemo } from 'react';
import { DropdownOptionModel, mapGenericDropdownOptions } from 'helpers/dropdown-options';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import productApi from 'api/product';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { ProductModel } from 'models/product';
import getPayload from './actions/get-payload';
import getFormData from './actions/get-form-data';
import validationRules from './validations';

export function mapCategoriesOptions(data: any[]) {
  return mapGenericDropdownOptions(data, { fields: { value: 'url', label: 'name' } });
}

export function mapManufacturersOptions(data: any[]) {
  return mapGenericDropdownOptions(data, { fields: { value: 'url', label: 'companyName' } });
}

export interface ProductFormModel{
  name: string;
  nameFr: string;
  model: string;
  mpn: string;
  price: string;
  cost: string;
  weight: string;
  description: string;
  descriptionFr: string;
  status: boolean;
  category: DropdownOptionModel;
  manufacturer: DropdownOptionModel;
  image: File[];
}

export default function useProductFormState(productUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: productApi,
    eventChannel: EventChannelList.PRODUCTS_LIST_CHANGED,
    param: productUrl,
    getPayload,
    getFormData,
    validationRules
  }), [productUrl]);

  const {
    state: {
      formData: product,
      errors,
      saving,
      errorFetchingEntity: errorFetchingProduct,
      fetchingEntity: fetchingProduct,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<ProductModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingProduct) {
      notification({ type: NotificationType.ERROR, message: errorFetchingProduct });
    }
  }, [errorFetchingProduct]);

  return {
    state: {
      product,
      savingProduct: saving,
      fetchingFormData: fetchingProduct,
      errors,
    },
    actions: {
      setFormValue,
      saveProduct: saveEntity,
      setErrors,
    },
  };
}
