import { commonRules, Rule, RuleType } from 'helpers/form-validations';

const { required, email, website } = commonRules;

const accountNameRule: Rule = {
  type: RuleType.MatchRegExp,
  value: /^[\s\w-]+$/i,
  message: 'This field can\'t have special characters',
};

const businessTradeName = [required, accountNameRule];

export const fullFormRules = {
  businessLegalName: [required],
  owner: [required],
  website: [website],
  businessEmail: [
    required,
    email,
  ],
  accountsPayableEmail: [email],
  paymentTerms: [required],
};

export const quickFormRules = {
  companyName: [required],
  firstName: [required],
  lastName: [required],
  email: [required, email],
};

export const getFullFormRules = (isEdit) => {
  return isEdit ? fullFormRules : { ...fullFormRules, businessTradeName };
};
