import React from 'react';
import snakeCase from 'lodash.snakecase';
import { Divider } from 'antd';
import { ProductModel } from 'models/product';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import '../../styles.scss';

const dividerStyles = { margin: '8px 0' };
const formattedModelDescription = (modelDescription) => modelDescription
  ? Object.keys(modelDescription)
    .reduce((template, description) => `${template}${snakeCase(description)}-`, '')
    .slice(0, -1)
  : '';

export const dropdownRender = (menu) => {
  if (menu?.props?.options?.[0]?.dropdownItem && menu?.props?.options?.[0]?.value !== 'waypoint') {
    const product = menu?.props?.options?.[0]?.dropdownItem;
    const modelDescription =
      product?.category?.levelDescription?.replaceAll(',', '-') ||
      formattedModelDescription(product?.modelDescription);

    return (
      <div className="add-product__style-label">
        <p className="add-product__label">
          {modelDescription}
        </p>
        <Divider style={dividerStyles} />
        {menu}
      </div>
    );
  }
  return menu;
};

export function mapProducts(product: ProductModel[]) {
  return mapGenericDropdownOptions(
    product,
    {
      fields: { value: 'url', label: 'model' },
      copyFullItem: true,
    }
  );
}
