import React, { FunctionComponent, useMemo } from 'react';
import { ShipmentAddressModel, ShipmentContactModel } from 'models/shipment';
import { OrderModel, OrderProductModel } from 'models/order';
import { formatDisplayDate } from 'helpers/formatting';
import RenderIf from 'components/base-components/RenderIf';

interface Props {
  products: OrderProductModel[];
  order: OrderModel;
  contact: ShipmentContactModel;
  address: ShipmentAddressModel;
}

const OrderInfo: FunctionComponent<Props> = (props) => {
  const {
    order: { createdDate },
    products,
    contact,
    address,
  } = props;

  // const {
  //   firstName,
  //   lastName,
  //   telephone,
  // } = contact || {};
  //
  // const {
  //   locationName,
  //   postalCode,
  //   province: { name },
  //   streetName,
  //   streetNumber,
  // } = address || { province: { name: '' } };

  const cncCount = useMemo(
    () => products.filter(prod => !!prod.cnc).length,
    [products],
  );

  return (
    <table className="work-order__order-info">
      <tr>
        <td className="work-order__order-info__header">Order Info</td>
        <td className="work-order__order-info__header">Shipping Address</td>
      </tr>
      <tr>
        <td>
          <div className="work-order__order-info__items">
            <span>
              <b>Date Added: </b>
              {formatDisplayDate(createdDate)}
            </span>
            <span>
              <b>Shipping Method: </b>
              Delivery
            </span>
            <span>
              <b>CNC Items: </b>
              {cncCount}
            </span>
          </div>
        </td>
        <td>
          <div className="work-order__order-info__items">
            <RenderIf isTrue={!!contact}>
              <span>{`${contact?.firstName} ${contact?.lastName}`}</span>
            </RenderIf>
            <RenderIf isTrue={!!address}>
              <span>{address?.locationName}</span>
              <span>{`${address?.streetNumber} ${address?.streetName}`}</span>
              <span>{`${address?.province?.name} ${address?.postalCode}`}</span>
            </RenderIf>
            <RenderIf isTrue={!!contact}>
              <span>{contact?.telephone}</span>
            </RenderIf>
          </div>
        </td>
      </tr>
    </table>
  );
};

export default OrderInfo;
