import { AssignFilesFormActions } from '../reducer';

export function setFormValue(dispatch) {
  return (data, fileName) => {
    return (value) => {
      const payload = {
        [fileName]: {
          ...data,
          folders: value
        }
      };
      dispatch({ type: AssignFilesFormActions.SET_FORM_VALUE, payload });
    };
  };
}
