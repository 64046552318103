import React from 'react';

export default function modelTemplate(row) {
  const options = Object.keys(row.options).map((option) => (
    <li key={`${option}`}>
      {`${option}: ${row.options[option]?.name}`}
    </li>
  ));

  return (
    <>
      <span className="text-muted">{row.model}</span>
      <ul>
        {options}
      </ul>
    </>
  );
}
