import { AxiosResponse } from 'axios';
import flowPlannerApi from 'api/flow-planner';
import { resolveApiErrorMessage } from 'api/base';
import orderApi, { CreateOrderResponse } from 'api/orders';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { QuoteDropdownModel, QuoteViewActions } from '../reducer';
import { AssignFilesHistorySateModel } from '../../Form/AssignFiles';

export default function convertToOrder(dispatch, quote: QuoteDropdownModel, push) {
  return () => {
    dispatch({ type: QuoteViewActions.START_CONVERTING_TO_ORDER });
    const payload = {
      quote: quote.value,
      orderStatus: 1,
    };

    const handleOrderConverted = () => {
      notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED);
      dispatch({ type: QuoteViewActions.CONVERT_TO_ORDER });
      notification({
        type: NotificationType.SUCCESS,
        message: 'Quote converted to Order successfully',
      });
      const historyLocationState: AssignFilesHistorySateModel = {
        assignFilesForm: {
          quoteId: quote.value,
        },
      };
      push(ModalRouteHash.AssignFilesForm, historyLocationState);
    };

    const handleOrderIsMissingFields = (errors) => {
      if (errors.error) {
        notification({
          type: NotificationType.ERROR,
          message: resolveApiErrorMessage(errors.error),
        });
        dispatch({ type: QuoteViewActions.TOGGLE_CONVERT_MODAL });
      } else {
        dispatch({
          type: QuoteViewActions.SET_ORDER_MISSING_VALUES,
          payload: errors,
        });
      }
    };

    const onSuccess = (response: AxiosResponse<CreateOrderResponse>) => {
      const { data: { success, error } } = response;

      if (success) {
        handleOrderConverted();
        if (!!quote?.data?.flowplannerId) {
          flowPlannerApi.patch({ id: quote.data?.flowplannerId, staffLocked: true });
        }
      } else {
        handleOrderIsMissingFields(error);
      }
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
      dispatch({ type: QuoteViewActions.TOGGLE_CONVERT_MODAL });
    };

    return orderApi.create(payload)
      .then(onSuccess)
      .catch(onError);
  };
}
