import { ExtraChargeModel } from 'models/extra-charge';
import { QuoteProductListActions } from '../reducer';

export default function handleAddExtraCharge(dispatch, quoteId, extraCharges: ExtraChargeModel[]) {
  return () => {
    const initialExtraCharge: ExtraChargeModel = {
      chargeName: '',
      amount: '0',
      chargeType: '',
      quote: quoteId,
    };

    const payload: ExtraChargeModel[] = extraCharges.concat([initialExtraCharge]);

    dispatch({ type: QuoteProductListActions.SET_EXTRA_CHARGES, payload });
  };
}
