import { LoggedUserDetailsModel } from 'models/user';
import { AccountModel } from 'models/account';
import { SaveDesignFormActions } from '../reducer';

export default function completeState(dispatch, user: LoggedUserDetailsModel, account: AccountModel) {
  const payload = {
    account: {
      value: account.businessTradeName,
      label: account.businessLegalName,
      businessTradeName: account.businessTradeName,
    },
    client: { value: user.id, label: `${user.name}` }
  };

  dispatch({
    type: SaveDesignFormActions.UPDATE_FORM_VALUE,
    payload
  });
}
