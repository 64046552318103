import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Moment } from 'moment';
import { OrderModel, PurchaseOrderModel } from 'models/order';
import { ManufacturerModel } from 'models/manufacturer';
import purchaseOrderApi from 'api/purchase-order';
import { DropdownOptionModel, mapGenericDropdownOptions } from 'helpers/dropdown-options';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import validationsRules from './validations';
import getFormData from './actions/get-form-data';
import getPayload from './actions/get-payload';

export function mapManufacturersOptions(manufacturers: ManufacturerModel[]) {
  return mapGenericDropdownOptions(manufacturers, { fields: { value: 'companyName', label: 'companyName' } });
}

export function mapOrderOptions(orders: OrderModel[]) {
  return mapGenericDropdownOptions(orders, { fields: { value: 'id', label: 'id' } });
}

export interface PurchaseOrderFormModel {
  manufacturer: DropdownOptionModel;
  dateSent: Moment;
  supplierReadyDate: Moment;
  paintShop: boolean;
  order: DropdownOptionModel;
  tag: string;
}

export default function usePurchaseOrderFormState(id: string, orderId, fromProjectCoordinator?: boolean) {
  const { push } = useHistory();

  const useFormStateParams: UseFormStateParams = useMemo(() => {
    return {
      api: purchaseOrderApi,
      eventChannel: EventChannelList.PURCHASE_ORDERS_LIST_CHANGED,
      param: id,
      validationRules: validationsRules,
      getFormData: getFormData(orderId),
      getPayload,
      noGoBackOnSuccess: !id && !fromProjectCoordinator,
      onSuccess: fromProjectCoordinator || id ? undefined : ({ data: { id: purchaseOrderId } }) => {
        push(`purchase-order/${purchaseOrderId}${ModalRouteHash.PurchaseOrderItemForm}`, {
          purchaseOrderItemForm: {
            id: null,
            purchaseOrderId,
          },
        });
      }
    };
  }, [id, orderId, push, fromProjectCoordinator]);

  const {
    state: {
      formData: purchaseOrder,
      errors,
      saving,
      errorFetchingEntity: errorFetchingPurchaseOrder,
      fetchingEntity: fetchingPurchaseOrder,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<PurchaseOrderModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingPurchaseOrder) {
      notification({ type: NotificationType.ERROR, message: errorFetchingPurchaseOrder });
    }
  }, [errorFetchingPurchaseOrder]);

  return {
    state: {
      purchaseOrder,
      savingPurchaseOrder: saving,
      errors,
      fetchingFormData: fetchingPurchaseOrder,
    },
    actions: {
      setFormValue,
      setErrors,
      savePurchaseOrder: saveEntity,
    },
  };
}
