import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import storeApi from 'api/store';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { StoreModel } from 'models/store';
import { EventChannelList } from 'helpers/event-center';
import Help from 'components/forms/CommonHelp';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { StoreFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: storeApi,
  deleteModalTitle: 'Delete Store',
  deleteModalContent: (storeToDelete: StoreModel) => `Are you sure that you want to delete the Store ${storeToDelete ? storeToDelete.nameLegal : ''}?`,
  searchFields: ['name', 'name_legal'],
  eventChannel: EventChannelList.STORE_SETTING_CHANGED,
};

const ellipsisAddressTemplate = (address: string) => address && address.length > 21 ? `${address.slice(0, 20)}...` : address;

const StoreList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<StoreModel>(useTableStateParams);

  const openStoreFormModal = (store?: StoreModel | any) => {
    const historyLocationState: StoreFormHistorySateModel = {
      storeForm: {
        storeUrl: store ? store.url : undefined,
      },
    };
    push(ModalRouteHash.StoreSetting, historyLocationState);
  };

  return (
    <Page title="Store">
      <Help view="store" title="Store List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addStore}>
          <Button
            onClick={openStoreFormModal}
            label="Add Store"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn dataIndex="name" columnKey="name" title="Name" sorter />
        <DataTableColumn dataIndex="email" columnKey="email" title="Email" sorter />
        <DataTableColumn dataIndex="interactEmail" columnKey="interactEmail" title="Int Email" sorter />
        <DataTableColumn
          dataIndex="location"
          columnKey="location"
          title="location"
          showBreakpoint={801}
        />
        <DataTableColumn
          dataIndex="domain"
          columnKey="domain"
          title="Domain"
          sorter
          showBreakpoint={1160}
        />
        <DataTableColumn
          dataIndex="address"
          columnKey="address"
          render={ellipsisAddressTemplate}
          title="Address"
          showBreakpoint={1354}
        />
        <DataTableColumn
          dataIndex="phone"
          columnKey="phone"
          title="Phone"
          sorter
          showBreakpoint={1464}
        />
        <DataTableColumn
          dataIndex="currencyCode"
          columnKey="currencyCode"
          width={90}
          title="Currency Code"
          sorter
          showBreakpoint={1616}
        />
        <DataTableColumn
          dataIndex="taxNumber"
          columnKey="taxNumber"
          title="Tax Number"
          sorter
          showBreakpoint={1747}
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editStore}
          deletePermissions={permissions.deleteStore}
          onEdit={openStoreFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default StoreList;
