import { QuoteModel } from 'models/quote';
import { QuoteProductsDataModel } from '..';

export default function prepareQuoteProductsData(quote: QuoteModel) {
  const expandedRowKeys = ['group-0'];
  const initialOrderProducts = [
    {
      id: 'group-0',
      group: '',
      products: [],
      subtotal: undefined,
    },
    {
      id: 'subtotal-0',
      group: undefined,
      products: undefined,
      subtotal: 0,
    }
  ];

  let groupId = 1;
  const quoteProducts: QuoteProductsDataModel[] = quote.orderproducts?.reduce(
    (orderProducts, orderProduct) => {
      const groupIndex = orderProducts.findIndex((op) => op.group === orderProduct.group);
      if (groupIndex === -1) {
        const newOrderProducts = orderProducts.concat(
          [
            {
              id: `group-${groupId}`,
              group: orderProduct.group,
              products: [orderProduct],
              subtotal: undefined,
            },
            {
              id: `subtotal-${groupId}`,
              group: undefined,
              products: undefined,
              subtotal: orderProduct.priceWithOptions * orderProduct.quantity,
            }
          ]
        );
        expandedRowKeys.push(`group-${groupId}`);
        groupId += 1;
        return newOrderProducts;
      }
      orderProducts[groupIndex].products.push(orderProduct);
      const productFullPrice = orderProduct.priceWithOptions * orderProduct.quantity;
      // eslint-disable-next-line no-param-reassign
      orderProducts[groupIndex + 1].subtotal += productFullPrice;
      return orderProducts;
    },
    initialOrderProducts,
  );

  const orderTotal = quoteProducts
    .reduce((total, quoteProduct) => (
      quoteProduct.subtotal ? total + quoteProduct.subtotal : total
    ), 0);

  return { quoteProducts, expandedRowKeys, orderTotal };
}
