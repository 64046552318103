/* eslint-disable */
import * as THREE from "three";
import Core from "../core";

export var Edge = function (scene, edge, controls) {
  const scope = this;
  // var scene = scene;
  // var edge = edge;
  // var controls = controls;
  const { wall } = edge;
  const { front } = edge;

  let planes = [];
  let basePlanes = []; // always visible
  let texture = null;

  let wallMaterial = null;
  let fillerMaterial = null;

  THREE.ImageUtils.loadTexture(
    `${Core.Configuration.getStringValue(
      "defaultPathPrefix"
    )}/rooms/textures/walllightmap.png`
  );
  const fillerColor = 0xdddddd;
  const sideColor = 0xcccccc;
  const baseColor = 0xdddddd;

  this.visible = false;

  document.addEventListener(Core.BP3D_EVENT_CONFIG_CHANGED, (e) => {
    const { detail } = e;
    if (!detail) return;
    if (
      detail.hasOwnProperty(Core.configXRayMode) &&
      wallMaterial &&
      fillerMaterial
    ) {
      let opacity = 1;
      if (detail[Core.configXRayMode]) opacity = 0.3;
      wallMaterial.opacity = opacity;
      fillerMaterial.opacity = opacity;
    }
  });

  this.remove = function () {
    let index = null;
    index = edge.redrawCallbacks.indexOf(redraw);
    // edge.redrawCallbacks.remove(redraw);
    edge.redrawCallbacks.splice(index, 1);
    index = controls.cameraMovedCallbacks.indexOf(updateVisibility);
    // controls.cameraMovedCallbacks.remove(updateVisibility);
    controls.cameraMovedCallbacks.splice(index, 1);
    removeFromScene();
  };

  function init() {
    edge.redrawCallbacks.push(redraw);
    controls.cameraMovedCallbacks.push(updateVisibility);
    updateTexture();
    updatePlanes();
    addToScene();
  }

  function redraw() {
    removeFromScene();
    updateTexture();
    updatePlanes();
    addToScene();
  }

  function removeFromScene() {
    planes.forEach((plane) => {
      scene.remove(plane);
    });
    basePlanes.forEach((plane) => {
      scene.remove(plane);
    });
    planes = [];
    basePlanes = [];
  }

  function addToScene() {
    planes.forEach((plane) => {
      scene.add(plane);
    });
    basePlanes.forEach((plane) => {
      scene.add(plane);
    });
    updateVisibility();
  }

  function updateVisibility() {
    // finds the normal from the specified edge
    const start = edge.interiorStart();
    const end = edge.interiorEnd();
    const x = end.x - start.x;
    const y = end.y - start.y;
    // rotate 90 degrees CCW
    const normal = new THREE.Vector3(-y, 0, x);
    normal.normalize();

    // setup camera
    const position = controls.object.position.clone();
    const focus = new THREE.Vector3(
      (start.x + end.x) / 2,
      0,
      (start.y + end.y) / 2
    );
    const direction = position.sub(focus).normalize();

    // find dot
    const dot = normal.dot(direction);

    // update visible
    scope.visible = dot >= 0;

    // show or hide plans
    planes.forEach((plane) => {
      plane.visible = scope.visible;
    });

    updateObjectVisibility();
  }

  function updateObjectVisibility() {
    wall.items.forEach((item) => {
      item.updateEdgeVisibility(scope.visible, front);
    });
    wall.onItems.forEach((item) => {
      item.updateEdgeVisibility(scope.visible, front);
    });
  }

  function updateTexture(callback) {
    // callback is fired when texture loads
    callback =
      callback ||
      function () {
        scene.needsUpdate = true;
      };
    const textureData = edge.getTexture();
    const { url, width, height } = textureData;

    texture = new THREE.TextureLoader().load(url);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;

    const textureWidth = width || 1;
    const textureHeight = height || 1;
    texture.repeat.set(1 / textureWidth, 1 / textureHeight);
  }

  function updatePlanes() {
    wallMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      // ambientColor: 0xffffff, TODO_Ekki
      // ambient: scope.wall.color,
      side: THREE.FrontSide,
      map: texture,
      transparent: true,
      opacity: Core.Configuration.getBooleanValue(Core.configXRayMode)
        ? 0.3
        : 1,
      // lightMap: lightMap TODO_Ekki
    });

    fillerMaterial = new THREE.MeshStandardMaterial({
      color: fillerColor,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: Core.Configuration.getBooleanValue(Core.configXRayMode)
        ? 0.3
        : 1,
    });

    // exterior plane
    planes.push(
      makeWall(
        edge.exteriorStart(),
        edge.exteriorEnd(),
        edge.exteriorTransform,
        edge.invExteriorTransform,
        fillerMaterial
      )
    );

    // interior plane
    planes.push(
      makeWall(
        edge.interiorStart(),
        edge.interiorEnd(),
        edge.interiorTransform,
        edge.invInteriorTransform,
        wallMaterial
      )
    );

    // bottom
    // put into basePlanes since this is always visible
    basePlanes.push(buildFiller(edge, 0, THREE.BackSide, baseColor));

    // top
    planes.push(buildFiller(edge, wall.height, THREE.DoubleSide, fillerColor));

    // sides
    planes.push(
      buildSideFillter(
        edge.interiorStart(),
        edge.exteriorStart(),
        wall.height,
        sideColor
      )
    );

    planes.push(
      buildSideFillter(
        edge.interiorEnd(),
        edge.exteriorEnd(),
        wall.height,
        sideColor
      )
    );
  }

  // start, end have x and y attributes (i.e. corners)
  function makeWall(start, end, transform, invTransform, material) {
    const v1 = toVec3(start);
    const v2 = toVec3(end);
    const v3 = v2.clone();
    v3.y = wall.height;
    const v4 = v1.clone();
    v4.y = wall.height;

    const points = [v1.clone(), v2.clone(), v3.clone(), v4.clone()];

    points.forEach((p) => {
      p.applyMatrix4(transform);
    });

    const shape = new THREE.Shape([
      new THREE.Vector2(points[0].x, points[0].y),
      new THREE.Vector2(points[1].x, points[1].y),
      new THREE.Vector2(points[2].x, points[2].y),
      new THREE.Vector2(points[3].x, points[3].y),
    ]);

    // add holes for each wall item
    wall.items.forEach((item) => {
      const boundingBox = item.getBounding();

      // var pos = item.position.clone();
      const pos = new THREE.Vector3(
        (boundingBox.max.x + boundingBox.min.x) / 2,
        (boundingBox.max.y + boundingBox.min.y) / 2,
        (boundingBox.max.z + boundingBox.min.z) / 2
      );

      pos.applyMatrix4(transform);
      const { halfSize } = item;
      const min = halfSize.clone().multiplyScalar(-1);
      const max = halfSize.clone();
      min.add(pos);
      max.add(pos);

      const holePoints = [
        new THREE.Vector2(min.x, min.y),
        new THREE.Vector2(max.x, min.y),
        new THREE.Vector2(max.x, max.y),
        new THREE.Vector2(min.x, max.y),
      ];

      shape.holes.push(new THREE.Path(holePoints));
    });

    const geometry = new THREE.ShapeGeometry(shape);

    geometry.vertices.forEach((v) => {
      v.applyMatrix4(invTransform);
    });

    // make UVs
    const totalDistance = Core.Utils.distance(v1.x, v1.z, v2.x, v2.z);
    const { height } = wall;
    geometry.faceVertexUvs[0] = [];

    function vertexToUv(vertex) {
      const x =
        Core.Utils.distance(v1.x, v1.z, vertex.x, vertex.z) / totalDistance;
      const y = vertex.y / height;
      return new THREE.Vector2(x, y);
    }

    geometry.faces.forEach((face) => {
      const vertA = geometry.vertices[face.a];
      const vertB = geometry.vertices[face.b];
      const vertC = geometry.vertices[face.c];
      geometry.faceVertexUvs[0].push([
        vertexToUv(vertA),
        vertexToUv(vertB),
        vertexToUv(vertC),
      ]);
    });

    geometry.faceVertexUvs[1] = geometry.faceVertexUvs[0];

    geometry.computeFaceNormals();
    geometry.computeVertexNormals();

    const mesh = new THREE.Mesh(geometry, material);

    mesh.castShadow = true;
    mesh.receiveShadow = true;

    mesh.material.transparent = true;
    return mesh;
  }

  function buildSideFillter(p1, p2, height, color) {
    const points = [
      toVec3(p1),
      toVec3(p2),
      toVec3(p2, height),
      toVec3(p1, height),
    ];

    const geometry = new THREE.Geometry();
    points.forEach((p) => {
      geometry.vertices.push(p);
    });
    geometry.faces.push(new THREE.Face3(0, 1, 2));
    geometry.faces.push(new THREE.Face3(0, 2, 3));

    const fillerMaterial = new THREE.MeshStandardMaterial({
      color,
      side: THREE.DoubleSide,
    });

    const filler = new THREE.Mesh(geometry, fillerMaterial);
    return filler;
  }

  function buildFiller(edge, height, side, color) {
    const points = [
      toVec2(edge.exteriorStart()),
      toVec2(edge.exteriorEnd()),
      toVec2(edge.interiorEnd()),
      toVec2(edge.interiorStart()),
    ];

    const fillerMaterial = new THREE.MeshStandardMaterial({
      color,
      side,
    });

    const shape = new THREE.Shape(points);
    const geometry = new THREE.ShapeGeometry(shape);

    const filler = new THREE.Mesh(geometry, fillerMaterial);
    filler.rotation.set(Math.PI / 2, 0, 0);
    filler.position.y = height;
    return filler;
  }

  function toVec2(pos) {
    return new THREE.Vector2(pos.x, pos.y);
  }

  function toVec3(pos, height) {
    height = height || 0;
    return new THREE.Vector3(pos.x, height, pos.y);
  }

  init();
};
