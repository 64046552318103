import { resolveApiErrorMessage } from 'api/base';
import { SubOption } from 'models/option';
import subOptionApi from 'api/sub-option';
import notification, { NotificationType } from 'helpers/notification';
import { SubOptionFormActions } from '../reducer';

export default function onDelete(dispatch, oldData: SubOption[]) {
  return (setDeleting, subOption: SubOption) => {
    setDeleting(true);

    const onSuccess = () => {
      const message = 'SubOption Successfully Deleted';
      notification({ type: NotificationType.SUCCESS, message });
      const payload = oldData.filter(subOpt => subOpt.url !== subOption.url);
      dispatch({ type: SubOptionFormActions.SET_DATA, payload });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      setDeleting(false);
    };

    return subOptionApi.delete(subOption.url)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
