import React, { FunctionComponent } from 'react';
import { Paragraph } from 'components/base-components/Typography';
import HelpModal from 'components/experience/HelpModal';
import TutorialsTable from 'components/experience/TutorialsTable';

interface Props {
  view: string;
  title: string;
  description?: string;
}

const Help: FunctionComponent<Props> = (props) => {
  const { view, description, title } = props;
  const helpDescriptions = description || `This page is used to manage all ${view.replace(/-/g, ' ')}`;
  return (
    <HelpModal title={title} name={view} className="quote-view__help">
      <Paragraph>
        {`${helpDescriptions}. The table below show a lists videos
        to walk you through the how-to of almost all
        the actions available in this page`}
      </Paragraph>
      <TutorialsTable view={view} />
    </HelpModal>
  );
};

export default Help;
