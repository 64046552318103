/* eslint-disable */
import { Floor } from './floor';
import { Edge } from './edge';
import Core from '../core';

export var Floorplan = function (scene, floorplan, controls) {
  const scope = this;

  this.scene = scene;
  this.floorplan = floorplan;
  this.controls = controls;

  this.floors = [];
  this.edges = [];

  floorplan.fireOnUpdatedRooms(redraw);

  this.updateHeight = (height) => {
    if (height > 0) {
      Core.Configuration.setValue('wallHeight', height);
      this.floorplan.walls.forEach(wall => wall.height = height);
      redraw();
    }
  }

  function redraw() {
    // clear scene
    scope.floors.forEach((floor) => {
      floor.removeFromScene();
    });

    scope.edges.forEach((edge) => {
      edge.remove();
    });
    scope.floors = [];
    scope.edges = [];

    // draw floors
    scope.floorplan.getRooms().forEach((room) => {
      const threeFloor = new Floor(scene, room);
      scope.floors.push(threeFloor);
      threeFloor.addToScene();
    });

    // draw edges
    scope.floorplan.wallEdges().forEach((edge) => {
      const threeEdge = new Edge(
        scene, edge, scope.controls
      );
      scope.edges.push(threeEdge);
    });
  }
};
