import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Input as AntInput } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import Label from 'components/base-components/Label';
import InputError from 'components/base-components/InputError';
import './styles.scss';

interface Props extends TextAreaProps{
  id?: string;
  label?: string;
  className?: string;
  inputClassName?: string;
  mR?: boolean;
  required?: boolean;
  error?: string;
}

const TextArea: FunctionComponent<Props> = (props) => {
  const {
    label,
    id,
    className,
    inputClassName,
    mR,
    required,
    error,
    ...inputProps
  } = props;
  const wrapperClassNames = classnames(
    'text-area__wrapper',
    { mR, error },
    className,
  );

  return (
    <div className={wrapperClassNames}>
      <Label text={label} forInput={id} required={required} />
      <AntInput.TextArea
        id={id}
        className={inputClassName}
        {...inputProps}
      />
      <InputError error={error} />
    </div>
  );
};

TextArea.defaultProps = {
  autoSize: { minRows: 2, maxRows: 20 },
};

export default TextArea;
