import moment from 'moment';
import { installerFormActions } from '../reducer';

export default function handleOnChange(dispatch, initialInstallerBooking) {
  return (installerBooking?: any) => {
    const payload = installerBooking.id
      ? {
        ...installerBooking,
        installer: { value: installerBooking.installer?.id, name: installerBooking.companyName },
        installationDate: moment(installerBooking.installationDate),
      }
      : initialInstallerBooking;
    dispatch({ type: installerFormActions.SET_FORM_VALUE, payload });
  };
}
