import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { BuilderFormModel } from '../state';
import { CopyProductsHistorySateModel } from '../CopyForm';

export function openCopyForm(push, product: BuilderFormModel) {
  return () => {
    const historyLocationState: CopyProductsHistorySateModel = {
      copyProductsForm: {
        product,
      },
    };
    push(ModalRouteHash.CopyProducts, historyLocationState);
  };
}
