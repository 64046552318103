/* eslint-disable */
import * as THREE from 'three';
// import { unitScale } from '../core/dimensioning';

export var Floor = function (scene, room) {
  const scope = this;

  this.room = room;

  let floorPlane = null;
  // var roofPlane = null;

  init();

  function init() {
    scope.room.fireOnFloorChange(redraw);
    floorPlane = buildFloor();
    // roofs look weird, so commented out
    // roofPlane = buildRoof();
  }

  function redraw() {
    scope.removeFromScene();
    floorPlane = buildFloor();
    scope.addToScene();
  }

  function buildFloor() {
    const textureSettings = scope.room.getTexture();
    // setup texture
    const floorTexture = new THREE.TextureLoader().load(textureSettings.url);
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;

    const textureWidth = textureSettings.width || 1;
    const textureHeight = textureSettings.height || 1;
    floorTexture.repeat.set(1 / textureWidth, 1 / textureHeight);

    const floorMaterialTop = new THREE.MeshPhongMaterial({
      map: floorTexture,
      side: THREE.DoubleSide,
      // ambient: 0xffffff, TODO_Ekki
      color: 0xCCCCCC,
      specular: 0x0A0A0A
    });

    // const textureScale = textureSettings.scale;
    // http://stackoverflow.com/questions/19182298/how-to-texture-a-three-js-mesh-created-with-shapegeometry
    // scale down coords to fit 0 -> 1, then rescale

    const points = [];
    scope.room.interiorCorners.forEach((corner) => {
      points.push(new THREE.Vector2(
        corner.x,
        corner.y
      ));
    });
    const shape = new THREE.Shape(points);

    const geometry = new THREE.ShapeGeometry(shape);

    const floor = new THREE.Mesh(geometry, floorMaterialTop);

    floor.rotation.set(Math.PI / 2, 0, 0);
    // floor.scale.set(textureScale, textureScale, textureScale);
    floor.receiveShadow = true;
    floor.castShadow = false;
    return floor;
  }

  // function buildRoof() {
  //   // setup texture
  //   var roofMaterial = new THREE.MeshBasicMaterial({
  //     side: THREE.FrontSide,
  //     color: 0xe5e5e5
  //   });

  //   var points = [];
  //   scope.room.interiorCorners.forEach((corner) => {
  //     points.push(new THREE.Vector2(
  //       corner.x,
  //       corner.y));
  //   });
  //   var shape = new THREE.Shape(points);
  //   var geometry = new THREE.ShapeGeometry(shape);
  //   var roof = new THREE.Mesh(geometry, roofMaterial);

  //   roof.rotation.set(Math.PI / 2, 0, 0);
  //   roof.position.y = 250;
  //   return roof;
  // }

  this.addToScene = function () {
    scene.add(floorPlane);
    // scene.add(roofPlane);
    // hack so we can do intersect testing
    scene.add(room.floorPlane);
  };

  this.removeFromScene = function () {
    scene.remove(floorPlane);
    // scene.remove(roofPlane);
    scene.remove(room.floorPlane);
  };
};
