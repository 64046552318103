import { AxiosResponse } from 'axios';
import { ShipmentDTO } from 'models/shipment';
import { resolveApiErrorMessage } from 'api/base';
import shipmentApi from 'api/shipment';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { generateQueryString } from 'helpers/query-params';
import { QuoteViewActions } from '../reducer';

export default function openShipmentDQ(dispatch, shipment: ShipmentDTO) {
  return () => {
    dispatch({ type: QuoteViewActions.START_FETCHING_HASH });

    const onSuccess = (response: AxiosResponse<string>) => {
      const { data: hash } = response;
      const baseUrl = window.location.origin;

      const queryParams = generateQueryString({
        filters: {
          cli: hash,
          notify: true,
        },
      });

      window.open(
        `${baseUrl}/questionnaire?${queryParams}`,
        '_blank',
        'resizable,scrollbars'
      );
      window.addEventListener('message', (event) => {
        const { data } = event;

        if (data && data.source && data.source === 'dq') {
          notifyEventChannel(EventChannelList.SHIPPING_PROJECT_CHANGE);
        }
      });
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const onFinally = () => {
      dispatch({ type: QuoteViewActions.STOP_FETCHING_HASH });
    };

    return shipmentApi
      .getShipmentHash(shipment.id)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
