import { useEffect, useMemo } from 'react';
import { AccountGroupModel } from 'models/account-group';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import accountGroupApi from 'api/account-group';

const defaultAccountGroup: AccountGroupModel = {
  type: '',
  discount: '0',
};

const getPayload = (accountGroup: AccountGroupModel) => {
  const { discount, ...rest } = accountGroup;
  return {
    ...rest,
    discount: parseFloat(discount),
  };
};

const getFormData = (accountGroup: AccountGroupModel) => {
  return accountGroup || defaultAccountGroup;
};

export default function useAccountGroupFormState(accountGroupUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: accountGroupApi,
    eventChannel: EventChannelList.ACCOUNT_GROUPS_LIST_CHANGED,
    param: accountGroupUrl,
    getPayload,
    getFormData,
  }), [accountGroupUrl]);

  const {
    state: {
      formData: accountGroup,
      errors,
      saving,
      errorFetchingEntity: errorFetchingAccountGroup,
      fetchingEntity: fetchingAccountGroup,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<AccountGroupModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingAccountGroup) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingAccountGroup,
      });
    }
  }, [errorFetchingAccountGroup]);

  return {
    state: {
      accountGroup,
      savingAccountGroup: saving,
      fetchingFormData: fetchingAccountGroup,
      errors,
    },
    actions: {
      setFormValue,
      saveAccountGroup: saveEntity,
      setErrors,
    },
  };
}
