import { useEffect, useState } from 'react';
import { QuoteModel } from 'models/quote';
import { OrderProductModel } from 'models/order';
import prepareQuoteProductsData from './actions/prepare-quote-products-data';

export interface QuoteProductsDataModel {
  id: string;
  group?: string;
  products?: OrderProductModel[];
}

export default function useProductsListState(quote: QuoteModel) {
  const [state, setState] = useState({
    quoteProducts: [],
    expandedRowKeys: [],
  });

  useEffect(() => {
    if (quote) {
      setState(prepareQuoteProductsData(quote));
    }
  }, [quote]);

  return { state };
}
