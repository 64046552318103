import { ShippingZoneModel } from 'models/shipment';
import { ApiModel, ApiContentType } from './base';
import getApi from './base/get-api';

const { REACT_APP_SHIPMENT_API } = process.env;

const endpoint = `${REACT_APP_SHIPMENT_API}/shippingzones/`;

const shippingZoneApi: ApiModel<ShippingZoneModel> = {
  ...getApi<ShippingZoneModel>(endpoint, ApiContentType.JSON, true),
};

export default shippingZoneApi;
