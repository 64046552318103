import React from 'react';
import { Waypoint } from 'react-waypoint';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import Spinner from '../../../Spinner';

export default function getOptions(
  children: any,
  onInfinityScroll: () => void,
  useInfinityScroll: boolean,
  options: DropdownOptionModel[]
) {
  if (children) {
    return undefined;
  }

  const shouldAddWaypoint = (
    onInfinityScroll &&
    useInfinityScroll &&
    options &&
    options.length > 0
  );

  if (shouldAddWaypoint) {
    return options.concat([
      {
        value: 'waypoint',
        label: (
          <div className="select__infinity-scroll">
            <Spinner size="small" centered={false} />
            <Waypoint
              key="cursor"
              onEnter={onInfinityScroll}
              bottomOffset="0px"
            />
          </div>
        ),
      } as any,
    ]);
  }

  return options;
}
