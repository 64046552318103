import { resolveApiErrorMessage } from 'api/base';
import quoteApi from 'api/quote';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { validateEntity } from 'helpers/form-validations';
import { CopyQuoteFormActions } from '../reducer';
import validationRules from '../validations';

export function copyQuote(
  dispatch,
  formData: { comments: string },
  quoteId: number,
  goBack,
) {
  return () => {
    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: CopyQuoteFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    dispatch({ type: CopyQuoteFormActions.START_COPING });

    const onSuccess = (response) => {
      const { data } = response;

      const message = 'Quote Successfully copied';
      notification({ type: NotificationType.SUCCESS, message });
      notifyEventChannel(EventChannelList.QUOTE_PAGE_NEW_QUOTE, data);
      notifyEventChannel(EventChannelList.ASYNC_SELECT_REFETCH);
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CopyQuoteFormActions.FINISH_COPING });
    };

    return quoteApi.copyQuote(quoteId, formData)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
