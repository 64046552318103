import { QuickAccountModel } from 'models/account';

export enum CreateQuickAccountFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_CREATING = 'START_CREATING',
  FINISH_CREATING = 'FINISH_CREATING',
}

interface CreateQuickAccountFormState {
  formData: QuickAccountModel;
  creating: boolean;
  errors: any;
}

export default function createQuickAccountFormReducer(
  state: CreateQuickAccountFormState, action
): CreateQuickAccountFormState {
  const { type, payload } = action;

  switch (type as CreateQuickAccountFormActions) {
    case CreateQuickAccountFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case CreateQuickAccountFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, creating: false };
    case CreateQuickAccountFormActions.START_CREATING:
      return { ...state, creating: true };
    case CreateQuickAccountFormActions.FINISH_CREATING:
      return { ...state, creating: false };
    default:
      return state;
  }
}
