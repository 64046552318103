import { PurchaseOrderDTO } from 'models/order';
import moment, { Moment } from 'moment';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import purchaseOrderApi from 'api/purchase-order';
import { formatDateTimeToString } from 'helpers/formatting';

function getPayload(purchaseOrder, todayDate: Moment) {
  const { id, manufacturer, order } = purchaseOrder;
  return {
    id,
    dateSent: formatDateTimeToString(todayDate),
    manufacturer: manufacturer?.companyName,
    order: order?.id,
  };
}

export default function handleSetDateSent(setUpdating, updateDataManually, data: PurchaseOrderDTO[]) {
  return (purchaseOrder: PurchaseOrderDTO) => {
    const todayDate = moment();
    setUpdating(true);

    const onSuccess = () => {
      const message = 'Date Sent successfully updated';
      notification({ type: NotificationType.SUCCESS, message });
      updateDataManually(data.map((item) => {
        if (item.id === purchaseOrder.id) {
          return {
            ...item,
            dateSent: todayDate,
          };
        }
        return item;
      }));
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      setUpdating(false);
    };

    const payload = getPayload(purchaseOrder, todayDate);

    return purchaseOrderApi.update(payload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
