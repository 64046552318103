import { ApiContentType } from 'api/base';
import getApi from 'api/base/get-api';
import { ExtraChargeModel } from 'models/extra-charge';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/extra_charges/`;

const extraChargeApi = getApi<ExtraChargeModel>(endpoint, ApiContentType.MULTIPART, true);

export default extraChargeApi;
