import { PurchaseOrderModel } from 'models/order';
import { QuoteModel } from 'models/quote';
import { QueryParams } from 'helpers/query-params';
import purchaseOrderApi from 'api/purchase-order';
import { PaintProductModel, ThirdPartyPaintState } from '..';

function resolveProductsFromOrder(
  purchaseOrder: PurchaseOrderModel,
  quote: QuoteModel,
): PaintProductModel[] {
  const { poItem: poItems } = purchaseOrder;
  const { orderproducts } = quote;

  return poItems.map((item) => {
    const {
      model,
      description,
      quantity,
      orderProduct,
      sentToPaint: dateSent,
    } = item;
    let options = [];

    if (orderProduct) {
      const product = orderproducts.find((p) => p.id === orderProduct);
      options = product.options;
    }

    return {
      model,
      description,
      options,
      quantity,
      dateSent,
    };
  });
}

export default async function resolveProducts(
  setState: (state: ThirdPartyPaintState) => void,
  quote: QuoteModel,
) {
  const { orderNum } = quote;
  const orderId = orderNum.split('-')[1];

  const params: QueryParams = {
    filters: {
      order: orderId,
      paintShop: true,
    },
  };

  try {
    const { data: { results: purchaseOrderDTOs } } = await purchaseOrderApi.list(params);
    const purchaseOrders = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const dto of purchaseOrderDTOs) {
      // eslint-disable-next-line no-await-in-loop
      const { data: purchaseOrder } = await purchaseOrderApi.get(dto.id);
      purchaseOrders.push(purchaseOrder);
    }

    const products = purchaseOrders.map((po) => resolveProductsFromOrder(po, quote));

    setState({
      fetching: false,
      products: products.flat(),
      error: undefined,
    });
  } catch (error) {
    setState({
      fetching: true,
      products: [],
      error,
    });
  }
}
