import { commonRules } from 'helpers/form-validations';
import { AnswersModel } from '../types';

const {
  required,
  email
} = commonRules;

export const contactRules = {
  firstName: [required],
  lastName: [required],
  telephone: [required],
  email: [required, email],
};

export const addressRules = {
  streetNumber: [required],
  streetName: [required],
  city: [required],
  postalCode: [required],
  province: [required],
};

function validateBooleanAnswer(value) {
  return value === 0 ? required.message : undefined;
}

function validateOptionalField(parentField: string) {
  return (value, answers: AnswersModel) => {
    if (answers[parentField] === 1) {
      return !value ? required.message : undefined;
    }
    return undefined;
  };
}

function validateInverseOptionalField(parentField: string) {
  return (value, answers: AnswersModel) => {
    if (answers[parentField] === -1) {
      return !value ? required.message : undefined;
    }
    return undefined;
  };
}

function validateAccessMethod(value) {
  return !value.id ? required.message : undefined;
}

function validateStairsField(value, answers: AnswersModel) {
  const { accessMethod: { id } } = answers;
  return id === 2 && !value ? required.message : undefined;
}

function validateElevatorAnswer(value, answers: AnswersModel) {
  const { accessMethod: { id } } = answers;
  return id === 3 && value === 0 ? required.message : undefined;
}

function validateAccessMethodDetail(value, answers: AnswersModel) {
  const { accessMethod: { id } } = answers;
  return id === 4 && !value ? required.message : undefined;
}

export const answersRules = {
  afterHours: [validateBooleanAnswer],
  areaPrepared: [validateBooleanAnswer],
  areaPreparedDetails: [validateInverseOptionalField('areaPrepared')],
  furnitureReconfigurationRequired: [validateBooleanAnswer],
  furnitureReconfigurationDetails: [
    validateOptionalField('furnitureReconfigurationRequired'),
  ],
  specificDeliveryHours: [validateBooleanAnswer],
  specificHoursStart: [validateOptionalField('specificDeliveryHours')],
  specificHoursEnd: [validateOptionalField('specificDeliveryHours')],
  streetOffload: [validateBooleanAnswer],
  parkingAvailable: [validateBooleanAnswer],
  accessCardsRequired: [validateBooleanAnswer],
  loadingDockAvailable: [validateBooleanAnswer],
  loadingDetails: [validateInverseOptionalField('loadingDockAvailable')],
  loadingDockBookingRequired: [validateOptionalField('loadingDockAvailable')],
  loadingDockBookingRequiredDetails: [
    validateOptionalField('loadingDockBookingRequired'),
  ],
  accessMethod: [validateAccessMethod],
  accessMethodDetails: [validateAccessMethodDetail],
  elevatorReservationRequired: [validateElevatorAnswer],
  stairs: [validateStairsField],
};
