import React from 'react';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import { AccountApplicationModel, AccountApplicationStatus } from 'models/account-application';
import { useHistory } from 'react-router-dom';
import Page from 'components/base-components/Page';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import Help from 'components/forms/CommonHelp';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import useAccountApplicationTableState from './state';
import ownerNameTemplate from './templates/owner-name-template';
import statusTemplate from './templates/status-template';
import { permissions } from './permissions';

export interface AccountApplicationHistorySateModel {
  accountApplication: {
    accountApplicationUrl: string;
  };
}

export default function AccountApplicationsList() {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleOnChange,
      handleFilter,
      handleAccountApproval,
    }
  } = useAccountApplicationTableState();

  const openAccountApplicationDetailsModal = (accountApplication: AccountApplicationModel) => {
    const historyLocationState: AccountApplicationHistorySateModel = {
      accountApplication: {
        accountApplicationUrl: accountApplication ? accountApplication.url : undefined,
      },
    };
    push(ModalRouteHash.AccountApplicationDetails, historyLocationState);
  };

  const handleApproveAccount = (rowData: AccountApplicationModel) => {
    handleAccountApproval(rowData, AccountApplicationStatus.approved);
  };

  const handleDenyAccount = (rowData: AccountApplicationModel) => {
    handleAccountApproval(rowData, AccountApplicationStatus.denied);
  };

  return (
    <Page title="Account Applications">
      <Help view="account-applications" title="Account Applications List" />
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn
          columnKey="business_trade_name"
          title="Business Trade Name"
          dataIndex="businessTradeName"
          sorter
        />
        <DataTableColumn
          columnKey="owner_first_name"
          title="Owner Name"
          render={ownerNameTemplate}
          sorter
        />
        <DataTableColumn
          dataIndex="ownerEmail"
          columnKey="owner_email"
          title="Owner Email"
          showBreakpoint={725}
          sorter
        />
        <DataTableColumn
          dataIndex="businessLegalName"
          columnKey="business_legal_name"
          title="Business Legal Name"
          showBreakpoint={1164}
          sorter
        />
        <DataTableColumn
          dataIndex="businessType"
          columnKey="business_type"
          title="Business Type"
          showBreakpoint={1433}
          sorter
        />
        <DataTableColumn
          dataIndex="bankName"
          columnKey="bank_name"
          title="Bank Name"
          showBreakpoint={1433}
          sorter
        />
        <DataTableColumn
          columnKey="status"
          title="Status"
          render={statusTemplate}
          showBreakpoint={1535}
          sorter
        />
        <DataTableColumn isActionColumn onShowDetails={openAccountApplicationDetailsModal}>
          <DataTableAction
            permissions={permissions.editApplication}
            onClick={handleApproveAccount}
            icon="fa fa-check-circle"
          />
          <DataTableAction
            danger
            permissions={permissions.editApplication}
            onClick={handleDenyAccount}
            icon="fa fa-ban"
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
}
