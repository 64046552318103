import { AxiosResponse } from 'axios';
import { ThreeModel } from 'models/three-model';
import { ApiContentType, ApiModel, patch } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/three_model/`;

interface ThreeModelApi extends ApiModel<ThreeModel> {
  patchModel: (model) => Promise<AxiosResponse>;
}

const threeModelApi: ThreeModelApi = {
  ...getApi(
    endpoint,
    ApiContentType.MULTIPART,
    false,
  ),
  patchModel: (model) => {
    return patch(`${endpoint}${model.id}/`, model, ApiContentType.MULTIPART);
  },
};

export default threeModelApi;
