import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { resolveApiErrorMessage, ApiModel, ListResponseModel } from 'api/base';
import { QueryParams } from 'helpers/query-params';

interface GetListState<T> {
  fetching: boolean;
  data: T[];
  count: number;
  error: string;
}

export interface GetListOptions {
  queryParams?: QueryParams;
  preventAutoFetch?: boolean;
}

type setStateFunctionType = (oldState: GetListState<any>) => GetListState<any>;
type setStateParam = GetListState<any> | setStateFunctionType;
type setStateType = (param: setStateParam) => void;

const fetchList = async (api, params, setState: setStateType, fetching: boolean) => {
  if (!fetching) {
    setState((oldState) => ({ ...oldState, fetching: true }));
  }

  const action = typeof api === 'function' ? api : api.list;
  try {
    const response = await action(params);
    setState({
      fetching: false,
      data: response.data.results,
      count: response.data.count,
      error: undefined,
    });
    return response.data.results;
  } catch (error) {
    const message = resolveApiErrorMessage((error as any).response);
    setState({
      fetching: false,
      data: [],
      count: undefined,
      error: message,
    });
    return undefined;
  }
};

export default function useGetList<T>(
  api: ApiModel<T> | ((params: any) => Promise<AxiosResponse<ListResponseModel>>),
  options: GetListOptions = { queryParams: undefined, preventAutoFetch: false },
) {
  const { queryParams, preventAutoFetch } = options;
  const [state, setState] = useState<GetListState<T>>({
    fetching: !preventAutoFetch,
    data: [],
    count: undefined,
    error: undefined,
  });

  useEffect(() => {
    if (!preventAutoFetch) {
      fetchList(api, queryParams, setState, state.fetching);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, queryParams, preventAutoFetch]);

  const updateDataManually = (newData: any[]) => {
    setState((oldState) => ({
      ...oldState,
      data: newData,
    }));
  };

  return {
    ...state,
    fetchList: useCallback(
      (params?) => fetchList(api, params, setState, state.fetching),
      [api, state.fetching],
    ),
    updateDataManually: useCallback(
      (newData: any[]) => updateDataManually(newData),
      [],
    ),
  };
}
