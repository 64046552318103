import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader as AntPageHeader } from 'antd';
import SubHeader from './SubHeader';
import './styles.scss';
import RenderIf from '../RenderIf';

interface Props {
  title?: string;
  href?: string;
  mainActions?: ReactNode;
  secondaryActions?: ReactNode;
}

const PageHeader: FunctionComponent<Props> = (props) => {
  const { title, href, mainActions, secondaryActions } = props;
  const history = useHistory();

  const onGoBack = useCallback(() => {
    if (href) {
      history.push(href);
    } else {
      history.goBack();
    }
  }, [href, history]);

  return (
    <RenderIf isTrue={!!title}>
      <AntPageHeader
        title={title}
        onBack={onGoBack}
        className="page-header"
      >
        <SubHeader leftSide={mainActions} rightSide={secondaryActions} />
      </AntPageHeader>
    </RenderIf>
  );
};

export default PageHeader;
