import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import manufacturerApi from 'api/manufacturer';
import { ManufacturerModel } from 'models/manufacturer';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Upload from 'components/base-components/Upload';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import NumberInput from 'components/base-components/NumberInput';
import { validationRules } from './validations';
import usePartFormState from './state';

export interface PartFormHistorySateModel {
  PartForm: {
    partId: number;
  };
}

export function mapManufacturersOptions(manufacturers: ManufacturerModel[]) {
  return mapGenericDropdownOptions(manufacturers, { fields: { value: 'companyName', label: 'companyName' } });
}

const PartForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { PartForm: { partId } } = state as PartFormHistorySateModel;

  const {
    state: {
      savingPart,
      part,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      savePart,
      setErrors,
    },
  } = usePartFormState(partId);

  const modalTitle = useMemo(
    () => partId ? 'Edit Part' : 'Create Part',
    [partId],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={savePart}
      confirmLoading={savingPart}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={part}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            name="mpn"
            id="mpn"
            label="Mpn"
            placeholder="Enter Mpn"
          />
          <Field
            id="description"
            name="description"
            label="Name"
            placeholder="Enter Description"
          />
          <Field
            id="name"
            name="name"
            label="TFG Name"
            placeholder="Enter Name"
            required
          />
          <Field
            id="units"
            name="units"
            label="Units"
            placeholder="Enter Units"
            required
          />
          <Field
            component={NumberInput}
            id="price"
            name="price"
            label="Price"
            placeholder="Enter Price"
            allowDecimals
            min={0}
            required
          />
          <Field
            component={AsyncSelectV2}
            name="manufacturer"
            label="Manufacturer"
            listAction={manufacturerApi.list}
            mapFunction={mapManufacturersOptions}
            required
          />
          <Field
            component={NumberInput}
            id="standardOrder"
            name="standardOrder"
            label="Standard"
            placeholder="Enter Standard Order"
            required
          />
          <Field
            component={NumberInput}
            id="minOrder"
            name="minOrder"
            label="Min"
            placeholder="Enter Min Order"
            required
          />
          <Field
            component={Upload}
            name="image"
            id="image"
            label="Choose Image"
            accept="image/png, .jpeg, .jpg"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default PartForm;
