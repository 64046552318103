import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { AccountModel } from 'models/account';
import accountApi from 'api/account';
import useGetList from 'hooks/base/get-list';
import { getLoggedUser } from 'helpers/get-logged-user';
import notification, { NotificationType } from 'helpers/notification';
import setFormValue from './actions/set-form-value';
import setErrors from './actions/set-errors';
import completeState from './actions/complete-state';
import onSubmit from './actions/on-submit';
import saveDesignReducer from './reducer';

export default function useSaveDesignFormState(goBack, products) {
  const [state, dispatch] = useReducer(saveDesignReducer, {
    flowPlanner: {
      account: undefined,
      client: undefined,
      projectName: ''
    },
    errors: undefined,
  });
  const { flowPlanner } = state;
  const loggedUser = useMemo(() => getLoggedUser(), []);
  const options = useMemo(() => ({
    queryParams: {
      filters: {
        search: loggedUser.id,
        searchFields: 'users'
      }
    },
    preventAutoFetch: loggedUser.isStaff
  }), [loggedUser]);

  const {
    fetching: fetchingAccount,
    data: account,
    error: accountError,
  } = useGetList<AccountModel>(accountApi, options);

  useEffect(() => {
    if (!loggedUser?.isStaff && account.length > 0) {
      completeState(dispatch, loggedUser, account[0]);
    }
  }, [loggedUser, account]);

  useEffect(() => {
    if (accountError) {
      notification({
        type: NotificationType.ERROR,
        message: accountError,
      });
    }
  }, [accountError]);

  return {
    state: {
      ...state,
      loggedUser,
      fetchingFormData: fetchingAccount,
    },
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      handleOnSubmit: useCallback(
        onSubmit(dispatch, flowPlanner, goBack, products),
        [flowPlanner, goBack, products]
      )
    },
  };
}
