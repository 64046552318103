import { useReducer, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  setFormValue,
  setValidationErrors,
  createQuickUser,
} from './actions';
import createQuickUserFormReducer from './reducer';

export * from './validations';

export default function useCreateUserFormState(account) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    createQuickUserFormReducer,
    {
      formData: {
        email: '',
        firstName: '',
        lastName: '',
      },
      errors: {},
      creating: false,
    },
  );
  const { formData } = state;
  return {
    state,
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      setValidationErrors: useCallback(setValidationErrors(dispatch), []),
      createQuickUser: useCallback(
        createQuickUser(dispatch, formData, account, goBack),
        [formData, account],
      ),
    },
  };
}
