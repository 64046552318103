export enum ModalRouteHash {
  CopyQuote = '#copy-quote',
  ReuseQuote = '#reuse-quote',
  CustomProduct = '#custom-product',
  UserForm = '#user-form',
  CNCCalc = '#cnc-calc',
  AccountForm = '#account-form',
  UserGroupForm = '#user-group-form',
  TermForm = '#term-form',
  ProductForm = '#product-form',
  ProductDetails = '#product-details',
  OptionForm = '#option-form',
  OptionGroupForm = '#option-group-form',
  ManufacturerForm = '#manufacturer-form',
  CategoryForm = '#category-form',
  AccountGroupForm = '#account-group-form',
  AccountApplicationDetails = '#account-application-details',
  DeficiencyItemForm = '#deficiency-item-form',
  QuickAccountForm = '#quick-account-form',
  QuickUserForm = '#quick-user-form',
  PurchaseOrderForm = '#purchase-order-form',
  PurchaseOrderItemForm = '#purchase-order-item-form',
  AddProduct = '#add-product',
  AddProduct3D = '#add-product-3D',
  EditOptions = '#edit-options',
  SendEmailForm = '#send-email',
  CreateQuote = '#create-quote',
  CreateProject = '#create-project',
  UpdateShipping = '#update-shipping',
  ApplyOptions = '#apply-options',
  ReceivePayment = '#receive-payment',
  OptionDetails = '#option-details',
  FileViewer = '#file-viewer',
  ThreeModelForm = '#three-model',
  ShippingZoneSetting = '#shipping-zone-setting',
  StoreSetting = '#store-setting',
  CopyProducts = '#copy-products',
  ProductList = '#products-list',
  TaskManager = '#task-manager',
  Calendar = '#calendar',
  CopyOrderProducts = '#copy-order-products',
  PartForm = '#form-part',
  PartDetail = '#detail-part',
  MaterialsDetails = '#material-details',
  ConfirmReadyDate = '#confirm-ready-date',
  InstallerForm = '#installer-form',
  InstallerBookingForm = '#installer-booking-form',
  PaymentForm = '#form-payment',
  AttachmentDocumentForm = '#form-attachment-document',
  SubOptionForm = '#sub-option',
  PurchaseOrderProd = '#po-items-manager',
  SetInstallerBooking = '#set-installer-booking',
  Tutorial = '#tutorial',
  NotesForm = '#notes',
  TaskForm = '#task',
  TaskTemplateForm = '#task-template',
  PropertySetting = '#property-setting-form',
  SheetForm = '#sheet-setting-form',
  InventoryForm = '#inventory-form',
  AddDesignToCart = '#add-to-cart',
  SetGroupBuilder = '#set-group-builder',
  CutListFrom = '#cut-list-from',
  AssignFilesForm = '#assign-files-form',
  CustomEventFrom = '#custom-event-form',
}
