export enum CNCListActions {
  SET_SELECTED_ROWS = 'SET_SELECTED_ROWS',
  START_CALCULATING_BULK = 'START_CALCULATING_BULK',
  FINISH_CALCULATING_BULK = 'FINISH_CALCULATING_BULK',
  ADD_PROCESSING_ORDER = 'ADD_PROCESSING_ORDER',
  REMOVE_PROCESSING_ORDER = 'REMOVE_PROCESSING_ORDER',
}

interface CNCListState {
  selectedRows: number[];
  calculatingBulk: boolean;
  ordersInProcess: number[];
}

export default function cncListReducer(state: CNCListState, action): CNCListState {
  const { type, payload } = action;

  switch (type as CNCListActions) {
    case CNCListActions.SET_SELECTED_ROWS:
      return { ...state, selectedRows: payload };
    case CNCListActions.START_CALCULATING_BULK:
      return { ...state, calculatingBulk: true };
    case CNCListActions.FINISH_CALCULATING_BULK:
      return { ...state, calculatingBulk: false };
    case CNCListActions.ADD_PROCESSING_ORDER:
      return { ...state, ordersInProcess: state.ordersInProcess.concat([payload]) };
    case CNCListActions.REMOVE_PROCESSING_ORDER:
      return {
        ...state,
        ordersInProcess: state.ordersInProcess.filter(order => order !== payload),
      };
    default:
      return state;
  }
}
