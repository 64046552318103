import React, { FunctionComponent } from 'react';

interface Props {
  job: string;
}

const ProjectDetails: FunctionComponent<Props> = (props) => {
  const { job } = props;

  return (
    <>
      <h1 className="questionnaire__header centered">Project Information</h1>
      <table className="questionnaire__project-info">
        <tbody>
          <tr>
            <td className="questionnaire__project-info__label">
              Job:
            </td>
            <td className="questionnaire__project-info__label">
              {job}
            </td>
          </tr>
          <tr>
            <td className="questionnaire__project-info__label">
              Delivery Method:
            </td>
            <td className="questionnaire__project-info__label">
              Deliver & Install
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ProjectDetails;
