import React, { FunctionComponent, useMemo } from 'react';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { getLoggedUserId } from 'helpers/get-logged-user';
import hasPermissions from 'helpers/permissons';
import orderApi from 'api/orders';
import { OrderDTO } from 'models/order';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';

export const orderTableStateParams: UseTableStateParams = {
  api: orderApi,
  searchFields: ['quote', 'job_title'],
  defaultSortField: '-order_num',
};

const TotalSales: FunctionComponent = () => {
  const queryParams = useMemo(() => ({
    quote__project__rep_owner: getLoggedUserId(),
  }), []);
  const {
    state: {
      data,
      fetching,
      count,
    },
    actions: {
      handleOnChange,
      handleFilter,
    }
  } = useTableState<OrderDTO>({ ...orderTableStateParams, queryParams });
  const linkToOrder = useMemo(() => hasPermissions(['Sales-Orders', 'Dealer', 'Sales']) ? (order) => `/quote/${order.quote?.id}` : undefined, []);

  return (
    <div className="chart-col">
      <DataTable
        loading={fetching}
        dataSource={data}
        totalRecords={count}
        onFilter={handleFilter}
        onChange={handleOnChange}
        size="small"
      >
        <DataTableColumn title="Order" dataIndex="id" link={linkToOrder} sorter />
        <DataTableColumn
          title="Job Title"
          dataIndex="jobTitle"
          columnKey="job_title"
        />
        <DataTableColumn
          isCurrency
          title="Total"
          dataIndex="totalWithTax"
          columnKey="totalWithTax"
        />
        <DataTableColumn
          title="Date Added"
          dataIndex="createdDate"
          columnKey="created_date"
          sorter
          isDate
        />
        <DataTableColumn
          title="Est. Ready Date"
          dataIndex="readyDate"
          columnKey="ready_date"
          sorter
          isDate
        />
      </DataTable>
    </div>
  );
};

export default TotalSales;
