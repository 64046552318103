import { validateEntity } from 'helpers/form-validations';
import { validationRules } from '../validations';
import { ManualCalcFormActions } from '../reducer';

export default function addProduct(dispatch, product) {
  return () => {
    const { errors, hasErrors } = validateEntity(product, validationRules);

    if (hasErrors) {
      dispatch({
        type: ManualCalcFormActions.SET_ERRORS,
        payload: errors,
      });
    } else {
      dispatch({
        type: ManualCalcFormActions.ADD_PRODUCT,
      });
    }
  };
}
