import { AxiosResponse } from 'axios';
import { resolveApiErrorMessage } from 'api/base';
import quoteApi from 'api/quote';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { validateEntity } from 'helpers/form-validations';
import { CreateQuoteFormActions } from '../reducer';
import validationRules from '../validations';

export function createQuote(dispatch, formData: {comments: string}, project, goBack) {
  return () => {
    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: CreateQuoteFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    dispatch({ type: CreateQuoteFormActions.START_CREATING });

    const onSuccess = (response: AxiosResponse) => {
      const { data } = response;
      const message = 'Quote Successfully Created';
      notification({ type: NotificationType.SUCCESS, message });
      notifyEventChannel(EventChannelList.QUOTE_PAGE_NEW_QUOTE, data);
      notifyEventChannel(EventChannelList.ASYNC_SELECT_REFETCH);
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CreateQuoteFormActions.FINISH_CREATING });
    };

    return quoteApi.create({ ...formData, project })
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
