import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import productApi from 'api/product';
import subOptionApi from 'api/sub-option';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import RenderIf from 'components/base-components/RenderIf';
import Form, { Field } from 'components/base-components/Form';
import Modal from 'components/base-components/ModalV2';
import Select from 'components/base-components/Select';
import TreeSelect from 'components/base-components/TreeSelect';
import AsyncAutoComplete from 'components/base-components/AsyncAutoComplete';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import Spinner from 'components/base-components/Spinner';
import Label from 'components/base-components/Label';
import useAddProductState, { mapProducts, validationRules, dropdownRender } from './state';
import './styles.scss';

export interface AddProductHistorySateModel {
  addProductForm: {
    quote: number;
    groups: any[];
    discount: number;
    isDealer: boolean;
  };
}

const mapOptions = {
  fields: { value: 'name', label: 'name' },
  copyFullItem: true,
};

const mapSubOptions = (subOptions) => {
  return mapGenericDropdownOptions(subOptions, mapOptions);
};

const blankOption = { value: null, label: 'None' };

export default function AddProduct() {
  const { goBack, location: { state: stateNavigation } } = useHistory();
  const {
    addProductForm: {
      quote,
      groups,
      discount,
      isDealer,
    },
  } = stateNavigation as AddProductHistorySateModel;

  const {
    state: {
      fetching,
      fetchingPrice,
      formOptions,
      formData,
      modelParams,
      adding,
      memoGroups,
      treeData,
      errors,
    },
    actions: {
      openAddGroupModal,
      openAddCustomOptionModal,
      handleValueChange,
      handleOptionsValueChange,
      fetchCategories,
      handleOnSubmit,
      setErrors,
    },
  } = useAddProductState(groups, quote, discount);

  const productOptions = useMemo(() => (
    formOptions.productOptions?.map((product, index) => {
      const hasSubOptions = formOptions[product.name]?.dropdownItem?.haveSuboptions;
      const filters = {
        filters: {
          option__name: formOptions[product.name]?.value,
        }
      };
      return (
        <>
          <Field
            key={`productO-${index}`}
            component={Select}
            name={product.name}
            id={product.name}
            label={product.name}
            isLoading={fetching}
            options={[blankOption, ...product.options]}
            addButtonLabel="Add custom option"
            onAddClick={() => openAddCustomOptionModal(product.name)}
            checkNewValue
          />
          <RenderIf isTrue={hasSubOptions}>
            <Field
              component={AsyncSelectV2}
              listAction={subOptionApi.list}
              mapFunction={mapSubOptions}
              name={`sub-${product.name}`}
              id={`sub-${product.name}`}
              label={`${formOptions[product.name].label} Options`}
              preventAutoFetch={!hasSubOptions}
              filters={filters}
            />
          </RenderIf>
        </>
      );
    })
  ), [formOptions, fetching, openAddCustomOptionModal]);

  const productListAction = useMemo(() => (
    modelParams ? productApi.fetchByCategory : productApi.list
  ), [modelParams]);

  return (
    <Modal
      visible
      title="Add Product"
      onCancel={goBack}
      okText="Submit"
      onOk={handleOnSubmit}
      confirmLoading={adding || fetchingPrice}
      maskClosable={false}
      className="add-product-modal"
    >
      <Form
        state={formData}
        onChange={handleValueChange}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <Field
          type="number"
          name="quantity"
          id="quantity"
          label="Quantity"
        />
        <Field
          component={Select}
          name="group"
          id="group"
          label="Group"
          options={memoGroups}
          onAddClick={openAddGroupModal}
        />
        <Field
          component={TreeSelect}
          name="category"
          id="treeSelect"
          label="Category"
          loadData={fetchCategories}
          treeData={treeData}
          allowClear
        />
        <Field
          component={AsyncAutoComplete}
          id="product"
          name="product"
          label="Model"
          dropdownRender={dropdownRender}
          listAction={productListAction}
          mapFunction={mapProducts}
          filters={modelParams}
          required
          allowClear
          allowIncompleteValue
        />
        <RenderIf isTrue={!!formData?.product?.value && !isDealer}>
          <Field
            type="number"
            name="price"
            id="price"
            label="Price"
            loading={fetchingPrice}
            disabled={isDealer}
          />
        </RenderIf>
        <RenderIf isTrue={!!formData?.product?.value && isDealer}>
          <div className="add-product__price-wrapper">
            <Label text="Price" />
            <div className="add-product__price-value">
              {formData.price}
              <RenderIf isTrue={fetchingPrice}>
                <Spinner size="small" centered={false} />
              </RenderIf>
            </div>
          </div>
        </RenderIf>
      </Form>
      <Form
        state={formOptions}
        onChange={handleOptionsValueChange}
      >
        <RenderIf isTrue={!!formData?.product?.value}>
          {productOptions}
        </RenderIf>
      </Form>
    </Modal>
  );
}
