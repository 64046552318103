import { CustomProductFormModel } from 'models/order-product';
import productApi from 'api/product';
import { flatTreeSelectLabel, treeSelectOption } from 'helpers/dropdown-options';
import { CustomProductFormActions } from '../reducer';
import { pricePayload } from './get-payload-price';

export function handleNewCustomOption(dispatch, data) {
  return async ({ option, parent, row }) => {
    const mappedOption = treeSelectOption(option.name, true, parent, true, true, option);

    let oldRow = {} as CustomProductFormModel;
    let currentIndex = -1;
    const price = {} as any;
    data.every((dataRow, index) => {
      if (dataRow.key === row) {
        oldRow = dataRow;
        currentIndex = index;
        return false;
      }
      return true;
    });
    const mappedOptions = oldRow.options.filter(opt => flatTreeSelectLabel(opt).parent !== parent);
    const newRow = {
      ...oldRow,
      options: [...mappedOptions, mappedOption.value],
      productOptions: oldRow?.productOptions?.length ? [...oldRow.productOptions, mappedOption] : [mappedOption] as any
    };
    if (newRow.model && newRow.product?.name) {
      const { data: { data: priceData } } = await productApi.getPrice('', pricePayload(newRow));
      price.price = parseFloat(priceData[0].totalPrice).toFixed(2);
      const optionsUpdated = {
        ...mappedOption,
        item: { ...mappedOption.item, price: priceData[0].options[mappedOption?.pId]?.price }
      };
      newRow.productOptions = oldRow?.productOptions?.length
        ? [...oldRow.productOptions, optionsUpdated] : [optionsUpdated] as any;
    }

    const newData = [...data];
    newData[currentIndex] = {
      ...newRow,
      ...price
    };

    dispatch({
      type: CustomProductFormActions.SET_CUSTOM_OPTION,
      payload: currentIndex >= 0 ? newData : data,
    });
  };
}
