import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import invoiceApi from 'api/invoice';
import accountApi from 'api/account';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Upload from 'components/base-components/Upload';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import NumberInput from 'components/base-components/NumberInput';
import Select from 'components/base-components/Select';
import Divider from 'components/base-components/Divider';
import DatePicker from 'components/base-components/DatePicker';
import TextArea from 'components/base-components/TextArea';
import { validationRules } from './validations';
import usePaymentFormState from './state';

export interface PaymentFormHistorySateModel {
  PaymentForm: {
    paymentId: number;
  };
}

export function mapInvoiceOptions(entity) {
  return mapGenericDropdownOptions(entity, { fields: { value: 'id', label: 'id' } });
}
export function mapAccountOptions(entity) {
  return mapGenericDropdownOptions(entity, { fields: { value: 'businessLegalName', label: 'businessLegalName' }, extra: ['businessTradeName'] });
}

const PaymentForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { PaymentForm: { paymentId } } = state as PaymentFormHistorySateModel;

  const {
    state: {
      fetching,
      payment,
      saving,
      errors,
      paymentMethods
    },
    actions: {
      setFormValue,
      save,
      setErrors,
    },
  } = usePaymentFormState(paymentId);

  const { method: { label: paymentType } } = payment;

  const amountLabel = useMemo(() => (
    (paymentType !== 'Cheque' && paymentType !== 'Check')
      ? 'Amount of Cash'
      : 'Amount on Cheque'
  ), [paymentType]);

  const modalTitle = useMemo(
    () => paymentId ? 'Edit Payment' : 'Create Payment',
    [paymentId],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={save}
      confirmLoading={saving}
    >
      <RenderIf isTrue={!fetching} fallback={<Spinner />}>
        <Form
          state={payment}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            component={AsyncSelectV2}
            name="account"
            id="account"
            placeholder="Select an account"
            label="Account"
            listAction={accountApi.list}
            mapFunction={mapAccountOptions}
          />
          <Field
            component={AsyncSelectV2}
            name="invoice"
            id="invoice"
            placeholder="Select an invoice"
            label="Invoice"
            listAction={invoiceApi.list}
            mapFunction={mapInvoiceOptions}
          />
          <Field
            name="method"
            label="Payment Type"
            component={Select}
            options={paymentMethods}
          />
          <Divider type="horizontal" />
          <RenderIf isTrue={paymentType === 'Cheque' || paymentType === 'Check'}>
            <Field name="nameOnCheque" label="Name on Cheque" />
          </RenderIf>
          <Field
            name="amount"
            label={amountLabel}
            component={NumberInput}
            allowDecimals
          />
          <Field
            component={DatePicker}
            name="receivedDate"
            id="receivedDate"
            label="Received Date"
          />
          <RenderIf isTrue={paymentType === 'Cheque' || paymentType === 'Check'}>
            <Field name="reference" label="Cheque Number" component={NumberInput} />
            <Field
              component={DatePicker}
              name="checkDate"
              id="checkDate"
              label="Date on Cheque"
            />
            <Field name="checkFrontImage" label="Front" component={Upload} />
            <Field name="checkBackImage" label="Back" component={Upload} />
          </RenderIf>
          <Field name="comments" label="Comments" component={TextArea} />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default PaymentForm;
