import React, { FunctionComponent, useMemo } from 'react';
import { Row, Col } from 'antd';
import rateApi from 'api/rate';
import hasPermissions from 'helpers/permissons';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import NumberInput from 'components/base-components/NumberInput';
import Button from 'components/base-components/Button';
import Help from 'components/forms/CommonHelp';
import Page from 'components/base-components/Page';
import Divider from 'components/base-components/Divider';
import Checkbox from 'components/base-components/Checkbox';
import Select from 'components/base-components/Select';
import { Text } from 'components/base-components/Typography';
import EditInPlaceV2 from 'components/base-components/EditInPlaceV2';
import Input from 'components/base-components/Input';
import AccessControl from 'components/base-components/AccessControl';
import useGenerateProducts from './state';
import { validationRateRules, validationGeneratingRules } from './state/validation-rules';
import { permissions } from './permissions';
import './styles.scss';

const renderRateValue = (value: string) => parseFloat(value).toFixed(2);
const helpDescription = 'This view is to generate products when the database is empty';

const GenerateProducts: FunctionComponent = () => {
  const {
    state: {
      fetching,
      filter,
      errors,
      adding,
      rateForm,
      rates,
      fetchingCategories,
      categories,
      generatingError,
      generatingForm,
      generating,
    },
    actions: {
      setRateFormValue,
      handleFilter,
      addRate,
      setRateValidationErrors,
      generate,
      setGeneratingErrors,
      setGeneratingFormValue
    }
  } = useGenerateProducts();

  const rateItems = useMemo(() => rates.map(rate => (
    <dd key={rate.name}>
      <div id={rate.name} className="rate__row">
        <Text strong>{rate.name}</Text>
        <div className="rate__edit-in-place">
          <EditInPlaceV2
            id={`edit-in-place__${rate.name}`}
            data={rate}
            field="value"
            onSubmit={rateApi.patch}
            render={renderRateValue}
            component={NumberInput}
            allowDecimals
            min={0}
          />
        </div>
      </div>
    </dd>
  )), [rates]);

  const shouldShowFormsSections = useMemo(
    () => hasPermissions(permissions.addCategory) || hasPermissions(permissions.addRate), []
  );
  const spanFormSections = shouldShowFormsSections ? 9 : 0;
  const spanListSections = shouldShowFormsSections ? 14 : 24;

  return (
    <Page title="Generate Products">
      <Help view="generate-products" title="Generate Products" description={helpDescription} />
      <Row justify="space-between">
        <Col span={spanFormSections}>
          <AccessControl permissions={permissions.addCategory}>
            <Divider>Generate</Divider>
            <Form
              state={generatingForm}
              onChange={setGeneratingFormValue}
              errors={generatingError}
              onError={setGeneratingErrors}
              rules={validationGeneratingRules}
            >
              <Field
                component={Select}
                id="category"
                name="category"
                label="Category"
                loading={fetchingCategories}
                options={categories}
                required
              />
              <Field
                component={Checkbox}
                id="optionsUpdate"
                name="optionsUpdate"
                label="Reset Options"
              />
            </Form>
            <div className="rate__push-to-end">
              <Button id="generate-action" loading={generating} onClick={generate} label="Generate" mB />
            </div>
          </AccessControl>
          <Divider>Create Rate</Divider>
          <AccessControl permissions={permissions.addRate}>
            <Form
              state={rateForm}
              onChange={setRateFormValue}
              errors={errors}
              onError={setRateValidationErrors}
              rules={validationRateRules}
            >
              <Field
                id="name"
                name="name"
                label="Name"
                placeholder="Enter a Name"
                required
              />
              <Field
                component={NumberInput}
                id="value"
                name="value"
                label="Value"
                placeholder="Enter Value"
                allowDecimals
                min={0}
              />
            </Form>
            <div className="rate__push-to-end">
              <Button loading={adding} onClick={addRate} label="Add" mB />
            </div>
          </AccessControl>
        </Col>
        <Col span={spanListSections}>
          <Divider>Rates</Divider>
          <RenderIf isTrue={!fetching} fallback={<Spinner />}>
            <div className="rate__push-to-end">
              <Input
                placeholder="Search"
                aria-label="Search"
                value={filter}
                onChange={handleFilter}
                size="small"
                className="rate__input-search"
              />
            </div>
            <div className="rate__list-wrapper">
              <dd>
                {rateItems}
              </dd>
            </div>
          </RenderIf>
        </Col>
      </Row>
    </Page>
  );
};

export default GenerateProducts;
