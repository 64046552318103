import { UserModel } from 'models/user';
import { AccountGroupModel } from 'models/account-group';
import { StoreModel } from 'models/store';
import { AccountTaxModel } from 'models/account';
import { TermModel } from 'models/term';
import { mapGenericDropdownOptions, mapUserDropdownOptions } from 'helpers/dropdown-options';

export function mapUsersOptions(users: UserModel[]) {
  return mapUserDropdownOptions(users);
}

export function mapAccountGroupsOptions(accountGroups: AccountGroupModel[]) {
  return mapGenericDropdownOptions(accountGroups, { fields: { value: 'url', label: 'type' } });
}

export function mapStoresOptions(stores: StoreModel[]) {
  return mapGenericDropdownOptions(stores, { fields: { value: 'url', label: 'name' } });
}

export function mapTaxesOptions(taxes: AccountTaxModel[]) {
  return mapGenericDropdownOptions(taxes, { fields: { value: 'url', label: 'taxCode' } });
}

export function mapPaymentTermsOptions(paymentTerms: TermModel[]) {
  return mapGenericDropdownOptions(paymentTerms, { fields: { value: 'url', label: 'termsName' } });
}

export function mapPaymentTermsOptionsByQuick(paymentTerms: TermModel[]) {
  return mapGenericDropdownOptions(paymentTerms, { fields: { value: 'termsName', label: 'termsName' } });
}
