import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import optionApi from 'api/option';
import { OptionModel } from 'models/option';
import { EventChannelList } from 'helpers/event-center';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import Button from 'components/base-components/Button';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Help from 'components/forms/CommonHelp';
import Page, { PageActions } from 'components/base-components/Page';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import AccessControl from 'components/base-components/AccessControl';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import { OptionFormHistorySateModel } from '../Form';
import { SubOptionHistorySateModel } from '../SubOptionFrom';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: optionApi,
  deleteModalTitle: 'Delete Option',
  deleteModalContent: (optionToDelete: OptionModel) => `Are you sure that you want to delete the option ${optionToDelete ? optionToDelete.name : ''}?`,
  searchFields: ['name'],
  eventChannel: EventChannelList.OPTIONS_LIST_CHANGED,
};

const OptionsList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<OptionModel>(useTableStateParams);

  const openOptionFormModal = (option?: OptionModel | any) => {
    const historyLocationState: OptionFormHistorySateModel = {
      optionForm: {
        optionUrl: option ? option.url : undefined,
      },
    };
    push(ModalRouteHash.OptionForm, historyLocationState);
  };
  const openOptionDetailsModal = (option?: OptionModel | any) => {
    const historyLocationState: OptionFormHistorySateModel = {
      optionForm: {
        optionUrl: option ? option.url : undefined,
      },
    };
    push(ModalRouteHash.OptionDetails, historyLocationState);
  };
  const openSubOptionModal = (option: OptionModel | any) => {
    const historyLocationState: SubOptionHistorySateModel = {
      subOptionsForm: {
        optionUrl: option.url,
        optionName: option.name,
      },
    };
    push(ModalRouteHash.SubOptionForm, historyLocationState);
  };

  return (
    <Page title="Options">
      <Help view="options" title="Options List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addOption}>
          <Button
            onClick={openOptionFormModal}
            label="Add Option"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn dataIndex="name" columnKey="name" title="Name" sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editOption}
          deletePermissions={permissions.deleteOption}
          viewPermissions={permissions.viewOption}
          onShowDetails={openOptionDetailsModal}
          onEdit={openOptionFormModal}
          onDelete={handleDeleteRow}
        >
          <DataTableAction
            permissions={permissions.editOption}
            onClick={openSubOptionModal}
            icon="fa fa-plus"
            label="SubOptions"
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default OptionsList;
