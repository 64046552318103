import { QboSyncModel } from 'models/qbo-sync';
import qboSyncApi from 'api/qbo-sync';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function resend(qboSync: QboSyncModel) {
  const { type, objectId, action } = qboSync;
  qboSyncApi.create({ type, objectId, action })
    .then(() => {
      notification({
        type: NotificationType.SUCCESS,
        message: 'QBO is Already Resend',
      });
    })
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}
