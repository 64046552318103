import { useCallback, useEffect, useReducer, useMemo } from 'react';
import { useHistory } from 'react-router';
import { resolveApiErrorMessage } from 'api/base';
import optionGroupsApi from 'api/option-group';
import useGetList from 'hooks/base/get-list';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import {
  completeState,
  setFormValue,
  setCheckAll,
  setCheckedGroups,
  applyOptions,
  handleNewCustomOption,
  openCustomOptionModal,
} from './actions';
import applyOptionsFormReducer from './reducer';

export default function useApplyOptions(historyState) {
  const { groups, groupCategories, quoteId } = historyState;
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    applyOptionsFormReducer,
    {
      formData: {},
      checkedGroups: [],
      checkAll: false,
      errors: {},
      editing: false,
    },
  );

  const { formData, checkedGroups } = state;
  const handleCustomOption = useCallback(handleNewCustomOption(dispatch), []);
  const categoriesSelected = useMemo(() => {
    let allCategories = [];
    checkedGroups.forEach(group => {
      const categoriesForGroup = groupCategories[group] ? groupCategories[group] : [];
      allCategories = [...allCategories, ...categoriesForGroup];
    });
    return [...new Set(allCategories)];
  }, [checkedGroups, groupCategories]);

  const {
    fetching: fetchingOptionGroups,
    data: productOptions,
    error: optionGroupsError,
    fetchList: fetchOptionsGroup,
  } = useGetList(optionGroupsApi, { preventAutoFetch: true });

  useEffect(() => {
    if (productOptions) {
      completeState(dispatch, productOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOptions]);
  useEffect(() => {
    if (categoriesSelected.length > 0) {
      fetchOptionsGroup({ filters: { category: categoriesSelected } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesSelected]);
  useEffect(() => {
    if (optionGroupsError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(optionGroupsError),
      });
    }
  }, [optionGroupsError]);

  useEventCenterUpdate(
    EventChannelList.QUOTE_PAGE_NEW_CUSTOM_OPTION,
    handleCustomOption,
  );

  return {
    state: {
      ...state,
      fetchingOptionGroups,
      groups: useMemo(() => groups.map(group => ({ value: group, label: group || 'None' })), [groups]),
      indeterminate: useMemo(
        () => !!checkedGroups.length && groups.length > checkedGroups.length, [groups, checkedGroups]
      ),
      productOptions: checkedGroups.length > 0 ? productOptions : [],
    },
    actions: {
      openCustomOptionModal,
      handleValueChange: useCallback(setFormValue(dispatch), []),
      handleCheckAll: useCallback(setCheckAll(dispatch, groups), [groups]),
      handleCheckedGroups: useCallback(setCheckedGroups(dispatch, groups), [groups]),
      handleOnSubmit: useCallback(
        applyOptions(dispatch, formData, checkedGroups, categoriesSelected, quoteId, goBack),
        [formData, checkedGroups, categoriesSelected, quoteId]
      ),
    },
  };
}
