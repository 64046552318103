function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

function manageBodyClass(cssClass, action = 'toggle') {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass);
      break;
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

export function isMobile() {
  return window?.matchMedia('(max-width: 1024px)').matches;
}

export function changeSidebarType(sidebarType: 'default' | 'condensed') {
  if (sidebarType === 'condensed') {
    manageBodyClass('sidebar-enable', 'add');
    if (!isMobile()) { // noinspection SpellCheckingInspection
      manageBodyClass('vertical-collpsed', 'add');
    }
  } else {
    changeBodyAttribute('data-sidebar-size', '');
    manageBodyClass('sidebar-enable', 'remove');
    if (!isMobile()) { // noinspection SpellCheckingInspection
      manageBodyClass('vertical-collpsed', 'remove');
    }
  }
}

export function initStyles() {
  changeBodyAttribute('data-sidebar', 'dark');
  changeBodyAttribute('data-layout-size', 'fluid');
  changeBodyAttribute('data-topbar', 'light');
  changeSidebarType('default');
}
