import React, { FunctionComponent } from 'react';
import Button from 'components/base-components/Button';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import { CategoryModel } from 'models/category';
import { useHistory } from 'react-router-dom';
import AccessControl from 'components/base-components/AccessControl';
import Help from 'components/forms/CommonHelp';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import categoryApi from 'api/category';
import nameTemplate from './name-template';
import { CategoryFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: categoryApi,
  deleteModalTitle: 'Delete Category',
  deleteModalContent: (categoryToDelete: CategoryModel) => `Are you sure that you want to delete the category ${categoryToDelete ? categoryToDelete.name : ''}?`,
  searchFields: ['name'],
  eventChannel: EventChannelList.CATEGORIES_LIST_CHANGED,
  defaultSortField: '-created_at',
};

const CategoriesList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<CategoryModel>(useTableStateParams);

  const openCategoryFormModal = (category?: CategoryModel | any) => {
    const historyLocationState: CategoryFormHistorySateModel = {
      categoryForm: {
        categoryUrl: category ? category.url : undefined,
      },
    };
    push(ModalRouteHash.CategoryForm, historyLocationState);
  };

  return (
    <Page title="Categories">
      <Help view="category" title="Category List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addCategory}>
          <Button
            onClick={openCategoryFormModal}
            label="Add Category"
            mR
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn
          title="Category Name"
          columnKey="name"
          sorter
          render={nameTemplate}
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editCategory}
          deletePermissions={permissions.deleteCategory}
          onEdit={openCategoryFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default CategoriesList;
