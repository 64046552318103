import { createSlice } from '@reduxjs/toolkit';
import Auth from '@aws-amplify/auth';

const initialState = {
  username: null,
  forgetSuccessMsg: null,
  forgetError: null
};

const ForgetPassword = createSlice({
  name: 'ForgetPassword',
  initialState,
  reducers: {
    userForgetPassword(state) {
      state.forgetSuccessMsg = null;
      state.forgetError = null;
    },
    userForgetPasswordSuccess(state, action) {
      state.username = action.payload.username;
      state.forgetSuccessMsg = action.payload.message;
    },
    userForgetPasswordError(state, action) {
      state.forgetError = action.payload;
      state.forgetSuccessMsg = null;
    }
  }
});

const { userForgetPassword, userForgetPasswordSuccess, userForgetPasswordError } = ForgetPassword.actions;

export const forgetUser = (username, history) => async dispatch => {
  try {
    dispatch(userForgetPassword());

    const response = await Auth.forgotPassword(username);

    if (response) {
      dispatch(userForgetPasswordSuccess({ username, message: 'Please enter the verification code sent to your email address' }));
      history.push('/forgot-password-confirmation');
    }
  } catch (error) {
    dispatch(userForgetPasswordError((error as any).message || ''));
  }
};

export const forgetUserConfirmation = ({ username, code, password }, history) => async dispatch => {
  try {
    await Auth.forgotPasswordSubmit(username, code, password);
    history.push('/login');
  } catch (error) {
    dispatch(userForgetPasswordError((error as any).message || ''));
  }
};

export default ForgetPassword.reducer;
