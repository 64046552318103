import { formatDateTimeToString } from 'helpers/formatting';
import { getLoggedUserId } from 'helpers/get-logged-user';
import { TaskFormModel } from '../reducer';

export default function getPayload(taskForm: TaskFormModel) {
  const {
    project,
    assignee,
    dueDate,
    status,
    notifyAssigner,
    notifyByEmail: _notifyByEmail,
    notifyBySms: _notifyBySms,
    updateSubs: _updateSubs,
    verbosity: _verbosity,
    ...rest
  } = taskForm;

  return {
    ...rest,
    notifyAssigner,
    status: status?.value,
    project: project?.value,
    dueDate: formatDateTimeToString(dueDate),
    assignee: assignee?.value,
    assigner: getLoggedUserId(),
  };
}
