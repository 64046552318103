import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Units } from 'models/builder';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import NumberInput from 'components/base-components/NumberInput';
import Checkbox from 'components/base-components/Checkbox';
import { Paragraph } from 'components/base-components/Typography';
import { PickItem, PickList } from 'components/base-components/PickList';
import { TextureModel, WallModel } from '../state';

interface Props {
  unit: Units;
  wall: WallModel;
  wallTextures: TextureModel[];
  onWallChange: (wall: WallModel) => void;
}

function convertMeterToUnit(property: number, unit: Units) {
  switch (unit) {
    case Units.feets:
      return ((property * 100) / 2.15) / 12;
    case Units.inches:
      return (property * 100) / 2.15;
    default:
      return property * 100;
  }
}

function convertUnitToMeter(property: number, unit: Units) {
  switch (unit) {
    case Units.feets:
      return ((property * 12) * 2.15) / 100;
    case Units.inches:
      return (property * 2.15) / 100;
    default:
      return property / 100;
  }
}

const WallPanel: FunctionComponent<Props> = (props) => {
  const { wallTextures, unit, wall, onWallChange } = props;

  const handleChange = useCallback((updatedWall) => {
    const { height, length, ...rest } = updatedWall;

    onWallChange({
      ...rest,
      height: convertUnitToMeter(height, unit),
      length: convertUnitToMeter(length, unit),
    });
  }, [unit, onWallChange]);

  const mappedWall = useMemo(() => {
    const { height, length, ...rest } = wall;

    return {
      ...rest,
      height: convertMeterToUnit(height, unit).toFixed(2),
      length: convertMeterToUnit(length, unit).toFixed(2),
    };
  }, [wall, unit]);

  const textureOptions = useMemo(() => {
    return wallTextures.map(({ name, image }) => (
      <PickItem value={image} key={name}>
        <img src={image} alt={`texture for ${name}`} />
      </PickItem>
    ));
  }, [wallTextures]);

  return (
    <Form className="builder__product-panel__form" onChange={handleChange} state={mappedWall}>
      <Field name="height" label="Height" component={NumberInput} />
      <Field name="length" label="length" component={NumberInput} />
      <Field name="locked" label="Lock Wall from changes" component={Checkbox} />
      <Paragraph>Textures</Paragraph>
      <Field name="texture" component={PickList} size="small" cols={2}>
        {textureOptions}
      </Field>
    </Form>
  );
};

export default WallPanel;
