import { PaymentModel } from 'models/payment';
import { formatDateToString } from 'helpers/formatting';
import { isValidFile } from 'helpers/file';
import { PaymentFormModel } from '../reducer';

export default function getPayload(
  payment: PaymentFormModel,
  account: string,
  orderId: number,
): PaymentModel {
  const { type, checkDate, front, back, name, chequeNumber, receivedDate, ...rest } = payment;

  return {
    ...rest,
    account,
    order: orderId,
    nameOnCheque: name,
    method: type.value,
    reference: chequeNumber,
    checkDate: formatDateToString(checkDate),
    receivedDate: formatDateToString(receivedDate),
    checkBackImage: back && isValidFile(back[0]) ? back : undefined,
    checkFrontImage: front && isValidFile(front[0]) ? front : undefined,
  };
}
