import React, { FunctionComponent } from 'react';

const Footer: FunctionComponent = () => (
  <div className="packing-slip__footer">
    <p>
      By signing, I accept that Products and Services were delivered in good condition.
    </p>
    <p>
      All Products remain the property of
      1729032 Ontario Inc. O/A The Furniture Guys until paid in full.
    </p>
    <p>
      Accepted by: _________________________________ Title: ________________________ Date: ___________
    </p>
  </div>
);

export default Footer;
