import { AxiosResponse } from 'axios';
import { resolveApiErrorMessage } from 'api/base';
import accountApi from 'api/account';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { validateEntity } from 'helpers/form-validations';
import { getFullFormRules, quickFormRules } from '../validations';
import { AccountFormActions } from '../reducer';
import getPayload from './get-payload';

export default function saveAccount(dispatch, account, useFullForm: boolean, isEditing: boolean, goBack) {
  return () => {
    dispatch({ type: AccountFormActions.START_SAVING_ACCOUNT });

    const rules = useFullForm ? getFullFormRules(isEditing) : quickFormRules;
    const { hasErrors, errors } = validateEntity(account, rules);

    if (hasErrors) {
      return dispatch({
        type: AccountFormActions.SET_ERRORS,
        payload: errors,
      });
    }

    const onSuccess = (response: AxiosResponse) => {
      const message = (
        !!account.url
          ? 'Account successfully edited'
          : 'Account successfully created'
      );

      notification({ type: NotificationType.SUCCESS, message });
      notifyEventChannel(
        EventChannelList.ACCOUNTS_LIST_CHANGED,
        { isEditing: !!account.url, account: response.data },
      );
      dispatch({ type: AccountFormActions.STOP_SAVING_ACCOUNT });
      goBack();
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
      dispatch({ type: AccountFormActions.STOP_SAVING_ACCOUNT });
    };

    const payload = getPayload(account, useFullForm);
    let apiAction = accountApi.quickCreate;

    if (useFullForm) {
      apiAction = account.url ? accountApi.update : accountApi.create;
    }

    return apiAction(payload)
      .then(onSuccess)
      .catch(onError);
  };
}
