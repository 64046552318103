import React from 'react';
import SidebarContent from './SidebarContent';

export default function Sidebar() {
  return (
    <div className="vertical-menu">
      <div className="h-100">
        <SidebarContent />
      </div>
    </div>
  );
}
