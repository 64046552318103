import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { EmailSender } from '..';
import { SendEmailFormActions } from '../reducer';

export default async function getFormData(
  id: number,
  emailSender: EmailSender,
  getTemplateMap,
  dispatch,
) {
  const action = getTemplateMap[emailSender];
  if (id && !!action) {
    dispatch({ type: SendEmailFormActions.START_FETCHING_DATA });
    try {
      const response = await action(id);
      dispatch({ type: SendEmailFormActions.SET_EMAIL, payload: response.data });
    } catch (error) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    }
    dispatch({ type: SendEmailFormActions.STOP_FETCHING_DATA });
  }
}
