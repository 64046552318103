import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { EmailSender } from 'components/forms/SendEmail/state';
import { PurchaseOrderListModel } from '..';

export default function openSendEmailModal(history, formProjectCoordinator?: boolean) {
  return (po: PurchaseOrderListModel) => {
    const { id, manufacturer } = po;

    history.push(
      `${history.location?.search}${ModalRouteHash.SendEmailForm}`,
      {
        sendEmailForm: {
          id,
          recipients: [manufacturer?.email],
          from: formProjectCoordinator ? EmailSender.PurchaseOrderFromProjectCoordinator : EmailSender.PurchaseOrder,
          title: 'Send Purchase Order by email',
        },
      }
    );
  };
}
