import { flatDropdownOption } from 'helpers/dropdown-options';
import { formatDateTimeToString } from 'helpers/formatting';
import { PurchaseOrderFormModel } from '..';

export default function getPayload(purchaseOrder: PurchaseOrderFormModel) {
  const { order, dateSent, manufacturer, supplierReadyDate, ...rest } = purchaseOrder;
  return {
    ...rest,
    dateSent: formatDateTimeToString(dateSent),
    supplierReadyDate: formatDateTimeToString(supplierReadyDate),
    order: flatDropdownOption(order),
    manufacturer: flatDropdownOption(manufacturer),
  };
}
