import React from 'react';
import { ProjectCoordinatorModel } from 'models/project-coordinator';
import { formatDisplayDate } from 'helpers/formatting';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { SetInstallerHistorySateModel } from '../SetInstaller';
import '../styles.scss';

export default function installerBookingRender(dataIndex: string, push) {
  return (data: ProjectCoordinatorModel) => {
    const historyLocationState = {
      setInstaller: {
        projectId: data.projectId,
        installers: data.installerBookings,
        jobName: data.jobName,
        orderId: data.id
      }
    } as SetInstallerHistorySateModel;

    const onClick = () => push(ModalRouteHash.SetInstallerBooking, historyLocationState);

    const installers = data?.installerBookings?.map((inst, index) => (
      <dd className="project-coordinator__installer-item__dd" key={index}>
        {dataIndex === 'installer' ? inst.installer.companyName : formatDisplayDate(inst[dataIndex])}
      </dd>
    ));

    return (
      <dl className="project-coordinator__installer-list__dl" onClick={onClick}>
        {installers}
      </dl>
    );
  };
}
