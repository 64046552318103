import React, { FunctionComponent, Fragment, useMemo } from 'react';
import { Units } from 'models/builder';
// import RenderIf from 'components/base-components/RenderIf';
import { Properties } from 'components/base-components/PropertiesPanel';
import { BuilderFormModel } from '../state';
// import SceneOptions from './SceneOptions';
import Options from './Options';
import './styles.scss';

interface Props {
  unit: Units;
  product: BuilderFormModel;
  onOptionsChange: (product: BuilderFormModel) => void;
  onPropertiesChange: (product: BuilderFormModel) => void;
  onSceneOptionsChange: (product: BuilderFormModel) => void;
}

const ProductPanel: FunctionComponent<Props> = (props) => {
  const {
    unit,
    product,
    onPropertiesChange,
    onOptionsChange,
    // onSceneOptionsChange,
  } = props;
  const {
    id,
    builderOptions,
    modelDescription,
    optionGroups,
    options,
    // sceneOptions,
    // overlapable,
    // stackable,
    // stackontop,
  } = product;

  const modelName = useMemo(() => {
    const propertiesName = Object.keys(modelDescription);
    return propertiesName.slice(0, propertiesName.findIndex(e => e === 'style') + 1)
      .reduce((modelProperties, property) => modelProperties.concat(modelDescription[property]), [])
      .join('-');
  }, [modelDescription]);

  return (
    <Fragment key={id}>
      <div className="builder__product-panel__wrapped-forms">
        <h5 className="builder__product-panel__model-name">{modelName}</h5>
        <Properties
          modelDescription={modelDescription}
          builderOptions={builderOptions}
          onChange={onPropertiesChange}
          unit={unit}
          fromBuilder
        />
        <Options
          options={options}
          productOptions={optionGroups}
          onChange={onOptionsChange}
        />
        {/* <RenderIf isTrue={!!onSceneOptionsChange}>
          <SceneOptions
            sceneOptions={sceneOptions}
            onChange={onSceneOptionsChange}
            overlapable={overlapable}
            stackable={stackable}
            stackontop={stackontop}
          />
        </RenderIf> */}
      </div>
    </Fragment>
  );
};

export default ProductPanel;
