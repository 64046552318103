import { AxiosResponse } from 'axios';
import { CncOrderModel } from 'models/cnc-order';
import { QueryParams } from 'helpers/query-params';
import { get, ListResponseModel } from './base';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/cnc-order`;

const cncOrderApi = {
  listOrders(params?: QueryParams): Promise<AxiosResponse<ListResponseModel<CncOrderModel>>> {
    return get(`${endpoint}/list-orders/`, params);
  },
  listDeficiencies(params?: QueryParams): Promise<AxiosResponse<ListResponseModel<CncOrderModel>>> {
    return get(`${endpoint}/list-deficiencies/`, params);
  },
  listQuotes(params?: QueryParams): Promise<AxiosResponse<ListResponseModel<CncOrderModel>>> {
    return get(`${endpoint}/list-quotes/`, params);
  }
};

export default cncOrderApi;
