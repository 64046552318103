import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import Button from 'components/base-components/Button';
import PopConfirm from 'components/base-components/PopConfirm';
import AsyncList from 'components/base-components/AsyncList';
import RenderIf from 'components/base-components/RenderIf';
import { brands } from '../Form';
import SubOptionItem from './SubOptionItem';
import useSubOptionState, { validationRules } from './state';

export interface SubOptionHistorySateModel {
  subOptionsForm: {
    optionUrl: string;
    optionName: string;
  };
}

const SubOptionForm: FunctionComponent = () => {
  const { goBack, location: { state: stateNavigation } } = useHistory();
  const {
    subOptionsForm: { optionName, optionUrl },
  } = stateNavigation as SubOptionHistorySateModel;

  const {
    state: {
      fetching,
      data,
      formData,
      saving,
      errors,
      subOptionSelected,
      hasMore
    },
    actions: {
      setFormValue,
      onChange,
      setErrors,
      handleOnDelete,
      onSave,
      loadMore
    }
  } = useSubOptionState(optionUrl, optionName);

  const renderItem = useCallback((subOpt) => {
    const isSelected = subOptionSelected === subOpt.url;
    return (
      <SubOptionItem
        key={subOpt.url}
        data={subOpt}
        handleOnDelete={handleOnDelete}
        handleOnSelect={onChange}
        isSelected={isSelected}
      />
    );
  }, [handleOnDelete, onChange, subOptionSelected]);

  const okText = formData.url ? 'Edit Sub Option' : 'Add Sub Option';

  return (
    <Modal
      title={`${optionName} - Sub Options`}
      visible
      onCancel={goBack}
      okText={okText}
      onOk={onSave}
      confirmLoading={saving}
    >
      <AsyncList loading={fetching} items={data} loadMore={loadMore} hasMore={hasMore} renderItem={renderItem} />
      <RenderIf isTrue={!!formData.url}>
        <PopConfirm title="Sure to discard changes?" onConfirm={onChange}>
          <Button label="Add New" mB />
        </PopConfirm>
      </RenderIf>
      <Form
        state={formData}
        onChange={setFormValue}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <Field
          id="name"
          name="name"
          label="Name"
          placeholder="Enter Sub Option name"
          disabled={formData.url}
        />
        <Field
          id="code"
          name="code"
          label="Code"
          placeholder="Enter code"
        />
        <Field
          component={Select}
          id="brand"
          name="brand"
          label="Brand"
          options={brands}
        />
      </Form>
    </Modal>
  );
};

export default SubOptionForm;
