import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Waypoint } from 'react-waypoint';
import { AutoComplete as AntAutoComplete } from 'antd';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import Label from 'components/base-components/Label';
import InputError from 'components/base-components/InputError';
import Spinner from 'components/base-components/Spinner';
import RenderIf from 'components/base-components/RenderIf';
import 'components/base-components/Select/styles.scss';
import './styles.scss';

export interface Props {
  id?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  suffixIcon?: ReactNode;
  loading?: boolean;
  value: DropdownOptionModel;
  options: DropdownOptionModel[];
  onChange: (event) => void;
  onBlur?: (event) => void;
  onClear?: () => void;
  onSearch?: (input) => void;
  filterOption?: boolean | ((input, option) => boolean);
  className?: string;
  inputClassName?: string;
  mR?: boolean;
  useInfinityScroll?: boolean;
  onInfinityScroll?: () => void;
  required?: boolean;
  allowClear?: boolean;
  error?: string;
}

const AutoComplete: FunctionComponent<Props> = (props) => {
  const {
    mR,
    id,
    label,
    className,
    inputClassName,
    options,
    useInfinityScroll,
    onInfinityScroll,
    value,
    onChange,
    loading,
    required,
    error,
    ...rest
  } = props;

  const handleOnChange = useCallback(
    (_value, option) => {
      onChange({ target: { value: option } });
    },
    [onChange],
  );

  const wrapperClassNames = classnames('select', { mR, error }, className);
  const selectClassName = classnames('select__input', inputClassName);
  const loaderClassName = classnames('auto-complete_loading', { 'with-label': !!label });

  const internalOptions = useMemo(() => {
    if (onInfinityScroll && useInfinityScroll) {
      return options.concat([
        {
          value: 'waypoint',
          label: (
            <div className="select__infinity-scroll">
              <Spinner size="small" centered={false} />
              <Waypoint
                key="cursor"
                onEnter={onInfinityScroll}
                bottomOffset="0px"
              />
            </div>
          ),
        } as any,
      ]);
    }

    return options;
  }, [options, onInfinityScroll, useInfinityScroll]);
  const internalValue = value.label;

  return (
    <div className={wrapperClassNames}>
      <Label text={label} forInput={id} required={required} />
      <AntAutoComplete
        {...rest}
        id={id}
        value={internalValue}
        options={internalOptions}
        className={selectClassName}
        onChange={handleOnChange}
        dropdownClassName={`${id}-dropdown`}
      />
      <RenderIf isTrue={loading}>
        <div className={loaderClassName}>
          <Spinner size="small" centered={false} />
        </div>
      </RenderIf>
      <InputError error={error} />
    </div>
  );
};

export default AutoComplete;
