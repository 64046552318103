/* eslint-disable */
import * as THREE from 'three';
import React from 'react';
import { EventChannelList, listenToEventChannel, notifyEventChannel, unsubscribeEventChannel } from 'helpers/event-center';
import RenderIf from 'components/base-components/RenderIf';
import BP3D from './app';
import SceneViewer from './sceneViewer';
import FloorPlanViewer from './floorPlanViewer';
import CameraControls from './CameraControls';
import { data as DefaultFloorPlan } from './defaultFloorPlan';

import HalfEdge from './app/model/half_edge';
import Wall from './app/model/wall';
import Room from './app/model/room';

import {
  IProps,
  IState,
  IPropertyChangeEvent,
  IOptionChangeEvent,
  IWallPropertyChangeEvent,
} from './interfaces';

import './styles.scss';

const panSpeed = 30;

const preventDefault = e => {
  e.preventDefault();
  e.stopPropagation();
  return false;
}

class Blueprint3D extends React.Component<IProps, IState> {
  elFloorPlannerElement = null;
  elThreeElemContainer = null;
  bp3d = null;

  selectedItem = null;
  selectedWall = null;
  selectedFloor = null;

  constructor(props) {
    super(props);
    this.state = {
      dimensionVisible: 0,
      showSwapDialog: false,
      newProduct: null,
    };

    this.registerEventChannels();
  }

  registerEventChannels = () => {
    listenToEventChannel(
      EventChannelList.BUILDER_PRODUCT_PROPERTY_CHANGED,
      (data: IPropertyChangeEvent) => {
        if (data.useMorphs) {
          this.setMorph(data.morphIndex, data.value);
        } else {
          this.updateStyle(data.groupNameInModel, data.nameInModel);
        }
      },
    );

    listenToEventChannel(
      EventChannelList.BUILDER_SCENE_OPTION_CHANGED,
      (data: any) => {
        if (data && data.hasOwnProperty('name')) {
          if (data.name === 'lockInPlace') {
            const locked = data.value;
            this.handleLocked(locked);
            locked && this.handleShowAllGizmo();
          }
        }
      },
    );

    listenToEventChannel(
      EventChannelList.BUILDER_PRODUCT_OPTION_CHANGED,
      (data: IOptionChangeEvent) => {
        if (data.mode === 'texture') {
          this.updateMaterial(
            data.groupNameInModel,
            { texture: data.image },
            data.textureWidth,
            data.textureHeight,
          );
        } else if (data.mode === 'hide') {
          this.updateStyle(data.groupNameInModel, data.nameInModel);
        }
      },
    );

    listenToEventChannel(EventChannelList.BUILDER_WALL_CHANGED, this.updateWall);

    listenToEventChannel(
      EventChannelList.BUILDER_SELECTED_PRODUCT_SWAPPED,
      this.swapItem,
    );

    listenToEventChannel(
      EventChannelList.BUILDER_SET_FIXED,
      (fixed: boolean) => {
        console.log('set fixed', fixed);
        this.selectedItem && this.selectedItem.setFixed(fixed);
      }
    );

    listenToEventChannel(
      EventChannelList.BUILDER_SET_STACKABLE,
      (stackable: boolean) => {
        console.log("set stackable", stackable);
        this.selectedItem && this.selectedItem.setStackable(stackable);
      }
    );

    listenToEventChannel(
      EventChannelList.BUILDER_SET_OVERLAPPABLE,
      (overlappable: boolean) => {
        console.log("set overlappable", overlappable);
        this.selectedItem && this.selectedItem.setOverlappable(overlappable);
      }
    );

    listenToEventChannel(
      EventChannelList.BUILDER_SET_STRETCH_DIRECTION,
      (direction: number) => {
        console.log("set morph align", direction);
        this.selectedItem && this.selectedItem.setMorphAlign(direction);
      }
    );

    listenToEventChannel(
      EventChannelList.BUILDER_FLIP_HORIZONTAL,
      () => {
        console.log('flip');
        this.selectedItem && this.selectedItem.flipHorizontal();
      }
    );

    listenToEventChannel(
      EventChannelList.BUILDER_DUPLICATE,
      () => {}
    );

    listenToEventChannel(
      EventChannelList.BUILDER_DELETE_ACTIVE_PRODUCT,
      () => {
        console.log('delete')
        this.handleDelete();
      }
    );

    listenToEventChannel(EventChannelList.BUILDER_REQ_SAVE_JSON,
      ({ products, flowPlanner }) => {
        const data = JSON.parse(this.bp3d.model.exportSerialized(products));
        data.thumbnail = this.bp3d.three.dataUrl();
        notifyEventChannel(EventChannelList.BUILDER_RES_SAVE_JSON, { ...flowPlanner, plan: data });
      }
    );

    listenToEventChannel(EventChannelList.BUILDER_REQ_LOAD_JSON, (json: string | object) => {
      const data = typeof json === 'string' ? JSON.parse(json) : json;
      this.bp3d.model.loadSerialized(JSON.stringify(data));
    })
  }

  initializeBP3D = () => {
    const opts = {
      floorplannerElement: this.elFloorPlannerElement,
      threeElement: this.elThreeElemContainer,
      textureDir: 'models/textures/',
      widget: false
    };
    this.bp3d = new BP3D(opts);
    const { defaultJson, readOnly } = this.props;
    this.bp3d.model.loadSerialized(
      defaultJson || JSON.stringify(DefaultFloorPlan),
    );

    this.bp3d.three.itemSelectedCallbacks.push(this.handleItemSelected);
    this.bp3d.three.itemUnselectedCallbacks.push(this.handleItemUnselected);

    this.bp3d.three.wallClicked.push(this.handleWallSelected);
    this.bp3d.three.floorClicked.push(this.handleFloorSelected);

    this.bp3d.three.lockController(readOnly);
  };

  componentDidMount = () => {
    this.initializeBP3D();
  };

  componentWillReceiveProps = (props: IProps) => {
    if (JSON.stringify(this.props.walls) !== JSON.stringify(props.walls)) {
      this.bp3d.three.setWallTexturePresets(props.walls);
    }
    if (JSON.stringify(this.props.floors) !== JSON.stringify(props.floors)) {
      this.bp3d.three.setFloorTexturePresets(props.floors);
    }
    if (props.measureUnit !== this.props.measureUnit) {
      this.bp3d.changeUnit(props.measureUnit);
    }
    if (props.viewMode !== this.props.viewMode) {
      this.handleSwitchMode();
    }
    if (props.sceneLocked !== this.props.sceneLocked) {
      this.bp3d.setSceneLocked(props.sceneLocked);
    }
    if (props.showDimensions !== this.props.showDimensions) {
      this.bp3d.setDimensionVisible(props.showDimensions);
    }
    if (props.snapEnabled !== this.props.snapEnabled) {
      this.bp3d.setSnap(props.snapEnabled);
    }
    if (props.xRayEnabled !== this.props.xRayEnabled) {
      this.bp3d.toggleXRayMode();
    }
  }

  componentWillUnmount = () => {
    unsubscribeEventChannel(EventChannelList.BUILDER_PRODUCT_PROPERTY_CHANGED);
    unsubscribeEventChannel(EventChannelList.BUILDER_REQ_LOAD_JSON);
    unsubscribeEventChannel(EventChannelList.BUILDER_REQ_SAVE_JSON);
    unsubscribeEventChannel(EventChannelList.BUILDER_DELETE_ACTIVE_PRODUCT);
    unsubscribeEventChannel(EventChannelList.BUILDER_DUPLICATE);
    unsubscribeEventChannel(EventChannelList.BUILDER_FLIP_HORIZONTAL);
    unsubscribeEventChannel(EventChannelList.BUILDER_SET_STRETCH_DIRECTION);
    unsubscribeEventChannel(EventChannelList.BUILDER_SET_OVERLAPPABLE);
    unsubscribeEventChannel(EventChannelList.BUILDER_SET_STACKABLE);
    unsubscribeEventChannel(EventChannelList.BUILDER_SET_FIXED);
    unsubscribeEventChannel(EventChannelList.BUILDER_SELECTED_PRODUCT_SWAPPED);
    unsubscribeEventChannel(EventChannelList.BUILDER_WALL_CHANGED);
    unsubscribeEventChannel(EventChannelList.BUILDER_PRODUCT_OPTION_CHANGED);
    unsubscribeEventChannel(EventChannelList.BUILDER_SCENE_OPTION_CHANGED);
  }

  handleItemSelected = (item) => {
    const { dimensionVisible } = this.state;
    this.selectedItem = item;
    dimensionVisible === 0 && this.handleShowCurrentGizmo();
    this.props.onItemSelected(item.metadata.id, item);
  };

  handleItemUnselected = () => {
    this.selectedItem = null;
    this.props.onItemSelected(undefined);
  };

  handleWallSelected = (activeWall: Wall | HalfEdge | undefined | null) => {
    this.selectedWall = activeWall;

    const res: IWallPropertyChangeEvent = {
      length: 0,
      height: 0,
      locked: false,
      texture: '',
    };

    if (!activeWall) {
      this.props.onSelectWall(null);
      return;
    }

    if (activeWall instanceof HalfEdge) {
      res.length = activeWall.wall.getWallLength();
      res.height = activeWall.wall.height;
      res.locked = activeWall.wall.locked;
      try {
        res.texture = activeWall.getTexture().url;
      } catch (_) { res.texture = '' }
    } else if (activeWall instanceof Wall) {
      res.length = activeWall.getWallLength();
      res.height = activeWall.height;
      res.locked = activeWall.locked;
    }
    this.props.onSelectWall(res);
  }

  handleFloorSelected = (activeFloor: Room | undefined | null) => {
    this.selectedFloor = activeFloor;
    const res = {
      texture: '',
    };
    if (!activeFloor) {
      this.props.onSelectFloor(null);
      return;
    }
    this.props.onSelectFloor(res);
  }

  update = () => {
    try { this.bp3d.three.updateWindowSize() } catch (_) { }
  };

  handleSwitchMode = () => {
    setTimeout(() => {
      this.bp3d && this.bp3d.floorplanner && this.bp3d.floorplanner.reset();
      this.bp3d.model.floorplan.update();
      this.bp3d.three.updateWindowSize();
    }, 50);
  };

  collectOptionsFromItem = (item: any) => {
    return this.bp3d.model.scene.collectOptionsFromItem(item);
  }

  addItem = (item: any, options: any = null) => {
    if (!options) {
      options = this.collectOptionsFromItem(item);
      options.overlappable = item.overlappable;
      options.stackable = item.stackable;
      options.stackontop = item.stackontop;
    }

    let position = null;
    let rotation = null;
    const initialData = item.initialData;
    if (initialData) {
      options = initialData.options;
      rotation = initialData.rotation;
      position = new THREE.Vector3(
        initialData.position.x,
        initialData.position.y,
        initialData.position.z
      )
    }

    try {
      let type = 1;
      if (item.position && item.position.index) type = item.position.index;
      this.bp3d.model.scene.addItem(
        type,
        item.threeModel,
        { ...item, name: item.category },
        position,
        rotation,
        options,
        !initialData
      );
    } catch (_) {
      console.log(_);
    }
  };

  addSetItem = (items: any[]) => {
    const metadata = items.reduce((meta, item) => ({ ...meta, [item.id]: item }));
    this.bp3d.model.scene.importSetFromBuilder(metadata, items, null, null, null);
  };

  swapItem = (item: any) => {
    if (!this.selectedItem) return;
    let options = this.collectOptionsFromItem(item);
    const currentOptions = this.selectedItem.getOptions();
    options = { ...options, ...currentOptions };
    const position = this.selectedItem.position;
    const rotation = this.selectedItem.rotation.y;
    this.selectedItem.remove();

    try {
      let type = 1;
      if (item.position && item.position.index) type = item.position.index;
      this.bp3d.model.scene.addItem(
        type,
        item.threeModel,
        { ...item },
        position,
        rotation,
        options,
      );
    } catch (_) {
      console.log(_);
    }
  }

  updateWall = (data: IWallPropertyChangeEvent) => {
    if (!this.selectedWall) return;

    data.height > 0 && this.bp3d.three.handleWallHeightChanged(data.height);

    if (this.selectedWall instanceof HalfEdge) {
      this.selectedWall.wall.setLocked(data.locked);
      data.length > 0 && this.selectedWall.wall.setWallLength(data.length);
      this.selectedWall.setTexture(data.texture);
    } else if (this.selectedWall instanceof Wall) {
      this.selectedWall.setLocked(data.locked);
      data.length > 0 && this.selectedWall.setWallLength(data.length);

      if (this.selectedWall.frontEdge) {
        this.selectedWall.frontEdge.setTexture(data.texture);
      }
      if (this.selectedWall.backEdge) {
        this.selectedWall.backEdge.setTexture(data.texture);
      }
    }
    this.bp3d.model.floorplan.update();
  }

  updateMaterial = (target, material, textureWidth, textureHeight) => {
    if (!this.selectedItem) return;

    const size = { w: textureWidth / 100, h: textureHeight / 100 };

    this.selectedItem.updateMaterial(
      target,
      material,
      size,
      () => setTimeout(this.update, 100)
    );
  };

  updateStyle = (hide_name, show_name) => {
    if (this.selectedItem) {
      this.selectedItem.updateStyle(
        hide_name,
        show_name,
        () => setTimeout(this.update, 100),
      );
    }
  };

  setMorph = (index, value) => {
    this.selectedItem && this.selectedItem.setMorph(index, value);
    setTimeout(this.update, 100);
  };

  handleShowCurrentGizmo = () => {
    this.bp3d.three.hideAllGizmo();
    this.selectedItem && this.selectedItem.showDimensionHelper();
    this.setState({ dimensionVisible: 0 });
    setTimeout(() => this.update(), 100);
  };

  handleShowAllGizmo = () => {
    this.bp3d.three.showAllGizmo();
    this.setState({ dimensionVisible: 1 });
    setTimeout(() => this.update(), 100);
  };

  handleHideAllGizmo = () => {
    this.bp3d.three.hideAllGizmo();
    this.setState({ dimensionVisible: 2 });
    setTimeout(() => this.update(), 100);
  };

  handleDelete = () => {
    if (this.selectedItem && this.selectedItem.metadata) {
      this.props.onDelete(this.selectedItem.metadata.id);
      this.selectedItem.remove();
    }
  };

  handleZoomOut = () => {
    this.bp3d.three.controls.dollyOut(1.1);
    this.bp3d.three.controls.update();
  };

  handleZoomIn = () => {
    this.bp3d.three.controls.dollyIn(1.1);
    this.bp3d.three.controls.update();
  };

  handleLocked = (locked) => {
    this.bp3d.three.lockController(locked);
  }

  handleHomeClicked = () => this.bp3d.three.centerCamera();

  handlePan = direction => {
    switch (direction) {
      case 'UP':
        this.bp3d.three.controls.panXY(0, panSpeed);
        break;
      case 'DOWN':
        this.bp3d.three.controls.panXY(0, -panSpeed);
        break;
      case 'LEFT':
        this.bp3d.three.controls.panXY(panSpeed, 0);
        break;
      case 'RIGHT':
        this.bp3d.three.controls.panXY(-panSpeed, 0);
        break;
    }
  };

  handleToggleXRay = () => {
    this.bp3d.toggleXRayMode();
  }

  render() {
    const { viewMode } = this.props;

    return (
      <div
        className="bp3d"
        onContextMenu={preventDefault}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          const data = JSON.parse(e.dataTransfer.getData('info'));
          this.props.onDragDropFinished(data);
        }}
      >
        <SceneViewer
          hidden={viewMode !== '3d'}
          onDomLoaded={el => this.elThreeElemContainer = el}
        />
        <FloorPlanViewer
          hidden={viewMode === '3d'}
          onDomLoaded={el => this.elFloorPlannerElement = el}
          onModeChanged={mode => this.bp3d.floorplanner.setMode(mode)}
        />
        <RenderIf isTrue={viewMode === '3d'}>
          <CameraControls
            onZoomOut={this.handleZoomOut}
            onZoomIn={this.handleZoomIn}
            onHomeClicked={this.handleHomeClicked}
            onPan={this.handlePan}
          />
        </RenderIf>
      </div>
    );
  }
}
export default Blueprint3D;
