import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import optionApi from 'api/option';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import Upload from 'components/base-components/Upload';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import Checkbox from 'components/base-components/Checkbox';
import Select from 'components/base-components/Select';
import Empty, { PRESENTED_IMAGE_SIMPLE } from 'components/base-components/Empty';
import useOptionGroupFormState, { mapOptions, validationRules } from './state';

export interface OptionGroupFormHistorySateModel {
  optionGroupForm: {
    optionGroupUrl: string;
  };
}

const NoDataContent = <Empty image={PRESENTED_IMAGE_SIMPLE} description="No Data, please select some options above." />;

const OptionGroupForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { optionGroupForm: { optionGroupUrl } } = state as OptionGroupFormHistorySateModel;

  const {
    state: {
      optionGroup,
      savingOptionGroup,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveOptionGroup,
      setErrors,
    },
  } = useOptionGroupFormState(optionGroupUrl);

  const modalTitle = useMemo(
    () => optionGroupUrl ? 'Edit Option Group' : 'Create Option Group',
    [optionGroupUrl],
  );

  const { option } = optionGroup;

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveOptionGroup}
      confirmLoading={savingOptionGroup}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={optionGroup}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            type="text"
            id="name"
            name="name"
            label="Name"
            placeholder="Enter Option Group name"
            disabled={optionGroupUrl}
            required
          />
          <Field
            type="text"
            id="nameFr"
            name="nameFr"
            label="Name fr"
            placeholder="Enter Option Group fr name"
          />
          <Field
            type="text"
            id="description"
            name="description"
            label="Description"
            placeholder="Enter description"
            required
          />
          <Field
            type="text"
            id="descriptionFr"
            name="descriptionFr"
            label="Description fr"
            placeholder="Enter description fr"
          />
          <Field
            component={AsyncSelectV2}
            id="option"
            name="option"
            label="Options"
            mode="multiple"
            listAction={optionApi.list}
            mapFunction={mapOptions}
          />
          <Field
            name="defaultOption"
            label="Default Option"
            component={Select}
            options={option}
            notFoundContent={NoDataContent}
          />
          <Field
            component={Checkbox}
            id="hideShowModel"
            name="hideShowModel"
            label="hideShowModel"
          />
          <Field
            component={Upload}
            name="image"
            id="image"
            label="Choose Image"
            accept="image/png, .jpeg, .jpg"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default OptionGroupForm;
