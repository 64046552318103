import React, { FunctionComponent } from 'react';
import InputMask from 'react-input-mask';
import Field from 'components/base-components/Form/Field';
import Input from 'components/base-components/Input';
import Upload from 'components/base-components/Upload';

interface Props {
  disableNameInput: string;
}

const ContentForm: FunctionComponent<Props> = (props) => {
  const { disableNameInput } = props;

  return (
    <>
      <Field
        type="text"
        id="companyName"
        name="companyName"
        label="Company Name"
        placeholder="Enter Company Name"
        disabled={disableNameInput}
      />
      <Field
        type="text"
        id="firstName"
        name="firstName"
        label="First Name"
        placeholder="Enter First Name"
      />
      <Field
        type="text"
        id="lastName"
        name="lastName"
        label="Last Name"
        placeholder="Enter Last Name"
      />
      <Field
        type="tel"
        id="phone"
        name="phone"
        label="Phone"
        placeholder="Enter Phone"
        mask="(999) 999-9999"
        maskChar="-"
        tag={[Input, InputMask]}
      />
      <Field
        type="email"
        id="email"
        name="email"
        label="Email"
        placeholder="Enter Email"
      />
      <Field
        type="text"
        id="address"
        name="address"
        label="Address"
        placeholder="Enter Address"
      />
      <Field
        type="text"
        id="city"
        name="city"
        label="City"
        placeholder="Enter City"
      />
      <Field
        type="text"
        id="province"
        name="province"
        label="Province"
        placeholder="Enter Province"
      />
      <Field
        type="text"
        id="postalCode"
        name="postalCode"
        label="Postal Code"
        placeholder="Enter Postal Code"
      />
      <Field
        type="text"
        id="country"
        name="country"
        label="Country"
        placeholder="Enter Country"
      />
      <Field
        type="text"
        id="currency"
        name="currency"
        label="Currency"
        placeholder="Enter Currency"
      />
      <Field
        type="text"
        id="purchaseMethod"
        name="purchaseMethod"
        label="Purchase Method"
        placeholder="Enter Purchase Method"
      />
      <Field
        component={Upload}
        name="image"
        id="image"
        label="Choose Image"
        accept="image/png, .jpeg, .jpg"
      />
    </>
  );
};

export default ContentForm;
