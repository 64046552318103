import { CutListModel } from 'models/cut-list';
import cutListApi from 'api/cut-list';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function downloadCutSheet(cutList: CutListModel) {
  const { id, name } = cutList;
  cutListApi.download(id, name)
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}
