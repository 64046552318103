import React, { FunctionComponent, useMemo } from 'react';
import { UserModel } from 'models/user';
import accountApi from 'api/account';
import userApi from 'api/user';
import { mapGenericDropdownOptions, mapUserDropdownOptions } from 'helpers/dropdown-options';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Empty, { PRESENTED_IMAGE_SIMPLE } from 'components/base-components/Empty';
import { validationRules } from './validations';
import useSaveDesignFormState from './state';
import { BuilderFormModel } from '../state';

interface Props {
  visible: boolean;
  goBack: () => void;
  products: BuilderFormModel[];
}

export function mapAccountOptions(entity) {
  return mapGenericDropdownOptions(entity, { fields: { value: 'businessLegalName', label: 'businessLegalName' }, extra: ['businessTradeName'] });
}
export function mapUsersOptions(users: UserModel[]) {
  return mapUserDropdownOptions(users, true);
}

const NoDataContent = <Empty image={PRESENTED_IMAGE_SIMPLE} description="No Data, please select an Account first." />;

const SaveFlowPlannerForm: FunctionComponent<Props> = (props) => {
  const { visible, goBack, products } = props;
  const {
    state: {
      fetchingFormData,
      flowPlanner,
      errors,
      loggedUser,
    },
    actions: {
      setErrors,
      setFormValue,
      handleOnSubmit
    }
  } = useSaveDesignFormState(goBack, products);

  const contactFilterParams = useMemo(
    () => ({ search: flowPlanner.account?.businessTradeName }),
    [flowPlanner.account],
  );

  return (
    <Modal
      title="Save Design"
      visible={visible}
      onCancel={goBack}
      okText="Submit"
      onOk={handleOnSubmit}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={flowPlanner}
          onChange={setFormValue}
          rules={validationRules}
          errors={errors}
          onError={setErrors}
        >
          <RenderIf isTrue={loggedUser.isStaff}>
            <Field
              component={AsyncSelectV2}
              name="account"
              id="account"
              placeholder="Select an account"
              label="Account"
              listAction={accountApi.list}
              mapFunction={mapAccountOptions}
              preventAutoFetch={!loggedUser.isStaff}
            />
            <Field
              component={AsyncSelectV2}
              name="client"
              label="Client"
              listAction={userApi.list}
              mapFunction={mapUsersOptions}
              filters={contactFilterParams}
              notFoundContent={NoDataContent}
              preventAutoFetch={!contactFilterParams?.search}
            />
          </RenderIf>
          <Field
            name="project"
            label="Project Name"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default SaveFlowPlannerForm;
