import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import Modal from 'components/base-components/ModalV2';
import Button from 'components/base-components/Button';
import FileViewer from 'components/base-components/FileViewer';
import downloadFile from './download-file';
import './styles.scss';

export interface FileViewerHistorySateModel {
  FileViewerForm: {
    quoteId: number;
  };
}

export default function S3Files() {
  const { goBack, location: { state } } = useHistory();
  const { FileViewerForm: { quoteId } } = state as FileViewerHistorySateModel;

  const filesRender = useCallback((files, fetchingFiles, params) => {
    const download = downloadFile(params, quoteId);
    return (
      files.map((fileName, index) => (
        <dd key={`file-${index}`}>
          <Button type="text" onClick={() => download(fileName)} disabled={fetchingFiles}>
            <i className="fa fa-file file-icon" />
            {fileName}
          </Button>
        </dd>
      )));
  }, [quoteId]);

  return (
    <Modal
      visible
      onCancel={goBack}
      footer={null}
      title="File Viewer"
    >
      <FileViewer quoteId={quoteId} filesRender={filesRender} />
    </Modal>
  );
}
