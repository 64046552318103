import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import camelCase from 'lodash/camelCase';

interface Props {
  from: object;
  field: string;
}

const Translator: FunctionComponent<Props> = (props) => {
  const { from: entity, field } = props;
  const lang = useSelector(({ login }: any) => login.lang);

  if (lang === 'en') {
    return entity[field];
  }

  return entity[camelCase(`${field}_${lang}`)];
};

export default Translator;
