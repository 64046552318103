import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import { ConvertToOrderProps } from '../..';
import { ConvertToOrderActions, ConvertToOrderState } from '../reducer';
import resolveShipmentFields from './resolve-shipment-fields';
import validateProducts from './validate-products';
import validateShipment from './validate-shipment';
import saveProducts from './save-products';
import saveShipment from './save-shipment';

export default function handleOnSubmit(
  dispatch,
  state: ConvertToOrderState,
  props: ConvertToOrderProps,
) {
  return async () => {
    const {
      isIncompleteOrder,
      onSubmit,
      missingFields,
      quote,
      shipment,
    } = props;

    if (!isIncompleteOrder) {
      onSubmit();
      return;
    }

    dispatch({ type: ConvertToOrderActions.START_UPDATING_FIELDS });
    const { productOptions, shipping } = missingFields;
    const { productsFormData, shipmentFormData } = state;
    const isMissingProductsInfo = !!productOptions?.missing;
    const isMissingShipmentInfo = !!shipping?.missing;
    let hasProductsInfoToSend = true;
    let hasShipmentInfoToSend = true;

    if (isMissingProductsInfo) {
      const {
        hasErrors,
        errors,
      } = validateProducts(productsFormData, productOptions.missing);

      if (hasErrors) {
        dispatch({
          type: ConvertToOrderActions.SET_PRODUCTS_ERRORS,
          payload: errors
        });
        hasProductsInfoToSend = false;
      }
    }

    if (isMissingShipmentInfo) {
      const shipmentFields = resolveShipmentFields(missingFields);
      const {
        project,
        zone,
        address,
      } = validateShipment(shipmentFormData, shipmentFields);

      if (project.hasErrors) {
        dispatch({
          type: ConvertToOrderActions.SET_PROJECT_ERROR,
          payload: 'This field is required',
        });
        hasShipmentInfoToSend = false;
      }

      if (zone.hasErrors) {
        dispatch({
          type: ConvertToOrderActions.SET_ZONE_ERROR,
          payload: 'This field is required',
        });
        hasShipmentInfoToSend = false;
      }

      if (address.hasErrors) {
        dispatch({
          type: ConvertToOrderActions.SET_ADDRESS_ERRORS,
          payload: address.errors,
        });
        hasShipmentInfoToSend = false;
      }
    }

    if (hasProductsInfoToSend || hasShipmentInfoToSend) {
      try {
        if (isMissingProductsInfo) {
          await saveProducts(quote, missingFields.productOptions.missing, productsFormData);
        }

        if (isMissingShipmentInfo) {
          const shipmentFields = resolveShipmentFields(missingFields);
          await saveShipment(shipment, shipmentFormData, shipmentFields);
        }

        onSubmit();
      } catch (error) {
        notification({
          type: NotificationType.ERROR,
          message: resolveApiErrorMessage((error as any).response),
        });
      }
    }
    dispatch({ type: ConvertToOrderActions.STOP_UPDATING_FIELDS });
  };
}
