import { OrderProductModel } from 'models/order';
import { ProductFormActions } from '../reducer';

const defaultValue = { value: '', label: 'None' };

export function setFormValue(
  dispatch,
  product: OrderProductModel,
  updateProductOptions: (productId: number, options) => void,
) {
  return (options, change) => {
    const fieldChanged = Object.keys(change)[0];
    const payload = change[fieldChanged].dropdownItem?.haveSuboptions
      ? {
        ...options,
        [`sub-${fieldChanged}`]: defaultValue,
        [fieldChanged]: {
          ...change[fieldChanged],
        }
      }
      : options;
    dispatch({ type: ProductFormActions.SET_FORM_VALUE, payload });
    updateProductOptions(product.id, payload);
  };
}
