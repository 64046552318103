import { MorphModel, ThreeModel } from 'models/three-model';
import { mapFileFromUrl } from 'helpers/file';
import uniqueId from 'helpers/unique-id';
import { ThreeFormActions } from '../reducer';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/property_setting/`;

const defaultMorph = {
  [uniqueId('empty-morph')]: {
    morphIndex: '0',
    morphLabel: 'height',
    property: {
      key: `${endpoint}height/`,
      label: 'height',
      value: `${endpoint}height/`,
    }
  },
  [uniqueId('empty-morph')]: {
    morphIndex: 1,
    morphLabel: 'width',
    property: {
      key: `${endpoint}width/`,
      label: 'width',
      value: `${endpoint}width/`,
    }
  },
  [uniqueId('empty-morph')]: {
    morphIndex: 2,
    morphLabel: 'depth',
    property: {
      key: `${endpoint}depth/`,
      label: 'depth',
      value: `${endpoint}depth/`,
    }
  }
};

const defaultModel = {
  threeModel: [],
  threeFileChanged: false,
  textureMapping: '',
  morphs: defaultMorph,
  initialMorphs: [],
};

const sortByIndex = (a, b) => a.morphIndex - b.morphIndex;

function mapMorphs(morphs: MorphModel[]) {
  return [...morphs].sort(sortByIndex).reduce((acc, morph) => ({
    ...acc,
    [morph.url]: {
      ...morph,
      morphIndex: morph.morphIndex || '0',
      property: {
        value: morph.property,
        label: morph.property.split('/').slice(-2).shift(),
      },
    },
  }), {});
}

export default function initFormData(
  dispatch,
  model: ThreeModel,
) {
  if (model) {
    const { threeModel, modelPosition, morphs, ...rest } = model;

    dispatch({
      type: ThreeFormActions.SET_MODEL,
      payload: {
        ...rest,
        modelPosition: (
          modelPosition
            ? { value: modelPosition.url, label: modelPosition.description }
            : undefined
        ),
        threeModel: mapFileFromUrl(threeModel),
        morphs: mapMorphs(morphs),
        initialMorphs: morphs.map(morph => morph.id),
        threeFileChanged: false,
      },
    });
  } else {
    dispatch({
      type: ThreeFormActions.SET_MODEL,
      payload: defaultModel,
    });
  }
}
