import { TrimAtColumnRender } from 'components/base-components/DataTableV2';

const getTrimLength = (width: number) => {
  const different = 2026 - width;
  const chartToRemove = Math.round(different / 6);
  const trimAt = 98 - chartToRemove;
  return trimAt < 20 ? 20 : trimAt;
};

export default function productTemplate(value, row) {
  const width = window?.innerWidth;
  if (typeof row.id === 'string') {
    return {
      props: {
        colSpan: 0
      },
    };
  }

  if (width <= 2026) {
    const timFunction = TrimAtColumnRender(getTrimLength(width));
    return timFunction(value);
  }

  return value;
}
