import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import filesViewerApi from 'api/file-viewer';

function sanitizingValue(value: any) {
  try {
    return value?.toString()?.includes('&') ? value.toString()?.replaceAll(/&/g, '%26') : value;
  } catch (error) {
    notification({ type: NotificationType.ERROR, message: 'Sorry, something went wrong' });
  }
  return value;
}

export default function downloadFile(actualParams: string, quoteId: number) {
  return (name: string) => {
    const filterFilename = `?filename=${sanitizingValue(name)}`;
    const params = actualParams ? `${filterFilename}&&${actualParams.slice(1)}` : filterFilename;

    const onSuccess = async () => {
      const message = 'File Successfully downloaded';
      notification({ type: NotificationType.SUCCESS, message });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    return filesViewerApi.read({ quoteId, params })
      .then(onSuccess)
      .catch(onError);
  };
}
