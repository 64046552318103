import { AxiosResponse } from 'axios';
import cncCalcApi, { ManualCutListResponse } from 'api/cnc-calc';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { ManualCalcFormActions, ManualProductModel } from '../reducer';

export default function calculateCNC(
  dispatch,
  productList: ManualProductModel[],
  goBack?,
) {
  return () => {
    dispatch({ type: ManualCalcFormActions.START_CALCULATIONS });
    const mappedProducts = productList.map(entry => ({ ...entry, model: entry.model.label }));
    const payload = { products: mappedProducts };

    const onSuccess = (response: AxiosResponse<ManualCutListResponse>) => {
      const { data: { xlsx: fileUrl } } = response;
      window.open(fileUrl);
      if (goBack) {
        goBack();
      }
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: ManualCalcFormActions.FINISH_CALCULATIONS });
    };

    return cncCalcApi
      .calculateManually(payload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
