import { resolveApiErrorMessage } from 'api/base';
import shipmentApi from 'api/shipment';
import shippingAddressApi from 'api/shipping-address';
import flatRateApi from 'api/flat-rate';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { addressRules } from 'components/pages/Quotes/Quote/ConvertToOrder/state/validations';
import { validateEntity } from 'helpers/form-validations';
import {
  UpdateShippingActions,
  FormAddressModel,
  FromShipmentModel,
  FormFlatRateModel,
} from '../reducer';
import { flatRateRules } from '../validations';
import { getAddressPayload, getShipmentPayload } from './get-payload';

async function update(
  addressPayload,
  isAddressChanged,
  shipment: FromShipmentModel,
  isFlatRateChanged: boolean,
  flatRateData,
) {
  let shippingAddress;
  if (isAddressChanged) {
    const actionAddress = addressPayload.id
      ? shippingAddressApi.update
      : shippingAddressApi.create;
    const { data: dataAddress } = await actionAddress(addressPayload);
    shippingAddress = dataAddress;
  }
  if (shipment.shipmentType?.dropdownItem?.ui === 'flat_rate' && isFlatRateChanged) {
    const actionFlatRate = flatRateData.id
      ? flatRateApi.update
      : flatRateApi.create;
    await actionFlatRate({ ...flatRateData, shipment: shipment.id });
  }
  const shipmentData = isAddressChanged ? { ...shipment, shippingAddress } : shipment;
  const [shipmentPayload, isShipmentChanged] = getShipmentPayload(shipmentData);
  if (isShipmentChanged || shippingAddress) {
    await shipmentApi.update(shipmentPayload);
  }
}
export function updateShipping(
  dispatch,
  shipment: FromShipmentModel,
  address: FormAddressModel,
  flatRate: FormFlatRateModel,
  goBack
) {
  return () => {
    const [addressPayload, isAddressChanged] = getAddressPayload(address);
    const { change: isFlatRateChanged, ...flatRateData } = flatRate;

    if (isAddressChanged) {
      const { hasErrors, errors } = validateEntity(address, addressRules);

      if (hasErrors) {
        return dispatch({
          type: UpdateShippingActions.SET_ADDRESS_VALIDATION_ERRORS,
          payload: errors,
        });
      }
    }

    if (shipment.shipmentType?.dropdownItem?.ui === 'flat_rate' && isFlatRateChanged) {
      const { hasErrors, errors } = validateEntity(flatRate, flatRateRules);

      if (hasErrors) {
        return dispatch({
          type: UpdateShippingActions.SET_FLAT_RATE_VALIDATION_ERRORS,
          payload: errors,
        });
      }
    }

    dispatch({ type: UpdateShippingActions.START_UPDATING });

    const onSuccess = () => {
      const message = 'Shipping Successfully Updated';
      notification({ type: NotificationType.SUCCESS, message });
      notifyEventChannel(EventChannelList.SHIPPING_PROJECT_CHANGE);
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: UpdateShippingActions.FINISH_UPDATING });
    };

    return update(addressPayload, isAddressChanged, shipment, isFlatRateChanged, flatRateData)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
