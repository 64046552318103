import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import InputNumber from 'components/base-components/InputNumber';
import { validationRules } from './validations';
import useSheetFormState from './state';
import './styles.scss';

export interface SheetFormHistorySateModel {
  sheetForm: {
    sheetId: string;
  };
}

const SheetForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { sheetForm: { sheetId } } = state as SheetFormHistorySateModel;

  const {
    state: {
      saving,
      formData,
      fetchingEntity,
      errors,
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  } = useSheetFormState(sheetId);

  const modalTitle = useMemo(
    () => sheetId ? 'Edit Sheet' : 'Create Sheet',
    [sheetId],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveEntity}
      confirmLoading={saving}
    >
      <RenderIf isTrue={!fetchingEntity} fallback={<Spinner />}>
        <Form
          state={formData}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="code"
            name="code"
            label="Code"
            placeholder="Enter Code"
          />
          <Field
            component={InputNumber}
            name="x"
            id="x"
            label="X"
            placeholder="Enter X"
            inputClassName="sheet__input-number"
          />
          <Field
            component={InputNumber}
            name="y"
            id="y"
            label="Y"
            placeholder="Enter Y"
            inputClassName="sheet__input-number"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default SheetForm;
