import { PartModel } from 'models/part';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/parts/`;

const partApi: ApiModel<PartModel> = {
  ...getApi<PartModel>(endpoint, ApiContentType.MULTIPART),
};

export default partApi;
