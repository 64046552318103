import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { DatePicker as AntDatePicker } from 'antd';
import { Moment } from 'moment';
import Label from 'components/base-components/Label';
import InputError from '../InputError';
import './styles.scss';

interface Props {
  label: string;
  value: Moment;
  onChange: (event) => void;
  id?: string;
  placeholder?: string;
  mode?: 'date' | 'month' | 'year' | 'decade';
  autoFocus?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  inputReadOnly?: boolean;
  showNow?: boolean;
  showToday?: boolean;
  showTime?: boolean;
  renderExtraFooter?: (mode: string) => ReactNode;
  mR?: boolean;
  className?: string;
  inputClassName?: string;
  required?: boolean;
  error?: string;
}

const DatePicker: FunctionComponent<Props> = (props) => {
  const {
    id,
    label,
    mR,
    className,
    inputClassName,
    onChange,
    required,
    error,
    ...rest
  } = props;

  const datepickerClassNames = useMemo(
    () => classnames('date-picker', { mR, error }, className),
    [className, mR, error]
  );

  const antInputClassName = useMemo(
    () => classnames('date-picker__input', inputClassName),
    [inputClassName]
  );

  const handleOnChange = useCallback(
    (date) => {
      onChange({ target: { value: date } });
    },
    [onChange]
  );

  return (
    <div className={datepickerClassNames}>
      <Label text={label} forInput={id} required={required} />
      <AntDatePicker
        id={id}
        className={antInputClassName}
        onChange={handleOnChange}
        {...rest}
      />
      <InputError error={error} />
    </div>
  );
};

DatePicker.defaultProps = {
  mode: 'date',
  allowClear: false,
};

export default DatePicker;
