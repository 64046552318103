/* eslint-disable */
// eslint-disable-next-line max-classes-per-file
import React from 'react';

export const getMorphValue = (value: number) => (value - 5) / (300 - 5);
class OptionSection extends React.Component<any> {
  handleMaterialChange = (target, material) => {
    this.props.onMaterialChange(target, material);
  };

  // eslint-disable-next-line @typescript-eslint/camelcase
  handleStyleChange = (hide_name, show_name) => {
    this.props.onStyleChange(hide_name, show_name);
  };

  renderMaterialSection = () => {
    const { materials } = this.props.info;
    if (!Array.isArray(materials)) return;
    // eslint-disable-next-line consistent-return
    return (
      <div>
        {materials.map((item, index) => (
          <div key={index}>
            <span style={{ color: '#888' }}>{item.label}</span>
            <div className="textures-container">
              {item.types.map((type, i) => (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  key={i}
                  className="texture-item"
                  onClick={() => {
                    const target = item.name_in_model;
                    this.handleMaterialChange(target, type);
                  }}
                >
                  <img
                    alt={type.label}
                    src={type.texture}
                    className="thumbnail"
                  />
                  <div className="label">{type.label}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderStyleSection = () => {
    const { styles } = this.props.info;
    if (!Array.isArray(styles)) return;
    // eslint-disable-next-line consistent-return
    return (
      <div>
        {styles.map((item, index) => (
          <div key={index}>
            <span style={{ color: '#888' }}>{item.label}</span>
            <div className="styles-container">
              {item.types.map((type, i) => (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  key={i}
                  className="style-item"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    const hide_name = item.name_in_model; const show_name = type.name_in_model;
                    this.handleStyleChange(hide_name, show_name);
                  }}
                >
                  {type.thumbnail && (
                    <img
                      alt={type.label}
                      src={type.thumbnail}
                      className="thumbnail"
                    />
                  )}
                  <div className="label">{type.label}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { info } = this.props;
    return (
      <div className="set-group-builder__option-section">
        {!info && <p>No item selected</p>}
        {info && this.renderMaterialSection()}
        {info && this.renderStyleSection()}
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface iProps {
  id?: string;
  info: any;
  onMorphChanged: (
    index: number,
    value: number,
    rawValue?: number,
    forceUpdate?: boolean
  ) => void;
  onMaterialChanged: (target: string, material: string) => void;
  // eslint-disable-next-line @typescript-eslint/camelcase
  onStyleChanged: (hide_name: string, show_name: string) => void;
}

export default class ProductInfoSection extends React.Component<iProps> {
  renderMorphingSection = () => {
    const { info, id } = this.props;
    if (!info || !Array.isArray(info.morph)) return;
    // eslint-disable-next-line consistent-return
    return info.morph.map((item, i) => {
      const { index } = item;
      const min = parseInt(item.min || 0, 10);
      const max = parseInt(item.max || 100, 10);
      const value = parseInt(item.value || min, 10);

      if (min === max) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        typeof this.props.onMorphChanged === 'function' &&
          this.props.onMorphChanged(index, getMorphValue(min), min, false);
        return null;
      }

      if (item.value > max) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        typeof this.props.onMorphChanged === 'function' &&
          this.props.onMorphChanged(index, getMorphValue(max), max, false);
      }

      return (
        <div className="input-container" key={i}>
          <label style={{ fontWeight: 'bold' }}>{item.label}</label>
          <span style={{ paddingLeft: 10, paddingRight: 10 }}>
            {min}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            "
          </span>
          <input
            type="range"
            min={min}
            max={max}
            data-id={id}
            data-field={item.label.toLowerCase()}
            defaultValue={0}
            value={value}
            step={0.1}
            onChange={(e) => {
              let morphValue = parseFloat(e.target.value);
              morphValue = getMorphValue(morphValue);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              typeof this.props.onMorphChanged === 'function' &&
                this.props.onMorphChanged(
                  index,
                  morphValue,
                  parseFloat(e.target.value)
                );
            }}
          />
          <span style={{ paddingLeft: 10 }}>
            {max}
            "
          </span>
          {/* <label>{parseInt(value / 2.54)}"</label> */}
        </div>
      );
    });
  };

  render() {
    const { info } = this.props;
    return (
      <div className="">
        <h4 style={{ margin: 10 }}>Options</h4>
        {this.renderMorphingSection()}
        <OptionSection
          info={info}
          onMaterialChange={this.props.onMaterialChanged}
          onStyleChange={this.props.onStyleChanged}
        />
      </div>
    );
  }
}
