import { ProjectModel } from 'models/project';
import { TaskUserModel } from 'models/task-user';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';

export function mapProjects(projects: ProjectModel[]) {
  return mapGenericDropdownOptions(projects, {
    fields: {
      label: 'jobTitle',
      value: 'id',
    },
  });
}

export function mapUsers(users: TaskUserModel[]) {
  return mapGenericDropdownOptions(users, {
    fields: {
      label: 'email',
      value: 'user',
    },
  });
}
