import { AxiosPromise } from 'axios';
import { ProductionManagerModel } from 'models/production-manager';
import { ApiContentType, ApiModel, put } from 'api/base';
import getApi from 'api/base/get-api';
import downloadBlob from './base/download-blob';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/production-manager/`;

interface ProductionManagerApiModel extends ApiModel<ProductionManagerModel> {
  setReadyDateConfirmation: (orderId, payload) => AxiosPromise<ProductionManagerModel>;
  setReadyDate: (orderId, payload) => AxiosPromise<ProductionManagerModel>;
  setFabricationPercentage: (orderId, payload) => AxiosPromise<ProductionManagerModel>;
  setCncPercentage: (orderId, payload) => AxiosPromise<ProductionManagerModel>;
  setProductionStatus: (orderId, payload) => AxiosPromise<ProductionManagerModel>;
  printShop: (order: number) => Promise<void>;
  printInstaller: (order: number) => Promise<void>;
}

const productionManagerApi: ProductionManagerApiModel = {
  ...getApi<ProductionManagerModel>(endpoint, ApiContentType.JSON),
  setCncPercentage(orderId, payload) {
    return put(`${endpoint}${orderId}/set-cnc-percentage/`, payload);
  },
  setReadyDateConfirmation(orderId, payload) {
    return put(`${endpoint}${orderId}/ready-date-confirmation/`, payload);
  },
  setReadyDate(orderId, payload) {
    return put(`${endpoint}${orderId}/set-ready-date/`, payload);
  },
  setFabricationPercentage(orderId, payload) {
    return put(`${endpoint}${orderId}/set-fabrication-percentage/`, payload);
  },
  setProductionStatus(orderId, payload) {
    return put(`${endpoint}${orderId}/set-production-status/`, payload);
  },
  printShop(order: number) {
    return downloadBlob(
      `${endpoint}${order}/shop-package/`,
      `Shop Order ${order}.pdf`,
    );
  },
  printInstaller(order: number) {
    return downloadBlob(
      `${endpoint}${order}/installer-package/`,
      `Installer Order ${order}.pdf`,
    );
  },
};

export default productionManagerApi;
