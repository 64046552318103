import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { List } from 'antd';
import { Waypoint } from 'react-waypoint';
import RenderIf from '../RenderIf';
import Spinner from '../Spinner';
import Empty from '../Empty';
import './styles.scss';

interface Props{
  loadMore: any;
  renderItem: (item) => any;
  items: any[];
  hasMore: boolean;
  loading: boolean;
}

const AsyncList: FunctionComponent<Props> = (props) => {
  const { hasMore, items, loadMore, renderItem, loading } = props;
  const internalItems = useMemo(() => {
    if (hasMore && loadMore) {
      return items.concat({ waypoint: true });
    }
    return items;
  }, [hasMore, loadMore, items]);

  const internalRender = useCallback((item) => item.waypoint ? (
    <div className="select__infinity-scroll">
      <Spinner size="small" centered={false} />
      <Waypoint
        key="cursor"
        onEnter={loadMore}
        bottomOffset="0px"
      />
    </div>
  ) : renderItem(item),
  [renderItem, loadMore]);

  return (
    <div className="async-list__infinite-container">
      <List dataSource={internalItems} renderItem={internalRender}>
        <RenderIf isTrue={loading}>
          <div className="async-list__loading-container">
            <Spinner size="small" centered={false} />
          </div>
        </RenderIf>
        <RenderIf isTrue={!items.length && !loading}>
          <Empty />
        </RenderIf>
      </List>
    </div>
  );
};

export default AsyncList;
