import shipmentApi from 'api/shipment';
import notification, { NotificationType } from 'helpers/notification';
import { QueryParams } from 'helpers/query-params';

export default function printQuestionnaire(item) {
  const { projectId } = item;
  const params: QueryParams = { filters: { project: projectId } };
  const onError = (error) => {
    notification({
      type: NotificationType.ERROR,
      message: error,
    });
  };

  return shipmentApi.list(params)
    .then(response => !!response.data.results[0].shippingQuestionnaire
      ? shipmentApi.printQuestionnaire(response.data.results[0].id)
      : onError('This Order has no Questionnaire')).catch(onError);
}
