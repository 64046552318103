import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useHistory } from 'react-router';
import orderApi from 'api/orders';
import calendarEventApi from 'api/calendar-event';
import installerBookingApi from 'api/installer-booking';
import useGetList, { GetListOptions } from 'hooks/base/get-list';
import useCallApiAction from 'hooks/base/call-api-action';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import generateSchedule, { ScheduleSource } from './actions/generate-schedule';
import handleOnDrop from './actions/handle-on-drop';
import generateDateRange from './actions/generate-date-range';
import buildOrdersSchedule from './actions/build-orders-schedule';
import buildBookingsSchedule from './actions/build-bookings-schedule';
import buildCustomEventSchedule from './actions/build-custom-event-schedule';
import handleSourcesChange from './actions/handle-sources-change';
import handleEventClick from './actions/handle-event-click';
import handleDateChange from './actions/handle-date-change';
import handleViewModeChange from './actions/handle-view-mode-change';
import productionCalendarReducer, {
  CalendarState,
  EventItemType,
  ViewMode,
  CalendarActions
} from './reducer';
import handleDayLinkClick from './actions/handle-day-link-click';
import handleWeekLinkClick from './actions/handle-week-link-click';

const initState = (): CalendarState => {
  return {
    schedule: [],
    sources: [EventItemType.BOOKING, EventItemType.ORDER],
    date: new Date(),
    view: ViewMode.MONTH,
  };
};

const getOptionInitialValue = {
  actions: {
    POST: {
      event_type: { choices: [] },
      status: { choices: [] },
    }
  }
};

export default function useCalendarState(visible: boolean) {
  const { push } = useHistory();
  const calendarRef = useRef(undefined);
  const [state, dispatch] = useReducer(
    productionCalendarReducer,
    undefined,
    initState,
  );

  const { date, schedule, sources: selectedSources } = state;

  const dateRage = useMemo(() => generateDateRange(date), [date]);

  const ordersParams = useMemo<GetListOptions>(() => ({
    preventAutoFetch: !visible || !selectedSources.some(
      source => source === EventItemType.ORDER,
    ),
    queryParams: {
      filters: {
        order_status__status__iexact: 'In+Production',
        ready_date__date__range: dateRage,
      }
    }
  }), [visible, dateRage, selectedSources]);

  const {
    data: orders,
    fetching: fetchingOrders,
    fetchList: fetchOrders
  } = useGetList(orderApi, ordersParams);

  const bookingsParams = useMemo<GetListOptions>(() => {
    const bookingDateRange = generateDateRange(date, true);
    return {
      preventAutoFetch:
        !visible ||
        !selectedSources.some(source => source === EventItemType.BOOKING) ||
        !bookingDateRange,
      queryParams: {
        filters: {
          installation_date__date__range: bookingDateRange,
        }
      }
    };
  }, [visible, date, selectedSources]);

  const {
    fetching: fetchingBookings,
    data: bookings,
    fetchList: fetchBookings,
  } = useGetList(installerBookingApi, bookingsParams);

  const customEventParams = useMemo<GetListOptions>(() => ({
    preventAutoFetch: !visible || !selectedSources.some(
      source => source !== EventItemType.BOOKING && source !== EventItemType.ORDER,
    ),
    queryParams: {
      filters: {
        scheduled__date__range: dateRage,
        event_type__in: selectedSources
          .filter(source => source !== EventItemType.BOOKING && source !== EventItemType.ORDER)
          .join(',')
      }
    }
  }), [visible, dateRage, selectedSources]);

  const {
    fetching: customEventFetching,
    data: customEvent,
    fetchList: fetchCustomEvent,
  } = useGetList(calendarEventApi, customEventParams);

  const refetchOrders = useCallback(
    () => fetchOrders(ordersParams.queryParams),
    [fetchOrders, ordersParams],
  );
  const refetchBookings = useCallback(
    () => fetchBookings(bookingsParams.queryParams),
    [fetchBookings, bookingsParams],
  );
  const refetchCustomEvent = useCallback(
    () => fetchCustomEvent(customEventParams.queryParams),
    [fetchCustomEvent, customEventParams]
  );

  const {
    data: options,
    fetching: fetchingOptions,
  } = useCallApiAction(calendarEventApi.getOptions, undefined, getOptionInitialValue);

  useEffect(() => {
    const eventSources: ScheduleSource[] = [
      {
        type: EventItemType.ORDER,
        list: orders,
        builderFunction: buildOrdersSchedule,
      },
      {
        type: EventItemType.BOOKING,
        list: bookings,
        builderFunction: buildBookingsSchedule,
      },
      {
        type: EventItemType.CUSTOM,
        list: customEvent,
        builderFunction: buildCustomEventSchedule,
      },
    ].filter(source => (
      source.type === EventItemType.CUSTOM
        ? selectedSources.some(src => src !== EventItemType.BOOKING && src !== EventItemType.ORDER)
        : selectedSources.some(src => src === source.type)));

    generateSchedule(dispatch, eventSources);
  }, [selectedSources, orders, bookings, customEvent]);

  useEffect(() => {
    if (options?.actions?.POST?.event_type?.choices.length > 0) {
      dispatch({
        type: CalendarActions.SET_SOURCES,
        payload: [
          EventItemType.BOOKING,
          EventItemType.ORDER,
          ...options?.actions?.POST?.event_type?.choices.map(e => e.value)
        ]
      });
    }
  }, [options]);

  useEventCenterUpdate(
    EventChannelList.PRODUCTION_MANAGER_CHANGED,
    refetchOrders,
  );
  useEventCenterUpdate(
    EventChannelList.INSTALLER_BOOKING_LIST_CHANGE,
    refetchBookings,
  );
  useEventCenterUpdate(
    EventChannelList.CUSTOM_EVENT_LIST_CHANGED,
    refetchCustomEvent,
  );

  return {
    state: {
      ...state,
      schedule,
      calendarRef,
      fetchingOptions,
      fetchingData: fetchingOrders || fetchingBookings || customEventFetching,
      eventTypesOptions: useMemo(() => options?.actions?.POST?.event_type?.choices.map(e => e.value), [options]),
    },
    actions: {
      handleOnDrop,
      handleDateChange: useCallback(handleDateChange(dispatch), []),
      handleViewModeChange: useCallback(handleViewModeChange(dispatch), []),
      handleSourcesChange: useCallback(handleSourcesChange(dispatch), []),
      handleEventClick: useCallback(handleEventClick(push), []),
      handleDayLinkClick: useCallback(
        handleDayLinkClick(dispatch, calendarRef),
        [],
      ),
      handleWeekLinkClick: useCallback(
        handleWeekLinkClick(dispatch, calendarRef),
        [],
      ),
    }
  };
}
