import React, { FunctionComponent } from 'react';

interface Props {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onPan: (direction: string) => void;
  onHomeClicked: () => void;
}

const CameraControls: FunctionComponent<Props> = (props) => {
  const { onZoomIn, onZoomOut, onPan, onHomeClicked } = props;

  return (
    <div className="bp3d__camera-controls">
      <button className="control-button" onClick={onHomeClicked}>
        <i className="fa fa-home" />
      </button>
      <button className="control-button" onClick={onZoomOut}>
        <i className="fa fa-search-minus" />
      </button>
      <button className="control-button" onClick={onZoomIn}>
        <i className="fa fa-search-plus" />
      </button>
      <button className="control-button" onClick={() => onPan('LEFT')}>
        <i className="fa fa-arrow-left" />
      </button>
      <div>
        <button className="control-button" onClick={() => onPan('UP')}>
          <i className="fa fa-arrow-up" />
        </button>
        <button className="control-button" onClick={() => onPan('DOWN')}>
          <i className="fa fa-arrow-down" />
        </button>
      </div>
      <button className="control-button" onClick={() => onPan('RIGHT')}>
        <i className="fa fa-arrow-right" />
      </button>
    </div>
  );
};

export default CameraControls;
