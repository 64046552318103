import { useEffect, useMemo } from 'react';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import installerApi from 'api/installer';
import { validationRules } from './validations';

const defaultInstaller = {
  companyName: '',
};

const getPayload = (installer) => installer;
const getFormData = (installer) => {
  return installer || defaultInstaller;
};

export default function useInstallerFormState(installerId: number) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: installerApi,
    eventChannel: EventChannelList.INSTALLER_LIST_CHANGE,
    param: installerId,
    getPayload,
    getFormData,
    validationRules
  }), [installerId]);

  const {
    state: {
      formData: installer,
      errors,
      saving,
      errorFetchingEntity: errorFetchingInstaller,
      fetchingEntity: fetchingInstaller,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState(useFormStateParams);

  useEffect(() => {
    if (errorFetchingInstaller) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingInstaller,
      });
    }
  }, [errorFetchingInstaller]);

  return {
    state: {
      installer,
      savingInstaller: saving,
      fetchingFormData: fetchingInstaller,
      errors,
    },
    actions: {
      setFormValue,
      saveInstaller: saveEntity,
      setErrors,
    },
  };
}
