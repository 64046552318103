import React from 'react';
import SetData from 'lib/products/sets';

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface iProps {
  onSetClicked: (data: any) => void;
  onClose: () => void;
}

function SetList(props: iProps) {
  const { onSetClicked, onClose } = props;
  return (
    <div style={{ padding: 5 }}>
      <div style={{ display: 'flex', alignItems: 'center', padding: 5 }}>
        <h3 style={{ flex: 1, margin: 0 }}>Set List</h3>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onClick={onClose}>
          <span className="fa fa-close" />
        </div>
      </div>
      <div>
        {SetData.map((item, index) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className="set-item"
            key={index}
            onClick={() => onSetClicked(item)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SetList;
