import { SubOption } from 'models/option';
import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum SubOptionFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_DATA = 'SET_DATA',
  UPDATE_DATA = 'UPDATE_DATA',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  NEXT_PAGE = 'NEXT_PAGE',
  START_SAVING = 'START_SAVING',
  FINISH_SAVING = 'FINISH_SAVING',
}

export interface SubOptionFormModel {
  option: string;
  code: string;
  brand: DropdownOptionModel;
  name: string;
  url?: string;
}

interface SubOptionFormState {
  formData: SubOptionFormModel;
  subOptionSelected: string;
  page: number;
  subOptions: SubOption[];
  saving: boolean;
  errors: any;
}

export default function subOptionFormReducer(
  state: SubOptionFormState,
  action
): SubOptionFormState {
  const { type, payload } = action;

  switch (type as SubOptionFormActions) {
    case SubOptionFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload, subOptionSelected: payload.url };
    case SubOptionFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, saving: false };
    case SubOptionFormActions.SET_DATA:
      return { ...state, subOptions: payload };
    case SubOptionFormActions.UPDATE_DATA:
      return { ...state, subOptions: state.subOptions.concat(payload) };
    case SubOptionFormActions.NEXT_PAGE:
      return { ...state, page: state.page + 1 };
    case SubOptionFormActions.START_SAVING:
      return { ...state, saving: true };
    case SubOptionFormActions.FINISH_SAVING:
      return { ...state, saving: false };
    default:
      return state;
  }
}
