import React, { FunctionComponent } from 'react';
import { ShipmentContactModel } from 'models/shipment';
import InputRow from 'components/base-components/InputRow';
import Field from 'components/base-components/Form/Field';
import Form from 'components/base-components/Form';
import Button from 'components/base-components/Button';
import { contactRules } from './state/validations';

interface Props {
  contactInfo: ShipmentContactModel;
  setContactInfo: (contactInfo) => void;
  contactErrors: { [field: string]: string };
  setContactErrors: (errors) => void;
  fetchingContact: boolean;
  disableContact: boolean;
  copyContactInfo: () => void;
}

const ContactInfo: FunctionComponent<Props> = (props) => {
  const {
    contactInfo,
    setContactInfo,
    contactErrors,
    setContactErrors,
    fetchingContact,
    disableContact,
    copyContactInfo,
  } = props;

  return (
    <Form
      state={contactInfo}
      onChange={setContactInfo}
      errors={contactErrors}
      onError={setContactErrors}
      rules={contactRules}
    >
      <div className="questionnaire__info-group__header">
        <h1 className="questionnaire__header">
          Contact at Location
        </h1>
        <Button
          type="default"
          label="Copy from Contact"
          loading={fetchingContact}
          disabled={disableContact}
          onClick={copyContactInfo}
          mB
        />
      </div>
      <InputRow>
        <Field name="firstName" label="First Name" mR />
        <Field name="lastName" label="Last Name" />
      </InputRow>
      <InputRow>
        <Field name="telephone" label="Telephone" mR />
        <Field name="email" label="E-Mail" />
      </InputRow>
    </Form>
  );
};

export default ContactInfo;
