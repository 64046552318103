import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { ApplyOptionsHistorySateModel } from '../../Form/ApplyOptions';

export default function openAddProductModal(
  push,
  dataQuote: QuoteModel,
  groupCategories: {[x: string]: string[]},
  groups: string[],
) {
  return () => {
    const historyLocationState: ApplyOptionsHistorySateModel = {
      applyOptionsForm: {
        groups,
        groupCategories,
        quoteId: dataQuote?.id,
      },
    };
    push(ModalRouteHash.ApplyOptions, historyLocationState);
  };
}
