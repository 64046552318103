import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import inventoryApi from 'api/inventory';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { InventoryModel } from 'models/inventory';
import { EventChannelList } from 'helpers/event-center';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { InventoryFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: inventoryApi,
  deleteModalTitle: 'Delete Inventory',
  deleteModalContent: (entryToDelete: InventoryModel) => `Are you sure that you want to delete the Inventory for ${entryToDelete?.partDetail?.name || ''}?`,
  searchFields: ['part__name', 'quantity'],
  eventChannel: EventChannelList.INVENTORY_LIST_CHANGED,
  defaultSortField: '-id',
};

const InventoryList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<InventoryModel>(useTableStateParams);

  const openInventoryFormModal = (inventory?: InventoryModel | any) => {
    const historyLocationState: InventoryFormHistorySateModel = {
      inventoryForm: {
        inventoryUrl: inventory ? inventory.url : undefined,
      },
    };
    push(ModalRouteHash.InventoryForm, historyLocationState);
  };

  return (
    <Page title="Inventory">
      <PageActions primary>
        <AccessControl permissions={permissions.addInventory}>
          <Button
            onClick={openInventoryFormModal}
            label="Add Inventory"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn dataIndex={['partDetail', 'name']} columnKey="part" title="Part Name" sorter />
        <DataTableColumn dataIndex={['partDetail', 'units']} columnKey="units" title="Part Units" />
        <DataTableColumn dataIndex="quantity" columnKey="quantity" title="Quantity" sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editInventory}
          deletePermissions={permissions.deleteInventory}
          onEdit={openInventoryFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default InventoryList;
