import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import { validationRules } from './validations';
import useInstallerFormState from './state';

export interface InstallerFormHistorySateModel {
  installerForm: {
    installerId: number;
  };
}

const InstallerForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { installerForm: { installerId } } = state as InstallerFormHistorySateModel;

  const {
    state: {
      savingInstaller,
      installer,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveInstaller,
      setErrors,
    },
  } = useInstallerFormState(installerId);

  const modalTitle = useMemo(
    () => installerId ? 'Edit Installer' : 'Create Installer',
    [installerId],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveInstaller}
      confirmLoading={savingInstaller}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={installer}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="companyName"
            name="companyName"
            label="Company Name"
            placeholder="Enter Company Name"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default InstallerForm;
