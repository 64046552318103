import { resolveApiErrorMessage } from 'api/base';
import { InstallerBookingDTO } from 'models/project-coordinator';
import installerBookingApi from 'api/installer-booking';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { installerFormActions } from '../reducer';

export default function onDelete(dispatch, oldData: InstallerBookingDTO[], orderId: number) {
  return (setDeleting, installerBooking: InstallerBookingDTO) => {
    setDeleting(true);

    const onSuccess = () => {
      const message = 'Installer Successfully Deleted';
      notification({ type: NotificationType.SUCCESS, message });
      const payload = oldData.filter(inst => inst.id !== installerBooking.id);
      notifyEventChannel(EventChannelList.PROJECT_COORDINATOR_UPDATE, { id: orderId, installerBookings: payload });
      dispatch({ type: installerFormActions.SET_DATA, payload });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      setDeleting(false);
    };

    return installerBookingApi.delete(installerBooking.id)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
