import { CategoryModel } from 'models/category';
import { mapDropdownOptions, mapGenericDropdownData } from 'helpers/dropdown-options';
import { mapFileFromUrl } from 'helpers/file';
import { CategoryFormModel } from '..';

const defaultCategory: CategoryFormModel = {
  name: '',
  nameFr: '',
  description: '',
  descriptionFr: '',
  parent: undefined,
  navBar: false,
  status: false,
  outsidePaint: false,
  cnc: false,
  optionGrp: [],
  categories: [],
  image: [],
  overlapable: false,
  stackable: false,
  levelDescription: '',
  threeModel: undefined,
  modelName: '',
  stackontop: false,
};

export default function getFormData(category: CategoryModel) {
  if (category) {
    const { parent, optionGrp, categories, image, threeModel, ...rest } = category;
    return {
      ...rest,
      parent: mapGenericDropdownData(parent, { fields: { value: 'url', label: 'name' } }),
      threeModel: mapGenericDropdownData(threeModel, { fields: { value: 'url', label: 'modelName' } }),
      optionGrp: mapDropdownOptions(optionGrp),
      categories: mapDropdownOptions(categories),
      image: mapFileFromUrl(image),
    };
  }
  return defaultCategory;
}
