import {
  CalendarActions,
  CalendarEvent,
  EventItemModel,
  EventItemType,
} from '../reducer';

export interface ScheduleSource {
  type: EventItemType;
  list: EventItemModel[];
  builderFunction: (list) => CalendarEvent[];
}

export default function generateSchedule(
  dispatch,
  sources: ScheduleSource[],
) {
  const schedule = sources.flatMap(
    ({ list, builderFunction }) => builderFunction(list)
  );

  dispatch({ type: CalendarActions.SET_SCHEDULE, payload: schedule });
}
