import { createSlice } from '@reduxjs/toolkit';
import { get, post, put, del, ApiContentType } from 'api/base';
import { EventChannelList } from 'helpers/event-center/channels';
import { notifyEventChannel } from 'helpers/event-center';

const { REACT_APP_ACCOUNTS_API } = process.env;
const url = `${REACT_APP_ACCOUNTS_API}/accounts-app/`;

const defaultAccountApplication = {
  businessTradeName: '',
  status: 'pending',
  ownerFirstName: '',
  ownerLastName: '',
  ownerEmail: '',
  businessLegalName: '',
  website: '',
  businessType: 'Proprietorship',
  businessPhone: '',
  businessAddress: '',
  businessCity: '',
  businessStateProvince: '',
  businessPostCode: '',
  businessEmail: '',
  businessNumber: '',
  irsGstNumber: '',
  yearsInBusiness: 0,
  creditAmount: 0,
  apName: '',
  apEmail: '',
  taxExempt: null,
  bankName: '',
  accountNumber: '',
  bankAddress: '',
  bankCity: '',
  bankStateProvince: '',
  bankPostCode: '',
  bankPhone: '',
  ref1BusinessName: '',
  ref1ContactEmail: '',
  ref1ContactPhone: '',
  ref2BusinessName: '',
  ref2ContactEmail: '',
  ref2ContactPhone: '',
  ref3BusinessName: '',
  ref3ContactEmail: '',
  ref3ContactPhone: '',
};

export const ACCOUNT_APPLICATION_MODALS = {
  DETAILS_MODAL: 'DETAILS_MODAL',
};

const initialState = {
  accountApplications: [],
  accountApplication: defaultAccountApplication,
  count: 0,
  error: null,
  loading: false,
  fetchingAccountApplication: false,
  accountApplicationAction: {
    loading: false,
    message: null
  },
  routToLogin: false,
  showModal: undefined,
};

const accountApplicationsSlice = createSlice({
  name: 'accountApplications',
  initialState,
  reducers: {
    getAccountApplicationsStart(state) {
      state.loading = true;
      state.error = null;
    },
    getAccountApplicationsSuccessful(state, action) {
      state.loading = false;
      state.accountApplications = action.payload.results;
      state.count = action.payload.count;
      state.error = null;
    },
    getAccountApplicationsFail(state, action) {
      state.accountApplications = null;
      state.loading = false;
      state.error = action.payload;
    },
    getAccountApplicationStart(state) {
      state.accountApplication = {...defaultAccountApplication};
      state.fetchingAccountApplication = true;
      state.error = null;
    },
    getAccountApplicationSuccessful(state, action) {
      state.fetchingAccountApplication = false;
      state.accountApplication = action.payload;
      state.error = null;
    },
    getAccountApplicationFail(state, action) {
      state.accountApplication = {...defaultAccountApplication};
      state.fetchingAccountApplication = false;
      state.error = action.payload;
    },
    saveAccountApplicationStart(state) {
      state.accountApplicationAction.loading = true;
      state.routToLogin = false;
    },
    saveAccountApplicationSuccess(state) {
      state.accountApplicationAction.loading = false;
      state.accountApplicationAction.message = 'Account application was successfully saved';
      state.showModal = undefined;
      state.routToLogin = true;
    },
    saveAccountApplicationFail(state, action) {
      state.accountApplicationAction.loading = false;
      state.accountApplicationAction.message = action.payload;
      state.routToLogin = false;
    },
    deleteAccountApplicationStart(state) {
      state.accountApplicationAction.loading = true;
    },
    deleteAccountApplicationSuccess(state) {
      state.accountApplicationAction.loading = false;
      state.accountApplicationAction.message = 'Account application was successfully deleted';
      state.showModal = undefined;
    },
    deleteAccountApplicationFail(state, action) {
      state.accountApplicationAction.loading = false;
      state.accountApplicationAction.message = action.payload;
    },
    setRoutToLogin(state, action) {
      state.routToLogin = action.payload;
    },
    openDetailsModal(state, action) {
      state.showModal = ACCOUNT_APPLICATION_MODALS.DETAILS_MODAL;
      state.accountApplication = action.payload || {...defaultAccountApplication};
    },
    closeModal(state) {
      state.showModal = undefined;
      state.accountApplication = {...defaultAccountApplication};
      state.accountApplicationAction.message = undefined;
    },
  }
});

const {
  getAccountApplicationsStart,
  getAccountApplicationsSuccessful,
  getAccountApplicationsFail,
  getAccountApplicationStart,
  getAccountApplicationSuccessful,
  getAccountApplicationFail,
  saveAccountApplicationStart,
  saveAccountApplicationSuccess,
  saveAccountApplicationFail,
  deleteAccountApplicationStart,
  deleteAccountApplicationSuccess,
  deleteAccountApplicationFail,
} = accountApplicationsSlice.actions;

export const {
  setRoutToLogin,
  closeModal,
  openDetailsModal,
} = accountApplicationsSlice.actions;

export const fetchAccountApplicationsList = (queryParams) => async dispatch => {
  try {
    dispatch(getAccountApplicationsStart());

    const response = await get(url, queryParams);
    dispatch(getAccountApplicationsSuccessful(response.data));
  } catch (error) {
    dispatch(getAccountApplicationsFail(error));
  }
};

export const fetchAccountApplication = (accountApplicationUrl) => async dispatch => {
  try {
    dispatch(getAccountApplicationStart());

    const response = await get(accountApplicationUrl);
    dispatch(getAccountApplicationSuccessful(response.data));
  } catch (error) {
    dispatch(getAccountApplicationFail(error));
  }
};

export const createAccountApplication = accountApplication => async dispatch => {
  try {
    dispatch(saveAccountApplicationStart());

    await post(url, accountApplication, ApiContentType.MULTIPART);

    notifyEventChannel(EventChannelList.ACCOUNT_APPLICATIONS_LIST_CHANGED);
    dispatch(saveAccountApplicationSuccess());
  } catch (error) {
    const messages = (error as any).response ? Object.values((error as any).response.data).flat() : "Sorry, something went wrong";
    dispatch(saveAccountApplicationFail(messages));
  }
};

export const updateAccountApplication = accountApplication => async dispatch => {
  try {
    dispatch(saveAccountApplicationStart());

    await put(accountApplication.url, accountApplication, ApiContentType.MULTIPART);

    notifyEventChannel(EventChannelList.ACCOUNT_APPLICATIONS_LIST_CHANGED);
    dispatch(saveAccountApplicationSuccess());
  } catch (error) {
    const messages = (error as any).response ? Object.values((error as any).response.data).flat() : "Sorry, something went wrong";
    dispatch(saveAccountApplicationFail(messages));
  }
};

export const deleteAccountApplication = accountApplicationUrl => async dispatch => {
  try {
    dispatch(deleteAccountApplicationStart());

    await del(accountApplicationUrl);

    notifyEventChannel(EventChannelList.ACCOUNT_APPLICATIONS_LIST_CHANGED);
    dispatch(deleteAccountApplicationSuccess());
  } catch (error) {
    const messages = (error as any).response ? Object.values((error as any).response.data).flat() : "Sorry, something went wrong";
    dispatch(deleteAccountApplicationFail(messages));
  }
};

export default accountApplicationsSlice.reducer;
