import { OrderModel } from 'models/order';
import { CalendarEventModel } from 'models/calendar-event';
import { InstallerBookingModel } from 'models/installer-booking';

export enum CalendarActions {
  SET_SCHEDULE = 'SET_SCHEDULE',
  SET_DATE = 'SET_DATE',
  SET_VIEW_MODE = 'SET_VIEW_MODE',
  SET_SOURCES = 'SET_SOURCES',
  SWITCH_VIEW_AND_DATE = 'SWITCH_VIEW_AND_DATE',
}

export enum EventItemType {
  ORDER = 'ORDER',
  BOOKING = 'BOOKING',
  CUSTOM = 'CUSTOM',
}

export type EventItemModel = OrderModel | InstallerBookingModel | CalendarEventModel;

export type CalendarEvent = {
  id: string;
  title: string;
  start: Date;
  end?: Date;
  allDay?: boolean;
  editable?: boolean;
  description?: string;
  extendedProps: {
    type: EventItemType;
    item: EventItemModel;
  };
};

export enum ViewMode {
  MONTH = 'dayGridMonth',
  WEEK = 'dayGridWeek',
  DAY = 'dayGridDay',
}

export interface CalendarState {
  schedule: CalendarEvent[];
  date: Date;
  view: ViewMode;
  sources: string[];
}

export default function productionCalendarReducer(
  state: CalendarState,
  action,
): CalendarState {
  const { type, payload } = action;

  switch (type as CalendarActions) {
    case CalendarActions.SET_SCHEDULE:
      return {
        ...state,
        schedule: payload,
      };
    case CalendarActions.SET_DATE:
      return { ...state, date: payload };
    case CalendarActions.SET_VIEW_MODE:
      return { ...state, view: payload };
    case CalendarActions.SET_SOURCES:
      return { ...state, sources: payload };
    case CalendarActions.SWITCH_VIEW_AND_DATE:
      return { ...state, view: payload.view, date: payload.date };
    default:
      return state;
  }
}
