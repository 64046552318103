import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import classnames from 'classnames';
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import RenderIf from 'components/base-components/RenderIf';
import './styles.scss';

interface Props extends ButtonProps {
  label?: string;
  icon?: ReactNode | string;
  mR?: boolean;
  mB?: boolean;
  mT?: boolean;
  mL?: boolean;

}

const Button: FunctionComponent<Props> = (props) => {
  const {
    icon: iconProp,
    label,
    children,
    className,
    mR,
    mB,
    mT,
    mL,
    ...rest
  } = props;
  const buttonClassName = useMemo(
    () => classnames('button', { mR, mB, mT, mL }, className),
    [className, mR, mB, mT, mL],
  );

  const icon = useMemo(
    () => (
      typeof iconProp === 'string'
        ? <i className={iconProp} />
        : iconProp
    ),
    [iconProp],
  );

  const content = useMemo(() => {
    if (children) {
      return children;
    }
    const labelClassName = classnames({ button__label: !!icon });
    return (
      <>
        {icon}
        <RenderIf isTrue={!!label}>
          <span className={labelClassName}>{label}</span>
        </RenderIf>
      </>
    );
  }, [icon, label, children]);

  return (
    <AntdButton className={buttonClassName} {...rest}>
      {content}
    </AntdButton>
  );
};

Button.defaultProps = {
  type: 'primary',
};

export default Button;
