import React, { FunctionComponent } from 'react';
import Button from 'components/base-components/Button';
import DropdownButton from 'components/base-components/DropdownButton';
import Menu, { MenuItem } from 'components/base-components/Menu';
import Tooltip from 'components/base-components/Tooltip';
import Badge from 'components/base-components/Badge';

interface Props {
  actions: any[];
}

const DataTableActions: FunctionComponent<Props> = ({ actions }): any => {
  if (actions.length <= 3) {
    return actions.map(({ key, label, icon, badge, ...rest }) => {
      return (
        <Badge key={key} count={badge}>
          <Tooltip title={label}>
            <Button
              icon={icon}
              label={icon ? undefined : label}
              {...rest}
            />
          </Tooltip>
        </Badge>
      );
    });
  }

  const [
    { key: firstActionKey, badge: firstActionBadge, label: firstActionLabel, ...firstActionRestProps },
    {
      key: secondActionKey,
      icon: secondActionIcon,
      badge: secondActionBadge,
      label: secondActionLabel,
      ...secondActionRestProps
    },
    ...restActions
  ] = actions;

  const menuItems = restActions.map(({ key, ...rest }) => (
    <MenuItem key={key} {...rest} />
  ));

  const menu = <Menu>{menuItems}</Menu>;

  const secondActionIconRender = typeof secondActionIcon === 'string'
    ? <i className={secondActionIcon} />
    : secondActionIcon;

  const buttonsRender = ([leftButton, rightButton]) => [
    <Badge
      count={secondActionBadge}
      key={secondActionKey}
    >
      <Tooltip title={secondActionLabel}>
        {leftButton}
      </Tooltip>
    </Badge>,
    rightButton,
  ];

  return (
    <>
      <Badge
        count={firstActionBadge}
        key={firstActionKey}
      >
        <Tooltip title={firstActionLabel}>
          <Button
            data-el="table-first-action"
            label={undefined}
            {...firstActionRestProps}
          />
        </Tooltip>
      </Badge>
      <DropdownButton
        key={secondActionKey}
        trigger={['hover', 'click']}
        {...secondActionRestProps}
        overlay={menu}
        buttonsRender={buttonsRender}
      >
        {secondActionIconRender}
      </DropdownButton>
    </>
  );
};

export default DataTableActions;
