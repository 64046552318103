import React, { FunctionComponent } from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntdTooltip {...rest}>
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
