import React, { createContext, FunctionComponent, useContext } from 'react';
import { Schedule } from './state/reducer';

interface Props {
  schedule: Schedule;
}

const Context = createContext<Schedule>(undefined);

const ScheduleProvider: FunctionComponent<Props> = (props) => {
  const { schedule, children } = props;

  return (
    <Context.Provider value={schedule}>
      {children}
    </Context.Provider>
  );
};

export const useSchedule = () => useContext(Context);

export default ScheduleProvider;
