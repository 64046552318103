import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import notification, { NotificationType } from 'helpers/notification';
import useGetList from 'hooks/base/get-list';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import { ApiModel, ListResponseModel } from 'api/base';
import { AxiosResponse } from 'axios';
import { handleDeleteRow, handleFilter, handleOnChange, handleResetState, handleReplace } from './actions';
import tableReducer from './reducer';
import { DeleteRowParams } from './actions/handle-delete-row';

export interface UseTableStateParams {
  api: ApiModel;
  overrideListFunction?: ((params: any) => Promise<AxiosResponse<ListResponseModel>>);
  searchFields?: string[];
  deleteModalTitle?: string;
  deleteModalContent?: (entryToDelete) => string;
  eventChannel?: EventChannelList;
  queryParams?: { [x: string]: any };
  queryId?: string;
  defaultSortField?: string;
  preventAutoFetch?: boolean;
  pageSize?: number;
  idField?: string;
  reference?: string;
}

export function useQuery(reference?: string) {
  const { replace, location: { search } } = useHistory();
  const searchQuery = useMemo(() => new URLSearchParams(search), [search]);
  const setPage = useMemo(() => reference ? parseInt(searchQuery.get(`${reference}_page`), 10) : undefined, [searchQuery, reference]);
  const setFilter = useMemo(() => reference ? searchQuery.get(`${reference}_filter`) : undefined, [searchQuery, reference]);
  return {
    state: {
      setPage,
      setFilter
    },
    action: {
      replace: useCallback(
        handleReplace(reference, setPage, setFilter, replace), [reference, setPage, setFilter, replace]
      )
    }
  };
}

export default function useTableState<T>(params: UseTableStateParams) {
  const {
    api,
    overrideListFunction,
    searchFields,
    deleteModalContent,
    deleteModalTitle,
    eventChannel,
    queryParams,
    queryId,
    defaultSortField,
    preventAutoFetch,
    pageSize,
    idField,
    reference
  } = params;

  const {
    state: { setFilter, setPage },
    action: { replace }
  } = useQuery(reference);

  const [state, dispatch] = useReducer(
    tableReducer,
    {
      page: setPage || 1,
      filter: setFilter || undefined,
      columnFilter: undefined,
      sortField: defaultSortField,
      entryToDelete: undefined,
    }
  );

  const { page, filter, sortField, columnFilter } = state;

  const listParams = useMemo(() => {
    return {
      page,
      filters: {
        ...queryParams,
        ...columnFilter,
        ordering: sortField,
        search: filter,
        search_fields: filter ? searchFields : undefined,
      },
      queryId,
      pageSize
    };
  }, [page, sortField, filter, searchFields, queryParams, queryId, pageSize, columnFilter]);

  const {
    fetching,
    count,
    data,
    error,
    fetchList,
    updateDataManually,
  } = useGetList<T>(overrideListFunction || api, { queryParams: listParams, preventAutoFetch });

  const updateList = useCallback(() => {
    const updateParams = {
      page,
      filters: {
        ...queryParams,
        ...columnFilter,
        ordering: sortField,
        search: filter,
        search_fields: filter ? searchFields : undefined,
      },
      pageSize
    };
    fetchList(updateParams);
  }, [filter, sortField, searchFields, page, fetchList, queryParams, pageSize, columnFilter]);

  useEventCenterUpdate(eventChannel, updateList);

  useEffect(() => {
    if (error) {
      notification({ type: NotificationType.ERROR, message: error });
    }
  }, [error]);

  useEffect(() => {
    replace({
      page,
      filter: filter || 'noneFilter'
    });
  }, [page, filter, replace]);

  const deleteRowParams: DeleteRowParams = {
    title: deleteModalTitle,
    eventChannel,
    dispatch,
    content: deleteModalContent,
    api,
  };

  return {
    state: {
      ...state,
      fetching,
      data,
      count,
      defaultFilter: setFilter,
    },
    actions: {
      handleFilter: handleFilter(dispatch),
      handleDeleteRow: handleDeleteRow(deleteRowParams, idField),
      handleOnChange: handleOnChange(dispatch),
      updateDataManually,
      resetState: handleResetState(dispatch, defaultSortField),
    },
  };
}
