export enum BuilderPropertyType {
  PART_TYPE = 'product_range',
  STYLE = 'style',
  SELECT = 'select',
  RANGE = 'range',
  SELECT_REFRESH = 'select-refresh',
}

export interface BuilderPartTypeProperty {
  propertyType: BuilderPropertyType.PART_TYPE;
  partType: {
    url: string;
    name: string;
    image: string;
  }[];
}

export interface BuilderStyleProperty {
  propertyType: BuilderPropertyType.STYLE;
  styles: {
    url: string;
    name: string;
    image?: string;
  }[];
}

export interface BuilderSelectProperty {
  propertyType: BuilderPropertyType.SELECT;
  nameInModel: string;
  options: { name: string; nameInModel: string }[];
}

export interface BuilderSelectRefreshProperty {
  propertyType: BuilderPropertyType.SELECT_REFRESH;
  nameInModel: string;
  options: { name: string; nameInModel: string }[];
}

export interface BuilderRangeProperty {
  propertyType: BuilderPropertyType.RANGE;
  minValue: number;
  maxValue: number;
  increment: number;
  morphIndex: number;
  morphLabel: string;
}

export interface BuilderOptionGroup {
  name: string;
  nameInModel: string;
  defaultOption: string;
  mode: 'texture' | 'hide';
  options: BuilderOption[];
}

export interface BuilderOption {
  name: string;
  code: string;
  image?: string;
  textureHeight?: number;
  textureWidth?: number;
  nameInModel?: string;
}

export interface BuilderModel {
  url: string;
  model: string;
  category: string;
  threeModel: string;
  position: {
    index: number;
    description: string;
  };
  modelDescription: { [property: string]: string };
  builderOptions: {
    [property: string]: (
      BuilderPartTypeProperty |
      BuilderStyleProperty |
      BuilderSelectProperty |
      BuilderRangeProperty
    );
  };
  optionGroups: BuilderOptionGroup[];
  stackable: boolean;
  stackontop: boolean;
  overlapable: boolean;
}

export interface BuilderCategory{
  url: string;
  name: string;
  parent: {
    url: string;
    name: string;
  };
  nameFr: string;
  description: string;
  descriptionFr: string;
  createdAt: string;
  updatedAt: string;
  levelDescription: string;
  modelName: string;
  image: string;
  lft: number;
  rght: number;
  treeId: number;
  level: number;
  threeModel: string;
  optionGrp: string[];
  categories: {
    url: string;
    name: string;
    image?: string;
  }[];
}

export enum Units{
  inches = 'in',
  feets = 'ft',
  centimeters = 'cm'
}

export type BuilderProperties = (
  BuilderPartTypeProperty |
  BuilderStyleProperty |
  BuilderSelectProperty |
  BuilderRangeProperty |
  BuilderSelectRefreshProperty
  // eslint-disable-next-line @typescript-eslint/indent
);
