import { AccountModel } from 'models/account';

export function getPayload(account: AccountModel, jobTitle) {
  return {
    jobTitle,
    account: account.businessTradeName,
    client: account.ownerDetail.cognitoId,
  };
}

export function getQuickAccountPayload(quickAccountData) {
  const { companyName, paymentTerms, ...rest } = quickAccountData;
  return {
    ...rest,
    termName: paymentTerms?.value,
    companyName: companyName.trim()
  };
}
