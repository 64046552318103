import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { UserGroupModel } from 'models/user-group';
import userGroupApi from 'api/user-group';
import { EventChannelList } from 'helpers/event-center';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import Button from 'components/base-components/Button';
import Help from 'components/forms/CommonHelp';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import AccessControl from 'components/base-components/AccessControl';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import { UserGroupFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

function getDeleteModalContent(groupToDelete: UserGroupModel) {
  return `Are you sure that you want to delete the group ${groupToDelete ? groupToDelete.name : ''}?`;
}

const useTableStateParams: UseTableStateParams = {
  api: userGroupApi,
  deleteModalTitle: 'Delete Group',
  deleteModalContent: getDeleteModalContent,
  searchFields: ['name'],
  eventChannel: EventChannelList.USERS_GROUP_LIST_CHANGED,
};

const UsersGroupList: FunctionComponent = () => {
  const { push } = useHistory();

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
    }
  } = useTableState<UserGroupModel>(useTableStateParams);

  const openUserGroupFormModal = (userGroup?: UserGroupModel | any) => {
    const historyLocationState: UserGroupFormHistorySateModel = {
      userGroupForm: {
        userGroupUrl: userGroup ? userGroup.url : undefined,
      },
    };
    push(ModalRouteHash.UserGroupForm, historyLocationState);
  };

  return (
    <Page title="Users Groups">
      <Help view="user-group" title="Users Groups List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addGroup}>
          <Button
            type="primary"
            onClick={openUserGroupFormModal}
            label="Add User Group"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        rowKey="id"
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
      >
        <DataTableColumn dataIndex="name" columnKey="name" title="Name" sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editGroup}
          deletePermissions={permissions.deleteGroup}
          onEdit={openUserGroupFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default UsersGroupList;
