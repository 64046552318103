/* eslint-disable */
import * as THREE from "three";
import Core from "../core";
import SetItem from "../items/set_item";

export var Controller = function (
  three,
  model,
  camera,
  element,
  controls,
  hud
) {
  const scope = this;

  this.enabled = true;

  // var three = three;
  // var model = model;
  const { scene } = model;
  // var element = element;
  // var camera = camera;
  // var controls = controls;
  // var hud = hud;

  let plane; // ground plane used for intersection testing

  let mouse;
  let intersectedObject;
  let mouseoverObject;
  let selectedObject;

  let mouseDown = false;
  let mouseMoved = false; // has mouse moved since down click

  let rotateMouseOver = false;

  const states = {
    UNSELECTED: 0, // no object selected
    SELECTED: 1, // selected but inactive
    DRAGGING: 2, // performing an action while mouse depressed
    ROTATING: 3, // rotating with mouse down
    ROTATING_FREE: 4, // rotating with mouse up
    PANNING: 5,
  };
  let state = states.UNSELECTED;

  this.needsUpdate = true;

  function init() {
    element.addEventListener("mousedown", mouseDownEvent);
    element.addEventListener("mouseup", mouseUpEvent);
    element.addEventListener("mousemove", mouseMoveEvent);

    mouse = new THREE.Vector2();

    scene.itemRemovedCallbacks.push(itemRemoved);
    scene.itemLoadedCallbacks.push(itemLoaded);
    setGroundPlane();
  }

  // invoked via callback when item is loaded
  function itemLoaded(item) {
    if (!item.position_set) {
      scope.setSelectedObject(item);
      switchState(states.DRAGGING);
      const pos = item.position.clone();
      pos.y = 0;
      const vec = three.projectVector(pos);
      clickPressed(vec);
      if (item.dimensionHelper) {
        item.dimensionHelper.position.copy(item.position);
      }
    }
    item.position_set = true;
  }

  function clickPressed(vec2) {
    vec2 = vec2 || mouse;
    const intersection = scope.itemIntersection(mouse, selectedObject);
    if (intersection) {
      selectedObject.clickPressed(intersection);
    }
  }

  function clickDragged(vec2) {
    vec2 = vec2 || mouse;
    const intersection = scope.itemIntersection(mouse, selectedObject);
    if (intersection) {
      if (scope.isRotating()) {
        !Boolean(selectedObject.fixed) && selectedObject.rotate(intersection);
      } else {
        selectedObject.clickDragged(intersection);
      }
    }
  }

  function itemRemoved(item) {
    // invoked as a callback to event in Scene
    if (item === selectedObject) {
      selectedObject &&
        (selectedObject.setUnselected() || selectedObject.clearLinkedItems());
      selectedObject.mouseOff();
      scope.setSelectedObject(null);
    }
  }

  function setGroundPlane() {
    // ground plane used to find intersections
    const size = 10000;
    plane = new THREE.Mesh(
      new THREE.PlaneGeometry(size, size),
      new THREE.MeshBasicMaterial()
    );
    plane.rotation.x = -Math.PI / 2;
    plane.visible = false;
    scene.add(plane);
  }

  function checkWallsAndFloors(event) {
    // if (event.buttons !== 2) return;

    // double click on a wall or floor brings up texture change modal
    if (state === states.UNSELECTED && !mouseoverObject) {
      // check walls
      const wallEdgePlanes = model.floorplan.wallEdgePlanes();
      const wallIntersects = scope.getIntersections(
        mouse,
        wallEdgePlanes,
        true
      );
      if (wallIntersects.length > 0) {
        const wall = wallIntersects[0].object.edge;
        // three.wallClicked.fire(wall);
        three.wallClicked.forEach(
          (cb) =>
            typeof cb === "function" && cb(wall, event.offsetX, event.offsetY)
        );
        return;
      }

      // check floors
      const floorPlanes = model.floorplan.floorPlanes();
      const floorIntersects = scope.getIntersections(mouse, floorPlanes, false);
      if (floorIntersects.length > 0) {
        const { room } = floorIntersects[0].object;
        // three.floorClicked.fire(room);
        three.floorClicked.forEach(
          (cb) =>
            typeof cb === "function" && cb(room, event.offsetX, event.offsetY)
        );
        return;
      }

      // three.nothingClicked.fire();
      three.nothingClicked.forEach((cb) => typeof cb === "function" && cb());
    }
  }

  function mouseMoveEvent(event) {
    if (Core.Configuration.getBooleanValue(Core.configSceneLocked)) return;
    if (scope.enabled) {
      event.preventDefault();

      mouseMoved = true;

      mouse.x = event.offsetX;
      mouse.y = event.offsetY;

      if (!mouseDown) {
        updateIntersections();
      }

      switch (state) {
        case states.UNSELECTED:
          updateMouseover();
          break;
        case states.SELECTED:
          updateMouseover();
          break;
        case states.DRAGGING:
        case states.ROTATING:
        case states.ROTATING_FREE:
          clickDragged();
          hud.update();
          scope.needsUpdate = true;
          break;
        default:
          break;
      }
    }
  }

  this.isRotating = function () {
    return state === states.ROTATING || state === states.ROTATING_FREE;
  };

  function mouseDownEvent(event) {
    // three.wallClicked.forEach(cb => typeof cb === 'function' && cb());
    // three.floorClicked.forEach(cb => typeof cb === 'function' && cb());
    if (Core.Configuration.getBooleanValue(Core.configSceneLocked)) return;
    if (scope.enabled) {
      event.preventDefault();

      mouseMoved = false;
      mouseDown = true;

      switch (state) {
        case states.SELECTED:
          if (rotateMouseOver) {
            switchState(states.ROTATING);
          } else if (intersectedObject != null) {
            scope.setSelectedObject(intersectedObject, event.ctrlKey);
            if (!intersectedObject.fixed) {
              switchState(states.DRAGGING);
            }
          } else if (intersectedObject === null && !mouseMoved) {
            switchState(states.UNSELECTED);
            checkWallsAndFloors(event);
          }
          break;
        case states.UNSELECTED:
          if (intersectedObject != null) {
            scope.setSelectedObject(intersectedObject, event.ctrlKey);
            if (!intersectedObject.fixed) {
              switchState(states.DRAGGING);
            }
          }
          if (!mouseMoved) {
            checkWallsAndFloors(event);
          }
          break;
        case states.DRAGGING:
        case states.ROTATING:
          break;
        case states.ROTATING_FREE:
          switchState(states.SELECTED);
          break;
        default:
          break;
      }
    }
  }

  function mouseUpEvent(event) {
    if (Core.Configuration.getBooleanValue(Core.configSceneLocked)) return;
    if (scope.enabled) {
      mouseDown = false;

      switch (state) {
        case states.DRAGGING:
          selectedObject.clickReleased();
          switchState(states.SELECTED);
          break;
        case states.ROTATING:
          if (!mouseMoved) {
            switchState(states.ROTATING_FREE);
          } else {
            switchState(states.SELECTED);
          }
          break;
        case states.UNSELECTED:
        // if (!mouseMoved) {
        //   checkWallsAndFloors(event);
        // }
        // break;
        case states.SELECTED:
          // if (intersectedObject === null && !mouseMoved) {
          //   switchState(states.UNSELECTED);
          //   checkWallsAndFloors(event);
          // }
          break;
        case states.ROTATING_FREE:
          break;
        default:
          break;
      }
    }
  }

  function switchState(newState) {
    if (newState !== state) {
      onExit(state);
      onEntry(newState);
    }
    state = newState;
    hud.setRotating(scope.isRotating());
  }

  function onEntry(state) {
    switch (state) {
      case states.UNSELECTED:
        scope.setSelectedObject(null);
        break;
      case states.SELECTED:
        controls.enabled = true;
        break;
      case states.ROTATING:
      case states.ROTATING_FREE:
        controls.enabled = false;
        break;
      case states.DRAGGING:
        three.setCursorStyle("move");
        clickPressed();
        controls.enabled = false;
        break;
      default:
        break;
    }
  }

  function onExit(state) {
    switch (state) {
      case states.UNSELECTED:
      case states.SELECTED:
        break;
      case states.DRAGGING:
        if (mouseoverObject) {
          three.setCursorStyle("pointer");
        } else {
          three.setCursorStyle("auto");
        }
        break;
      case states.ROTATING:
      case states.ROTATING_FREE:
        break;
      default:
        break;
    }
  }

  this.selectedObject = function () {
    return selectedObject;
  };

  // updates the vector of the intersection with the plane of a given
  // mouse position, and the intersected object
  // both may be set to null if no intersection found
  function updateIntersections() {
    // check the rotate arrow
    const hudObject = hud.getObject();
    if (hudObject != null) {
      const hudIntersects = scope.getIntersections(
        mouse,
        hudObject,
        false,
        false,
        true
      );
      if (hudIntersects.length > 0) {
        rotateMouseOver = true;
        hud.setMouseover(true);
        intersectedObject = null;
        return;
      }
    }
    rotateMouseOver = false;
    hud.setMouseover(false);

    // check objects
    const items = model.scene.getItems();
    const intersects = scope.getIntersections(mouse, items, false, true);

    if (intersects.length > 0) {
      intersectedObject = intersects[0].object;
    } else {
      intersectedObject = null;
    }
  }

  // sets coords to -1 to 1
  function normalizeVector2(vec2) {
    const retVec = new THREE.Vector2();
    const rect = element.getBoundingClientRect();
    retVec.x = (vec2.x / rect.width) * 2 - 1;
    retVec.y = -(vec2.y / rect.height) * 2 + 1;
    return retVec;
  }

  //
  function mouseToVec3(vec2) {
    const normVec2 = normalizeVector2(vec2);
    const vector = new THREE.Vector3(normVec2.x, normVec2.y, 0.5);
    vector.unproject(camera);
    return vector;
  }

  // returns the first intersection object
  this.itemIntersection = function (vec2, item) {
    const customIntersections = item.customIntersectionPlanes();
    let intersections = null;
    if (customIntersections && customIntersections.length > 0) {
      intersections = this.getIntersections(vec2, customIntersections, true);
    } else {
      intersections = this.getIntersections(vec2, plane);
    }
    if (intersections.length > 0) {
      return intersections[0];
    }
    return null;
  };

  // filter by normals will only return objects facing the camera
  // objects can be an array of objects or a single object
  this.getIntersections = function (
    vec2,
    objects,
    filterByNormals,
    onlyVisible,
    recursive,
    linePrecision
  ) {
    // console.log('get intersection', objects);
    const vector = mouseToVec3(vec2);
    onlyVisible = onlyVisible || false;
    filterByNormals = filterByNormals || false;
    recursive = recursive || false;
    linePrecision = linePrecision || 0.2;

    const direction = vector.sub(camera.position).normalize();
    const raycaster = new THREE.Raycaster(camera.position, direction);
    // raycaster.linePrecision = linePrecision;
    raycaster.params.Line.threshold = linePrecision;

    // scene.add(new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 1000, 0xff0000));
    let intersections;
    if (Array.isArray(objects)) {
      const meshes = [];
      objects.forEach((obj) => obj.traverse((m) => m.isMesh && meshes.push(m)));
      intersections = raycaster.intersectObjects(meshes, recursive);
      intersections.forEach((item) => {
        if (item.object.isMesh && item.object.parent) {
          if (item.object.parent instanceof THREE.Scene) return;
          item.object.parent && (item.object = item.object.parent);

          if (
            item.object.groupParent instanceof SetItem &&
            item.object.groupParent.opened === false
          ) {
            item.object = item.object.groupParent;
          }
        }
      });
    } else {
      intersections = raycaster.intersectObject(objects, recursive);
    }
    // filter by visible, if true
    if (onlyVisible) {
      intersections = Core.Utils.removeIf(intersections, (intersection) => {
        return !intersection.object.visible;
      });
    }

    // filter by normals, if true
    if (filterByNormals) {
      intersections = Core.Utils.removeIf(intersections, (intersection) => {
        const dot = intersection.face.normal.dot(direction);
        return dot > 0;
      });
    }
    return intersections;
  };

  // manage the selected object
  this.setSelectedObject = function (object, ctrlKey = false) {
    if (state === states.UNSELECTED) {
      switchState(states.SELECTED);
    }
    if (object != null) {
      if (object === selectedObject) return;
      if (ctrlKey && selectedObject) {
        selectedObject.addLinkedItem(object);
      } else {
        selectedObject &&
          (selectedObject.setUnselected() || selectedObject.clearLinkedItems());

        selectedObject = object;
        selectedObject.setSelected();
        // three.itemSelectedCallbacks.fire(object);
        three.itemSelectedCallbacks.forEach(
          (cb) => typeof cb === "function" && cb(object)
        );
      }
    } else {
      selectedObject &&
        (selectedObject.setUnselected() || selectedObject.clearLinkedItems());

      selectedObject = null;
      // three.itemUnselectedCallbacks.fire();
      three.itemUnselectedCallbacks.forEach(
        (cb) => typeof cb === "function" && cb()
      );
    }
    this.needsUpdate = true;
  };

  // TODO: there MUST be simpler logic for expressing this
  function updateMouseover() {
    if (intersectedObject != null) {
      if (mouseoverObject != null) {
        if (mouseoverObject !== intersectedObject) {
          mouseoverObject.mouseOff();
          mouseoverObject = intersectedObject;
          mouseoverObject.mouseOver();
          scope.needsUpdate = true;
        } else {
          // do nothing, mouseover already set
        }
      } else {
        mouseoverObject = intersectedObject;
        mouseoverObject.mouseOver();
        three.setCursorStyle("pointer");
        scope.needsUpdate = true;
      }
    } else if (mouseoverObject != null) {
      mouseoverObject.mouseOff();
      three.setCursorStyle("auto");
      mouseoverObject = null;
      scope.needsUpdate = true;
    }
  }

  init();
};
