import { AxiosResponse } from 'axios';
import { ApiModel, post } from 'api/base';
import getApi from 'api/base/get-api';
import { UserGroupModel } from 'models/user-group';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/groups/`;

interface UserGroupApiModel extends ApiModel<UserGroupModel> {
  updatePermissions(payload): Promise<AxiosResponse>;
}

const userGroupApi: UserGroupApiModel = {
  ...getApi<UserGroupModel>(endpoint),
  updatePermissions(payload) {
    return post(`${endpoint}update-permissions/`, payload);
  }
};

export default userGroupApi;
