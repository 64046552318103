import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TaskTemplate } from 'models/task-template';
import taskTemplatesApi from 'api/task-template';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import useCallApiAction from 'hooks/base/call-api-action';
import { EventChannelList } from 'helpers/event-center';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { TaskTemplateFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: taskTemplatesApi,
  deleteModalTitle: 'Delete Task Template',
  deleteModalContent: (taskToDelete: TaskTemplate) => `Are you sure that you want to delete the Task Template ${taskToDelete ? taskToDelete.details : ''}?`,
  searchFields: ['details'],
  queryParams: {
    onetime: false,
  },
  eventChannel: EventChannelList.TASK_TEMPLATE_CHANGED,
  defaultSortField: '-id'
};

const TaskTemplateList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<TaskTemplate>(useTableStateParams);

  const {
    data: options,
    fetching: fetchingOptions
  } = useCallApiAction(taskTemplatesApi.getConditionalChoices, undefined, undefined as any);

  const conditionalOptions = useMemo(() => !!options && !fetchingOptions
    ? options.actions.POST.conditional.choices : [],
  [options, fetchingOptions]);

  const conditionalRender = useCallback((conditional: string) => conditionalOptions
    .find(e => e.value === conditional)?.display_name,
  [conditionalOptions]);

  const openTaskTemplateFormModal = (task?: TaskTemplate | any) => {
    const historyLocationState: TaskTemplateFormHistorySateModel = {
      taskTemplateForm: {
        taskTemplateId: task ? task.id : undefined,
        conditionalOptions,
      },
    };
    push(ModalRouteHash.TaskTemplateForm, historyLocationState);
  };

  return (
    <Page title="Tasks Template">
      <PageActions primary>
        <AccessControl permissions={permissions.addTaskTemplate}>
          <Button
            onClick={openTaskTemplateFormModal}
            label="Add Task Template"
            disabled={fetchingOptions}
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching || fetchingOptions}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="priority" columnKey="priority" title="Priority" sorter />
        <DataTableColumn dataIndex="details" columnKey="details" title="details" sorter />
        <DataTableColumn dataIndex={['taskName', 'name']} columnKey="task_name" title="Name" sorter />
        <DataTableColumn dataIndex={['startingStage', 'status']} columnKey="starting_stage" title="Starting" sorter />
        <DataTableColumn dataIndex={['endingStage', 'status']} columnKey="ending_stage" title="Ending" sorter />
        <DataTableColumn dataIndex="conditional" columnKey="conditional" render={conditionalRender} title="Conditional" sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editTaskTemplate}
          deletePermissions={permissions.deleteTaskTemplate}
          onEdit={openTaskTemplateFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default TaskTemplateList;
