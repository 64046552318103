import React, { FunctionComponent } from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import { TextProps } from 'antd/lib/typography/Text';
import { LinkProps } from 'antd/lib/typography/Link';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';

export const Title: FunctionComponent<TitleProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Typography.Title {...rest}>
      {children}
    </Typography.Title>
  );
};

export const Text: FunctionComponent<TextProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Typography.Text {...rest}>
      {children}
    </Typography.Text>
  );
};

export const Link: FunctionComponent<LinkProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Typography.Link {...rest}>
      {children}
    </Typography.Link>
  );
};

export const Paragraph: FunctionComponent<ParagraphProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Typography.Paragraph {...rest}>
      {children}
    </Typography.Paragraph>
  );
};
