import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import createProjectFormReducer from './reducer';
import { createProject, setFormValue, setValidationErrors } from './actions';

export interface CreateProjectHistorySateModel {
  createProjectForm: {
    client: string;
    account: string;
  };
}

const initialState = {
  formData: {
    jobTitle: '',
  },
  errors: {},
  creating: false,
};

export default function useCreateProjectState(historyState) {
  const { goBack } = useHistory();
  const { createProjectForm: { client, account } } = historyState as CreateProjectHistorySateModel;

  const [state, dispatch] = useReducer(
    createProjectFormReducer,
    initialState,
  );
  const { formData } = state;

  return {
    state: {
      ...state
    },
    actions: {
      handleValueChange: useCallback(setFormValue(dispatch), []),
      setValidationErrors: useCallback(setValidationErrors(dispatch), []),
      handleOnSubmit: useCallback(
        createProject(dispatch, formData, client, account, goBack),
        [formData, client, account],
      ),
    },
  };
}
