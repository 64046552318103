import cutListApi from 'api/cut-list';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function uploadCutSheet(cutListId, setExelValue, setRowToUpLoad) {
  return (event) => {
    const onSuccess = () => {
      notification({ type: NotificationType.ERROR, message: 'File was uploaded successfully' });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      setExelValue('');
      setRowToUpLoad(undefined);
    };

    let newFiles: File[] = [];
    const { files } = event.target;
    if (files.length > 0 && cutListId) {
      newFiles = [files[0]];
      return cutListApi.upload(cutListId, newFiles)
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    }
    return null;
  };
}
