import questionnaireApi from 'api/questionnaire';
import { ShipmentContactModel } from 'models/shipment';

export default async function saveContactInfo(token, contactInfo: ShipmentContactModel) {
  const action = contactInfo.id
    ? questionnaireApi.updateContact
    : questionnaireApi.storeContact;

  const response = await action(token, contactInfo);
  return response ? response.data.id : contactInfo.id;
}
