import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum SaveDesignFormActions {
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE',
  SET_ERRORS = 'SET_ERRORS',
}

interface SaveDesignFormModel {
  account: DropdownOptionModel;
  client: DropdownOptionModel;
  projectName: string;
}

export interface SaveDesignFormState {
  flowPlanner: SaveDesignFormModel ;
  errors: any;
}

export default function saveDesignReducer(state: SaveDesignFormState, action) {
  const { type, payload } = action;

  switch (type as SaveDesignFormActions) {
    case SaveDesignFormActions.SET_FORM_VALUE:
      return { ...state, flowPlanner: payload };
    case SaveDesignFormActions.UPDATE_FORM_VALUE:
      return { ...state, flowPlanner: { ...state.flowPlanner, ...payload } };
    case SaveDesignFormActions.SET_ERRORS:
      return { ...state, errors: payload, savingProject: false };
    default:
      return state;
  }
}
