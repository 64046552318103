import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import questionnaireApi, { ValidQuestionnaireResponse } from 'api/questionnaire';
import { validateEntity } from 'helpers/form-validations';
import { QuestionnaireActions, QuestionnaireState } from '../reducer';
import saveContactInfo from './save-contact-info';
import saveAddressInfo from './save-address-info';
import saveAnswersInfo from './save-answers-info';
import { addressRules, answersRules, contactRules } from '../validations';

export default function handleSend(
  dispatch,
  state: QuestionnaireState,
  shipmentInfo: ValidQuestionnaireResponse,
  notify?: boolean,
) {
  return async (event) => {
    event.preventDefault();
    dispatch({ type: QuestionnaireActions.START_SENDING_ANSWERS });

    const {
      editedContactInfo,
      contactInfo,
      editedAddressInfo,
      addressInfo,
      answers,
    } = state;
    const { shipment, token } = shipmentInfo;
    let hasValidationErrors = false;

    const {
      hasErrors: hasInvalidContactInfo,
      errors: contactErrors,
    } = validateEntity(contactInfo, contactRules);
    const {
      hasErrors: hasInvalidAddressInfo,
      errors: addressInfoErrors,
    } = validateEntity(addressInfo, addressRules);
    const {
      hasErrors: hasInvalidAnswers,
      errors: answersErrors,
    } = validateEntity(answers, answersRules);

    if (hasInvalidContactInfo) {
      dispatch({
        type: QuestionnaireActions.SET_CONTACT_ERRORS,
        payload: contactErrors,
      });
      hasValidationErrors = true;
    }

    if (hasInvalidAddressInfo) {
      dispatch({
        type: QuestionnaireActions.SET_ADDRESS_ERRORS,
        payload: addressInfoErrors,
      });
      hasValidationErrors = true;
    }

    if (hasInvalidAnswers) {
      dispatch({
        type: QuestionnaireActions.SET_ANSWER_ERRORS,
        payload: answersErrors,
      });
      hasValidationErrors = true;
    }

    if (!hasValidationErrors) {
      try {
        const {
          shipmentType,
          shippingZone,
          project,
          ...otherShipmentFields
        } = shipment;

        const contactId = editedContactInfo
          ? await saveContactInfo(token, contactInfo)
          : contactInfo.id;
        const addressId = editedAddressInfo
          ? await saveAddressInfo(token, addressInfo)
          : addressInfo.id;
        const answersId = await saveAnswersInfo(token, answers);

        const payload = {
          ...otherShipmentFields,
          project,
          shipmentType: shipmentType?.id,
          shippingZone: shippingZone?.id,
          contact: contactId,
          shippingAddress: addressId,
          shippingQuestionnaire: answersId,
        };

        await questionnaireApi.updateShipment(token, payload);
        dispatch({ type: QuestionnaireActions.SET_ANSWERS_SENT });

        if (notify && window.opener) {
          const message = {
            source: 'dq',
            payload: true,
          };
          window.opener.postMessage(message, '*');
          window.close();
        }
      } catch (error) {
        notification({
          type: NotificationType.ERROR,
          message: resolveApiErrorMessage((error as any).response),
        });
        dispatch({ type: QuestionnaireActions.STOP_SENDING_ANSWERS });
      }
    }
  };
}
