/* eslint-disable */
import * as THREE from 'three';
import Item from './item';

import {
  IgnorableMeshNames,
} from './utils';

import Core from '../core';

export default class SetItem extends Item {
  isSet = true;
  opened = false;
  // constructor(model, metadata, meshList, position, rotation, options) {
  //   super(model, metadata, meshList, position, rotation, options);
  // }

  /** */
  initObject = function (meshList, position, rotation, options) {
    // center in its bounding box
    this.halfSize = this.objectHalfSize();

    this.configDimensionLabels();

    // load stored configuration
    if (position) {
      this.position.copy(position);
      this.dimensionHelper.position.copy(position);
      this.position_set = true;
    } else {
      this.position.set(0, 0, 0);
      this.position_set = false;
    }

    if (rotation) {
      this.rotation.y = rotation;
    }

    if (options) {
      // // console.log(options);
      // options.hasOwnProperty('fixed') && (this.fixed = options.fixed);
      // options.hasOwnProperty('stackable') && (this.stackable = options.stackable);
      // options.hasOwnProperty('stackontop') && (this.stackontop = options.stackontop);
      // options.hasOwnProperty('overlappable') && (this.overlappable = options.overlappable);

      // // load morph
      // if (options.morph) {
      //   for (var i in options.morph) {
      //     this.setMorph(i, options.morph[i]);
      //   }
      // }
      // if (options.textures) {
      //   for (i in options.textures) this.updateMaterial(i, options.textures[i].material, options.textures[i].size);
      // }
      // if (options.styles) {
      //   for (i in options.styles) this.updateStyle(i, options.styles[i]);
      // }
    }
    this.placeInRoom();
    // select and stuff
    this.scene.needsUpdate = true;
  };

  placeInRoom() {
    // if (!this.position_set) {
    //   const center = this.model.floorplan.getCenter();
    //   const offset = new THREE.Vector3().copy(this.position).sub(new THREE.Vector3(center.x, 0, center.z));
    //   this.position.x = center.x;
    //   this.position.z = center.z;
    //   this.position.y = 0;
    //   if (this.metadata.defaultHeightFromFloor) {
    //     this.position.y = this.metadata.defaultHeightFromFloor * 2.54 / 100;
    //   }

    //   this.linkedItems.forEach(item => {
    //     console.log('child', item, offset)
    //     item.moveToPosition(new THREE.Vector3().copy(item.position).add(offset));
    //   });
    // }
  }

  clearLinkedItems = () => { }

  calculateHalfSize = () => {
    this.halfSize = this.objectHalfSize();
  }

  getBounding = () => {
    // const mesh = this;
    let minX = +Infinity;
    let minY = +Infinity;
    let minZ = +Infinity;

    let maxX = -Infinity;
    let maxY = -Infinity;
    let maxZ = -Infinity;

    this.linkedItems.forEach(item => {
      const bbox = item.getBounding();
      if (bbox.min.x < minX) minX = bbox.min.x;
      if (bbox.min.y < minY) minY = bbox.min.y;
      if (bbox.min.z < minZ) minZ = bbox.min.z;

      if (bbox.max.x > maxX) maxX = bbox.max.x;
      if (bbox.max.y > maxY) maxY = bbox.max.y;
      if (bbox.max.z > maxZ) maxZ = bbox.max.z;
    })

    return {
      min: new THREE.Vector3(minX, minY, minZ),
      max: new THREE.Vector3(maxX, maxY, maxZ),
    };
  }

  getCenterWithLinkedItems = () => {
    return this.position;
  }

  /** */
  moveToPosition(vec3, intersection) {
    if (isNaN(vec3.x) || isNaN(vec3.y) || isNaN(vec3.z)) {
      console.log('nan detected');
      return;
    }
    const temp = new THREE.Vector3().copy(this.position);
    this.position.copy(vec3);
    this.dimensionHelper.position.copy(vec3);

    const offset = new THREE.Vector3().copy(vec3).sub(temp);
    this.moveLinkedItems(offset);
  }

  update = () => {
    this.calculateHalfSize();
    this.dimensionHelper.update();
  }

  setOpened = opened => this.opened = opened;

  /** */
  setSelected() {
    this.selected = true;
    this.updateHighlight();
    this.linkedItems.forEach(item => item.setUnselected())
  }

  /** */
  setUnselected() {
    this.selected = false;
    this.linkedItems.forEach(item => item.setUnselected())
    this.updateHighlight();
  }

  updateHighlight() {
    const on = this.hover || this.selected;
    if (this.opened) return;
    const meshes = [];
    let childMeshes = [];
    this.linkedItems.forEach(item => childMeshes = [...childMeshes, ...item.childMeshes]);

    childMeshes.forEach(item => {
      let skippable = false;
      IgnorableMeshNames.forEach(str => {
        if (item.name.toLowerCase().includes(str)) skippable = true;
      })
      if (!skippable) meshes.push(item);
    })
    if (on) document.dispatchEvent(new CustomEvent(Core.BP3D_EVENT_HIGHLIGHT_CHANGED, {
      detail: {
        objects: meshes
      }
    }));
    else document.dispatchEvent(new CustomEvent(Core.BP3D_EVENT_HIGHLIGHT_CHANGED, {
      detail: {
        objects: []
      }
    }));
  }
}
