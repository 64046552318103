import { flatTreeSelectLabel } from 'helpers/dropdown-options';

export function getPayloadOption(orderId, option, optionType) {
  return {
    optionType,
    optionSelected: option.name,
    orderProduct: orderId,
    description: option.description || '',
    description_fr: option.description_fr || '',
    code: option.code,
    brand: option.brand || '',
    user_input: option.user_input || false,
    price: option.price,
    cost: option.cost,
  };
}

export function getOptions(row) {
  const preparedRow = {};
   row.options?.forEach(option => {
     const flatOptions = flatTreeSelectLabel(option);
     const complete = row.productOptions.find(object => object?.item?.name === flatOptions.child);
     preparedRow[flatOptions.parent] = complete?.item || { name: flatOptions.child };
   });
   return preparedRow;
}

export function getPayloadOrder(data) {
  const {
    product,
    manufacturer: manufacturerValueSelect,
    group: groupValueSelect,
    disabled: _disabled,
    options,
    productOptions,
    ...rest
  } = data;
  const cnc = product.category?.cnc || rest.cnc;
  const outsidePaint = product.category?.outsidePaint || rest.outsidePaint;
  const manufacturer = typeof manufacturerValueSelect === 'object' ? manufacturerValueSelect.label : manufacturerValueSelect;
  const group = typeof groupValueSelect === 'object' ? groupValueSelect.label : groupValueSelect;
  const payloadOrder = { ...product, ...rest, cnc, outsidePaint, manufacturer, group };
  return [payloadOrder, { ...payloadOrder, options, productOptions }];
}
