import { ProjectModel } from 'models/project';
import { QuoteModel } from 'models/quote';
import { quoteDropdownData } from 'helpers/dropdown-options';
import { QuoteViewActions } from '../reducer';

export default function handleNewProjectUpdate(dispatch) {
  return (entities: { project: ProjectModel; quote: QuoteModel }) => {
    const { project: { client, id, jobTitle }, quote } = entities;

    dispatch({
      type: QuoteViewActions.UPDATE_FILTERS,
      payload: {
        project: {
          key: id,
          value: id,
          label: jobTitle,
          client,
        },
        quote: quoteDropdownData(quote),
      },
    });
  };
}
