import snakeCaseKeys from 'snakecase-keys';
import { ApiContentType, ApiModel, barePost } from 'api/base';
import getApi from 'api/base/get-api';
import { OrderProductModel } from 'models/order';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/orderproducts/`;

const orderProductApi: ApiModel<OrderProductModel> =
  {
    ...getApi<OrderProductModel>(endpoint, ApiContentType.JSON, true),
    create(payload) {
      const body = snakeCaseKeys(payload, { deep: false });
      return barePost(endpoint, body);
    }
  };

export default orderProductApi;
