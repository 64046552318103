import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import hasPermissions from 'helpers/permissons';
import deficiencyOrderApi from 'api/deficiency-item';
import { ActiveDeficiencyItemModel } from 'models/order';
import DataTable from 'components/base-components/DataTableV2';
import Help from 'components/forms/CommonHelp';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Page, { PageActions } from 'components/base-components/Page';
import Switch from 'components/base-components/Switch';
import { permissions } from './permissions';

const DeficiencyOrdersList: FunctionComponent = () => {
  const { push } = useHistory();
  const [showAll, setShowAll] = useState<boolean>(false);
  const linkToOrder = useMemo(() => hasPermissions(['Sales-Orders', 'Dealer', 'Sales']) ? (cnc) => `quote/${cnc?.orderNum.split('-')[0]}` : undefined, []);

  const useTableStateParams: UseTableStateParams = useMemo(() => ({
    api: deficiencyOrderApi,
    overrideListFunction: deficiencyOrderApi.getActiveDeficiencies,
    queryParams: { all: showAll },
    defaultSortField: '-order_num',
  }), [showAll]);

  const handleShowAll = useCallback((checked) => { setShowAll(checked); }, []);

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleFilter,
      handleOnChange,
    }
  } = useTableState<ActiveDeficiencyItemModel>(useTableStateParams);

  const goToDetails = useCallback((deficiency: ActiveDeficiencyItemModel) => {
    push(`/deficiency-order/${deficiency.project}`, { quotePage: { orderNum: deficiency.orderNum } });
  }, [push]);

  return (
    <Page title="Deficiency Orders">
      <Help view="deficiency-orders" title="Deficiency Orders List" />
      <PageActions primary>
        <Switch
          checked={showAll}
          onChange={handleShowAll}
          label="Show All"
        />
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="project"
      >
        <DataTableColumn dataIndex="orderNum" columnKey="id" title="Order Number" link={linkToOrder} />
        <DataTableColumn dataIndex="companyName" columnKey="company_name" title="Company" />
        <DataTableColumn dataIndex="repOwnerName" title="Sales Person" />
        <DataTableColumn dataIndex="jobTitle" title="Job Title" />
        <DataTableColumn
          dataIndex="totalDeficiencies"
          title="Total Deficiencies"
          showBreakpoint={1076}
        />
        <DataTableColumn
          dataIndex="outstandingDeficiencies"
          title="Outstanding Deficiencies"
          showBreakpoint={1273}
        />
        <DataTableColumn isActionColumn onShowDetails={goToDetails} viewPermissions={permissions.viewDeficiencyItems} />
      </DataTable>
    </Page>
  );
};

export default DeficiencyOrdersList;
