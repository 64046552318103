import { commonTextures, handleStyles, doorStyles } from './__CommonOptions';

const data = {
  category: 'HUTCH',
  styles: [
    {
      name: 'HUTCH-DM-2HD',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-2HD.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-2HDC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-2HDC.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-2HDOL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-2HDOL.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-2HDOLC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-2HDOLC.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-2HDOR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-2HDOR.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-2HDORC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-2HDORC.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-2SO',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-2SO.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
      ],
      styles: [],
    },
    {
      name: 'HUTCH-DM-2SOC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-2SOC.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
      ],
      styles: [],
    },
    {
      name: 'HUTCH-DM-3HD',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-3HD.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-3HDC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-3HDC.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-3SO',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-3SO.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
      ],
      styles: [],
    },
    {
      name: 'HUTCH-DM-3SOC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-3SOC.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
      ],
      styles: [],
    },
    {
      name: 'HUTCH-DM-4HD',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-4HD.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-4HDC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-4HDC.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-DM-4SO',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-4SO.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
      ],
      styles: [],
    },
    {
      name: 'HUTCH-DM-4SOC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-DM-4SOC.glb',
      type: '1',
      stackable: true,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 24, max: 48 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
      ],
      styles: [],
    },
    {
      name: 'HUTCH-WM-2HD',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-2HD.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 14.375, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-2HDC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-2HDC.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 20.125, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-2HDOL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-2HDOL.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 14.375, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-2HDOLC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-2HDOLC.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 20.125, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-2HDOR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-2HDOR.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 14.375, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-2HDORC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-2HDORC.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 20.125, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-2SO',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-2SO.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 14.375, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
    },
    {
      name: 'HUTCH-WM-2SOC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-2SOC.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 20.125, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
    },
    {
      name: 'HUTCH-WM-3HD',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-3HD.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 14.375, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-3HDC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-3HDC.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 20.125, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-3SO',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-3SO.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 14.375, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
    },
    {
      name: 'HUTCH-WM-3SOC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-3SOC.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 20.125, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
    },
    {
      name: 'HUTCH-WM-4HD',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-4HD.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 14.375, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-4HDC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-4HDC.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 20.125, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Doors', name_in_model: 'doors', types: doorStyles },
        { label: 'Handle', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'HUTCH-WM-4SO',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-4SO.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 14.375, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
    },
    {
      name: 'HUTCH-WM-4SOC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/hutch-lwm-2hd.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/HUTCH-WM-4SOC.glb',
      type: '2',
      blockMorphIndex: 0,
      defaultHeightFromFloor: 30,
      morph: [
        { label: 'Height', index: 0, min: 20.125, max: 96 },
        { label: 'Width', index: 1, min: 36, max: 60 },
        { label: 'Depth', index: 2, min: 15, max: 24 },
      ],
      materials: [
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
      ],
    },
  ],
};

export default data;
