import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Moment } from 'moment';
import getEventValue from 'helpers/get-event-value';
import FlexBox from 'components/base-components/FlexBox';
import Button from 'components/base-components/Button';
import Select from 'components/base-components/Select';
import { RadioGroup } from 'components/base-components/RadioGroup';
import RadioButton from 'components/base-components/RadioButton';

interface Props {
  value: Moment;
  type: any;
  onChange: (value) => void;
  onTypeChange: (value) => void;
  openForm: (task?) => void;
}

const monthSelectStyles = { width: '80px' };

const Header: FunctionComponent<Props> = (props) => {
  const { value, type, onChange, onTypeChange, openForm } = props;

  const openCreateTask = useCallback(() => {
    openForm();
  }, [openForm]);

  const handleMonthChange = useCallback(event => {
    const selectedMonth = getEventValue(event);
    const newValue = value.clone();
    newValue.month(selectedMonth.value);
    onChange(newValue);
  }, [value, onChange]);

  const handleYearChange = useCallback(event => {
    const newYear = getEventValue(event);
    const now = value.clone().year(newYear.value);
    onChange(now);
  }, [value, onChange]);

  const handleTypeChange = useCallback(
    e => onTypeChange(e.target.value),
    [onTypeChange],
  );

  const month = value.month();
  const year = value.year();

  const monthValue = useMemo(() => {
    const current = value.clone();
    current.month(month);
    const localeData = value.localeData();
    return {
      value: month,
      label: localeData.monthsShort(current),
    };
  }, [value, month]);

  const yearValue = useMemo(() => ({
    value: year,
    label: year.toString(),
  }), [year]);

  const monthOptions = useMemo(() => {
    const current = value.clone();
    const localeData = value.localeData();
    return new Array(12)
      .fill('')
      .map((_m, index) => {
        current.month(index);
        const monthLabel = localeData.monthsShort(current);
        return { value: index, label: monthLabel };
      });
  }, [value]);

  const yearOptions = useMemo(() => (
    new Array(20).fill('')
      .map((_, index) => ({
        value: year - 10 + index,
        label: (year - 10 + index).toString(),
      }))
  ), [year]);

  return (
    <FlexBox align="flex-start" justify="space-between" mB>
      <Button label="Add Task" onClick={openCreateTask} />
      <FlexBox>
        <Select
          value={monthValue}
          options={monthOptions}
          onChange={handleMonthChange}
          style={monthSelectStyles}
        />
        <Select
          value={yearValue}
          options={yearOptions}
          onChange={handleYearChange}
          style={monthSelectStyles}
        />
        <RadioGroup value={type} onChange={handleTypeChange}>
          <FlexBox>
            <RadioButton value="month" label="Month" />
            <RadioButton value="year" label="Year" />
          </FlexBox>
        </RadioGroup>
      </FlexBox>
    </FlexBox>
  );
};

export default Header;
