import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router';
import { CategoryModel } from 'models/category';
import { resolveApiErrorMessage } from 'api/base';
import optionGroupsApi from 'api/option-group';
import categoriesApi from 'api/category';
import productApi from 'api/product';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { mapTreeSelectOptionSimple } from 'helpers/dropdown-options';
import useGetList from 'hooks/base/get-list';
import useCallApiAction from 'hooks/base/call-api-action';
import {
  addProduct,
  fetchCategories,
  mapProducts,
  dropdownRender,
  prepareOptionsGroups,
  setFormValue,
  setGroupValue,
  setOptionFormValue,
} from './actions';
import fetchCustomProduct from './actions/fetch-custom-product';
import setErrors from './actions/set-errors';
import handleNewCustomOption from './actions/handle-new-custom-option';
import openAddGroupModal from './actions/open-add-group-modal';
import openCustomOptionModal from './actions/open-custom-option-modal';
import addProductFormReducer, {
  AddProductFormActions,
  initialOrder,
} from './reducer';

export * from './validation-rules';
export { mapProducts, dropdownRender };

export default function useAddProductState(groups, quote, discount = 0) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    addProductFormReducer,
    {
      formData: initialOrder,
      treeData: [],
      errors: {},
      adding: false,
      groupForSelect: groups,
      formOptions: {
        productOptions: []
      }
    },
  );

  const { formData, groupForSelect, treeData, formOptions } = state;
  const { product } = formData;
  const { productOptions } = formOptions;

  const {
    fetching: fetchingOptionGroups,
    data: optionGroups,
    error: optionGroupsError,
    fetchList: fetchListOptionGroups,
  } = useGetList(optionGroupsApi, { preventAutoFetch: true });

  const {
    data: categories,
    error: categoriesError,
  } = useCallApiAction<CategoryModel[]>(categoriesApi.getTopLevel, '', []);

  const pricePayload = useMemo(() => {
    if (!!product?.label) {
      const normalizedOptions = productOptions?.length > 0
        ? Object.keys(formOptions)
          .filter(option => option !== 'productOptions')
          .reduce((opt, optionType) => {
            if (formOptions[optionType].value) {
              const optionInfo = {
                name: formOptions[optionType].value,
                custom: formOptions[optionType].dropdownItem?.custom,
                cost: formOptions[optionType].dropdownItem?.cost,
              };

              return {
                ...opt,
                [optionType]: optionInfo,
              };
            }

            return {
              ...opt,
              [optionType]: null,
            };
          }, {})
        : {};

      return {
        products: [{
          model: product.label,
          options: normalizedOptions,
          price: product?.dropdownItem?.price
        }]
      };
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, formOptions]);

  const {
    fetching: fetchingPrice,
    data: { data: priceData },
  } = useCallApiAction<any>(
    productApi.getPrice,
    null,
    { data: [] },
    !pricePayload?.products,
    pricePayload,
  );

  useEffect(() => {
    if (priceData && priceData.length > 0) {
      const { price } = priceData[0];
      const totalPrice = price - (price * (discount / 100));
      dispatch({
        type: AddProductFormActions.UPDATE_FORM_VALUE,
        payload: {
          recommendedPrice: totalPrice,
          price: totalPrice.toFixed(2),
        },
      });
    }
  }, [priceData, discount]);

  useEffect(() => {
    if (optionGroupsError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(optionGroupsError),
      });
    }
  }, [optionGroupsError]);

  useEffect(() => {
    if (categoriesError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(categoriesError),
      });
    }
  }, [categoriesError]);

  useEffect(() => {
    if (categories.length > 0 && treeData.length === 0) {
      const mappedCategories = categories.map((cat) => ({
        ...cat,
        name: (
          cat.name !== cat.description
            ? `${cat.name} - ${cat.description}`
            : cat.name
        )
      }));

      dispatch({
        type: AddProductFormActions.SET_TREE_DATA,
        payload: mapTreeSelectOptionSimple(mappedCategories, 0, 'categories'),
      });
    }
  }, [categories, treeData.length]);

  useEffect(() => {
    if (optionGroups) {
      prepareOptionsGroups(dispatch, optionGroups);
    }
  }, [optionGroups]);

  useEffect(() => {
    if (!product?.value && product?.label) {
      fetchCustomProduct(dispatch, product.label);
    }

    if (product?.dropdownItem) {
      fetchListOptionGroups({
        filters: {
          category: product.dropdownItem.category.name,
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.product]);

  const handleNewGroup = useCallback(setGroupValue(dispatch), []);
  const handleCustomOption = useCallback(handleNewCustomOption(dispatch), []);

  useEventCenterUpdate(EventChannelList.QUOTE_PAGE_NEW_GROUP, handleNewGroup);
  useEventCenterUpdate(
    EventChannelList.QUOTE_PAGE_NEW_CUSTOM_OPTION,
    handleCustomOption,
  );

  return {
    state: {
      ...state,
      fetching: fetchingOptionGroups,
      fetchingPrice,
      treeData,
      memoGroups: useMemo(() => (
        groupForSelect.map(group => ({
          value: group,
          label: group === '' ? 'None' : group,
        }))
      ), [groupForSelect]),
      modelParams: useMemo(
        () => (
          formData.category
            ? { category: formData.category.split(' ')[0] }
            : undefined
        ),
        [formData.category],
      ),
    },
    actions: {
      openAddGroupModal,
      openAddCustomOptionModal: openCustomOptionModal,
      handleValueChange: useCallback(setFormValue(dispatch), []),
      handleOptionsValueChange: useCallback(setOptionFormValue(dispatch), []),
      fetchCategories: useCallback(fetchCategories(dispatch), []),
      handleOnSubmit: useCallback(
        addProduct(dispatch, { ...formData, ...formOptions }, quote, goBack, priceData[0]),
        [formData, quote, goBack, formOptions, priceData],
      ),
      setErrors: useCallback(setErrors(dispatch), []),
    },
  };
}
