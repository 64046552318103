import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import { AccountGroupModel } from 'models/account-group';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/accounts-group/`;

const accountGroupApi: ApiModel<AccountGroupModel> = getApi<AccountGroupModel>(endpoint, ApiContentType.MULTIPART);

export default accountGroupApi;
