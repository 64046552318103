import React, { FunctionComponent, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Units } from 'models/builder';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Button from 'components/base-components/Button';
import ProductPanel from './ProductPanel';
import WallPanel from './WallPanel';
import { openCopyForm } from './utils';
import { BuilderFormModel, WallModel, FloorModel, TextureModel } from '../state';
import './styles.scss';

interface Props {
  blockUI: boolean;
  unit: Units;
  floorTextures: TextureModel[];
  wallTextures: TextureModel[];
  wall: WallModel;
  onWallChange: (wall: WallModel) => void;
  floor?: FloorModel;
  hiddenContent?: boolean;
  product: BuilderFormModel;
  onOptionsChange: (product: BuilderFormModel) => void;
  onPropertiesChange: (product: BuilderFormModel) => void;
  onSceneOptionsChange?: (product: BuilderFormModel) => void;
}

const PropertiesPanel: FunctionComponent<Props> = (props) => {
  const {
    blockUI,
    wallTextures,
    wall,
    onWallChange,
    floor,
    product,
    onPropertiesChange,
    onOptionsChange,
    onSceneOptionsChange,
    hiddenContent,
    unit,
  } = props;
  const { push } = useHistory();

  const openFrom = useCallback(openCopyForm(push, product), [push, product]);
  const wrapperClassName = useMemo(() => classnames('builder__product-panel', { blockUI }), [blockUI]);

  const hasNoElement = !product && !wall && !floor;

  if (hasNoElement) {
    return (
      <aside className="builder__product-panel">
        <h3>Properties</h3>
        <div className="builder__product-panel__fields" />
        <div className="builder__product-panel__fields" />
      </aside>
    );
  }

  const Panel = (
    (!!product && ProductPanel) ||
    (!!wall && WallPanel)
  );

  return (
    <aside className={wrapperClassName}>
      <RenderIf isTrue={!hiddenContent}>
        <div className="builder__product-panel__header">
          <h3>Properties</h3>
          <Button
            onClick={openFrom}
          >
            Copy
          </Button>
        </div>
        <Panel
          unit={unit}
          product={product}
          onOptionsChange={onOptionsChange}
          onPropertiesChange={onPropertiesChange}
          onSceneOptionsChange={onSceneOptionsChange}
          wall={wall}
          wallTextures={wallTextures}
          onWallChange={onWallChange}
        />
      </RenderIf>
    </aside>
  );
};

export default PropertiesPanel;
