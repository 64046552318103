import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { BuilderActions } from '../reducer';

export default function handleWallChange(dispatch) {
  return (wall) => {
    notifyEventChannel(EventChannelList.BUILDER_WALL_CHANGED, wall);
    dispatch({
      type: BuilderActions.UPDATE_WALL,
      payload: wall,
    });
  };
}
