/* eslint-disable */
import { Skybox } from "./skybox";

import { Controls } from "./controls";

import { HUD } from "./hud";

import { Controller } from "./controller";

import { Lights } from "./lights";

import { Floorplan } from "./floorplan";

import * as THREE from "three";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
// import { OutlinePass } from './customOutlinePass';

import { unitScale } from "../core/dimensioning";
import WallContextMenu from "./wallContextMenu";
import FloorContextMenu from "./floorContextMenu";
import Core from "../core";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default function (model, element, canvasElement, opts) {
  var scope = this;

  var options = {
    resize: true,
    pushHref: false,
    spin: true,
    spinSpeed: 0.00002,
    clickPan: true,
    canMoveFixedItems: false,
  };

  // override with manually set options
  for (var opt in options) {
    if (options.hasOwnProperty(opt) && opts.hasOwnProperty(opt)) {
      options[opt] = opts[opt];
    }
  }

  var scene = model.scene;
  this.scene = scene;

  this.element = element;
  var domElement;

  var camera;
  var renderer;
  var composer;
  var outlinePass;
  var effectFXAA;

  this.controls = null;
  // var canvas;
  var controller;
  var floorplan;

  //var canvas;
  //var canvasElement = canvasElement;

  var needsUpdate = false;

  var lastRender = Date.now();
  var mouseOver = false;
  var hasClicked = false;

  var hud;

  this.heightMargin = 0;
  this.widthMargin = 0;
  this.elementHeight = 0;
  this.elementWidth = 0;

  this.itemSelectedCallbacks = []; // item
  this.itemUnselectedCallbacks = [];

  this.wallClicked = []; // wall
  this.floorClicked = []; // floor
  this.nothingClicked = [];

  this.outlineManager = null;

  const init = () => {
    domElement = scope.element; // Container
    camera = new THREE.PerspectiveCamera(45, 1, 1, 10000);
    renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true, // required to support .toDataURL()
    });
    renderer.autoClear = false;
    renderer.shadowMap.enabled = true;
    renderer.shadowMapSoft = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    new Skybox(scene);

    scope.controls = new Controls(camera, domElement);

    let rect = scope.element.getBoundingClientRect();

    hud = new HUD(scope);

    controller = new Controller(
      scope,
      model,
      camera,
      renderer.domElement,
      scope.controls,
      hud
    );

    domElement.appendChild(renderer.domElement);

    composer = new EffectComposer(renderer);

    const renderPass = new RenderPass(scene.scene, camera);
    composer.addPass(renderPass);

    outlinePass = new OutlinePass(
      new THREE.Vector2(rect.width, rect.height),
      scene.scene,
      camera
    );
    outlinePass.renderToScreen = true;
    outlinePass.depthMaterial.morphTargets = true;
    outlinePass.prepareMaskMaterial.morphTargets = true;
    outlinePass.edgeStrength = 20;
    outlinePass.edgeGlow = 0.3;
    outlinePass.edgeThickness = 0.5;
    outlinePass.pulsePeriod = 2;
    outlinePass.visibleEdgeColor = new THREE.Color(0, 0, 1);
    outlinePass.hiddenEdgeColor = new THREE.Color(0, 0, 1);

    composer.addPass(outlinePass);

    effectFXAA = new ShaderPass(FXAAShader);
    effectFXAA.uniforms["resolution"].value.set(
      1 / rect.width,
      1 / rect.height
    );
    composer.addPass(effectFXAA);

    this.outlineManager = outlinePass;

    // console.log(this.outlineManager.selectedObjects);

    // handle window resizing
    scope.updateWindowSize();
    if (options.resize) {
      window.addEventListener("resize", scope.updateWindowSize);
    }

    // setup camera nicely
    scope.centerCamera();
    model.floorplan.fireOnUpdatedRooms(scope.centerCamera);

    new Lights(scene, model.floorplan);

    floorplan = new Floorplan(scene, model.floorplan, scope.controls);

    animate();

    scope.element.addEventListener("mouseenter", function () {
      mouseOver = true;
    });
    scope.element.addEventListener("mouseleave", function () {
      mouseOver = false;
    });
    scope.element.addEventListener("click", function () {
      hasClicked = true;
    });

    document.addEventListener(
      Core.BP3D_EVENT_HIGHLIGHT_CHANGED,
      ({ detail }) => {
        if (!detail) return;
        if (detail.objects) {
          this.updateOutline(detail.objects);
        }
      }
    );

    this.wallContextMenu = new WallContextMenu(this.element.parentNode, this);
    this.wallContextMenu.addEventListener(
      "heightChanged",
      this.handleWallHeightChanged
    );

    this.floorContextMenu = new FloorContextMenu(this.element.parentNode, this);
  };

  function spin() {
    if (options.spin && !mouseOver && !hasClicked) {
      var theta = 2 * Math.PI * options.spinSpeed * (Date.now() - lastRender);
      scope.controls.rotateLeft(theta);
      scope.controls.update();
    }
  }

  this.dataUrl = function () {
    var dataUrl = renderer.domElement.toDataURL("image/png");
    return dataUrl;
  };

  this.stopSpin = () => (hasClicked = true);

  this.options = () => options;

  this.getModel = () => model;

  this.getScene = () => scene;

  this.getController = () => controller;

  this.getCamera = () => camera;

  this.needsUpdate = () => (needsUpdate = true);

  function shouldRender() {
    return true;
    // // Do we need to draw a new frame
    // if (scope.controls.needsUpdate || controller.needsUpdate || needsUpdate || model.scene.needsUpdate) {
    //   scope.controls.needsUpdate = false;
    //   controller.needsUpdate = false;
    //   needsUpdate = false;
    //   model.scene.needsUpdate = false;
    //   return true;
    // } else {
    //   return false;
    // }
  }

  function render() {
    // spin();
    if (shouldRender()) {
      // renderer.clear();
      // renderer.render(scene.getScene(), camera);
      // renderer.clearDepth();
      // renderer.render(hud.getScene(), camera);
      composer.render();
    }
    lastRender = Date.now();
  }

  function animate() {
    requestAnimationFrame(animate);
    render();
  }

  this.rotatePressed = function () {
    controller.rotatePressed();
  };

  this.rotateReleased = function () {
    controller.rotateReleased();
  };

  this.setCursorStyle = function (cursorStyle) {
    domElement.style.cursor = cursorStyle;
  };

  this.updateWindowSize = function () {
    let rect = scope.element.getBoundingClientRect();
    scope.heightMargin = rect.top;
    scope.widthMargin = rect.left;

    scope.elementWidth = rect.width;
    scope.elementHeight = rect.height;

    camera.aspect = scope.elementWidth / scope.elementHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(scope.elementWidth, scope.elementHeight);
    composer.setSize(scope.elementWidth, scope.elementHeight);
    needsUpdate = true;
  };

  this.centerCamera = function () {
    var yOffset = 1.5 / unitScale;

    var pan = model.floorplan.getCenter();
    pan.y = yOffset;

    scope.controls.target = pan;

    var distance = model.floorplan.getSize().z * 1.5;

    var offset = pan.clone().add(new THREE.Vector3(0, distance, distance));
    //scope.controls.setOffset(offset);
    camera.position.copy(offset);

    scope.controls.update();
  };

  this.updateOutline = function (objects) {
    outlinePass.selectedObjects = [];
    Array.isArray(objects) &&
      objects.forEach((obj) => this.outlineManager.selectedObjects.push(obj));
  };

  // projects the object's center point into x,y screen coords
  // x,y are relative to top left corner of viewer
  this.projectVector = function (vec3, ignoreMargin) {
    ignoreMargin = ignoreMargin || false;

    var widthHalf = scope.elementWidth / 2;
    var heightHalf = scope.elementHeight / 2;

    var vector = new THREE.Vector3();
    vector.copy(vec3);
    vector.project(camera);

    var vec2 = new THREE.Vector2();

    vec2.x = vector.x * widthHalf + widthHalf;
    vec2.y = -(vector.y * heightHalf) + heightHalf;

    if (!ignoreMargin) {
      vec2.x += scope.widthMargin;
      vec2.y += scope.heightMargin;
    }

    return vec2;
  };

  this.showAllGizmo = function () {
    try {
      console.log("show all gizmo");
      scene.items.forEach((item) => item.showDimensionHelper());
    } catch (_) {
      console.log(_);
    }
  };
  this.hideAllGizmo = function () {
    try {
      console.log("hide all gizmo");
      scene.items.forEach((item) => item.hideDimensionHelper());
    } catch (_) {
      console.log(_);
    }
  };

  this.lockController = function (locked) {
    controller.locked = locked;
  };

  this.showWallContextMenu = (edge, left, top) => {
    if (!edge) {
      // this.controls.noPan = true;
      this.wallContextMenu.setEdge(null);
      this.wallContextMenu.setVisible(false);
      return;
    }

    // this.controls.noPan = true;
    hasClicked = true;
    this.wallContextMenu.setEdge(edge);
    this.wallContextMenu.setVisible(true);
    this.wallContextMenu.setPosition(left, top);
  };

  // this.wallClicked.push(this.showWallContextMenu);

  this.showFloorContextMenu = (floor, left, top) => {
    if (!floor) {
      // this.controls.noPan = true;
      this.floorContextMenu.setFloor(null);
      this.floorContextMenu.setVisible(false);
      return;
    }

    // this.controls.noPan = true;
    hasClicked = true;
    this.floorContextMenu.setVisible(true);
    this.floorContextMenu.setFloor(floor);
    this.floorContextMenu.setPosition(left, top);
  };

  // this.floorClicked.push(this.showFloorContextMenu);

  this.handleWallHeightChanged = (height) => {
    floorplan.updateHeight(height);
    this.updateWindowSize();
  };

  this.setWallTexturePresets = (textures) => {
    this.wallContextMenu.setTexturePresets(textures);
  };

  this.setFloorTexturePresets = (textures) => {
    this.floorContextMenu.setTexturePresets(textures);
  };

  function test() {
    const box = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 1),
      new THREE.MeshStandardMaterial({ color: 0xff0000 })
    );
    box.position.set(3.5, 0.3, 0);
    scene.add(box);
    // outlinePass.selectedObjects.push(box);

    new GLTFLoader().load("/Blueprint3D-assets/models/glb/test.glb", (gltf) => {
      let mesh = null;
      gltf.scene.traverse((node) => {
        if (node.isMesh) {
          mesh = node;
        }
      });
      console.log(mesh);
      mesh.position.x = 5;
      mesh.morphTargetInfluences[0] = 0.5;
      scene.add(mesh);
      outlinePass.selectedObjects.push(mesh);
    });
  }

  init();
}
