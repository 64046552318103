import { TermModel } from 'models/term';
import { commonRules, RuleType } from 'helpers/form-validations';

const { required: requiredRule } = commonRules;

const validateTotalPercentage = (_value, term: TermModel) => {
  const {
    onOrderConversion,
    onProductionCommencing,
    onLeavingForDelivery,
    onInstallComplete,
    onOrderCompletion,
  } = term;

  const sum = (
    (onOrderConversion || 0) +
    (onProductionCommencing || 0) +
    (onLeavingForDelivery || 0) +
    (onInstallComplete || 0) +
    (onOrderCompletion || 0)
  );

  if (sum !== 100) {
    return 'the sum of all percentages must be 100%';
  }
  return undefined;
};

const validationRules = {
  termsName: [requiredRule],
  termsDetails: [requiredRule],
  days: [
    requiredRule,
    { type: RuleType.Min, value: 0, message: 'There must be at least 0 day' }
  ],
  totalPercentage: [validateTotalPercentage],
};

export default validationRules;
