import React, { FunctionComponent, useCallback } from 'react';
import { InstallerBookingModel } from 'models/installer-booking';
import { formatDisplayDate, formatDisplayDateTime } from 'helpers/formatting';
import Tag from 'components/base-components/Tag';
import FlexBox from 'components/base-components/FlexBox';
import { Link, Text } from 'components/base-components/Typography';
import Popover from 'components/base-components/Popover';
import Button from 'components/base-components/Button';
import RenderIf from 'components/base-components/RenderIf';
import Tooltip from 'components/base-components/Tooltip';
import printInstallerOrder from './actions/print-installer-order';
import printShopOrder from './actions/print-shop-order';

interface Props {
  item: InstallerBookingModel;
  hasOrderPermission?: boolean;
}

const textStyle = { marginBottom: '8px' };

const BookingEvent: FunctionComponent<Props> = (props) => {
  const { item, hasOrderPermission } = props;
  const {
    id, orderNumber, installationDate, jobTitle, readyDate,
    quoteId, companyName, installer: { companyName: installerCompany }
  } = item;
  const printInstaller = useCallback(() => printInstallerOrder(orderNumber), [orderNumber]);
  const printShop = useCallback(() => printShopOrder(orderNumber), [orderNumber]);

  const content = (
    <FlexBox direction="column">
      <Text strong>Order</Text>
      <RenderIf isTrue={!!orderNumber && hasOrderPermission}>
        <Link style={textStyle} href={`/quote/${quoteId}`}>{orderNumber}</Link>
      </RenderIf>
      <RenderIf isTrue={!orderNumber || !hasOrderPermission}>
        <Text style={textStyle}>N/A</Text>
      </RenderIf>
      <Text strong>Company Name</Text>
      <Text style={textStyle}>{companyName || 'N/A'}</Text>
      <Text strong>Job Title</Text>
      <Text style={textStyle}>{jobTitle || 'N/A'}</Text>
      <Text strong>Ready Date</Text>
      <Text>{formatDisplayDate(readyDate) || 'N/A'}</Text>
      <Text strong>Installer</Text>
      <Text style={textStyle}>{installerCompany}</Text>
      <Text strong>Installation date</Text>
      <Text style={textStyle}>{formatDisplayDateTime(installationDate)}</Text>
    </FlexBox>
  );

  const title = (
    <FlexBox justify="space-between">
      <Text strong>Installer Booking</Text>
      <RenderIf isTrue={!!orderNumber}>
        <Tooltip title="Print Installer">
          <Button size="small" type="text" shape="circle" onClick={printInstaller} icon="fa fa-tools" mL />
        </Tooltip>
        <Tooltip title="Print Shop">
          <Button size="small" type="text" shape="circle" onClick={printShop} icon="fa fa-shopping-bag" />
        </Tooltip>
      </RenderIf>
    </FlexBox>
  );

  return (
    <Popover
      content={content}
      overlayClassName="production-calendar__popovers"
      title={title}
      placement="bottomLeft"
      trigger="hover"
    >
      <Tag
        color="black"
        className="production-calendar__tag"
        id={id.toString(10)}
      >
        <i className="fa fa-tools" />
        <span style={{ marginLeft: '8px', fontSize: '14px' }}>
          {`${orderNumber} - ${installerCompany}`}
        </span>
      </Tag>
    </Popover>
  );
};

export default BookingEvent;
