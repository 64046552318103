export enum CategoryFormActions {
  SET_PARENT_TREE_DATA = 'SET_PARENT_TREE_DATA',
  SET_CATEGORIES_TREE_DATA = 'SET_CATEGORIES_TREE_DATA',
  INITIALIZE_TREE_DATA = 'INITIALIZE_TREE_DATA',
}

interface CategoryFormState {
  parentTreeData: any[];
  categoriesTreeData: any[];
}

export default function categoryFormReducer(state: CategoryFormState, action): CategoryFormState {
  const { type, payload } = action;

  switch (type as CategoryFormActions) {
    case CategoryFormActions.INITIALIZE_TREE_DATA:
      return {
        ...state,
        parentTreeData: payload,
        categoriesTreeData: payload
      };
    case CategoryFormActions.SET_PARENT_TREE_DATA:
      return {
        ...state,
        parentTreeData: state.parentTreeData.concat(payload),
      };
    case CategoryFormActions.SET_CATEGORIES_TREE_DATA:
      return {
        ...state,
        categoriesTreeData: state.categoriesTreeData.concat(payload),
      };
    default:
      return state;
  }
}
