import { AxiosResponse } from 'axios';
import { BuilderModel } from 'models/builder';
import builderApi from 'api/builder';
import notification, { NotificationType } from 'helpers/notification';
import normalizeModel from 'components/pages/Builder/state/actions/normalize-model';
import { Actions } from '../reducer';

export default function fetchModel(dispatch) {
  const onSuccess = (response: AxiosResponse<BuilderModel>) => {
    const { data: product } = response;
    const normalizedProduct = normalizeModel(product);
    dispatch({ type: Actions.LOAD_PREVIEW_MODEL, payload: normalizedProduct });
  };

  const onError = () => {
    notification({
      type: NotificationType.ERROR,
      message: 'We could not load the preview model'
    });
    dispatch({ type: Actions.FINISH_LOADING_PAGE });
  };

  builderApi.getDetailsByModel('DESK-RRF')
    .then(onSuccess)
    .catch(onError);
}
