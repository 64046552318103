import { RuleType } from 'helpers/form-validations';

export const validationRules = {
  zoneName: [
    { type: RuleType.Required, message: 'This field is required' },
  ],
  zonePercentage: [
    { type: RuleType.Max, message: 'This field must be between 0 - 100', value: 100 },
    { type: RuleType.Min, message: 'This field must be between 0 - 100', value: 0 }
  ]
};
