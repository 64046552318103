import React, { FunctionComponent, useMemo } from 'react';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import { useHistory } from 'react-router-dom';
import { validationRules } from './validations';
import useAccountGroupFormState from './state';

export interface AccountGroupFormHistorySateModel {
  accountGroupForm: {
    accountGroupUrl: string;
  };
}

const AccountGroupForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { accountGroupForm: { accountGroupUrl } } = state as AccountGroupFormHistorySateModel;

  const {
    state: {
      accountGroup,
      savingAccountGroup,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveAccountGroup,
      setErrors,
    },
  } = useAccountGroupFormState(accountGroupUrl);

  const modalTitle = useMemo(
    () => accountGroupUrl ? 'Edit Account Group' : 'Create Account Group',
    [accountGroupUrl],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveAccountGroup}
      confirmLoading={savingAccountGroup}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={accountGroup}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="type"
            name="type"
            label="Type"
            placeholder="Enter Account Group type"
            disabled={!!accountGroup && !!accountGroup.url}
          />
          <Field
            type="number"
            name="discount"
            id="discount"
            label="Discount"
            placeholder="Enter discount"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default AccountGroupForm;
