import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { AddProductHistorySateModel } from '../../Form/AddProduct';
import { AccountDropdownModel } from '../reducer';

export default function openAddProductModal(
  push,
  quote: QuoteModel,
  account: AccountDropdownModel,
  groups: string[],
  isDealer: boolean,
) {
  return () => {
    const historyLocationState: AddProductHistorySateModel = {
      addProductForm: {
        groups,
        quote: quote.id,
        discount: account.groupDetail?.discount
          ? parseFloat(account.groupDetail.discount)
          : 0,
        isDealer,
      },
    };
    push(ModalRouteHash.AddProduct, historyLocationState);
  };
}
