import React from 'react';
import { Redirect } from 'react-router-dom';
import { PermissionLabels } from 'constants/permissions';
import Dashboard from 'components/pages/Dashboard';
import Login from 'components/pages/Authentication/Login';
import ForgetPassword from 'components/pages/Authentication/ForgetPassword';
import ForgetPasswordConfirmation from 'components/pages/Authentication/ForgetPasswordConfirmation';
import UsersGroupList from 'components/pages/UserGroups/List';
import UsersList from 'components/pages/Users/List';
import AccountsList from 'components/pages/Account/List';
import AccountGroupsList from 'components/pages/AccountGroups/List';
import TermsList from 'components/pages/Terms/List';
import AccountApplicationForm from 'components/pages/Authentication/AccountApplicationForm';
import AccountApplicationsList from 'components/pages/AccountApplications/List';
import ManufacturersList from 'components/pages/Manufacturers/List';
import OptionsList from 'components/pages/Options/List';
import OptionGroupsList from 'components/pages/OptionGroups/List';
import CategoriesList from 'components/pages/Categories/List';
import DeliveryQuestionnaireForm from 'components/pages/DeliveryQuestionnaire';
import ProductsList from 'components/pages/Products/List';
import QuotesList from 'components/pages/Quotes/List';
import Quote from 'components/pages/Quotes/Quote';
import OrdersList from 'components/pages/Orders/List';
import CNCCalc from 'components/pages/CNCCalc/List';
import DeficiencyItemsList from 'components/pages/DeficiencyItems/List';
import DeficiencyItemsDetails from 'components/pages/DeficiencyItems/Details';
import PurchaseOrdersList from 'components/pages/PurchaseOrders/List';
import PurchaseOrderDetails from 'components/pages/POItems/List';
import WorkOrder from 'components/pages/Printouts/WorkOrder';
import PackingSlip from 'components/pages/Printouts/PackingSlip';
import ThirdPartyPaint from 'components/pages/Printouts/ThirdPartyPaint';
import QuoteReport from 'components/pages/Printouts/Quote';
import Builder from 'components/pages/Builder';
import CreateProject from 'components/pages/CreateProject';
import Reports from 'components/pages/Reports';
import ReportView from 'components/pages/Reports/ReportView';
import ThreeModelsList from 'components/pages/ThreeModels/List';
import ShippingZonesSetting from 'components/pages/ShippingZonesSetting/List';
import StoreList from 'components/pages/Store/List';
import CNCManualCalculatorFormAsPage from 'components/pages/CNCCalc/Form/AsPage';
import ViewsPermissions from 'components/pages/ViewsPermissions';
import ProductViewer from 'components/pages/ProductViewer';
import TaskSetting from 'components/pages/TaskSetting';
import Invoices from 'components/pages/Invoices';
import ProductionManager from 'components/pages/ProductionManager';
import Parts from 'components/pages/Parts/List';
import Installers from 'components/pages/Installers/List';
import InstallerBookingList from 'components/pages/InstallersBooking/List';
import CategoryImage from 'components/pages/CategoryImage';
import Payment from 'components/pages/Payment/List';
import TextureSettings from 'components/pages/TextureSettings';
import AttachmentDocument from 'components/pages/AttachmentDocument/List';
import SalesReport from 'components/pages/SalesReport';
import ProjectCoordinator from 'components/pages/ProjectCoordinator';
import GenerateProduct from 'components/pages/GenerateProduct';
import TutorialsList from 'components/pages/Tutorials/List';
import PrivacyPolicy from 'components/pages/PrivacyPolicy';
import EULA from 'components/pages/EULA';
import TaskTemplates from 'components/pages/TaskTemplates/List';
import PropertyList from 'components/pages/PropertySetting/List';
import FlowPlannerList from 'components/pages/FlowPlanner/List';
import InventoryList from 'components/pages/Inventory/List';
import SheetList from 'components/pages/SheetSetting/List';
import CutList from 'components/pages/CutList/List';
import CutListDetails from 'components/pages/CutList/Details';
import QBOSyncList from 'components/pages/QBOSync/List';
import CNCWorker from 'components/pages/CNCWorker';

const authProtectedRoutes: {
  path: string;
  component: any;
  exact?: boolean;
  permissions?: PermissionLabels[];
}[] = [
  { path: '/dashboard', component: Dashboard },
  // account
  { path: '/user-groups', component: UsersGroupList, permissions: ['Account-User_Groups'] },
  { path: '/users', component: UsersList, permissions: ['Account-Users'] },
  { path: '/accounts', component: AccountsList, permissions: ['Account-Accounts', 'Sales'] },
  { path: '/account-groups', component: AccountGroupsList, permissions: ['Account-Account_Groups'] },
  { path: '/account-applications', component: AccountApplicationsList, permissions: ['Account-Account_Applications', 'Sales'] },
  { path: '/terms', component: TermsList, permissions: ['Account-Terms'] },
  // Catalog
  { path: '/manufacturers', component: ManufacturersList, permissions: ['Catalog-Manufacturers', 'Sales', 'Shop'] },
  { path: '/options', component: OptionsList, permissions: ['Catalog-Options', 'Shop'] },
  { path: '/option-groups', component: OptionGroupsList, permissions: ['Catalog-Option_Groups', 'Sales', 'Shop'] },
  { path: '/categories', component: CategoriesList, permissions: ['Catalog-Categories', 'Sales', 'Shop'] },
  { path: '/products', component: ProductsList, permissions: ['Catalog-Products', 'Dealer', 'Sales', 'Shop'] },
  // Inventory
  { path: '/inventory', component: InventoryList, permissions: ['Inventory-Inventory', 'Shop'] },
  // Production
  { path: '/production-manager', component: ProductionManager, permissions: ['Productions-Production_Manager', 'Shop'] },
  { path: '/installer-booking', component: InstallerBookingList, permissions: ['Productions-Installers_Booking', 'Shop'] },
  { path: '/project-coordinator', component: ProjectCoordinator, permissions: ['Productions-Project_Coordinator', 'Shop'] },
  { path: '/cutlists', component: CutList, permissions: ['Productions-Cutlist', 'Shop'] },
  { path: '/cutlist/:id', component: CutListDetails, permissions: ['Productions-Cutlist', 'Shop'] },
  { path: '/purchase-orders', component: PurchaseOrdersList, permissions: ['Productions-Purchase_Orders', 'Shop'] },
  { path: '/purchase-order/:id', component: PurchaseOrderDetails, permissions: ['Productions-Purchase_Orders', 'Shop'] },
  { path: '/parts', component: Parts, permissions: ['Productions-Parts', 'Shop'] },
  { path: '/cnc-calc', component: CNCCalc, permissions: ['Productions-CNC_Calc', 'Shop'] },
  { path: '/cnc-worker', component: CNCWorker, permissions: ['Productions-CNC_Worker', 'Shop'] },
  // Sales
  { path: '/quotes', component: QuotesList, permissions: ['Sales-Quotes', 'Dealer', 'Sales'] },
  { path: '/quote/:id', component: Quote, permissions: ['Sales-Quotes', 'Sales-Orders', 'Dealer', 'Sales'] },
  { path: '/quote', component: Quote, permissions: ['Sales-Quotes', 'Dealer', 'Sales'] },
  { path: '/orders', component: OrdersList, permissions: ['Sales-Orders', 'Dealer', 'Sales'] },
  { path: '/deficiency-orders', component: DeficiencyItemsList, permissions: ['Sales-Deficiency_Orders'] },
  { path: '/deficiency-order/:projectId', component: DeficiencyItemsDetails, permissions: ['Sales-Deficiency_Orders'] },
  { path: '/invoices/', component: Invoices, permissions: ['Sales-Invoices', 'Sales', 'Dealer'] },
  { path: '/project-designs', component: FlowPlannerList, permissions: ['Dealer', 'Sales-Project_Designs'] },
  { path: '/payments', component: Payment, permissions: ['Sales-Payments'] },
  // Sales-Pipeline
  { path: '/create-project', component: CreateProject, permissions: ['add_quotes', 'Sales_Pipeline-Create_Project', 'Sales'] },
  // Reports
  { path: '/reports', component: Reports, permissions: ['Reports'] },
  { path: '/report-view/:report', component: ReportView, permissions: ['Reports'] },
  { path: '/sales-report', component: SalesReport, permissions: ['Reports'] },
  // Setting
  { path: '/three-models', component: ThreeModelsList, permissions: ['Settings-Three_Models'] },
  { path: '/stores', component: StoreList, permissions: ['Settings-Account-Stores'] },
  { path: '/shipping-zones-setting', component: ShippingZonesSetting, permissions: ['Settings-Shipping-Shipping_Zones'] },
  { path: '/views-permissions', component: ViewsPermissions, permissions: ['Settings-Views_Permissions'] },
  { path: '/sheets', component: SheetList, permissions: ['Settings-Materials-Sheet_Setting'] },
  { path: '/installer', component: Installers, permissions: ['Settings-Shipping-Installer'] },
  { path: '/task-setting', component: TaskSetting },
  { path: '/category-image', component: CategoryImage, permissions: ['Settings-Catalog-Category_Image'] },
  { path: '/textures', component: TextureSettings, permissions: ['Settings-Textures'] },
  { path: '/tutorials', component: TutorialsList, permissions: ['Settings-Tutorials'] },
  { path: '/attachment-document', component: AttachmentDocument, permissions: ['Settings-Attachment_Document'] },
  { path: '/generate-products', component: GenerateProduct, permissions: ['Settings-Catalog-Generate_Products'] },
  { path: '/task-templates', component: TaskTemplates, permissions: ['Settings-Task_Templates'] },
  { path: '/property-setting', component: PropertyList, permissions: ['Settings-Property_Setting'] },
  { path: '/qbo-sync', component: QBOSyncList, permissions: ['Settings-QBO_Sync'] },
  // Builder
  { path: '/builder', component: Builder },
  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPassword },
  { path: '/forgot-password-confirmation', component: ForgetPasswordConfirmation },
  // { path: '/register', component: Register }
  { path: '/account-application', component: AccountApplicationForm },
  { path: '/questionnaire', component: DeliveryQuestionnaireForm },
  { path: '/printouts/work-order', component: WorkOrder },
  { path: '/printouts/packing-slip', component: PackingSlip },
  { path: '/printouts/third-party-paint', component: ThirdPartyPaint },
  { path: '/printouts/quote-report', component: QuoteReport },
  { path: '/product-viewer', component: ProductViewer },
  { path: '/manually-cnc-calc', component: CNCManualCalculatorFormAsPage },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/eula', component: EULA },
];

export { publicRoutes, authProtectedRoutes };
