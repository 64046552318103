import notification, { NotificationType } from 'helpers/notification';
import { UpdateShippingActions, AccountAddress } from '../reducer';

interface AddressFromAccount {
  streetNumber?: number;
  streetName: string;
  city?: string;
  postalCode?: string;
  change?: boolean;
}

function isNumeric(str) {
  return !isNaN(parseFloat(str));
}

export function copyAddressFromAccount(dispatch, accountAddress: AccountAddress) {
  return () => {
    const haveSomeValue = !!accountAddress.street || !!accountAddress.city || !!accountAddress.postalCode;
    if (haveSomeValue) {
      const payload = { change: true } as AddressFromAccount;
      if (accountAddress.city) {
        payload.city = accountAddress.city;
      }
      if (accountAddress.postalCode) {
        payload.postalCode = accountAddress.postalCode;
      }
      if (accountAddress.street) {
        const indexOfFirstWhiteSpace = accountAddress.street.indexOf(' ');
        let streetName = accountAddress.street;
        if (indexOfFirstWhiteSpace) {
          const subStrUntilWhiteSpace = accountAddress.street.slice(0, indexOfFirstWhiteSpace);
          if (isNumeric(subStrUntilWhiteSpace)) {
            payload.streetNumber = parseInt(subStrUntilWhiteSpace, 10);
            streetName = accountAddress.street.slice(indexOfFirstWhiteSpace + 1);
          }
        }
        payload.streetName = streetName;
      }

      dispatch({
        type: UpdateShippingActions.COPY_FROM_ACCOUNT,
        payload,
      });
    } else {
      notification({ type: NotificationType.ERROR, message: 'The account has no significant address' });
    }
  };
}
