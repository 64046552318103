import React, { FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { PickListProvider } from './context';
import './styles.scss';

export interface PickListProps {
  value?: string | string[];
  onChange?: (value) => void;
  cols?: 2 | 3 | 4;
  multiple?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const PickList: FunctionComponent<PickListProps> = (props) => {
  const {
    value,
    onChange,
    multiple,
    size,
    children,
    cols,
  } = props;

  const handleChange = useCallback((item) => {
    if (value === undefined || value === null || !onChange) {
      return;
    }

    if (multiple) {
      const isSelected = (value as string[]).some((v) => v === item);
      const nextValue = isSelected
        ? (value as string[]).filter(v => v !== item)
        : (value as string[]).concat([item]);

      onChange(nextValue);
    } else {
      onChange(item);
    }
  }, [onChange, value, multiple]);

  const listClassName = classnames('tfg-pick-list', `cols-${cols}`);

  return (
    <PickListProvider
      size={size}
      value={value}
      multiple={multiple}
      onChange={handleChange}
    >
      <ul className={listClassName}>
        {children}
      </ul>
    </PickListProvider>

  );
};

PickList.defaultProps = {
  size: 'medium',
  cols: 3,
};

export default PickList;
