import notification, { NotificationType } from 'helpers/notification';
import { ApiModel, resolveApiErrorMessage } from 'api/base';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { TableActions } from '../reducer';

export interface DeleteEntryParams {
  dispatch: any;
  entryToDelete: any;
  eventChannel: EventChannelList;
  successMessage?: string;
  api: ApiModel;
}

export async function deleteEntry(params: DeleteEntryParams, idField: string) {
  const {
    dispatch,
    eventChannel,
    entryToDelete,
    successMessage,
    api,
  } = params;

  notifyEventChannel(EventChannelList.DATATABLE_DELETING, true);
  const queryParams = idField ? entryToDelete[idField] : entryToDelete.url || entryToDelete.id;

  try {
    await api.delete(queryParams);
    const message = successMessage || 'Entry successfully deleted.';
    notification({ type: NotificationType.SUCCESS, message });
    dispatch({ type: TableActions.HANDLE_PAGE_CHANGE, payload: 1 });
    notifyEventChannel(eventChannel);
  } catch (error) {
    const message = resolveApiErrorMessage((error as any).response);
    notification({ type: NotificationType.ERROR, message });
  } finally {
    notifyEventChannel(EventChannelList.DATATABLE_DELETING, false);
  }
}
