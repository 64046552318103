import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import AccessControl from 'components/base-components/AccessControl';
import ReuseQuoteForm from 'components/pages/Quotes/List/ReuseQuoteForm';
import QuickAccountForm from 'components/pages/Quotes/Quote/Form/CreateAccount';
import QuickUserForm from 'components/pages/Quotes/Quote/Form/CreateUser';
import UserForm from 'components/pages/Users/Form';
import AccountForm from 'components/pages/Account/Form';
import CNCManualCalculatorForm from 'components/pages/CNCCalc/Form';
import UserGroupForm from 'components/pages/UserGroups/Form';
import TermForm from 'components/pages/Terms/Form';
import ProductForm from 'components/pages/Products/Form';
import ProductDetails from 'components/pages/Products/List/ProductDetails';
import OptionForm from 'components/pages/Options/Form';
import SubOptionFrom from 'components/pages/Options/SubOptionFrom';
import OptionGroupForm from 'components/pages/OptionGroups/Form';
import ManufacturerForm from 'components/pages/Manufacturers/Form';
import CategoryForm from 'components/pages/Categories/Form';
import AccountApplicationDetails from 'components/pages/AccountApplications/DetailsModal';
import AccountGroupForm from 'components/pages/AccountGroups/Form';
import DeficiencyItemForm from 'components/pages/DeficiencyItems/Form';
import PurchaseOrderForm from 'components/pages/PurchaseOrders/Form';
import CustomProduct from 'components/pages/Quotes/Quote/Form/CustomProduct';
import AddProduct from 'components/pages/Quotes/Quote/Form/AddProduct';
import AddProduct3D from 'components/forms/AddProduct3D';
import EditOptions from 'components/pages/Quotes/Quote/Form/EditOptions';
import CopyQuoteForm from 'components/pages/Quotes/Quote/Form/CopyQuote';
import CopyProducts from 'components/pages/Quotes/Quote/Form/CopyOrderProducts';
import PurchaseOrderItemForm from 'components/pages/POItems/Form';
import SendEmailForm from 'components/forms/SendEmail';
import CreateQuoteForm from 'components/pages/Quotes/Quote/Form/CreateQuote';
import CreateProjectForm from 'components/pages/Quotes/Quote/Form/CreateProject';
import UpdateShipping from 'components/pages/Quotes/Quote/Form/UpdateShipping';
import ApplyOptions from 'components/pages/Quotes/Quote/Form/ApplyOptions';
import OptionDetails from 'components/pages/Options/List/OptionDetails';
import ReceivePaymentForm from 'components/pages/Orders/PaymentForm';
import FileViewer from 'components/pages/Quotes/Quote/Form/FileViewer';
import AssignFiles from 'components/pages/Quotes/Quote/Form/AssignFiles';
import ShippingZoneSettingForm from 'components/pages/ShippingZonesSetting/Form';
import PartForm from 'components/pages/Parts/Form';
import PartDetails from 'components/pages/Parts/List/Details';
import ThreeModelForm from 'components/pages/ThreeModels/Form';
import StoreForm from 'components/pages/Store/Form';
import CopyProductsForm from 'components/pages/Builder/CopyForm';
import ProductListForm from 'components/pages/Builder/ProductList';
import AddToCartFrom from 'components/pages/Builder/AddToCartForm';
import SetModal from 'components/pages/Builder/SetModal';
import TaskManager from 'components/pages/TaskManager';
import Calendar from 'components/pages/Calendar';
import MaterialsDetails from 'components/pages/ProductionManager/MaterialsDetails';
import ConfirmReadyDate from 'components/pages/ProductionManager/ConfirmReadyDate';
import InstallerForm from 'components/pages/Installers/Form';
import InstallerBookingForm from 'components/pages/InstallersBooking/Form';
import PaymentForm from 'components/pages/Payment/Form';
import AttachmentDocumentForm from 'components/pages/AttachmentDocument/Form';
import POItemsForm from 'components/pages/ProductionManager/PurchaseOrderForm';
import SetInstaller from 'components/pages/ProjectCoordinator/SetInstaller';
import TutorialForm from 'components/pages/Tutorials/Form';
import NotesForm from 'components/pages/ProjectCoordinator/Notes';
import TaskForm from 'components/pages/ProjectCoordinator/Task';
import TaskTemplatesForm from 'components/pages/TaskTemplates/Form';
import PropertyForm from 'components/pages/PropertySetting/Form';
import InventoryForm from 'components/pages/Inventory/Form';
import SheetForm from 'components/pages/SheetSetting/Form';
import CutListForm from 'components/pages/CutList/Form';
import CustomEventForm from 'components/pages/ProductionManager/CustomEvent';
import ErrorBoundary from '../ErrorBoundry';
import ErrorModal from './ErrorModal';
import { ModalRouteHash } from './modal-route-hash';
import { modalPermissions } from './permissions';

export { ModalRouteHash };

function getModal(hash) {
  switch (hash as ModalRouteHash) {
    case ModalRouteHash.CopyQuote:
      return (
        <AccessControl permissions={modalPermissions.copyQuote}>
          <CopyQuoteForm />
        </AccessControl>
      );
    case ModalRouteHash.ReuseQuote:
      return (
        <AccessControl permissions={modalPermissions.copyQuote}>
          <ReuseQuoteForm />
        </AccessControl>
      );
    case ModalRouteHash.UserForm:
      return (
        <AccessControl permissions={modalPermissions.user}>
          <UserForm />
        </AccessControl>
      );
    case ModalRouteHash.AccountForm:
      return (
        <AccessControl permissions={modalPermissions.account}>
          <AccountForm />
        </AccessControl>
      );
    case ModalRouteHash.UserGroupForm:
      return (
        <AccessControl permissions={modalPermissions.userGroup}>
          <UserGroupForm />
        </AccessControl>
      );
    case ModalRouteHash.TermForm:
      return (
        <AccessControl permissions={modalPermissions.terms}>
          <TermForm />
        </AccessControl>
      );
    case ModalRouteHash.ProductForm:
      return (
        <AccessControl permissions={modalPermissions.product}>
          <ProductForm />
        </AccessControl>
      );
    case ModalRouteHash.ProductDetails:
      return (
        <AccessControl permissions={modalPermissions.productDetails}>
          <ProductDetails />
        </AccessControl>
      );
    case ModalRouteHash.OptionForm:
      return (
        <AccessControl permissions={modalPermissions.options}>
          <OptionForm />
        </AccessControl>
      );
    case ModalRouteHash.SubOptionForm:
      return (
        <AccessControl permissions={modalPermissions.options}>
          <SubOptionFrom />
        </AccessControl>
      );
    case ModalRouteHash.OptionGroupForm:
      return (
        <AccessControl permissions={modalPermissions.optionGroup}>
          <OptionGroupForm />
        </AccessControl>
      );
    case ModalRouteHash.ManufacturerForm:
      return (
        <AccessControl permissions={modalPermissions.manufacturer}>
          <ManufacturerForm />
        </AccessControl>
      );
    case ModalRouteHash.CategoryForm:
      return (
        <AccessControl permissions={modalPermissions.category}>
          <CategoryForm />
        </AccessControl>
      );
    case ModalRouteHash.AccountGroupForm:
      return (
        <AccessControl permissions={modalPermissions.accountGroup}>
          <AccountGroupForm />
        </AccessControl>
      );
    case ModalRouteHash.AccountApplicationDetails:
      return (
        <AccessControl permissions={modalPermissions.accountApplicationDetails}>
          <AccountApplicationDetails />
        </AccessControl>
      );
    case ModalRouteHash.DeficiencyItemForm:
      return (
        <AccessControl permissions={modalPermissions.deficiencyOrder}>
          <DeficiencyItemForm />
        </AccessControl>
      );
    case ModalRouteHash.CustomProduct:
      return (
        <AccessControl permissions={modalPermissions.editQuote}>
          <CustomProduct />
        </AccessControl>
      );
    case ModalRouteHash.QuickAccountForm:
      return (
        <AccessControl permissions={modalPermissions.quickAccount}>
          <QuickAccountForm />
        </AccessControl>
      );
    case ModalRouteHash.QuickUserForm:
      return (
        <AccessControl permissions={modalPermissions.user}>
          <QuickUserForm />
        </AccessControl>
      );
    case ModalRouteHash.PurchaseOrderForm:
      return (
        <AccessControl permissions={modalPermissions.purchaseOrder}>
          <PurchaseOrderForm />
        </AccessControl>
      );
    case ModalRouteHash.AddProduct:
      return (
        <AccessControl permissions={modalPermissions.editQuote}>
          <AddProduct />
        </AccessControl>
      );
    case ModalRouteHash.EditOptions:
      return (
        <AccessControl permissions={modalPermissions.editQuote}>
          <EditOptions />
        </AccessControl>
      );
    case ModalRouteHash.PurchaseOrderItemForm:
      return (
        <AccessControl permissions={modalPermissions.pOItems}>
          <PurchaseOrderItemForm />
        </AccessControl>
      );
    case ModalRouteHash.CreateQuote:
      return (
        <AccessControl permissions={modalPermissions.copyQuote}>
          <CreateQuoteForm />
        </AccessControl>
      );
    case ModalRouteHash.CreateProject:
      return (
        <AccessControl permissions={modalPermissions.project}>
          <CreateProjectForm />
        </AccessControl>
      );
    case ModalRouteHash.UpdateShipping:
      return (
        <AccessControl permissions={modalPermissions.shipping}>
          <UpdateShipping />
        </AccessControl>
      );
    case ModalRouteHash.ApplyOptions:
      return (
        <AccessControl permissions={modalPermissions.editQuote}>
          <ApplyOptions />
        </AccessControl>
      );
    case ModalRouteHash.ReceivePayment:
      return (
        <AccessControl permissions={modalPermissions.receivePayment}>
          <ReceivePaymentForm />
        </AccessControl>
      );
    case ModalRouteHash.PaymentForm:
      return (
        <AccessControl permissions={modalPermissions.receivePayment}>
          <PaymentForm />
        </AccessControl>
      );
    case ModalRouteHash.CopyOrderProducts:
      return (
        <AccessControl permissions={modalPermissions.editQuote}>
          <CopyProducts />
        </AccessControl>
      );
    case ModalRouteHash.OptionDetails:
      return (
        <AccessControl permissions={modalPermissions.optionsDetails}>
          <OptionDetails />
        </AccessControl>
      );
    case ModalRouteHash.ThreeModelForm:
      return (
        <AccessControl permissions={modalPermissions.threeModel}>
          <ThreeModelForm />
        </AccessControl>
      );
    case ModalRouteHash.StoreSetting:
      return (
        <AccessControl permissions={modalPermissions.store}>
          <StoreForm />
        </AccessControl>
      );
    case ModalRouteHash.ShippingZoneSetting:
      return (
        <AccessControl permissions={modalPermissions.shippingZone}>
          <ShippingZoneSettingForm />
        </AccessControl>
      );
    case ModalRouteHash.MaterialsDetails:
      return (
        <AccessControl permissions={modalPermissions.productionManager}>
          <MaterialsDetails />
        </AccessControl>
      );
    case ModalRouteHash.ConfirmReadyDate:
      return (
        <AccessControl permissions={modalPermissions.productionManager}>
          <ConfirmReadyDate />
        </AccessControl>
      );
    case ModalRouteHash.PartForm:
      return (
        <AccessControl permissions={modalPermissions.part}>
          <PartForm />
        </AccessControl>
      );
    case ModalRouteHash.PartDetail:
      return (
        <AccessControl permissions={modalPermissions.viewPart}>
          <PartDetails />
        </AccessControl>
      );
    case ModalRouteHash.InstallerForm:
      return (
        <AccessControl permissions={modalPermissions.installer}>
          <InstallerForm />
        </AccessControl>
      );
    case ModalRouteHash.InstallerBookingForm:
      return (
        <AccessControl permissions={modalPermissions.installerBooking}>
          <InstallerBookingForm />
        </AccessControl>
      );
    case ModalRouteHash.SetInstallerBooking:
      return (
        <AccessControl permissions={modalPermissions.installerBooking}>
          <SetInstaller />
        </AccessControl>
      );
    case ModalRouteHash.PropertySetting:
      return (
        <AccessControl permissions={modalPermissions.propertySetting}>
          <PropertyForm />
        </AccessControl>
      );
    case ModalRouteHash.InventoryForm:
      return (
        <AccessControl permissions={modalPermissions.inventory}>
          <InventoryForm />
        </AccessControl>
      );
    case ModalRouteHash.CutListFrom:
      return (
        <AccessControl permissions={modalPermissions.cutList}>
          <CutListForm />
        </AccessControl>
      );
    case ModalRouteHash.Calendar:
      return <Calendar />;
    case ModalRouteHash.TaskManager:
      return <TaskManager />;
    case ModalRouteHash.CNCCalc:
      return <CNCManualCalculatorForm />;
    case ModalRouteHash.SheetForm:
      return <SheetForm />;
    case ModalRouteHash.SendEmailForm:
      return <SendEmailForm />;
    case ModalRouteHash.FileViewer:
      return <FileViewer />;
    case ModalRouteHash.AssignFilesForm:
      return <AssignFiles />;
    case ModalRouteHash.CopyProducts:
      return <CopyProductsForm />;
    case ModalRouteHash.ProductList:
      return <ProductListForm />;
    case ModalRouteHash.AddProduct3D:
      return <AddProduct3D />;
    case ModalRouteHash.AddDesignToCart:
      return <AddToCartFrom />;
    case ModalRouteHash.SetGroupBuilder:
      return <SetModal />;
    case ModalRouteHash.AttachmentDocumentForm:
      return <AttachmentDocumentForm />;
    case ModalRouteHash.PurchaseOrderProd:
      return <POItemsForm />;
    case ModalRouteHash.NotesForm:
      return <NotesForm />;
    case ModalRouteHash.TaskForm:
      return <TaskForm />;
    case ModalRouteHash.TaskTemplateForm:
      return <TaskTemplatesForm />;
    case ModalRouteHash.Tutorial:
      return <TutorialForm />;
    case ModalRouteHash.CustomEventFrom:
      return <CustomEventForm />;
    default:
      return null;
  }
}

const ModalRouter: FunctionComponent = () => {
  const { hash } = useLocation();
  const modal = getModal(hash);

  return (
    <ErrorBoundary errorMessage={<ErrorModal />}>
      {modal}
    </ErrorBoundary>
  );
};

export default ModalRouter;
