import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { CreateQuoteHistorySateModel } from '../../Form/CreateQuote/state';
import { ProjectDropdownModel } from '../reducer';

export default function openCreateQuoteModal(push, project: ProjectDropdownModel) {
  return () => {
    const historyLocationState: CreateQuoteHistorySateModel = {
      createQuoteForm: {
        project: project.value,
      },
    };
    push(ModalRouteHash.CreateQuote, historyLocationState);
  };
}
