/* eslint-disable */
import Core from "../core";

export const floorplannerModes = {
  MOVE: 0,
  DRAW: 1,
  DELETE: 2,
};

export const zoomLevel = 100;

// grid parameters
const gridWidth = 1;
const gridColor = "#f1f1f1";

// room config
const roomColor = "#f9f9f9";

// wall config
const wallWidth = 5;
const wallWidthHover = 7;
const wallColor = "#dddddd";
const wallColorHover = "#008cba";
const edgeColor = "#888888";
const edgeColorHover = "#008cba";
const edgeWidth = 1;

const deleteColor = "#ff0000";

// corner config
const cornerRadius = 0;
const cornerRadiusHover = 7;
const cornerColor = "#cccccc";
const cornerColorHover = "#00ff00";

// item config
const itemBorderWidth = 1;
const itemBorderColor = "#444";
const itemFillColor = "#33333311";
const itemFillColorHover = "#0000ff22";
const itemLabelColor = "#000";

// ruler config
const rulerLengthTolerance = 0.01;

/**
 * The View to be used by a Floorplanner to render in/interact with.
 */
export default class FloorplannerView {
  /** The canvas element. */
  canvasElement;

  /** The 2D context. */
  context;

  /** */
  constructor(floorplan, viewmodel, canvas) {
    this.floorplan = floorplan;
    this.viewmodel = viewmodel;
    this.canvas = canvas;
    this.canvasElement = canvas;
    this.context = this.canvasElement.getContext("2d");

    const scope = this;
    window.addEventListener("resize", () => {
      scope.handleWindowResize();
    });
    this.handleWindowResize();
    document.addEventListener(Core.BP3D_EVENT_CONFIG_CHANGED, (e) => {
      const { detail } = e;
      if (!detail) return;
      if (detail.hasOwnProperty(Core.configDimensionVisible)) {
        this.draw();
      }
    });
  }

  /** */
  handleWindowResize = () => {
    this.viewmodel.resetOrigin();
    const canvasSel = this.canvas;
    const parent = canvasSel.parentNode;
    canvasSel.height = parent.clientHeight;
    canvasSel.width = parent.clientWidth;
    this.canvasElement.height = parent.clientHeight;
    this.canvasElement.width = parent.clientWidth;
    this.draw();
  };

  /** */
  draw = () => {
    this.context.clearRect(
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );

    this.context.fillStyle = "#d3d3d3";
    this.context.fillRect(
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );

    this.drawGrid();

    this.floorplan.getRooms().forEach((room) => {
      this.drawRoom(room);
    });

    this.floorplan.getWalls().forEach((wall) => {
      this.drawWall(wall);
    });

    this.floorplan.getCorners().forEach((corner) => {
      this.drawCorner(corner);
    });

    this.floorplan.getItems().forEach((item) => {
      this.drawItem(item);
      this.drawItemLabel(
        { x: item.position.x, y: item.position.z },
        item.metadata.name
      );
    });

    if (this.viewmodel.mode === floorplannerModes.DRAW) {
      this.drawTarget(
        this.viewmodel.targetX,
        this.viewmodel.targetY,
        this.viewmodel.lastNode
      );
    }

    this.floorplan.getWalls().forEach((wall) => {
      this.drawWallLabels(wall);
    });

    (() => {
      if (!Core.Configuration.getBooleanValue(Core.configDimensionVisible))
        return;
      let minX = +Infinity;
      let minY = +Infinity;
      const offset = 50;
      this.floorplan.getCorners().forEach((corner) => {
        if (corner.getX() < minX) minX = corner.getX();
        if (corner.getY() < minY) minY = corner.getY();
      });
      minX = this.viewmodel.convertX(minX) - offset;
      minY = this.viewmodel.convertY(minY) - offset;
      const rulerData = this.floorplan.calculateRulerData();
      rulerData.x.forEach((ruler) => {
        this.drawRuler(ruler.start, ruler.end, ruler.length, "x", minY);
      });
      rulerData.y.forEach((ruler) => {
        this.drawRuler(ruler.start, ruler.end, ruler.length, "y", minX);
      });
    })();
  };

  drawRuler(start, end, length, direction, offset) {
    if (length < rulerLengthTolerance) return;
    start = {
      x: this.viewmodel.convertX(start.x),
      y: this.viewmodel.convertY(start.y),
    };

    end = {
      x: this.viewmodel.convertX(end.x),
      y: this.viewmodel.convertY(end.y),
    };

    const rStart = { ...start };
    const rEnd = { ...end };
    if (direction === "x") {
      rStart.y = offset;
      rEnd.y = offset;
    } else if (direction === "y") {
      rStart.x = offset;
      rEnd.x = offset;
    }
    // render ruler lines
    (() => {
      [
        {
          p: [rStart, rEnd],
          dashed: false,
        },
        {
          p: [rStart, start],
          dashed: true,
        },
        {
          p: [end, rEnd],
          dashed: true,
        },
      ].forEach((l) => {
        const c = direction === "x" ? "#f00" : "#00f";
        this.drawLine(l.p[0].x, l.p[0].y, l.p[1].x, l.p[1].y, 1, c, l.dashed);
      });
    })();
    // render arrows
    (() => {
      const arrowSize = 15;
      const r = 4;
      let color = direction === "x" ? "#f00" : "#00f";

      const sPoints = [];
      sPoints.push(rStart);
      sPoints.push({
        x: direction === "x" ? rStart.x + arrowSize : rStart.x - arrowSize / r,
        y: direction === "x" ? rStart.y - arrowSize / r : rStart.y + arrowSize,
      });
      sPoints.push({
        x: direction === "x" ? rStart.x + arrowSize : rStart.x + arrowSize / r,
        y: direction === "x" ? rStart.y + arrowSize / r : rStart.y + arrowSize,
      });

      const ePoints = [];
      ePoints.push(rEnd);
      ePoints.push({
        x: direction === "x" ? rEnd.x - arrowSize : rEnd.x - arrowSize / r,
        y: direction === "x" ? rEnd.y - arrowSize / r : rEnd.y - arrowSize,
      });
      ePoints.push({
        x: direction === "x" ? rEnd.x - arrowSize : rEnd.x + arrowSize / r,
        y: direction === "x" ? rEnd.y + arrowSize / r : rEnd.y - arrowSize,
      });

      [sPoints, ePoints].forEach((points) => {
        let xArr = points.map((p) => p.x);
        let yArr = points.map((p) => p.y);
        this.drawPolygon(xArr, yArr, true, color, true, color, 1);
      });
    })();

    this.drawItemLabel(
      { x: (rStart.x + rEnd.x) / 2, y: (rStart.y + rEnd.y) / 2 },
      Core.Dimensioning.cmToMeasure(length * 100),
      20,
      false,
      direction === "y" ? -Math.PI / 2 : 0
    );
  }

  drawItem(item) {
    const rawPointsCollection = item.getSnapPoints();
    rawPointsCollection.forEach((hullPoints) => {
      ((points) => {
        const xArr = [];
        const yArr = [];
        points.forEach((point) => {
          xArr.push(this.viewmodel.convertX(point.x));
          yArr.push(this.viewmodel.convertY(point.y));
        });
        this.drawPolygon(
          xArr,
          yArr,
          true,
          item === this.viewmodel.activeItem
            ? itemFillColorHover
            : itemFillColor,
          true,
          itemBorderColor,
          itemBorderWidth
        );
      })(hullPoints);
    });
  }

  /** */
  drawItemLabel(pos, label, fontSize = 12, convert = true, rotation = 0) {
    this.context.font = `normal ${fontSize}px Arial`;
    this.context.fillStyle = itemLabelColor;
    this.context.textBaseline = "middle";
    this.context.textAlign = "center";
    this.context.strokeStyle = "#ffffff";
    this.context.lineWidth = 4;

    let x = convert ? this.viewmodel.convertX(pos.x) : pos.x;
    let y = convert ? this.viewmodel.convertY(pos.y) : pos.y;
    if (!rotation) {
      this.context.fillText(label, x, y);
    } else {
      this.context.save();
      this.context.translate(x, y);
      this.context.rotate(rotation);
      this.context.fillText(label, 0, 0);
      this.context.restore();
    }
  }

  /** */
  drawWallLabels(wall) {
    const start = { x: wall.start.x, y: wall.start.y };
    const end = { x: wall.end.x, y: wall.end.y };
    const pos = { x: (start.x + end.x) / 2, y: (start.y + end.y) / 2 };
    const length = Math.sqrt((start.x - end.x) ** 2 + (start.y - end.y) ** 2);
    this.drawEdgeLabel(pos, length);
    // // we'll just draw the shorter label... idk
    // if (wall.backEdge && wall.frontEdge) {
    //   if (wall.backEdge.interiorDistance() < wall.frontEdge.interiorDistance()) {
    //     this.drawEdgeLabel(wall.backEdge);
    //   } else {
    //     this.drawEdgeLabel(wall.frontEdge);
    //   }
    // } else if (wall.backEdge) {
    //   this.drawEdgeLabel(wall.backEdge);
    // } else if (wall.frontEdge) {
    //   this.drawEdgeLabel(wall.frontEdge);
    // }
  }

  /** */
  drawWall(wall) {
    const hover =
      wall === this.viewmodel.activeWall && !this.viewmodel.activeCorner;
    let color = wallColor;
    if (hover && this.viewmodel.mode === floorplannerModes.DELETE) {
      color = deleteColor;
    } else if (hover) {
      color = wallColorHover;
    }
    if (wall.frontEdge) {
      this.drawEdge(wall.frontEdge, hover);
    }
    if (wall.backEdge) {
      this.drawEdge(wall.backEdge, hover);
    }
    Array.isArray(wall.items) &&
      wall.items.forEach((item) => this.drawWallItem(item, wall));
  }

  drawWallItem(item, wall) {
    const corners = item.getCorners();

    const xArr = [];
    const yArr = [];

    corners.forEach((corner) => {
      xArr.push(this.viewmodel.convertX(corner.x));
      yArr.push(this.viewmodel.convertY(corner.y));
    });
    this.drawPolygon(xArr, yArr, true, "#fff", true, "#888", 1);
  }

  /** */
  drawEdgeLabel(pos, length) {
    // var pos = edge.interiorCenter();
    // var length = edge.interiorDistance();
    length *= zoomLevel;
    if (length < 60) {
      // dont draw labels on walls this short
      return;
    }
    this.context.font = "normal 12px Arial";
    this.context.fillStyle = "#000000";
    this.context.textBaseline = "middle";
    this.context.textAlign = "center";
    this.context.strokeStyle = "#ffffff";
    this.context.lineWidth = 4;

    this.context.strokeText(
      Core.Dimensioning.cmToMeasure(length),
      this.viewmodel.convertX(pos.x),
      this.viewmodel.convertY(pos.y)
    );
    this.context.fillText(
      Core.Dimensioning.cmToMeasure(length),
      this.viewmodel.convertX(pos.x),
      this.viewmodel.convertY(pos.y)
    );
  }

  /** */
  drawEdge(edge, hover) {
    let color = edgeColor;
    if (hover && this.viewmodel.mode === floorplannerModes.DELETE) {
      color = deleteColor;
    } else if (hover) {
      color = edgeColorHover;
    }
    const corners = edge.corners();

    const scope = this;
    this.drawPolygon(
      Core.Utils.map(corners, (corner) => {
        return scope.viewmodel.convertX(corner.x);
      }),
      Core.Utils.map(corners, (corner) => {
        return scope.viewmodel.convertY(corner.y);
      }),
      true,
      hover ? edgeColorHover : "#888",
      true,
      color,
      edgeWidth
    );
  }

  /** */
  drawRoom(room) {
    const scope = this;
    this.drawPolygon(
      Core.Utils.map(room.corners, (corner) => {
        return scope.viewmodel.convertX(corner.x);
      }),
      Core.Utils.map(room.corners, (corner) => {
        return scope.viewmodel.convertY(corner.y);
      }),
      true,
      roomColor
    );
  }

  /** */
  drawCorner(corner) {
    const hover = corner === this.viewmodel.activeCorner;
    let color = cornerColor;
    if (hover && this.viewmodel.mode === floorplannerModes.DELETE) {
      color = deleteColor;
    } else if (hover) {
      color = cornerColorHover;
    }
    this.drawCircle(
      this.viewmodel.convertX(corner.x),
      this.viewmodel.convertY(corner.y),
      hover ? cornerRadiusHover : cornerRadius,
      color
    );
  }

  /** */
  drawTarget(x, y, lastNode) {
    this.drawCircle(
      this.viewmodel.convertX(x),
      this.viewmodel.convertY(y),
      cornerRadiusHover,
      cornerColorHover
    );
    if (this.viewmodel.lastNode) {
      this.drawLine(
        this.viewmodel.convertX(lastNode.x),
        this.viewmodel.convertY(lastNode.y),
        this.viewmodel.convertX(x),
        this.viewmodel.convertY(y),
        wallWidthHover,
        wallColorHover
      );
    }
  }

  /** */
  drawLine(
    startX,
    startY,
    endX,
    endY,
    width = 1,
    color = "#000",
    dashed = false
  ) {
    // width is an integer
    // color is a hex string, i.e. #ff0000
    this.context.beginPath();
    this.context.setLineDash([]);
    if (dashed) this.context.setLineDash([3, 6]);
    this.context.moveTo(startX, startY);
    this.context.lineTo(endX, endY);
    this.context.lineWidth = width;
    this.context.strokeStyle = color;
    this.context.stroke();
  }

  /** */
  drawPolygon(xArr, yArr, fill, fillColor, stroke, strokeColor, strokeWidth) {
    // fillColor is a hex string, i.e. #ff0000
    fill = fill || false;
    stroke = stroke || false;
    this.context.beginPath();
    this.context.moveTo(xArr[0], yArr[0]);
    for (let i = 1; i < xArr.length; i++) {
      this.context.lineTo(xArr[i], yArr[i]);
    }
    this.context.closePath();
    if (fill) {
      this.context.fillStyle = fillColor;
      this.context.fill();
    }
    if (stroke) {
      this.context.lineWidth = strokeWidth;
      this.context.strokeStyle = strokeColor;
      this.context.stroke();
    }
  }

  /** */
  drawCircle(centerX, centerY, radius, fillColor) {
    this.context.beginPath();
    this.context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    this.context.fillStyle = fillColor;
    this.context.fill();
  }

  calculateGridSpacing() {
    let scale = 100;
    const { pixelsPerCm } = this.viewmodel;
    if (pixelsPerCm < 0.5) scale = 100;
    else if (pixelsPerCm < 1) scale = 50;
    else if (pixelsPerCm < 2) scale = 25;
    else if (pixelsPerCm < 4) scale = 12.5;
    const gridSpacing = (pixelsPerCm || 0.5) * scale;
    return gridSpacing;
  }

  /** returns n where -gridSize/2 < n <= gridSize/2  */
  calculateGridOffset(n) {
    const gridSpacing = this.calculateGridSpacing();
    if (n >= 0) {
      return ((n + gridSpacing / 2) % gridSpacing) - gridSpacing / 2;
    }
    return ((n - gridSpacing / 2) % gridSpacing) + gridSpacing / 2;
  }

  /** */
  drawGrid() {
    const offsetX = this.calculateGridOffset(-this.viewmodel.originX);
    const offsetY = this.calculateGridOffset(-this.viewmodel.originY);
    const { width } = this.canvasElement;
    const { height } = this.canvasElement;
    const gridSpacing = this.calculateGridSpacing();

    for (let x = 0; x <= width / gridSpacing; x++) {
      this.drawLine(
        gridSpacing * x + offsetX,
        0,
        gridSpacing * x + offsetX,
        height,
        gridWidth,
        gridColor
      );
    }
    for (let y = 0; y <= height / gridSpacing; y++) {
      this.drawLine(
        0,
        gridSpacing * y + offsetY,
        width,
        gridSpacing * y + offsetY,
        gridWidth,
        gridColor
      );
    }
  }
}
