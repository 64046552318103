import { useEffect, useMemo } from 'react';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import taskTemplatesApi from 'api/task-template';
import { validationRules } from './validations';

const defaultTaskTemplate = {
  details: '',
  endingStage: undefined,
  onetime: false,
  priority: '0',
  startingStage: undefined,
  taskName: undefined,
  conditional: undefined,
};

const getPayload = (taskTemplate) => ({
  ...taskTemplate,
  taskName: taskTemplate.taskName.value,
  startingStage: taskTemplate.startingStage.value,
  endingStage: taskTemplate.endingStage.value,
  conditional: taskTemplate.conditional.value,
});

const getFormData = (mappedConditionalOptions) => {
  return (taskTemplate) => {
    const conditional = taskTemplate
      ? mappedConditionalOptions.find(e => e.value === taskTemplate.conditional)
      : undefined;
    return taskTemplate ? {
      ...taskTemplate,
      conditional,
      taskName: { value: taskTemplate.taskName.id, label: taskTemplate.taskName.name },
      startingStage: { value: taskTemplate.startingStage.id, label: taskTemplate.startingStage.status },
      endingStage: { value: taskTemplate.endingStage.id, label: taskTemplate.endingStage.status },
    } : defaultTaskTemplate;
  };
};

export default function useTaskTemplateFormState(taskTemplateId: number, conditionalOptions: any[]) {
  const mappedConditionalOptions = useMemo(
    () => conditionalOptions.map(e => ({ value: e.value, label: e.display_name })), [conditionalOptions]
  );

  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: taskTemplatesApi,
    eventChannel: EventChannelList.TASK_TEMPLATE_CHANGED,
    param: taskTemplateId,
    getPayload,
    getFormData: getFormData(mappedConditionalOptions),
    validationRules
  }), [taskTemplateId, mappedConditionalOptions]);

  const {
    state: {
      errorFetchingEntity,
      ...state
    },
    actions
  } = useFormState(useFormStateParams);

  useEffect(() => {
    if (errorFetchingEntity) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingEntity,
      });
    }
  }, [errorFetchingEntity]);

  return {
    state: {
      ...state,
      mappedConditionalOptions
    },
    actions,
  };
}
