import React, { FunctionComponent } from 'react';
import { OrderProductModel } from 'models/order';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import Divider from 'components/base-components/Divider';
import RenderIf from 'components/base-components/RenderIf';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import Form from 'components/base-components/Form';
import Spinner from 'components/base-components/Spinner';
import Button from 'components/base-components/Button';
import InputRow from 'components/base-components/InputRow';
import NumberInput from 'components/base-components/NumberInput';
import { Text, Title } from 'components/base-components/Typography';
import ProductsSection from './ProductsSection';
import { addressRules } from './state';
import { FormAddressModel } from './state/reducer';

interface Props {
  isIncompleteOrder: boolean;
  showProductsSection: boolean;
  showShipmentSection: boolean;
  productsToFill: OrderProductModel[];
  fetchingShipmentData: boolean;
  missingOptions: { [productId: string]: string[] };
  shipmentFields: {
    showProjectSelect: boolean;
    showZoneSelect: boolean;
    showAddressForm: boolean;
    showDQLink: boolean;
  };
  shipmentFormData: {
    project?: DropdownOptionModel;
    zone?: DropdownOptionModel;
    address?: FormAddressModel;
  };
  projects: DropdownOptionModel[];
  shippingZones: DropdownOptionModel[];
  provinces: DropdownOptionModel[];
  countries: DropdownOptionModel[];
  selectedCountry: DropdownOptionModel;
  fetchingHash: boolean;
  productErrors: any;
  addressErrors: any;
  projectError: string;
  zoneError: string;
  handleUpdateProduct: (productId: number, options) => void;
  setProject: (event) => void;
  setZone: (event) => void;
  setAddressInfo: (info) => void;
  handleCountryChange: (country) => void;
  openShipmentDQ: () => void;
  setAddressErrors: (errors) => void;
}

const Content: FunctionComponent<Props> = (props) => {
  const {
    isIncompleteOrder,
    showProductsSection,
    showShipmentSection,
    missingOptions,
    productsToFill,
    fetchingShipmentData,
    shipmentFields: {
      showProjectSelect,
      showZoneSelect,
      showAddressForm,
      showDQLink,
    },
    shipmentFormData: {
      project,
      zone,
      address,
    },
    projects,
    shippingZones,
    provinces,
    countries,
    selectedCountry,
    fetchingHash,
    productErrors,
    addressErrors,
    projectError,
    zoneError,
    handleUpdateProduct,
    setProject,
    setZone,
    setAddressInfo,
    handleCountryChange,
    setAddressErrors,
    openShipmentDQ,
  } = props;

  if (isIncompleteOrder) {
    const showShipmentForm = (
      showShipmentSection && (showZoneSelect || showProjectSelect)
    );

    const showReadyToConvertText = (
      !showProductsSection && !showShipmentSection && !showDQLink
    );

    if (showReadyToConvertText) {
      return (
        <span>
          The quote is now ready to be converted
        </span>
      );
    }

    return (
      <div>
        <RenderIf isTrue={showProductsSection || showShipmentForm}>
          <Title level={5}>
            To convert the quote this fields must be filled
          </Title>
        </RenderIf>
        <RenderIf isTrue={showProductsSection}>
          <Divider>
            Product Options
          </Divider>
          <ProductsSection
            products={productsToFill}
            errors={productErrors}
            onUpdateProduct={handleUpdateProduct}
            missingOptions={missingOptions}
          />
        </RenderIf>
        <RenderIf isTrue={showShipmentForm}>
          <Divider>Shipping</Divider>
          <RenderIf isTrue={!fetchingShipmentData} fallback={<Spinner />}>
            <RenderIf isTrue={showProjectSelect}>
              <Select
                id="project"
                name="project"
                label="Project"
                value={project}
                options={projects}
                error={projectError}
                onChange={setProject}
              />
            </RenderIf>
            <RenderIf isTrue={showZoneSelect}>
              <Select
                id="zone"
                name="zone"
                label="Shipping Zone"
                value={zone}
                options={shippingZones}
                error={zoneError}
                onChange={setZone}
              />
            </RenderIf>
            <RenderIf isTrue={showAddressForm}>
              <Form
                state={address}
                onChange={setAddressInfo}
                errors={addressErrors}
                onError={setAddressErrors}
                rules={addressRules}
              >
                <Field name="locationName" label="Company" />
                <InputRow>
                  <Field
                    component={NumberInput}
                    name="streetNumber"
                    label="Street Number"
                    className="questionnaire__address__street-number"
                  />
                  <Field name="streetName" label="Street Name" />
                </InputRow>
                <Field name="unitNumber" label="Unit" />
                <InputRow>
                  <Field name="city" label="City" mR />
                  <Field name="postalCode" label="Post Code" />
                </InputRow>
                <Select
                  id="country"
                  name="country"
                  label="Country"
                  options={countries}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                />
                <Field
                  name="province"
                  label="Province"
                  component={Select}
                  options={provinces}
                />
              </Form>
            </RenderIf>
          </RenderIf>
        </RenderIf>
        <RenderIf isTrue={showShipmentSection && showDQLink}>
          <div className="cto_content__dq-section">
            <Text>The Delivery Questionnaire needs to be filled</Text>
            <Button
              label="Click here to see the questionnaire"
              loading={fetchingHash}
              onClick={openShipmentDQ}
              mT
            />
          </div>
        </RenderIf>
      </div>
    );
  }

  return (
    <span>
      Are you sure you want to convert this quote to an order?
      This action is irreversible.
    </span>
  );
};

export default Content;
