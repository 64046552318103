export const columnOptions = [
  { value: 'id', label: 'Quote' },
  { value: 'rep_owner', label: 'Sales Rep' },
  { value: 'job_title', label: 'Job Title' },
  { value: 'company', label: 'Company' },
  { value: 'client', label: 'Customer' },
  { value: 'created_date', label: 'Date Added' },
  { value: 'modified_date', label: 'Date Modified' },
  { value: 'totalWithTax', label: 'Total' },
  { value: 'isconverted', label: 'Status' },
];

export const breakpoints = {
  id: 0,
  rep_owner: 1441,
  job_title: 0,
  company: 1024,
  client: 1360,
  created_date: 1681,
  modified_date: 1681,
  totalWithTax: 0,
  isconverted: 0,
};

export const parentColumns = {};
