import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { NotesHistorySateModel } from '../../Notes';

export default function openNotesModal(push) {
  return (order: any) => {
    push(ModalRouteHash.NotesForm, {
      notesForm: {
        orderId: order.id,
        jobTitle: order.jobName || order.jobTitle,
      }
    } as NotesHistorySateModel);
  };
}
