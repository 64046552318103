import { ApiModel, get } from './base';
import { CountryModel } from '../models/country';

const { REACT_APP_SHIPMENT_API } = process.env;
const countriesEndpoint = `${REACT_APP_SHIPMENT_API}/countries/`;

const countriesApi: ApiModel<CountryModel> = {
  get(url) {
    return get(url);
  },
  list(params?) {
    return get(countriesEndpoint, params);
  }
};

export default countriesApi;
