import { SheetModel } from 'models/sheet';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_MATERIALS_API } = process.env;
const endpoint = `${REACT_APP_MATERIALS_API}/sheet-settings/`;

const sheetApi: ApiModel<SheetModel> = {
  ...getApi<SheetModel>(endpoint, ApiContentType.MULTIPART, true),
};

export default sheetApi;
