import { AxiosResponse } from 'axios';
import { OrderModel } from 'models/order';
import { post } from './base';

const { REACT_APP_MATERIALS_API } = process.env;
const endpoint = `${REACT_APP_MATERIALS_API}/cnccalc`;

interface ManualCutListPayload {
  products: {
    model: string;
    quantity: number;
  }[];
}

export interface ManualCutListResponse {
  xlsx: string;
  cutList: {
    model: string;
    parts: {
      'WORKPIECE NAME': string;
      'TYPE': string;
      'DESCRIPTION': string;
      'QTY': string;
      'X QTY': string;
      'X': string;
      'Y': string;
      'Z': string;
      'MATERIAL': string;
      'GRAIN': string;
      'FILE': string;
    }[];
    quantity: number;
  }[];
}

interface CutListPayload {
  orders: OrderModel[];
}

export interface CutListResponse {
  xlsx: string;
  cutList: {
    orderId: string;
    projectName: string;
    products: {
      model: string;
      parts: {
        'WORKPIECE NAME': string;
        'TYPE': string;
        'DESCRIPTION': string;
        'QTY': string;
        'X QTY': string;
        'X': string;
        'Y': string;
        'Z': string;
        'MATERIAL': string;
        'GRAIN': string;
        'FILE': string;
      }[];
      quantity: number;
    }[];
  };
}

const cncCalcApi = {
  calculateManually(
    payload: ManualCutListPayload
  ): Promise<AxiosResponse<ManualCutListResponse>> {
    return post(`${endpoint}/manual_cutsheet/`, payload);
  },
  calculate(
    payload: CutListPayload
  ): Promise<AxiosResponse<CutListResponse>> {
    return post(`${endpoint}/excel_cutsheet/`, payload);
  }
};

export default cncCalcApi;
