import productionManagerApi from 'api/production-manger';

export const helpDescription = 'this view have join data and actions from different views';

export const columnOptions = [
  { value: 'id', label: 'Order' },
  { value: 'company', label: 'Company' },
  { value: 'job_title', label: 'Job Title' },
  { value: 'rep_owner', label: 'Rep. Owner' },
  { value: 'ordered', label: 'Ordered' },
  { value: 'received', label: 'Received' },
  { value: 'installer', label: 'Installer' },
  { value: 'inst_date', label: 'Inst. Date' },
  { value: 'ready_date', label: 'Ready Date' },
  { value: 'cnc_percentage', label: 'Cnc Percentage' },
  { value: 'fabrication_percentage', label: 'Fabrication Percentage' },
  { value: 'production_status', label: 'Production Status' },
];

export const breakpoints = {
  job_info: 0,
  id: 0,
  company: 0,
  rep_owner: 0,
  job_title: 0,
  materials: 1915,
  ordered: 1915,
  received: 1915,
  shipping: 850,
  installer: 1915,
  inst_date: 1915,
  ready_date: 850,
  cnc_percentage: 1426,
  fabrication_percentage: 1522,
  production_status: 1665,
};

export const parentColumns = {
  job_info: ['id', 'company', 'rep_owner', 'job_title'],
  materials: ['ordered', 'received'],
  shipping: ['installer', 'ready_date', 'inst_date'],
};

export const orderTableStateParams = {
  api: productionManagerApi,
  defaultSortField: '-id',
};

export const productionStatusOptions = [
  { value: 'Ready', label: 'Ready' },
  { value: 'In Production', label: 'In Production' },
  { value: 'Not Started', label: 'Not Started' },
];
