import { AxiosResponse } from 'axios';
import { resolveApiErrorMessage } from 'api/base';
import quoteApi from 'api/quote';
import { QuoteModel } from 'models/quote';
import notification, { NotificationType } from 'helpers/notification';
import { SpecialInstructionsFormActions } from '../reducer';
import getPayload from './get-payload';

export function editSpecialInstructions(
  dispatch,
  formData: {specialInstructions: string},
  quote: QuoteModel,
  updateDataQuoteManually
) {
  return () => {
    const quotePayload = getPayload(quote, formData);
    dispatch({ type: SpecialInstructionsFormActions.START_EDITING });

    const onSuccess = (response: AxiosResponse<QuoteModel>) => {
      const { data } = response;
      updateDataQuoteManually({
        ...quote,
        specialInstructions: data.specialInstructions
      });
      const message = 'Instructions Successfully Edited';
      notification({ type: NotificationType.SUCCESS, message });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      dispatch({
        type: SpecialInstructionsFormActions.SET_FORM_VALUE,
        payload: { specialInstructions: quote.specialInstructions }
      });
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: SpecialInstructionsFormActions.FINISH_EDITING });
    };

    return quoteApi.update(quotePayload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
