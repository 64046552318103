import { Breakpoints, ParentColumns } from '../reducer';

interface DataStorage {
  width: number;
  hiddenColumns: string[];
}

function hiddenColumn(
  width: number,
  localStorageKey: string,
  parentColumns: ParentColumns,
  breakpoints: Breakpoints
) {
  const parents = Object.keys(parentColumns);
  const hiddenColumns = Object.keys(breakpoints)
    .filter(column => (!parents.includes(column) && breakpoints[column] > width));
  localStorage.removeItem(localStorageKey);
  localStorage.setItem(localStorageKey, JSON.stringify({ width, hiddenColumns }));
  return hiddenColumns;
}

export default function getHiddenColumn(
  localStorageKey: string,
  breakpoints: Breakpoints,
  parentColumns: ParentColumns
) {
  const projectCoordinatorData = localStorage.getItem(localStorageKey);
  const screenWidth = window?.innerWidth;

  if (projectCoordinatorData) {
    const { hiddenColumns, width } = JSON.parse(projectCoordinatorData) as DataStorage;

    if (width === screenWidth) {
      return hiddenColumns;
    }
    return hiddenColumn(screenWidth, localStorageKey, parentColumns, breakpoints);
  }
  return hiddenColumn(screenWidth, localStorageKey, parentColumns, breakpoints);
}
