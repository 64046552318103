/* eslint-disable */
import Core from "../core";

const cornerTolerance = 0.2;

/**
 * Corners are used to define Walls.
 */
export default class Corner {
  /** Array of start walls. */
  wallStarts = [];

  /** Array of end walls. */
  wallEnds = [];

  /** Callbacks to be fired on movement. */
  moved_callbacks = [];

  /** Callbacks to be fired on removal. */
  deleted_callbacks = [];

  /** Callbacks to be fired in case of action. */
  action_callbacks = [];

  locked = false;

  /** Constructs a corner.
   * @param floorplan The associated floorplan.
   * @param x X coordinate.
   * @param y Y coordinate.
   * @param id An optional unique id. If not set, created internally.
   */
  constructor(floorplan, x, y, id) {
    this.floorplan = floorplan;
    this.x = x;
    this.y = y;
    this.id = id || Core.Utils.guid();
  }

  /** Add function to moved callbacks.
   * @param func The function to be added.
   */
  fireOnMove(func) {
    this.moved_callbacks.push(func);
  }

  /** Add function to deleted callbacks.
   * @param func The function to be added.
   */
  fireOnDelete(func) {
    this.deleted_callbacks.push(func);
  }

  /** Add function to action callbacks.
   * @param func The function to be added.
   */
  fireOnAction(func) {
    this.action_callbacks.push(func);
  }

  /**
   * @returns
   * @deprecated
   */
  getX() {
    return this.x;
  }

  /**
   * @returns
   * @deprecated
   */
  getY() {
    return this.y;
  }

  /**
   *
   */
  snapToAxis(tolerance = 0.25) {
    // this.x = parseInt(this.x / tolerance, 10) * tolerance;
    // this.y = parseInt(this.y / tolerance, 10) * tolerance;

    // try to snap this corner to an axis
    const snapped = {
      x: false,
      y: false,
    };

    const scope = this;

    this.adjacentCorners().forEach((corner) => {
      if (Math.abs(corner.x - scope.x) < tolerance) {
        scope.x = corner.x;
        snapped.x = true;
      }
      if (Math.abs(corner.y - scope.y) < tolerance) {
        scope.y = corner.y;
        snapped.y = true;
      }
    });
    return snapped;
  }

  /** Moves corner relatively to new position.
   * @param dx The delta x.
   * @param dy The delta y.
   */
  relativeMove(dx, dy) {
    if (this.locked) return;
    this.move(this.x + dx, this.y + dy);
  }

  fireAction(action) {
    // this.action_callbacks.fire(action);
    this.action_callbacks.forEach(
      (cb) => typeof cb === "function" && cb(action)
    );
  }

  /** Remove callback. Fires the delete callbacks. */
  remove() {
    // this.deleted_callbacks.fire(this);
    this.deleted_callbacks.forEach(
      (cb) => typeof cb === "function" && cb(this)
    );
  }

  /** Removes all walls. */
  removeAll() {
    for (var i = 0; i < this.wallStarts.length; i++) {
      this.wallStarts[i].remove();
    }
    for (i = 0; i < this.wallEnds.length; i++) {
      this.wallEnds[i].remove();
    }
    this.remove();
  }

  /** Moves corner to new position.
   * @param newX The new x position.
   * @param newY The new y position.
   */
  move(newX, newY, willSnap = true) {
    if (this.locked) return;
    const prevX = this.x;
    const prevY = this.y;
    this.x = newX;
    this.y = newY;
    this.mergeWithIntersected();

    willSnap && this.snapToAxis();
    // this.moved_callbacks.fire(this.x, this.y);
    this.moved_callbacks.forEach(
      (cb) => typeof cb === "function" && cb(this.x, this.y, prevX, prevY)
    );

    this.wallStarts.forEach((wall) => {
      wall.fireMoved();
    });

    this.wallEnds.forEach((wall) => {
      wall.fireMoved();
    });
  }

  /** Gets the adjacent corners.
   * @returns Array of corners.
   */
  adjacentCorners() {
    const retArray = [];
    for (var i = 0; i < this.wallStarts.length; i++) {
      retArray.push(this.wallStarts[i].getEnd());
    }
    for (i = 0; i < this.wallEnds.length; i++) {
      retArray.push(this.wallEnds[i].getStart());
    }
    return retArray;
  }

  /** Checks if a wall is connected.
   * @param wall A wall.
   * @returns True in case of connection.
   */
  isWallConnected(wall) {
    for (var i = 0; i < this.wallStarts.length; i++) {
      if (this.wallStarts[i] === wall) {
        return true;
      }
    }
    for (i = 0; i < this.wallEnds.length; i++) {
      if (this.wallEnds[i] === wall) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   */
  distanceFrom(x, y) {
    const distance = Core.Utils.distance(x, y, this.x, this.y);
    // console.log('x,y ' + x + ',' + y + ' to ' + this.getX() + ',' + this.getY() + ' is ' + distance);
    return distance;
  }

  /** Gets the distance from a wall.
   * @param wall A wall.
   * @returns The distance.
   */
  distanceFromWall(wall) {
    return wall.distanceFrom(this.x, this.y);
  }

  /** Gets the distance from a corner.
   * @param corner A corner.
   * @returns The distance.
   */
  distanceFromCorner(corner) {
    return this.distanceFrom(corner.x, corner.y);
  }

  /** Detaches a wall.
   * @param wall A wall.
   */
  detachWall(wall) {
    Core.Utils.removeValue(this.wallStarts, wall);
    Core.Utils.removeValue(this.wallEnds, wall);
    if (this.wallStarts.length === 0 && this.wallEnds.length === 0) {
      this.remove();
    }
  }

  /** Attaches a start wall.
   * @param wall A wall.
   */
  attachStart(wall) {
    this.wallStarts.push(wall);
  }

  /** Attaches an end wall.
   * @param wall A wall.
   */
  attachEnd(wall) {
    this.wallEnds.push(wall);
  }

  /** Get wall to corner.
   * @param corner A corner.
   * @return The associated wall or null.
   */
  wallTo(corner) {
    for (let i = 0; i < this.wallStarts.length; i++) {
      if (this.wallStarts[i].getEnd() === corner) {
        return this.wallStarts[i];
      }
    }
    return null;
  }

  /** Get wall from corner.
   * @param corner A corner.
   * @return The associated wall or null.
   */
  wallFrom(corner) {
    for (let i = 0; i < this.wallEnds.length; i++) {
      if (this.wallEnds[i].getStart() === corner) {
        return this.wallEnds[i];
      }
    }
    return null;
  }

  /** Get wall to or from corner.
   * @param corner A corner.
   * @return The associated wall or null.
   */
  wallToOrFrom(corner) {
    return this.wallTo(corner) || this.wallFrom(corner);
  }

  /**
   *
   */
  combineWithCorner(corner) {
    // update position to other corner's
    this.x = corner.x;
    this.y = corner.y;
    // absorb the other corner's wallStarts and wallEnds
    for (var i = corner.wallStarts.length - 1; i >= 0; i--) {
      corner.wallStarts[i].setStart(this);
    }
    for (i = corner.wallEnds.length - 1; i >= 0; i--) {
      corner.wallEnds[i].setEnd(this);
    }
    // delete the other corner
    corner.removeAll();
    this.removeDuplicateWalls();
    this.floorplan.update();
  }

  mergeWithIntersected() {
    // check corners
    for (var i = 0; i < this.floorplan.getCorners().length; i++) {
      const corner = this.floorplan.getCorners()[i];
      if (
        this.distanceFromCorner(corner) < cornerTolerance &&
        corner !== this
      ) {
        this.combineWithCorner(corner);
        return true;
      }
    }
    // check walls
    for (i = 0; i < this.floorplan.getWalls().length; i++) {
      const wall = this.floorplan.getWalls()[i];
      if (
        this.distanceFromWall(wall) < cornerTolerance &&
        !this.isWallConnected(wall)
      ) {
        // update position to be on wall
        const intersection = Core.Utils.closestPointOnLine(
          this.x,
          this.y,
          wall.getStart().x,
          wall.getStart().y,
          wall.getEnd().x,
          wall.getEnd().y
        );
        this.x = intersection.x;
        this.y = intersection.y;
        // merge this corner into wall by breaking wall into two parts
        this.floorplan.newWall(this, wall.getEnd());
        wall.setEnd(this);
        this.floorplan.update();
        return true;
      }
    }
    return false;
  }

  /** Ensure we do not have duplicate walls (i.e. same start and end points) */
  removeDuplicateWalls() {
    // delete the wall between these corners, if it exists
    const wallEndpoints = {};
    const wallStartpoints = {};
    for (var i = this.wallStarts.length - 1; i >= 0; i--) {
      if (this.wallStarts[i].getEnd() === this) {
        // remove zero length wall
        this.wallStarts[i].remove();
      } else if (this.wallStarts[i].getEnd().id in wallEndpoints) {
        // remove duplicated wall
        this.wallStarts[i].remove();
      } else {
        wallEndpoints[this.wallStarts[i].getEnd().id] = true;
      }
    }
    for (i = this.wallEnds.length - 1; i >= 0; i--) {
      if (this.wallEnds[i].getStart() === this) {
        // removed zero length wall
        this.wallEnds[i].remove();
      } else if (this.wallEnds[i].getStart().id in wallStartpoints) {
        // removed duplicated wall
        this.wallEnds[i].remove();
      } else {
        wallStartpoints[this.wallEnds[i].getStart().id] = true;
      }
    }
  }

  setLocked(locked) {
    this.locked = locked;
  }
}
