import { TermModel } from 'models/term';

const defaultTerm: TermModel = {
  termsName: '',
  termsDetails: '',
  days: 0,
  onOrderConversion: 0,
  onProductionCommencing: 0,
  onLeavingForDelivery: 0,
  onInstallComplete: 0,
  onOrderCompletion: 0,
};

export default function getFormData(term: TermModel): TermModel {
  return term || defaultTerm;
}
