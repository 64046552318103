import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Blueprint3D from 'lib/Blueprint3D';
import { Units } from 'models/builder';
import RenderIf from 'components/base-components/RenderIf';
import { BuilderFormModel, TextureModel } from '../state';
import './styles.scss';

interface Props {
  blockUI: boolean;
  viewMode: string;
  sceneLocked?: boolean;
  showDimensions?: boolean;
  snapEnabled?: boolean;
  xRayEnabled?: boolean;
  models: BuilderFormModel[];
  onDragDropFinished?: (data: any) => void;
  onSelect: (productId: string, rawItem?: any) => void;
  onDelete: (productId: string) => void;
  readOnly?: boolean;
  floors: TextureModel[];
  walls: TextureModel[];
  measureUnit: Units;
  onSelectWall: (wall) => void;
  onSelectFloor: (floor) => void;
  onUnitChange: (unit) => void;
  openProductListFrom: () => void;
}

const stopPropagation = (event) => event.stopPropagation();

const Stage: FunctionComponent<Props> = (props) => {
  const {
    models,
    viewMode,
    sceneLocked,
    showDimensions,
    snapEnabled,
    xRayEnabled,
    onDragDropFinished,
    onSelect,
    onDelete,
    readOnly,
    blockUI,
    floors,
    walls,
    measureUnit,
    onSelectWall,
    onSelectFloor,
    onUnitChange,
    openProductListFrom
  } = props;
  const [addedModels, setAddedModels] = useState([]);
  const refBp3d = useRef(undefined);

  useEffect(() => {
    const newModels = models.filter(
      (model) => !addedModels.some((added) => added.id === model.id)
    );
    const setItem = [];
    newModels.forEach((model) => {
      if (!model.loaded) {
        if (model.isFromSet) {
          setItem.push(model);
        } else {
          refBp3d.current.addItem(model);
        }
      }
    });
    if (setItem.length > 0) {
      refBp3d.current.addSetItem(setItem);
    }
    const nextAdded = addedModels.concat(newModels);
    setAddedModels(nextAdded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  return (
    <>
      <Blueprint3D
        ref={refBp3d}
        viewMode={viewMode}
        sceneLocked={sceneLocked}
        showDimensions={showDimensions}
        snapEnabled={snapEnabled}
        xRayEnabled={xRayEnabled}
        defaultJson={null}
        onDragDropFinished={onDragDropFinished}
        onItemSelected={onSelect}
        onDelete={onDelete}
        readOnly={readOnly}
        floors={floors}
        walls={walls}
        measureUnit={measureUnit}
        onSelectWall={onSelectWall}
        onSelectFloor={onSelectFloor}
        onUnitChange={onUnitChange}
        openProductListFrom={openProductListFrom}
      />
      <RenderIf isTrue={blockUI}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="builder__loading-screen" onClick={stopPropagation} />
      </RenderIf>
    </>
  );
};

export default Stage;
