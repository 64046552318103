import React, { FunctionComponent } from 'react';
import Button from 'components/base-components/Button';

interface Props {
  files: File[] | object[];
  onUnSelectFile: (fileIndex: any) => void;
  byExtraField?: string;
}

const Files: FunctionComponent<Props> = (props): any => {
  const { files, onUnSelectFile, byExtraField } = props;

  if (files && Array.isArray(files)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return files.map((file, index) => {
      const { name } = file;

      const handleOnClick = () => { onUnSelectFile(byExtraField ? file[byExtraField] : index); };

      return (
        <li
          className="upload__file"
          key={`${name}-${index}`}
        >
          <span>{name}</span>
          <Button className="upload__file__remove-button" type="text" icon="fa fa-trash" onClick={handleOnClick} />
        </li>
      );
    });
  }

  return null;
};

export default Files;
