import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { FlowPlannerModel } from 'models/flow-planner';
import { AddToCartHistorySateModel } from '../../AddToCartForm';
import { BuilderFormModel } from '../types';

export default function openAddToCartForm(
  push, flowPlanner: FlowPlannerModel, products: BuilderFormModel[], productPrice
) {
  return () => {
    const historyLocationState: AddToCartHistorySateModel = {
      addToCartForm: {
        flowPlanner,
        productPrice,
        products
      },
    };
    push(ModalRouteHash.AddDesignToCart, historyLocationState);
  };
}
