import { ProformaInvoiceModel } from 'models/proforma-invoice';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { EmailSender } from 'components/forms/SendEmail/state';

export default function openSendProformaInvoiceEmailModal(push) {
  return (proformaInvoice: ProformaInvoiceModel) => {
    const { id } = proformaInvoice;

    push(
      ModalRouteHash.SendEmailForm,
      {
        sendEmailForm: {
          id,
          from: EmailSender.ProformaInvoice,
          title: 'Send Proforma Invoice by email',
        },
      },
    );
  };
}
