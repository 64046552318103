import React, { FunctionComponent, useRef } from 'react';
import Button from 'components/base-components/Button';
import classnames from 'classnames';
import './styles.scss';
import Files from './Files';
import InputError from '../InputError';

interface Props {
  id?: string;
  accept?: string;
  onChange: (event) => void;
  className?: string;
  mR?: boolean;
  nMB?: boolean;
  label?: string;
  multiple?: boolean;
  value: File[];
  loading?: boolean;
  disabled?: boolean;
  error?: string;
}

const Upload: FunctionComponent<Props> = (props) => {
  const {
    id,
    accept,
    onChange,
    mR,
    nMB,
    className,
    label,
    multiple,
    value,
    loading,
    disabled,
    error,
  } = props;
  const inputFilesRef = useRef(undefined);

  const wrapperClassNames = classnames('upload__wrapper', { mR, nMB }, className);

  const handleAddFileClick = (event) => {
    event.preventDefault();
    inputFilesRef.current.click();
  };

  const handleSelectFiles = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      let newFiles: File[] = [files[0]];
      if (multiple) {
        newFiles = value || [];
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          newFiles.push(file);
        }
      }
      onChange({ target: { files: newFiles } });
    }
  };

  const handleUnSelectFiles = (fileIndex: number) => {
    const newFiles = value.filter((_, index) => fileIndex !== index);
    onChange({ target: { files: newFiles } });
  };

  return (
    <div className={wrapperClassNames}>
      <input
        id={id}
        style={{ display: 'none' }}
        type="file"
        ref={inputFilesRef}
        multiple={multiple}
        onChange={handleSelectFiles}
        accept={accept}
      />
      <Button
        label={label}
        onClick={handleAddFileClick}
        icon={<i className="fa fa-file-upload" />}
        type="default"
        loading={loading}
        disabled={disabled}
      />
      <InputError error={error} />
      <ul className="upload__files">
        <Files files={value} onUnSelectFile={handleUnSelectFiles} />
      </ul>
    </div>
  );
};

Upload.defaultProps = {
  label: 'Choose file',
};

export default Upload;
