import questionnaireApi from 'api/questionnaire';
import { getAddressPayload } from './get-payload';
import { FormAddressModel } from '../reducer';

export default async function saveAddressInfo(token, addressInfo: FormAddressModel) {
  const payload = getAddressPayload(addressInfo);
  const action = addressInfo.id
    ? questionnaireApi.updateAddress
    : questionnaireApi.storeAddress;

  const response = await action(token, payload);
  return response ? response.data.id : payload.id;
}
