import { ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import { PermissionModel } from 'models/permission';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/permissions/`;

const permissionApi: ApiModel<PermissionModel> = getApi<PermissionModel>(endpoint);

export default permissionApi;
