import { ShipmentContactModel } from 'models/shipment';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_SHIPMENT_API } = process.env;

const endpoint = `${REACT_APP_SHIPMENT_API}/shipmentcontact/`;

const shipmentContactApi: ApiModel<ShipmentContactModel> = {
  ...getApi<ShipmentContactModel>(endpoint, ApiContentType.JSON, true),
};

export default shipmentContactApi;
