import merge from './helpers/merge';

export default function resolveOptions(conditions) {
  const {
    disableAnimations,
    disableLines,
    disableCurves,
    disableXAxisGridLines,
    disableYAxisGridLines,
    disableXAxisBorders,
    disableYAxisBorders,
    disableXAxisTickLabels,
    disableYAxisTickLabels,
    showLegend,
    legendPosition,
    showStacked,
    maintainAspectRatio,
    type,
    options: nativeOptions,
  } = conditions;

  let options: any = {
    maintainAspectRatio,
    legend: {
      display: showLegend,
      position: legendPosition,
      fullWidth: true,
      labels: {
        usePointStyle: true,
      },
    },
  };

  if (type === 'bar' || type === 'horizontalBar' || type === 'line') {
    options = {
      ...options,
      scales: {
        xAxes: [
          {
            ticks: {
              display: !disableXAxisTickLabels,
            },
            gridLines: {
              display: !disableXAxisGridLines,
              drawBorder: !disableXAxisBorders,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: !disableYAxisTickLabels,
            },
            gridLines: {
              display: !disableYAxisGridLines,
              drawBorder: !disableYAxisBorders,
            },
          },
        ],
      },
    };
  }

  if (disableAnimations) {
    options = {
      ...options,
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    };
  }
  if (disableLines) {
    options = {
      ...options,
      showLines: false,
    };
  }
  if (disableCurves) {
    options = {
      ...options,
      elements: {
        line: {
          tension: 0,
        },
      },
    };
  }
  if (showStacked) {
    options = {
      ...options,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    };
  }

  return merge(options, nativeOptions);
}
