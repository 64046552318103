import { TableActions } from '../reducer';

export function handleResetState(dispatch, defaultSortField) {
  return () => {
    dispatch({
      type: TableActions.RESET_STATE,
      payload: {
        page: 1,
        filter: undefined,
        sortField: defaultSortField,
        entryToDelete: undefined,
      },
    });
  };
}
