import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { resolveApiErrorMessage } from 'api/base';
import taskStatusApi from 'api/task-status';
import useGetList from 'hooks/base/get-list';
import notification, { NotificationType } from 'helpers/notification';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import taskFormReducer, { TaskFormActions } from './reducer';
import initFormData from './actions/init-form-data';
import setFormValue from './actions/set-form-value';
import setErrors from './actions/set-errors';
import handleSubsChange from './actions/handle-subs-change';
import saveTask from './actions/save-task';
import handleSmsSubChange from './actions/handle-sms-sub-change';
import handleEmailSubChange from './actions/handle-email-sub-change';
import handleVerbosityChange from './actions/handle-verbosity-cahage';

export * from './actions/map-functions';
export * from './validation-rules';

const statusMapOptions = {
  fields: { value: 'name', label: 'name' }
};

export const verbosityOptions = [
  { value: '0', label: 'Minimal' },
  { value: '1', label: 'Some' },
  { value: '2', label: 'Medium' },
  { value: '3', label: 'All' },
];

const initialState = {
  task: undefined,
  fetching: true,
  savingTask: false,
  errors: {},
};

export default function useTaskFormSate({ taskInstance, visible, onCancel }) {
  const [state, dispatch] = useReducer(taskFormReducer, initialState);

  const {
    fetching: fetchingStatus,
    data: statusList,
    error: statusError,
  } = useGetList(taskStatusApi);

  useEffect(() => {
    if (visible) {
      dispatch({ type: TaskFormActions.SET_STATE, payload: initialState });
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      initFormData(dispatch, taskInstance);
    }
  }, [taskInstance, visible]);

  useEffect(() => {
    if (statusError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(statusError),
      });
    }
  }, [statusError]);

  return {
    state: {
      ...state,
      fetching: state.fetching || fetchingStatus,
      verbosityOptions,
      statusList: useMemo(() => {
        const sortedStatusList = [...statusList].sort(
          (a, b) => a.priority - b.priority
        );
        return mapGenericDropdownOptions(sortedStatusList, statusMapOptions);
      }, [statusList]),
    },
    actions: {
      handleSubsChange,
      handleSmsSubChange,
      handleEmailSubChange,
      handleVerbosityChange,
      setFormValue: useCallback(setFormValue(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      saveTask: useCallback(saveTask(dispatch, state.task, onCancel), [state.task]),
    },
  };
}
