import { TaskTemplate } from 'models/task-template';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import axios, { AxiosResponse } from 'axios';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/task-templates/`;

interface TaskTemplateApiModel extends ApiModel<TaskTemplate> {
  getConditionalChoices: () => Promise<AxiosResponse | void>;
}

const taskTemplatesApi: TaskTemplateApiModel = {
  ...getApi<TaskTemplate>(endpoint, ApiContentType.JSON, true),
  getConditionalChoices() {
    return axios.options(endpoint);
  }
};

export default taskTemplatesApi;
