import { useCallback, useReducer } from 'react';
import moment from 'moment';
import { InstallerBookingDTO } from 'models/project-coordinator';
import installerFormReducer from './reducer';
import getState from './actions/get-state';
import setFormValue from './actions/set-form-value';
import handleOnChange from './actions/handle-on-change';
import setErrors from './actions/set-errors';
import onSave from './actions/handle-on-save';
import onDelete from './actions/handle-on-delete';

export default function useSetInstaller(installers: InstallerBookingDTO [], projectId: number, orderId: number) {
  const initialInstaller = {
    projectId,
    installer: undefined,
    installationDate: (
      moment()
        .utcOffset('z')
        .set('hour', 9)
        .set('minutes', 0)
        .set('seconds', 0)
    ),
  };
  const [state, dispatch] = useReducer(installerFormReducer, { installers, initialInstaller }, getState);
  const { installersBooking, formData } = state;

  return {
    state,
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      onChange: useCallback(handleOnChange(dispatch, initialInstaller), []),
      setErrors: useCallback(setErrors(dispatch), []),
      handleOnDelete: useCallback(onDelete(dispatch, installersBooking, orderId), [installersBooking, orderId]),
      onSave: useCallback(
        onSave(dispatch, formData, installersBooking, orderId), [formData, installersBooking, orderId]
      ),
    }
  };
}
