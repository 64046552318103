import { FileViewerFormActions } from '../reducer';

export default function goBackFile(actualParams: string, dispatch) {
  return () => {
    if (actualParams) {
      const lastSlashIndex = actualParams.lastIndexOf('/');
      const params = lastSlashIndex === -1 ? '' : actualParams.slice(0, lastSlashIndex);
      dispatch({ type: FileViewerFormActions.SET_PARAMS, payload: params });
    }
  };
}
