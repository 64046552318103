import { AccessMethodModel } from 'models/access-methods';
import { get, ApiModel } from './base';

const { REACT_APP_SHIPMENT_API } = process.env;
const accessMethodsEndpoint = `${REACT_APP_SHIPMENT_API}/accessmethod/`;

const accessMethodsApi: ApiModel<AccessMethodModel> = {
  get(url) {
    return get(url);
  },
  list(params?) {
    return get(accessMethodsEndpoint, params);
  }
};

export default accessMethodsApi;
