import { QuickUserModel } from 'models/user';
import { AccountModel } from 'models/account';

export function getUserPayload(formData: QuickUserModel) {
  const { email, ...rest } = formData;
  const userName = email.replace('@', '-');
  return { ...rest, email, username: userName };
}

export function getAccountPayload(account: AccountModel, newUserURL: string) {
  const { users } = account;
  const usersWhitNewUser = [...users, newUserURL];
  return { ...account, users: usersWhitNewUser };
}
