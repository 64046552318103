/* eslint-disable */
import Core from "../core";
import * as THREE from "three";
import { unitScale } from "../core/dimensioning";

const defaultWallTexture = {
  url: `${Core.Configuration.getStringValue(
    "defaultPathPrefix"
  )}/rooms/textures/blank.png`,
  stretch: true,
  scale: 0,
};

/**
 * A Wall is the basic element to create Rooms.
 *
 * Walls consists of two half edges.
 */
export default class Wall {
  /** The unique id of each wall. */
  id;

  /** Front is the plane from start to end. */
  frontEdge = null;

  /** Back is the plane from end to start. */
  backEdge = null;

  /** */
  orphan = false;

  locked = false;

  /** Items attached to this wall */
  items = [];

  /** */
  onItems = [];

  /** The front-side texture. */
  frontTexture = defaultWallTexture;

  /** The back-side texture. */
  backTexture = defaultWallTexture;

  /** Wall thickness. */
  thickness = Core.Configuration.getNumericValue(Core.configWallThickness);

  /** Wall height. */
  height = Core.Configuration.getNumericValue(Core.configWallHeight);

  /** Actions to be applied after movement. */
  moved_callbacks = [];

  /** Actions to be applied on removal. */
  deleted_callbacks = [];

  /** Actions to be applied explicitly. */
  action_callbacks = [];

  /**
   * Constructs a new wall.
   * @param start Start corner.
   * @param end End corner.
   */
  constructor(start, end) {
    this.start = start;
    this.end = end;
    this.id = this.getUuid();

    this.start.attachStart(this);
    this.end.attachEnd(this);

    this.start.moved_callbacks.push(this.handleStartMoved);
    this.end.moved_callbacks.push(this.handleEndMoved);
  }

  getUuid() {
    return [this.start.id, this.end.id].join();
  }

  resetFrontBack() {
    this.frontEdge = null;
    this.backEdge = null;
    this.orphan = false;
  }

  snapToAxis(tolerance = 0.25) {
    // order here is important, but unfortunately arbitrary
    this.start.snapToAxis(tolerance);
    this.end.snapToAxis(tolerance);
  }

  fireOnMove(func) {
    this.moved_callbacks.push(func);
  }

  fireOnDelete(func) {
    this.deleted_callbacks.push(func);
  }

  dontFireOnDelete(func) {
    const index = this.deleted_callbacks.indexOf(func);
    // this.deleted_callbacks.remove(func);
    this.deleted_callbacks.splice(index, 1);
  }

  fireOnAction(func) {
    this.action_callbacks.push(func);
  }

  fireAction(action) {
    // this.action_callbacks.fire(action);
    this.action_callbacks.forEach(
      (cb) => typeof cb === "function" && cb(action)
    );
  }

  handleStartMoved = (x, y, prevX, prevY) => {
    const change = {
      prev: {
        start: { x: prevX, y: prevY },
        end: { x: this.getEndX(), y: this.getEndY() },
      },
      current: {
        start: { x, y },
        end: { x: this.getEndX(), y: this.getEndY() },
      },
    };
    this.moveWallItems(change);
  };

  handleEndMoved = (x, y, prevX, prevY) => {
    const change = {
      prev: {
        start: { x: this.getStartX(), y: this.getStartY() },
        end: { x: prevX, y: prevY },
      },
      current: {
        start: { x: this.getStartX(), y: this.getStartY() },
        end: { x, y },
      },
    };
    this.moveWallItems(change);
  };

  relativeMove(dx, dy) {
    if (this.locked || this.start.locked || this.end.locked) return;

    this.start.move(this.getStartX() + dx, this.getStartY() + dy, false);
    this.end.move(this.getEndX() + dx, this.getEndY() + dy, false);

    const change = {
      prev: {
        start: { x: this.getStartX(), y: this.getStartY() },
        end: { x: this.getEndX(), y: this.getEndY() },
      },
    };
    this.snapToAxis();
    change.current = {
      start: { x: this.getStartX(), y: this.getStartY() },
      end: { x: this.getEndX(), y: this.getEndY() },
    };
    this.moveWallItems(change);
  }

  moveWallItems(change) {
    const { prev, current } = change;
    if (!this.items.length) return;
    // console.log(prev, current);
    this.items.forEach((item) => {
      const pos = item.position;
      const prevLength = Math.sqrt(
        (prev.start.x - prev.end.x) ** 2 + (prev.start.y - prev.end.y) ** 2
      );

      const prevDistance = Math.sqrt(
        (prev.start.x - pos.x) ** 2 + (prev.start.y - pos.z) ** 2
      );
      const ratio = prevDistance / prevLength;
      const targetPos = {
        x: current.start.x + (current.end.x - current.start.x) * ratio,
        y: pos.y,
        z: current.start.y + (current.end.y - current.start.y) * ratio,
      };
      const dx = targetPos.x - pos.x;
      const dz = targetPos.z - pos.z;

      item.relativeMove(dx, dz);

      const v1 = new THREE.Vector3(
        prev.end.x - prev.start.x,
        0,
        prev.end.y - prev.start.y
      );
      const v2 = new THREE.Vector3(
        current.end.x - current.start.x,
        0,
        current.end.y - current.start.y
      );

      const normal = new THREE.Vector3().copy(v1).cross(v2);

      let dAngle = v1.angleTo(v2);
      if (normal.y < 0) dAngle = -dAngle;

      let angle = item.rotation.y;
      angle += dAngle;
      item.rotation.y = angle;
      item.dimensionHelper.rotation.y = angle;
    });
  }

  fireMoved() {
    // this.moved_callbacks.fire();
    this.moved_callbacks.forEach((cb) => typeof cb === "function" && cb());
  }

  fireRedraw() {
    if (this.frontEdge) {
      // this.frontEdge.redrawCallbacks.fire();
      this.frontEdge.redrawCallbacks.forEach(
        (cb) => typeof cb === "function" && cb()
      );
    }
    if (this.backEdge) {
      // this.backEdge.redrawCallbacks.fire();
      this.backEdge.redrawCallbacks.forEach(
        (cb) => typeof cb === "function" && cb()
      );
    }
  }

  getStart() {
    return this.start;
  }

  getEnd() {
    return this.end;
  }

  getStartX() {
    return this.start.getX();
  }

  getEndX() {
    return this.end.getX();
  }

  getStartY() {
    return this.start.getY();
  }

  getEndY() {
    return this.end.getY();
  }

  remove() {
    this.start.detachWall(this);
    this.end.detachWall(this);
    // this.deleted_callbacks.fire(this);
    this.deleted_callbacks.forEach(
      (cb) => typeof cb === "function" && cb(this)
    );
  }

  setStart(corner) {
    this.start.detachWall(this);
    corner.attachStart(this);
    this.start = corner;
    this.fireMoved();
  }

  setEnd(corner) {
    this.end.detachWall(this);
    corner.attachEnd(this);
    this.end = corner;
    this.fireMoved();
  }

  distanceFrom(x, y) {
    return Core.Utils.pointDistanceFromLine(
      x,
      y,
      this.getStartX(),
      this.getStartY(),
      this.getEndX(),
      this.getEndY()
    );
  }

  /** Return the corner opposite of the one provided.
   * @param corner The given corner.
   * @returns The opposite corner.
   */
  oppositeCorner(corner) {
    if (this.start === corner) {
      return this.end;
    }
    if (this.end === corner) {
      return this.start;
    }
    console.log("Wall does not connect to corner");
  }

  setLocked(locked) {
    this.locked = locked;
    this.start.setLocked(locked);
    this.end.setLocked(locked);
  }

  getWallCenter() {
    const start = { x: this.start.x, y: this.start.y };
    const end = { x: this.end.x, y: this.end.y };
    return {
      x: (start.x + end.x) / 2,
      y: (start.y + end.y) / 2,
    };
  }

  getWallLength() {
    const start = { x: this.start.x, y: this.start.y };
    const end = { x: this.end.x, y: this.end.y };
    const length = Math.sqrt((start.x - end.x) ** 2 + (start.y - end.y) ** 2);
    return length;
  }

  setWallLength(value) {
    if (value > 0) {
      const rate = value / this.getWallLength();
      let center = { x: 0, y: 0 };

      if (!this.start.locked && !this.end.locked) {
        center = this.getWallCenter();
      } else if (this.start.locked && !this.end.locked) {
        center = {
          x: this.getStartX(),
          y: this.getStartY(),
        };
      } else if (!this.start.locked && this.end.locked) {
        center = {
          x: this.getEndX(),
          y: this.getEndY(),
        };
      } else {
        return;
      }
      /** Start */
      let newX = 0;
      let newY = 0;
      newX = center.x + (this.start.x - center.x) * rate;
      newY = center.y + (this.start.y - center.y) * rate;
      this.start.move(newX, newY, false);

      /** End */
      newX = center.x + (this.end.x - center.x) * rate;
      newY = center.y + (this.end.y - center.y) * rate;
      this.end.move(newX, newY, false);

      // this.planner.view.draw();
    }
  }
}
