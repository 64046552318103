import { useEffect, useMemo } from 'react';
import { InventoryModel } from 'models/inventory';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import inventoryApi from 'api/inventory';
import { validationRules } from './validations';

const defaultInventory = {
  quantity: 1,
  part: undefined,
};

const getPayload = (inventory) => ({
  ...inventory,
  part: inventory?.part?.value
});

const getFormData = (inventory: InventoryModel) => {
  return inventory
    ? {
      quantity: inventory?.quantity || '0',
      url: inventory.url,
      part: {
        value: inventory?.partDetail?.url,
        label: inventory?.partDetail?.name
      }
    }
    : defaultInventory;
};

export default function useInventoryFormState(inventoryUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: inventoryApi,
    eventChannel: EventChannelList.INVENTORY_LIST_CHANGED,
    param: inventoryUrl,
    getPayload,
    getFormData,
    validationRules
  }), [inventoryUrl]);

  const { state, actions } = useFormState<InventoryModel>(useFormStateParams);
  const { errorFetchingEntity } = state;

  useEffect(() => {
    if (errorFetchingEntity) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingEntity,
      });
    }
  }, [errorFetchingEntity]);

  return { state, actions };
}
