import { PurchaseOrderModel } from 'models/order';
import moment from 'moment';
import { PurchaseOrderFormModel } from '..';

const defaultPurchaseOrder: PurchaseOrderFormModel = {
  manufacturer: undefined,
  dateSent: undefined,
  order: undefined,
  paintShop: false,
  supplierReadyDate: undefined,
  tag: 'stock',
};

export default function getFormData(orderId: string) {
  return (purchaseOrder: PurchaseOrderModel): PurchaseOrderFormModel => {
    if (purchaseOrder) {
      const {
        manufacturer,
        order,
        dateSent,
        supplierReadyDate,
        ...rest
      } = purchaseOrder;

      return {
        ...rest,
        dateSent: dateSent ? moment(dateSent) : null,
        supplierReadyDate: supplierReadyDate ? moment(supplierReadyDate) : null,
        order: { value: order?.id, label: order?.id?.toString() },
        manufacturer: { value: manufacturer, label: manufacturer },
      };
    }
    if (orderId) {
      return {
        ...defaultPurchaseOrder,
        order: { value: orderId, label: orderId },
      };
    }

    return defaultPurchaseOrder;
  };
}
