import { useCallback, useEffect, useMemo, useState } from 'react';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import hasPermissions from 'helpers/permissons';
import useGetList from 'hooks/base/get-list';
import manufacturerApi from 'api/manufacturer';
import { resolveApiErrorMessage } from 'api/base';
import { useHistory } from 'react-router-dom';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { PurchaseOrderDTO } from 'models/order';
import { ManufacturerModel } from 'models/manufacturer';
import purchaseOrderApi from 'api/purchase-order';
import openFormModal from './actions/open-form-modal';
import getData from './actions/get-data';
import handleSetDateSent from './actions/handle-set-sent-date';
import openSendEmailModal from './actions/open-sed-email-modal';
import printPDF from './actions/print-pdf';
import createBill from './actions/create-bill';
import deleteBill from './actions/delete-bill';

export interface PurchaseOrderListModel{
  id: number;
  order: string;
  dateCreated: string;
  dateSent: string;
  bill?: number;
  manufacturer: ManufacturerModel;
}

export default function usePurchaseOrdersListState() {
  const history = useHistory();
  const [showAll, setShowAll] = useState(false);
  const useTableStateParams = useMemo<UseTableStateParams>(() => ({
    overrideListFunction: showAll ? undefined : purchaseOrderApi.listIncomplete,
    api: purchaseOrderApi,
    deleteModalTitle: 'Delete Purchase Order',
    deleteModalContent: () => 'Are you sure that you want to delete this purchase order?',
    searchFields: ['manufacturer', 'id', 'order__id', 'order__quote__project__job_title'],
    eventChannel: EventChannelList.PURCHASE_ORDERS_LIST_CHANGED,
    defaultSortField: '-id',
    reference: 'purchase-order'
  }), [showAll]);

  const {
    state: {
      data: purchaseOrders,
      fetching,
      count,
      page,
      filter
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
      updateDataManually,
    }
  } = useTableState<PurchaseOrderDTO>(useTableStateParams);

  const [updating, setUpdating] = useState(false);

  const {
    fetching: fetchingManufacturers,
    data: manufacturers,
    error: fetchingManufacturersErrors,
  } = useGetList<ManufacturerModel>(manufacturerApi);

  useEffect(() => {
    if (fetchingManufacturersErrors) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(fetchingManufacturersErrors),
      });
    }
  }, [fetchingManufacturersErrors]);

  const fetchingData = useMemo(() => fetching || fetchingManufacturers || updating,
    [fetching, fetchingManufacturers, updating]);

  const data = useMemo(
    () => getData(purchaseOrders, manufacturers),
    [purchaseOrders, manufacturers],
  );

  return {
    state: {
      showAll,
      filter,
      data,
      fetchingData: fetchingData || updating,
      count,
      page,
      linkToOrder: useMemo(() => hasPermissions(['Sales-Orders', 'Dealer', 'Sales']) ? (order) => `quote/${order.quoteId}` : undefined, [])
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
      printPDF,
      openFormModal: useCallback(openFormModal(history), [history]),
      openSendEmailModal: useCallback(openSendEmailModal(history), [history]),
      handleSetDateSent: useCallback(
        handleSetDateSent(setUpdating, updateDataManually, purchaseOrders),
        [setUpdating, updateDataManually, purchaseOrders],
      ),
      createBill: useCallback(createBill(setUpdating), []),
      deleteBill: useCallback(deleteBill(setUpdating), []),
      toggleShowAll: useCallback(() => setShowAll(!showAll), [showAll]),
    },
  };
}
