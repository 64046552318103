import { useCallback, useEffect, useReducer, useMemo } from 'react';
import { FileViewerModel } from 'models/file-viewer';
import filesViewerApi from 'api/file-viewer';
import notification, { NotificationType } from 'helpers/notification';
import useCallApiAction from 'hooks/base/call-api-action';
import filesViewerFormReducer, { FileViewerFormActions } from './reducer';
import openDirectory from './actions/open-directory';
import uploadFile from './actions/upload-file';
import goBackFile from './actions/go-back';
import dropInsideFolder from './actions/drop-inside-folder';
import addNewFolder from './actions/add-new-folder';
import onCancel from './actions/on-cancel';

const initialState = {
  data: {
    directories: [],
    directory: '',
    files: [],
  } as FileViewerModel,
  params: '',
  file: null,
  uploading: false,
  addFolder: false,
  addingFolder: false,
};

export default function useFileViewerState(quoteId, onUpload) {
  const [state, dispatch] = useReducer(
    filesViewerFormReducer, initialState,
  );
  const { params, data: { directory } } = state;
  const route = useMemo(() => directory.split('/'), [directory]);
  const queryParams = useMemo(() => ({ quoteId, params }), [quoteId, params]);
  const {
    fetching: fetchingFiles,
    data,
    error: filesError,
    callAction: fetchFiles,
  } = useCallApiAction<FileViewerModel>(
    filesViewerApi.list,
    queryParams,
    null as FileViewerModel,
  );

  useEffect(() => {
    if (filesError) {
      notification({ type: NotificationType.ERROR, message: filesError });
    }
  }, [filesError]);

  useEffect(() => {
    if (data) {
      dispatch({ type: FileViewerFormActions.SET_DATA, payload: data });
    }
  }, [data]);

  useEffect(() => {
    const preventDefault = (e) => {
      e.preventDefault();
    };

    window.addEventListener('dragover', preventDefault, false);
    window.addEventListener('drop', preventDefault, false);

    return () => {
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', preventDefault);
    };
  }, []);

  return {
    state: {
      ...state,
      fetchingFiles,
      route,
    },
    actions: {
      openDirectory: useCallback(openDirectory(params, dispatch), [params]),
      goBackFile: useCallback(goBackFile(params, dispatch), [params]),
      uploadFile: useCallback(
        uploadFile(params, quoteId, dispatch, onUpload, fetchFiles), [params, quoteId, fetchFiles, onUpload]
      ),
      dropInsideFolder: useCallback(dropInsideFolder(params, quoteId, dispatch, onUpload), [params, quoteId, onUpload]),
      addNewFolder: useCallback(addNewFolder(params, quoteId, dispatch, fetchFiles), [params, quoteId, fetchFiles]),
      showNewFolderInput: useCallback(() => dispatch({ type: FileViewerFormActions.SET_ADD_FOLDER }), []),
      onCancel: useCallback(onCancel(dispatch), []),
    },
  };
}
