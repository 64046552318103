import calendarEventApi from 'api/calendar-event';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';

export default function setCustomEventCompleted(event, loading) {
  return () => {
    loading(true);
    const onSuccess = () => {
      notifyEventChannel(EventChannelList.CUSTOM_EVENT_LIST_CHANGED);
    };
    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };
    const onFinally = () => loading(false);

    return calendarEventApi.patch({ id: event.id, completed: !event.completed })
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
