/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row, Card, CardBody, Alert } from 'reactstrap';
import profile from 'assets/images/profile-img.png';
import logo from 'assets/images/tfg-logo.svg';
import { forgetUser } from 'store/actions';
import { commonRules, validateEntity } from 'helpers/form-validations';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Button from 'components/base-components/Button';

const rules = {
  username: [commonRules.required],
};

const ForgetPassword = () => {
  const [formValue, setFormValue] = useState<{ username: string }>({ username: '' });
  const [errors, setErrors] = useState<{ username: string }>({ username: '' });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { forgetError } = useSelector((state: any) => ({
    forgetError: state.forgetPassword.forgetError
  }));

  const handleValidSubmit = () => {
    setLoading(true);
    const { hasErrors, errors: nextErrors } = validateEntity(formValue, rules);

    if (hasErrors) {
      setErrors(nextErrors as any);
      setLoading(false);
    } else {
      dispatch(forgetUser(formValue.username, history));
    }
  };

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Forgot Password!</h5>
                      <p>Let's get you a new one.</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img src={logo} alt="" className="rounded-circle" height="34" />
                    </span>
                  </div>
                </div>
                <div className="p-2">
                  {forgetError && (
                    <Alert color="danger" style={{ marginTop: '13px' }}>
                      {forgetError}
                    </Alert>
                  )}
                  <Form
                    onChange={setFormValue}
                    state={formValue}
                    errors={errors}
                    onError={setErrors}
                    rules={rules}
                  >
                    <Field
                      name="username"
                      label="User Name"
                      placeholder="Enter username"
                      required
                    />
                  </Form>
                  <Button label="Reset" onClick={handleValidSubmit} loading={loading} block />
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Go back to
                {' '}
                <Link to="login" className="font-weight-medium text-primary">
                  Login
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPassword;
