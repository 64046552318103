import { formatDateToString } from 'helpers/formatting';
import { flatDropdownOption } from 'helpers/dropdown-options';
import { IncomeCustomerForm } from '../util';

export function getIncomeCustomerParams(formData: IncomeCustomerForm) {
  return {
    filters: {
      startDate: formatDateToString(formData.startDate, 'DD/MM/YY'),
      endDate: formatDateToString(formData.endDate, 'DD/MM/YY'),
      repOwner: flatDropdownOption(formData.repOwner),
    }
  };
}
