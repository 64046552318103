import { useCallback, useEffect, useState } from 'react';
import { resolveApiErrorMessage, ApiModel } from 'api/base';
import { AxiosResponse } from 'axios';

interface GetOneState<T> {
  fetching: boolean;
  data: T;
  error: string;
}

const fetchOne = async (api, param: string | number, setState, fetching: boolean) => {
  if (!fetching) {
    setState((oldState) => ({ ...oldState, fetching: true }));
  }
  if (param) {
    const action = typeof api === 'function' ? api : api.get;
    try {
      const response = await action(param);
      setState({
        fetching: false,
        data: response.data,
        error: undefined,
      });
    } catch (error) {
      const message = resolveApiErrorMessage((error as any).response);
      setState({
        fetching: false,
        data: undefined,
        error: message,
      });
    }
  }
};

const updateState = (setState, nextState, onlyUpdateData?: boolean) => {
  if (onlyUpdateData) {
    setState((oldState) => ({ ...oldState, data: { ...oldState.data, ...nextState } }));
  } else {
    setState((oldState) => ({ ...oldState, ...nextState }));
  }
};

export default function useGetOne<T>(
  api: ApiModel<T> | ((param) => Promise<AxiosResponse<T>>),
  param: string | number,
  options: { preventAutoFetch?: boolean } = {},
) {
  const { preventAutoFetch } = options;
  const [state, setState] = useState<GetOneState<T>>({
    fetching: !preventAutoFetch && !!param,
    data: undefined,
    error: undefined,
  });

  useEffect(() => {
    if (!preventAutoFetch && !!param) {
      fetchOne(api, param, setState, state.fetching);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, param, preventAutoFetch]);

  return {
    ...state,
    fetchOne: useCallback(
      () => fetchOne(api, param, setState, state.fetching),
      [api, param, state.fetching]
    ),
    fetchOneByParam: useCallback(
      (otherParam: string) => fetchOne(api, otherParam, setState, false),
      [api]
    ),
    updateDataManually: useCallback(
      (nextState, onlyUpdateData?) => updateState(setState, nextState, onlyUpdateData),
      [],
    ),
  };
}
