import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'components/base-components/ModalV2';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import './styles.scss';

interface Props {
  title: string;
  fetching: boolean;
  image: string;
  description: any;
}

export const emptyDescription = {
  ul_list: [],
  paragraph: '',
};

const ShowDetails: FunctionComponent<Props> = (props) => {
  const { goBack } = useHistory();
  const { title, description, fetching, image } = props;
  const listDescriptions = useMemo(() => (
    (typeof description !== 'string' && Array.isArray(description.ul_list)) ? description.ul_list.map((li, index) => (<li key={index}>{li}</li>)) : ''
  ), [description]);

  return (
    <Modal
      title={title}
      visible
      onCancel={goBack}
      footer={null}
      width="70%"
    >
      <RenderIf isTrue={!fetching} fallback={<Spinner />}>
        <div className="table__details">
          <img src={image} alt="product" />
        </div>
        <RenderIf isTrue={typeof description !== 'string'}>
          <ul className="list__details">
            {listDescriptions}
          </ul>
          <p>{description.paragraph}</p>
        </RenderIf>
        <RenderIf isTrue={typeof description === 'string'}>
          <p>{description}</p>
        </RenderIf>
      </RenderIf>
    </Modal>
  );
};

export default ShowDetails;
