import React, { FunctionComponent } from 'react';
import { EventChannelList } from 'helpers/event-center';
import { OptionGroupModel } from 'models/option-group';
import optionGroupApi from 'api/option-group';
import { useHistory } from 'react-router-dom';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import DataTable from 'components/base-components/DataTableV2';
import Help from 'components/forms/CommonHelp';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Page, { PageActions } from 'components/base-components/Page';
import Button from 'components/base-components/Button';
import AccessControl from 'components/base-components/AccessControl';
import { OptionGroupFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: optionGroupApi,
  deleteModalTitle: 'Delete Option Group',
  deleteModalContent: (optionGroupToDelete: OptionGroupModel) => `Are you sure that you want to delete the option group ${optionGroupToDelete ? optionGroupToDelete.name : ''}?`,
  searchFields: ['name'],
  eventChannel: EventChannelList.OPTION_GROUPS_LIST_CHANGED,
};

const OptionsList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<OptionGroupModel>(useTableStateParams);

  const openOptionGroupFormModal = (optionGroup?: OptionGroupModel | any) => {
    const historyLocationState: OptionGroupFormHistorySateModel = {
      optionGroupForm: {
        optionGroupUrl: optionGroup ? optionGroup.url : undefined,
      },
    };
    push(ModalRouteHash.OptionGroupForm, historyLocationState);
  };

  return (
    <Page title="Option Groups">
      <Help view="option-group" title="Option Groups List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addOption}>
          <Button
            onClick={openOptionGroupFormModal}
            label="Add Option Group"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onFilter={handleFilter}
        onChange={handleOnChange}
        rowKey="url"
      >
        <DataTableColumn dataIndex="name" columnKey="name" title="Name" sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editOption}
          deletePermissions={permissions.deleteOption}
          onEdit={openOptionGroupFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default OptionsList;
