import React, { FunctionComponent, useMemo } from 'react';
import { CategoryModel } from 'models/category';
import { BuilderCategory } from 'models/builder';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Tab, { TabPane } from 'components/base-components/Tab';
import { StyleModel } from '../state';
import Category from './Category';
import './styles.scss';

interface Props {
  blockUI: boolean;
  fetching: boolean;
  products: CategoryModel[];
  roomObjects: BuilderCategory[];
  selectedCategory: CategoryModel | BuilderCategory;
  onSelectCategory: (category: CategoryModel | BuilderCategory) => void;
  styles?: StyleModel[];
  onSelectStyle?: (style) => void;
  hiddenContent?: boolean;
}

const CategoryPanel: FunctionComponent<Props> = (props) => {
  const {
    blockUI,
    fetching,
    products,
    roomObjects,
    selectedCategory,
    onSelectCategory,
    styles,
    onSelectStyle,
    hiddenContent,
  } = props;

  const productItems = useMemo(() => products.map(cat => (
    <Category
      key={cat.url}
      url={cat.url}
      name={cat.name}
      categories={styles}
      onSelectStyle={onSelectStyle}
      levelDescription={cat.levelDescription}
      onSelectCategory={() => onSelectCategory(cat)}
      showChildren={cat.name === selectedCategory?.name}
    />
  )), [products, styles, onSelectStyle, selectedCategory, onSelectCategory]);

  const roomObjectItems = useMemo(() => roomObjects.map(cat => (
    <Category
      key={cat.url}
      url={cat.url}
      name={cat.name}
      categories={styles}
      onSelectStyle={onSelectStyle}
      levelDescription={cat.levelDescription}
      onSelectCategory={() => onSelectCategory(cat)}
      showChildren={cat.name === selectedCategory?.name}
    />
  )), [roomObjects, styles, onSelectStyle, selectedCategory, onSelectCategory]);

  return (
    <aside className="builder__category-panel">
      <RenderIf isTrue={!hiddenContent}>
        <RenderIf isTrue={!fetching} fallback={<Spinner />}>
          <Tab defaultActiveKey="1">
            <TabPane tab="Products" key="1">
              <div className="builder__category-panel__list">
                {productItems}
              </div>
            </TabPane>
            <TabPane tab="Room Objects" key="2">
              <ul className="builder__category-panel__list">
                {roomObjectItems}
              </ul>
            </TabPane>
          </Tab>
        </RenderIf>
        <RenderIf isTrue={blockUI}>
          <div className="builder__loading-screen" />
        </RenderIf>
      </RenderIf>
    </aside>
  );
};

export default CategoryPanel;
