import { resolveApiErrorMessage } from 'api/base';
import quoteApi from 'api/quote';
import shipmentApi from 'api/shipment';
import projectsApi from 'api/projects';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { validateEntity } from 'helpers/form-validations';
import { getLoggedUser } from 'helpers/get-logged-user';
import { CreateProjectFormActions } from '../reducer';
import validationRules from '../validations';

export async function createAll(project) {
  const { id: repOwner } = getLoggedUser();
  const projectResponse = await projectsApi.create({ ...project, repOwner });
  const { data: newProject } = projectResponse;

  const newShipment = { project: newProject.id, shipmentType: 1 };
  await shipmentApi.create(newShipment);

  const newQuote = { comments: 'Initial Quote', project: newProject.id };
  const quoteResponse = await quoteApi.create(newQuote);
  const { data: quote } = quoteResponse;

  return { project: newProject, quote };
}

export function createProject(
  dispatch,
  formData: { jobTitle: string },
  client: string,
  account: string,
  goBack,
) {
  return () => {
    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: CreateProjectFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    dispatch({ type: CreateProjectFormActions.START_CREATING });

    const onSuccess = (entities) => {
      const message = 'Project Successfully Created';
      notification({ type: NotificationType.SUCCESS, message });
      notifyEventChannel(EventChannelList.QUOTE_PAGE_NEW_PROJECT, entities);
      notifyEventChannel(EventChannelList.ASYNC_SELECT_REFETCH);
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CreateProjectFormActions.FINISH_CREATING });
    };

    return createAll({ ...formData, client, account })
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
