import { useCallback, useEffect, useReducer, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { resolveApiErrorMessage } from 'api/base';
import taskApi from 'api/task';
import tasksStatusApi from 'api/task-status';
import taskUserApi from 'api/task-user';
import { TaskModel, StatusTask } from 'models/task';
import useGetList from 'hooks/base/get-list';
import useGetOne from 'hooks/base/get-one';
import { getLoggedUserId } from 'helpers/get-logged-user';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import generateColumns from './actions/generate-columns';
import openEditTaskModal from './actions/open-edit-task';
import onCardDragEnd from './actions/on-card-drag-end';
import calendarReducer, { TaskManagerActions } from './reducer';

export default function useTaskManager() {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(calendarReducer, {
    columns: [],
    isShowTaskForm: false,
    task: null,
    generatingColumns: true,
  });
  const loggedUserId = useMemo(() => getLoggedUserId(), []);

  const { columns } = state;

  const {
    fetching: loadingInstance,
    data: taskUser,
  } = useGetOne(taskUserApi, loggedUserId);

  const options = useMemo(() => ({
    queryParams: {
      filters: {
        assignee: loggedUserId
      }
    },
    preventAutoFetch: !taskUser,
  }), [loggedUserId, taskUser]);

  const {
    fetching: fetchingTasks,
    data: tasks,
    error: tasksError,
    fetchList: reFetchTask,
  } = useGetList<TaskModel>(taskApi, options);

  const {
    fetching: fetchingStatus,
    data: statuses,
    error: statusError,
  } = useGetList<StatusTask>(tasksStatusApi);

  useEffect(() => {
    if (!fetchingTasks && !fetchingStatus && !loadingInstance) {
      generateColumns(statuses, tasks, dispatch);
    }
  }, [tasks, fetchingTasks, fetchingStatus, statuses, loadingInstance]);

  useEffect(() => {
    if (tasksError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(tasksError),
      });
    }
  }, [tasksError]);

  useEffect(() => {
    if (statusError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(statusError),
      });
    }
  }, [statusError]);

  const handleUpdates = useCallback(() => reFetchTask(options.queryParams), [reFetchTask, options.queryParams]);

  useEventCenterUpdate(EventChannelList.TASK_PAGE_LIST_CHANGED, handleUpdates);

  return {
    state: {
      ...state,
    },
    actions: {
      openEditTaskModal: useCallback(openEditTaskModal(dispatch), []),
      onCardDragEnd: useCallback(onCardDragEnd(columns, dispatch), [columns]),
      goBack: useCallback(goBack, []),
      onClose: useCallback(() => dispatch({ type: TaskManagerActions.CLOSE_TASK_FORM }), []),
    }
  };
}
