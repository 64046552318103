import { ThreeFormActions } from '../reducer';

export default function changeMorph(dispatch) {
  return (index, morph) => {
    dispatch({
      type: ThreeFormActions.CHANGE_MORPH,
      payload: { index, morph },
    });
  };
}
