import { QuickAccountModel } from 'models/account';
import { resolveApiErrorMessage } from 'api/base';
import accountApi from 'api/account';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { validateEntity } from 'helpers/form-validations';
import { CreateQuickAccountFormActions } from '../reducer';
import { validationRules } from '../validations';

export function createQuickAccount(dispatch, formData: QuickAccountModel, goBack) {
  return () => {
    dispatch({ type: CreateQuickAccountFormActions.START_CREATING });

    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      dispatch({
        type: CreateQuickAccountFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
      return;
    }

    const onSuccess = (payload) => {
      const account = {
        value: payload.data.url,
        label: payload.data.businessLegalName,
        allUsersDetails: payload.data.allUsersDetails,
        taxDetails: payload.data.taxDetails,
        groupDetail: payload.data.groupDetail,
        businessAddress: payload.data.businessAddress,
        businessCity: payload.data.businessCity,
        businessPostCode: payload.data.businessPostCode,
      };
      notifyEventChannel(EventChannelList.QUOTE_PAGE_NEW_ACCOUNT, account);
      notifyEventChannel(EventChannelList.ASYNC_SELECT_REFETCH);

      const message = 'Account Successfully created';
      notification({ type: NotificationType.SUCCESS, message });
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CreateQuickAccountFormActions.FINISH_CREATING });
    };

    // eslint-disable-next-line consistent-return
    return accountApi.quickCreate(formData)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
