import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { TutorialModel } from 'models/tutorial';
import tutorialsApi from 'api/tutorials';
import { EventChannelList } from 'helpers/event-center';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import Page, { PageActions } from 'components/base-components/Page';
import Button from 'components/base-components/Button';
import DataTable, { DataTableColumn } from 'components/base-components/DataTableV2';
import { ModalRouteHash } from 'components/base-components/ModalRouter';

const params: UseTableStateParams = {
  api: tutorialsApi,
  deleteModalTitle: 'Delete tutorial video',
  deleteModalContent: (tutorial: TutorialModel) => (
    `Are you sure that you want to delete the video ${tutorial.videoName}?`
  ),
  searchFields: [],
  eventChannel: EventChannelList.TUTORIAL_LIST_CHANGED,
  defaultSortField: '-id'
};

const TutorialsList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
    }
  } = useTableState<TutorialModel>(params);

  const openFormModal = (tutorial: TutorialModel | any) => {
    const navigationState = {
      tutorialsForm: { tutorial: tutorial.id ? tutorial : undefined },
    };
    push(ModalRouteHash.Tutorial, navigationState);
  };

  return (
    <Page title="Tutorial Videos">
      <PageActions primary>
        <Button
          onClick={openFormModal}
          label="Add video"
        />
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        rowKey="id"
      >
        <DataTableColumn
          title="Name"
          dataIndex="videoName"
          columnKey="video_name"
          sorter
        />
        <DataTableColumn
          title="View"
          dataIndex="erpView"
          columnKey="erp_view"
          sorter
        />
        <DataTableColumn
          dataIndex="video"
          columnKey="video_url"
          title="Video"
          showBreakpoint={1873}
        />
        <DataTableColumn
          isActionColumn
          onEdit={openFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default TutorialsList;
