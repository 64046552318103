import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import partApi from 'api/product-part';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import NumberInput from 'components/base-components/NumberInput';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import { validationRules } from './validations';
import useInventoryFormState from './state';

export function mapOptions(data: any[]) {
  return mapGenericDropdownOptions(data, { fields: { value: 'url', label: 'name' } });
}

export interface InventoryFormHistorySateModel {
  inventoryForm: {
    inventoryUrl: string;
  };
}

const InventoryForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { inventoryForm: { inventoryUrl } } = state as InventoryFormHistorySateModel;

  const {
    state: {
      fetchingEntity,
      errors,
      formData,
      saving,
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  } = useInventoryFormState(inventoryUrl);

  const modalTitle = useMemo(
    () => inventoryUrl ? 'Edit Inventory' : 'Create Inventory',
    [inventoryUrl],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveEntity}
      confirmLoading={saving}
    >
      <RenderIf isTrue={!fetchingEntity} fallback={<Spinner />}>
        <Form
          state={formData}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            component={NumberInput}
            id="quantity"
            name="quantity"
            label="Quantity"
            placeholder="Quantity"
          />
          <Field
            component={AsyncSelectV2}
            name="part"
            id="part"
            placeholder="Select Part"
            label="Part"
            listAction={partApi.list}
            mapFunction={mapOptions}
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default InventoryForm;
