/* eslint-disable */
export class Utils {
  /** Determines the distance of a point from a line.
   * @param x Point's x coordinate.
   * @param y Point's y coordinate.
   * @param x1 Line-Point 1's x coordinate.
   * @param y1 Line-Point 1's y coordinate.
   * @param x2 Line-Point 2's x coordinate.
   * @param y2 Line-Point 2's y coordinate.
   * @returns The distance.
   */
  static pointDistanceFromLine(x, y, x1, y1, x2, y2) {
    const tPoint = Utils.closestPointOnLine(x, y, x1, y1, x2, y2);
    const tDx = x - tPoint.x;
    const tDy = y - tPoint.y;
    return Math.sqrt(tDx * tDx + tDy * tDy);
  }

  /** Gets the projection of a point onto a line.
   * @param x Point's x coordinate.
   * @param y Point's y coordinate.
   * @param x1 Line-Point 1's x coordinate.
   * @param y1 Line-Point 1's y coordinate.
   * @param x2 Line-Point 2's x coordinate.
   * @param y2 Line-Point 2's y coordinate.
   * @returns The point.
   */
  static closestPointOnLine(x, y, x1, y1, x2, y2) {
    // Inspired by: http://stackoverflow.com/a/6853926
    const tA = x - x1;
    const tB = y - y1;
    const tC = x2 - x1;
    const tD = y2 - y1;

    const tDot = tA * tC + tB * tD;
    const tLenSq = tC * tC + tD * tD;
    const tParam = tDot / tLenSq;

    let tXx; let
      tYy;

    if (tParam < 0 || (x1 === x2 && y1 === y2)) {
      tXx = x1;
      tYy = y1;
    } else if (tParam > 1) {
      tXx = x2;
      tYy = y2;
    } else {
      tXx = x1 + tParam * tC;
      tYy = y1 + tParam * tD;
    }

    return {
      x: tXx,
      y: tYy
    };
  }

  /** Gets the distance of two points.
   * @param x1 X part of first point.
   * @param y1 Y part of first point.
   * @param x2 X part of second point.
   * @param y2 Y part of second point.
   * @returns The distance.
   */
  static distance(x1, y1, x2, y2) {
    return Math.sqrt(
      (x2 - x1) ** 2 +
      (y2 - y1) ** 2
    );
  }

  /**  Gets the angle between 0,0 -> x1,y1 and 0,0 -> x2,y2 (-pi to pi)
   * @returns The angle.
   */
  static angle(x1, y1, x2, y2) {
    const tDot = x1 * x2 + y1 * y2;
    const tDet = x1 * y2 - y1 * x2;
    const tAngle = -Math.atan2(tDet, tDot);
    return tAngle;
  }

  /** shifts angle to be 0 to 2pi */
  static angle2pi(x1, y1, x2, y2) {
    let tTheta = Utils.angle(x1, y1, x2, y2);
    if (tTheta < 0) {
      tTheta += 2 * Math.PI;
    }
    return tTheta;
  }

  /** Checks if an array of points is clockwise.
   * @param points Is array of points with x,y attributes
   * @returns True if clockwise.
   */
  static isClockwise(points) {
    // make positive
    const tSubX = Math.min(0, Math.min.apply(null, Utils.map(points, (p) => {
      return p.x;
    })));
    const tSubY = Math.min(0, Math.min.apply(null, Utils.map(points, (p) => {
      return p.x;
    })));

    const tNewPoints = Utils.map(points, (p) => {
      return {
        x: p.x - tSubX,
        y: p.y - tSubY
      };
    });

    // determine CW/CCW, based on:
    // http://stackoverflow.com/questions/1165647
    let tSum = 0;
    for (let tI = 0; tI < tNewPoints.length; tI++) {
      const tC1 = tNewPoints[tI];
      var tC2;
      if (tI === tNewPoints.length - 1) {
        tC2 = tNewPoints[0];
      } else {
        tC2 = tNewPoints[tI + 1];
      }
      tSum += (tC2.x - tC1.x) * (tC2.y + tC1.y);
    }
    return (tSum >= 0);
  }

  /** Creates a Guid.
   * @returns A new Guid.
   */
  static guid() {
    const tS4 = function () {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16).slice(1);
    };

    return `${tS4() + tS4()}-${tS4()}-${tS4()}-${tS4()}-${tS4()}${tS4()}${tS4()}`;
  }

  /** both arguments are arrays of corners with x,y attributes */
  static polygonPolygonIntersect(firstCorners, secondCorners) {
    for (let tI = 0; tI < firstCorners.length; tI++) {
      const tFirstCorner = firstCorners[tI];
      var tSecondCorner;

      if (tI === firstCorners.length - 1) {
        tSecondCorner = firstCorners[0];
      } else {
        tSecondCorner = firstCorners[tI + 1];
      }

      if (Utils.linePolygonIntersect(
        tFirstCorner.x, tFirstCorner.y,
        tSecondCorner.x, tSecondCorner.y,
        secondCorners
      )) {
        return true;
      }
    }
    return false;
  }

  /** Corners is an array of points with x,y attributes */
  static linePolygonIntersect(x1, y1, x2, y2, corners) {
    for (let tI = 0; tI < corners.length; tI++) {
      const tFirstCorner = corners[tI];
      var tSecondCorner;
      if (tI === corners.length - 1) {
        tSecondCorner = corners[0];
      } else {
        tSecondCorner = corners[tI + 1];
      }

      if (Utils.lineLineIntersect(x1, y1, x2, y2,
        tFirstCorner.x, tFirstCorner.y,
        tSecondCorner.x, tSecondCorner.y)) {
        return true;
      }
    }
    return false;
  }

  /** */
  static lineLineIntersect(x1, y1, x2, y2, x3, y3, x4, y4) {
    function tCCW(p1, p2, p3) {
      const tA = p1.x;
      const tB = p1.y;
      const tC = p2.x;
      const tD = p2.y;
      const tE = p3.x;
      const tF = p3.y;
      return (tF - tB) * (tC - tA) > (tD - tB) * (tE - tA);
    }

    const tP1 = {
      x: x1,
      y: y1
    };
    const tP2 = {
      x: x2,
      y: y2
    };
    const tP3 = {
      x: x3,
      y: y3
    };
    const tP4 = {
      x: x4,
      y: y4
    };
    return (tCCW(tP1, tP3, tP4) !== tCCW(tP2, tP3, tP4)) && (tCCW(tP1, tP2, tP3) !== tCCW(tP1, tP2, tP4));
  }

  /**
    @param corners Is an array of points with x,y attributes
    @param startX X start coord for raycast
    @param startY Y start coord for raycast
  */
  static pointInPolygon(x, y, corners, startX, startY) {
    // startX = startX || 0;
    // startY = startY || 0;

    // ensure that point(startX, startY) is outside the polygon consists of corners
    let tMinX = 0;
    let tMinY = 0;

    if (startX === undefined || startY === undefined) {
      for (var tI = 0; tI < corners.length; tI++) {
        tMinX = Math.min(tMinX, corners[tI].x);
        tMinY = Math.min(tMinX, corners[tI].y);
      }
      startX = tMinX - 10;
      startY = tMinY - 10;
    }

    let tIntersects = 0;
    for (tI = 0; tI < corners.length; tI++) {
      const tFirstCorner = corners[tI];
      var tSecondCorner;
      if (tI === corners.length - 1) {
        tSecondCorner = corners[0];
      } else {
        tSecondCorner = corners[tI + 1];
      }

      if (Utils.lineLineIntersect(startX, startY, x, y,
        tFirstCorner.x, tFirstCorner.y,
        tSecondCorner.x, tSecondCorner.y)) {
        tIntersects++;
      }
    }
    // odd intersections means the point is in the polygon
    return ((tIntersects % 2) === 1);
  }

  /** Checks if all corners of insideCorners are inside the polygon described by outsideCorners */
  static polygonInsidePolygon(insideCorners, outsideCorners, startX, startY) {
    startX = startX || 0;
    startY = startY || 0;

    for (const element of insideCorners) {
      if (!Utils.pointInPolygon(
        element.x, element.y,
        outsideCorners,
        startX, startY
      )) {
        return false;
      }
    }
    return true;
  }

  /** Checks if any corners of firstCorners is inside the polygon described by secondCorners */
  static polygonOutsidePolygon(insideCorners, outsideCorners, startX, startY) {
    startX = startX || 0;
    startY = startY || 0;

    for (const element of insideCorners) {
      if (Utils.pointInPolygon(
        element.x, element.y,
        outsideCorners,
        startX, startY
      )) {
        return false;
      }
    }
    return true;
  }

  // arrays

  static forEach(array, action) {
    for (const element of array) {
      action(element);
    }
  }

  static forEachIndexed(array, action) {
    for (const [tI, element] of array.entries()) {
      action(tI, element);
    }
  }

  static map(array, func) {
    const tResult = [];
    array.forEach((element) => {
      tResult.push(func(element));
    });
    return tResult;
  }

  /** Remove elements in array if func(element) returns true */
  static removeIf(array, func) {
    const tResult = [];
    array.forEach((element) => {
      if (!func(element)) {
        tResult.push(element);
      }
    });
    return tResult;
  }

  /** Shift the items in an array by shift (positive integer) */
  static cycle(arr, shift) {
    const tReturn = arr.slice(0);
    for (let tI = 0; tI < shift; tI++) {
      const tmp = tReturn.shift();
      tReturn.push(tmp);
    }
    return tReturn;
  }

  /** Returns in the unique elemnts in arr */
  static unique(arr, hashFunc) {
    const tResults = [];
    const tMap = {};
    for (const element of arr) {
      if (!tMap.hasOwnProperty(element)) {
        tResults.push(element);
        tMap[hashFunc(element)] = true;
      }
    }
    return tResults;
  }

  /** Remove value from array, if it is present */
  static removeValue(array, value) {
    for (let tI = array.length - 1; tI >= 0; tI--) {
      if (array[tI] === value) {
        array.splice(tI, 1);
      }
    }
  }

  /** Checks if value is in array */
  static hasValue = function (array, value) {
    for (const element of array) {
      if (element === value) {
        return true;
      }
    }
    return false;
  };

  /** Subtracts the elements in subArray from array */
  static subtract(array, subArray) {
    return Utils.removeIf(array, (el) => {
      return Utils.hasValue(subArray, el);
    });
  }
}
