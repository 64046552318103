import categoriesApi from 'api/category';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import getPayload from './get-payload';
import { CategoryImageFormActions } from '../reducer';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/category/`;

function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length - 1;
  const u8arr = new Uint8Array(bstr.length);
  while (n) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default function saveImage(dispatch, urlImage, style) {
  return () => {
    dispatch({ type: CategoryImageFormActions.START_SAVING });
    const image = dataURLtoFile(urlImage, `${style.name}.jpg`);
    const url = `${endpoint}${style.name}/`;

    const onSuccess = () => {
      const message = 'Products Successfully added';
      notification({ type: NotificationType.SUCCESS, message });
      dispatch({ type: CategoryImageFormActions.FINISH_SAVING });
    };

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
      dispatch({ type: CategoryImageFormActions.FINISH_SAVING });
    };

    return categoriesApi.get(url)
      .then(response => categoriesApi.update(getPayload(response.data, image, url)))
      .then(onSuccess)
      .catch(onError);
  };
}
