import React, { FunctionComponent, useMemo } from 'react';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import { useHistory } from 'react-router-dom';
import { validationRules } from './validations';
import useShippingZoneFormState from './state';

export interface ShippingZoneFormHistorySateModel {
  shippingZoneForm: {
    shippingZoneId: number;
  };
}

const ShippingZoneForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { shippingZoneForm: { shippingZoneId } } = state as ShippingZoneFormHistorySateModel;

  const {
    state: {
      savingShippingZone,
      shippingZone,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveShippingZone,
      setErrors,
    },
  } = useShippingZoneFormState(shippingZoneId);

  const modalTitle = useMemo(
    () => shippingZoneId ? 'Edit Shipping Zone' : 'Create Shipping Zone',
    [shippingZoneId],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveShippingZone}
      confirmLoading={savingShippingZone}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={shippingZone}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="zoneName"
            name="zoneName"
            label="Name"
            placeholder="Enter Zone Name"
          />
          <Field
            type="number"
            name="zoneBaseFee"
            id="zoneBaseFee"
            label="Base Fee"
            placeholder="Enter Base Fee"
          />
          <Field
            type="number"
            name="zonePercentage"
            id="zonePercentage"
            label="Percentage"
            placeholder="Enter Percentage"
            min={0}
            max={100}
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default ShippingZoneForm;
