import React, { FunctionComponent, useEffect, useRef } from 'react';
import useUniqueId from 'hooks/ui/unique-id';
import ChartContext from './context';

interface Props {
  /** The values to represent in the chart */
  values: number[];
  /** The title for the dataset which appears in the legend and tooltips. */
  title: string;
  /** The fill color under the line. */
  backgroundColor?: string | string[];
  /** The color of the line, if left undefined, the backgroundColor is used */
  borderColor?: string | string[];
  /** The ID of the group to which this dataset belongs to
   * (when stacked, each group will be a separate stack) */
  stack?: string;
  /** Whether to fill the area under the line */
  fill?: boolean;
}

const Dataset: FunctionComponent<Props> = (props) => {
  const { title: label, values: data, ...rest } = props;
  const { registerDataset, unregisterDataset, updateDataset } = React.useContext(ChartContext);
  const isRegistered = useRef<boolean>(false);
  const datasetId = useUniqueId('dataset');

  useEffect(() => {
    if (isRegistered.current) {
      updateDataset(datasetId, {
        label,
        data,
        ...rest,
      });
    }
  });

  useEffect(() => {
    registerDataset(datasetId, {
      label,
      data,
      ...rest,
    });
    isRegistered.current = true;

    return () => {
      if (isRegistered.current) {
        unregisterDataset(datasetId);
        isRegistered.current = false;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

Dataset.defaultProps = {
  values: [],
  title: 'Dataset',
  backgroundColor: undefined,
  borderColor: undefined,
  stack: undefined,
  fill: false,
};

export default Dataset;
