import { MorphModel } from 'models/three-model';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/morph/`;

const morphApi: ApiModel<MorphModel> = getApi(
  endpoint,
  ApiContentType.JSON,
);

export default morphApi;
