import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { ProductionManagerModel } from 'models/production-manager';
import { InstallerBookingModel } from 'models/installer-booking';
import installerBookingApi from 'api/installer-booking';
import hasPermissions from 'helpers/permissons';
import useCallApiAction from 'hooks/base/call-api-action';
import useTableState from 'hooks/ui/table-state';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import printInstallerOrder from './actions/print-installer-order';
import printShopOrder from './actions/print-shop-order';
import printDeficiencyReport from './actions/print-deficiency-report';
import onSubmit from './actions/handle-on-submit';
import openPOItemsProd from './actions/open-purchase-order';
import { orderTableStateParams, productionStatusOptions } from '../constants';
import openNotesModal from '../../ProjectCoordinator/state/actions/open-notes-form';

export default function useProductionManagerState() {
  const { push } = useHistory();
  const [showCalender, setShowCalendar] = useState(false);
  const { state, actions } = useTableState<ProductionManagerModel>(orderTableStateParams);
  const { fetching: fetchingOrders, data: orders } = state;
  const { updateDataManually } = actions;

  const installerParams = useMemo(() => {
    const projectIds = !fetchingOrders
      ? orders.map((order) => order.projectId)
      : [];
    const projectsIDsSet = [...new Set(projectIds)];
    const queryTemplate = projectsIDsSet.join(',');

    return {
      filters: {
        shipment__project__in: queryTemplate,
      }
    };
  }, [fetchingOrders, orders]);

  const {
    fetching: fetchingInstallers,
    data: { results: installers },
    error: installersError,
  } = useCallApiAction<{ results: InstallerBookingModel []}>(
    installerBookingApi.list,
    installerParams,
    { results: [] },
    fetchingOrders || orders.length === 0,
  );
  const updateDataTable = useCallback((response) => {
    const order = response.data || response;
    updateDataManually(orders.map(item => {
      if (item.id === order.id) {
        return {
          ...item,
          ...order,
        };
      }
      return item;
    }));
  }, [orders, updateDataManually]);

  useEffect(() => {
    if (installersError) {
      notification({
        type: NotificationType.ERROR,
        message: installersError,
      });
    }
  }, [installersError]);

  useEventCenterUpdate(EventChannelList.PRODUCTION_MANAGER_CHANGED, updateDataTable);
  useEventCenterUpdate(EventChannelList.PRODUCTION_MANAGER_DROPPED, updateDataTable);
  useEventCenterUpdate(EventChannelList.UPDATE_NOTE_COUNT, updateDataTable);

  return {
    state: {
      ...state,
      showCalender,
      productionStatusOptions,
      fetching: (installers.length === 0 && fetchingInstallers) || fetchingOrders,
      filtersProductOption: useMemo(() => productionStatusOptions.map(opt => (
        { value: opt.value, text: opt.label }
      )), []),
      data: useMemo(() => orders.map((order) => {
        const orderFormatted = {
          ...order,
          cncPercentage: order.cncPercentage * 100,
          fabricationPercentage: order.fabricationPercentage * 100,
        };
        const installer = installers.filter((i) => i.shipment.project === order.projectId);

        if (installer) {
          return {
            ...orderFormatted,
            installersBooking: installer.map(inst => ({
              installer: inst.installer.companyName,
              installationDate: inst.installationDate,
            })),
          };
        }
        return orderFormatted;
      }), [orders, installers]),
      linkToOrder: useMemo(() => hasPermissions(['Sales-Orders', 'Dealer', 'Sales']) ? (order) => `quote/${order.quoteId}` : undefined, [])
    },
    actions: {
      ...actions,
      printInstallerOrder,
      printShopOrder,
      printDeficiencyReport,
      onSubmit,
      updateDataTable,
      openNotesModal: useCallback(openNotesModal(push), []),
      handleShowCalendar: useCallback(() => setShowCalendar((oldState) => !oldState), []),
      openPOItemsProd: useCallback(openPOItemsProd(push), []),
    }
  };
}
