import { useCallback, useMemo, useReducer } from 'react';
import reportApi from 'api/report';
import incomeCustomerGroupReducer from './reducer';
import {
  getIncomeCustomerParams,
  initialState,
} from '../../common';
import { setIncomeCustomerGroupValue } from './actions/set-forms-value';

const axisY = [
  { title: 'total', borderColor: '#01b6f5' },
  { title: 'accounts', borderColor: '#fe4849' },
];

const incomeCustomerGroupParams = {
  action: reportApi.incomeCustomerGroup,
  label: 'name',
  pieFrame: 'accounts',
  axisY,
};

export default function useCustomerGroupIncome() {
  const [state, dispatch] = useReducer(
    incomeCustomerGroupReducer,
    {
      formIncomeCustomerGroupData: initialState,
    },
  );

  const { formIncomeCustomerGroupData } = state;

  return {
    state: {
      ...state,
      incomeCustomerGroupParams: useMemo(() => ({
        ...incomeCustomerGroupParams,
        queryParams: getIncomeCustomerParams(formIncomeCustomerGroupData)
      }), [formIncomeCustomerGroupData]),
    },
    actions: {
      setIncomeCustomerGroupValue: useCallback(setIncomeCustomerGroupValue(dispatch), []),
    }
  };
}
