export enum CopyQuoteFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_COPING = 'START_COPING',
  FINISH_COPING = 'FINISH_COPING',
}

interface CopyQuoteFormState {
  formData: {comments: string};
  coping: boolean;
  errors: any;
}

export default function copyQuoteFormReducer(state: CopyQuoteFormState, action): CopyQuoteFormState {
  const { type, payload } = action;

  switch (type as CopyQuoteFormActions) {
    case CopyQuoteFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case CopyQuoteFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, coping: false };
    case CopyQuoteFormActions.START_COPING:
      return { ...state, coping: true };
    case CopyQuoteFormActions.FINISH_COPING:
      return { ...state, coping: false };
    default:
      return state;
  }
}
