import React from 'react';
import Tag from 'components/base-components/Tag';

const tagColor = {
  deficiency: 'green',
  order: 'blue',
  quote: 'cyan',
};

export default function tagRender(type) {
  return (<Tag color={tagColor[type]}>{type}</Tag>);
}
