export enum cutListFormActions {
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_TARGET_KEYS = 'SET_TARGET_KEYS',
  SET_ERRORS = 'SET_ERRORS',
  UPDATE_STATE = 'UPDATE_STATE',
  START_UPDATING = 'START_UPDATING',
  FINISH_UPDATING = 'FINISH_UPDATING',
}

export interface CutListFormState {
  createForm: { id?: number; name: string };
  targetKeys: string[];
  dataRow: any[];
  setKeys: string[];
  errors: any;
  updating: boolean;
}

export default function cutListReducer(state: CutListFormState, action): CutListFormState {
  const { type, payload } = action;

  switch (type as cutListFormActions) {
    case cutListFormActions.SET_FORM_VALUE:
      return { ...state, createForm: payload };
    case cutListFormActions.SET_TARGET_KEYS:
      return {
        ...state,
        targetKeys: payload.keys,
        dataRow: payload.dataRow,
        setKeys: state.setKeys.filter(e => payload.keys.includes(e)),
      };
    case cutListFormActions.UPDATE_STATE:
      return {
        ...state,
        targetKeys: payload.keys,
        dataRow: payload.dataRow,
        setKeys: payload.keys,
        createForm: payload.createForm,
      };
    case cutListFormActions.SET_ERRORS:
      return { ...state, errors: payload };
    case cutListFormActions.START_UPDATING:
      return { ...state, updating: true };
    case cutListFormActions.FINISH_UPDATING:
      return { ...state, updating: false };
    default:
      return state;
  }
}
