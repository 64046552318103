function userTemplate(field) {
  return (rowData) => {
    const user = rowData[field];

    if (user) {
      const { firstName, lastName } = user;
      return `${firstName} ${lastName}`;
    }
    return '';
  };
}

export const ownerTemplate = userTemplate('ownerDetail');
export const salesRepTemplate = userTemplate('salesRepDetail');
