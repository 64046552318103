import React, { FunctionComponent } from 'react';
import { Empty as AntEmpty } from 'antd';
import { EmptyProps } from 'antd/lib/empty';

export const { PRESENTED_IMAGE_DEFAULT, PRESENTED_IMAGE_SIMPLE } = AntEmpty;

const Empty: FunctionComponent<EmptyProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntEmpty {...rest}>
      {children}
    </AntEmpty>
  );
};

export default Empty;
