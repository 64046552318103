import cutListApi from 'api/cut-list';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { validateEntity } from 'helpers/form-validations';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { cutListFormActions } from '../reducer';
import { validationRules } from '../../validations';

export default function onSubmit(
  dispatch,
  formData: { id?: number; name: string },
  targetKeys: string[],
  goBack,
) {
  return () => {
    const { hasErrors, errors } = validateEntity(formData, validationRules);
    if (hasErrors) {
      return dispatch({
        type: cutListFormActions.SET_ERRORS,
        payload: errors,
      });
    }

    dispatch({ type: cutListFormActions.START_UPDATING });
    const mappedSources = targetKeys.map(key => {
      const [sourceType, sourceId] = key.split('-');
      return { sourceType, sourceId };
    });
    const sourcesPayload = { sources: mappedSources };

    const onSuccess = () => {
      const message = !!formData.id
        ? 'Cutlist successfully Edited'
        : 'Cutlist successfully Created';

      notification({ type: NotificationType.SUCCESS, message });
      notifyEventChannel(EventChannelList.CUT_LIST_CHANGED);
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: cutListFormActions.FINISH_UPDATING });
    };

    const action = formData.id ? cutListApi.patch : cutListApi.create;

    return action(formData)
      .then(({ data }) => cutListApi.defineCutList(data.id, sourcesPayload))
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
