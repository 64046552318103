/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useMemo } from 'react';
import MetisMenu from 'metismenujs';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import RenderIf from 'components/base-components/RenderIf';
import { getLayoutPermissions } from './permissions';

const activateParentDropdown = item => {
  item.classList.add('active');
  const parent = item.parentElement;

  if (parent) {
    parent.classList.add('mm-active');
    const parent2 = parent.parentElement;

    if (parent2) {
      parent2.classList.add('mm-show');

      const parent3 = parent2.parentElement;

      if (parent3) {
        parent3.classList.add('mm-active'); // li
        parent3.childNodes[0].classList.add('mm-active'); // a
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('mm-active');
        }
      }
    }
    return false;
  }
  return false;
};

const initMenu = (location) => {
  // eslint-disable-next-line no-new
  new MetisMenu('#side-menu');

  let matchingMenuItem = null;
  const ul = document.getElementById('side-menu');
  const items = ul.getElementsByTagName('a');
  // eslint-disable-next-line no-restricted-syntax
  for (const element of items) {
    if (location.pathname === element.pathname) {
      matchingMenuItem = element;
      break;
    }
  }
  if (matchingMenuItem) {
    activateParentDropdown(matchingMenuItem);
  }
};

export default function SidebarContent() {
  const { pathname } = useLocation();
  const sideBarPermissions = useMemo(() => getLayoutPermissions(), []);

  useEffect(() => initMenu(pathname), [pathname]);

  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">
          <FormattedMessage id="Menu" />
        </li>
        <li>
          <Link to="/dashboard" className="waves-effect">
            <i className="bx bx-home-circle" />
            <span className="badge badge-pill badge-info float-right">03</span>
            <span>
              <FormattedMessage id="Dashboards" />
            </span>
          </Link>
        </li>

        <li className="menu-title">Pages</li>
        <RenderIf isTrue={sideBarPermissions.shouldShowAccountSection}>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-user-detail" />
              <span>Account</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <RenderIf isTrue={sideBarPermissions.account}>
                <li>
                  <Link to="/accounts">Accounts</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.accountGroup}>
                <li>
                  <Link to="/account-groups">Account Groups</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.accountApplication}>
                <li>
                  <Link to="/account-applications">Account Applications</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.terms}>
                <li>
                  <Link to="/terms">Terms</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.user}>
                <li>
                  <Link to="/users">Users</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.userGroup}>
                <li>
                  <Link to="/user-groups">User Groups</Link>
                </li>
              </RenderIf>
            </ul>
          </li>
        </RenderIf>
        <RenderIf isTrue={sideBarPermissions.shouldShowCatalogSections}>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-purchase-tag" />
              <span>Catalog</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <RenderIf isTrue={sideBarPermissions.category}>
                <li>
                  <Link to="/categories">Categories</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.manufacturer}>
                <li>
                  <Link to="/manufacturers">Manufacturers</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.options}>
                <li>
                  <Link to="/options">Options</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.optionGroup}>
                <li>
                  <Link to="/option-groups">Option Groups</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.product}>
                <li>
                  <Link to="/products">Products</Link>
                </li>
              </RenderIf>
            </ul>
          </li>
        </RenderIf>
        <RenderIf isTrue={sideBarPermissions.shouldShowInventorySection}>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-cabinet" />
              <span>Inventory</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <RenderIf isTrue={sideBarPermissions.inventory}>
                <li>
                  <Link to="/inventory">Inventory</Link>
                </li>
              </RenderIf>
            </ul>
          </li>
        </RenderIf>
        <RenderIf isTrue={sideBarPermissions.shouldShowProductionsSections}>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-book-content" />
              <span>Production</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <RenderIf isTrue={sideBarPermissions.cncCalc}>
                <li>
                  <Link to="/cnc-calc">CNC Calc</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.cncCalc}>
                <li>
                  <Link to="/cnc-worker">CNC Worker</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.cutList}>
                <li>
                  <Link to="/cutlists">Cutlist</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.installerBooking}>
                <li>
                  <Link to="/installer-booking">Installer Booking</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.parts}>
                <li>
                  <Link to="/parts">Parts</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.purchaseOrder}>
                <li>
                  <Link to="/purchase-orders">Purchase Orders</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.productionManager}>
                <li>
                  <Link to="/production-manager">Production Manager</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.projectCoordinator}>
                <li>
                  <Link to="/project-coordinator">Project Coordinator</Link>
                </li>
              </RenderIf>
            </ul>
          </li>
        </RenderIf>
        <RenderIf isTrue={sideBarPermissions.reports}>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-report" />
              <span>Reports</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/reports">
                  <span>Summary Reports</span>
                </Link>
              </li>
              <li>
                <Link to="/sales-report">
                  <span>Sales Reports</span>
                </Link>
              </li>
            </ul>
          </li>
        </RenderIf>
        <RenderIf isTrue={sideBarPermissions.shouldShowSalesSection}>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-coin-stack" />
              <span>Sales</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <RenderIf isTrue={sideBarPermissions.deficiencyOrder}>
                <li>
                  <Link to="/deficiency-orders">Deficiency Orders</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.invoice}>
                <li>
                  <Link to="/invoices">Invoices</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.order}>
                <li>
                  <Link to="/orders">Orders</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.payments}>
                <li>
                  <Link to="/payments">Payments</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.projectDesigns}>
                <li>
                  <Link to="/project-designs">Project Designs</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.quote}>
                <li>
                  <Link to="/quotes">Quotes</Link>
                </li>
              </RenderIf>
            </ul>
          </li>
        </RenderIf>
        <RenderIf isTrue={sideBarPermissions.shouldShowPipelineSection}>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-package" />
              <span>Sales Pipeline</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <RenderIf isTrue={sideBarPermissions.crateProject}>
                <li>
                  <Link to="/create-project">Create Project</Link>
                </li>
              </RenderIf>
            </ul>
          </li>
        </RenderIf>
        <RenderIf isTrue={sideBarPermissions.shouldShowSettingsSection}>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="fa fa-cog" />
              <span>Settings</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <RenderIf isTrue={sideBarPermissions.shouldShowStoreSubSection}>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <span>Account</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <RenderIf isTrue={sideBarPermissions.store}>
                      <li>
                        <Link to="/stores">Stores</Link>
                      </li>
                    </RenderIf>
                  </ul>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.attachmentDocument}>
                <li>
                  <Link to="/attachment-document">Attachment Document</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.shouldShowCatalogSubSection}>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <span>Catalog</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <RenderIf isTrue={sideBarPermissions.categoryImage}>
                      <li>
                        <Link to="/category-image">Category Image</Link>
                      </li>
                    </RenderIf>
                    <RenderIf isTrue={sideBarPermissions.generateProducts}>
                      <li>
                        <Link to="/generate-products">Generate Products</Link>
                      </li>
                    </RenderIf>
                  </ul>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.shouldShowMaterialsSubSection}>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <span>Materials</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <RenderIf isTrue={sideBarPermissions.sheet}>
                      <li>
                        <Link to="/sheets">Sheet Setting</Link>
                      </li>
                    </RenderIf>
                  </ul>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.propertySetting}>
                <li>
                  <Link to="/property-setting">Property Setting</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.qboSync}>
                <li>
                  <Link to="/qbo-sync">QBO Sync</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.shouldShowShippingSubSection}>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <span>Shipping</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <RenderIf isTrue={sideBarPermissions.installer}>
                      <li>
                        <Link to="/installer">Installers</Link>
                      </li>
                    </RenderIf>
                    <RenderIf isTrue={sideBarPermissions.shippingZone}>
                      <li>
                        <Link to="/shipping-zones-setting">Shipping Zones</Link>
                      </li>
                    </RenderIf>
                  </ul>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.taskTemplate}>
                <li>
                  <Link to="/task-templates">Task Templates</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.textures}>
                <li>
                  <Link to="/textures">Textures</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.threeModel}>
                <li>
                  <Link to="/three-models">Three Models</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.tutorials}>
                <li>
                  <Link to="/tutorials">Tutorial Videos</Link>
                </li>
              </RenderIf>
              <RenderIf isTrue={sideBarPermissions.viewsPermissions}>
                <li>
                  <Link to="/views-permissions">Views Permissions</Link>
                </li>
              </RenderIf>
            </ul>
          </li>
        </RenderIf>
      </ul>
    </div>
  );
}
