const store = [];

function generate() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-bitwise,no-mixed-operators
    const v = c === 'x' ? r : (r & 0x3 | 0x8);

    return v.toString(16);
  });
}

export function generateUID(): string {
  let uid = generate();
  let exists = store.some((item) => item === uid);

  if (exists) {
    while (exists) {
      uid = generate();
      // eslint-disable-next-line no-loop-func
      exists = store.some((item) => item === uid);
    }
  }

  store.push(uid);

  return uid;
}
