import React, { useCallback, useState } from 'react';
import { Typography } from 'antd';
import { Resizable } from 're-resizable';
import { useHistory } from 'react-router';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import Modal from 'components/base-components/ModalV2';
import Select from 'components/base-components/Select';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import ProductViewer from 'components/base-components/ProductViewer';
import PropertiesPanel from 'components/base-components/PropertiesPanel';
import useAddProduct3DFormState from './state';
import ImageScene from './ImageScene';
import './styles.scss';

export interface AddProductsMandatory {
  quote: number;
  groups: any[];
  discount?: number;
}

export interface AddProduct3DHistorySateModel {
  addProduct3DForm?: AddProductsMandatory;
  readonly?: boolean;
}

const enable = { bottom: true, top: true };

export default function AddProduct3D() {
  const { goBack, location: { state: stateNavigation } } = useHistory();
  const {
    addProduct3DForm,
    readonly
  } = stateNavigation as AddProduct3DHistorySateModel;
  const [sizeState, setSizeState] = useState({ height: 300, width: '100%' });
  const onResize = (_, __, ___, d) => {
    setSizeState((oldSize) => ({
      width: '100%',
      height: oldSize.height + d.height,
    }));
  };

  const {
    state: {
      categories,
      loadingCategories,
      secondLevel,
      category,
      style,
      fetchingProductData,
      selectedProduct,
      staticForm,
      groupsOptions,
      adding,
      fetchingPrice,
      totalPrice,
      thirdLevelOptions,
      secondLevelOptions,
      haveAnotherLevelProperty,
      loadingThirdLevel,
    },
    actions: {
      onChangeCategory,
      onStyleChange,
      onChangeSecondLevel,
      updateOptions,
      updateProperties,
      setStaticFormData,
      saveProduct,
      openCustomOptionModal
    }
  } = useAddProduct3DFormState(addProduct3DForm);

  const openNewGroupModal = useCallback(() => {
    notifyEventChannel(EventChannelList.QUOTE_PAGE_OPEN_GROUP_MODAL);
  }, []);

  const footer = readonly ? true : undefined;
  const onCustomOption = readonly ? undefined : openCustomOptionModal;

  return (
    <Modal
      visible
      title="Add Product 3D"
      onCancel={goBack}
      okText="Submit"
      onOk={saveProduct}
      maskClosable={false}
      confirmLoading={adding}
      footer={footer}
      width={900}
    >
      <div className="add-product-3d__wrapper">
        <div className="add-product-3d__section">
          <div className="add-product-3d__scene">
            <Select
              id="categories"
              onChange={onChangeCategory}
              value={category}
              options={categories}
              loading={loadingCategories}
              label="Categories"
            />
            <RenderIf isTrue={!haveAnotherLevelProperty}>
              <Select
                id="style"
                onChange={onStyleChange}
                value={style}
                options={secondLevelOptions}
                label="Style"
              />
            </RenderIf>
            <RenderIf isTrue={!!haveAnotherLevelProperty}>
              <Select
                id="secondLevel"
                onChange={onChangeSecondLevel}
                value={secondLevel}
                options={secondLevelOptions}
                label={haveAnotherLevelProperty as any}
              />
              <Select
                id="style"
                onChange={onStyleChange}
                value={style}
                options={thirdLevelOptions}
                loading={loadingThirdLevel}
                label="Style"
              />
            </RenderIf>
            <RenderIf isTrue={!readonly}>
              <Form onChange={setStaticFormData} state={staticForm}>
                <Field
                  type="number"
                  name="quantity"
                  id="quantity"
                  label="Quantity"
                />
                <Field
                  component={Select}
                  name="group"
                  id="group"
                  label="Group"
                  options={groupsOptions}
                  onAddClick={openNewGroupModal}
                />
              </Form>
            </RenderIf>
            <div className="add-product-3d__price-wrapper">
              <div>
                <Typography.Text strong>
                  {'Price: '}
                </Typography.Text>
                {totalPrice}
              </div>
              <RenderIf isTrue={fetchingPrice}>
                <Spinner centered={false} />
              </RenderIf>
            </div>
          </div>
          <div className="add-product-3d__properties-panel">
            <PropertiesPanel
              blockUI={fetchingProductData}
              product={selectedProduct}
              onOptionsChange={updateOptions}
              onPropertiesChange={updateProperties}
              onCustomOption={onCustomOption}
              asRow
            />
          </div>
        </div>
        <RenderIf isTrue={!selectedProduct || !!selectedProduct?.threeModel}>
          <Resizable
            className="add-product-3D__product-viewer__resizable"
            minHeight={300}
            enable={enable}
            size={sizeState}
            onResizeStop={onResize}
          >
            <div className="add-product-3D__product-viewer__wrapper">
              <ProductViewer blockUI={fetchingProductData} product={selectedProduct} height={sizeState.height} />
            </div>
          </Resizable>
        </RenderIf>
        <RenderIf isTrue={!!selectedProduct && !selectedProduct?.threeModel}>
          <ImageScene selectedProduct={selectedProduct} />
        </RenderIf>
      </div>
    </Modal>
  );
}
