import CASES_TEST_SET_1 from './Test Set 1/restrictions';

export const CASES = [...CASES_TEST_SET_1];

export const getChanges = async (setId, itemId, itemName) => {
  return new Promise((resolve) => {
    // console.log("get changes", setId, itemId, itemName);
    const res = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of CASES) {
      if (item.id === itemId) {
        if (item.product_names.length > 1) {
          if (item.product_names.includes(itemName)) {
            res.push(...item.changes);
          }
        } else if (item.product_names.length === 1) {
          if (item.product_names[0] === '*') {
            res.push(...item.changes);
          }
          if (!itemName && !item.product_names[0]) {
            res.push(...item.changes);
          }
        }
      }
    }
    resolve(res);
  });
};
