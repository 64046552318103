import { OrderProductModel } from 'models/order';

export function getState(product: OrderProductModel) {
  const formData = {};
  if (product?.options) {
    product.options.forEach(option => {
      const options = option.optionSelected.split('_');
      const mainOption = options[0];
      const suboption = options[1];
      formData[option.optionType] = { value: mainOption, label: mainOption };
      if (!!suboption) {
        formData[`sub-${option.optionType}`] = { value: suboption, label: suboption };
      }
    });
  }
  return formData;
}
