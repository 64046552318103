import getEventValue from 'helpers/get-event-value';
import { FormActions } from '../reducer';

export default function onChangeHiddenColumns(dispatch, localStorageKey) {
  return (event) => {
    const value = getEventValue(event);
    const width = window?.innerWidth;
    const hiddenColumns = value.map(e => e.value);
    localStorage.setItem(localStorageKey, JSON.stringify({ width, hiddenColumns }));
    dispatch({ type: FormActions.SET_HIDDEN_COLUMNS, payload: value });
  };
}
