import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import termApi from 'api/term';
import { TermModel } from 'models/term';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import Help from 'components/forms/CommonHelp';
import Button from 'components/base-components/Button';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { TermFormHistorySateModel } from '../Form';
import { permissions } from './permissions';
import percentTemplate from './templates/percent-template';

const useTableStateParams: UseTableStateParams = {
  api: termApi,
  deleteModalTitle: 'Delete Term',
  deleteModalContent: (termToDelete: TermModel) => (
    `Are you sure that you want to delete the term ${termToDelete ? termToDelete.termsName : ''}?`
  ),
  searchFields: ['terms_name', 'terms_details', 'days'],
  eventChannel: EventChannelList.TERMS_LIST_CHANGED,
};

const TermsList: FunctionComponent = () => {
  const { push } = useHistory();

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
    }
  } = useTableState<TermModel>(useTableStateParams);

  const openTermFormModal = (term?: TermModel | any) => {
    const historyLocationState: TermFormHistorySateModel = {
      termForm: {
        termUrl: term ? term.url : undefined,
      },
    };
    push(ModalRouteHash.TermForm, historyLocationState);
  };

  return (
    <Page title="Terms">
      <Help view="term" title="Terms List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addTerm}>
          <Button
            onClick={openTermFormModal}
            label="Add Term"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn dataIndex="termsName" columnKey="terms_name" title="Name" sorter />
        <DataTableColumn dataIndex="termsDetails" columnKey="terms_details" title="Details" sorter />
        <DataTableColumn
          dataIndex="days"
          columnKey="days"
          title="Days"
          sorter
          showBreakpoint={800}
        />
        <DataTableColumn
          isCurrency
          dataIndex="onOrderConversion"
          columnKey="onOrderConversion"
          title="On Conversion"
          render={percentTemplate}
          showBreakpoint={1176}
        />
        <DataTableColumn
          isCurrency
          dataIndex="onProductionCommencing"
          columnKey="onProductionCommencing"
          title="On Production"
          render={percentTemplate}
          showBreakpoint={1304}
        />
        <DataTableColumn
          isCurrency
          dataIndex="onLeavingForDelivery"
          columnKey="onLeavingForDelivery"
          title="On Delivery"
          render={percentTemplate}
          showBreakpoint={1405}
        />
        <DataTableColumn
          isCurrency
          dataIndex="onInstallComplete"
          columnKey="onInstallComplete"
          title="On Install"
          render={percentTemplate}
          showBreakpoint={1498}
        />
        <DataTableColumn
          isCurrency
          dataIndex="onOrderCompletion"
          columnKey="onOrderCompletion"
          title="On Completion"
          render={percentTemplate}
          showBreakpoint={1629}
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editTerm}
          deletePermissions={permissions.deleteTerm}
          onEdit={openTermFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default TermsList;
