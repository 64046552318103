import { Answers, AnswersModel } from '../../types';
import { FormAddressModel } from '../reducer';

function mapAnswersToBoolean(fields) {
  return Object.entries(fields).reduce((collection, field) => {
    const [key, value] = field;

    if (typeof value === 'number') {
      return { ...collection, [key]: value === Answers.Yes };
    }

    return { ...collection, [key]: value };
  }, {} as any);
}

export function getAnswersPayload(answers: AnswersModel) {
  const {
    id,
    specificHoursStart,
    specificHoursEnd,
    stairs,
    accessMethod,
    ...otherAnswers
  } = answers;
  const mappedAnswers = mapAnswersToBoolean(otherAnswers);

  return {
    ...mappedAnswers,
    id,
    specificHoursStart: mappedAnswers.specificDeliveryHours && specificHoursStart
      ? specificHoursStart
      : undefined,
    specificHoursEnd: mappedAnswers.specificDeliveryHours && specificHoursEnd
      ? specificHoursEnd
      : undefined,
    stairs,
    accessMethod: accessMethod.id,
  };
}

export function getAddressPayload(addressInfo: FormAddressModel) {
  const {
    province,
    ...otherAddressFields
  } = addressInfo;

  return {
    ...otherAddressFields,
    province: province?.value,
  };
}
