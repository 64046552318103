/* eslint-disable */
import { dimInch } from "./dimensioning";
import Core from ".";

export const configDimUnit = "dimUnit";

export const configDefaultPathPrefix = "defaultPathPrefix";
// WALL:

/** The initial wall height in cm. */
export const configWallHeight = "wallHeight";

/** The initial wall thickness in cm. */
export const configWallThickness = "wallThickness";

export const configSceneLocked = "scene-locked";

export const configXRayMode = "xRay";

export const configSnapMode = "snap";

export const configDimensionVisible = "dimensionVisible";

const REACT_APP_BLUEPRINT_ASSETS_PREFIX = "https://blueprint3d-react-dist.netlify.app/";
/** Global configuration to customize the whole system.  */
export class Configuration {
  /** Configuration data loaded from/stored to extern. */
  static data = {
    defaultPathPrefix: `${REACT_APP_BLUEPRINT_ASSETS_PREFIX}Blueprint3D-assets/`,
    dimUnit: dimInch,
    wallHeight: 2.5,
    wallThickness: 0.1,
    sceneLocked: false,
    xRay: false,
    snap: true,
    dimensionVisible: true,
  };

  /** Set a configuration parameter. */
  static setValue(key, value) {
    this.data[key] = value;
    console.log(key, value);
    document.dispatchEvent(
      new CustomEvent(Core.BP3D_EVENT_CONFIG_CHANGED, {
        detail: { [key]: value },
      })
    );
  }

  /** Get a string configuration parameter. */
  static getStringValue(key) {
    switch (key) {
      case configDimUnit:
        return this.data[key];
      case configDefaultPathPrefix:
        return this.data[key];
      default:
        throw new Error("Invalid string configuration parameter: " + key);
    }
  }

  static getBooleanValue(key) {
    switch (key) {
      case configSceneLocked:
        return this.data[key]
      case configXRayMode:
        return this.data[key];
      case configSnapMode:
        return this.data[key];
      case configDimensionVisible:
        return this.data[key];
      default:
        throw new Error("Invalid boolean configuration parameter: " + key);
    }
  }

  /** Get a numeric configuration parameter. */
  static getNumericValue(key) {
    switch (key) {
      case configWallHeight:
      case configWallThickness:
        return this.data[key];
      default:
        throw new Error("Invalid numeric configuration parameter: " + key);
    }
  }
}
