import React, { FunctionComponent } from 'react';
import { InvoiceReportModel } from 'models/invoice-report';
import { formatCurrency } from 'helpers/formatting';
import { Summary as TableSummary } from 'components/base-components/DataTableV2';
import { Title } from 'components/base-components/Typography';
import { TableFilters } from './state';

const { Row, Cell } = TableSummary;

interface Props {
  data: any[];
  tableFilters: TableFilters;
  filterAccounts: (value, record) => boolean;
  filterGroups: (value, record) => boolean;
  filterReps: (value, record) => boolean;
  grouped: boolean;
}

function applyFilters(
  data: InvoiceReportModel[],
  tableFilters: TableFilters,
  filterAccounts,
  filterGroups,
  filterReps,
) {
  const filtersMap = {
    accounts: filterAccounts,
    groups: filterGroups,
    reps: filterReps,
  };
  const activeFilters = Object
    .keys(tableFilters)
    .filter(filter => tableFilters[filter]?.length > 0);

  if (activeFilters.length === 0) {
    return data;
  }

  let nextData = data;
  activeFilters.forEach((filter) => {
    const filters = tableFilters[filter];
    const filterFunction = filtersMap[filter];
    nextData = nextData.filter(record => (
      filters.some(value => filterFunction(value, record))
    ));
  });

  return nextData;
}

const Summary: FunctionComponent<Props> = (props) => {
  const {
    data,
    tableFilters,
    filterAccounts,
    filterGroups,
    filterReps,
    grouped,
  } = props;
  const grandTotal = grouped
    ? data
      .filter(row => row.type && row.type === 'header')
      .reduce((sum, row) => sum + parseFloat(row.total), 0)
    : (
      applyFilters(
        data,
        tableFilters,
        filterAccounts,
        filterGroups,
        filterReps,
      ).reduce((sum, row) => sum + row.total, 0)
    );

  return (
    <Row>
      <Cell index={0} align="right" colSpan={3}>
        <Title level={5}>Total</Title>
      </Cell>
      <Cell index={1} align="right">
        <Title level={5}>{formatCurrency(grandTotal)}</Title>
      </Cell>
    </Row>
  );
};

export default Summary;
