import { useEffect, useMemo, useReducer } from 'react';
import shipmentApi from 'api/shipment';
import { resolveApiErrorMessage } from 'api/base';
import { QuoteModel } from 'models/quote';
import { OrderProductModel } from 'models/order';
import { ShipmentDTO } from 'models/shipment';
import notification, { NotificationType } from 'helpers/notification';
import useCallApiAction from 'hooks/base/call-api-action';
import { ExtraChargeModel } from 'models/extra-charge';
import prepareQuoteProductsData from './actions/prepare-quote-products-data';
import quoteProductListReducer, {
  QuoteProductListActions,
  QuoteProductListState,
} from './reducer';

export interface QuoteProductsDataModel {
  id: string;
  group?: string;
  products?: OrderProductModel[];
  subtotal?: number;
}

export default function useQuoteProductsListState(
  quote: QuoteModel,
  shipment: ShipmentDTO,
  extraCharges: ExtraChargeModel[]
) {
  const initialReducerState: QuoteProductListState = {
    quoteProducts: [],
    expandedRowKeys: [],
    orderTotal: 0,
    extraCharges,
    deletingExtraChargeIndex: undefined,
  };

  const [state, dispatch] = useReducer(quoteProductListReducer, initialReducerState);

  const { orderTotal } = state;

  const {
    data: { price: shippingPrice },
    error: fetchingShippingPriceErrors,
    fetching: fetchingShippingPrice,
    callAction: fetchShippingPrice,
  } = useCallApiAction(
    shipmentApi.shippingPrice,
    shipment?.id,
    { price: 0, shippingType: '' },
    true,
    orderTotal,
  );

  useEffect(() => {
    if (fetchingShippingPriceErrors) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(fetchingShippingPriceErrors),
      });
    }
  }, [fetchingShippingPriceErrors]);

  useEffect(() => {
    if (quote) {
      const payload = prepareQuoteProductsData(quote);
      dispatch({ type: QuoteProductListActions.SET_DATA, payload });
    }
  }, [quote]);

  useEffect(() => {
    if (extraCharges.length > 0) {
      dispatch({ type: QuoteProductListActions.SET_EXTRA_CHARGES, payload: extraCharges });
    }
  }, [extraCharges]);

  useEffect(() => {
    if (shipment && orderTotal) {
      fetchShippingPrice(shipment.id, orderTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, orderTotal]);

  return {
    state: {
      ...state,
      hasOrder: useMemo(() => quote?.orders && quote?.orders.length, [quote]),
      shippingPrice,
      fetchingShippingPrice,
    },
  };
}
