import React from 'react';
import { Row } from 'antd';
import { Text } from 'components/base-components/Typography';
import '../style.scss';

const sheetRows = (sheets: { [x: string]: string }, keys: string[]) => keys.map((key, index) => (
  <dd key={index} className="cut-list-details__dd">
    <Text className="cut-list-details__sheet" strong>{`${key}:`}</Text>
    <Text>{sheets[key]}</Text>
  </dd>
));

export default function materialsRender(sheets: { [x: string]: string }) {
  const keys = Object.keys(sheets);
  const middleIndex = Math.ceil(keys.length / 2);

  return (
    <Row justify="space-between">
      <dl key={1} className="cut-list-details__dl">
        {sheetRows(sheets, keys.splice(0, middleIndex))}
      </dl>
      <dl key={2} className="cut-list-details__dl">
        {sheetRows(sheets, keys.splice(-middleIndex))}
      </dl>
    </Row>
  );
}
