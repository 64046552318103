import React, { FunctionComponent } from 'react';
import { Calendar as AntCalendar } from 'antd';
import { Moment } from 'moment';

interface Props {
  dateCellRender?: (date: Moment) => JSX.Element;
  dateFullCellRender?: (date: Moment) => JSX.Element;
  onPanelChange?: (date: Moment) => any;
  monthCellRender?: (date: Moment) => JSX.Element;
  headerRender?: ({ value, type, onChange, onTypeChange }) => JSX.Element;
}

const Calendar: FunctionComponent<Props> = (props) => {
  return (
    <AntCalendar {...props} />
  );
};

export default Calendar;
