import React from 'react';
import { QuoteViewActions } from '../reducer';
import { AccountLabel } from './map-functions';

export default function handleNewAccountUpdate(dispatch, dataQuote, updateManually) {
  return (response) => {
    const { isEditing, account } = response;
    const {
      url,
      businessLegalName,
      businessTradeName,
      groupDetail,
      taxDetails,
      allUsersDetails,
      businessAddress,
      businessCity,
      businessPostCode,
    } = account;

    const newAccount = {
      value: url,
      label: (<AccountLabel label={businessLegalName} url={url} />) as any,
      businessTradeName,
      businessLegalName,
      allUsersDetails,
      taxDetails,
      groupDetail,
      businessAddress,
      businessCity,
      businessPostCode,
    };

    if (isEditing) {
      dispatch({
        type: QuoteViewActions.UPDATE_FILTERS,
        payload: {
          account: newAccount,
        },
      });
      if (!!dataQuote) {
        updateManually({
          data: {
            ...dataQuote,
            project: {
              ...dataQuote.project,
              companyInfo: account,
              terms: account.paymentTermsDetail
            }
          }
        });
      }
    } else {
      dispatch({
        type: QuoteViewActions.UPDATE_ACCOUNT_FILTER,
        payload: newAccount,
      });
    }
  };
}
