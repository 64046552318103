import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { QuoteModel } from 'models/quote';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Select from 'components/base-components/Select';
import useCopyProductState from './state';

export interface CopyProductHistorySateModel {
  copyProductForm: {
    quote: QuoteModel;
    groups: any[];
    productsSelected: number[];
  };
}

export default function CopyProducts() {
  const { goBack, location: { state: stateNavigation } } = useHistory();
  const {
    copyProductForm: {
      quote,
      groups,
      productsSelected
    },
  } = stateNavigation as CopyProductHistorySateModel;

  const {
    state: {
      formData,
      coping,
      memoGroups,
      errors,
    },
    actions: {
      handleValueChange,
      handleOnSubmit,
    },
  } = useCopyProductState(groups, quote, productsSelected);

  const openNewGroupModal = useCallback(() => {
    notifyEventChannel(EventChannelList.QUOTE_PAGE_OPEN_GROUP_MODAL);
  }, []);

  return (
    <Modal
      visible
      title="Copy Products"
      onCancel={goBack}
      okText="Submit"
      onOk={handleOnSubmit}
      confirmLoading={coping}
      maskClosable={false}
    >
      <Form
        state={formData}
        onChange={handleValueChange}
        errors={errors}
      >
        <Field
          component={Select}
          name="group"
          id="group"
          label="Group"
          options={memoGroups}
          onAddClick={openNewGroupModal}
        />
      </Form>
    </Modal>
  );
}
