import { useCallback, useReducer } from 'react';
import { getLoggedUserId } from 'helpers/get-logged-user';
import { createQuickProject, setFormValue, setValidationErrors, } from './actions';
import createQuickProjectFormReducer, { emptyState } from './reducer';

export default function useQuickCreateProjectState() {
  const [state, dispatch] = useReducer(
    createQuickProjectFormReducer,
    {
      formData: { ...emptyState, salesRep: getLoggedUserId() },
      errors: {},
      creating: false,
    },
  );

  const { formData } = state;

  return {
    state,
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      setValidationErrors: useCallback(setValidationErrors(dispatch), []),
      createQuickAccount: useCallback(createQuickProject(dispatch, formData), [formData]),
    },
  };
}
