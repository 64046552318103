import { useEffect, useReducer } from 'react';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import getEventValue from 'helpers/get-event-value';
import selectReducer, { SelectActions } from './reducer';
import getOptions from './actions/get-options';

export const getPrevValue = (value: DropdownOptionModel[] | DropdownOptionModel) => {
  const formattedValue = getEventValue(value);
  if (!formattedValue) {
    return [];
  }
  return Array.isArray(formattedValue) ? [...formattedValue] : [formattedValue];
};

export default function useOptionHandler(
  onInfinityScroll: () => void,
  useInfinityScroll: boolean,
  options: DropdownOptionModel[],
  children: any,
  value: DropdownOptionModel[] | DropdownOptionModel,
  checkNewValue: boolean
) {
  const [state, dispatch] = useReducer(
    selectReducer,
    {
      internalOptions: getOptions(children, onInfinityScroll, useInfinityScroll, options),
      prevValue: getPrevValue(value),
    }
  );

  useEffect(() => {
    dispatch({
      type: SelectActions.SET_INTERNAL_OPTIONS,
      payload: getOptions(children, onInfinityScroll, useInfinityScroll, options)
    });
  }, [children, onInfinityScroll, useInfinityScroll, options]);

  useEffect(() => {
    if (checkNewValue) {
      dispatch({
        type: SelectActions.SET_PREVIEW_VALUE,
        payload: value
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return state.internalOptions;
}
