import {
  MORPH_ALIGN_BACK,
  MORPH_ALIGN_FRONT,
  MORPH_ALIGN_LEFT,
} from '../../../Blueprint3D-cart-set-builder/app/items/item';
import { CAT_DESK, CAT_HUTCH, CAT_PED_MP, CAT_RTN } from '../../category';

import {
  commonTextures,
  commonModestyStyles,
  commonGrommetStyles,
  cogGrommetStyles,
  handleStyles,
} from '../../category/__CommonOptions';

const In2M = 2.54 / 100;

const data = {
  id: '8e4f7f8e-512a-11ec-bf63-0242ac130002',
  name: 'L-Type Desk Set(Right)',
  item: {
    id: '9dda8ac0-512a-11ec-bf63-0242ac130002',
    parentUUID: '8e4f7f8e-512a-11ec-bf63-0242ac130002',
    label: 'Main Desk',
    products: [
      CAT_DESK.styles[1],
      CAT_DESK.styles[4],
      CAT_DESK.styles[5],
      CAT_DESK.styles[6],
      CAT_DESK.styles[9],
      CAT_DESK.styles[10],
    ],
    morphAlign: MORPH_ALIGN_FRONT,
    optionGroup: {
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        {
          label: 'Desk Top',
          name_in_model: 'desk-top',
          types: commonTextures,
        },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    children: [
      {
        id: 'f59937fc-512a-11ec-bf63-0242ac130002',
        parentUUID: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        label: 'Left Pedestal',
        marginLeft: 1.2 * In2M,
        marginFront: 0.1 * In2M,
        morphAlign: MORPH_ALIGN_FRONT,
        products: [CAT_PED_MP.styles[10]],
        // overlapCheck: ["08cdc612-512b-11ec-bf63-0242ac130002"],
        optionGroup: {
          morph: [
            {
              label: 'Width',
              index: 1,
              min: 18,
              max: {
                target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
                field: 'width',
                delta: {
                  default: 2,
                  target: '1e229b32-512b-11ec-bf63-0242ac130002',
                  field: 'depth',
                  delta: 1.1,
                },
              },
            },
            {
              label: 'Depth',
              index: 2,
              followMaxValue: true,
              min: 20,
              max: {
                target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
                field: 'depth',
                delta: 1.2,
              },
            },
          ],
          materials: [
            {
              label: 'Cabinet Top',
              name_in_model: 'cabinet-top',
              types: commonTextures,
            },
            {
              label: 'Cabinet Body',
              name_in_model: 'cabinet-body',
              types: commonTextures,
            },
            {
              label: 'Cabinet Face',
              name_in_model: 'cabinet-face',
              types: commonTextures,
            },
          ],
          styles: [
            {
              label: 'Handle Style',
              name_in_model: 'handle',
              types: handleStyles,
            },
          ],
        },
      },
      {
        id: '08cdc612-512b-11ec-bf63-0242ac130002',
        parentUUID: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        label: 'Right Pedestal 1',
        marginRight: 1.2 * In2M,
        marginFront: 0.1 * In2M,
        morphAlign: MORPH_ALIGN_FRONT,
        products: [CAT_PED_MP.styles[10]],
        validation: {
          overlapCheck: ['f59937fc-512a-11ec-bf63-0242ac130002'],
        },
        optionGroup: {
          morph: [
            {
              label: 'Width',
              index: 1,
              min: 18,
              max: {
                target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
                field: 'width',
                delta: 2,
              },
            },
            {
              label: 'Depth',
              index: 2,
              followMaxValue: true,
              min: 20,
              max: {
                target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
                field: 'depth',
                delta: 1.2,
              },
            },
          ],
          materials: [
            {
              label: 'Cabinet Top',
              name_in_model: 'cabinet-top',
              types: commonTextures,
            },
            {
              label: 'Cabinet Body',
              name_in_model: 'cabinet-body',
              types: commonTextures,
            },
            {
              label: 'Cabinet Face',
              name_in_model: 'cabinet-face',
              types: commonTextures,
            },
          ],
          styles: [
            {
              label: 'Handle Style',
              name_in_model: 'handle',
              types: handleStyles,
            },
          ],
        },
      },
      {
        id: '11d97e86-512b-11ec-bf63-0242ac130002',
        parentUUID: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        label: 'Hutch 1',
        marginLeft: 0.1 * In2M,
        marginBack: 0.1 * In2M,
        morphAlign: MORPH_ALIGN_BACK,
        products: [CAT_HUTCH.styles[0], CAT_HUTCH.styles[1]],
        validation: {
          overlapCheck: ['89ad3c07-512b-11ec-bf63-0242ac130002'],
        },
        optionGroup: {
          morph: [
            {
              label: 'Width',
              index: 1,
              min: 36,
              max: {
                target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
                field: 'width',
                delta: 0,
              },
            },
            {
              label: 'Depth',
              index: 2,
              min: 15,
              max: {
                target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
                field: 'depth',
                delta: 0,
              },
            },
          ],
          materials: [
            {
              label: 'Cabinet Top',
              name_in_model: 'cabinet-top',
              types: commonTextures,
            },
            {
              label: 'Cabinet Body',
              name_in_model: 'cabinet-body',
              types: commonTextures,
            },
            {
              label: 'Cabinet Face',
              name_in_model: 'cabinet-face',
              types: commonTextures,
            },
          ],
          styles: [
            {
              label: 'Handle Style',
              name_in_model: 'handle',
              types: handleStyles,
            },
          ],
        },
      },
      {
        id: '89ad3c07-512b-11ec-bf63-0242ac130002',
        parentUUID: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        label: 'Hutch 2',
        marginRight: 0.1 * In2M,
        marginBack: 0.1 * In2M,
        rotation: -Math.PI / 2,
        morphAlign: MORPH_ALIGN_BACK,
        products: [CAT_HUTCH.styles[0], CAT_HUTCH.styles[1]],
        optionGroup: {
          morph: [
            {
              label: 'Width',
              index: 1,
              min: 18,
              max: {
                target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
                field: 'depth',
                delta: {
                  default: 0,
                  isAddValue: true,
                  target: '1e229b32-512b-11ec-bf63-0242ac130002',
                  field: 'width',
                  delta: 0,
                },
              },
            },
            {
              label: 'Depth',
              index: 2,
              min: 15,
              max: 30,
            },
          ],
          materials: [
            {
              label: 'Cabinet Top',
              name_in_model: 'cabinet-top',
              types: commonTextures,
            },
            {
              label: 'Cabinet Body',
              name_in_model: 'cabinet-body',
              types: commonTextures,
            },
            {
              label: 'Cabinet Face',
              name_in_model: 'cabinet-face',
              types: commonTextures,
            },
          ],
          styles: [
            {
              label: 'Handle Style',
              name_in_model: 'handle',
              types: handleStyles,
            },
          ],
        },
      },
      {
        id: '1e229b32-512b-11ec-bf63-0242ac130002',
        parentUUID: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        label: 'Right Returns',
        rotation: -Math.PI / 2,
        marginRight: 0.1 * In2M,
        morphAlign: MORPH_ALIGN_LEFT,
        position: { x: 0, y: 0, z: 48 * In2M },
        products: [CAT_RTN.styles[1], CAT_RTN.styles[3], CAT_RTN.styles[5]],
        validation: {
          combineCheck: true,
        },
        optionGroup: {
          morph: [
            { label: 'Width', index: 1, min: 60, max: 108 },
            { label: 'Depth', index: 2, min: 24, max: 48 },
          ],
          materials: [
            {
              label: 'Desk Top',
              name_in_model: 'desk-top',
              types: commonTextures,
            },
            {
              label: 'Desk Modesty',
              name_in_model: 'desk-modesty',
              types: commonTextures,
            },
            {
              label: 'Desk Gable',
              name_in_model: 'desk-gable',
              types: commonTextures,
            },
          ],
          styles: [
            {
              label: 'Modesty Style',
              name_in_model: 'modesty',
              types: commonModestyStyles,
            },
            {
              label: 'Grommet Style',
              name_in_model: 'grommet',
              types: cogGrommetStyles,
            },
          ],
        },
        children: [
          {
            id: '521fc342-512b-11ec-bf63-0242ac130002',
            parentUUID: '1e229b32-512b-11ec-bf63-0242ac130002',
            label: 'Right Pedestal 2',
            marginLeft: 0.1 * In2M,
            marginFront: 1.2 * In2M,
            rotation: -Math.PI / 2,
            morphAlign: MORPH_ALIGN_FRONT,
            products: [CAT_PED_MP.styles[10]],
            optionGroup: {
              morph: [
                {
                  label: 'Width',
                  index: 1,
                  min: 18,
                  max: {
                    target: '1e229b32-512b-11ec-bf63-0242ac130002',
                    field: 'width',
                    delta: 1,
                  },
                },
                {
                  label: 'Depth',
                  index: 2,
                  followMaxValue: true,
                  min: 20,
                  max: {
                    target: '1e229b32-512b-11ec-bf63-0242ac130002',
                    field: 'depth',
                    delta: 1.2,
                  },
                },
              ],
              materials: [
                {
                  label: 'Cabinet Top',
                  name_in_model: 'cabinet-top',
                  types: commonTextures,
                },
                {
                  label: 'Cabinet Body',
                  name_in_model: 'cabinet-body',
                  types: commonTextures,
                },
                {
                  label: 'Cabinet Face',
                  name_in_model: 'cabinet-face',
                  types: commonTextures,
                },
              ],
              styles: [
                {
                  label: 'Handle Style',
                  name_in_model: 'handle',
                  types: handleStyles,
                },
              ],
            },
          },
        ],
      },
    ],
  },
};

export default data;
