import { BuilderModel, BuilderOptionGroup, BuilderPropertyType } from 'models/builder';
import { generateUID } from 'helpers/generators';
import { getLabelOption } from 'components/base-components/PropertiesPanel';
import { BuilderFormModel } from '..';

function parseValue(
  type: BuilderPropertyType,
  value: string,
  options: { name: string; nameInModel: string }[],
) {
  switch (type) {
    case BuilderPropertyType.RANGE:
      return parseInt(value, 10);
    case BuilderPropertyType.SELECT: {
      const option = options.find((op) => op.name === value);
      return {
        value,
        label: value,
        key: value,
        nameInModel: option.nameInModel,
        type
      };
    }
    case BuilderPropertyType.SELECT_REFRESH: {
      const option = options.find((op) => op.name === value);
      return {
        value,
        label: value,
        key: value,
        nameInModel: option.nameInModel,
        type
      };
    }
    default:
      return value;
  }
}
function findDefaultOption(group: BuilderOptionGroup) {
  const { options, defaultOption } = group;
  if (defaultOption) {
    return options.find((option) => option.name === defaultOption);
  }
  return undefined;
}
export default function normalizeModel(data: BuilderModel, isFrom3D?: boolean): BuilderFormModel {
  const { modelDescription, builderOptions, optionGroups } = data;
  const normalizedValues = (
    Object.keys(modelDescription)
      .reduce((values, property) => {
        const value = modelDescription[property];
        const { propertyType: type, options } = (builderOptions[property] as any);
        if (!!value && !!type) {
          return {
            ...values,
            [property]: parseValue(type, value, options)
          };
        }
        return {
          ...values,
          [property]: value,
        };
      }, {} as any)
  );
  const initialOptions = optionGroups.reduce((options, group) => {
    const defaultOption = findDefaultOption(group);
    if (defaultOption) {
      return {
        ...options,
        [group.name]: {
          ...defaultOption,
          dropdownItem: defaultOption,
          value: defaultOption.name,
          label: isFrom3D ? getLabelOption(defaultOption) : defaultOption.name,
          group,
        },
      };
    }
    return {
      ...options,
      [group.name]: null
    };
  }, {});
  return {
    ...data,
    id: generateUID(),
    modelDescription: normalizedValues,
    options: initialOptions,
    sceneOptions: {
      lockInPlace: false,
      stackable: false,
      stackontop: false,
      overlapable: false,
    }
  };
}
