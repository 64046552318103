import { BuilderCategory, Units } from 'models/builder';
import { CategoryModel } from 'models/category';
import { FlowPlannerModel } from 'models/flow-planner';
import { BuilderFormModel, FloorModel, StyleProviders, WallModel } from './types';

export enum BuilderActions {
  SET_CATEGORY_SELECTED = 'SET_CATEGORY_SELECTED',
  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_PRODUCTS = 'ADD_PRODUCTS',
  SET_PRODUCTS = 'SET_PRODUCTS',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  SWAP_PRODUCT = 'SWAP_PRODUCT',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
  START_FETCHING_PRODUCT = 'START_FETCHING_PRODUCT',
  STOP_FETCHING_PRODUCT = 'STOP_FETCHING_PRODUCT',
  SET_VIEW_MODE = 'SET_VIEW_MODE',
  SET_ERROR = 'SET_ERROR',
  SET_UNIT = 'SET_UNIT',
  SET_FLOOR_PLANNER = 'SET_FLOOR_PLANNER',
  UPDATE_FLOOR_PLANNER = 'UPDATE_FLOOR_PLANNER',
  SELECT_WALL = 'SELECT_WALL',
  UPDATE_WALL = 'UPDATE_WALL',
  SELECT_FLOOR = 'SELECT_FLOOR',
  UPDATE_FLOOR = 'UPDATE_FLOOR',
}

export interface BuilderState {
  products: BuilderFormModel[];
  selectedProduct: BuilderFormModel;
  fetchingProductData: boolean;
  selectedCategory: CategoryModel | BuilderCategory;
  styleProvider?: StyleProviders;
  unit?: Units;
  wall?: WallModel;
  floor?: FloorModel;
  flowPlanner?: FlowPlannerModel;
  viewMode: string;
  error?: any;
}

export default function builderReducer(
  state: BuilderState,
  action,
): BuilderState {
  const { type, payload } = action;

  switch (type as BuilderActions) {
    case BuilderActions.SET_CATEGORY_SELECTED:
      return {
        ...state,
        selectedCategory: payload,
        selectedProduct: undefined,
        styleProvider: StyleProviders.Category,
      };
    case BuilderActions.ADD_PRODUCTS:
      return {
        ...state,
        products: state.products.concat(payload),
        fetchingProductData: false,
      };
    case BuilderActions.ADD_PRODUCT:
      return {
        ...state,
        products: state.products.concat(payload),
        selectedProduct: payload,
        fetchingProductData: false,
      };
    case BuilderActions.UPDATE_PRODUCT: {
      const { selectedProduct: { id: productToUpdate } } = state;

      const nextProducts = state.products.map((product) => (
        product.id === productToUpdate ? payload : product
      ));

      return {
        ...state,
        products: nextProducts,
        selectedProduct: payload,
        fetchingProductData: false,
      };
    }
    case BuilderActions.SWAP_PRODUCT: {
      const { selectedProduct } = state;
      const updatedProduct = { ...selectedProduct, ...payload };

      const nextProducts = state.products.map((product) => (
        product.id === updatedProduct.id ? updatedProduct : product
      ));

      return {
        ...state,
        products: nextProducts,
        selectedProduct: updatedProduct,
        fetchingProductData: false,
      };
    }
    case BuilderActions.REMOVE_PRODUCT: {
      const { products: oldProducts, styleProvider } = state;
      const nextProducts = oldProducts.filter(
        (product) => product.id !== payload,
      );

      return {
        ...state,
        fetchingProductData: false,
        products: nextProducts,
        selectedProduct: undefined,
        styleProvider: (
          nextProducts.length === 0
            ? StyleProviders.Category
            : styleProvider
        ),
      };
    }
    case BuilderActions.SET_SELECTED_PRODUCT: {
      const selectedProduct = !!payload
        ? state.products.find((prod) => prod.id === payload)
        : undefined;

      return {
        ...state,
        wall: undefined,
        floor: undefined,
        selectedProduct,
        selectedCategory: undefined,
        styleProvider: (
          !!selectedProduct
            ? StyleProviders.Product
            : StyleProviders.Category
        ),
      };
    }
    case BuilderActions.START_FETCHING_PRODUCT:
      return { ...state, fetchingProductData: true };
    case BuilderActions.STOP_FETCHING_PRODUCT:
      return { ...state, fetchingProductData: false, };
    case BuilderActions.SET_FLOOR_PLANNER:
      return { ...state, flowPlanner: payload };
    case BuilderActions.UPDATE_FLOOR_PLANNER:
      return { ...state, flowPlanner: { ...state.flowPlanner, ...payload } };
    case BuilderActions.SET_UNIT:
      return { ...state, unit: payload };
    case BuilderActions.SET_VIEW_MODE:
      return { ...state, viewMode: payload };
    case BuilderActions.SET_ERROR:
      return { ...state, error: payload, fetchingProductData: false };
    case BuilderActions.SET_PRODUCTS:
      return { ...state, products: payload };
    case BuilderActions.SELECT_WALL:
      return {
        ...state,
        wall: payload,
        floor: undefined,
        selectedProduct: undefined,
        selectedCategory: undefined,
      };
    case BuilderActions.UPDATE_WALL:
      return { ...state, wall: payload };
    case BuilderActions.SELECT_FLOOR:
      return {
        ...state,
        floor: payload,
        wall: undefined,
        selectedProduct: undefined,
        selectedCategory: undefined,
      };
    case BuilderActions.UPDATE_FLOOR:
      return { ...state, floor: payload };
    default:
      return state;
  }
}
