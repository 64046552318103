import React, { FunctionComponent, useMemo } from 'react';
import { OrderDTO } from 'models/order';
import orderApi from 'api/orders';
import useHiddenColumnsHandler from 'hooks/ui/hidden-columns-handler';
import hasPermissions from 'helpers/permissons';
import Page, { PageActions } from 'components/base-components/Page';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import Select from 'components/base-components/Select';
import Switch from 'components/base-components/Switch';
import Help from 'components/forms/CommonHelp';
import {
  clientInfoTemplate,
  salesPersonTemplate,
  renderBalance,
  renderContent
} from './template';
import useOrderTableState from './state';
import './styles.scss';
import { permissions } from './permissions';
import { breakpoints, columnOptions, parentColumns } from './constants';

const emptyAction = () => undefined;

const icons = {
  pdf: <i className="fa fa-file-pdf" />,
  excel: <i className="fa fa-file-csv" />,
  print: <i className="fa fa-print" />,
  email: <i className="fa fa-envelope" />,
  copy: <i className="fa fa-copy" />,
  details: <i className="fas fa-eye" />,
  shipping: <i className="fas fa-truck" />,
  payments: <i className="fas fa-dollar-sign" />,
  deficiencyReport: <i className="bx bxs-report" />,
};

const expandableConf = {
  expandedRowRender: (order: OrderDTO) => <p>{order.quote.specialInstructions}</p>,
};

const OrdersList: FunctionComponent = () => {
  const {
    state: {
      showAll,
      fetching,
      data,
      count,
      orderStatuses,
      fetchingOrderStatus,
      filtersOrderStatus,
      defaultFilter,
    },
    actions: {
      hiddenAction,
      handleOnChange,
      handleFilter,
      handleDeleteRow,
      openOrderDetails,
      openSendEmailModal,
      printOrder,
      printMaterial,
      openReceivePaymentModal,
      getPayload,
      toggleShowAll,
      onChangeStatusSuccess,
      printDeficiencyReport
    },
  } = useOrderTableState();

  const hasEditStatusPermission = useMemo(
    () => hasPermissions(permissions.changeOrderStatus),
    [],
  );

  const {
    breakpoint,
    handleHiddenColumns,
    hiddenColumns,
  } = useHiddenColumnsHandler('order-list', columnOptions, breakpoints, parentColumns);

  return (
    <Page title="Orders">
      <Help view="orders" title="Orders List" />
      <PageActions primary>
        <Select
          className="fixed-column-select"
          placeholder="Hidden Columns"
          onChange={handleHiddenColumns}
          value={hiddenColumns}
          maxTagCount="responsive"
          mode="multiple"
          options={columnOptions}
          label="Hidden Columns"
          size="middle"
        />
        <Switch
          className="order-list__switch"
          checked={showAll}
          onChange={toggleShowAll}
          label="Show All"
        />
      </PageActions>
      <DataTable
        loading={fetching}
        dataSource={data}
        totalRecords={count}
        onChange={handleOnChange}
        onFilter={handleFilter}
        expandable={expandableConf}
        onSubmit={orderApi.update}
        defaultFilter={defaultFilter}
      >
        <DataTableColumn
          title="Order"
          dataIndex="id"
          columnKey="id"
          sorter
          hideColumn={breakpoint.id}
        />
        <DataTableColumn
          title="Job Title"
          dataIndex="jobTitle"
          columnKey="job_title"
          trimAt={25}
          sorter
          hideColumn={breakpoint.job_title}
        />
        <DataTableColumn
          title="Sales Person"
          render={salesPersonTemplate}
          columnKey="rep_owner"
          sorter
          hideColumn={breakpoint.rep_owner}
        />
        <DataTableColumn
          title="Company"
          dataIndex="companyName"
          columnKey="company"
          trimAt={25}
          sorter
          hideColumn={breakpoint.company}
        />
        <DataTableColumn
          title="Customer"
          render={clientInfoTemplate}
          columnKey="client"
          sorter
          hideColumn={breakpoint.client}
        />
        <DataTableColumn
          title="Status"
          dataIndex={['orderStatus', 'id']}
          component={Select}
          columnKey="order_status"
          align="center"
          render={renderContent}
          inputClassName="order-list__input-status-column"
          onSubmitSuccess={onChangeStatusSuccess}
          options={orderStatuses}
          alwaysShowInput
          editable
          getPayload={getPayload}
          sorter
          disabled={!hasEditStatusPermission || fetchingOrderStatus}
          loading={fetchingOrderStatus}
          hideColumn={breakpoint.order_status}
          filters={filtersOrderStatus}
        />
        <DataTableColumn
          isCurrency
          title="Total"
          dataIndex="totalWithTax"
          columnKey="totalWithTax"
          hideColumn={breakpoint.totalWithTax}
        />
        <DataTableColumn
          isCurrency
          title="Balance"
          dataIndex="balance"
          columnKey="balance"
          render={renderBalance}
          hideColumn={breakpoint.balance}
        />
        <DataTableColumn
          title="Date Added"
          dataIndex="createdDate"
          columnKey="created_date"
          isDate
          sorter
          hideColumn={breakpoint.created_date}
        />
        <DataTableColumn
          title="Est. Ready Date"
          dataIndex="readyDate"
          columnKey="ready_date"
          sorter
          isDate
          hideColumn={breakpoint.ready_date}
        />
        <DataTableColumn
          isActionColumn
          onShowDetails={openOrderDetails}
          onDelete={handleDeleteRow}
          deletePermissions={permissions.deleteOrder}
        >
          <DataTableAction onClick={emptyAction} icon={icons.shipping} />
          <DataTableAction
            permissions={permissions.viewTransaction}
            onClick={openReceivePaymentModal}
            icon={icons.payments}
            label="Transactions"
          />
          <DataTableAction
            permissions={permissions.changeOrder}
            onClick={openSendEmailModal}
            icon={icons.email}
            label="Send Email"
            hide={hiddenAction}
          />
          <DataTableAction
            onClick={printOrder}
            icon={icons.print}
            label="Print"
          />
          <DataTableAction
            onClick={printMaterial}
            icon={icons.print}
            label="Print Materials"
          />
          <DataTableAction
            onClick={printDeficiencyReport}
            label="Deficiency Report"
            icon={icons.deficiencyReport}
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default OrdersList;
