import { commonRules, RuleType } from 'helpers/form-validations';

const { required } = commonRules;

export const paymentRules = {
  amount: [
    required,
    { type: RuleType.Min, value: 1, message: 'The amount must be greater than 0' },
  ],
};
