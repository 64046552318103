import taskApi from 'api/project-task';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { TaskFormActions } from '../reducer';

export default function handleOnChange(dispatch, oldValue: number[]) {
  const onError = (error) => {
    const message = resolveApiErrorMessage((error as any).response);
    notification({
      type: NotificationType.ERROR,
      message
    });

    dispatch({
      type: TaskFormActions.SET_TASK_DONE,
      payload: oldValue,
    });
  };

  return (newValue) => {
    dispatch({
      type: TaskFormActions.SET_TASK_DONE,
      payload: newValue,
    });
    const newCheckedValue = newValue.find((o) => !oldValue.includes(o));

    if (newCheckedValue) {
      const payload = { id: newCheckedValue, completed: true };
      return taskApi.patch(payload).catch(onError);
    }

    const newUnCheckedValue = oldValue.find((o) => !newValue.includes(o));
    const payload = { id: newUnCheckedValue, completed: false };
    return taskApi.patch(payload).catch(onError);
  };
}
