import { GroupedReportModel } from 'models/invoice-report';
import { generateUID } from 'helpers/generators';

const sumUp = (items) => items.reduce((sum, entry) => sum + entry.total, 0);

export default function prepareGroupedData(
  data: GroupedReportModel[],
) {
  const grandTotal = data.reduce((total, batch) => total + sumUp(batch.data), 0);

  return data.reduce((extended, batch) => {
    const label = batch.header || 'None';
    let percent = ((batch.total / grandTotal) * 100).toFixed(2);
    percent = parseFloat(percent) < 100
      ? `00${percent}`.slice(-5)
      : percent;

    return extended.concat({
      type: 'header',
      id: generateUID(),
      label,
      batch: batch.data,
      total: batch.total,
      percent,
    });
  }, []);
}
