import { resolveApiErrorMessage } from 'api/base';
import notesApi from 'api/notes';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { NotesFormActions, NotesFormModel } from '../reducer';

export default function onDelete(dispatch, oldData: NotesFormModel[], orderId) {
  return (setDeleting, note: NotesFormModel) => {
    setDeleting(true);

    const onSuccess = () => {
      const message = 'Note Successfully Deleted';
      notification({ type: NotificationType.SUCCESS, message });
      const payload = oldData.filter(n => n.id !== note.id);
      dispatch({ type: NotesFormActions.SET_DATA, payload });
      notifyEventChannel(EventChannelList.UPDATE_NOTE_COUNT, { id: orderId, noteCount: payload.length });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      setDeleting(false);
    };

    return notesApi.delete(note.id)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
