import { QuoteModel } from 'models/quote';
import { ShipmentDTO } from 'models/shipment';

export enum PrintOuts {
  WorkOrder = 'work-order',
  PackingSlip = 'packing-slip',
  ThirdPartyPaint = 'third-party-paint',
  QuoteReport = 'quote-report',
}

export default function openPrintout(
  printout: PrintOuts,
  quote: QuoteModel,
  shipment: ShipmentDTO,
) {
  return () => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}/printouts/${printout}`;

    localStorage.setItem(`${printout}-data`, JSON.stringify({ quote, shipment }));
    window.open(url, '_blank');
  };
}
