import React, { FunctionComponent, useMemo } from 'react';
import { formatCurrency } from 'helpers/formatting';
import { ExtraChargeModel } from 'models/extra-charge';
import { AccountTaxModel } from 'models/account';
import { Summary } from 'components/base-components/DataTableV2';
import { calculateSubTotal, calculateTotal } from './util';
import Taxes from './Taxes';
import ExtraCharges from './ExtraCharges';

interface Props {
  orderTotal: number;
  shippingPrice: number;
  taxes: AccountTaxModel[];
  colSpan: number;
  extraCharges: ExtraChargeModel[];
}

const QuoteProductsListSummary: FunctionComponent<Props> = (props) => {
  const {
    orderTotal,
    shippingPrice,
    taxes,
    colSpan,
    extraCharges,
  } = props;

  const total = useMemo(
    () => calculateTotal(taxes, shippingPrice, orderTotal, extraCharges),
    [taxes, shippingPrice, orderTotal, extraCharges],
  );

  const subTotal = useMemo(
    () => calculateSubTotal(orderTotal, extraCharges),
    [orderTotal, extraCharges],
  );

  return (
    <>
      <Summary.Row className="work-order__products_group-header">
        <Summary.Cell index={0} colSpan={colSpan + 1}>
          Total
        </Summary.Cell>
      </Summary.Row>
      <ExtraCharges
        extraCharges={extraCharges}
        colSpan={colSpan}
      />
      <Summary.Row className="quote-products-table__summary-row">
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          <b>Sub-Total</b>
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          <b>{formatCurrency(subTotal)}</b>
        </Summary.Cell>
      </Summary.Row>
      <Summary.Row className="quote-products-table__summary-row">
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          <b>Deliver & Install</b>
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          <b>{formatCurrency(shippingPrice)}</b>
        </Summary.Cell>
      </Summary.Row>
      <Taxes
        orderTotal={orderTotal}
        shippingPrice={shippingPrice}
        taxes={taxes}
        colSpan={colSpan}
      />
      <Summary.Row className="quote-products-table__summary-row">
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          <b>Total</b>
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          <b>{formatCurrency(total)}</b>
        </Summary.Cell>
      </Summary.Row>
    </>
  );
};

export default QuoteProductsListSummary;
