import React from 'react';

export default function nameTemplate(data) {
  const { ancestors, name } = data;
  let path = '';
  const parents = ancestors.split('>');
  for (let i = 1; i < parents.length; i += 1) {
    path += `${parents[i]} > `;
  }
  path += name;

  return (
    <div>
      {path}
    </div>
  );
}
