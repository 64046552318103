import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { StoreModel } from 'models/store';
import storeApi from 'api/store';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Upload from 'components/base-components/Upload';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Select from 'components/base-components/Select';
import { validationRules } from './validations';
import useAttachmentDocumentFormState from './state';

export interface AttachmentDocumentFormHistorySateModel {
  attachmentDocumentForm: {
    attachmentDocumentId: number;
  };
}

export function mapStoresOptions(stores: StoreModel[]) {
  return mapGenericDropdownOptions(stores, { fields: { value: 'name', label: 'name' } });
}

const AttachmentDocumentForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { attachmentDocumentForm: { attachmentDocumentId } } = state as AttachmentDocumentFormHistorySateModel;

  const {
    state: {
      saving,
      attachmentDocument,
      fetchingFormData,
      typeOptions,
      errors,
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  } = useAttachmentDocumentFormState(attachmentDocumentId);

  const modalTitle = useMemo(
    () => attachmentDocumentId ? 'Edit Attachment Document' : 'Create Attachment Document',
    [attachmentDocumentId],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveEntity}
      confirmLoading={saving}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={attachmentDocument}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            component={AsyncSelectV2}
            name="store"
            id="store"
            label="Store"
            listAction={storeApi.list}
            mapFunction={mapStoresOptions}
          />
          <Field
            id="documentType"
            name="documentType"
            label="Document Type"
            placeholder="Enter Document Type"
            component={Select}
            options={typeOptions}
          />
          <Field
            component={Upload}
            name="document"
            id="document"
            label="Choose Documents"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default AttachmentDocumentForm;
