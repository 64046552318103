import { AxiosResponse } from 'axios';
import { CopyQuoteFormModel } from 'models/quote';
import { resolveApiErrorMessage } from 'api/base';
import quoteApi, { CopyQuoteResponse } from 'api/quote';
import notification, { NotificationType } from 'helpers/notification';
import { validateEntity } from 'helpers/form-validations';
import { CopyQuoteFormActions } from '../reducer';
import { getPayload } from './get-payload';
import { validationRules } from '../validation-rules';

export function reuseQuote(dispatch, formData: CopyQuoteFormModel, quoteId: number, push) {
  return () => {
    dispatch({ type: CopyQuoteFormActions.START_COPING });

    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: CopyQuoteFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    const payload = getPayload(formData);

    const onSuccess = (response: AxiosResponse<CopyQuoteResponse>) => {
      const { data: { id } } = response;
      const message = 'Quote Successfully copied';
      notification({ type: NotificationType.SUCCESS, message });
      push(`/quote/${id}`);
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CopyQuoteFormActions.FINISH_COPING });
    };

    return quoteApi.reuseQuote(quoteId, payload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
