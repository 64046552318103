import { QuoteModel } from 'models/quote';
import { ShipmentDTO } from 'models/shipment';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { EmailSender } from 'components/forms/SendEmail/state';

export default function openSendDQEmailModal(push, quote: QuoteModel, shipment: ShipmentDTO) {
  return () => {
    const { id } = shipment;
    const { project: { clientInfo } } = quote;
    const recipients = clientInfo ? [clientInfo.email] : undefined;

    push(
      ModalRouteHash.SendEmailForm,
      {
        sendEmailForm: {
          id,
          recipients,
          from: EmailSender.QuoteQuestionnaire,
          title: 'Send Delivery Questionnaire by email'
        },
      },
    );
  };
}
