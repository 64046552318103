import purchaseOrderApi from 'api/purchase-order';
import { formatDateTimeToString } from 'helpers/formatting';

export default function onSubmit(data) {
  const { dateSent, id } = data;
  const payload = {
    id,
    dateSent: dateSent ? formatDateTimeToString(dateSent) : null,
  };
  return purchaseOrderApi.patch(payload);
}
