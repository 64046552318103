import { AxiosResponse } from 'axios';
import { InvoiceModel } from 'models/invoice';
import { OrderTotal } from 'models/order';
import { QueryParams } from 'helpers/query-params';
import { ApiContentType, ApiModel, get, ListResponseModel, post } from './base';
import getApi from './base/get-api';
import { getEmailPayload } from './base/get-email-payload';
import downloadBlob from './base/download-blob';

const { REACT_APP_TRANSACTIONS_API } = process.env;
const paymentEndpoint = `${REACT_APP_TRANSACTIONS_API}/invoice/`;

interface InvoiceApiModel extends ApiModel<InvoiceModel> {
  getOrderTotals: (params: QueryParams) => Promise<AxiosResponse<ListResponseModel<OrderTotal>>>;
  getTransactionSummary: (orderId: number) => Promise<AxiosResponse>;
  sendEmail: (invoice: number, recipients: string[]) => Promise<AxiosResponse>;
  print: (invoice: number) => Promise<void>;
}

const invoiceApi: InvoiceApiModel = {
  ...getApi(paymentEndpoint, ApiContentType.MULTIPART, true),
  getOrderTotals(params) {
    return get(`${paymentEndpoint}totals/`, params);
  },
  getTransactionSummary(orderId) {
    return get(`${paymentEndpoint}transaction_summary/${orderId}/`);
  },
  sendEmail(invoice: number, recipients: string[]) {
    const payload = getEmailPayload(recipients);
    return post(`${paymentEndpoint}${invoice}/email/`, payload);
  },
  print(invoice: number) {
    return downloadBlob(
      `${paymentEndpoint}${invoice}/view/`,
      `Invoice ${invoice}.pdf`
    );
  }
};

export default invoiceApi;
