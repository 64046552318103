import { ShipmentDTO } from 'models/shipment';
import shipmentApi from 'api/shipment';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { QuoteViewActions } from '../reducer';

export default function printQuestionnaire(dispatch, shipment: ShipmentDTO) {
  return async () => {
    const { id, shippingQuestionnaire } = shipment;
    if (shippingQuestionnaire) {
      dispatch({ type: QuoteViewActions.START_PRINTING });

      const onError = (error) => {
        notification({
          type: NotificationType.ERROR,
          message: resolveApiErrorMessage((error as any).response),
        });
      };

      const onFinally = () => {
        dispatch({ type: QuoteViewActions.STOP_PRINTING });
      };

      return shipmentApi.printQuestionnaire(id)
        .catch(onError)
        .finally(onFinally);
    }
    notification({
      type: NotificationType.ERROR,
      message: 'Questionnaire should be filled in',
    });
    return undefined;
  };
}
