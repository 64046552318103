import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { SheetModel } from 'models/sheet';
import sheetApi from 'api/sheet';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import Help from 'components/forms/CommonHelp';
import { SheetFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: sheetApi,
  deleteModalTitle: 'Delete Sheet Setting',
  deleteModalContent: (sheetToDelete: SheetModel) => `Are you sure that you want to delete the Sheet Setting ${sheetToDelete ? sheetToDelete.code : ''}?`,
  searchFields: ['code'],
  eventChannel: EventChannelList.SHEET_LIST_CHANGED,
};

const SheetList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleOnChange,
      handleFilter,
    }
  } = useTableState<SheetModel>(useTableStateParams);

  const openSheetFormModal = (sheet?: SheetModel | any) => {
    const historyLocationState: SheetFormHistorySateModel = {
      sheetForm: {
        sheetId: sheet ? sheet.id : undefined,
      },
    };
    push(ModalRouteHash.SheetForm, historyLocationState);
  };

  return (
    <Page title="Sheet Setting">
      <Help view="sheet-setting" title="Sheet Setting" />
      <PageActions primary>
        <AccessControl permissions={permissions.addSheet}>
          <Button
            onClick={openSheetFormModal}
            label="Add Sheet"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="code" columnKey="code" title="Code" sorter />
        <DataTableColumn dataIndex="x" columnKey="x" title="X" sorter />
        <DataTableColumn dataIndex="y" columnKey="y" title="Y" sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editSheet}
          onEdit={openSheetFormModal}
        />
      </DataTable>
    </Page>
  );
};

export default SheetList;
