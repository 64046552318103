import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import './styles.scss';

interface Props {
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  justify?: (
    'flex-start' |
    'flex-end' |
    'center' |
    'stretch' |
    'space-between' |
    'space-around'
  );
  wrap?: boolean;
  grow?: boolean;
  w?: 'initial' | 100 | 50 | 25;
  mT?: boolean;
  mB?: boolean;
  mR?: boolean;
  mL?: boolean;
  className?: string;
}

const FlexBox: FunctionComponent<Props> = (props) => {
  const {
    direction,
    justify,
    align,
    wrap,
    grow,
    w,
    mR,
    mB,
    mL,
    mT,
    className,
    children,
  } = props;

  const classNames = classnames(
    'flex_box',
    {
      direction__row: direction === 'row',
      direction__row_reverse: direction === 'row-reverse',
      direction__column: direction === 'column',
      direction__column_reverse: direction === 'column-reverse',
      justify__start: justify === 'flex-start',
      justify__center: justify === 'center',
      justify__end: justify === 'flex-end',
      justify__space_around: justify === 'space-around',
      justify__space_between: justify === 'space-between',
      justify__stretch: justify === 'stretch',
      align__start: align === 'flex-start',
      align__center: align === 'center',
      align__end: align === 'flex-end',
      align__stretch: align === 'stretch',
      flex__wrap: wrap,
      flex__grow: grow,
      width_initial: w === 'initial',
      width_100: w === 100,
      width_50: w === 50,
      width_25: w === 25,
      margin__top: mT,
      margin__bottom: mB,
      margin__left: mL,
      margin__right: mR,
    },
    className,
  );

  return (
    <div className={classNames}>{children}</div>
  );
};

FlexBox.defaultProps = {
  direction: 'row',
  justify: 'flex-start',
  align: 'flex-start',
};

export default FlexBox;
