import { useEffect, useMemo } from 'react';
import { OrderModel } from 'models/order';
import { ShipmentModel } from 'models/shipment';
import orderApi from 'api/orders';
import shipmentApi from 'api/shipment';
import useCallApiAction from 'hooks/base/call-api-action';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { onSaveCalendar, onSaveManager, onSaveProject, getPayload, getFormData } from './util';

export enum Owner {
  Production = 'Production',
  Project = 'Project',
  Calendar = 'Calendar',
}

const actions = {
  [Owner.Calendar]: onSaveCalendar,
  [Owner.Production]: onSaveManager,
  [Owner.Project]: onSaveProject,
};

const event = {
  [Owner.Calendar]: EventChannelList.PRODUCTION_MANAGER_CHANGED,
  [Owner.Production]: EventChannelList.PRODUCTION_MANAGER_CHANGED,
  [Owner.Project]: EventChannelList.PROJECT_COORDINATOR_UPDATE,
};

export default function useConfirmReadyDateFormState(orderId: number, owner: Owner) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: orderApi,
    eventChannel: event[owner],
    param: orderId,
    getPayload,
    getFormData,
    noGoBackOnSuccess: true,
    replaceAction: actions[owner]
  }), [orderId, owner]);

  const {
    state: {
      formData: order,
      errors,
      saving,
      errorFetchingEntity: errorFetchingOrder,
      fetchingEntity: fetchingOrder,
    },
    actions: {
      reFetchEntity,
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<OrderModel>(useFormStateParams);

  const shipmentParams = useMemo(() => ({
    filters: {
      project: order.quote?.project?.id,
    }
  }), [order.quote]);

  const {
    fetching: fetchingShipment,
    data: { results: shipment },
    error: shipmentError,
  } = useCallApiAction<{ results: ShipmentModel[]}>(
    shipmentApi.list,
    shipmentParams,
    { results: [] },
    !shipmentParams.filters.project,
  );

  useEffect(() => {
    if (errorFetchingOrder) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingOrder,
      });
    }
  }, [errorFetchingOrder]);

  useEffect(() => {
    if (shipmentError) {
      notification({
        type: NotificationType.ERROR,
        message: shipmentError,
      });
    }
  }, [shipmentError]);

  useEventCenterUpdate(event[owner], reFetchEntity);

  return {
    state: {
      order,
      errors,
      savingOrder: saving,
      fetchingFormData: fetchingOrder || fetchingShipment,
      shipmentType: useMemo(() => shipment.length > 0 ? shipment[0].shipmentMethod : null, [shipment]),
    },

    actions: {
      setFormValue,
      saveOrder: saveEntity,
      setErrors,
    },
  };
}
