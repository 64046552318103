import React, { FunctionComponent, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Page from 'components/base-components/Page';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import installerBookingRender from '../../ProductionManager/templates/installers-template';
import useCutListDetails from './state';
import tagRender from './templates/tag-templates';
import materialsRender from './templates/materials-tamplate';

const CutListDetails: FunctionComponent = () => {
  const { id } = useParams<any>();
  const {
    state: {
      fetching,
      data,
      cutListName
    }
  } = useCutListDetails(id);

  const installerRender = useCallback(installerBookingRender('installer'), []);
  const installationDateRender = useCallback(installerBookingRender('installationDate'), []);

  return (
    <Page title={cutListName}>
      <DataTable
        loading={fetching}
        dataSource={data}
        rowKey="id"
      >
        <DataTableColumn
          columnGroup
          title="JOB INFO"
          className="force-border-top"
        >
          <DataTableColumn dataIndex="sourceId" title="Id" />
          <DataTableColumn dataIndex="sourceType" title="Type" render={tagRender} />
          <DataTableColumn dataIndex="salesRepName" title="Rep. Owner" />
          <DataTableColumn dataIndex="jobTitle" title="Job Name" />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
        />
        <DataTableColumn
          columnGroup
          title="SUMMARY"
          className="force-border-top"
        >
          <DataTableColumn dataIndex="errorCount" title="Error" />
          <DataTableColumn dataIndex="partsCount" title="Parts" />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
        />
        <DataTableColumn
          columnGroup
          title="SHIPPING"
          className="force-border-top"
        >
          <DataTableColumn
            title="Installer"
            render={installerRender}
          />
          <DataTableColumn
            title="Inst. Date"
            render={installationDateRender}
          />
        </DataTableColumn>
        <DataTableColumn
          className="no-border-bottom"
        />
        <DataTableColumn columnGroup title="DETAILS" className="force-border-top">
          <DataTableColumn dataIndex="sourceId" title="Fab" />
          <DataTableColumn dataIndex="sheets" title="Materials" render={materialsRender} />
          <DataTableColumn
            title="Actions"
            isActionColumn
            width="110px"
          >
            <DataTableAction
              onClick={() => {}}
              label="Install"
              icon="fa fa-tools"
            />
            <DataTableAction
              onClick={() => {}}
              label="Shop"
              icon="fa fa-shopping-bag"
            />
          </DataTableColumn>
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default CutListDetails;
