import { Actions } from '../reducer';

export default function handleTextureImageLoad(dispatch, imageRef) {
  return () => {
    if (imageRef.current) {
      const { width, height } = imageRef.current.getBoundingClientRect();
      const widthRatio = Math.round(width / height);
      dispatch({ type: Actions.LOAD_TEXTURE_IMAGE, payload: widthRatio });
    }
  };
}
