import React, { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router';
import Page from 'components/base-components/Page';
import CustomerGroupIncome from './componets/CustomerGroupIcome';
import CustomerIncome from './componets/CustomerIncome';
import TotalSales from './componets/TotalSales';
import { ReportsComponents } from '.';

const ReportMap: { [sender: string]: any } = {
  [ReportsComponents.CustomerGroupIncomeCharts]: <CustomerGroupIncome />,
  [ReportsComponents.CustomerIncomeChart]: <CustomerIncome />,
  [ReportsComponents.TotalSalesTable]: <TotalSales />,
};

const ReportNameMap: { [sender: string]: any } = {
  [ReportsComponents.CustomerGroupIncomeCharts]: 'Income By Customer Group',
  [ReportsComponents.CustomerIncomeChart]: 'Income By Customer',
  [ReportsComponents.TotalSalesTable]: 'Total Sales',
};

const ReportView: FunctionComponent = () => {
  const history = useHistory();
  const { report } = useParams<any>();
  if (!report || !ReportMap[report]) {
    history.push('/reports');
  }

  return (
    <Page title={ReportNameMap[report]}>
      {ReportMap[report]}
    </Page>
  );
};

export default ReportView;
