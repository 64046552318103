import { useEffect, useMemo } from 'react';
import { InstallerBookingModel } from 'models/installer-booking';
import installerBookingApi from 'api/installer-booking';
import cutListApi from 'api/cut-list';
import useCallApiAction from 'hooks/base/call-api-action';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';

export default function useCutListDetails(cutListId) {
  const {
    data,
    error,
    fetching,
  } = useGetOne(cutListApi, cutListId);

  const installerParams = useMemo(() => {
    const projectIds = !fetching
      ? data?.cutlistsource.map((item) => item.projectId)
      : [];
    return {
      filters: {
        shipment__project__in: [...new Set(projectIds)].join(','),
      }
    };
  }, [fetching, data]);

  const {
    fetching: fetchingInstallers,
    data: { results: installers },
    error: installersError,
  } = useCallApiAction<{ results: InstallerBookingModel []}>(
    installerBookingApi.list,
    installerParams,
    { results: [] },
    fetching || !data || installerParams?.filters?.shipment__project__in?.length === 0,
  );

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (installersError) {
      notification({
        type: NotificationType.ERROR,
        message: installersError,
      });
    }
  }, [installersError]);

  return {
    state: {
      fetching: fetchingInstallers || fetching,
      cutListName: useMemo(() => data?.name ? `CNC Manager - ${data?.name}` : 'CNC Manager', [data]),
      data: useMemo(() => data?.cutlistsource.map((item) => {
        const installer = installers.filter((i) => i.shipment.project === item.projectId);
        if (installer) {
          return {
            ...item,
            installersBooking: installer.map(inst => ({
              installer: inst.installer.companyName,
              installationDate: inst.installationDate,
            })),
          };
        }
        return item;
      }), [data, installers]),
    }
  };
}
