import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Moment } from 'moment';
import { DropdownOptionModel, mapGenericDropdownOptions } from 'helpers/dropdown-options';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { OrderProductModel, PurchaseOrderItemModel } from 'models/order';
import purchaseOrderItemApi from 'api/purchase-order-item';
import validationsRules from './validations';
import getFormData from './actions/get-form-data';
import getPayload from './actions/get-payload';

export function mapOrderProductsOptions(orderProducts: OrderProductModel[]) {
  return mapGenericDropdownOptions(orderProducts);
}

export interface PurchaseOrderItemFormModel {
  partName: string;
  description: string;
  quantity: number;
  price: string;
  received: boolean;
  model: string;
  mpn: string;
  orderProduct: DropdownOptionModel;
  purchaseOrder: string;
  sentToPaint: Moment;
}

export default function usePurchaseOrderItemFormState(id: string, purchaseOrderId: string, fromProjectCoordinator?) {
  const { replace, goBack } = useHistory();
  const handleGetFormData = useCallback((purchaseOrderItem) => getFormData(purchaseOrderItem, purchaseOrderId),
    [purchaseOrderId]);

  const useFormStateParams: UseFormStateParams = useMemo(() => {
    return {
      api: purchaseOrderItemApi,
      eventChannel: EventChannelList.PURCHASE_ORDER_ITEMS_LIST_CHANGED,
      param: id,
      validationRules: validationsRules,
      getFormData: handleGetFormData,
      getPayload,
      noGoBackOnSuccess: true,
      clearOnSuccess: true,
    };
  }, [id, handleGetFormData]);

  const {
    state: {
      formData: purchaseOrderItem,
      errors,
      saving,
      errorFetchingEntity: errorFetchingPurchaseOrderItem,
      fetchingEntity: fetchingPurchaseOrderItem,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<PurchaseOrderItemModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingPurchaseOrderItem) {
      notification({ type: NotificationType.ERROR, message: errorFetchingPurchaseOrderItem });
    }
  }, [errorFetchingPurchaseOrderItem]);

  return {
    state: {
      purchaseOrderItem,
      savingPurchaseOrderItem: saving,
      errors,
      fetchingFormData: fetchingPurchaseOrderItem,
    },
    actions: {
      setFormValue,
      setErrors,
      savePurchaseOrder: saveEntity,
      goBack: useCallback(
        () => fromProjectCoordinator ? goBack() : replace(`${purchaseOrderId}`),
        [replace, purchaseOrderId, fromProjectCoordinator, goBack]
      )
    },
  };
}
