import React, { FunctionComponent, useMemo } from 'react';
import { PurchaseOrderModel } from 'models/order';
import purchaseOrderItemApi from 'api/purchase-order-item';
import hasPermissions from 'helpers/permissons';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import NumberInput from 'components/base-components/NumberInput';
import Checkbox from 'components/base-components/Checkbox';
import { permissions } from '../permissions';
import mpnTemplate from '../mpn-template';
import usePOIDataTableState from './state';

interface Props {
  purchaseOrderId: string;
  fetchingPurchaseOrder: boolean;
  purchaseOrder: PurchaseOrderModel;
  hiddenExtra?: string[];
  pageSize?: number;
}

const getPayload = (data, newValue, field) => (
  { ...data, orderProduct: typeof data.orderProduct === 'number' ? data.orderProduct : data.orderProduct?.id, [field]: newValue }
);

const PODataTable: FunctionComponent<Props> = (props) => {
  const { purchaseOrderId, fetchingPurchaseOrder, purchaseOrder, hiddenExtra, pageSize } = props;

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
      openFormModal,
    }
  } = usePOIDataTableState(purchaseOrderId, fetchingPurchaseOrder, purchaseOrder, pageSize);

  const shouldShowContent = useMemo(() => {
    const has = hasPermissions(permissions.editPOItem);
    return {
      search: !hiddenExtra?.includes('search') ? handleFilter : undefined,
      quantity: has && !hiddenExtra?.includes('quantity'),
      price: has && !hiddenExtra?.includes('price'),
      received: !has && hiddenExtra?.includes('received'),
      onEdit: !hiddenExtra?.includes('edit') ? openFormModal : undefined,
      onDelete: !hiddenExtra?.includes('delete') ? handleDeleteRow : undefined,
      pagination: pageSize ? { pageSize } : undefined,
    };
  }, [openFormModal, hiddenExtra, handleFilter, handleDeleteRow, pageSize]);

  const actionWidth = !shouldShowContent.onDelete || !shouldShowContent.onEdit ? '80px' : '140px';

  return (
    <DataTable
      id="po-item-data-table"
      dataSource={data}
      loading={fetching}
      page={page}
      totalRecords={count}
      onChange={handleOnChange}
      onFilter={shouldShowContent.search}
      onSubmit={purchaseOrderItemApi.update}
      pagination={shouldShowContent.pagination}
    >
      <DataTableColumn
        title="MPN"
        render={mpnTemplate}
        sorter
      />
      <DataTableColumn
        title="Model"
        dataIndex="model"
        sorter
      />
      <DataTableColumn
        title="Quantity"
        dataIndex="quantity"
        columnKey="quantity"
        component={NumberInput}
        placeholder="Enter quantity"
        editable={shouldShowContent.quantity}
        getPayload={getPayload}
        sorter
        width="110px"
      />
      <DataTableColumn
        title="Price"
        dataIndex="price"
        columnKey="price"
        placeholder="Enter price"
        component={NumberInput}
        allowDecimals
        editable={shouldShowContent.price}
        getPayload={getPayload}
        sorter
        isCurrency
        width="110px"
      />
      <DataTableColumn
        title="Received"
        dataIndex="received"
        component={Checkbox}
        columnKey="received"
        align="center"
        alwaysShowInput
        getPayload={getPayload}
        editable
        disabled={shouldShowContent.received}
        width="100px"
      />
      <DataTableColumn
        isActionColumn
        onEdit={shouldShowContent.onEdit}
        editPermissions={permissions.editPOItem}
        deletePermissions={permissions.deletePOItem}
        onDelete={shouldShowContent.onDelete}
        hideColumn={!shouldShowContent.onDelete && !shouldShowContent.onEdit}
        width={actionWidth}
      />
    </DataTable>
  );
};

export default PODataTable;
