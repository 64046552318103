import React, { FunctionComponent } from 'react';
import { Avatar as AntAvatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import './styles.scss';

const Avatar: FunctionComponent<AvatarProps> = (props) => {
  return (
    <AntAvatar className="avatar" {...props} />
  );
};

export default Avatar;
