import { UserModel } from 'models/user';
import { mapDropdownOptions } from 'helpers/dropdown-options';
import { mapFileFromUrl } from 'helpers/file';

const defaultUser = {
  email: '',
  username: '',
  title: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordRepeat: '',
  groups: [],
  groupsDetail: [],
  userPermissions: [],
  userPermissionsDetail: [],
  image: [],
  isStaff: false,
  isSuperuser: false,
};

export default function getFormData(user: UserModel) {
  if (user) {
    const { groupsDetail, userPermissionsDetail, image, ...rest } = user;

    return {
      ...rest,
      groups: mapDropdownOptions(groupsDetail),
      userPermissions: mapDropdownOptions(userPermissionsDetail),
      image: mapFileFromUrl(image),
    };
  }

  return defaultUser;
}
