import { OrderDTO } from 'models/order';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { EmailSender } from 'components/forms/SendEmail/state';

export default function openSendEmailModal(push, search) {
  return (order: OrderDTO) => {
    const { id, clientInfo } = order;
    const recipients = clientInfo ? [clientInfo.email] : undefined;

    push(
      `${search}${ModalRouteHash.SendEmailForm}`,
      {
        sendEmailForm: {
          id,
          recipients,
          from: EmailSender.Order,
          title: 'Send Order by email',
        },
      },
    );
  };
}
