import { useEffect, useMemo } from 'react';
import { AttachmentDocument } from 'models/attachment-document';
import attachmentDocumentApi from 'api/attachment-document';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import useCallApiAction from 'hooks/base/call-api-action';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { isValidFile, mapFileFromUrl } from 'helpers/file';
import { validationRules } from './validations';

const defaultAttachmentDocument: AttachmentDocument = {
  document: undefined,
  documentType: undefined,
  store: undefined
};

const getPayload = (attachmentDocument) => ({
  ...attachmentDocument,
  store: attachmentDocument.store.value,
  documentType: attachmentDocument.documentType?.value,
  document: attachmentDocument.document && isValidFile(attachmentDocument.document[0])
    ? attachmentDocument.document[0] : undefined,
});

const getFormData = (attachmentDocument: AttachmentDocument) => {
  return attachmentDocument
    ? {
      ...attachmentDocument,
      store: { value: attachmentDocument.store, label: attachmentDocument.store },
      document: attachmentDocument.document
        ? mapFileFromUrl(attachmentDocument.document) : undefined,
      documentType: { value: attachmentDocument.documentType, label: attachmentDocument.documentType.replaceAll('_', ' ') }
    }
    : defaultAttachmentDocument;
};

export default function useAttachmentDocumentFormState(attachmentDocumentId: number) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: attachmentDocumentApi,
    eventChannel: EventChannelList.ATTACHMENT_DOCUMENT_LIST_CHANGE,
    param: attachmentDocumentId,
    getPayload,
    getFormData,
    validationRules,
    defaultSortField: '-id'
  }), [attachmentDocumentId]);

  const {
    state: {
      formData: attachmentDocument,
      errors,
      saving,
      errorFetchingEntity,
      fetchingEntity,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<AttachmentDocument>(useFormStateParams);

  const {
    data: options,
    fetching: fetchingOptions
  } = useCallApiAction(attachmentDocumentApi.getOption, undefined, undefined as any);

  useEffect(() => {
    if (errorFetchingEntity) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingEntity,
      });
    }
  }, [errorFetchingEntity]);

  return {
    state: {
      attachmentDocument,
      saving,
      fetchingFormData: fetchingEntity || fetchingOptions,
      errors,
      typeOptions: useMemo(() => {
        if (!!options) {
          return options?.actions?.POST?.document_type?.choices?.map(e => ({ value: e.value, label: e.value?.replaceAll('_', ' ') }));
        }
        return [];
      }, [options]),
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  };
}
