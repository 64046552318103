import { OrderModel } from 'models/order';
import { CalendarEvent, EventItemType } from '../reducer';

export default function buildOrdersSchedule(orders: OrderModel[]): CalendarEvent[] {
  return orders.map((order) => {
    const { id, readyDate, jobTitle } = order;
    return {
      id: `order-${id.toString(10)}`,
      title: `${id} - ${jobTitle}`,
      start: new Date(readyDate),
      allDay: true,
      extendedProps: {
        type: EventItemType.ORDER,
        item: order,
      },
    } as CalendarEvent;
  });
}
