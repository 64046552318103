import { AxiosPromise } from 'axios';
import { InvoiceReportModel } from 'models/invoice-report';
import { QueryParams } from 'helpers/query-params';
import { get, ListResponseModel } from './base';

const { REACT_APP_TRANSACTIONS_API } = process.env;
const endpoint = `${REACT_APP_TRANSACTIONS_API}/invoice-reports/`;

const invoiceReportsApi = {
  list: (params: QueryParams): AxiosPromise<ListResponseModel<InvoiceReportModel>> => {
    return get(endpoint, params);
  }
};

export default invoiceReportsApi;
