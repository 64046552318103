import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { EmailSender } from 'components/forms/SendEmail/state';

export default function openSendEmailModal(
  push,
  quote: QuoteModel,
  quoteSender?: boolean,
) {
  return () => {
    const { id, project: { clientInfo }, isconverted, orderNum } = quote;
    const recipients = clientInfo ? [clientInfo.email] : undefined;
    const asProposal = !isconverted && !quoteSender;
    const formQuote = asProposal ? EmailSender.QuoteProposal : EmailSender.Quote;

    return push(
      ModalRouteHash.SendEmailForm,
      {
        sendEmailForm: {
          id: isconverted ? orderNum?.split('-')[1] : id,
          recipients,
          from: isconverted ? EmailSender.Order : formQuote,
          title: asProposal ? 'Send Quote Proposal by email' : 'Send Quote by email',
        },
      },
    );
  };
}
