import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { generateQueryString } from 'helpers/query-params';
// import getAuthToken from 'helpers/auth-token';
// import { sanitizeUrl } from 'helpers/url-parsing';

export default function getAxiosInstance() {
  const axiosInstance = axios.create({
    paramsSerializer: generateQueryString
  });

  // axiosInstance.interceptors.request.use(
  //   (request) => {
  //     const authToken = getAuthToken();
  //     if (authToken) {
  //       request.headers.Authorization = `Bearer ${authToken}`;
  //     }
  //     return request;
  //   },
  //   (error) => {
  //     const { response: { data } } = error;
  //     return Promise.reject(data);
  //   },
  // );

  // axiosInstance.interceptors.request.use((config) => {
  //   // eslint-disable-next-line no-param-reassign
  //   config.url = sanitizeUrl(config.url);
  //   return config;
  // });

  axiosInstance.interceptors.response.use(
    response => {
      return camelcaseKeys(response, {
        deep: true,
        stopPaths: [
          'data.option_calc',
          'data.results.option_calc',
          'data.data.options',
          'data.results.plan',
          'data.files'
        ],
      });
    },
    error => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}
