import Auth from '@aws-amplify/auth';

export const checkAuthMiddleware = () => next => async action => {
  try {
    await Auth.currentAuthenticatedUser();

    const isLoggedIn = localStorage.getItem('isLoggedIn');

    if (!isLoggedIn) {
      localStorage.setItem('isLoggedIn', 'true');
    }
  } catch (error) {
    localStorage.clear();
    next({ type: 'login/setAuthState', payload: false });
  }

  return next(action);
};
