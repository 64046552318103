import React, { FunctionComponent } from 'react';

interface Props {
  primary?: boolean;
  isPageAction?: boolean;
}

export const PageActions: FunctionComponent<Props> = () => <></>;

PageActions.defaultProps = {
  isPageAction: true,
};
