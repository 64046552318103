import { FormAddressModel } from '../reducer';

export function getAddressPayload(addressInfo: FormAddressModel) {
  const {
    province,
    ...otherAddressFields
  } = addressInfo;

  return {
    ...otherAddressFields,
    province: province?.value,
  };
}
