import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { PaymentModel } from 'models/payment';
import paymentApi from 'api/payment';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Help from 'components/forms/CommonHelp';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { PaymentFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: paymentApi,
  deleteModalTitle: 'Delete Payment',
  deleteModalContent: (paymentToDelete: PaymentModel) => `Are you sure that you want to delete the Payment fot ${paymentToDelete ? paymentToDelete.jobName : ''}?`,
  searchFields: ['job_name', 'account', 'order'],
  eventChannel: EventChannelList.PAYMENTS_LIST_CHANGE,
  defaultSortField: '-id'
};

const PaymentsList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<PaymentModel>(useTableStateParams);

  const openPaymentFormModal = (payment?: PaymentModel | any) => {
    const historyLocationState: PaymentFormHistorySateModel = {
      PaymentForm: {
        paymentId: payment ? payment.id : undefined,
      },
    };
    push(ModalRouteHash.PaymentForm, historyLocationState);
  };

  return (
    <Page title="Payments">
      <Help view="payment" title="Payments List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addPayment}>
          <Button
            onClick={openPaymentFormModal}
            label="Add Payment"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="id" columnKey="id" title="Payment Id" sorter />
        <DataTableColumn dataIndex="account" columnKey="account" title="account" sorter />
        <DataTableColumn dataIndex="jobName" columnKey="job_name" title="Job Name" sorter />
        <DataTableColumn
          dataIndex="invoice"
          columnKey="invoice"
          title="Invoice Num"
          sorter
          showBreakpoint={750}
        />
        <DataTableColumn
          dataIndex={['method', 'methodName']}
          columnKey="method"
          title="Method"
          sorter
          showBreakpoint={1094}
        />
        <DataTableColumn
          dataIndex="receivedDate"
          columnKey="received_date"
          title="Received Date"
          sorter
          isDate
          showBreakpoint={1246}
        />
        <DataTableColumn
          dataIndex="amount"
          columnKey="amount"
          title="amount"
          sorter
          isCurrency
          showBreakpoint={1333}
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editPayment}
          deletePermissions={permissions.deletePayment}
          onEdit={openPaymentFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default PaymentsList;
