import React, { FunctionComponent } from 'react';
import { Popconfirm as AntPopConfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';

const PopConfirm: FunctionComponent<PopconfirmProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntPopConfirm {...rest}>
      {children}
    </AntPopConfirm>
  );
};

export default PopConfirm;
