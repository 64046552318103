import React, { FunctionComponent } from 'react';
import { Radio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';

export { Option } from './option';

interface Props extends RadioGroupProps {
  name?: string;
  value: any;
  onChange: (value) => void;
}

export const RadioGroup: FunctionComponent<Props> = (props) => {
  const { value, onChange, children, ...rest } = props;

  return (
    <Radio.Group value={value} onChange={onChange} {...rest}>
      {children}
    </Radio.Group>
  );
};
