import { SubOptionFormActions } from '../reducer';

export default function handleOnChange(dispatch, initialSubOption) {
  return (subOption?: any) => {
    const payload = subOption
      ? {
        ...subOption,
        brand: { value: subOption.brand, name: subOption.brand }
      }
      : initialSubOption;
    dispatch({ type: SubOptionFormActions.SET_FORM_VALUE, payload });
  };
}
