import deficiencyItemApi from 'api/deficiency-item';
import { DeficiencyItemModel } from 'models/order';
import { DeficiencyItemFormModel } from '../Form/state';

function getPayload(deficiencyItem: DeficiencyItemModel): DeficiencyItemFormModel {
  const { id, orderProduct, quantity, corrected, comment } = deficiencyItem;
  return {
    id,
    orderProduct: orderProduct.id,
    comment,
    corrected,
    quantity,
  };
}

export default function handleOnSubmit(deficiencyItem: DeficiencyItemModel) {
  return deficiencyItemApi.update(getPayload(deficiencyItem));
}
