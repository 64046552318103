import hasPermissions from 'helpers/permissons';

export function getLayoutPermissions() {
  const sideBarPermissions = {
    // Account
    user: hasPermissions(['Account-Users']),
    userGroup: hasPermissions(['Account-User_Groups']),
    account: hasPermissions(['Account-Accounts', 'Sales']),
    accountGroup: hasPermissions(['Account-Account_Groups']),
    terms: hasPermissions(['Account-Terms']),
    accountApplication: hasPermissions(['Account-Account_Applications', 'Sales']),
    // Catalog
    manufacturer: hasPermissions(['Catalog-Manufacturers', 'Sales', 'Shop']),
    product: hasPermissions(['Catalog-Products', 'Dealer', 'Sales', 'Shop']),
    options: hasPermissions(['Catalog-Options', 'Shop']),
    optionGroup: hasPermissions(['Catalog-Option_Groups', 'Sales', 'Shop']),
    category: hasPermissions(['Catalog-Categories', 'Sales', 'Shop']),
    // Inventory
    inventory: hasPermissions(['Inventory-Inventory', 'Shop']),
    // Productions
    installerBooking: hasPermissions(['Productions-Installers_Booking', 'Shop']),
    productionManager: hasPermissions(['Productions-Production_Manager', 'Shop']),
    projectCoordinator: hasPermissions(['Productions-Project_Coordinator', 'Shop']),
    cutList: hasPermissions(['Productions-Cutlist', 'Shop']),
    purchaseOrder: hasPermissions(['Productions-Purchase_Orders', 'Shop']),
    cncCalc: hasPermissions(['Productions-CNC_Calc', 'Shop']),
    cncWorker: hasPermissions(['Productions-CNC_Worker', 'Shop']),
    parts: hasPermissions(['Productions-Parts', 'Shop']),
    // Sales
    quote: hasPermissions(['Sales-Quotes', 'Dealer', 'Sales']),
    order: hasPermissions(['Sales-Orders', 'Dealer', 'Sales']),
    deficiencyOrder: hasPermissions(['Sales-Deficiency_Orders']),
    invoice: hasPermissions(['Sales-Invoices', 'Sales', 'Dealer']),
    payments: hasPermissions(['Sales-Payments']),
    projectDesigns: hasPermissions(['Dealer', 'Sales-Project_Designs']),
    // Sales_Pipeline
    crateProject: hasPermissions(['Sales_Pipeline-Create_Project', 'Sales']),
    // Reports
    reports: hasPermissions(['Reports']),
    // Settings
    threeModel: hasPermissions(['Settings-Three_Models']),
    tutorials: hasPermissions(['Settings-Tutorials']),
    shippingZone: hasPermissions(['Settings-Shipping-Shipping_Zones']),
    store: hasPermissions(['Settings-Account-Stores']),
    viewsPermissions: hasPermissions(['Settings-Views_Permissions']),
    installer: hasPermissions(['Settings-Shipping-Installer']),
    categoryImage: hasPermissions(['Settings-Catalog-Category_Image']),
    textures: hasPermissions(['Settings-Textures']),
    attachmentDocument: hasPermissions(['Settings-Attachment_Document']),
    generateProducts: hasPermissions(['Settings-Catalog-Generate_Products']),
    taskTemplate: hasPermissions(['Settings-Task_Templates']),
    propertySetting: hasPermissions(['Settings-Property_Setting']),
    sheet: hasPermissions(['Settings-Materials-Sheet_Setting']),
    qboSync: hasPermissions(['Settings-QBO_Sync']),
  };

  return {
    ...sideBarPermissions,
    shouldShowAccountSection:
      sideBarPermissions.account || sideBarPermissions.accountApplication ||
      sideBarPermissions.accountGroup || sideBarPermissions.user ||
      sideBarPermissions.userGroup || sideBarPermissions.terms,
    shouldShowCatalogSections:
      sideBarPermissions.manufacturer || sideBarPermissions.product ||
      sideBarPermissions.options || sideBarPermissions.optionGroup || sideBarPermissions.category,
    shouldShowSalesSection:
      sideBarPermissions.quote || sideBarPermissions.order || sideBarPermissions.invoice ||
      sideBarPermissions.deficiencyOrder || sideBarPermissions.payments || sideBarPermissions.projectDesigns,
    shouldShowPipelineSection: sideBarPermissions.crateProject,
    shouldShowProductionsSections: sideBarPermissions.productionManager || sideBarPermissions.installerBooking ||
      sideBarPermissions.projectCoordinator || sideBarPermissions.cutList || sideBarPermissions.purchaseOrder ||
      sideBarPermissions.cncCalc || sideBarPermissions.cncWorker || sideBarPermissions.parts,
    shouldShowSettingsSection: sideBarPermissions.shippingZone || sideBarPermissions.store ||
      sideBarPermissions.threeModel || sideBarPermissions.viewsPermissions ||
      sideBarPermissions.installer || sideBarPermissions.categoryImage ||
      sideBarPermissions.textures || sideBarPermissions.attachmentDocument || sideBarPermissions.taskTemplate ||
      sideBarPermissions.propertySetting || sideBarPermissions.sheet || sideBarPermissions.qboSync,
    shouldShowStoreSubSection: sideBarPermissions.store,
    shouldShowCatalogSubSection: sideBarPermissions.categoryImage || sideBarPermissions.generateProducts,
    shouldShowShippingSubSection: sideBarPermissions.shippingZone || sideBarPermissions.installer,
    shouldShowMaterialsSubSection: sideBarPermissions.parts || sideBarPermissions.sheet,
    shouldShowInventorySection: sideBarPermissions.inventory,
  };
}
