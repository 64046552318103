import { useMemo } from 'react';
import { QuoteModel } from 'models/quote';
import {
  ShipmentAddressModel,
  ShipmentContactModel,
  ShipmentDTO,
} from 'models/shipment';
import shippingAddressApi from 'api/shipping-address';
import shipmentContactApi from 'api/shipment-contact';
import useGetOne from 'hooks/base/get-one';

interface PrintoutInfo {
  quote: QuoteModel;
  shipment: ShipmentDTO;
}

function loadData(name: string) {
  const data = localStorage.getItem(name);

  if (data) {
    return JSON.parse(data);
  }

  return {};
}

export default function usePrintoutState(name: string) {
  const { quote, shipment } = useMemo<PrintoutInfo>(() => loadData(name), [name]);

  const {
    fetching: fetchingContact,
    data: contact,
    error: contactError,
  } = useGetOne<ShipmentContactModel>(shipmentContactApi, shipment?.contact);

  const {
    fetching: fetchingAddress,
    data: address,
    error: addressError,
  } = useGetOne<ShipmentAddressModel>(shippingAddressApi, shipment?.shippingAddress);

  return {
    noData: !quote && !shipment,
    fetching: fetchingContact || fetchingAddress,
    error: contactError || addressError,
    quote,
    contact,
    address,
  };
}
