import { flatDropdownOptions } from 'helpers/dropdown-options';
import { isValidFile } from 'helpers/file';
import { CategoryFormModel } from '..';

const { REACT_APP_PRODUCTS_API } = process.env;
const getValue = (val) => val.value || `${REACT_APP_PRODUCTS_API}/category/${val}/`;

export default function getPayload(category: CategoryFormModel) {
  const { parent, optionGrp, categories, image, threeModel, ...rest } = category;

  return {
    ...rest,
    parent: parent ? encodeURI(getValue(parent)) : undefined,
    optionGrp: flatDropdownOptions(optionGrp),
    threeModel: threeModel?.value,
    categories: categories.map((cat) => encodeURI(getValue(cat))),
    image: image && isValidFile(image[0]) ? image : undefined,
  };
}
