import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import optionGroupApi from 'api/option-group';
import threeModelApi from 'api/three-model';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import Checkbox from 'components/base-components/Checkbox';
import Upload from 'components/base-components/Upload';
import TextArea from 'components/base-components/TextArea';
import TreeSelect, { SHOW_PARENT } from 'components/base-components/TreeSelect';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import useCategoryFormState, { mapOptionGroups, mapThreeModel, validationRules } from './state';
import { CategoryFormActions } from './state/reducer';

export interface CategoryFormHistorySateModel {
  categoryForm: {
    categoryUrl: string;
  };
}

const CategoryForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { categoryForm: { categoryUrl } } = state as CategoryFormHistorySateModel;

  const {
    state: {
      category,
      savingCategory,
      parentTreeData,
      categoriesTreeData,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveCategory,
      setErrors,
      loadTreeSelectData
    },
  } = useCategoryFormState(categoryUrl);

  const modalTitle = useMemo(
    () => categoryUrl ? 'Edit Category' : 'Create Category',
    [categoryUrl],
  );

  const loadParentTreeData =
    useCallback((treeNode) => loadTreeSelectData(treeNode, CategoryFormActions.SET_PARENT_TREE_DATA),
      [loadTreeSelectData]);

  const loadCategoriesTreeData =
    useCallback((treeNode) => loadTreeSelectData(treeNode, CategoryFormActions.SET_CATEGORIES_TREE_DATA),
      [loadTreeSelectData]);

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveCategory}
      confirmLoading={savingCategory}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={category}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            type="text"
            id="name"
            name="name"
            label="Name"
            placeholder="Enter Category name"
            disabled={category.url}
          />
          <Field
            type="text"
            id="nameFr"
            name="nameFr"
            label="Name fr"
            placeholder="Enter Category fr name"
          />
          <Field
            type="text"
            id="description"
            name="description"
            label="Description"
            placeholder="Enter description"
          />
          <Field
            type="text"
            id="descriptionFr"
            name="descriptionFr"
            label="Description fr"
            placeholder="Enter description fr"
          />
          <Field
            type="text"
            id="modelName"
            name="modelName"
            label="Model Name"
            placeholder="Enter Model Name"
          />
          <Field
            component={TextArea}
            id="levelDescription"
            name="levelDescription"
            label="Level Description"
            placeholder="Enter Level Description"
            rows={4}
          />
          <Field
            component={TreeSelect}
            id="parent"
            name="parent"
            label="Parent"
            loadData={loadParentTreeData}
            treeData={parentTreeData}
          />
          <div>
            <Field component={Checkbox} name="navBar" id="navBar" label="Nav bar" />
            <Field component={Checkbox} name="status" id="status" label="Status" />
            <Field component={Checkbox} name="outsidePaint" id="outsidePaint" label="Outside paint" />
            <Field component={Checkbox} name="cnc" id="cnc" label="Cnc" />
            <Field component={Checkbox} name="stackable" id="stackable" label="Stackable" />
            <Field component={Checkbox} name="overlapable" id="overlapable" label="Overlapable" />
            <Field component={Checkbox} name="stackontop" id="stackontop" label="Stack On Top" />
          </div>
          <Field
            component={AsyncSelectV2}
            id="optionGrp"
            name="optionGrp"
            label="Option grp"
            mode="multiple"
            listAction={optionGroupApi.list}
            mapFunction={mapOptionGroups}
          />
          <Field
            component={AsyncSelectV2}
            id="threeModel"
            name="threeModel"
            label="Three Model"
            listAction={threeModelApi.list}
            mapFunction={mapThreeModel}
          />
          <Field
            component={TreeSelect}
            name="categories"
            label="Categories"
            loadData={loadCategoriesTreeData}
            treeData={categoriesTreeData}
            treeCheckable
            showCheckedStrategy={SHOW_PARENT}
          />
          <Field
            component={Upload}
            name="image"
            id="image"
            label="Choose Image"
            accept="image/png, .jpeg, .jpg"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default CategoryForm;
