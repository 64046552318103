import Auth from '@aws-amplify/auth';
import { createSlice } from '@reduxjs/toolkit';
import { COGNITO_USER, NEW_PASSWORD_REQUIRED } from 'constants/login';
import userApi from 'api/user';
import { LoggedUserDetailsModel } from 'models/user';

function getInitialState() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const isAuthenticated = !!userDetails;
  const initialState = {
    isAuthenticated,
    userId: undefined,
    username: undefined,
    permissions: undefined,
    loading: false,
    error: null,
    lang: 'en',
  };

  if (userDetails) {
    return {
      ...initialState,
      ...userDetails,
    };
  }

  return initialState;
}

const loginSlice = createSlice({
  name: 'login',
  initialState: getInitialState(),
  reducers: {
    loginStart(state) {
      state.loading = true;
    },
    loginSuccess(state, action) {
      const { id, username, permissions } = action.payload;
      state.userId = id;
      state.username = username;
      state.permissions = permissions;
      state.isAuthenticated = true;
      state.error = null;
      state.loading = false;
    },
    loginFail(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    logoutStart() {
      // state = state // really need ?
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
    },
    setAuthState(state, action) {
      state.isAuthenticated = action.payload;
    },
    changeLang(state, action) {
      state.lang = action.payload;
      const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
      localStorage.setItem(
        'userDetails',
        JSON.stringify({ ...userDetails, lang: action.payload }),
      );
    }
  }
});

const {
  loginStart,
  loginSuccess,
  loginFail,
  logoutStart,
} = loginSlice.actions;

export const { logoutSuccess, changeLang } = loginSlice.actions;
export const loginUser = (credentials, history) => async dispatch => {
  try {
    dispatch(loginStart());

    const authResponse = await Auth.signIn(
      credentials.username,
      credentials.password,
    );

    const {
      keyPrefix,
      username,
      challengeName,
      attributes: {
        sub: userId,
      },
    } = authResponse;

    if (challengeName === NEW_PASSWORD_REQUIRED) {
      Auth[COGNITO_USER] = authResponse;
      return history.push('/set-password');
    }

    const {
      data: {
        userPermissionsDetail,
        groupsDetail,
        image,
        firstName,
        lastName,
        email,
        isSuperuser,
        isStaff
      },
    } = await userApi.getById(userId);
    const permissions = [...userPermissionsDetail];
    const permissionsGroup = [];
    groupsDetail.forEach(groupsD => {
      const permissionByName = groupsD.name.split('(')[0];
      permissions.push(...groupsD.permissionsDetail);
      permissionsGroup.push(permissionByName);
    })
    const permissionsSet = new Set(permissions);
    const userPermissions = [...permissionsSet].map(p => p.codename);

    const userDetails: LoggedUserDetailsModel = {
      id: userId,
      keyPrefix,
      username,
      email,
      image,
      isSuperuser,
      isStaff: isSuperuser || isStaff,
      name: `${firstName} ${lastName}`,
      permissions: [...userPermissions, ...permissionsGroup],
    };
    localStorage.setItem('userDetails', JSON.stringify(userDetails));

    dispatch(loginSuccess(userDetails));
  } catch (error) {
    localStorage.clear();
    dispatch(loginFail((error as any).message || ''));
  }
};

export const logoutUser = () => async dispatch => {
  try {
    logoutStart();

    await Auth.signOut();
    localStorage.clear();

    dispatch(logoutSuccess());
  } catch (error) {
    // @todo: display alert
  }
};

export default loginSlice.reducer;
