import { TaskModel } from 'models/task';
import moment from 'moment';
import projectsApi from 'api/projects';
import taskUserApi from 'api/task-user';
import { resolveApiErrorMessage } from 'api/base';
import taskSubscriptionApi from 'api/task-subscription';
import { getLoggedUserId } from 'helpers/get-logged-user';
import notification, { NotificationType } from 'helpers/notification';
import { verbosityOptions } from '..';
import { TaskFormActions } from '../reducer';

const defaultTask = {
  title: '',
  project: null,
  dueDate: moment(),
  description: '',
  notifyAssigner: false,
  notifyBySms: false,
  notifyByEmail: false,
  status: null,
  assignee: null,
  verbosity: { value: '0', label: 'Minimal' },
};

export default async function initFormData(dispatch, task: TaskModel) {
  let formData = defaultTask;

  if (task) {
    try {
      const { status } = task;
      const { data: project } = await projectsApi.get(task?.project);
      const { data: assignee } = await taskUserApi.get(task.assignee);
      const { data: { results: subs } } = await taskSubscriptionApi.list({
        filters: {
          taskUser: getLoggedUserId(),
          task: task.id,
        },
      });

      const notifyBySms = subs.some(sub => sub.protocol === 'sms');
      const notifyByEmail = subs.some(sub => sub.protocol === 'email');
      const verbosity = subs[0] ? verbosityOptions.find(e => e.value === subs[0].verbosity) : { value: '0', label: 'Minimal' };

      formData = {
        ...task,
        notifyBySms,
        notifyByEmail,
        dueDate: moment(task.dueDate),
        status: status ? { value: status, label: status } : undefined,
        project: project ? { value: project.id, label: project.jobTitle } : undefined,
        assignee: assignee ? { value: assignee.user, label: assignee.email } : undefined,
        verbosity,
      };
    } catch (error) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(error),
      });
    }
  }

  dispatch({
    type: TaskFormActions.INIT_FORM,
    payload: formData,
  });
}
