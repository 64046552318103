import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { BuilderActions } from '../reducer';

export default function handleFloorChange(dispatch) {
  return (floor) => {
    notifyEventChannel(EventChannelList.BUILDER_FLOOR_CHANGED, floor);
    dispatch({
      type: BuilderActions.UPDATE_WALL,
      payload: floor,
    });
  };
}
