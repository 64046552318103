import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { AttachmentDocument } from 'models/attachment-document';
import attachmentDocumentApi from 'api/attachment-document';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import Help from 'components/forms/CommonHelp';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import { AttachmentDocumentFormHistorySateModel } from '../Form';

const useTableStateParams: UseTableStateParams = {
  api: attachmentDocumentApi,
  deleteModalTitle: 'Delete Attachment Document',
  deleteModalContent:
    (attachmentDocumentToDelete: AttachmentDocument) => `Are you sure that you want to delete the Attachment Document for the Store ${attachmentDocumentToDelete ? attachmentDocumentToDelete.store : ''}?`,
  searchFields: ['store', 'document_type'],
  eventChannel: EventChannelList.ATTACHMENT_DOCUMENT_LIST_CHANGE,
  idField: 'id',
  defaultSortField: '-id'
};
const documentTypeRender = (e: string) => e?.replaceAll('_', ' ');

const AttachmentDocumentList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<AttachmentDocument>(useTableStateParams);

  const openAttachmentDocumentFormModal = (attachmentDocument?: AttachmentDocument | any) => {
    const historyLocationState: AttachmentDocumentFormHistorySateModel = {
      attachmentDocumentForm: {
        attachmentDocumentId: attachmentDocument ? attachmentDocument.id : undefined,
      },
    };
    push(ModalRouteHash.AttachmentDocumentForm, historyLocationState);
  };

  return (
    <Page title="Attachment Document">
      <Help view="attachment-document" title="Attachment Document List" />
      <PageActions primary>
        <Button
          onClick={openAttachmentDocumentFormModal}
          label="Add Attachment Document"
        />
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="store" columnKey="store" title="Store" sorter />
        <DataTableColumn
          dataIndex="documentType"
          columnKey="document_type"
          title="Document Type"
          render={documentTypeRender}
          sorter
        />
        <DataTableColumn
          dataIndex="document"
          columnKey="document"
          title="Document"
          sorter
          showBreakpoint={1321}
        />
        <DataTableColumn
          isActionColumn
          onEdit={openAttachmentDocumentFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default AttachmentDocumentList;
