import React, { FunctionComponent } from 'react';

interface Props {
  instructions: string;
}

const Instructions: FunctionComponent<Props> = (props) => {
  const { instructions } = props;

  if (instructions) {
    return (
      <div className="work-order__instructions">
        <h4>Special Instructions:</h4>
        <p>{instructions}</p>
      </div>
    );
  }

  return null;
};

export default Instructions;
