import React, { FunctionComponent } from 'react';
import KanbanBoard from 'components/base-components/KanbanBoard';
import Drawer from 'components/base-components/Drawer';
import Button from 'components/base-components/Button';
import TaskForm from './Form';
import useTaskManager from './state';

const TaskManager: FunctionComponent = () => {
  const {
    state: {
      columns,
      task,
      isShowTaskForm,
      generatingColumns
    },
    actions: {
      openEditTaskModal,
      onCardDragEnd,
      goBack,
      onClose
    },
  } = useTaskManager();

  return (
    <>
      <Drawer
        title="Task Manager"
        visible
        onClose={goBack}
      >
        <Button
          onClick={() => openEditTaskModal()}
          label="Create Quote"
          mL
          mB
        />
        <KanbanBoard
          columns={columns}
          openEdit={openEditTaskModal}
          onCardDragEnd={onCardDragEnd}
          loading={generatingColumns}
        />
      </Drawer>
      <TaskForm visible={isShowTaskForm} taskInstance={task} onCancel={onClose} />
    </>
  );
};

export default TaskManager;
