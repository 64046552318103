import { useEffect, useMemo } from 'react';
import { PropertySettingModel } from 'models/property-setting';
import propertySettingApi from 'api/property-setting';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { validationRules } from './validations';

const defaultProperty: PropertySettingModel = {
  url: undefined,
  name: '',
  increment: 1,
  propertyTypes: undefined,
};

const getPayload = (property) => ({
  ...property,
  propertyTypes: property.propertyTypes.value,
});

const getFormData = (property: PropertySettingModel) => {
  return property
    ? {
      ...property,
      propertyTypes: { value: property.propertyTypes, label: property.propertyTypes.replace('_', ' ') }
    }
    : defaultProperty;
};

export default function usePropertyFormState(propertyUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: propertySettingApi,
    eventChannel: EventChannelList.PROPERTY_SETTING_CHANGED,
    param: propertyUrl,
    getPayload,
    getFormData,
    validationRules
  }), [propertyUrl]);

  const {
    state: {
      formData,
      errors,
      saving,
      errorFetchingEntity,
      fetchingEntity,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<PropertySettingModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingEntity) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingEntity,
      });
    }
  }, [errorFetchingEntity]);

  return {
    state: {
      formData,
      saving,
      fetchingEntity,
      errors,
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  };
}
