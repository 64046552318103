import React, { FunctionComponent, useCallback, useState } from 'react';
import { ProjectModel } from 'models/project';
import flowPlannerApi from 'api/flow-planner';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import { validateEntity } from 'helpers/form-validations';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import { validationRules } from './validations';

interface Props {
  visible: boolean;
  submit: (flowPlanner) => void;
  goBack: () => void;
}

export function mapProjects(projects: ProjectModel[]) {
  return mapGenericDropdownOptions(projects, {
    fields: {
      label: 'projectName',
      value: 'id',
    },
    copyFullItem: true
  });
}

const LoadFlowPlannerForm: FunctionComponent<Props> = (props) => {
  const { visible, goBack, submit } = props;
  const [formData, setFormData] = useState({ project: undefined });
  const [errors, setErrors] = useState({});
  const handleChange = useCallback((newState) => setFormData(newState), []);
  const handleErrors = useCallback((newState) => setErrors(newState), []);
  const handleSubmit = useCallback(
    () => {
      const { hasErrors, errors: validatedErrors } = validateEntity(formData, validationRules);
      if (hasErrors) {
        setErrors(validatedErrors);
      } else {
        submit(formData?.project?.dropdownItem);
        goBack();
      }
    },
    [formData, submit, goBack]
  );

  return (
    <Modal
      title="Load Design"
      visible={visible}
      onCancel={goBack}
      okText="Submit"
      onOk={handleSubmit}
    >
      <Form
        state={formData}
        onChange={handleChange}
        rules={validationRules}
        errors={errors}
        onError={handleErrors}
      >
        <Field
          name="project"
          label="Project"
          component={AsyncSelectV2}
          listAction={flowPlannerApi.list}
          mapFunction={mapProjects}
        />
      </Form>
    </Modal>
  );
};

export default LoadFlowPlannerForm;
