import { TaskModel } from 'models/task';
import { formatDateToString } from 'helpers/formatting';
import { CalendarActions, Schedule } from '../reducer';

export default function generateSchedule(dispatch, tasks: TaskModel[]) {
  const fullSchedule = tasks.reduce((schedule, task) => {
    const taskDate = formatDateToString(task.dueDate);
    if (!schedule[taskDate]) {
      return {
        ...schedule,
        [taskDate]: [task],
      };
    }

    return {
      ...schedule,
      [taskDate]: schedule[taskDate].concat([task]),
    };
  }, {} as Schedule);

  dispatch({ type: CalendarActions.SET_SCHEDULE, payload: fullSchedule });
}
