import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ShipmentContactModel } from 'models/shipment';
import { AccessMethodModel } from 'models/access-methods';
import { CountryModel } from 'models/country';
import { ProvinceModel } from 'models/province';
import { AccountModel } from 'models/account';
import { UserModel } from 'models/user';
import accountApi from 'api/account';
import userApi from 'api/user';
import accessMethodsApi from 'api/access-method';
import countriesApi from 'api/country';
import provincesApi from 'api/province';
import questionnaireApi, { ValidQuestionnaireResponse } from 'api/questionnaire';
import useGetList from 'hooks/base/get-list';
import useCallApiAction from 'hooks/base/call-api-action';
import useGetOne from 'hooks/base/get-one';
import {
  GenericMapOptions,
  mapDropdownOptions,
  mapGenericDropdownOptions,
} from 'helpers/dropdown-options';
import { initialAnswers } from '../types';
import setContactInfo from './actions/set-contact-info';
import setAddressInfo from './actions/set-address-info';
import setAnswers from './actions/set-answers';
import handleTermsChange from './actions/handle-terms-change';
import filterProvinces from './actions/filter-provinces';
import handleCountryChange from './actions/handle-country-change';
import initFormData from './actions/init-form';
import setErrors from './actions/set-errors';
import handleSend from './actions/handle-send';
import questionnaireReducer, {
  FormAddressModel,
  QuestionnaireActions,
  QuestionnaireState,
} from './reducer';
import copyAccountAddress from './actions/copy-account-address';
import copyContactInfo from './actions/copy-contact-info';

interface DQQueryParams {
  cli: string;
  notify: boolean;
  job: string;
  account: string;
  contact: string;
}

const initialState: QuestionnaireState = {
  contactInfo: {} as ShipmentContactModel,
  contactErrors: {},
  addressInfo: {} as FormAddressModel,
  addressErrors: {},
  answers: initialAnswers,
  answersErrors: {},
  selectedCountry: undefined,
  termsAccepted: false,
  loadingFormData: true,
  sendingAnswers: false,
  answersSent: false,
  editedContactInfo: false,
  editedAddressInfo: false,
  hasValidationErrors: false,
};

const countryMapOptions: GenericMapOptions = {
  fields: { value: 'id', label: 'country' },
};

const { REACT_APP_ACCOUNTS_API } = process.env;
const accountUrl = `${REACT_APP_ACCOUNTS_API}/accounts`;

export default function useQuestionnaireState() {
  const [state, dispatch] = useReducer(questionnaireReducer, initialState);
  const location = useLocation();

  const {
    cli: shipmentHash,
    notify: notifyParent,
  } = useMemo<DQQueryParams>(
    () => queryString.parse(location.search) as any,
    [location],
  );

  const {
    fetching: fetchingShipment,
    data: shipmentValidation,
    error: shipmentError,
  } = useCallApiAction<ValidQuestionnaireResponse>(
    questionnaireApi.validate,
    shipmentHash,
    {} as ValidQuestionnaireResponse,
  );

  const {
    fetching: fetchingAccount,
    data: account,
    error: accountError,
  } = useGetOne<AccountModel>(
    accountApi,
    `${accountUrl}/${encodeURI(shipmentValidation?.account)}`,
    { preventAutoFetch: !shipmentValidation || !shipmentValidation.account }
  );

  const {
    fetching: fetchingContact,
    data: contact,
    error: contactError,
  } = useGetOne<UserModel>(
    userApi.getById,
    shipmentValidation?.contact,
    { preventAutoFetch: !shipmentValidation || !shipmentValidation?.contact }
  );

  const {
    fetching: fetchingAccessMethod,
    data: accessMethods,
    error: accessMethodError,
  } = useGetList<AccessMethodModel>(accessMethodsApi);

  const {
    fetching: fetchingCountries,
    data: countries,
    error: countryError,
  } = useGetList<CountryModel>(countriesApi);

  const {
    fetching: fetchingProvinces,
    data: provinces,
    error: provinceError,
  } = useGetList<ProvinceModel>(provincesApi);

  useEffect(
    () => {
      const finishedFetching = (
        !fetchingAccessMethod &&
        !fetchingShipment &&
        !fetchingCountries &&
        !fetchingProvinces
      );
      const hasLoadedWithSuccess = (
        finishedFetching &&
        shipmentValidation &&
        shipmentValidation.shipment
      );

      if (hasLoadedWithSuccess) {
        initFormData(dispatch, shipmentValidation.shipment);
      }
    },
    [
      fetchingAccessMethod,
      fetchingShipment,
      fetchingCountries,
      fetchingProvinces,
      accessMethods,
      shipmentValidation,
    ],
  );

  return {
    state: {
      ...state,
      fetchingAccount,
      disableAccount: !account,
      fetchingContact,
      disableContact: !contact,
      job: shipmentValidation?.job,
      accessMethods,
      countries: useMemo(() => (
        mapGenericDropdownOptions(countries, countryMapOptions)
      ), [countries]),
      provinces: useMemo(() => (
        mapDropdownOptions(filterProvinces(provinces, state.selectedCountry))
      ), [provinces, state.selectedCountry]),
      error: (
        accountError ||
        contactError ||
        shipmentError ||
        accessMethodError ||
        countryError ||
        provinceError
      ),
    },
    actions: {
      copyContactInfo: useCallback(
        copyContactInfo(dispatch, contact),
        [contact],
      ),
      copyAccountAddress: useCallback(
        copyAccountAddress(dispatch, account),
        [account],
      ),
      setContactInfo: useCallback(setContactInfo(dispatch), []),
      setAddressInfo: useCallback(setAddressInfo(dispatch), []),
      setAnswers: useCallback(setAnswers(dispatch), []),
      handleCountryChange: useCallback(handleCountryChange(dispatch), []),
      handleTermsChange: useCallback(handleTermsChange(dispatch), []),
      handleSendAnswers: handleSend(dispatch, state, shipmentValidation, notifyParent),
      setContactErrors: useCallback(
        setErrors(dispatch, QuestionnaireActions.SET_CONTACT_ERRORS),
        [],
      ),
      setAddressErrors: useCallback(
        setErrors(dispatch, QuestionnaireActions.SET_ADDRESS_ERRORS),
        [],
      ),
    },
  };
}
