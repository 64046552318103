import React, { FunctionComponent } from 'react';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import manufacturerApi from 'api/manufacturer';
import { ManufacturerModel } from 'models/manufacturer';
import Help from 'components/forms/CommonHelp';
import { EventChannelList } from 'helpers/event-center';
import { useHistory } from 'react-router-dom';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import Button from 'components/base-components/Button';
import { ManufacturerFormHistorySateModel } from '../Form';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: manufacturerApi,
  deleteModalTitle: 'Delete Manufacturer',
  deleteModalContent: (manufacturerToDelete: ManufacturerModel) => `Are you sure that you want to delete the manufacturer ${manufacturerToDelete ? manufacturerToDelete.companyName : ''}?`,
  searchFields: ['company_name'],
  eventChannel: EventChannelList.MANUFACTURERS_LIST_CHANGED,
};

const ManufacturersList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<ManufacturerModel>(useTableStateParams);

  const openManufacturerFormModal = (manufacturer?: ManufacturerModel | any) => {
    const historyLocationState: ManufacturerFormHistorySateModel = {
      manufacturerForm: {
        manufacturerUrl: manufacturer ? manufacturer.url : undefined,
      },
    };
    push(ModalRouteHash.ManufacturerForm, historyLocationState);
  };

  return (
    <Page title="Manufacturers">
      <Help view="manufacturer" title="Manufacturers List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addManufacturer}>
          <Button
            onClick={openManufacturerFormModal}
            label="Add Manufacturer"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn
          dataIndex="companyName"
          columnKey="company_name"
          title="Manufacturer Name"
          sorter
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editManufacturer}
          deletePermissions={permissions.deleteManufacturer}
          onEdit={openManufacturerFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default ManufacturersList;
