import isEdgeRow from './is-edge-row';

export default function groupTemplate(row) {
  const { type, accountGroup } = row;

  if (isEdgeRow(type)) {
    return {
      children: null,
      props: { colSpan: 0 },
    };
  }

  return accountGroup || '-';
}
