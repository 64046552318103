import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import categoriesApi from 'api/category';
import { mapTreeSelectOptionSimple } from 'helpers/dropdown-options';
import { AddProductFormActions } from '../reducer';

export function fetchCategories(dispatch) {
  return async (treeNode) => {
    try {
      const { id, label } = treeNode.props;
      const params = {
        filters: {
          parent: label ? label.split(' ')[0] : undefined,
        }
      };
      const { data: { results } } = await categoriesApi.list(params);
      const mappedCategories = results.map((cat) => ({
        ...cat,
        name: (
          cat.name !== cat.description
            ? `${cat.name} - ${cat.description}`
            : cat.name
        )
      }));

      const categoryTreeData = mapTreeSelectOptionSimple(
        mappedCategories,
        id,
        'categories',
      );
      dispatch({
        type: AddProductFormActions.SET_TREE_DATA,
        payload: categoryTreeData,
      });
    } catch (error) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    }
  };
}
