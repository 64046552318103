import React, { FunctionComponent, useMemo } from 'react';
import { Moment } from 'moment';
import Badge, { badgeColors } from 'components/base-components/Badge';
import genRandomNumber from './utils/generate-random-number';
import { useSchedule } from './ScheduleProvider';
import { formatDateToString } from '../../../helpers/formatting';

interface Props {
  date: Moment;
  openForm: (task?) => void;
}

const Cell: FunctionComponent<Props> = (props) => {
  const { date, openForm } = props;
  const schedule = useSchedule();

  const tasksElements = useMemo(() => {
    const tasks = schedule[formatDateToString(date)] || [];

    return tasks.map(task => {
      const openTask = () => {
        openForm(task);
      };

      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        <li key={task.id} role="button" onClick={openTask}>
          <Badge color={badgeColors[genRandomNumber(0, 11)]} text={task.title} />
        </li>
      );
    });
  }, [schedule, date, openForm]);

  return (
    <ul className="calendar_cell__tasks">
      {tasksElements}
    </ul>
  );
};

export default Cell;
