import { RuleType, commonRules } from 'helpers/form-validations';

const min = {
  type: RuleType.Min,
  message: 'This field must be greater than 0',
  value: 0,
};

export const flatRateRules = {
  price: [commonRules.required, min],
};
