import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { ThreeModel } from 'models/three-model';
import threeModelApi from 'api/three-model';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import Button from 'components/base-components/Button';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import Help from 'components/forms/CommonHelp';
import modelNameTemplate from './templates/model-name-template';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: threeModelApi,
  queryParams: {
    filters: {
      ordering: '-id',
    },
  },
  deleteModalTitle: 'Delete 3D Model',
  deleteModalContent: (model: ThreeModel) => (
    `
    Are you sure that you want to delete the 3D model
    ${model ? `${model.modelName} (${modelNameTemplate(model.threeModel)})` : ''}?
    `
  ),
  searchFields: [],
  eventChannel: EventChannelList.THREE_MODEL_LIST_CHANGED,
};

const ThreeModelsList: FunctionComponent = () => {
  const { push } = useHistory();

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter
    }
  } = useTableState<ThreeModel>(useTableStateParams);

  const openFormModal = (model: ThreeModel | any) => {
    const navigationState = {
      threeModelForm: {
        model: !!model.threeModel ? model : undefined,
      },
    };
    push(ModalRouteHash.ThreeModelForm, navigationState);
  };

  return (
    <Page title="3D Models">
      <Help view="three-models" title="3D Models List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addThreeModel}>
          <Button
            onClick={openFormModal}
            label="Add 3D model"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn
          title="Name"
          dataIndex="modelName"
          columnKey="model_name"
          sorter
        />
        <DataTableColumn
          title="File"
          dataIndex="threeModel"
          columnKey="model_name"
          render={modelNameTemplate}
          sorter
        />
        <DataTableColumn
          dataIndex={['modelPosition', 'description']}
          columnKey="model_position_description"
          title="Position"
        />
        <DataTableColumn
          dataIndex="threeModel"
          columnKey="model_url"
          title="Url"
          showBreakpoint={1455}
        />
        <DataTableColumn
          isActionColumn
          onEdit={openFormModal}
          onDelete={handleDeleteRow}
          editPermissions={permissions.editThreeModel}
          deletePermissions={permissions.deleteThreeModel}
        />
      </DataTable>
    </Page>
  );
};

export default ThreeModelsList;
