import { resolveApiErrorMessage } from 'api/base';
import orderProductApi from 'api/order-product';
import productApi from 'api/product';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { RuleType, validateEntity } from 'helpers/form-validations';
import { BuilderFormModel } from 'components/pages/Builder/state';
import getPayload, { getProductPayload } from './get-payload';
import { AddProduct3DFormActions } from '../reducer';

export default function addProduct(
  dispatch,
  product: BuilderFormModel,
  discount: number,
  quote: number,
  staticForm,
  priceData,
  goBack
) {
  return () => {
    const validationRules = Object.keys(product.builderOptions).reduce((rules, key) => {
      const option = product.builderOptions[key];
      if (
        option.propertyType === 'range' &&
        option.minValue !== option.maxValue
      ) {
        return {
          ...rules,
          [key]: [
            { type: RuleType.Min, value: option.minValue, message: `Must be at least ${option.minValue}` },
            { type: RuleType.Max, value: option.maxValue, message: `Must be not more then ${option.maxValue}` },
          ]
        };
      }
      return rules;
    }, {});
    const { hasErrors, errors } = validateEntity(product.modelDescription, validationRules);

    if (hasErrors) {
      return dispatch({
        type: AddProduct3DFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }
    dispatch({ type: AddProduct3DFormActions.START_ADDING });
    const payload = getPayload(product, quote, priceData, staticForm);

    const onSuccess = () => {
      notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED, true);
      const message = 'Products Successfully added';
      notification({ type: NotificationType.SUCCESS, message });
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: AddProduct3DFormActions.FINISH_ADDING });
    };

    return productApi.getByModel(payload.model)
      .then((response) => {
        const productPayload = getProductPayload(response.data, discount, payload);
        return orderProductApi.create(productPayload);
      })
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
