import { Rule, RuleType } from 'helpers/form-validations';

const requiredRule: Rule = { type: RuleType.Required, message: 'This field is required' };

const validationsRules = {
  partName: [requiredRule],
  purchaseOrder: [requiredRule],
  price: [requiredRule],
  quantity: [requiredRule],
};

export default validationsRules;
