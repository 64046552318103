import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountModel } from 'models/account';
import accountApi from 'api/account';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';
import {
  defaultFullAccount,
  defaultQuickAccount,
  getFormData,
  getInitialState,
} from './actions/get-form-data';
import setFormValue from './actions/set-form-value';
import setErrors from './actions/set-errors';
import saveAccount from './actions/save-account';
import accountFormState, { AccountFormActions } from './reducer';

export * from './actions/map-functions';

export default function useAccountFormState() {
  const { location, goBack } = useHistory();
  const [state, dispatch] = useReducer(accountFormState, location, getInitialState);

  const accountUrl = useMemo(
    () => (location.state as any)?.accountForm?.url,
    [location],
  );

  const isEditing = useMemo(
    () => !!(location.state as any)?.accountForm?.url,
    [location],
  );

  const {
    fetching: fetchingAccount,
    data: account,
    error: accountError,
  } = useGetOne<AccountModel>(accountApi, accountUrl);

  useEffect(() => {
    const salesRep = (location.state as any)?.accountForm?.salesRep;
    const emptyAccount = state.showFullForm
      ? defaultFullAccount
      : { ...defaultQuickAccount, salesRep };

    dispatch({
      type: AccountFormActions.RESET_STATE,
      payload: emptyAccount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showFullForm]);

  useEffect(() => {
    if (accountUrl && !fetchingAccount && account) {
      dispatch({
        type: AccountFormActions.SET_FORM_VALUE,
        payload: getFormData(account),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (accountError) {
      notification({
        type: NotificationType.ERROR,
        message: accountError,
      });
    }
  }, [accountError]);

  return {
    state: {
      ...state,
      fetchingFormData: fetchingAccount,
      isEditing
    },
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      saveAccount: useCallback(
        saveAccount(dispatch, state.account, state.showFullForm, isEditing, goBack),
        [state.account, state.showFullForm, isEditing],
      ),
    },
  };
}
