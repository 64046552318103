import { AxiosResponse } from 'axios';
import { ApiContentType, ApiModel, get, ListResponseModel } from 'api/base';
import getApi from 'api/base/get-api';
import { ActiveDeficiencyItemModel, DeficiencyItemModel } from 'models/order';
import { QueryParams } from 'helpers/query-params';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/deficiencyitems/`;

interface DeficiencyItemApiModel extends ApiModel<DeficiencyItemModel> {
  getActiveDeficiencies: (params?: QueryParams) =>
  Promise<AxiosResponse<ListResponseModel<ActiveDeficiencyItemModel>>>;
  getDeficienciesByProject: (params?: any) => Promise<AxiosResponse<ListResponseModel<DeficiencyItemModel>>>;
}

const deficiencyItemApi: DeficiencyItemApiModel = {
  ...getApi<DeficiencyItemModel>(endpoint, ApiContentType.MULTIPART, true),
  getActiveDeficiencies(params) {
    return get(`${endpoint}active_deficiencies/`, params);
  },
  getDeficienciesByProject({ queryId, ...params }) {
    return get(`${endpoint}project/${queryId}/`, params);
  }
};

export default deficiencyItemApi;
