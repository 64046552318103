export enum ViewsPermissionsListActions {
  SET_SELECTED_ROWS = 'SET_SELECTED_ROWS',
  START_UPDATING = 'START_UPDATING',
  FINISH_UPDATING = 'FINISH_CALCULATING_BULK',
  ADD_UPDATING_VIEW = 'ADD_UPDATING_VIEW',
  REMOVE_UPDATING_VIEW = 'REMOVE_UPDATING_VIEW',
}

interface CNCListState {
  selectedRows: string[];
  updating: boolean;
  viewsInProcess: string[];
}

export default function viewPermissionsListReducer(state: CNCListState, action): CNCListState {
  const { type, payload } = action;

  switch (type as ViewsPermissionsListActions) {
    case ViewsPermissionsListActions.SET_SELECTED_ROWS:
      return { ...state, selectedRows: payload };
    case ViewsPermissionsListActions.START_UPDATING:
      return { ...state, updating: true };
    case ViewsPermissionsListActions.FINISH_UPDATING:
      return { ...state, updating: false };
    case ViewsPermissionsListActions.ADD_UPDATING_VIEW:
      return { ...state, viewsInProcess: state.viewsInProcess.concat([payload]) };
    case ViewsPermissionsListActions.REMOVE_UPDATING_VIEW:
      return {
        ...state,
        viewsInProcess: state.viewsInProcess.filter(view => view !== payload),
      };
    default:
      return state;
  }
}
