import { DropdownOptionModel } from 'helpers/dropdown-options';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { CreateProjectHistorySateModel } from '../../Form/CreateProject/state';
import { AccountDropdownModel } from '../reducer';

export default function openCreateProjectModal(
  push,
  contact: DropdownOptionModel,
  account: AccountDropdownModel,
) {
  return () => {
    const historyLocationState: CreateProjectHistorySateModel = {
      createProjectForm: {
        client: contact.value,
        account: account?.businessTradeName,
      },
    };
    push(ModalRouteHash.CreateProject, historyLocationState);
  };
}
