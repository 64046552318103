import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { ProductModel } from 'models/product';
import productApi from 'api/product';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Form from 'components/base-components/Form';
import Modal from 'components/base-components/ModalV2';
import Field from 'components/base-components/Form/Field';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import NumberInput from 'components/base-components/NumberInput';
import AsyncAutoComplete from 'components/base-components/AsyncAutoComplete';
import RenderIf from 'components/base-components/RenderIf';
import useManualCalcFormState, { validationRules } from './state';
import './styles.scss';

function mapProducts(products: ProductModel[]) {
  return mapGenericDropdownOptions(
    products,
    { fields: { label: 'model', value: 'url' } },
  );
}

const CNCManualCalculatorForm: FunctionComponent = () => {
  const { goBack } = useHistory();
  const {
    state: {
      product,
      productList,
      errors,
      calculating,
    },
    actions: {
      setProduct,
      addProduct,
      deleteProduct,
      setErrors,
      calculate,
    }
  } = useManualCalcFormState();

  return (
    <Modal
      visible
      title="Manually calculate by Model"
      footer={null}
      onCancel={goBack}
    >
      <Form
        state={product}
        onChange={setProduct}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <Field
          component={AsyncAutoComplete}
          name="model"
          id="model"
          label="Model"
          listAction={productApi.list}
          mapFunction={mapProducts}
          allowIncompleteValue
        />
        <Field
          name="quantity"
          id="quantity"
          label="Quantity"
          component={NumberInput}
        />
        <div className="cnc-calc__actions">
          <Button label="Add" onClick={addProduct} id="addProduct" />
        </div>
      </Form>
      <RenderIf isTrue={productList.length > 0}>
        <DataTable dataSource={productList} pagination={false}>
          <DataTableColumn key="model" dataIndex={['model', 'label']} title="Model" />
          <DataTableColumn key="quantity" dataIndex="quantity" title="Quantity" />
          <DataTableColumn isActionColumn onDelete={deleteProduct} />
        </DataTable>
      </RenderIf>
      <div className="cnc-calc__actions">
        <Button
          label="Cancel"
          onClick={goBack}
          disabled={calculating}
          type="default"
          mR
        />
        <Button
          label="Calculate"
          onClick={calculate}
          loading={calculating}
          disabled={productList.length === 0}
        />
      </div>
    </Modal>
  );
};

export default CNCManualCalculatorForm;
