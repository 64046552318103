import {
  AccountApplicationBusinessType,
  AccountApplicationStatus
} from 'models/account-application';
import { AccountApplicationFormModel } from '..';

const defaultAccountApplication: AccountApplicationFormModel = {
  businessTradeName: '',
  status: AccountApplicationStatus.pending,
  ownerFirstName: '',
  ownerLastName: '',
  ownerEmail: '',
  businessLegalName: '',
  website: '',
  businessType: AccountApplicationBusinessType.Proprietorship,
  businessPhone: '',
  businessAddress: '',
  businessCity: '',
  businessStateProvince: '',
  businessPostCode: '',
  businessEmail: '',
  businessNumber: '',
  irsGstNumber: '',
  yearsInBusiness: '0',
  creditAmount: '0',
  apName: '',
  apEmail: '',
  taxExempt: null,
  bankName: '',
  accountNumber: '',
  bankAddress: '',
  bankCity: '',
  bankStateProvince: '',
  bankPostCode: '',
  bankPhone: '',
  ref1BusinessName: '',
  ref1ContactEmail: '',
  ref1ContactPhone: '',
  ref2BusinessName: '',
  ref2ContactEmail: '',
  ref2ContactPhone: '',
  ref3BusinessName: '',
  ref3ContactEmail: '',
  ref3ContactPhone: '',
};

export default function getFormData(): AccountApplicationFormModel {
  return defaultAccountApplication;
}
