/* eslint-disable */
import React, { FunctionComponent, useMemo } from 'react';
import { floorplannerModes } from './app/floorplanner/floorplanner_view';

interface Props {
  hidden: boolean;
  onDomLoaded: (ref: any) => void;
  onModeChanged: (mode: number) => void;
}

const FloorPlanViewer: FunctionComponent<Props> = (props) => {
  const { hidden, onDomLoaded } = props;

  const styles = useMemo(() => ({ display: hidden ? 'none' : 'inherit', width: '100%', height: '100%' }), [hidden]);

  return (
    <div style={styles}>
      <canvas
        className="canvas-floor-plan"
        ref={ref => onDomLoaded(ref)}
      >
        Floor plan viewer
      </canvas>
      <div className="floorplan-modes-container">
        <span onClick={() => props.onModeChanged(floorplannerModes.MOVE)}>Move</span>
        <span onClick={() => props.onModeChanged(floorplannerModes.DRAW)}>Draw</span>
        <span onClick={() => props.onModeChanged(floorplannerModes.DELETE)}>Delete</span>
      </div>
    </div>
  );
};

export default FloorPlanViewer;
