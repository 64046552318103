import { ApiContentType, ApiModel, post } from 'api/base';
import getApi from 'api/base/get-api';
import { OptionModel } from 'models/option';
import { AxiosPromise, AxiosResponse } from 'axios';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/option/`;

interface OptionApiModel extends ApiModel<OptionModel> {
  getCustomOptionPrice: (params?: any) => AxiosPromise<AxiosResponse>;
}

const optionApi: OptionApiModel = {
  ...getApi<OptionModel>(endpoint, ApiContentType.MULTIPART),
  getCustomOptionPrice(payload) {
    return post(`${endpoint}custom-price/`, payload);
  }
};

export default optionApi;
