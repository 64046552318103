import { ExtraChargeModel } from 'models/extra-charge';
import { QuoteProductsDataModel } from '.';

export enum QuoteProductListActions {
  SET_DATA = 'SET_DATA',
  SET_EXTRA_CHARGES = 'SET_EXTRA_CHARGES',
  SET_DELETING_EXTRA_CHARGE_INDEX = 'SET_DELETING_EXTRA_CHARGE_INDEX',
}

export interface QuoteProductListState {
  quoteProducts: QuoteProductsDataModel[];
  expandedRowKeys: string[];
  orderTotal: number;
  extraCharges: ExtraChargeModel[];
  deletingExtraChargeIndex: number;
}

export default function quoteProductListReducer(state: QuoteProductListState, action): QuoteProductListState {
  const { type, payload } = action;

  switch (type as QuoteProductListActions) {
    case QuoteProductListActions.SET_DATA:
      return { ...state, ...payload };
    case QuoteProductListActions.SET_EXTRA_CHARGES:
      return { ...state, extraCharges: payload, deletingExtraChargeIndex: undefined };
    case QuoteProductListActions.SET_DELETING_EXTRA_CHARGE_INDEX:
      return { ...state, deletingExtraChargeIndex: payload };
    default:
      return state;
  }
}
