import { AxiosResponse } from 'axios';
import { ShipmentModel } from 'models/shipment';
import { ApiContentType, ApiModel, get, post } from 'api/base';
import getApi from 'api/base/get-api';
import { getEmailPayload } from 'api/base/get-email-payload';
import downloadBlob from './base/download-blob';

const { REACT_APP_SHIPMENT_API } = process.env;
const endpoint = `${REACT_APP_SHIPMENT_API}/shipment/`;

interface ShipmentApiModel extends ApiModel {
  getShipmentHash: (id: number) => Promise<AxiosResponse<string>>;
  shippingPrice: (id: number, orderTotal: number) => Promise<AxiosResponse<string>>;
  emailQuestionnaire: (id: number, recipients: string[]) => Promise<AxiosResponse>;
  printQuestionnaire: (id: number) => Promise<void | AxiosResponse>;
}

const shipmentApi: ShipmentApiModel = {
  ...getApi<ShipmentModel>(endpoint, ApiContentType.JSON, true),
  getShipmentHash(id: number) {
    return get(`${endpoint}${id}/hash`);
  },
  shippingPrice(id, orderTotal) {
    return post(`${endpoint}${id}/price/`, { orderTotal }, ApiContentType.MULTIPART);
  },
  emailQuestionnaire(id: number, recipients: string[]) {
    const payload = getEmailPayload(recipients);
    return post(`${endpoint}${id}/email_questionnaire/`, payload);
  },
  printQuestionnaire(id: number) {
    return downloadBlob(
      `${endpoint}${id}/questionnaire_pdf/`,
      `Questionnaire Shipment - ${id}.pdf`
    );
  }
};

export default shipmentApi;
