import React from 'react';

export default function modelTemplate(row) {
  if (typeof row.id === 'string') {
    const isSubtotal = row.id.includes('subtotal');
    let colSpan = 0;

    if (row.group || row.subtotal) {
      colSpan = isSubtotal ? 2 : 3;
    }

    return {
      children: isSubtotal ? <b>Sub-Total</b> : row.group,
      props: {
        colSpan,
        align: isSubtotal ? 'right' : undefined,
        className: isSubtotal ? 'quote-product-list__subtotal' : null,
      },
    };
  }

  const options = row.options.map((option) => (
    <li key={`${option.optionType}-${row.id}`}>
      {`${option.optionType}: ${option.optionSelected}`}
    </li>
  ));

  return (
    <>
      <span className="text-muted">{row.name}</span>
      <ul>
        {options}
      </ul>
    </>
  );
}
