const commonTextures = [
  {
    label: 'Oak',
    texture: 'Blueprint3D-assets/textures/163 Aged Oak.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'Mahogany',
    texture: 'Blueprint3D-assets/textures/Mahogany-w365.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'W155',
    texture: 'Blueprint3D-assets/textures/W155.jpg',
    width: 35,
    height: 35,
  },
];

const handleStyles = [
  { label: 'Bar 5" Pull Nickel', name_in_model: 'handle-5"-bar-pull-nickel' },
  { label: 'Loop 5" Black', name_in_model: 'handle-5"-loop-black' },
  { label: 'Loop 5" Nickel', name_in_model: 'handle-5"-loop-nickel' },
  { label: 'Square 5" Nickel', name_in_model: 'handle-5"-square-nickel' },
  {
    label: 'Strait 5" Pull Black',
    name_in_model: 'handle-5"-strait-pull-black',
  },
  {
    label: 'Strait 5" Pull Silver',
    name_in_model: 'handle-5"-strait-pull-silver',
  },
  { label: 'Waterfall 5" Black', name_in_model: 'handle-5"-waterfall-black' },
];

const data = {
  category: 'PED-MP',
  styles: [
    {
      name: 'PED-MP-BBBBL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-bbbbl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-BBBBL.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-BBBBLT',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-bbbblt.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-BBBBLT.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-BBFL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-bbfl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-BBFL.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-BBFLT',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-bbflt.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-BBFLT.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-BBL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-bbl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-BBL.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-BBLT',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-bblt.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-BBLT.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-BBOL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-bbol.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-BBOL.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-BBOLT',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-bbolt.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-BBOLT.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-FBBL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-fbbl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-FBBL.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-FBBLT',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-fbblt.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-FBBLT.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-LL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-ll.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-LL.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-LLT',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-llt.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-LLT.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-OBBL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-obbl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-OBBL.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
    {
      name: 'PED-MP-OBBLT',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/ped-mp-obblt.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/PED-MP-OBBLT.glb',
      type: '1',
      overlappable: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 20, max: 48 },
      ],
      materials: [
        {
          label: 'Cabinet Top',
          name_in_model: 'cabinet-top',
          types: commonTextures,
        },
        {
          label: 'Cabinet Body',
          name_in_model: 'cabinet-body',
          types: commonTextures,
        },
        {
          label: 'Cabinet Face',
          name_in_model: 'cabinet-face',
          types: commonTextures,
        },
      ],
      styles: [
        { label: 'Handle Style', name_in_model: 'handle', types: handleStyles },
      ],
    },
  ],
};

export default data;
