import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { AddProduct3DHistorySateModel } from 'components/forms/AddProduct3D';
import { AccountDropdownModel } from '../reducer';

export default function openAddProduct3DModal(
  push,
  quote: QuoteModel,
  account: AccountDropdownModel,
  groups: string[],
) {
  return () => {
    const historyLocationState: AddProduct3DHistorySateModel = {
      addProduct3DForm: {
        groups,
        quote: quote.id,
        discount: account.groupDetail?.discount
          ? parseFloat(account.groupDetail.discount)
          : 0,
      },
    };
    push(ModalRouteHash.AddProduct3D, historyLocationState);
  };
}
