import { DropdownOptionModel } from 'helpers/dropdown-options';
import { initialTask } from '.';

export enum TaskFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_TASK_DONE = 'SET_TASK_DONE',
  SET_DATA = 'SET_DATA',
  SET_STATE = 'SET_STATE',
  SET_ONETIME_FORM = 'SET_ONETIME_FORM',
  UPDATE_DATA = 'UPDATE_DATA',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_SAVING = 'START_SAVING',
  FINISH_SAVING = 'FINISH_SAVING',
  SAVE_ONETIME_TASK = 'SAVE_ONETIME_TASK'
}

export interface TaskFormModel {
  priority: any;
  details: string;
  startingStage: DropdownOptionModel;
  endingStage: DropdownOptionModel;
}

interface NotesFormState {
  formData: TaskFormModel;
  taskDone: number[];
  task: any[];
  saving: boolean;
  errors: any;
  onetimeForm: boolean;
}

export default function notesFormReducer(
  state: NotesFormState,
  action
): NotesFormState {
  const { type, payload } = action;

  switch (type as TaskFormActions) {
    case TaskFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case TaskFormActions.SET_ONETIME_FORM:
      return { ...state, onetimeForm: !state.onetimeForm };
    case TaskFormActions.SET_TASK_DONE:
      return { ...state, taskDone: payload };
    case TaskFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, saving: false };
    case TaskFormActions.SET_DATA:
      return { ...state, task: payload };
    case TaskFormActions.SET_STATE:
      return { ...state, task: payload.task, taskDone: payload.taskDone };
    case TaskFormActions.SAVE_ONETIME_TASK:
      return { ...state, task: payload, formData: initialTask, onetimeForm: false };
    case TaskFormActions.UPDATE_DATA:
      return { ...state, task: state.task.concat(payload) };
    case TaskFormActions.START_SAVING:
      return { ...state, saving: true };
    case TaskFormActions.FINISH_SAVING:
      return { ...state, saving: false };
    default:
      return state;
  }
}
