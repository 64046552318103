import { Moment } from 'moment';
import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum TaskFormActions {
  INIT_FORM = 'INIT_FORM',
  SET_STATE = 'SET_STATE',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_ERRORS = 'SET_ERRORS',
  START_SAVING = 'START_SAVING',
  FINISH_SAVING = 'FINISH_SAVING',
}

export interface TaskFormModel {
  id: number;
  snsTopic: string;
  title: string;
  project: DropdownOptionModel;
  dueDate: Moment;
  description: string;
  notifyAssigner: boolean;
  updateSubs?: boolean;
  notifyBySms: boolean;
  notifyByEmail: boolean;
  status: DropdownOptionModel;
  assigner: DropdownOptionModel;
  assignee: DropdownOptionModel;
  verbosity: DropdownOptionModel;
}

export interface TaskFormState {
  task: TaskFormModel;
  fetching: boolean;
  errors: any;
  savingTask: boolean;
}

export default function taskFormReducer(state: TaskFormState, action): TaskFormState {
  const { type, payload } = action;

  switch (type as TaskFormActions) {
    case TaskFormActions.SET_STATE:
      return { ...payload };
    case TaskFormActions.INIT_FORM:
      return { ...state, task: payload, fetching: false, };
    case TaskFormActions.SET_FORM_VALUE:
      return { ...state, task: payload };
    case TaskFormActions.SET_ERRORS:
      return { ...state, errors: payload, savingTask: false };
    case TaskFormActions.START_SAVING:
      return { ...state, savingTask: true };
    case TaskFormActions.FINISH_SAVING:
      return { ...state, savingTask: false };
    default:
      return state;
  }
}
