import moment from 'moment';
import { formatDateTimeToString } from 'helpers/formatting';
import { CalendarEvent, EventItemType } from '../reducer';

export default function getPayload(event: CalendarEvent) {
  const { start, extendedProps: { type, item } } = event;

  switch (type) {
    case EventItemType.ORDER: {
      return {
        id: item.id,
        readyDate: formatDateTimeToString(moment(start)),
      };
    }
    case EventItemType.BOOKING: {
      return {
        id: item.id,
        installationDate: formatDateTimeToString(moment(start)),
      };
    }
    case EventItemType.CUSTOM: {
      return {
        id: item.id,
        scheduled: formatDateTimeToString(moment(start)),
      };
    }
    default:
      return {};
  }
}
