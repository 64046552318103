import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Row, Col, Slider as AntSlider } from 'antd';
import { SliderSingleProps } from 'antd/lib/slider';
import Label from '../Label';
import InputError from '../InputError';
import './styles.scss';
import RenderIf from '../RenderIf';
import NumberInput from '../NumberInput';

interface Props extends SliderSingleProps {
  id?: string;
  label: string;
  required?: boolean;
  error?: string;
  className?: string;
  mR?: boolean;
  input?: boolean;
  value: any;
  onChange: (value: any) => any;
}

const Slider: FunctionComponent<Props> = (props) => {
  const { id, label, required, error, className, mR, input, ...rest } = props;
  const sliderClassName = classnames('slider', { mR }, className);
  const colspan = input ? 17 : 24;

  return (
    <div className={sliderClassName}>
      <Label text={label} forInput={id} required={required} />
      <Row justify="space-between">
        <Col span={colspan}>
          <AntSlider
            id={id}
            {...rest}
          />
        </Col>
        <RenderIf isTrue={input}>
          <Col span={6}>
            <NumberInput
              notMB
              {...rest}
            />
          </Col>
        </RenderIf>
      </Row>
      <InputError error={error} />
    </div>
  );
};

export default Slider;
