import React, { useMemo } from 'react';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import { useHistory } from 'react-router-dom';
import permissionApi from 'api/permission';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import validationRules from './state/validations';
import useUserGroupFormState, { mapPermissionsOptions } from './state';

export interface UserGroupFormHistorySateModel {
  userGroupForm: {
    userGroupUrl: string;
  };
}

export default function UserGroupForm() {
  const { goBack, location: { state } } = useHistory();

  const { userGroupForm: { userGroupUrl } } = state as UserGroupFormHistorySateModel;

  const {
    state: {
      userGroup,
      fetchingFormData,
      savingUserGroup,
      errors
    },
    actions: {
      setFormValue,
      saveUserGroup,
      setErrors
    },
  } = useUserGroupFormState(userGroupUrl);

  const formModalTitle = useMemo(
    () => userGroupUrl ? 'Edit User Group' : 'Create User Group',
    [userGroupUrl],
  );

  return (
    <Modal
      title={formModalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveUserGroup}
      confirmLoading={savingUserGroup}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={userGroup}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="name"
            name="name"
            label="Name"
            placeholder="Enter User Group name"
            disabled={userGroup && userGroup.url}
          />
          <Field
            component={AsyncSelectV2}
            name="permissions"
            id="permissions"
            label="Permissions"
            mode="multiple"
            listAction={permissionApi.list}
            mapFunction={mapPermissionsOptions}
          />
        </Form>
      </RenderIf>
    </Modal>
  );
}
