import { CustomProductFormModel } from 'models/order-product';

export enum CustomProductFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_EXCEL = 'SET_EXCEL',
  SET_ROW_ERROR = 'SET_ROW_ERROR',
  START_ADDING = 'START_ADDING',
  START_READING = 'START_READING',
  START_GETTING_TEMPLATE = 'START_GETTING_TEMPLATE',
  FINISH_ADDING = 'FINISH_ADDING',
  FINISH_READING = 'FINISH_READING',
  FINISH_GETTING_TEMPLATE = 'FINISH_GETTING_TEMPLATE',
  SET_CUSTOM_OPTION = 'SET_CUSTOM_OPTION',
}

interface CustomProductFormState {
  data: CustomProductFormModel[];
  excel: any;
  adding: boolean;
  reading: boolean;
  getting: boolean;
  errors: any;
  rowError: number[];
}

export const initialOrder = {
  key: 0,
  model: '',
  mpn: '',
  category: 'custom',
  options: [],
  name: '',
  manufacturer: '',
  price: 0,
  quantity: 1,
  group: '',
  cnc: false,
  outsidePaint: false,
  disabled: false,
  productOptions: [],
  product: {},
};

export default function copyQuoteFormReducer(state: CustomProductFormState, action): CustomProductFormState {
  const { type, payload } = action;

  switch (type as CustomProductFormActions) {
    case CustomProductFormActions.SET_FORM_VALUE:
      return { ...state, data: payload };
    case CustomProductFormActions.SET_EXCEL:
      return { ...state, excel: payload };
    case CustomProductFormActions.SET_ROW_ERROR:
      return { ...state, rowError: payload };
    case CustomProductFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, adding: false };
    case CustomProductFormActions.START_ADDING:
      return { ...state, adding: true };
    case CustomProductFormActions.FINISH_ADDING:
      return { ...state, adding: false };
    case CustomProductFormActions.START_READING:
      return { ...state, reading: true };
    case CustomProductFormActions.FINISH_READING:
      return { ...state, reading: false };
    case CustomProductFormActions.START_GETTING_TEMPLATE:
      return { ...state, getting: true };
    case CustomProductFormActions.FINISH_GETTING_TEMPLATE:
      return { ...state, getting: false };
    case CustomProductFormActions.SET_CUSTOM_OPTION:
      return { ...state, data: payload };
    default:
      return state;
  }
}
