import { QuoteModel } from 'models/quote';
import { CreateOrderErrors } from 'api/orders';

export default function resolveProducts(quote: QuoteModel, missingFields: CreateOrderErrors) {
  if (missingFields?.productOptions?.missing) {
    return quote.orderproducts.filter(
      (product) => !!missingFields.productOptions.missing[product.id]
    );
  }

  return [];
}
