import { AccessMethodModel } from 'models/access-methods';
import { Moment } from 'moment';

export enum Answers {
  Yes = 1,
  No = -1,
}

export interface AnswersModel {
  id?: number;
  rushOrder: Answers;
  afterHours: Answers;
  areaPrepared: Answers;
  areaPreparedDetails: string;
  furnitureReconfigurationRequired: Answers;
  furnitureReconfigurationDetails: string;
  specificDeliveryHours: Answers;
  specificHoursStart: Moment;
  specificHoursEnd: Moment;
  streetOffload: Answers;
  parkingAvailable: Answers;
  accessCardsRequired: Answers;
  loadingDockAvailable: Answers;
  loadingDetails: string;
  loadingDockBookingRequired: Answers;
  loadingDockBookingRequiredDetails: string;
  accessMethod: AccessMethodModel;
  accessMethodDetails: string;
  elevatorReservationRequired: Answers;
  stairs: number;
}

export const initialAnswers: AnswersModel = {
  rushOrder: 0,
  afterHours: 0,
  areaPrepared: 0,
  areaPreparedDetails: '',
  furnitureReconfigurationRequired: 0,
  furnitureReconfigurationDetails: '',
  specificDeliveryHours: 0,
  specificHoursStart: undefined,
  specificHoursEnd: undefined,
  streetOffload: 0,
  parkingAvailable: 0,
  accessCardsRequired: 0,
  loadingDockAvailable: 0,
  loadingDetails: '',
  loadingDockBookingRequired: 0,
  loadingDockBookingRequiredDetails: '',
  accessMethod: {} as AccessMethodModel,
  accessMethodDetails: '',
  elevatorReservationRequired: 0,
  stairs: 0,
};
