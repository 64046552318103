import { FileViewerModel } from 'models/file-viewer';

export enum FileViewerFormActions {
  SET_DATA = 'SET_DATA',
  SET_PARAMS = 'SET_PARAMS',
  START_UPLOAD = 'START_UPLOAD',
  FINISH_UPLOAD = 'FINISH_UPLOAD',
  START_ADDING = 'START_ADDING',
  FINISH_ADDING = 'FINISH_ADDING',
  SET_ADD_FOLDER = 'SET_ADD_FOLDER',
}

interface EspecialInstructionsFormState {
  data: FileViewerModel;
  params: string;
  file: any;
  uploading: boolean;
  addFolder: boolean;
  addingFolder: boolean;
}

export default function EspecialInstructionsFormReducer(
  state: EspecialInstructionsFormState, action
): EspecialInstructionsFormState {
  const { type, payload } = action;

  switch (type as FileViewerFormActions) {
    case FileViewerFormActions.SET_DATA:
      return { ...state, data: payload };
    case FileViewerFormActions.SET_PARAMS:
      return { ...state, params: payload };
    case FileViewerFormActions.START_UPLOAD:
      return { ...state, uploading: true, file: payload };
    case FileViewerFormActions.FINISH_UPLOAD:
      return { ...state, uploading: false, file: null };
    case FileViewerFormActions.START_ADDING:
      return { ...state, addingFolder: true };
    case FileViewerFormActions.FINISH_ADDING:
      return { ...state, addingFolder: false };
    case FileViewerFormActions.SET_ADD_FOLDER:
      return { ...state, addFolder: !state.addFolder };
    default:
      return state;
  }
}
