import DESK from './DESK';
import PDESK from './PDESK';
import RTN from './RTN';
import BR from './BR';
import WS from './WS';
import DOOR from './DOOR';
import PED_OP from './PED-OP';
import PED_SP from './PED-SP';
import PED_MP from './PED-MP';
import HUTCH from './HUTCH';
import LST from './LST';
import BC from './BC';
import BSC from './BSC';
import SSC from './SSC';
import PST from './PST';
import LBC from './LBC';
import FILING from './FILING';
import WARD from './WARD';
import AS from './AS';
import LS from './LS';
import KIT from './KIT';
import ML from './ML';

const categories = [
  ML,
  AS,
  BC,
  BR,
  BSC,
  DESK,
  FILING,
  HUTCH,
  KIT,
  LBC,
  LS,
  LST,
  PDESK,
  PED_MP,
  PED_OP,
  PED_SP,
  PST,
  RTN,
  SSC,
  WARD,
  WS,
  DOOR,
];

export const CAT_DESK = DESK;
export const CAT_PDESK = PDESK;
export const CAT_RTN = RTN;
export const CAT_BR = BR;
export const CAT_WS = WS;
export const CAT_DOOR = DOOR;
export const CAT_PED_OP = PED_OP;
export const CAT_PED_SP = PED_SP;
export const CAT_PED_MP = PED_MP;
export const CAT_HUTCH = HUTCH;
export const CAT_LST = LST;
export const CAT_BC = BC;
export const CAT_BSC = BSC;
export const CAT_SSC = SSC;
export const CAT_PST = PST;
export const CAT_LBC = LBC;
export const CAT_FILING = FILING;
export const CAT_WARD = WARD;
export const CAT_AS = AS;
export const CAT_LS = LS;
export const CAT_KIT = KIT;
export const CAT_ML = ML;

export default categories;
