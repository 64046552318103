import React, { FunctionComponent } from 'react';
import hasPermissions from 'helpers/permissons';
import { AppPermissions } from 'constants/permissions';
import { BookingEvent, OrderEvent, CustomEvent } from './Cells';
import { CalendarEvent, EventItemType } from './state/reducer';

interface Props {
  event: CalendarEvent;
  isStart?: boolean;
  isEnd?: boolean;
  isMirror?: boolean;
  isPast?: boolean;
  isFuture?: boolean;
  isToday?: boolean;
  el: any;
}

const componentMap = {
  [EventItemType.BOOKING]: BookingEvent,
  [EventItemType.ORDER]: OrderEvent,
  [EventItemType.CUSTOM]: CustomEvent,
};

const Cell: FunctionComponent<Props> = (props) => {
  const { event: { extendedProps: { type, item } } } = props;
  const Component = componentMap[type];
  const hasOrderPermission = hasPermissions(['Sales-Orders', AppPermissions.view_orders]);

  return <Component item={item as any} hasOrderPermission={hasOrderPermission} />;
};

export default Cell;
