import { commonRules } from 'helpers/form-validations';
import { isValidEmail } from 'helpers/input-validations';

export const validationRules = {
  recipients: [
    commonRules.required,
    (value: string[]) => {
      if (value.some(email => !isValidEmail(email))) {
        return 'There is an invalid email address';
      }
      return undefined;
    },
  ]
};
