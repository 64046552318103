import { EditOptionsFromModel } from '.';

export enum EditOptionsFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_EDITING = 'START_EDITING',
  FINISH_EDITING = 'FINISH_EDITING',
  SET_CUSTOM_OPTION = 'SET_CUSTOM_OPTION'
}

interface EditOptionsFormState {
  formData: EditOptionsFromModel;
  editing: boolean;
  errors: any;
}

export default function editOptionsFormReducer(
  state: EditOptionsFormState,
  action,
): EditOptionsFormState {
  const { type, payload } = action;

  switch (type as EditOptionsFormActions) {
    case EditOptionsFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case EditOptionsFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, editing: false };
    case EditOptionsFormActions.START_EDITING:
      return { ...state, editing: true };
    case EditOptionsFormActions.FINISH_EDITING:
      return { ...state, editing: false };
    case EditOptionsFormActions.SET_CUSTOM_OPTION: {
      const { formData } = state;
      const { option, parent } = payload;

      return {
        ...state,
        formData: {
          ...formData,
          [parent]: option,
        },
      };
    }
    default:
      return state;
  }
}
