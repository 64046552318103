import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import getEventValue from 'helpers/get-event-value';
import Input, { Props as InputProps } from 'components/base-components/Input';

interface Props extends InputProps {
  value: number;
  allowDecimals?: boolean;
  min?: number;
  max?: number;
  onBlur?: () => void;
  notMB?: boolean;
}

const integerNumberRegex = /^(-)?\d+$/;
const floatNumberRegex = /^(-)?\d+(\.)?(\d{1,2})?$/;
const maxError = (max) => `Max-${max}`;
const minError = (min) => `Min-${min}`;

const NumberInput: FunctionComponent<Props> = (props) => {
  const { allowDecimals, value, onChange, min, max, onBlur, notMB, error, ...rest } = props;
  const [baseNumber, setBaseNumber] = useState(value.toString());
  const [internalError, setInternalError] = useState(undefined);

  const handleOnChange = useCallback((event) => {
    const nextValue = getEventValue(event);
    const regex = allowDecimals ? floatNumberRegex : integerNumberRegex;

    if (nextValue === '.') {
      setBaseNumber('0.');
      onChange({ target: { value: parseFloat('0.') } } as any);
    }

    if (nextValue === '' || nextValue === '-' || regex.test(nextValue)) {
      const number = allowDecimals ? parseFloat(nextValue) || 0 : parseInt(nextValue, 10) || 0;
      setBaseNumber(nextValue);
      onChange({ target: { value: parseFloat(nextValue) || 0 } } as any);
      if (typeof min === 'number' && min > number) {
        setInternalError(minError(min));
      } else if (typeof max === 'number' && max < number) {
        setInternalError(maxError(max));
      } else {
        setInternalError(undefined);
      }
    }
  }, [onChange, allowDecimals, min, max]);

  const handleOnBlur = useCallback(() => {
    const number = allowDecimals ? parseFloat(baseNumber) : parseInt(baseNumber, 10);
    onBlur();
    if (typeof min === 'number' && min > number) {
      setInternalError(minError(min));
    } else if (typeof max === 'number' && max < number) {
      setInternalError(maxError(max));
    } else {
      setInternalError(undefined);
    }
  }, [allowDecimals, baseNumber, max, min, onBlur]);

  useEffect(() => {
    const parsedBaseValue = parseFloat(baseNumber);
    if (value !== parsedBaseValue) {
      setBaseNumber(value.toString());
    }
    if (typeof min === 'number' && min > value) {
      setInternalError(minError(min));
    } else if (typeof max === 'number' && max < value) {
      setInternalError(maxError(max));
    } else {
      setInternalError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      value={baseNumber}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      error={error || internalError}
      notMB={notMB}
      {...rest}
    />
  );
};

export default NumberInput;
