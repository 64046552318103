import React from 'react';
import { formatCurrency } from 'helpers/formatting';
import '../styles.scss';

export const outstandingTemplate = (value) => (
  <div className="project-coordinator__due-column">
    {formatCurrency(value)}
  </div>
);

export const paidTemplate = (value) => (
  <div className="project-coordinator__paid-column">
    {formatCurrency(value)}
  </div>
);
