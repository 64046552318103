import React, { FunctionComponent } from 'react';
import './styles.scss';
import RenderIf from '../RenderIf';

interface Props {
  item: any;
}

const LabelOption: FunctionComponent<Props> = (props) => {
  const { item } = props;
  const lightTexture = (item.name.includes('White') || item.name === 'Geneva' || !item.image) ? 'lightTexture' : undefined;

  return (
    <div className="product-viewer__option-label-wrapper">
      <span className={`product-viewer__option-label-text ${lightTexture}`}>{item.name}</span>
      <RenderIf isTrue={!!item.image}>
        <img className="product-viewer__option-label-image" src={item.image} crossOrigin="anonymous" alt={`${item.name} texture`} />
      </RenderIf>
    </div>
  );
};

export function getLabelOption(item) {
  return (<LabelOption item={item} />);
}
