import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import Upload from 'components/base-components/Upload';
import profile from 'assets/images/profile-img.png';
import { AccountApplicationBusinessType } from 'models/account-application';
import { Text, Title } from 'components/base-components/Typography';
import NumberInput from 'components/base-components/NumberInput';
import useAccountApplicationFormState from './state';
import validationRules from './state/validations';
import './styles.scss';

const businessTypeOptions = [
  { value: AccountApplicationBusinessType.Proprietorship, label: AccountApplicationBusinessType.Proprietorship },
  { value: AccountApplicationBusinessType.Partnership, label: AccountApplicationBusinessType.Partnership },
  { value: AccountApplicationBusinessType.Corporation, label: AccountApplicationBusinessType.Corporation },
];

export default function AccountApplicationForm() {
  const {
    state: {
      accountApplication,
      errors,
      savingAccountApplication,
    },
    actions: {
      setErrors,
      setFormValue,
      saveAccountApplication,
    },
  } = useAccountApplicationFormState();

  const businessTypeValue = useMemo(() => {
    const value = businessTypeOptions.find((bT) => bT.value === accountApplication.businessType);
    return value || businessTypeOptions[0];
  }, [accountApplication]);

  const action = (
    <Button
      label="Submit"
      onClick={saveAccountApplication}
      loading={savingAccountApplication}
      block
    />
  );

  return (
    <div className="account-app-container">
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home fa-lg" />
        </Link>
      </div>
      <div className="account-pages my-2 pt-sm-2">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={7}>
              <Card bordered={false} className="account-app__card" actions={[action]}>
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <Title level={5}>Welcome !</Title>
                        <Text>Account Application</Text>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <div className="account-app-container__body">
                  <div className="p-2">
                    <Form
                      state={accountApplication}
                      onChange={setFormValue}
                      errors={errors}
                      onError={setErrors}
                      rules={validationRules}
                    >
                      <Field
                        id="businessTradeName"
                        name="businessTradeName"
                        label="Business Trade Name"
                        placeholder="Enter Business Trade Name"
                      />
                      <Field
                        id="ownerFirstName"
                        name="ownerFirstName"
                        label="Owner First Name"
                        placeholder="Enter Owner First Name"
                      />
                      <Field
                        id="ownerLastName"
                        name="ownerLastName"
                        label="Owner Last Name"
                        placeholder="Enter Owner Last Name"
                      />
                      <Field
                        type="email"
                        id="ownerEmail"
                        name="ownerEmail"
                        label="Owner Email"
                        placeholder="Enter Owner email"
                      />
                      <Field
                        id="businessLegalName"
                        name="businessLegalName"
                        label="Business Legal Name"
                        placeholder="Enter Business Legal Name"
                      />
                      <Field
                        id="website"
                        name="website"
                        label="Website"
                        placeholder="Enter Website"
                      />
                      <Field
                        component={Select}
                        id="businessType"
                        name="businessType"
                        label="Business Type"
                        options={businessTypeOptions}
                        value={businessTypeValue}
                      />
                      <Field
                        component={NumberInput}
                        id="businessPhone"
                        name="businessPhone"
                        label="Business Phone"
                        placeholder="Enter Business Phone"
                      />
                      <Field
                        id="businessAddress"
                        name="businessAddress"
                        label="Business Address"
                        placeholder="Enter Business Address"
                      />
                      <Field
                        id="businessCity"
                        name="businessCity"
                        label="Business City"
                        placeholder="Enter Business City"
                      />
                      <Field
                        id="businessStateProvince"
                        name="businessStateProvince"
                        label="Business State Province"
                        placeholder="Enter Business State Province"
                      />
                      <Field
                        id="businessPostCode"
                        name="businessPostCode"
                        label="Business Post Code"
                        placeholder="Enter Business Post Code"
                      />
                      <Field
                        type="email"
                        id="businessEmail"
                        name="businessEmail"
                        label="Business Email"
                        placeholder="Enter Business Email"
                      />
                      <Field
                        id="businessNumber"
                        name="businessNumber"
                        label="Business Number"
                        placeholder="Enter Business Number"
                      />
                      <Field
                        id="irsGstNumber"
                        name="irsGstNumber"
                        label="Irs gst Number"
                        placeholder="Enter Irs gst Number"
                      />
                      <Field
                        component={NumberInput}
                        id="yearsInBusiness"
                        name="yearsInBusiness"
                        label="Years In Business"
                        placeholder="Enter Years In Business"
                      />
                      <Field
                        component={NumberInput}
                        id="creditAmount"
                        name="creditAmount"
                        label="Credit Amount"
                        placeholder="Enter Credit Amount"
                      />
                      <Field
                        id="apName"
                        name="apName"
                        label="Ap Name"
                        placeholder="Enter Ap Name"
                      />
                      <Field
                        type="email"
                        id="apEmail"
                        name="apEmail"
                        label="Ap Email"
                        placeholder="Enter Ap Email"
                      />
                      <Field
                        component={Upload}
                        name="taxExempt"
                        id="taxExempt"
                        label="Choose Tax Exempt"
                        accept="image/png, .jpeg, .jpg, application/pdf"
                      />
                      <Field
                        id="bankName"
                        name="bankName"
                        label="Bank Name"
                        placeholder="Enter Bank Name"
                      />
                      <Field
                        id="accountNumber"
                        name="accountNumber"
                        label="Account Number"
                        placeholder="Enter Account Number"
                      />
                      <Field
                        id="bankAddress"
                        name="bankAddress"
                        label="Bank Address"
                        placeholder="Enter Bank Address"
                      />
                      <Field
                        id="bankCity"
                        name="bankCity"
                        label="Bank City"
                        placeholder="Enter Bank City"
                      />
                      <Field
                        id="bankStateProvince"
                        name="bankStateProvince"
                        label="Bank State Province"
                        placeholder="Enter Bank State Province"
                      />
                      <Field
                        id="bankPostCode"
                        name="bankPostCode"
                        label="Bank Post Code"
                        placeholder="Enter Bank Post Code"
                      />
                      <Field
                        component={NumberInput}
                        id="bankPhone"
                        name="bankPhone"
                        label="Bank Phone"
                        placeholder="Enter Bank Phone"
                      />
                      <div className="border rounded-top bg-light p-2">
                        References
                      </div>
                      <div
                        className="account-app-references-wrapper border border-light rounded-bottom p-1 mb-4"
                      >
                        <Row form className="border-bottom mb-1">
                          <Col>Name</Col>
                          <Col>Email</Col>
                          <Col>Phone</Col>
                        </Row>
                        <Row form className="border-bottom mb-1 pb-1">
                          <Col>
                            <Field
                              id="ref1BusinessName"
                              name="ref1BusinessName"
                              placeholder="Enter Business Name"
                            />
                          </Col>
                          <Col>
                            <Field
                              type="email"
                              id="ref1ContactEmail"
                              name="ref1ContactEmail"
                              placeholder="Enter Contact Email"
                            />
                          </Col>
                          <Col>
                            <Field
                              component={NumberInput}
                              id="ref1ContactPhone"
                              name="ref1ContactPhone"
                              placeholder="Enter Contact Phone"
                            />
                          </Col>
                        </Row>
                        <Row form className="border-bottom mb-1 pb-1">
                          <Col>
                            <Field
                              id="ref2BusinessName"
                              name="ref2BusinessName"
                              placeholder="Enter Business Name"
                            />
                          </Col>
                          <Col>
                            <Field
                              type="email"
                              id="ref2ContactEmail"
                              name="ref2ContactEmail"
                              placeholder="Enter Contact Email"
                            />
                          </Col>
                          <Col>
                            <Field
                              component={NumberInput}
                              id="ref2ContactPhone"
                              name="ref2ContactPhone"
                              placeholder="Enter Contact Phone"
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col>
                            <Field
                              id="ref3BusinessName"
                              name="ref3BusinessName"
                              placeholder="Enter Business Name"
                            />
                          </Col>
                          <Col>
                            <Field
                              type="email"
                              id="ref3ContactEmail"
                              name="ref3ContactEmail"
                              placeholder="Enter Contact Email"
                            />
                          </Col>
                          <Col>
                            <Field
                              component={NumberInput}
                              id="ref3ContactPhone"
                              name="ref3ContactPhone"
                              placeholder="Enter Contact Phone"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
