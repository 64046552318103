import {
  commonTextures,
  commonModestyStyles,
  commonGrommetStyles,
  cogGrommetStyles,
} from './__CommonOptions';

const data = {
  category: 'DESK',
  styles: [
    {
      name: 'DESK-BF',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-bf.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-BF.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-ECUL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-ecul.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-ECUL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-ECUR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-ecur.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-ECUR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-RCL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-rcl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-RCL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-RCR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-rcr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-RCR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-RFF',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-rff.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-RFF.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-RFFLHG',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-rfflhg.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-RFFLHG.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-RFFLRHG',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-rfflrhg.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-RFFLRHG.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-RFFRHG',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-rffrhg.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-RFFRHG.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-RRF',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-rrf.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-RRF.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-WVL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-wvl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-WVL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'DESK-WVR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-wvr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/DESK-WVR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
  ],
};

export default data;
