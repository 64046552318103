import React, { FunctionComponent, useCallback } from 'react';
import { QuoteModel } from 'models/quote';
import { ShipmentDTO } from 'models/shipment';
import { AccountTaxModel } from 'models/account';
import { ExtraChargeModel } from 'models/extra-charge';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTable from 'components/base-components/DataTableV2';
import totalTemplate from './templates/total-template';
import modelTemplate from './templates/model-template';
import renderContent from './templates/render-content';
import QuoteProductsListSummary from './QuoteProductsListSummary';
import rowClassName from './row-class-name';
import useQuoteProductsListState from './state';

interface Props {
  quote: QuoteModel;
  shipment: ShipmentDTO;
  taxes: AccountTaxModel[];
  charges: ExtraChargeModel[];
}

const QuoteProductsList: FunctionComponent<Props> = (props) => {
  const { quote, shipment, taxes, charges } = props;

  const {
    state: {
      expandedRowKeys,
      quoteProducts,
      orderTotal,
      shippingPrice,
      fetchingShippingPrice,
      extraCharges,
    },
  } = useQuoteProductsListState(quote, shipment, charges);

  const summary = useCallback(() => {
    return (
      <QuoteProductsListSummary
        orderTotal={orderTotal}
        shippingPrice={shippingPrice}
        taxes={taxes}
        colSpan={2}
        extraCharges={extraCharges}
      />
    );
  }, [
    orderTotal,
    shippingPrice,
    taxes,
    extraCharges,
  ]);

  return (
    <DataTable
      rowKey="id"
      loading={fetchingShippingPrice}
      dataSource={quoteProducts}
      rowClassName={rowClassName}
      pagination={false}
      childrenColumnName="products"
      indentSize={0}
      expandIconColumnIndex={-1}
      expandedRowKeys={expandedRowKeys}
      summary={summary}
    >
      <DataTableColumn
        columnKey="model"
        title="Product"
        render={modelTemplate}
        className="quote-product-list__model-column"
      />
      <DataTableColumn
        dataIndex="quantity"
        columnKey="quantity"
        title="Quantity"
        render={renderContent}
      />
      <DataTableColumn
        columnKey="total"
        title="Total"
        render={totalTemplate}
        className="quote-product-list__subtotal"
        isCurrency
      />
    </DataTable>
  );
};

export default QuoteProductsList;
