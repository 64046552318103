import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Spinner from 'components/base-components/Spinner';

export const headerLabel = (
  <div>
    <h1 className="questionnaire__header centered">
      Client Questionnaire
    </h1>
    <p className="questionnaire__p mB centered">
      To help accommodate delivery, please complete the form below.
      <br />
      This information will be automatically sent to our staff in order
      to ensure we provide the best service possible.
    </p>
  </div>
);

export const loadingScreen = (
  <div className="page-content questionnaire">
    <div className="container-fluid">
      <Row>
        <Col>
          <Card className="questionnaire__card">
            <CardBody className="questionnaire__card--loading">
              {headerLabel}
              <Spinner />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  </div>
);

export const successScreen = (
  <div className="page-content questionnaire">
    <div className="container-fluid">
      <Row>
        <Col>
          <Card className="questionnaire__card">
            <CardBody className="questionnaire__card--loading">
              <div>
                <h1 className="questionnaire__header centered">
                  Client Questionnaire
                </h1>
                <p className="questionnaire__p mB centered">
                  Thanks for taking a time and filling the questionnaire.
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  </div>
);

export const errorScreen = (
  <div className="page-content questionnaire">
    <div className="container-fluid">
      <Row>
        <Col>
          <Card className="questionnaire__card">
            <CardBody className="questionnaire__card--loading">
              <div>
                <h1 className="questionnaire__header centered">
                  Oops!? ...this is embarrassing.
                </h1>
                <p className="questionnaire__p mB centered">
                  Something went wrong.
                  <br />
                  If the issue is on our side, we will deal with it,
                  but just in case,
                  <br />
                  please check your internet connection.
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  </div>
);

export const termsLabel = (
  <span className="questionnaire__terms">
    I have read and agree to the
    <Link to="/">
      <span className="questionnaire__terms-link">
        {' '}
        Terms & Conditions
      </span>
    </Link>
  </span>
);
