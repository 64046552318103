import React, { FunctionComponent } from 'react';
import { getModel } from 'helpers/builder-util';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import modelTemplate from './model-template';
import { BuilderFormModel } from '../state';
import './styles.scss';

interface ProductListProps {
  products: BuilderFormModel[];
  loading?: boolean;
}

export const getPayload = (products: BuilderFormModel[]) => products.map(({ modelDescription, options }) => {
  const model = getModel(modelDescription);
  const normalizedOptions = Object.keys(options).reduce((opt, optionType) => {
    return ({
      ...opt,
      [optionType]: {
        name: options[optionType]?.value,
        cost: options[optionType]?.dropdownItem
          ? options[optionType].dropdownItem?.cost
          : options[optionType]?.cost,
      },
    });
  }, {});
  return {
    model,
    options: normalizedOptions,
    price: null,
  };
});

export const ProductTable: FunctionComponent<ProductListProps> = (props) => {
  const { products, loading } = props;

  return (
    <DataTable
      loading={loading}
      dataSource={products}
    >
      <DataTableColumn
        columnKey="model"
        title="Model"
        render={modelTemplate}
        className="builder-product-list__model-column"
      />
      <DataTableColumn
        dataIndex="quantity"
        columnKey="quantity"
        title="Quantity"
      />
      <DataTableColumn
        dataIndex="price"
        columnKey="price"
        title="Unit Price"
        isCurrency
      />
      <DataTableColumn
        columnKey="totalPrice"
        dataIndex="totalPrice"
        title="Total"
        isCurrency
      />
    </DataTable>
  );
};
