import { getLoggedUser } from 'helpers/get-logged-user';
import { PermissionLabels } from 'constants/permissions';

export default function hasPermissions(permissionsToMatch: PermissionLabels[]) {
  const {
    isSuperuser,
    permissions,
  } = getLoggedUser();

  const shouldCheckFormPermissions = (
    !isSuperuser &&
    Array.isArray(permissions) &&
    permissions.length > 0 &&
    Array.isArray(permissionsToMatch) &&
    permissionsToMatch.length > 0

  );

  if (shouldCheckFormPermissions) {
    return permissionsToMatch.some(perm => !!permissions.find(p => p === perm));
  }
  return true;
}

export function isStaffPermission() {
  const { isStaff } = getLoggedUser();

  return isStaff;
}
