import { PurchaseOrderModel } from 'models/order';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { PurchaseOrderFormHistorySateModel } from '../../../Form';

export default function openFormModal(history, orderId?, fromProjectCoordinator?) {
  return (purchaseOrder?: PurchaseOrderModel | any) => {
    const historyLocationState: PurchaseOrderFormHistorySateModel = purchaseOrder ? {
      purchaseOrderForm: {
        id: purchaseOrder?.id,
        orderId,
        fromProjectCoordinator,
      },
    } : null;
    history.push(`${history.location?.search}${ModalRouteHash.PurchaseOrderForm}`, historyLocationState);
  };
}
