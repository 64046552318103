import { useCallback, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import setErrors from './actions/set-errors';
import setTutorial from './actions/set-tutorial';
import initFormData from './actions/init-form-data';
import saveTutorial from './actions/save-tutorial';
import handleFileChange from './actions/handle-file-change';
import tutorialFormReducer, { TutorialFormState } from './reducer';

export * from './validations';
export * from './reducer';

const initialState: TutorialFormState = {
  tutorial: {} as any,
  fetchingFormData: true,
  savingTutorial: false,
  errors: {},
};

export default function use3DModelFormState() {
  const { location: { state: navigationState }, goBack } = useHistory();
  const [state, dispatch] = useReducer(tutorialFormReducer, initialState);

  useEffect(() => {
    const tutorial = (navigationState as any)?.tutorialsForm?.tutorial;
    initFormData(dispatch, tutorial);
  }, [navigationState]);

  return {
    state,
    actions: {
      handleFileChange,
      setModel: useCallback(setTutorial(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      saveThreeModel: useCallback(
        saveTutorial(dispatch, state.tutorial, goBack),
        [state.tutorial],
      ),
    },
  };
}
