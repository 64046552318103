/* eslint-disable */
import * as THREE from "three";
import Floorplan from "./floorplan";
import Scene from "./scene";

export default class Model {
  /** */
  floorplan;

  /** */
  scene;

  /** */
  roomLoadingCallbacks = [];

  /** */
  roomLoadedCallbacks = [];

  /** name */
  roomSavedCallbacks = [];

  /** success (bool), copy (bool) */
  roomDeletedCallbacks = [];

  /** Constructs a new model.
   * @param textureDir The directory containing the textures.
   */
  constructor(textureDir) {
    this.floorplan = new Floorplan();
    this.scene = new Scene(this, textureDir);
    this.floorplan.scene = this.scene;
  }

  loadSerialized = (json) => {
    // TODO: better documentation on serialization format.
    // TODO: a much better serialization format.
    this.roomLoadingCallbacks.forEach((cb) => typeof cb === "function" && cb());

    const data = JSON.parse(json);
    this.newRoom(data.floorplan, data.items);

    this.roomLoadedCallbacks.forEach((cb) => typeof cb === "function" && cb());
  };

  exportSerialized = (products) => {
    const itemsArr = [];
    const objects = this.scene.getItems();
    for (const [i, object] of objects.entries()) {
      const metadata = products.find(product => product.id === object.metadata.id) || object.metadata;
      itemsArr[i] = {
        uuid: object.uuid,
        item_name: object.metadata.itemName,
        item_type: object.metadata.itemType,
        model_url: object.metadata.modelUrl || object.metadata.threeModel,
        xpos: object.position.x,
        ypos: object.position.y,
        zpos: object.position.z,
        rotation: object.rotation.y,
        metadata,
        options: object.getOptions(),
      };
    }

    const room = {
      floorplan: this.floorplan.saveFloorplan(),
      items: itemsArr,
    };

    return JSON.stringify(room);
  };

  newRoom = (floorplan, items) => {
    this.scene.clearItems();
    this.floorplan.loadFloorplan(floorplan);
    items.forEach((item) => {
      const position = new THREE.Vector3(item.xpos, item.ypos, item.zpos);
      const { metadata } = item;
      this.scene.addItem(
        item.item_type,
        item.model_url,
        metadata,
        position,
        item.rotation,
        item.options
      );
    });
  };
}
