import { ProvinceModel } from 'models/province';
import { ApiModel, get } from './base';

const { REACT_APP_SHIPMENT_API } = process.env;
const provincesEndpoint = `${REACT_APP_SHIPMENT_API}/provinces/`;

const provincesApi: ApiModel<ProvinceModel> = {
  get(url) {
    return get(url);
  },
  list(params?) {
    return get(provincesEndpoint, params);
  }
};

export default provincesApi;
