import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import setProduct from './actions/set-product';
import addProduct from './actions/add-product';
import deleteProduct from './actions/delete-product';
import setErrors from './actions/set-errors';
import calculate from './actions/calculate';
import manualCalcReducer, {
  ManualCalcFormState,
  ManualProductModel,
} from './reducer';

export { validationRules } from './validations';

const initialState: ManualCalcFormState = {
  product: {} as ManualProductModel,
  productList: [],
  errors: {} as any,
  calculating: false,
};

export default function useManualCalcFormState() {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(manualCalcReducer, initialState);

  return {
    state,
    actions: {
      setProduct: useCallback(setProduct(dispatch), []),
      addProduct: useCallback(addProduct(dispatch, state.product), [state.product]),
      deleteProduct: useCallback(deleteProduct(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      calculate: useCallback(calculate(dispatch, state.productList, goBack), [state.productList]),
      calculateWithoutGoBack: useCallback(calculate(dispatch, state.productList), [state.productList]),
    },
  };
}
