import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import productApi from 'api/product';
import notification, { NotificationType } from 'helpers/notification';
import useCallApiAction from 'hooks/base/call-api-action';
import Modal from 'components/base-components/ModalV2';
import { getPayload, ProductTable } from './ProductTable';
import { BuilderFormModel } from '../state';
import './styles.scss';

export interface ProductListHistorySateModel {
  productListForm: {
    products: BuilderFormModel[];
  };
}

export * from './ProductTable';

const ProductListForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { productListForm: { products } } = state as ProductListHistorySateModel;
  const payload = useMemo(() => ({
    products: getPayload(products)
  }), [products]);

  const {
    fetching,
    data: { data },
    error,
  } = useCallApiAction<any>(productApi.getPrice, null, [], false, payload);

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: error,
      });
    }
  }, [error]);

  return (
    <Modal
      title="Product List"
      visible
      footer={null}
      onCancel={goBack}
    >
      <ProductTable products={data} loading={fetching} />
    </Modal>
  );
};

export default ProductListForm;
