import { BillModel } from 'models/bill';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/bills/`;

const billApi: ApiModel<BillModel> = {
  ...getApi<BillModel>(endpoint, ApiContentType.JSON, true),
};

export default billApi;
