import { QuoteModel } from 'models/quote';
import orderOptionsApi from 'api/order-option';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { getOptionPayload } from '../../../Form/AddProduct/state/actions/get-payload';

export default async function saveProducts(
  quote: QuoteModel,
  productList: { [productId: string]: any },
  productsFormData: { [productId: string]: { [option: string]: DropdownOptionModel } },
) {
  const productsToUpdate = quote.orderproducts.filter(
    (product) => !!productList[product.id]
  );

  // eslint-disable-next-line no-restricted-syntax
  for (const product of productsToUpdate) {
    const productValue = productsFormData[product.id];
    const productOptions = Object.keys(productValue);

    // eslint-disable-next-line no-restricted-syntax
    for (const optionName of productOptions) {
      const optionValue = productValue[optionName];
      if (!!optionValue && !optionName.includes('sub')) {
        const payload = getOptionPayload(optionValue, optionName, product.id, productValue[`sub-${optionName}`]);
        // eslint-disable-next-line no-await-in-loop
        await orderOptionsApi.create(payload);
      }
    }
  }
}
