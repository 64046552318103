import { useCallback, useMemo, useReducer } from 'react';
import checkIsProcessingView from './actions/check-is-processing-view';
import updateBulkViews from './actions/updating-bulk';
import updating from './actions/updating';
import { dataViews } from '../views';
import viewPermissionsListReducer, { ViewsPermissionsListActions } from './reducer';

export default function useViewsPermissionsListState() {
  const [state, dispatch] = useReducer(viewPermissionsListReducer, {
    selectedRows: [],
    viewsInProcess: [],
    updating: false,
  });

  const rowSelection = useMemo(() => ({
    selectedRowKeys: state.selectedRows,
    onChange: (selectedRowKeys) => {
      dispatch({
        type: ViewsPermissionsListActions.SET_SELECTED_ROWS,
        payload: selectedRowKeys,
      });
    },
  }), [state.selectedRows]);
  const data = useMemo(() => {
    return dataViews.map(view => {
      const permissions = [];
      Object.keys(view.permissions).forEach(key => permissions.push(...view.permissions[key]));
      return { ...view, permissions };
    });
  }, []);

  return {
    state: {
      ...state,
      data,
      rowSelection,
    },
    actions: {
      updatingPermissions: useCallback(updating(dispatch), []),
      updateBulkViews: useCallback(
        updateBulkViews(dispatch, data, state.selectedRows),
        [data, state.selectedRows],
      ),
      checkIsProcessingView: useCallback(
        checkIsProcessingView(state.viewsInProcess),
        [state.viewsInProcess],
      ),
    },
  };
}
