import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import { OrderOption } from 'models/order-option';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/options/`;

const orderOptionApi: ApiModel<OrderOption> = getApi<OrderOption>(endpoint, ApiContentType.JSON, true);

export default orderOptionApi;
