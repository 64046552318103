import { SendEmailFormActions } from '../reducer';

export default function unselectS3Files(dispatch, files: string[]) {
  return (value) => {
    dispatch({
      type: SendEmailFormActions.SET_FILES,
      payload: files.filter(file => file !== value),
    });
  };
}
