import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PurchaseOrderModel } from 'models/order';
import Button from 'components/base-components/Button';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Page, { PageActions } from 'components/base-components/Page';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import Help from 'components/forms/CommonHelp';
import AccessControl from 'components/base-components/AccessControl';
import Switch from 'components/base-components/Switch';
import { permissions } from './permissions';
import usePurchaseOrdersListState, { PurchaseOrderListModel } from './state';
import './styles.scss';

const hideIfNoManufacturer = (po: PurchaseOrderListModel) => !po.manufacturer;
const hideIfUnBilled = (po: PurchaseOrderListModel) => !po.bill;
const hideIfBilled = (po: PurchaseOrderListModel) => !!po.bill;

const PurchaseOrdersList: FunctionComponent = () => {
  const { push } = useHistory();

  const {
    state: {
      data,
      fetchingData,
      count,
      page,
      linkToOrder,
      filter,
      showAll,
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
      openFormModal,
      printPDF,
      createBill,
      deleteBill,
      handleSetDateSent,
      openSendEmailModal,
      toggleShowAll,
    }
  } = usePurchaseOrdersListState();

  const goToDetails = useCallback((purchaseOrder: PurchaseOrderModel) => {
    push(`/purchase-order/${purchaseOrder.id}`);
  }, [push]);

  return (
    <Page title="Purchase Orders">
      <Help view="purchase-orders" title="Purchase Orders List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addOrder}>
          <Button
            onClick={openFormModal}
            label="Create Purchase Order"
          />
        </AccessControl>
        <Switch
          className="purchase-order-list__switch"
          checked={showAll}
          onChange={toggleShowAll}
          label="Show All"
        />
      </PageActions>
      <DataTable
        dataSource={data}
        loading={fetchingData}
        page={page}
        totalRecords={count}
        onChange={handleOnChange}
        onFilter={handleFilter}
        defaultFilter={filter}
      >
        <DataTableColumn
          title="PO Number"
          dataIndex="id"
          columnKey="id"
          sorter
        />
        <DataTableColumn
          title="Manufacturer"
          dataIndex={['manufacturer', 'companyName']}
          columnKey="manufacturer"
          sorter
        />
        <DataTableColumn
          title="Order ID"
          dataIndex="order"
          columnKey="order"
          sorter
          link={linkToOrder}
        />
        <DataTableColumn
          title="Job Title"
          dataIndex="jobTitle"
          columnKey="job_title"
          sorter
          showBreakpoint={808}
        />
        <DataTableColumn
          title="Pur. Method"
          dataIndex={['manufacturer', 'purchaseMethod']}
          columnKey="purchase_method"
          showBreakpoint={1092}
        />
        <DataTableColumn
          title="Tag"
          dataIndex="tag"
          columnKey="tag"
          sorter
          showBreakpoint={1199}
        />
        <DataTableColumn
          title="Date Sent to Supplier"
          dataIndex="dateSent"
          columnKey="date_sent"
          isDate
          sorter
          showBreakpoint={1381}
        />
        <DataTableColumn
          title="Order Ready Date"
          dataIndex="orderReadyDate"
          columnKey="order_ready_date"
          isDate
          sorter
          showBreakpoint={1545}
        />
        <DataTableColumn
          title="Supplier Ready Date"
          dataIndex="supplierReadyDate"
          columnKey="supplier_ready_date"
          isDate
          sorter
          showBreakpoint={1721}
        />
        <DataTableColumn
          isActionColumn
          onShowDetails={goToDetails}
          editPermissions={permissions.editOrder}
          deletePermissions={permissions.deleteOrder}
          viewPermissions={permissions.viewPOItems}
          onEdit={openFormModal}
          onDelete={handleDeleteRow}
        >
          <DataTableAction
            hide={hideIfNoManufacturer}
            onClick={openSendEmailModal}
            icon="fa fa-paper-plane"
            permissions={permissions.editOrder}
          />
          <DataTableAction
            onClick={handleSetDateSent}
            label="Set Date Sent"
            icon="fa fa-calendar-check"
            permissions={permissions.editOrder}
          />
          <DataTableAction
            hide={hideIfNoManufacturer}
            onClick={printPDF}
            label="Print"
            icon="fa fa-print"
          />
          <DataTableAction
            hide={hideIfBilled}
            onClick={createBill}
            label="Convert to Bill"
            icon="fa fa-receipt"
            permissions={permissions.addBill}
          />
          <DataTableAction
            hide={hideIfUnBilled}
            onClick={deleteBill}
            label="Delete Bill"
            icon="fa fa-receipt"
            permissions={permissions.deleteBill}
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default PurchaseOrdersList;
