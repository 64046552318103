import React, { FunctionComponent } from 'react';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import optionsTemplate from './templates/options-template';
import { PaintProductModel } from '../state';

interface Props {
  products: PaintProductModel[];
}

const ProductsList: FunctionComponent<Props> = (props) => {
  const { products } = props;

  return (
    <DataTable
      rowKey="model"
      dataSource={products}
      pagination={false}
    >
      <DataTableColumn
        columnKey="model"
        title="Model"
        dataIndex="model"
      />
      <DataTableColumn
        dataIndex={['description', 'paragraph']}
        columnKey="description"
        title="Description"
        className="third-party-paint__description-column"
      />
      <DataTableColumn
        dataIndex="options"
        columnKey="options"
        title="Options"
        render={optionsTemplate}
      />
      <DataTableColumn
        dataIndex="quantity"
        columnKey="quantity"
        title="Quantity"
      />
      <DataTableColumn
        dataIndex="dateSent"
        columnKey="dateSent"
        title="Sent"
        isDate
      />
    </DataTable>
  );
};

export default ProductsList;
