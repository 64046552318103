import React, { useContext, useState, useEffect, FunctionComponent, ReactNode } from 'react';
import { Form } from 'antd';
import manufacturerApi from 'api/manufacturer';
import { resolveApiErrorMessage } from 'api/base';
import { mapDropdownOptions, DropdownOptionModel } from 'helpers/dropdown-options';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import getEventValue from 'helpers/get-event-value';
import Select from 'components/base-components/Select';
import AsyncSelect from 'components/base-components/AsyncSelectV2';
import Input from 'components/base-components/Input';
import TextArea from 'components/base-components/TextArea';
import Checkbox from 'components/base-components/Checkbox';
import Tag from 'components/base-components/Tag';
import InputNumber from 'components/base-components/InputNumber';
import TreeSelect from 'components/base-components/TreeSelect';

import EditableContext from './editableContext';

type EditableType = 'select' | 'input' | 'inputNumber' | 'treeSelect' | 'asyncSelect' | 'checkbox' | 'inputTextArea';

const autoSize = { minRows: 1, maxRows: 20 };

interface Item {
  key: string;
  model: string;
  category: string;
  options: DropdownOptionModel[];
  name: string;
  manufacture: string;
  price: number;
  quantity: number;
  group: string;
  disabled?: boolean;
  cnc: boolean;
  outsidePaint: boolean;
  productOptions: any[];
}

interface EditableCellProps {
  title: ReactNode;
  editable: boolean;
  type: EditableType;
  options: DropdownOptionModel[];
  children: ReactNode;
  dataIndex: string;
  record: Item;
  handleSave: (record: Item, dataIndex: string) => void;
}

function tagRender(props) {
  const { value, closable, onClose } = props;

  return (
    <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
      {value}
    </Tag>
  );
}

const EditableCell: FunctionComponent<EditableCellProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  options,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [valueColumn, setValueColumn] = useState(false);
  const form = useContext(EditableContext);
  const styleEditable = { margin: 0, minWidth: '100%' };

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };
  const handleNewGroup = () => {
    notifyEventChannel(EventChannelList.QUOTE_PAGE_OPEN_GROUP_MODAL);
  };
  useEffect(() => {
    if (record && record[dataIndex] !== valueColumn) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
      setValueColumn(record[dataIndex]);
    }
  }, [record, dataIndex, valueColumn, form]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      if (type === 'select' || type === 'checkbox') {
        values[dataIndex] = getEventValue(values[dataIndex]);
      }
      if (type.includes('input')) {
        toggleEdit();
      }
      handleSave({ ...record, ...values }, dataIndex);
    } catch (error) {
      notification({ type: NotificationType.ERROR, message: resolveApiErrorMessage('Save Fail') });
    }
  };
  const handleOnPressEsc = (event) => {
    if (event.keyCode === 27) {
      setEditing(false);
    }
  };
  let childNode = children;
  if (editable) {
    const ComponentEditable = {
      inputTextArea: (
        <TextArea
          className="editable-cell-edit-wrap"
          onPressEnter={save}
          onBlur={save}
          onKeyDown={handleOnPressEsc}
          autoSize={autoSize}
        />
      ),
      checkbox: (
        <Checkbox value={record[dataIndex]} disabled={record.disabled} onChange={save} />
      ),
      asyncSelect: (
        <AsyncSelect
          className="editable-cell-edit-wrap"
          listAction={manufacturerApi.list}
          mapFunction={mapDropdownOptions}
          onChange={save}
          value={{ value: record[dataIndex], label: record[dataIndex] }}
          disabled={record.disabled}
        />
      ),
      select: (
        <Select
          className="editable-cell-edit-wrap"
          key={`select-component${dataIndex}`}
          onChange={save}
          value={{ value: record[dataIndex], label: record[dataIndex] }}
          options={options}
          onAddClick={handleNewGroup}
        />
      ),
      input: (
        <Input
          autoFocus={dataIndex === 'model'}
          className="editable-cell-edit-wrap"
          onPressEnter={save}
          onBlur={save}
          onKeyDown={handleOnPressEsc}
        />
      ),
      inputNumber: (
        <InputNumber
          autoFocus
          className="editable-cell-edit-wrap"
          min={dataIndex === 'price' ? 0 : 1}
          step={dataIndex === 'price' ? 0.1 : 1}
          onPressEnter={save}
          onBlur={save}
          onKeyDown={handleOnPressEsc}
        />
      ),
      treeSelect: (
        <TreeSelect
          className="editable-cell-edit-wrap"
          tagRender={tagRender}
          treeData={record.productOptions}
          onChange={save}
          value={record[dataIndex]}
          multiple
          repeatedValues
          oneChild
        />
      )
    };
    childNode = editing || !type.includes('inputNumber') ? (
      <Form.Item
        style={styleEditable}
        name={dataIndex}
      >
        {ComponentEditable[type]}
      </Form.Item>
    ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        { children }
      </div>
    );
  }

  return <td {...restProps}>{ childNode }</td>;
};
export default EditableCell;
