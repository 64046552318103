import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import VerticalLayout from 'components/base-components/VerticalLayout';
import hasPermissions from 'helpers/permissons';
import { PermissionLabels } from 'constants/permissions';
import ModalRouter from 'components/base-components/ModalRouter';

interface Props {
  path: string;
  location?: string;
  component: any;
  isAuthProtected?: boolean;
  isAuthenticated?: boolean;
  permissions?: PermissionLabels[];
}

const AppRoute: FunctionComponent<Props> = (props) => {
  const {
    component: Component,
    isAuthProtected,
    permissions,
    location,
    ...rest
  } = props;

  const { isAuthenticated } = useSelector(({ login }: any) => ({
    isAuthenticated: login.isAuthenticated,
    userPermissions: login.permissions,
  }));

  const RouteComponent = useCallback((routeProps) => {
    if (!isAuthProtected) {
      return (
        <>
          <Component {...routeProps} />
          <ModalRouter />
        </>
      );
    }

    if (!isAuthenticated) {
      return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
    }

    if (permissions) {
      if (hasPermissions(permissions)) {
        return (
          <VerticalLayout>
            <Component {...routeProps} location={location} />
            <ModalRouter />
          </VerticalLayout>
        );
      }
      return <Redirect to={{ pathname: '/dashboard' }} />;
    }
    if (rest.path.includes('builder')) {
      return (
        <>
          <Component {...routeProps} />
          <ModalRouter />
        </>
      );
    }

    return (
      <VerticalLayout>
        <Component {...routeProps} />
        <ModalRouter />
      </VerticalLayout>
    );
  }, [isAuthProtected, permissions, isAuthenticated, location, rest.path]);

  return (
    <Route {...rest} render={RouteComponent} />
  );
};

export default AppRoute;
