import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import RenderIf from 'components/base-components/RenderIf';
import NumberInput from 'components/base-components/NumberInput';
import useTermFormState from './state';
import validationRules from './state/validations';

export interface TermFormHistorySateModel {
  termForm: {
    termUrl: string;
  };
}

export default function TermForm() {
  const { goBack, location: { state } } = useHistory();

  const { termForm: { termUrl } } = state as TermFormHistorySateModel;

  const {
    state: {
      term,
      savingTerm,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveTerm,
      setErrors,
    },
  } = useTermFormState(termUrl);

  const { totalPercentage } = errors;
  const modalTitle = useMemo(() => termUrl ? 'Edit Term' : 'Create Term', [termUrl]);

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveTerm}
      confirmLoading={savingTerm}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={term}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            type="text"
            id="termsName"
            name="termsName"
            label="Name"
            placeholder="Enter Term name"
            disabled={!!term && !!term.url}
          />
          <Field
            type="textarea"
            id="termsDetails"
            name="termsDetails"
            label="Details"
            placeholder="Enter Term details"
          />
          <Field
            component={NumberInput}
            name="days"
            id="days"
            label="Days"
            placeholder="Enter days"
            min={0}
          />
          <Field
            component={NumberInput}
            name="onOrderConversion"
            id="onOrderConversion"
            label="On Order Conversion"
            placeholder="Enter percentage"
            error={totalPercentage}
            allowDecimals
          />
          <Field
            component={NumberInput}
            name="onProductionCommencing"
            id="onProductionCommencing"
            label="On Production Commencing"
            placeholder="Enter percentage"
            error={totalPercentage}
            allowDecimals
          />
          <Field
            component={NumberInput}
            name="onLeavingForDelivery"
            id="onLeavingForDelivery"
            label="On Leaving For Delivery"
            placeholder="Enter percentage"
            error={totalPercentage}
            allowDecimals
          />
          <Field
            component={NumberInput}
            name="onInstallComplete"
            id="onInstallComplete"
            label="On Install Complete"
            placeholder="Enter percentage"
            error={totalPercentage}
            allowDecimals
          />
          <Field
            component={NumberInput}
            name="onOrderCompletion"
            id="onOrderCompletion"
            label="On Order Completion"
            placeholder="Enter percentage"
            error={totalPercentage}
            allowDecimals
          />
        </Form>
      </RenderIf>
    </Modal>
  );
}
