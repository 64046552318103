const commonTextures = [
  {
    label: 'Oak',
    texture: 'Blueprint3D-assets/textures/163 Aged Oak.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'Mahogany',
    texture: 'Blueprint3D-assets/textures/Mahogany-w365.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'W155',
    texture: 'Blueprint3D-assets/textures/W155.jpg',
    width: 35,
    height: 35,
  },
];

const commonGrommetStyles = [
  {
    label: 'NG',
    name_in_model: '',
  },
  {
    label: 'LG',
    name_in_model: 'grommet-lg',
  },
  {
    label: 'CG',
    name_in_model: 'grommet-cg',
  },
  {
    label: 'RG',
    name_in_model: 'grommet-rg',
  },
  {
    label: 'LRG',
    name_in_model: 'grommet-lrg',
  },
];

const scpGrommetStyles = [
  {
    label: 'NG',
    name_in_model: ',desk-top',
  },
  {
    label: 'LG',
    name_in_model: 'grommet-lg,desk-top',
  },
  {
    label: 'CG',
    name_in_model: 'grommet-cg,desk-top',
  },
  {
    label: 'RG',
    name_in_model: 'grommet-rg,desk-top',
  },
  {
    label: 'LRG',
    name_in_model: 'grommet-lrg,desk-top',
  },
];

const cogGrommetStyles = [
  {
    label: 'NG',
    name_in_model: '',
  },
  {
    label: 'COG',
    name_in_model: 'grommet-cog',
  },
];

const cogScpGrommetStyles = [
  {
    label: 'NG',
    name_in_model: ',desk-top',
  },
  {
    label: 'COG',
    name_in_model: 'grommet-cog,desk-top',
  },
];

const data = {
  category: 'WS',
  styles: [
    {
      name: 'WS-120',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-ecul.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-120.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 108 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '2SCP',
              name_in_model: 'grommet-2scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-5SC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/br-rb.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-5SC.glb',
      type: '1',
      stackontop: true,
      morph: [],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '2SCP',
              name_in_model: 'grommet-2scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-ARKL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-arkl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-ARKL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-ARKR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-arkr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-ARKR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-BF',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-bf.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-BF.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-BOOML',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-booml.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-BOOML.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-BOOMR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-boomr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-BOOMR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-BOOMRTNL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-bml.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-BOOMRTNL.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '1SCP',
              name_in_model: 'grommet-1scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-BOOMRTNR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-bmr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-BOOMRTNR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '1SCP',
              name_in_model: 'grommet-1scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-DTL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-dtl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-DTL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-DTR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-dtr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-DTR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-ECUL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-ecul.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-ECUL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '2SCP',
              name_in_model: 'grommet-2scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-ECUR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-ecur.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-ECUR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '2SCP',
              name_in_model: 'grommet-2scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-JTL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-jtl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-JTL.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-JTR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-jtr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-JTR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-PTL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-ptl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-PTL.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-PTR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/pdesk-ptr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-PTR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-RC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-wl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-RC.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 36, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 108 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '2SCP',
              name_in_model: 'grommet-2scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-RFF',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-rff.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-RFF.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...scpGrommetStyles,
            {
              label: '1SCP',
              name_in_model: 'grommet-1scp',
            },
            {
              label: '2SCP',
              name_in_model: 'grommet-2scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-RRF',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/desk-bf.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-RRF.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 36, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: commonGrommetStyles,
        },
      ],
    },
    {
      name: 'WS-RRTN',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/br-rb.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-RRTN.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 30, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '1SCP',
              name_in_model: 'grommet-1scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-WC',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/br-rb.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-WC.glb',
      type: '1',
      stackontop: true,
      morph: [],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '2SCP',
              name_in_model: 'grommet-2scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-WRTNL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-wl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-WRTNL.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '1SCP',
              name_in_model: 'grommet-1scp',
            },
          ],
        },
      ],
    },
    {
      name: 'WS-WRTNR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-wr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/WS-WRTNR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
      ],
      styles: [
        {
          label: 'Grommet Style',
          name_in_model: 'grommet,desk-top',
          types: [
            ...cogScpGrommetStyles,
            {
              label: '1SCP',
              name_in_model: 'grommet-1scp',
            },
          ],
        },
      ],
    },
  ],
};

export default data;
