/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import i18nConfig from 'i18n_config';
import { useHelpActions } from 'components/providers/Help';
import RenderIf from 'components/base-components/RenderIf';
import logo from 'assets/images/tfg-logo.svg';
import chairLogo from 'assets/images/tfg-chair-logo.png';
import LanguageDropdown from './LanguageDropdown';
import ProfileMenu from './ProfileMenu';
import Button from '../Button';

interface Props {
  toggleMenu: () => void;
}

const Header: FunctionComponent<Props> = (props) => {
  const { toggleMenu } = props;
  const { openModal } = useHelpActions();

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={chairLogo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="56" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={toggleMenu}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex align-items-center">
            <Button
              type="text"
              icon="fa fa-question"
              onClick={openModal}
            />
            <RenderIf isTrue={i18nConfig.showSelector}>
              <LanguageDropdown />
            </RenderIf>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
