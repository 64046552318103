export default function getEventValue(event) {
  if (event) {
    if (event.target) {
      const keys = ['value', 'checked', 'files'];
      const valueKey = keys.find((key) => event.target[key] !== undefined);
      return event.target[valueKey];
    }

    return event;
  }
  return undefined;
}
