import axios, { AxiosResponse } from 'axios';
import { QboSyncModel } from 'models/qbo-sync';
import { ApiContentType, ApiModel, get } from './base';
import getApi from './base/get-api';

const { REACT_APP_ACCOUNTING_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTING_API}/items/`;

interface QBOSyncApiModel extends ApiModel<QboSyncModel> {
  getTokenTypes: (account) => Promise<AxiosResponse>;
  getOption: () => Promise<AxiosResponse | void>;
}
const qboSyncApi: QBOSyncApiModel = {
  ...getApi(endpoint, ApiContentType.JSON, true),
  list(params?) {
    return get(`${endpoint}unprocessed/`, params);
  },
  getTokenTypes() {
    return get(`${endpoint}token-types/`);
  },
  getOption() {
    return axios.options(`${endpoint}?page=1`);
  }
};

export default qboSyncApi;
