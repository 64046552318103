import { ShipmentModel } from 'models/shipment';
import { Answers, initialAnswers } from '../../types';
import { QuestionnaireActions } from '../reducer';

function mapAnswersToEnum(fields) {
  return Object.entries(fields).reduce((collection, field) => {
    const [key, value] = field;

    if (typeof value === 'boolean') {
      return { ...collection, [key]: value ? Answers.Yes : Answers.No };
    }

    return { ...collection, [key]: value };
  }, {});
}

export default function initFormData(
  dispatch,
  shipment: ShipmentModel,
) {
  const {
    contact: shippingContact,
    shippingAddress,
    shippingQuestionnaire,
  } = shipment;
  const { province, ...rest } = shippingAddress || {};

  const address = {
    ...rest,
    province: province ? { value: province.id, label: province.name } : undefined,
  };
  const country = province
    ? { value: province.country.id, label: province.country.country }
    : undefined;

  const answers = (
    shippingQuestionnaire ||
    { ...initialAnswers }
  );

  const {
    specificHoursStart,
    specificHoursEnd,
    stairs,
    accessMethod,
    ...otherAnswers
  } = answers;
  const mappedAnswers = mapAnswersToEnum(otherAnswers);

  dispatch({
    type: QuestionnaireActions.INIT_FORM,
    payload: {
      answers: {
        ...mappedAnswers,
        specificHoursStart,
        specificHoursEnd,
        stairs,
        accessMethod,
      },
      addressInfo: address,
      contactInfo: shippingContact || {},
      selectedCountry: country,
    },
  });
}
