import { TermModel } from 'models/term';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import termApi from 'api/term';
import { EventChannelList } from 'helpers/event-center';
import { useEffect, useMemo } from 'react';
import notification, { NotificationType } from 'helpers/notification';
import getPayload from './actions/get-payload';
import getFormData from './actions/get-form-data';
import validationRules from './validations';

export default function useTermFormState(termUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: termApi,
    eventChannel: EventChannelList.TERMS_LIST_CHANGED,
    param: termUrl,
    getPayload,
    getFormData,
    validationRules,
  }), [termUrl]);

  const {
    state: {
      formData: term,
      errors,
      saving,
      errorFetchingEntity: errorFetchingTerm,
      fetchingEntity: fetchingTerm,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<TermModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingTerm) {
      notification({ type: NotificationType.ERROR, message: errorFetchingTerm });
    }
  }, [errorFetchingTerm]);

  return {
    state: {
      term,
      savingTerm: saving,
      errors,
      fetchingFormData: fetchingTerm,
    },
    actions: {
      setFormValue,
      setErrors,
      saveTerm: saveEntity,
    },
  };
}
