import { InventoryModel } from 'models/inventory';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/inventory/`;

const inventoryApi: ApiModel<InventoryModel> = {
  ...getApi<InventoryModel>(endpoint, ApiContentType.MULTIPART),
};

export default inventoryApi;
