import moment from 'moment';

export default function generateDateRange(date: Date, isBooking?: boolean) {
  const today = moment();
  const panelDate = moment(date).startOf('month').startOf('week');
  const firstDay = panelDate.format('YYYY-MM-DD');
  const lastDay = panelDate.clone().add(41, 'days').format('YYYY-MM-DD');
  if (isBooking) {
    if (today.diff(panelDate.clone().add(41, 'days'), 'day') > 0) {
      return false;
    }
    if (panelDate.diff(today, 'day') < 0) {
      return `${today.format('YYYY-MM-DD')},${lastDay}`;
    }
  }
  return `${firstDay},${lastDay}`;
}
