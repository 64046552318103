import React from 'react';
import Tag from 'components/base-components/Tag';

export default function statusTemplate(data) {
  const { status } = data;

  const colors = {
    pending: 'warning',
    approved: 'success',
    denied: 'error',
  };

  return <Tag color={colors[status]} label={status} />;
}
