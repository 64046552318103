import React from 'react';
import { Card as CardProps } from '.';
import Card from '../Card';
import Button from '../Button';
import './styles.scss';

export interface CardBag{
  dragging: any;
  removeCard: () => any;
}

const CardKanban = (card: CardProps, { dragging }: CardBag, openEdit) => {
  const editButton = (<Button type="default" icon="fas fa-pen" onClick={() => openEdit(card)} mL />);
  return (
    <div draggable onDrag={dragging} className="wrapper-card">
      <Card
        bordered={false}
        title={card.title}
        extra={editButton}
        style={{ width: '100%' }}
      >
        {card.description}
      </Card>
    </div>
  );
};

export default CardKanban;
