import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { BuilderFormModel } from 'components/pages/Builder/state';
import { OptionDropdownModel } from '../reducer';

export function updateModelTextures(
  model: BuilderFormModel,
  option: OptionDropdownModel,
) {
  const { optionGroups } = model;

  optionGroups.filter((group) => group.mode === 'texture')
    .forEach((group) => {
      const { name, nameInModel, mode } = group;
      const { image, textureHeight, textureWidth } = option;

      const payload = {
        name,
        nameInModel,
        groupNameInModel: nameInModel,
        mode,
        image,
        textureHeight,
        textureWidth,
      };

      notifyEventChannel(
        EventChannelList.BUILDER_PRODUCT_OPTION_CHANGED,
        payload,
      );
    });
}
