import React from 'react';
import { formatCurrency } from 'helpers/formatting';

export function renderBalance(value) {
  return (
    <div className={value > 0 ? 'order-list__balance-column' : ''}>
      {formatCurrency(value)}
    </div>
  );
}
