import { QuoteModel } from 'models/quote';
import quoteApi from 'api/quote';
import orderApi from 'api/orders';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { QuoteViewActions } from '../reducer';

export default function printQuote(dispatch, quote: QuoteModel, asProposal) {
  return async () => {
    dispatch({ type: QuoteViewActions.START_PRINTING });
    const { id, isconverted, orderNum } = quote;

    const onError = (error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    };

    const onFinally = () => {
      dispatch({ type: QuoteViewActions.STOP_PRINTING });
    };

    const print = isconverted
      ? orderApi.print
      : quoteApi.print;

    const action = asProposal
      ? quoteApi.printProposal
      : print;

    const payload = isconverted && !asProposal
      ? parseInt(orderNum.split('-')[1], 10) : id;

    return action(payload)
      .catch(onError)
      .finally(onFinally);
  };
}
