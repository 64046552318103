import { AxiosResponse } from 'axios';
import { CategoryModel } from 'models/category';
import { ApiContentType, ApiModel, get, post } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/category/`;

interface CategoryApiModel extends ApiModel<CategoryModel> {
  getTopLevel: () => Promise<AxiosResponse<CategoryModel>>;
  getTopLevelList: () => Promise<AxiosResponse<string[]>>;
  getOptionsByCategory: (categories: {categories: string[]}) => Promise<AxiosResponse<{[x: string]: string[]}>>;
  generate: (payload: {
    category: string;
    optionsUpdate: boolean;
  }) => Promise<AxiosResponse>;
}

const categoryApi: CategoryApiModel = {
  ...getApi<CategoryModel>(endpoint, ApiContentType.MULTIPART),
  getTopLevel() {
    return get(`${endpoint}toplevel/`);
  },
  getTopLevelList() {
    return get(`${endpoint}toplevel-list/`);
  },
  getOptionsByCategory(categories) {
    return post(`${endpoint}options/`, categories);
  },
  generate(payload) {
    return post(`${endpoint}generate/`, payload);
  },
};

export default categoryApi;
