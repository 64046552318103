import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import useCharts from 'hooks/ui/charts';
import { capitalizeFirstLetter } from 'helpers/formatting';
import { Chart, Dataset } from 'components/base-components/Chart';
import Switch from 'components/base-components/Switch';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import DataTable from 'components/base-components/DataTableV2';
import Column from 'components/base-components/DataTableV2/Column';
import Divider from 'components/base-components/Divider';
import useIncomeCustomer from './state';
import FiltersForm from '../FiltersForm';
import '../../styles.scss';

const columnsList = ['name', 'total', 'orders'];

const CustomerIncome: FunctionComponent = () => {
  const {
    state: {
      incomeCustomerParams,
      formIncomeCustomerData
    },
    actions: { setIncomeCustomerValue }
  } = useIncomeCustomer();
  const [isChart, setIsChart] = useState(true);
  const style = { maxWidth: isChart ? '1500px' : '100%' };
  const handleIsChart = useCallback((checked) => { setIsChart(checked); }, []);
  const {
    fetching,
    barDataSets,
    labels,
    data
  } = useCharts(incomeCustomerParams);

  const datasets = useMemo(() => barDataSets.map(({ title, values, borderColor }) => (
    <Dataset
      key={title}
      title={title}
      values={values}
      borderColor={borderColor}
      backgroundColor={borderColor}
    />
  )), [barDataSets]);

  const columns = useMemo(() => columnsList.map((column) => (
    <Column title={capitalizeFirstLetter(column)} dataIndex={column} key={column} />
  )), []);

  return (
    <div className="chart-col">
      <FiltersForm
        onChange={setIncomeCustomerValue}
        state={formIncomeCustomerData}
        baseKey="CustomerIncome"
      />
      <Row justify="end">
        <Col span={24} style={style}>
          <div className="d-flex justify-content-end">
            <RenderIf isTrue={!fetching || !isChart}>
              <Switch
                checked={isChart}
                onChange={handleIsChart}
                label={`Change to ${isChart ? 'Table' : 'Chart'}`}
                reverse
              />
            </RenderIf>
            <RenderIf isTrue={fetching && isChart}>
              <Spinner className="spinner" centered={false} />
            </RenderIf>
          </div>
          <RenderIf isTrue={isChart}>
            <Chart labels={labels} type="bar">
              {datasets}
            </Chart>
          </RenderIf>
          <RenderIf isTrue={!isChart}>
            <DataTable
              loading={fetching}
              dataSource={data}
              size="middle"
              className="data-table"
            >
              {columns}
            </DataTable>
          </RenderIf>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default CustomerIncome;
