import { ShipmentDTO } from 'models/shipment';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { UpdateShippingHistorySateModel } from '../../Form/UpdateShipping';
import { AccountDropdownModel } from '../reducer';

export default function openUpdateShipmentModal(push, shipment: ShipmentDTO, account: AccountDropdownModel) {
  return () => {
    const historyLocationState: UpdateShippingHistorySateModel = {
      updateShippingForm: {
        shipmentId: shipment.id,
        accountAddress: {
          city: account.businessCity,
          postalCode: account.businessPostCode,
          street: account.businessAddress
        }
      },
    };

    push(ModalRouteHash.UpdateShipping, historyLocationState);
  };
}
