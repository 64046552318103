import { resolveApiErrorMessage } from 'api/base';
import orderProductApi from 'api/order-product';
import { QuoteModel } from 'models/quote';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { CopyProductFormActions } from '../reducer';
import { getProductPayload } from './get-payload';

async function saveProduct(orderProduct) {
  const promises = orderProduct.map((product) => orderProductApi.create(product));
  return Promise.all(promises);
}

export function copyProduct(
  dispatch,
  formData: {group: DropdownOptionModel},
  quote: QuoteModel,
  productSelected: number[],
  goBack
) {
  return () => {
    dispatch({ type: CopyProductFormActions.START_COPPING });
    const payload = getProductPayload(quote, formData.group.value, productSelected);

    const onSuccess = () => {
      notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED, true);
      const message = 'Products Successfully Copied';
      notification({ type: NotificationType.SUCCESS, message });
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CopyProductFormActions.FINISH_COPPING });
    };

    return saveProduct(payload)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
