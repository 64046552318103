import React, { FunctionComponent } from 'react';
import { OrderProductModel } from 'models/order';
import ProductForm from './ProductForm';

interface Props {
  products: OrderProductModel[];
  errors: { [productId: string]: { [option: string]: string } };
  missingOptions: { [productId: string]: string[] };
  onUpdateProduct: (productId: number, options) => void;
}

const ProductsSection: FunctionComponent<Props> = (props): any => {
  const { products, errors, onUpdateProduct, missingOptions } = props;

  return products.map(product => (
    <ProductForm
      key={product.id}
      product={product}
      errors={errors[product.id]}
      missing={missingOptions[product.id]}
      onUpdateProduct={onUpdateProduct}
    />
  ));
};

export default ProductsSection;
