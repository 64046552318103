import { ThreeFormActions } from '../reducer';

export default function setErrors(dispatch) {
  return (errors) => {
    dispatch({
      type: ThreeFormActions.SET_ERRORS,
      payload: errors,
    });
  };
}
