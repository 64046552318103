import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import { AccountApplicationModel } from 'models/account-application';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/accounts-app/`;

const accountApplicationApi: ApiModel<AccountApplicationModel> =
  getApi<AccountApplicationModel>(endpoint, ApiContentType.MULTIPART);

export default accountApplicationApi;
