/* eslint-disable jsx-a11y/media-has-caption */
import React, { FunctionComponent } from 'react';

interface Props {
  url: string;
}

const Video: FunctionComponent<Props> = (props) => {
  const { url } = props;

  return (
    <div className="tutorials-player">
      <video width="100%" key={url} controls autoPlay>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
