import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import { AccountTaxModel } from 'models/account';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/tax/`;

const taxApi: ApiModel = getApi<AccountTaxModel>(endpoint, ApiContentType.MULTIPART);

export default taxApi;
