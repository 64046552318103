import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import { Radio as AntRadio } from 'antd';
import { RadioProps } from 'antd/lib/radio';
import './styles.scss';

interface Props extends RadioProps {
  id?: string;
  name?: string;
  label: ReactNode;
  expected: any;
  value: any;
  onChange: (event) => void;
  autoFocus?: boolean;
  mR?: boolean;
  className?: string;
}

const Radio: FunctionComponent<Props> = (props) => {
  const { label, expected, value, mR, className, ...rest } = props;
  const radioClassName = classnames('radio', { mR }, className);

  return (
    <AntRadio
      {...rest}
      className={radioClassName}
      value={expected}
      checked={isEqual(value, expected)}
    >
      {label}
    </AntRadio>
  );
};

export default Radio;
