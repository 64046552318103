import { Moment } from 'moment';
import { InstallerBookingDTO } from 'models/project-coordinator';
import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum installerFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_DATA = 'SET_DATA',
  UPDATE_DATA = 'UPDATE_DATA',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_SAVING = 'START_SAVING',
  FINISH_SAVING = 'FINISH_SAVING',
}

export interface InstallerFormModel {
  id?: number;
  installationDate: Moment;
  installer: DropdownOptionModel;
  projectId: number;
}

export interface InstallerFormState {
  formData: InstallerFormModel;
  installerSelected: number;
  installersBooking: InstallerBookingDTO[];
  saving: boolean;
  errors: any;
}

export default function installerFormReducer(
  state: InstallerFormState,
  action
): InstallerFormState {
  const { type, payload } = action;

  switch (type as installerFormActions) {
    case installerFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload, installerSelected: payload.id };
    case installerFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, saving: false };
    case installerFormActions.SET_DATA:
      return { ...state, installersBooking: payload };
    case installerFormActions.UPDATE_DATA:
      return { ...state, installersBooking: state.installersBooking.concat(payload) };
    case installerFormActions.START_SAVING:
      return { ...state, saving: true };
    case installerFormActions.FINISH_SAVING:
      return { ...state, saving: false };
    default:
      return state;
  }
}
