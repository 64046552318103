import { AxiosResponse } from 'axios';
import { OrderModel } from 'models/order';
import orderApi from 'api/orders';
import calendarEventApi from 'api/calendar-event';
import installerBookingApi from 'api/installer-booking';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { CalendarEvent, EventItemType, } from '../reducer';
import getPayload from './get-payload';

const actionMap = {
  [EventItemType.ORDER]: orderApi.patch,
  [EventItemType.BOOKING]: installerBookingApi.patch,
  [EventItemType.CUSTOM]: calendarEventApi.patch,
};

const eventNameMap = {
  [EventItemType.BOOKING]: 'installer booking',
  [EventItemType.ORDER]: 'order',
  [EventItemType.CUSTOM]: 'custom event',
};

export default function handleOnDrop({ event, revert }) {
  const { extendedProps: { type } } = event as CalendarEvent;
  const payload = getPayload(event);

  const onSuccess = (response: AxiosResponse<OrderModel>) => {
    notifyEventChannel(EventChannelList.PRODUCTION_MANAGER_DROPPED, response);
  };

  const onError = () => {
    revert();
    notification({
      type: NotificationType.ERROR,
      message: `Failed to update the ${eventNameMap[type]}`,
    });
  };

  const action = actionMap[type];

  return action(payload)
    .then(onSuccess)
    .catch(onError);
}
