import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum ConvertToOrderActions {
  INITIALIZE_PRODUCTS_VALUES = 'INITIALIZE_PRODUCTS_VALUES',
  SET_PRODUCT_VALUES = 'SET_PRODUCT_VALUES',
  SET_PROJECT = 'SET_PROJECT',
  SET_ZONE = 'SET_ZONE',
  SET_ADDRESS_INFO = 'SET_ADDRESS_INFO',
  SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY',
  START_FETCHING_HASH = 'START_FETCHING_HASH',
  STOP_FETCHING_HASH = 'STOP_FETCHING_HASH',
  START_UPDATING_FIELDS = 'START_UPDATING_FIELDS',
  STOP_UPDATING_FIELDS = 'STOP_UPDATING_FIELDS',
  SET_PRODUCTS_ERRORS = 'SET_PRODUCTS_ERRORS',
  SET_PROJECT_ERROR = 'SET_PROJECT_ERROR',
  SET_ZONE_ERROR = 'SET_ZONE_ERROR',
  SET_ADDRESS_ERRORS = 'SET_ADDRESS_ERRORS',
  RESET_STATE = 'RESET_STATE',
}

export interface FormAddressModel {
  id: number;
  locationName: string;
  streetNumber: number;
  streetName: string;
  unitNumber: number;
  postalCode: string;
  city: string;
  province: DropdownOptionModel;
}

export interface ConvertToOrderState {
  productsFormData: { [s: string]: { [x: string]: DropdownOptionModel } };
  shipmentFormData: {
    project?: DropdownOptionModel;
    zone?: DropdownOptionModel;
    address?: FormAddressModel;
  };
  selectedCountry: DropdownOptionModel;
  fetchingHash: boolean;
  projectError: string;
  zoneError: string;
  productErrors: any;
  addressErrors: any;
  updatingQuoteFields: boolean;
}

export default function convertToOrderReducer(
  state: ConvertToOrderState,
  action,
): ConvertToOrderState {
  const { type, payload } = action;

  switch (type as ConvertToOrderActions) {
    case ConvertToOrderActions.INITIALIZE_PRODUCTS_VALUES:
      return { ...state, productsFormData: payload, productErrors: payload };
    case ConvertToOrderActions.SET_PRODUCT_VALUES:
      return { ...state, productsFormData: { ...state.productsFormData, ...payload } };
    case ConvertToOrderActions.SET_PROJECT:
      return { ...state, shipmentFormData: { ...state.shipmentFormData, project: payload } };
    case ConvertToOrderActions.SET_ZONE:
      return { ...state, shipmentFormData: { ...state.shipmentFormData, zone: payload } };
    case ConvertToOrderActions.SET_ADDRESS_INFO:
      return { ...state, shipmentFormData: { ...state.shipmentFormData, address: payload } };
    case ConvertToOrderActions.SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: payload,
      };
    case ConvertToOrderActions.START_FETCHING_HASH:
      return { ...state, fetchingHash: true };
    case ConvertToOrderActions.STOP_FETCHING_HASH:
      return { ...state, fetchingHash: false };
    case ConvertToOrderActions.SET_PRODUCTS_ERRORS:
      return { ...state, productErrors: payload };
    case ConvertToOrderActions.SET_PROJECT_ERROR:
      return { ...state, projectError: payload };
    case ConvertToOrderActions.SET_ZONE_ERROR:
      return { ...state, zoneError: payload };
    case ConvertToOrderActions.SET_ADDRESS_ERRORS:
      return { ...state, addressErrors: payload };
    case ConvertToOrderActions.START_UPDATING_FIELDS:
      return { ...state, updatingQuoteFields: true };
    case ConvertToOrderActions.STOP_UPDATING_FIELDS:
      return { ...state, updatingQuoteFields: false };
    case ConvertToOrderActions.RESET_STATE:
      return payload;
    default:
      return state;
  }
}
