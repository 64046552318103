import { ApiModel } from 'api/base';
import { EventChannelList } from 'helpers/event-center';
import { validateEntity, ValidationRules } from 'helpers/form-validations';
import { FormActions } from '../reducer';
import { saveEntity } from './save-entity';

export interface SubmitFormParams {
  dispatch: any;
  api: ApiModel;
  formData: any;
  eventChannel: EventChannelList;
  getPayload: (entity: any) => any;
  goBack: () => void;
  validationRules?: ValidationRules;
  onSaveSuccess?: (response?) => void;
  noGoBackOnSuccess?: boolean;
  clearOnSuccess?: boolean;
  replaceAction: () => any;
  pK?: string;
  getFormData: () => any;
}

export function handleOnSubmit(params: SubmitFormParams) {
  return () => {
    const {
      dispatch,
      formData,
      validationRules,
    } = params;

    if (!validationRules) {
      saveEntity(params);
      return;
    }

    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      dispatch({ type: FormActions.SET_ERRORS, payload: errors });
    } else {
      saveEntity(params);
    }
  };
}
