import React from 'react';
import classnames from 'classnames';
import { Row } from 'antd';
import ReactQuill, { Quill } from 'react-quill';
import Label from 'components/base-components/Label';
import InputError from 'components/base-components/InputError';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import 'react-quill/dist/quill.snow.css';
import './styles.scss';

interface Props extends Quill{
  id?: string;
  label?: string;
  className?: string;
  mR?: boolean;
  required?: boolean;
  error?: string;
  loading?: boolean;
}

const TextEditor: (props: Props) => JSX.Element = (props) => {
  const {
    label,
    id,
    className,
    mR,
    required,
    error,
    loading,
    ...inputProps
  } = props;
  const wrapperClassNames = classnames('text-editor__wrapper', { mR, error }, className);

  return (
    <div className={wrapperClassNames}>
      <Row>
        <Label text={label} forInput={id} required={required} />
        <RenderIf isTrue={loading}>
          <Spinner className="spinner" centered={false} />
        </RenderIf>
      </Row>
      <ReactQuill
        id={id}
        aria-disabled
        className="text-editor"
        {...inputProps}
      />
      <InputError error={error} />
    </div>
  );
};

export default TextEditor;
