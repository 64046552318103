import { useCallback, useEffect, useMemo, useReducer } from 'react';
import categoryApi from 'api/category';
import { OptionGroupModel } from 'models/option-group';
import { ThreeModel } from 'models/three-model';
import { CategoryModel } from 'models/category';
import useCallApiAction from 'hooks/base/call-api-action';
import {
  DropdownOptionModel,
  mapGenericDropdownOptions,
  mapTreeSelectOptionSimple
} from 'helpers/dropdown-options';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import getPayload from './actions/get-payload';
import getFormData from './actions/get-form-data';
import { loadTreeSelectData } from './actions/load-tree-select-data';
import categoryFormReducer, { CategoryFormActions } from './reducer';

export * from './validations';

export function mapOptionGroups(optionGroups: OptionGroupModel[]) {
  return mapGenericDropdownOptions(optionGroups, { fields: { value: 'url', label: 'name' } });
}
export function mapThreeModel(threeModel: ThreeModel[]) {
  return mapGenericDropdownOptions(threeModel, { fields: { value: 'url', label: 'modelName' } });
}

export interface CategoryFormModel {
  name: string;
  nameFr: string;
  description: string;
  descriptionFr: string;
  parent: DropdownOptionModel;
  navBar: boolean;
  status: boolean;
  outsidePaint: boolean;
  cnc: boolean;
  optionGrp: DropdownOptionModel[];
  image: File[];
  categories: any;
  stackable: boolean;
  overlapable: boolean;
  levelDescription: string;
  threeModel: DropdownOptionModel;
  modelName: string;
  stackontop: boolean;
}

export default function useCategoryFormState(categoryUrl: string) {
  const [state, dispatch] = useReducer(
    categoryFormReducer,
    {
      parentTreeData: [],
      categoriesTreeData: [],
    },
  );

  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: categoryApi,
    eventChannel: EventChannelList.CATEGORIES_LIST_CHANGED,
    param: categoryUrl,
    defaultSortField: '-created_at',
    getPayload,
    getFormData,
  }), [categoryUrl]);

  const {
    state: {
      formData: category,
      errors,
      saving,
      errorFetchingEntity: errorFetchingCategory,
      fetchingEntity: fetchingCategory,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<CategoryModel>(useFormStateParams);

  const { parentTreeData, categoriesTreeData } = state;

  const {
    fetching: fetchingCategories,
    data: categories,
    error: fetchingCategoriesErrors,
  } = useCallApiAction(categoryApi.getTopLevel, '', []);

  useEffect(() => {
    if (categories.length > 0 && parentTreeData.length === 0 && categoriesTreeData.length === 0) {
      dispatch({
        type: CategoryFormActions.INITIALIZE_TREE_DATA,
        payload: mapTreeSelectOptionSimple(categories, 0, 'categories')
      });
    }
  }, [categories, parentTreeData.length, categoriesTreeData.length]);

  useEffect(() => {
    if (fetchingCategoriesErrors) {
      notification({ type: NotificationType.ERROR, message: fetchingCategoriesErrors });
    }
  }, [fetchingCategoriesErrors]);

  useEffect(() => {
    if (errorFetchingCategory) {
      notification({ type: NotificationType.ERROR, message: errorFetchingCategory });
    }
  }, [errorFetchingCategory]);

  const fetchingFormData = useMemo(() => fetchingCategories || fetchingCategory,
    [fetchingCategories, fetchingCategory]);

  return {
    state: {
      category,
      savingCategory: saving,
      parentTreeData,
      categoriesTreeData,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveCategory: saveEntity,
      setErrors,
      loadTreeSelectData: useCallback(loadTreeSelectData(dispatch), []),
    },
  };
}
