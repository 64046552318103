import { UpdateShippingActions } from '../reducer';

export function setAddress(dispatch) {
  return (addressInfo) => {
    dispatch({
      type: UpdateShippingActions.SET_ADDRESS,
      payload: { ...addressInfo, change: true },
    });
  };
}
