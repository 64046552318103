import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum CopyProductFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_GROUP_FOR_SELECT = 'SET_GROUP_FOR_SELECT',
  START_COPPING = 'START_COPPING',
  FINISH_COPPING = 'FINISH_COPPING',
}

interface CopyProductFormState {
  formData: {
    group: DropdownOptionModel;
  };
  groupForSelect: string [];
  coping: boolean;
  errors: any;
}

export default function copyProductFormReducer(
  state: CopyProductFormState,
  action
): CopyProductFormState {
  const { type, payload } = action;

  switch (type as CopyProductFormActions) {
    case CopyProductFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case CopyProductFormActions.SET_GROUP_FOR_SELECT:
      return { ...state, groupForSelect: [...new Set([...state.groupForSelect, payload])] };
    case CopyProductFormActions.START_COPPING:
      return { ...state, coping: true };
    case CopyProductFormActions.FINISH_COPPING:
      return { ...state, coping: false };
    default:
      return state;
  }
}
