export function getShipmentPayload({ change = false, ...shipment }) {
  return [
    {
      ...shipment,
      shippingAddress: shipment.shippingAddress?.id,
      shipmentType: shipment.shipmentType.value,
      shippingZone: shipment.shippingZone.value,
      shippingQuestionnaire: shipment.shippingQuestionnaire?.id,
      contact: shipment.contact?.id
    },
    change
  ];
}
export function getAddressPayload({ province, change = false, ...rest }) {
  return [
    {
      ...rest,
      province: province.value,
    },
    change
  ];
}
