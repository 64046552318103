/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import getEventValue from 'helpers/get-event-value';
import hasPermissions from 'helpers/permissons';
import Input from 'components/base-components/Input';
import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import { PermissionLabels } from 'constants/permissions';
import { AxiosResponse } from 'axios';
import classnames from 'classnames';
import getValue from './get-value';
import './styles.scss';

enum KeyType {
  Escape = 'Escape',
  Enter = 'Enter',
  Tab = 'Tab',
}

interface Props {
  id?: string;
  data: any;
  field: string;
  component?: any;
  onChange?: (value, setValue?) => void;
  onSubmit: (payload, field?) => Promise<AxiosResponse>;
  onSubmitSuccess?: (payload) => void;
  permissions?: PermissionLabels[];
  type?: string;
  placeholder?: string;
  alwaysShowInput?: boolean;
  render?: any;
  getPayload?: (data, newValue, field?) => any;
  [x: string]: any;
}

const EditInPlaceV2: FunctionComponent<Props> = (props) => {
  const {
    id,
    data,
    field,
    onChange,
    component,
    onSubmit,
    onSubmitSuccess,
    permissions,
    type,
    placeholder,
    className,
    alwaysShowInput,
    disabled,
    render,
    getPayload,
    ...rest
  } = props;

  const { options } = rest;

  const [value, setValue] = useState(getValue(data, field, options));
  const [prevValue, setPrevValue] = useState(getValue(data, field, options));
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleOnSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const newValue = options ? value.value : value;
      const payload = getPayload
        ? getPayload(data, newValue, field)
        : { ...data, [field]: newValue };

      const response = await onSubmit(payload, field);
      if (onSubmitSuccess) {
        onSubmitSuccess(response.data);
      }
      setPrevValue(value);
    } catch (error) {
      setValue(prevValue);
      const message = resolveApiErrorMessage((error as any)?.response);
      notification({ type: NotificationType.ERROR, message });
    } finally {
      setLoading(false);
      setEditing(false);
    }
  }, [data, field, value, onSubmit, prevValue, onSubmitSuccess, options, getPayload]);

  const handleChange = useCallback((event) => {
    if (onChange) {
      onChange(event, setValue);
    } else {
      const nextValue = getEventValue(event);
      setValue(nextValue);
    }
  }, [onChange]);

  const handleOnKeyDown = (event) => {
    const { key } = event;

    const shouldSubmit = (
      (
        key === KeyType.Tab ||
        (
          key === KeyType.Enter &&
          type !== 'textarea'
        )
      ) &&
      !isEqual(prevValue, value) &&
      !alwaysShowInput
    );
    if (shouldSubmit) {
      handleOnSubmit();
    } else if (key === KeyType.Escape) {
      setValue(prevValue);
      setEditing(false);
    }
  };

  const handleOnBlur = () => {
    if (!isEqual(data[field], value) && !alwaysShowInput) {
      handleOnSubmit();
    } else {
      setEditing(false);
    }
  };

  const isDisabled = useMemo(() => {
    return !hasPermissions(permissions) || disabled || loading;
  }, [permissions, disabled, loading]);

  const setToEditing = () => {
    if (!isDisabled) {
      setEditing(true);
    }
  };

  useEffect(() => {
    if (alwaysShowInput && !isEqual(prevValue, value)) {
      handleOnSubmit();
    }
  }, [alwaysShowInput, value, prevValue, handleOnSubmit]);

  useEffect(() => {
    const nextValue = getValue(data, field, options);

    if (nextValue && !value && !prevValue) {
      setValue(nextValue);
      setPrevValue(nextValue);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    const nextValue = getValue(data, field, options);

    if (nextValue !== value) {
      setPrevValue(value);
      setValue(nextValue);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [data]);

  if (alwaysShowInput || editing) {
    const Component = component || Input;
    const inputId = id;
    const classNames = classnames('edit-in-place__input-wrap', className);

    return (
      <Component
        id={inputId}
        name={field}
        value={value || ''}
        onChange={handleChange}
        onBlur={handleOnBlur}
        type={type}
        onKeyDown={handleOnKeyDown}
        autoFocus={!alwaysShowInput}
        placeholder={placeholder}
        disabled={isDisabled}
        loading={loading}
        {...rest}
        className={classNames}
      />
    );
  }

  const getLabel = () => {
    if (render) {
      return render(value, data);
    }
    if (options) {
      return value ? value.label : placeholder;
    }
    return value || placeholder;
  };

  const labelClassNames = classnames(
    'edit-in-place__value-wrap',
    {
      placeholder: getLabel() === placeholder,
      disabled: isDisabled,
    },
    className,
  );

  return (
    <span className={labelClassNames} onClick={setToEditing}>
      {getLabel()}
    </span>
  );
};

export default EditInPlaceV2;
