/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent, useMemo } from 'react';
import RenderIf from 'components/base-components/RenderIf';
import { StyleModel } from '../state';
import './styles.scss';

interface Props {
  styles: StyleModel[];
  onSelect: (style) => void;
  hiddenContent?: boolean;
}

const StylesPanel: FunctionComponent<Props> = (props) => {
  const { styles, onSelect, hiddenContent } = props;

  const stylesElements = useMemo(() => (
    (styles || []).map((style) => (
      <li
        key={style.name}
        onClick={() => onSelect(style)}
        className="builder__styles__style"
      >
        <div className="builder__styles__wrap">
          <img
            className="builder__styles__style-image"
            src={style.image || 'desk.png'}
            alt={style.name}
          />
          <p className="builder__styles__name-style">{style.name}</p>
        </div>
      </li>
    ))
  ), [onSelect, styles]);

  return (
    <footer className="builder__styles">
      <RenderIf isTrue={!hiddenContent}>
        <ul className="builder__styles__list">
          {stylesElements}
        </ul>
      </RenderIf>
    </footer>
  );
};

export default StylesPanel;
