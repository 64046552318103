import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum ApplyOptionsFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  SET_GROUP_CHECKED = 'SET_GROUP_CHECKED',
  SET_CHECK_ALL = 'SET_CHECK_ALL',
  START_EDITING = 'START_EDITING',
  FINISH_EDITING = 'FINISH_EDITING',
  SET_CUSTOM_OPTION = 'SET_CUSTOM_OPTION',
}

interface ApplyOptionsFormState {
  formData: {[x: string]: DropdownOptionModel};
  checkAll: boolean;
  checkedGroups: string[];
  editing: boolean;
  errors: any;
}

export default function applyOptionsFormReducer(
  state: ApplyOptionsFormState,
  action,
): ApplyOptionsFormState {
  const { type, payload } = action;

  switch (type as ApplyOptionsFormActions) {
    case ApplyOptionsFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case ApplyOptionsFormActions.SET_GROUP_CHECKED:
      return { ...state, checkedGroups: payload };
    case ApplyOptionsFormActions.SET_CHECK_ALL:
      return { ...state, checkAll: payload };
    case ApplyOptionsFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, editing: false };
    case ApplyOptionsFormActions.START_EDITING:
      return { ...state, editing: true };
    case ApplyOptionsFormActions.FINISH_EDITING:
      return { ...state, editing: false };
    case ApplyOptionsFormActions.SET_CUSTOM_OPTION: {
      const { formData } = state;
      const { option, parent } = payload;

      return {
        ...state,
        formData: {
          ...formData,
          [parent]: option,
        },
      };
    }
    default:
      return state;
  }
}
