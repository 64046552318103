import React, { FunctionComponent, ReactElement, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Dropdown as AntdDropdown, Menu } from 'antd';
import Button from '../Button';
import './styles.scss';

export interface DropdownItem {
  key: string;
  label: string;
  icon?: string;
  hidden?: boolean;
  onClick: () => void;
}

interface Props {
  label?: string;
  menu?: ReactElement;
  items?: DropdownItem[];
  placement?: (
    'bottomLeft' |
    'bottomCenter' |
    'bottomRight' |
    'topLeft' |
    'topCenter' |
    'topRight');
  trigger?: Array<'click' | 'hover' | 'contextMenu'>;
  arrow?: boolean;
  disabled?: boolean;
  visible?: boolean;
  onVisibleChange?: (e) => void;
  className?: string;
  mR?: boolean;
  mB?: boolean;
}

const Dropdown: FunctionComponent<Props> = (props) => {
  const { label, menu, items, children, className, mB, mR, ...rest } = props;

  const handleItemClick = useCallback((event) => {
    const clickedItem = items.find(({ key }) => event.key === key);

    if (clickedItem) {
      clickedItem.onClick();
    }
  }, [items]);

  const overlay = useMemo(() => {
    if (menu) {
      return menu;
    }

    const menuItems = items.reduce((mappedItems, { key, label: itemLabel, icon, hidden }) => {
      const item = (
        <Menu.Item key={key} icon={<i className={icon} />}>
          {itemLabel}
        </Menu.Item>
      );
      return hidden ? mappedItems : mappedItems.concat(item);
    }, []);

    return (
      <Menu onClick={handleItemClick}>{menuItems}</Menu>
    );
  }, [menu, items, handleItemClick]);

  const classNames = useMemo(() => (
    classnames('dropdown', { mR, mB }, className)
  ), [className, mB, mR]);

  const content = useMemo(() => (
    children || <Button label={label} />
  ), [label, children]);

  return (
    <AntdDropdown overlay={overlay} className={classNames} {...rest}>
      {content}
    </AntdDropdown>
  );
};

Dropdown.defaultProps = {
  trigger: ['click'],
};

export default Dropdown;
