import { useCallback, useEffect, useReducer } from 'react';
import projectCoordinatorApi from 'api/project-coordinator';
import useCallApiAction from 'hooks/base/call-api-action';
import notification, { NotificationType } from 'helpers/notification';
import setFormValue from './actions/set-form-value';
import setErrors from './actions/set-errors';
import onSave from './actions/handle-on-save';
import onDelete from './actions/handle-on-delete';
import notesFormReducer, { NotesFormActions, NotesFormModel } from './reducer';

export { validationRules } from './validation-rules';
export const initialNote: NotesFormModel = {
  text: '',
};

export default function useNotesState(orderId) {
  const [state, dispatch] = useReducer(
    notesFormReducer,
    {
      formData: initialNote,
      errors: undefined,
      saving: false,
      notes: [],
    },
  );

  const { formData, notes } = state;

  const {
    data,
    fetching,
    error,
  } = useCallApiAction(projectCoordinatorApi.getNotes, orderId, []);

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (data.length > 0) {
      dispatch({
        type: NotesFormActions.UPDATE_DATA,
        payload: data,
      });
    }
  }, [data]);

  return ({
    state: {
      ...state,
      fetching,
      data: notes,
      orderId,
    },
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      onSave: useCallback(onSave(dispatch, formData, notes, orderId), [formData, notes, orderId]),
      handleOnDelete: useCallback(onDelete(dispatch, notes, orderId), [notes, orderId]),
    },
  });
}
