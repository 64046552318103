import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  addDays,
  addMonths,
  addWeeks,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { capitalizeFirstLetter } from 'helpers/formatting';
import FlexBox from 'components/base-components/FlexBox';
import Popover from 'components/base-components/Popover';
import Button from 'components/base-components/Button';
import { CheckboxGroup } from 'components/base-components/Checkbox';
import ButtonGroup from 'components/base-components/ButtonGroup';
import { Title } from 'components/base-components/Typography';
import Spinner from 'components/base-components/Spinner';
import RenderIf from 'components/base-components/RenderIf';
import RadioButton from 'components/base-components/RadioButton';
import { RadioGroup } from 'components/base-components/RadioGroup';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { EventItemType, ViewMode } from './state/reducer';

interface Props {
  calendarRef: any;
  fetchingData: boolean;
  date: Date;
  view: any;
  onDateChange: (value: Date) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  selectedSources: string[];
  onSourcesChange: (sources) => void;
  fetchingOptions: boolean;
  eventTypesOptions: string[];
}

const shortDateFormatter = new Intl.DateTimeFormat('en', {
  month: 'long',
  year: 'numeric',
});

const getSources = (eventTypesOptions: string[]) => ([
  {
    value: EventItemType.ORDER,
    label: (
      <span>
        <i style={{ fontSize: '16px' }} className="fa fa-shopping-bag" />
        <span style={{ marginLeft: '8px', fontSize: '16px' }}>Orders</span>
      </span>
    ) as any,
  },
  {
    value: EventItemType.BOOKING,
    label: (
      <span>
        <i style={{ fontSize: '16px' }} className="fa fa-tools" />
        <span style={{ marginLeft: '8px', fontSize: '16px' }}>
          Installer Bookings
        </span>
      </span>
    ) as any
  },
  ...eventTypesOptions.map(e => (
    {
      value: e,
      label: (
        <span>
          <i style={{ fontSize: '16px' }} className="fa fa-user-clock" />
          <span style={{ marginLeft: '8px', fontSize: '16px' }}>
            {capitalizeFirstLetter(e)}
          </span>
        </span>
      ) as any
    }
  )),
]);

const Header: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  const {
    calendarRef,
    fetchingData,
    date,
    onDateChange,
    view,
    onViewModeChange,
    selectedSources,
    onSourcesChange,
    eventTypesOptions,
    fetchingOptions,
  } = props;

  const sources = useMemo(() => getSources(eventTypesOptions), [eventTypesOptions]);

  const goPrev = useCallback(() => {
    const dateModifiers = {
      [ViewMode.MONTH]: subMonths,
      [ViewMode.WEEK]: subWeeks,
      [ViewMode.DAY]: subDays,
    };

    const nextDate = dateModifiers[view](date, 1);
    onDateChange(nextDate);

    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, date, onDateChange]);

  const goNext = useCallback(() => {
    const dateModifiers = {
      [ViewMode.MONTH]: addMonths,
      [ViewMode.WEEK]: addWeeks,
      [ViewMode.DAY]: addDays,
    };

    const nextDate = dateModifiers[view](date, 1);
    onDateChange(nextDate);

    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, date, onDateChange]);

  const handleViewModeChange = useCallback((event) => {
    const viewMode = event.target.value;
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(viewMode, date);
    onViewModeChange(viewMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onViewModeChange]);

  const goToday = useCallback(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    onDateChange(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDateChange]);

  const openEventFormModal = () => {
    const locationState = {
      customEventForm: {
        customEventId: undefined,
      }
    };
    push(ModalRouteHash.CustomEventFrom, locationState);
  };

  const sourcesOptions = (
    <CheckboxGroup
      className="production-calendar__header__sources-list"
      options={sources}
      value={selectedSources}
      onChange={onSourcesChange}
    />
  );

  return (
    <FlexBox direction="column">
      <FlexBox align="flex-start" justify="space-between" w={100} mB>
        <FlexBox>
          <ButtonGroup>
            <Button icon="fa fa-chevron-left" onClick={goPrev} />
            <Button icon="fa fa-chevron-right" onClick={goNext} />
          </ButtonGroup>
          <Button
            label="Today"
            onClick={goToday}
            className="production-calendar__header__btn"
          />
        </FlexBox>
        <FlexBox>
          <Button
            label="Add Event"
            className="production-calendar__header__btn"
            onClick={openEventFormModal}
            mR
          />
          <RadioGroup
            value={view}
            onChange={handleViewModeChange}
            optionType="button"
            buttonStyle="solid"
          >
            <FlexBox>
              <RadioButton value={ViewMode.MONTH} label="Month" />
              <RadioButton value={ViewMode.WEEK} label="Week" />
              <RadioButton value={ViewMode.DAY} label="Day" />
            </FlexBox>
          </RadioGroup>
          <Popover
            overlayClassName="production-calendar__popovers"
            placement="bottomRight"
            title="Sources"
            trigger="click"
            content={sourcesOptions}
          >
            <Button
              type="default"
              label="Select Sources"
              className="production-calendar__header__btn"
              loading={fetchingOptions}
            />
          </Popover>
        </FlexBox>
      </FlexBox>
      <FlexBox>
        <Title level={3}>
          {shortDateFormatter.format(date)}
        </Title>
        <RenderIf isTrue={fetchingData}>
          <Spinner
            centered={false}
            className="production-calendar__calendar-spinner"
          />
        </RenderIf>
      </FlexBox>
    </FlexBox>
  );
};

export default Header;
