import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import userApi from 'api/user';
import { UserModel } from 'models/user';
import { EventChannelList } from 'helpers/event-center';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import Button from 'components/base-components/Button';
import Help from 'components/forms/CommonHelp';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { UserFormHistorySateModel } from '../Form';
import imageTemplate from './templates/image-template';
import nameTemplate from './templates/name-template';
import groupsTemplate from './templates/groups-template';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: userApi,
  deleteModalTitle: 'Delete User',
  deleteModalContent: (userToDelete: UserModel) => `
  Are you sure that you want to delete the user
  ${userToDelete ? userToDelete.username : ''}?
  `,
  searchFields: ['username', 'first_name', 'last_name', 'email'],
  eventChannel: EventChannelList.USERS_LIST_CHANGED,
};

const UsersList: FunctionComponent = () => {
  const { push } = useHistory();

  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleFilter,
      handleOnChange,
    }
  } = useTableState<UserModel>(useTableStateParams);

  const openUserFormModal = (user?: UserModel | any) => {
    const historyLocationState: UserFormHistorySateModel = {
      userForm: {
        userUrl: user ? user.url : undefined,
      },
    };
    push(ModalRouteHash.UserForm, historyLocationState);
  };

  return (
    <Page title="Users">
      <Help view="users" title="Users List" />
      <PageActions primary>
        <AccessControl permissions={permissions.createUser}>
          <Button
            onClick={openUserFormModal}
            id="add-user"
            type="primary"
            label="Create User"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        dataSource={data}
        loading={fetching}
        page={page}
        totalRecords={count}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="url"
      >
        <DataTableColumn
          title="Image"
          render={imageTemplate}
          columnKey="image"
        />
        <DataTableColumn
          title="Name"
          columnKey="first_name"
          render={nameTemplate}
          sorter
        />
        <DataTableColumn
          title="Email"
          dataIndex="email"
          columnKey="email"
          sorter
        />
        <DataTableColumn
          title="User group"
          columnKey="groups"
          render={groupsTemplate}
          sorter
          showBreakpoint={1091}
        />
        <DataTableColumn
          title="Last login"
          dataIndex="lastLogin"
          columnKey="last_login"
          isDate
          sorter
          showBreakpoint={1264}
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editUser}
          deletePermissions={permissions.deleteUser}
          onEdit={openUserFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default UsersList;
