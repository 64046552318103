import React, { FunctionComponent } from 'react';
import getEventValue from 'helpers/get-event-value';
import Field from 'components/base-components/Form/Field';
import { RadioGroup, Option } from 'components/base-components/RadioGroup';
import { Answers } from './types';

interface Props {
  label: string;
  name: string;
}

const Section: FunctionComponent<Props> = (props) => {
  const { label, name, children } = props;

  const handleRadioChange = (event, setField) => {
    setField({ [name]: getEventValue(event) });
  };

  return (
    <div className="questionnaire__section">
      <div className="questionnaire__section__question">
        <span>{label}</span>
        <div className="questionnaire__section__answers">
          <Field
            name={name}
            component={RadioGroup}
            onChange={handleRadioChange}
            skipDebounce
          >
            <Option value={Answers.Yes} label="Yes" />
            <Option value={Answers.No} label="No" />
          </Field>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Section;
