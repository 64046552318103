/* eslint-disable */
import Core from '../core';
import { dimInch, dimCentiMeter, dimFeet, dimMeter } from '../core/dimensioning';

const zoomLevel = 100;

export default class InputWithUnit {
  dom = null;
  value = 0;

  events = {};
  constructor(placeholder) {
    this.dom = document.createElement('div');
    this.dom.setAttribute('style', 'display: flex; width: inherit; align-items: center;');
    this.dom.insertAdjacentHTML('beforeend', `<label>${placeholder}</label>`);

    // cm input container
    this.cmInputContainer = document.createElement('div');
    this.inputCm = document.createElement('input');
    this.inputCm.setAttribute('type', 'number');
    this.inputCm.setAttribute('placeholder', placeholder);
    this.inputCm.setAttribute('min', 0);
    this.inputCm.value = 0;
    this.inputCm.style.width = '80px';

    this.inputCm.addEventListener('change', e => {
      const value = e.target.value;
      typeof this.events['change'] === 'function' && this.events['change'](value / zoomLevel);
      this.setValue(value / zoomLevel);
    })

    this.cmInputContainer.appendChild(this.inputCm);
    this.cmInputContainer.insertAdjacentHTML('beforeend', '<label>cm</label>');
    this.dom.appendChild(this.cmInputContainer);

    // inch input container
    this.inchInputContainer = document.createElement('div');
    this.inputInch = document.createElement('input');
    this.inputInch.setAttribute('type', 'number');
    this.inputInch.setAttribute('placeholder', placeholder);
    this.inputInch.setAttribute('min', 0);
    this.inputInch.value = 0;
    this.inputInch.style.width = '80px';

    this.inputInch.addEventListener('change', e => {
      const value = e.target.value * 2.54;
      typeof this.events['change'] === 'function' && this.events['change'](value / zoomLevel);
      this.setValue(value / zoomLevel);
    })
    this.inchInputContainer.appendChild(this.inputInch);
    this.inchInputContainer.insertAdjacentHTML('beforeend', '<label>"</label>');
    this.dom.appendChild(this.inchInputContainer);

    // feet input container
    this.feetInputContainer = document.createElement('div');
    this.inputFeet1 = document.createElement('input');
    this.inputFeet1.setAttribute('type', 'number');
    this.inputFeet1.setAttribute('placeholder', placeholder);
    this.inputFeet1.setAttribute('min', 0);
    this.inputFeet1.value = 0;
    this.inputFeet1.style.width = '40px';

    this.inputFeet1.addEventListener('change', e => {
      if (typeof this.events['change'] === 'function') {
        const value = this.inputFeet1.value * 30.48 + this.inputFeet2.value * 2.54;
        this.events['change'](value / zoomLevel);
        this.setValue(value / zoomLevel);
      }
    })
    this.feetInputContainer.appendChild(this.inputFeet1);
    this.feetInputContainer.insertAdjacentHTML('beforeend', '<label>\'</label>');

    this.inputFeet2 = document.createElement('input');
    this.inputFeet2.setAttribute('type', 'number');
    this.inputFeet2.setAttribute('placeholder', placeholder);
    this.inputFeet2.setAttribute('min', 0);
    this.inputFeet2.value = 0;
    this.inputFeet2.style.width = '40px';

    this.inputFeet2.addEventListener('change', e => {
      if (typeof this.events['change'] === 'function') {
        const value = this.inputFeet1.value * 30.48 + this.inputFeet2.value * 2.54;
        this.events['change'](value / zoomLevel);
        this.setValue(value / zoomLevel);
      }
    })
    this.feetInputContainer.appendChild(this.inputFeet2);
    this.feetInputContainer.insertAdjacentHTML('beforeend', '<label>"</label>');
    this.dom.appendChild(this.feetInputContainer);

    document.addEventListener(Core.BP3D_EVENT_CONFIG_CHANGED, e => {
      const { detail } = e;
      if (!detail) return;
      detail.dimUnit && this.handleUnitChanged(detail.dimUnit);
    })

    this.handleUnitChanged(Core.Configuration.getStringValue('dimUnit'));
  }

  handleUnitChanged = (unit) => {
    this.cmInputContainer.style.display = 'none';
    this.inchInputContainer.style.display = 'none';
    this.feetInputContainer.style.display = 'none';

    switch (unit) {
      case dimInch:
        this.inchInputContainer.style.display = 'inherit';
        break;
      case dimCentiMeter:
        this.cmInputContainer.style.display = 'inherit';
        break;
      case dimFeet:
        this.feetInputContainer.style.display = 'inherit';
        break;
      case dimMeter:
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param {Number} value measurement length in meter
   */
  setValue = value => {
    const cm = value * zoomLevel;
    this.inputCm.value = Math.round(10 * cm) / 10;

    this.inputInch.value = Math.round(cm / 2.54);

    const realFeet = ((cm * 0.393700) / 12);
    const feet = Math.floor(realFeet);
    const inches = Math.round((realFeet - feet) * 12);
    this.inputFeet1.value = feet;
    this.inputFeet2.value = inches;
  }

  setDisabled = disabled => {
    this.inputCm.disabled = disabled;
  }

  addEventListener = (type, cb) => this.events[type] = cb;
}
