import { UserModel } from 'models/user';
import { QuestionnaireActions } from '../reducer';

export default function copyContactInfo(dispatch, contact: UserModel) {
  return () => {
    dispatch({
      type: QuestionnaireActions.SET_CONTACT_INFO,
      payload: contact,
    });
  };
}
