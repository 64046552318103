import getEventValue from 'helpers/get-event-value';
import { ApplyOptionsFormActions } from '../reducer';

export function setCheckAll(dispatch, allGroups) {
  return (value) => {
    const payload = getEventValue(value);
    dispatch({ type: ApplyOptionsFormActions.SET_CHECK_ALL, payload });
    dispatch({ type: ApplyOptionsFormActions.SET_GROUP_CHECKED, payload: payload ? allGroups : [] });
  };
}
