import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import useReuseQuoteFormState from './state';
import { validationRules } from './state/validation-rules';

export interface ReuseQuoteHistorySateModel {
  reuseQuoteForm: {
    quoteId: number;
    repOwner: string;
  };
}

const ReuseQuoteForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { reuseQuoteForm: { quoteId, repOwner } } = state as ReuseQuoteHistorySateModel;

  const {
    state: {
      formData,
      clientOptions,
      fetchingClients,
      coping,
      errors,
    },
    actions: {
      setFormValue,
      reuseQuote,
      setValidationErrors,
    },
  } = useReuseQuoteFormState(quoteId, repOwner);

  return (
    <Modal
      visible
      title="Reuse Quote"
      onCancel={goBack}
      okText="Submit"
      onOk={reuseQuote}
      confirmLoading={coping}
    >
      <RenderIf isTrue={!fetchingClients} fallback={<Spinner />}>
        <Form
          state={formData}
          onChange={setFormValue}
          errors={errors}
          onError={setValidationErrors}
          rules={validationRules}
        >
          <Field
            id="comments"
            name="comments"
            label="Comments"
            placeholder="Enter a comment"
          />
          <Field
            id="jobTitle"
            name="jobTitle"
            label="Job Title"
            placeholder="Enter Job Title"
          />
          <Field
            component={Select}
            name="client"
            label="Client"
            options={clientOptions}
            showSearch
            optionFilterProp="label"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default ReuseQuoteForm;
