import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { PaymentFormNavigationState } from 'components/pages/Orders/PaymentForm/state';

export default function openReceivePaymentModal(push) {
  return (order: any) => {
    push(ModalRouteHash.ReceivePayment, {
      paymentForm: {
        data: {
          orderId: order.id,
          companyName: order.account,
          jobTitle: order.jobName,
          totalWithTax: order.total,
        },
      },
    } as PaymentFormNavigationState);
  };
}
