/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import './styles.scss';

interface Props {
  info?: any;
}

const morphAligns = [
  { font: 'fas fa-arrows-alt', value: 0, rotation: 0 },
  { font: 'fas fa-arrow-left', value: 1, rotation: 0 },
  { font: 'fas fa-arrow-left', value: 2, rotation: -45 },
  { font: 'fas fa-arrow-down', value: 3, rotation: 0 },
  { font: 'fas fa-arrow-down', value: 4, rotation: -45 },
  { font: 'fas fa-arrow-right', value: 5, rotation: 0 },
  { font: 'fas fa-arrow-right', value: 6, rotation: -45 },
  { font: 'fas fa-arrow-up', value: 7, rotation: 0 },
  { font: 'fas fa-arrow-up', value: 8, rotation: -45 },
];

class FloatProductToolbar extends React.Component<Props> {
  getButtons = () => {
    const { info } = this.props;
    let buttons: any[] = [
      {
        font: morphAligns[info && info.morphAlign ? info.morphAlign : 0].font,
        tooltip: 'Stretch Direction',
        fontStyle: {
          transform: `rotate(${
            morphAligns[info && info.morphAlign ? info.morphAlign : 0].rotation
          }deg)`,
        },
        callback: () => {
          let nextIndex = 0;
          nextIndex = (info.morphAlign - 0 + 1) % morphAligns.length;
          notifyEventChannel(EventChannelList.BUILDER_SET_STRETCH_DIRECTION, nextIndex);
          this.forceUpdate();
        },
      },
      {
        toggled: info && info.fixed,
        font: 'fas fa-thumbtack',
        tooltip: 'Lock in place',
        callback: () => {
          notifyEventChannel(EventChannelList.BUILDER_SET_FIXED, !Boolean(info && info.fixed));
          this.forceUpdate();
        },
      },
      {
        toggled: info && info.stackable,
        font: 'fas fa-layer-group',
        tooltip: 'Stackable',
        callback: () => {
          notifyEventChannel(EventChannelList.BUILDER_SET_STACKABLE, !Boolean(info && info.stackable));
          this.forceUpdate();
        },
      },
      {
        toggled: info && info.overlappable,
        font: 'fas fa-clone',
        tooltip: 'Overlappable',
        callback: () => {
          notifyEventChannel(EventChannelList.BUILDER_SET_OVERLAPPABLE, !Boolean(info && info.overlappable));
          this.forceUpdate();
        },
      },
    ];
    if (info && info.flippable) {
      buttons.push({
        font: 'fas fa-exchange-alt',
        tooltip: 'Flip',
        callback: () => {
          notifyEventChannel(EventChannelList.BUILDER_FLIP_HORIZONTAL);
        },
      });
    }
    buttons = [
      ...buttons,
      ...[
        null,
        // {
        //   font: 'far fa-copy',
        //   tooltip: 'Duplicate',
        //   callback: () => {
        //     notifyEventChannel(EventChannelList.BUILDER_DUPLICATE);
        //   },
        // },
        {
          font: 'far fa-trash-alt',
          tooltip: 'Delete',
          callback: () => {
            notifyEventChannel(EventChannelList.BUILDER_DELETE_ACTIVE_PRODUCT);
          },
        },
      ],
    ];
    return buttons;
  };

  render() {
    return (
      <aside className="builder__float-product-toolbar">
        <section className="float-toolbar">
          {this.getButtons().map((button, index) => {
            if (!button) return <div key={index} className="hr" />;
            return (
              <div
                key={index}
                className={`float-toolbar-button ${
                  button.toggled ? 'toggled' : ''
                }`}
                data-tip={button.tooltip}
                onClick={button.callback}
              >
                <span className={button.font} style={button.fontStyle}>
                  {button.label}
                </span>
              </div>
            );
          })}
        </section>
        <ReactTooltip />
      </aside>
    );
  }
}

export default FloatProductToolbar;
