/* eslint-disable */
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Item from './item';

export default class SceneManager {
  scene = null;
  camera = null;

  model = null;

  renderer = null;
  controls = null;

  itemSelectedCallbacks = [];
  /**
   *
   * @param {HTMLElement} container
   */
  constructor(container) {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xe0e0e0);

    this.camera = new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 1, 20);
    this.camera.position.set(-2, 2, 2);
    this.scene.add(this.camera);

    this.scene.add(new THREE.AmbientLight(0x8FBCD4, 0.4));

    const pointLight = new THREE.PointLight(0xffffff, 1);

    // const box = new THREE.Mesh(new THREE.BoxGeometry(0.3, 0.6, 0.9), new THREE.MeshStandardMaterial());
    // this.scene.add(box);

    this.camera.add(pointLight);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true // required to support .toDataURL()
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(this.renderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    console.log(this.controls);
    this.controls.maxPolarAngle = Math.PI / 2 + 0.3;
    // this.controls.minDistance = 3;
    this.controls.maxDistance = 10;

    this.GLTFLoader = new GLTFLoader();

    window.addEventListener('resize', () => {
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      this.camera.aspect = container.clientWidth / container.clientHeight;
      this.camera.updateProjectionMatrix();
    })

    this.renderer.setAnimationLoop(() => {
      this.renderer.render(this.scene, this.camera);
      this.controls.update();

      // if (this.bIsSettingCameraPosition) {
      //   const threshold = 0.05;
      //   let current = new THREE.Vector3().copy(this.camera.position);
      //   current = current.add(this.cameraMovingSpeed);
      //   this.camera.position.copy(current);

      //   const dist = this.targetCameraPosition.distanceTo(current);
      //   if (dist < threshold) {
      //     this.bIsSettingCameraPosition = false;
      //   }
      // }

      // // limit min distance of orbit control
      // // if (this.model) {
      // //   const bbox = this.model.getBounding();
      // //   const dist = bbox.max.distanceTo(bbox.min);

      // //   this.controls.minDistance = dist * 1.5;
      // // }
    });
  }

  /**
   * Creates an item and adds it to the scene.
   * @param itemType The type of the item given by an enumerator.
   * @param fileName The name of the file to load.
   * @param metadata TODO
   * @param position The initial position.
   * @param rotation The initial rotation around the y axis.
   * @param options The initial options.
   */
  addItem(itemType, fileName, metadata, position, rotation, options) {
    itemType = itemType || 1;
    var scope = this;

    this.GLTFLoader.load(
      fileName,
      function (gltf) {
        const meshList = [];

        const morphHeight = {};
        const morphWidth = {};
        const morphDepth = {};

        gltf.scene.traverse((node) => {
          if (node.isMesh) {
            if (!node.name.includes('morph-')) {
              meshList.push(node);
            }

            if (node.name.includes('morph-height')) {
              const name = node.name.replace('-morph-height', '');
              const tmp = [];
              node.geometry.attributes.uv.array.forEach(v => tmp.push(v));
              morphHeight[name] = tmp;
            } else if (node.name.includes('morph-width')) {
              const name = node.name.replace('-morph-width', '');
              const tmp = [];
              node.geometry.attributes.uv.array.forEach(v => tmp.push(v));
              morphWidth[name] = tmp;
            } else if (node.name.includes('morph-depth')) {
              const name = node.name.replace('-morph-depth', '');
              const tmp = [];
              node.geometry.attributes.uv.array.forEach(v => tmp.push(v));
              morphDepth[name] = tmp;
            }
          }
        });

        let morphUVs = [morphHeight, morphWidth, morphDepth];
        var item = new Item(
          scope.scene,
          { ...metadata, morphUVs },
          meshList,
          position,
          rotation,
          options,
        );

        scope.scene.add(item);

        scope.model = item;

        scope.itemSelectedCallbacks.forEach(cb => cb(item));
        // setTimeout(() => {
        //   notifyEventChannel(
        //     EventChannelList.BUILDER_LOADED_PRODUCT,
        //     metadata.id,
        //   );
        // }, 100);
      },
      undefined
    );
  }

  setMinDistance = dist => {
    console.log(dist);
  }

  onChangeSize(clientHeight){
    let clientWidth = this.renderer?.getSize()?.x;
    this.renderer?.setSize(clientWidth, clientHeight );
    this.camera.aspect = clientWidth / clientHeight;
    this.camera?.updateProjectionMatrix();
  }

  /**
   * Set Camera Position Preset
   * @param {Object} preset {x: number, y: number: z: number}
   */
  setCameraPositionPreset(preset) {
    const current = this.camera.position;
    const dist = current.distanceTo(new THREE.Vector3());
    const newPos = new THREE.Vector3(preset.x, preset.y, preset.z).multiplyScalar(dist);
    this.camera.position.copy(newPos);
    // this.bIsSettingCameraPosition = true;
    // this.targetCameraPosition = newPos;
    // this.cameraMovingSpeed = new THREE.Vector3().copy(newPos).sub(current).multiplyScalar(1 / 40);
  }
}
