import { useCallback, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import {
  copyProduct,
  setFormValue,
  setGroupValue,
} from './actions';
import copyProductFormReducer from './reducer';

export default function useCopyProductState(groups, quote, productsSelected) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    copyProductFormReducer,
    {
      formData: {
        group: null,
      },
      errors: {},
      coping: false,
      groupForSelect: groups,
    },
  );

  const { formData, groupForSelect } = state;

  const handleNewGroup = useCallback(setGroupValue(dispatch), []);

  useEventCenterUpdate(EventChannelList.QUOTE_PAGE_NEW_GROUP, handleNewGroup);

  return {
    state: {
      ...state,
      memoGroups: useMemo(() => (
        groupForSelect.map(group => ({
          value: group,
          label: group === '' ? 'None' : group,
        }))
      ), [groupForSelect]),
    },
    actions: {
      handleValueChange: useCallback(setFormValue(dispatch), []),
      handleOnSubmit: useCallback(
        copyProduct(dispatch, formData, quote, productsSelected, goBack),
        [formData, quote, goBack, productsSelected],
      ),
    },
  };
}
