import React, { FunctionComponent, useMemo } from 'react';
import { Summary } from 'components/base-components/DataTableV2';
import { formatDisplayDate, formatCurrency } from 'helpers/formatting';
import { TransactionSummary } from 'models/invoice';

interface Props {
  dataSummary: TransactionSummary;
}

const QuoteProductsListSummary: FunctionComponent<Props> = (props) => {
  const { dataSummary } = props;

  const payments = useMemo(
    () => dataSummary.payments?.map((payment) => (
      <Summary.Row key={payment.id}>
        <Summary.Cell index={0} colSpan={3} align="right">
          {`${formatDisplayDate(payment.receivedDate || payment.createdDate)} - ${payment.method.methodName}`}
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          {formatCurrency(payment.amount)}
        </Summary.Cell>
      </Summary.Row>
    )),
    [dataSummary.payments],
  );

  const terms = useMemo(() => dataSummary?.terms?.map((term, index) => (
    <Summary.Cell index={0} key={`term-${index}`} align="right">
      {`${term.percentage}% ${term.paymentType}`}
    </Summary.Cell>
  )), [dataSummary]);

  const dues = useMemo(() => dataSummary?.terms?.map((term, index) => (
    <Summary.Cell index={0} key={`due-${index}`} align="right">
      {formatCurrency(term.due)}
    </Summary.Cell>
  )), [dataSummary]);

  return (
    <>
      <Summary.Row className="quote-products-table_group-header__row">
        <Summary.Cell index={0} colSpan={4}>
          Terms
        </Summary.Cell>
      </Summary.Row>
      <Summary.Row>
        <Summary.Cell index={0} align="right">
          Terms
        </Summary.Cell>
        {terms}
      </Summary.Row>
      <Summary.Row>
        <Summary.Cell index={0} align="right">
          Due
        </Summary.Cell>
        {dues}
      </Summary.Row>
      <Summary.Row className="quote-products-table_group-header__row">
        <Summary.Cell index={0} colSpan={4}>
          Payments
        </Summary.Cell>
      </Summary.Row>
      {payments}
      <Summary.Row>
        <Summary.Cell index={0} colSpan={3} align="right">
          Balance
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          {formatCurrency(dataSummary.balance)}
        </Summary.Cell>
      </Summary.Row>
    </>
  );
};

export default QuoteProductsListSummary;
