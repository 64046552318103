import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import orderStatusApi from 'api/orde-status';
import taskNameApi from 'api/task-name';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import NumberInput from 'components/base-components/NumberInput';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Select from 'components/base-components/Select';
import { validationRules } from './validations';
import useTaskTemplateFormState from './state';

export interface TaskTemplateFormHistorySateModel {
  taskTemplateForm: {
    taskTemplateId: number;
    conditionalOptions: any[];
  };
}

export function mapOrderStatusOptions(statuses) {
  return mapGenericDropdownOptions(statuses, { fields: { value: 'id', label: 'status' } });
}
export function mapTaskNameOptions(statuses) {
  return mapGenericDropdownOptions(statuses, { fields: { value: 'id', label: 'name' } });
}

const TaskTemplateForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { taskTemplateForm: { taskTemplateId, conditionalOptions } } = state as TaskTemplateFormHistorySateModel;

  const {
    state: {
      fetchingEntity,
      saving,
      formData,
      errors,
      mappedConditionalOptions
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  } = useTaskTemplateFormState(taskTemplateId, conditionalOptions);

  const modalTitle = useMemo(
    () => taskTemplateId ? 'Edit Task' : 'Create Task',
    [taskTemplateId],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveEntity}
      confirmLoading={saving}
    >
      <RenderIf isTrue={!fetchingEntity} fallback={<Spinner />}>
        <Form
          state={formData}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            component={NumberInput}
            id="priority"
            name="priority"
            label="priority"
            placeholder="Enter priority"
            min={0}
            required
          />
          <Field
            id="details"
            name="details"
            label="Details"
            placeholder="Enter Details"
            required
          />
          <Field
            component={AsyncSelectV2}
            name="taskName"
            id="taskName"
            label="Task Name"
            listAction={taskNameApi.list}
            mapFunction={mapTaskNameOptions}
            required
          />
          <Field
            component={AsyncSelectV2}
            name="startingStage"
            id="startingStage"
            label="Starting Stage"
            listAction={orderStatusApi.list}
            mapFunction={mapOrderStatusOptions}
            required
          />
          <Field
            component={AsyncSelectV2}
            name="endingStage"
            id="endingStage"
            label="Ending Stage"
            listAction={orderStatusApi.list}
            mapFunction={mapOrderStatusOptions}
            required
          />
          <Field
            component={Select}
            name="conditional"
            id="conditional"
            label="Conditional"
            options={mappedConditionalOptions}
            required
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default TaskTemplateForm;
