import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import { OrderStatus } from 'models/order';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/orderstatus/`;

const orderStatusApi: ApiModel<OrderStatus> = {
  ...getApi<OrderStatus>(endpoint, ApiContentType.JSON),
};

export default orderStatusApi;
