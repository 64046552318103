import React, { FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { ValidationRules } from 'helpers/form-validations';
import Field from './Field';
import { FormProvider } from './FormContext';
import './styles.scss';

export { Field };

interface Props {
  id?: string;
  state: any;
  onChange: (value, change?) => void;
  errors?: { [x: string]: string };
  onError?: (errors) => void;
  rules?: ValidationRules;
  className?: string;
  style?: object;
  mT?: boolean;
  autoComplete?: boolean;
}

const disableOnSubmit = (event) => {
  event.stopPropagation();
  event.preventDefault();
};

const Form: FunctionComponent<Props> = (props) => {
  const {
    id,
    state,
    onChange,
    errors,
    onError,
    rules,
    className,
    style,
    mT,
    autoComplete,
    children,
  } = props;
  const formClassName = classnames('form', { mT }, className);

  const setField = useCallback((value) => {
    const nextState = { ...state, ...value };
    onChange(nextState, value);
  }, [state, onChange]);

  const setErrors = useCallback((error) => {
    if (errors && onError) {
      const nextErrors = { ...errors, ...error };
      onError(nextErrors);
    }
  }, [errors, onError]);

  return (
    <form
      id={id}
      style={style}
      autoComplete={autoComplete ? 'on' : 'off'}
      className={formClassName}
      onSubmit={disableOnSubmit}
    >
      <FormProvider
        state={state}
        setField={setField}
        errors={errors}
        setErrors={setErrors}
        rules={rules}
      >
        {children}
      </FormProvider>
    </form>
  );
};

Form.defaultProps = {
  id: undefined,
  className: '',
  style: null,
  autoComplete: false,
};

export default Form;
