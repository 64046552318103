import { resolveApiErrorMessage } from 'api/base';
import orderOptionsApi from 'api/order-option';
import optionApi from 'api/option';
import productApi from 'api/product';
import orderProductApi from 'api/order-product';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { getOptionPayload } from '../../../AddProduct/state/actions/get-payload';
import { getCustomOptionPricePayload } from '../../../ApplyOptions/state/actions/get-payload';
import { EditOptionsFromModel } from '..';
import { EditOptionsFormActions } from '../reducer';

async function edit(product, formData, options, customPriceResponse, discount) {
  const formattedCustomPrice = customPriceResponse.reduce((customPrice, option) => ({
    ...customPrice,
    [option.optionGroup]: option.price
  }), {});
  const optionsPrice = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const item of options) {
    const optionSelected = (formData[item.name]).value;
    const optionData = formattedCustomPrice[item.name]
      ? {
        ...formData[item.name],
        dropdownItem: {
          ...formData[item.name]?.dropdownItem,
          price: formattedCustomPrice[item.name]
        }
      }
      : formData[item.name];

    const orderOption = product.options.find(
      optionInstance => optionInstance.optionType === item.name
    );

    optionsPrice[item.name] = {
      name: optionSelected,
      cost: (optionData.dropdownItem?.cost || 0),
    };

    if (orderOption && orderOption.optionSelected !== optionSelected) {
      if (!optionSelected) {
        // eslint-disable-next-line no-await-in-loop
        await orderOptionsApi.delete(orderOption.id);
      } else {
        const payload = getOptionPayload(optionData, item.name, product.id, formData[`sub-${item.name}`]);
        // eslint-disable-next-line no-await-in-loop
        await orderOptionsApi.update({
          ...payload,
          id: orderOption.id
        });
      }
    }

    if (!!optionSelected && orderOption === undefined) {
      const payload = getOptionPayload(optionData, item.name, product.id, formData[`sub-${item.name}`]);
      // eslint-disable-next-line no-await-in-loop
      await orderOptionsApi.create(payload);
    }
  }
  if (product.category !== 'custom') {
    const getPricePayload = {
      products: [
        {
          model: product.model,
          options: optionsPrice,
          price: product?.price
        }
      ]
    };
    const { data: { data } } = await productApi.getPrice({}, getPricePayload);
    const priceFilled = data[0]?.price || 0;
    const price = (priceFilled - (priceFilled * (discount / 100))).toFixed(2);
    await orderProductApi.patch({ id: product.id, price });
  }
}

export function editOptions(dispatch, formData: EditOptionsFromModel, product, options, goBack, discount) {
  return () => {
    dispatch({ type: EditOptionsFormActions.START_EDITING });
    const customOptionPricePayload = getCustomOptionPricePayload(formData);

    const onSuccess = () => {
      notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED, true);
      const message = 'Options Successfully edited';
      notification({ type: NotificationType.SUCCESS, message });
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: EditOptionsFormActions.FINISH_EDITING });
    };

    const getCustomOptionPrice = customOptionPricePayload.length > 0
      ? optionApi.getCustomOptionPrice(customOptionPricePayload)
      : new Promise((resolve) => resolve({ data: [] })) as any;

    return getCustomOptionPrice
      .then(({ data }) => edit(product, formData, options, data, discount))
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
