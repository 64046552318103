import { SendEmailFormState } from '../reducer';

const initialState: SendEmailFormState = {
  email: { recipients: [], html: '', s3Attachments: [], subject: 'Send Email', attachmentCheck: [] },
  errors: {},
  sendingEmail: false,
  fetchingData: false,
};

export default function getInitialState(navigationState) {
  if (navigationState && navigationState.sendEmailForm) {
    const { sendEmailForm: { recipients, extraFields } } = navigationState;

    return {
      ...initialState,
      email: { ...initialState.email, ...extraFields, recipients },
    };
  }

  return initialState;
}
