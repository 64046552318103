import { useEffect, useMemo } from 'react';
import { ManufacturerModel } from 'models/manufacturer';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import manufacturerApi from 'api/manufacturer';
import { isValidFile, mapFileFromUrl } from 'helpers/file';

interface ManufacturerFormModel {
  companyName: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  currency: string;
  purchaseMethod: string;
  image: File[];
}

const defaultManufacturer: ManufacturerFormModel = {
  companyName: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  address: '',
  city: '',
  province: '',
  postalCode: '',
  country: '',
  currency: '',
  purchaseMethod: '',
  image: [],
};

const getPayload = (manufacturer: ManufacturerFormModel) => {
  const { image, ...rest } = manufacturer;
  return {
    ...rest,
    image: image && isValidFile(image[0]) ? image : undefined,
  };
};

const getFormData = (manufacturer: ManufacturerModel) => {
  if (manufacturer) {
    const { image, ...rest } = manufacturer;
    return {
      ...rest,
      image: mapFileFromUrl(image),
    };
  }
  return defaultManufacturer;
};

export default function useManufacturerFormState(manufacturerUrl: string, onSuccess?: () => any) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: manufacturerApi,
    eventChannel: EventChannelList.MANUFACTURERS_LIST_CHANGED,
    param: manufacturerUrl,
    getPayload,
    getFormData,
    noGoBackOnSuccess: !!onSuccess,
    onSuccess,
    clearOnSuccess: !!onSuccess,
  }), [manufacturerUrl, onSuccess]);

  const {
    state: {
      formData: manufacturer,
      errors,
      saving,
      errorFetchingEntity: errorFetchingManufacturer,
      fetchingEntity: fetchingManufacturer,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<ManufacturerModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingManufacturer) {
      notification({ type: NotificationType.ERROR, message: errorFetchingManufacturer });
    }
  }, [errorFetchingManufacturer]);

  return {
    state: {
      manufacturer,
      fetchingFormData: fetchingManufacturer,
      savingManufacturer: saving,
      errors,
    },
    actions: {
      setFormValue,
      saveManufacturer: saveEntity,
      setErrors,
    },
  };
}
