import React, { FunctionComponent } from 'react';
import { QuoteModel } from 'models/quote';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import TextArea from 'components/base-components/TextArea';
import Button from 'components/base-components/Button';
import useSpecialInstructionsState from './state';

interface Props {
  quote: QuoteModel;
  updateDataQuoteManually: any;
  loading: boolean;
}

const EspecialInstructions: FunctionComponent<Props> = (props) => {
  const { quote, updateDataQuoteManually, loading } = props;

  const {
    state: {
      formData,
      editing,
      errors,
    },
    actions: {
      handleValueChange,
      handleOnSubmit,
      setValidationErrors,
    },
  } = useSpecialInstructionsState(quote, updateDataQuoteManually);

  return (
    <Form
      state={formData}
      onChange={handleValueChange}
      errors={errors}
      onError={setValidationErrors}
      className="quote-view__instructions"
    >
      <Field
        label="Special Instructions"
        component={TextArea}
        id="specialInstructions"
        name="specialInstructions"
        placeholder="Enter Instructions"
        disabled={editing || loading}
        onPressEnter={handleOnSubmit}
      />
      <Button
        loading={editing}
        disabled={loading}
        onClick={handleOnSubmit}
        className="quote-view__instructions_btn"
        label="Special Instruction"
        icon="fas fa-pen"
        htmlType="button"
        type="default"
        mT
      />
    </Form>
  );
};

export default EspecialInstructions;
