import { ProductionManagerModel } from 'models/production-manager';
import productionManagerApi from 'api/production-manger';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';

export default function printShopOrder(item: ProductionManagerModel) {
  const { id } = item;
  productionManagerApi.printShop(id)
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}
