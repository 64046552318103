import { StatusTask, TaskModel } from 'models/task';
import { Card, Column } from 'components/base-components/KanbanBoard';
// eslint-disable-next-line @typescript-eslint/camelcase
import snake_case from 'lodash.snakecase';
import { TaskManagerActions } from '../reducer';

const compare = (a: StatusTask, b: StatusTask) => a.priority - b.priority;

export default function generateColumns(statuses: StatusTask[], tasks: TaskModel[], dispatch) {
  const sortStatus = [...statuses].sort(compare);
  const columnOrder = {};
  sortStatus.forEach((status, index) => { columnOrder[snake_case(status.name)] = index; });
  const columns: Column[] = sortStatus.map((status, index) => ({
    id: index,
    title: status.name,
    cards: [] as Card[],
  }));
  tasks.forEach(task => {
    const indexColumn = columnOrder[snake_case(task.status)];
    if (indexColumn !== undefined) {
      columns[indexColumn].cards.push(task);
    }
  });
  dispatch({ type: TaskManagerActions.SET_COLUMNS, payload: columns });
}
