import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import manufacturerApi from 'api/manufacturer';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Select from 'components/base-components/Select';
import Spinner from 'components/base-components/Spinner';
import Checkbox from 'components/base-components/Checkbox';
import Upload from 'components/base-components/Upload';
import NumberInput from 'components/base-components/NumberInput';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import useOptionFormState, { mapManufacturersOptions } from './state';
import { validationRules } from './validations';

export const brands = [
  { value: 'Formica', label: 'Formica' },
  { value: 'Wilsonart', label: 'Wilsonart' },
  { value: 'Octupus', label: 'Octupus' },
];

export interface OptionFormHistorySateModel {
  optionForm: {
    optionUrl: string;
  };
}

const OptionForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { optionForm: { optionUrl } } = state as OptionFormHistorySateModel;

  const {
    state: {
      option,
      savingOption,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveOption,
      setErrors,
    },
  } = useOptionFormState(optionUrl);

  const modalTitle = useMemo(
    () => optionUrl ? 'Edit Option' : 'Create Option',
    [optionUrl],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveOption}
      confirmLoading={savingOption}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={option}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="name"
            name="name"
            label="Name"
            placeholder="Enter Option name"
            disabled={option.url}
          />
          <Field
            id="nameFr"
            name="nameFr"
            label="Name fr"
            placeholder="Enter Option fr name"
          />
          <Field
            id="description"
            name="description"
            label="Description"
            placeholder="Enter description"
          />
          <Field
            id="descriptionFr"
            name="descriptionFr"
            label="Description fr"
            placeholder="Enter description fr"
          />
          <Field
            id="code"
            name="code"
            label="Code"
            placeholder="Enter code"
          />
          <Field
            component={Select}
            id="brand"
            name="brand"
            label="Brand"
            options={brands}
          />
          <Field
            component={AsyncSelectV2}
            name="manufacturer"
            label="Manufacturer"
            listAction={manufacturerApi.list}
            mapFunction={mapManufacturersOptions}
          />
          <Field
            component={Checkbox}
            id="userInput"
            name="userInput"
            label="User Input"
          />
          <Field
            component={NumberInput}
            name="price"
            id="price"
            label="Price"
            placeholder="Enter price"
            allowDecimals
          />
          <Field
            type="number"
            name="cost"
            id="cost"
            label="Cost"
            placeholder="Enter cost"
          />
          <Field
            component={NumberInput}
            name="textureWidth"
            id="textureWidth"
            label="Texture Width"
            placeholder="Enter Texture"
            allowDecimals
          />
          <Field
            component={NumberInput}
            name="textureHeight"
            id="textureHeight"
            label="Texture Height"
            placeholder="Enter price"
            allowDecimals
          />
          <Field
            component={Upload}
            name="image"
            id="image"
            label="Choose Image"
            accept="image/png, .jpeg, .jpg"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default OptionForm;
