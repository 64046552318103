import { useReducer, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  setFormValue,
  setValidationErrors,
  createQuickAccount,
} from './actions';
import createQuickAccountFormReducer from './reducer';

export * from './validations';

export default function useCopyQuoteFormState(salesRep) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    createQuickAccountFormReducer,
    {
      formData: {
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        paymentTerms: '',
        salesRep
      },
      errors: {},
      creating: false,
    },
  );

  const { formData } = state;

  return {
    state,
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      setValidationErrors: useCallback(setValidationErrors(dispatch), []),
      createQuickAccount: useCallback(createQuickAccount(dispatch, formData, goBack), [formData]),
    },
  };
}
