import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum UpdateShippingActions {
  SET_SHIPMENT = 'SET_SHIPMENT',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_FLAT_RATE = 'SET_FLAT_RATE',
  START_UPDATING = 'START_UPDATING',
  FINISH_UPDATING = 'FINISH_UPDATING',
  SET_ADDRESS_VALIDATION_ERRORS = 'SET_ADDRESS_VALIDATION_ERRORS',
  SET_FLAT_RATE_VALIDATION_ERRORS = 'SET_FLAT_RATE_VALIDATION_ERRORS',
  SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  COPY_FROM_ACCOUNT = 'COPY_FROM_ACCOUNT',
}
export interface FormAddressModel {
  id?: number;
  locationName: string;
  streetNumber: number;
  streetName: string;
  unitNumber: number;
  postalCode: string;
  city: string;
  province: DropdownOptionModel;
  change?: boolean;
}

export interface FromShipmentModel {
  id: number;
  shipmentType: DropdownOptionModel;
  shippingZone: DropdownOptionModel;
  change?: boolean;
}

export interface FormFlatRateModel{
  id?: number;
  price: number;
  shipment: number;
  change?: boolean;
}
export interface AccountAddress {
  street: string;
  city: string;
  postalCode: string;
}

export interface UpdateShippingFormState {
  shipment: FromShipmentModel;
  address: FormAddressModel;
  addressErrors: any;
  flatRateErrors: any;
  updating: boolean;
  selectedCountry: DropdownOptionModel;
  flatRate: FormFlatRateModel;
}
export default function updateShippingFormReducer(
  state: UpdateShippingFormState, action
): UpdateShippingFormState {
  const { type, payload } = action;

  switch (type as UpdateShippingActions) {
    case UpdateShippingActions.SET_SHIPMENT:
      return { ...state, shipment: payload };
    case UpdateShippingActions.SET_ADDRESS:
      return { ...state, address: payload };
    case UpdateShippingActions.COPY_FROM_ACCOUNT:
      return { ...state, address: { ...state.address, ...payload } };
    case UpdateShippingActions.SET_FLAT_RATE:
      return { ...state, flatRate: payload };
    case UpdateShippingActions.SET_SELECTED_COUNTRY:
      return { ...state, selectedCountry: payload };
    case UpdateShippingActions.SET_INITIAL_STATE:
      return { ...state, ...payload };
    case UpdateShippingActions.SET_ADDRESS_VALIDATION_ERRORS:
      return { ...state, addressErrors: payload, updating: false };
    case UpdateShippingActions.SET_FLAT_RATE_VALIDATION_ERRORS:
      return { ...state, flatRateErrors: payload, updating: false };
    case UpdateShippingActions.START_UPDATING:
      return { ...state, updating: true };
    case UpdateShippingActions.FINISH_UPDATING:
      return { ...state, updating: false };
    default:
      return state;
  }
}
