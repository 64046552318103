import React from 'react';
import Tooltip from '../../Tooltip';

export default function TrimAtColumnRender(length: number) {
  return (text: string) => {
    const renderText = text && text.length >= length
      ? `${text.slice(0, length - 3)}...`
      : text;

    return (
      <Tooltip placement="topLeft" title={text}>
        {renderText}
      </Tooltip>
    );
  };
}
