import { PaymentMethodModel } from 'models/payment-method';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_TRANSACTIONS_API } = process.env;
const paymentEndpoint = `${REACT_APP_TRANSACTIONS_API}/payment-methods/`;

const paymentMethodApi: ApiModel<PaymentMethodModel> = {
  ...getApi(paymentEndpoint, ApiContentType.JSON, true),
};

export default paymentMethodApi;
