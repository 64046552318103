import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { AssignFilesHistorySateModel } from 'components/pages/Quotes/Quote/Form/AssignFiles';

export default function openAssignFilesModal(push, quote: QuoteModel) {
  return () => {
    const historyLocationState: AssignFilesHistorySateModel = {
      assignFilesForm: {
        quoteId: quote.id,
      },
    };
    push(ModalRouteHash.AssignFilesForm, historyLocationState);
  };
}
