import React, { FunctionComponent, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { validateEntity } from 'helpers/form-validations';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import { validationRules } from './validations';
import { BuilderFormModel } from '../state';

export interface CopyProductsHistorySateModel {
  copyProductsForm: {
    product: BuilderFormModel;
  };
}

const CopyProductsForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { copyProductsForm: { product: { model } } } = state as CopyProductsHistorySateModel;
  const [formData, setFormData] = useState({ quantity: 1 });
  const [errors, setErrors] = useState({});
  const handleChange = useCallback((newState) => setFormData(newState), []);
  const handleErrors = useCallback((newState) => setErrors(newState), []);
  const handleSubmit = useCallback(
    () => {
      const { hasErrors, errors: validatedErrors } = validateEntity(formData, validationRules);
      if (hasErrors) {
        setErrors(validatedErrors);
      } else {
        notifyEventChannel(EventChannelList.BUILDER_COPY_PRODUCTS, formData);
      }
    },
    [formData]
  );

  return (
    <Modal
      title={`Copy Product ${model}`}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={handleSubmit}
    >
      <Form
        state={formData}
        onChange={handleChange}
        rules={validationRules}
        errors={errors}
        onError={handleErrors}
      >
        <Field
          type="number"
          name="quantity"
          id="quantity"
          label="Quantity"
          min={1}
        />
      </Form>
    </Modal>
  );
};

export default CopyProductsForm;
