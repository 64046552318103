import { resolveApiErrorMessage } from 'api/base';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { CustomProductFormModel } from 'models/order-product';
import orderProductApi from 'api/order-product';
import orderOptionsApi from 'api/order-option';
import { CustomProductFormActions } from '../reducer';
import { getPayloadOption, getOptions, getPayloadOrder } from './get-payload';

async function saveCustomProduct(data) {
  const dataSource = data.filter(row => !row.model || !row.name);
  if (dataSource.length === 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const orderProduct of data) {
      const recommendedPrice = orderProduct.product?.price || orderProduct.price;
      const [orderPayload, row] = getPayloadOrder({ ...orderProduct, recommendedPrice });
      // eslint-disable-next-line no-await-in-loop
      const order = await orderProductApi.create(orderPayload);

      if (order) {
        const rowPreparedOption = getOptions(row);
        // eslint-disable-next-line no-restricted-syntax
        for (const optionType of Object.keys(rowPreparedOption)) {
          if (rowPreparedOption[optionType]) {
            const payloadOptions = getPayloadOption(order.data.id, rowPreparedOption[optionType], optionType);
            // eslint-disable-next-line no-await-in-loop
            await orderOptionsApi.create(payloadOptions);
          }
        }
      }
    }
  }
  return dataSource;
}

export function addProducts(dispatch, formData: CustomProductFormModel[], goBack) {
  return () => {
    dispatch({ type: CustomProductFormActions.START_ADDING });

    const onSuccess = (dataSource) => {
      if (dataSource.length === 0) {
        const message = 'Products Successfully added';
        notification({ type: NotificationType.SUCCESS, message });
        notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED, true);
        goBack();
      } else {
        const message = 'Model and Name are Required';
        notification({ type: NotificationType.ERROR, message });
        dispatch({
          type: CustomProductFormActions.SET_ROW_ERROR, payload: dataSource.map(row => row.key)
        });
      }
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CustomProductFormActions.FINISH_ADDING });
    };

    return saveCustomProduct(formData)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
