import { getLoggedUserId } from 'helpers/get-logged-user';
import { DropdownOptionModel } from 'helpers/dropdown-options';

export interface QuickProjectModel {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  salesRep: string;
  jobTitle: string;
  paymentTerms: DropdownOptionModel;
}

export enum CreateQuickProjectFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_CREATING = 'START_CREATING',
  FINISH_CREATING = 'FINISH_CREATING',
  CLEAR_STATE = 'CLEAR_STATE'
}

interface CreateQuickAccountFormState {
  formData: QuickProjectModel;
  creating: boolean;
  errors: any;
}

export const emptyState = {
  email: '',
  firstName: '',
  lastName: '',
  companyName: '',
  salesRep: undefined,
  paymentTerms: { value: 'Prepaid', label: 'Prepaid' },
  jobTitle: '',
};

export default function createQuickProjectFormReducer(
  state: CreateQuickAccountFormState, action
): CreateQuickAccountFormState {
  const { type, payload } = action;

  switch (type as CreateQuickProjectFormActions) {
    case CreateQuickProjectFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case CreateQuickProjectFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, creating: false };
    case CreateQuickProjectFormActions.START_CREATING:
      return { ...state, creating: true };
    case CreateQuickProjectFormActions.FINISH_CREATING:
      return { ...state, creating: false };
    case CreateQuickProjectFormActions.CLEAR_STATE:
      return { ...state, formData: { ...emptyState, salesRep: getLoggedUserId() } };
    default:
      return state;
  }
}
