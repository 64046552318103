import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import Label from 'components/base-components/Label';
import RenderIf from 'components/base-components/RenderIf';
import InputError from 'components/base-components/InputError';
import './styles.scss';

export interface Props extends InputProps {
  label?: string;
  required?: boolean;
  error?: string;
  inputClassName?: string;
  mR?: boolean;
}

const PasswordInput: FunctionComponent<Props> = (props) => {
  const {
    id,
    label,
    className,
    inputClassName,
    required,
    error,
    mR,
    ...inputProps
  } = props;
  const wrapperClassNames = classnames('password-input__wrapper', { mR, error }, className);

  return (
    <div className={wrapperClassNames}>
      <RenderIf isTrue={!!label}>
        <Label text={label} forInput={id} required={required} />
      </RenderIf>
      <AntInput.Password
        id={id}
        className={inputClassName}
        {...inputProps}
      />
      <InputError error={error} />
    </div>
  );
};

PasswordInput.defaultProps = {
  placeholder: '',
};

export default PasswordInput;
