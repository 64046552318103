import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ShipmentDTO } from 'models/shipment';
import { QuoteModel } from 'models/quote';
import { ProjectDTOModel } from 'models/project';
import quoteApi from 'api/quote';
import extraChargeApi from 'api/extra-charge';
import { ExtraChargeModel } from 'models/extra-charge';
import useGetList from 'hooks/base/get-list';
import useGetOne from 'hooks/base/get-one';
import useCallApiAction from 'hooks/base/call-api-action';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import { quoteDropdownData } from 'helpers/dropdown-options';
import { isDealerLoggedUser } from 'helpers/get-logged-user';
import setFilters from './actions/set-filters';
import updateFilters from './actions/update-filters';
import convertToOrder from './actions/convert-to-order';
import toggleConvertModal from './actions/toggle-convert-modal';
import handleProjectChange from './actions/handle-project-change';
import handleContactChange from './actions/handle-contact-change';
import handleAccountChange from './actions/handle-account-change';
import getShipmentByProject from './actions/get-shipment-by-project';
import openCreateAccountModal from './actions/open-create-account-modal';
import openCopyQuoteModal from './actions/open-copy-quote-modal';
import openCreatUserModal from './actions/open-create-user-modal';
import openAddProduct3DModal from './actions/open-3D-add-product';
import openAddProductModal from './actions/open-add-product-modal';
import openCustomProductModal from './actions/open-custom-product-modal';
import openCreateQuoteModal from './actions/open-create-quote-modal';
import openCreateProjectModal from './actions/open-create-project-modal';
import openUpdateShipmentModal from './actions/open-update-shipment-modal';
import openApplyOptionsModal from './actions/open-apply-option-modal';
import handleNewProjectUpdate from './actions/handle-new-project';
import openShipmentDQ from './actions/open-shipment-dq';
import openPrintout, { PrintOuts } from './actions/open-print-outs';
import openSendEmailModal from './actions/open-send-email';
import printQuote from './actions/print-quote';
import printQuestionnaire from './actions/print-questionnaire';
import openSendDQEmailModal from './actions/open-send-questionaire-email';
import handleNewAccountUpdate from './actions/handle-new-account';
import openFileViewerModal from './actions/open-file-viewer';
import openAssignFilesModal from './actions/open-assign-files';
import bulkDelete from './actions/bulk-delete';
import { mapUsers } from './actions/map-functions';
import openCopyProductModal from './actions/open-copy-products';
import updateContactProject from './actions/update-contact-project';
import quoteViewReducer, { QuoteViewActions, QuoteViewState } from './reducer';

export * from './actions/map-functions';

const initialReducerState: QuoteViewState = {
  updatingFilters: false,
  filters: {},
  groups: [],
  showGroupModal: false,
  showCustomOptionModal: false,
  showManufacturerModal: false,
  showConvertModal: false,
  convertingToOrder: false,
  isIncompleteOrder: false,
  isConvertedToOrder: false,
  missingFields: undefined,
  fetchingHash: false,
  printingQuote: false,
  selectedRows: [],
  bulkDeleting: false,
};

export default function useQuoteViewState() {
  const { push, replace } = useHistory();
  const { id: quoteIdParam } = useParams<any>();
  const isDealer = useMemo(() => isDealerLoggedUser() as any, []);
  const [state, dispatch] = useReducer(quoteViewReducer, initialReducerState);
  const { quote: quoteFilter, account, project, contact } = state.filters;

  const quoteId = useMemo(() => quoteFilter?.value, [quoteFilter]);
  const quoteOptions = useMemo(
    () => ({
      preventAutoFetch: (
        quoteId &&
        quoteIdParam &&
        quoteId === parseInt(quoteIdParam, 10)
      ),
    }),
    [quoteId, quoteIdParam],
  );

  // noinspection JSUnusedGlobalSymbols
  const rowSelection = useMemo(() => ({
    selectedRowKeys: state.selectedRows,
    getCheckboxProps: (record) => {
      const key = record.id.toString();
      const isHidden = key.includes('group') || key.includes('subtotal');
      return {
        disabled: isHidden,
      };
    },
    onChange: (selectedRowKeys) => {
      dispatch({
        type: QuoteViewActions.SET_SELECTED_ROWS,
        payload: selectedRowKeys,
      });
    },
  }), [state.selectedRows]);

  const {
    fetching: fetchingQuote,
    data: dataQuote,
    fetchOne: reFetchQuote,
    fetchOneByParam,
    updateDataManually,
  } = useGetOne<QuoteModel>(quoteApi, quoteId, quoteOptions);

  const {
    data: { leadTimeCalculated },
    callAction: reFetchLeadTime,
  } = useCallApiAction(
    quoteApi.getLeadTimeCalculated,
    quoteId,
    { leadTimeCalculated: dataQuote?.leadTimeCalculated },
    !quoteId
  );

  const extraChargeParams = useMemo(
    () => ({ filters: { quote: quoteId } }),
    [quoteId],
  );

  const {
    fetching: fetchingExtraCharges,
    data: extraCharges,
  } = useGetList<ExtraChargeModel>(
    extraChargeApi,
    {
      queryParams: extraChargeParams,
      preventAutoFetch: !quoteId,
    },
  );

  const projectId = useMemo(() => project?.value, [project]);

  const {
    fetching: fetchingShipment,
    data: shipment,
    callAction: reFetchShipment,
  } = useCallApiAction<ShipmentDTO>(
    getShipmentByProject,
    projectId,
    undefined,
    !projectId,
  );

  const nameGroupQuote = useMemo(() => {
    if (dataQuote?.orderproducts) {
      const allGroups = dataQuote.orderproducts.map(product => product.group);
      const normalizedGroups = new Set(allGroups);
      return [...normalizedGroups];
    }
    return [];
  }, [dataQuote]);

  const nameGroup = useMemo(
    () => ([...new Set([...nameGroupQuote, ...state.groups])]),
    [nameGroupQuote, state.groups],
  );

  const groupCategories = useMemo(() => {
    const result = {};
    if (dataQuote?.orderproducts) {
      dataQuote.orderproducts.forEach(orderProduct => {
        if (orderProduct.category !== 'custom') {
          result[orderProduct.group] = result[orderProduct.group]
            ? [...result[orderProduct.group], orderProduct.category]
            : [orderProduct.category];
        }
      });
    }
    return result;
  }, [dataQuote]);

  const openGroupModal = useCallback(
    () => dispatch({ type: QuoteViewActions.TOGGLE_GROUP_MODAL }),
    [],
  );

  const openCustomOptionModal = useCallback(
    (optionGroup = undefined) => dispatch({
      type: QuoteViewActions.TOGGLE_CUSTOM_OPTION_MODAL,
      payload: optionGroup,
    }),
    [],
  );

  const openManufacturerModal = useCallback(
    () => dispatch({
      type: QuoteViewActions.TOGGLE_MANUFACTURER_MODAL,
    }),
    [],
  );

  const handleNewGroup = useCallback(({ group }) => {
    dispatch({ type: QuoteViewActions.PUSH_NEW_GROUP, payload: group });
  }, []);

  const handleNewAccount = useCallback(
    handleNewAccountUpdate(dispatch, dataQuote, updateDataManually),
    [dataQuote, updateDataManually]
  );

  const handleNewContact = useCallback((newContact) => {
    dispatch({
      type: QuoteViewActions.UPDATE_CONTACT_FILTER,
      payload: newContact,
    });
  }, []);

  const handleNewProject = useCallback(handleNewProjectUpdate(dispatch), []);

  const handleNewQuote = useCallback((nextQuote) => {
    dispatch({
      type: QuoteViewActions.UPDATE_FILTERS,
      payload: { quote: quoteDropdownData(nextQuote) },
    });
  }, []);

  useEffect(() => {
    if (leadTimeCalculated) {
      updateDataManually(
        { leadTimeCalculated },
        true
      );
    }
  }, [leadTimeCalculated, updateDataManually]);

  useEffect(() => {
    if (quoteIdParam) {
      fetchOneByParam(quoteIdParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shouldUpdateQuoteFilter = (
      dataQuote &&
      dataQuote.id !== quoteFilter?.value
    );

    if (shouldUpdateQuoteFilter) {
      dispatch({
        type: QuoteViewActions.SET_FILTERS,
        payload: { quote: quoteDropdownData(dataQuote) },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataQuote]);

  useEffect(() => {
    if (isDealer && !quoteIdParam) {
      const dealer = {
        value: isDealer.id,
        label: isDealer.name,
      };
      dispatch({
        type: QuoteViewActions.SET_FILTERS,
        payload: { contact: dealer },
      });
    }
  }, [isDealer, quoteIdParam]);

  useEffect(() => {
    updateFilters(dispatch, state.filters);
  }, [state.filters]);

  useEffect(() => {
    if (!quoteFilter && dataQuote) {
      updateDataManually({
        fetching: false,
        data: undefined,
        error: undefined,
      });
      dispatch({ type: QuoteViewActions.RESET_ORDER_STATES });
    }

    if (quoteFilter?.value) {
      setTimeout(() => replace(`/quote/${quoteFilter.value}`), 250);
    }

    if (quoteFilter?.data?.isconverted) {
      dispatch({ type: QuoteViewActions.CONVERT_TO_ORDER });
    }

    if (!(quoteFilter?.data?.isconverted) && state.isConvertedToOrder) {
      dispatch({ type: QuoteViewActions.RESET_ORDER_STATES });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteFilter]);

  useEventCenterUpdate(EventChannelList.QUOTES_LIST_CHANGED, reFetchQuote);
  useEventCenterUpdate(EventChannelList.SHIPPING_PROJECT_CHANGE, reFetchShipment);
  useEventCenterUpdate(
    EventChannelList.QUOTE_PAGE_OPEN_GROUP_MODAL,
    openGroupModal,
  );
  useEventCenterUpdate(
    EventChannelList.QUOTE_PAGE_OPEN_CUSTOM_OPTION_MODAL,
    openCustomOptionModal,
  );
  useEventCenterUpdate(
    EventChannelList.QUOTE_PAGE_OPEN_MANUFACTURER_MODAL,
    openManufacturerModal,
  );
  useEventCenterUpdate(EventChannelList.QUOTE_PAGE_NEW_GROUP, handleNewGroup);
  useEventCenterUpdate(EventChannelList.ACCOUNTS_LIST_CHANGED, handleNewAccount);
  useEventCenterUpdate(EventChannelList.QUOTE_PAGE_NEW_USER, handleNewContact);
  useEventCenterUpdate(EventChannelList.QUOTE_PAGE_NEW_PROJECT, handleNewProject);
  useEventCenterUpdate(EventChannelList.QUOTE_PAGE_NEW_QUOTE, handleNewQuote);

  return {
    state: {
      ...state,
      fetchingQuote,
      fetchingShipment,
      shipment,
      isDealer,
      contactFilterParams: useMemo(
        () => ({ search: account?.businessTradeName }),
        [account],
      ),
      projectFilterParams: useMemo(
        () => ({ client: isDealer?.id || contact?.value }),
        [contact, isDealer],
      ),
      quoteFilterParams: useMemo(
        () => ({ project__client: isDealer?.id, project: project?.value }),
        [project, isDealer],
      ),
      dataQuote,
      nameGroupForSelect: useMemo(
        () => {
          return nameGroup.reduce((list, group) => {
            if (group) {
              return list.concat([{ value: group, label: group }]);
            }
            return list;
          }, [{ value: '', label: 'None' }]);
        },
        [nameGroup],
      ),
      isEditable: useMemo(
        () => !quoteFilter || !state.isConvertedToOrder,
        [quoteFilter, state.isConvertedToOrder],
      ),
      extraCharges,
      fetchingExtraCharges,
      rowSelection,
    },
    actions: {
      setFilters: useCallback(setFilters(dispatch), []),
      openCreateAccountModal: useCallback(openCreateAccountModal(push), [push]),
      openCopyQuoteModal: useCallback(
        openCopyQuoteModal(push, dataQuote),
        [push, dataQuote],
      ),
      openFileViewerModal: useCallback(
        openFileViewerModal(push, dataQuote),
        [dataQuote],
      ),
      openAssignFilesModal: useCallback(
        openAssignFilesModal(push, dataQuote),
        [dataQuote],
      ),
      openCreatUserModal: useCallback(
        openCreatUserModal(push, account),
        [push, account],
      ),
      openAddProductModal: useCallback(
        openAddProductModal(push, dataQuote, account, nameGroup, !!isDealer),
        [push, dataQuote, account, nameGroup, isDealer],
      ),
      open3DAddProductModal: useCallback(
        openAddProduct3DModal(push, dataQuote, account, nameGroup),
        [push, dataQuote, account, nameGroup],
      ),
      openCopyProductModal: useCallback(
        openCopyProductModal(push, dataQuote, state.selectedRows, nameGroup),
        [push, dataQuote, state.selectedRows, nameGroup],
      ),
      openCustomProductModal: useCallback(
        openCustomProductModal(push, dataQuote, account, nameGroup),
        [push, dataQuote, account, nameGroup],
      ),
      openCreateQuoteModal: useCallback(
        openCreateQuoteModal(push, project),
        [push, project],
      ),
      openCreateProjectModal: useCallback(
        openCreateProjectModal(push, contact, account),
        [push, contact, account],
      ),
      openUpdateShipmentModal: useCallback(
        openUpdateShipmentModal(push, shipment, account),
        [push, shipment, account],
      ),
      openApplyOptionsModal: useCallback(
        openApplyOptionsModal(push, dataQuote, groupCategories, nameGroupQuote),
        [push, dataQuote, groupCategories, nameGroupQuote],
      ),
      openShipmentDQ: useCallback(
        openShipmentDQ(dispatch, shipment),
        [shipment],
      ),
      openWorkOrder: useCallback(
        openPrintout(PrintOuts.WorkOrder, dataQuote, shipment),
        [dataQuote, shipment],
      ),
      openPackingSlip: useCallback(
        openPrintout(PrintOuts.PackingSlip, dataQuote, shipment),
        [dataQuote, shipment],
      ),
      openThirdPartyPaint: useCallback(
        openPrintout(PrintOuts.ThirdPartyPaint, dataQuote, shipment),
        [dataQuote, shipment],
      ),
      openSendProposalEmailModal: useCallback(
        openSendEmailModal(push, dataQuote),
        [dataQuote],
      ),
      openSendEmailModal: useCallback(
        openSendEmailModal(push, dataQuote, true),
        [dataQuote],
      ),
      openSendDQEmailModal: useCallback(
        openSendDQEmailModal(push, dataQuote, shipment),
        [dataQuote, shipment],
      ),
      printQuote: useCallback(printQuote(dispatch, dataQuote, false), [dataQuote]),
      printQuestionnaire: useCallback(
        printQuestionnaire(dispatch, shipment),
        [shipment],
      ),
      printProposalQuote: useCallback(
        printQuote(dispatch, dataQuote, true),
        [dataQuote],
      ),
      handleAccountChange,
      handleContactChange,
      handleProjectChange,
      openGroupModal,
      openCustomOptionModal,
      openManufacturerModal,
      toggleConvertModal: useCallback(toggleConvertModal(dispatch), []),
      convertToOrder: useCallback(
        convertToOrder(dispatch, quoteFilter, push),
        [quoteFilter, push],
      ),
      bulkDelete: useCallback(
        bulkDelete(dispatch, state.selectedRows),
        [state.selectedRows],
      ),
      updateDataQuoteManually: updateDataManually,
      geLeadTimePayload: (data, newValue) => ({ id: data.id, leadTime: newValue }),
      geCustomerPOPayload: (data, newValue) => ({ id: data.id, customerPo: newValue }),
      onLeadTimeSuccess: useCallback(
        (data: QuoteModel) => {
          updateDataManually({
            data: {
              ...dataQuote,
              leadTime: data.leadTime,
              leadTimeCalculated: data.leadTime,
            },
          });
        },
        [updateDataManually, dataQuote],
      ),
      onCustomerPOSuccess: useCallback(
        (data: ProjectDTOModel) => {
          updateDataManually({
            data: {
              ...dataQuote,
              project: {
                ...dataQuote.project,
                customerPo: data.customerPo
              },
            },
          });
        },
        [updateDataManually, dataQuote],
      ),
      reFetchLeadTime,
      mapUsers: useCallback(mapUsers(updateContactProject(dispatch, projectId)), [projectId])
    }
  };
}
