import { TaskUserModel } from 'models/task-user';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_TASKS_API } = process.env;
const endpoint = `${REACT_APP_TASKS_API}/task-user/`;

const taskUserApi: ApiModel<TaskUserModel> = {
  ...getApi(endpoint, ApiContentType.JSON, 'user'),
};

export default taskUserApi;
