import React, { FunctionComponent } from 'react';
import { Collapse as AntCollapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';

export const { Panel } = AntCollapse;

const Collapse: FunctionComponent<CollapseProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntCollapse {...rest}>
      {children}
    </AntCollapse>
  );
};

export default Collapse;
