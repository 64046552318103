import React from 'react';
import isEqual from 'lodash/isEqual';
import projectsApi from 'api/projects';
import userApi from 'api/user';
import accountApi from 'api/account';
import { AccountModel } from 'models/account';
import { ProjectCompanyInfoModel } from 'models/project';
import { userDropdownData } from 'helpers/dropdown-options';
import { QuoteViewActions, QuoteViewFilters } from '../reducer';
import { AccountLabel } from './map-functions';

function buildAccountValue(account: AccountModel | ProjectCompanyInfoModel) {
  const {
    url,
    businessTradeName,
    businessLegalName,
    taxDetails,
    groupDetail,
    businessAddress,
    businessCity,
    businessPostCode,
  } = account;

  return {
    key: url,
    value: url,
    label: (<AccountLabel label={businessLegalName} url={url} />) as any,
    businessTradeName,
    allUsersDetails: [],
    taxDetails,
    groupDetail,
    businessAddress,
    businessCity,
    businessPostCode,
  };
}

export default async function updateFilters(dispatch, filters: QuoteViewFilters) {
  const { account, contact, project, quote } = filters;
  const hasSomeFilerWithValue = !!(account || contact || project || quote);
  const hasEmptyFilters = !account || !contact || !project;
  const willUpdateFilters = !!(
    (!project && quote) ||
    (!contact && project) ||
    (!account && contact)
  );

  if (hasSomeFilerWithValue && hasEmptyFilters && willUpdateFilters) {
    dispatch({ type: QuoteViewActions.START_UPDATING_FILTERS });

    const nextFilters = { ...filters };

    if (!nextFilters.project && quote && quote.project) {
      const { data } = await projectsApi.get(quote.project);

      nextFilters.project = {
        key: data.id,
        value: data.id,
        label: data.jobTitle,
        client: data.client,
      };

      if (!nextFilters.contact && data.clientInfo) {
        nextFilters.contact = userDropdownData(data.clientInfo, true);
      }

      if (!nextFilters.account && data.companyInfo) {
        nextFilters.account = data.companyInfo ? buildAccountValue(data.companyInfo) : null;
      }
    }

    if (!nextFilters.contact && nextFilters.project) {
      const { data } = await userApi.getById(nextFilters.project.client);
      nextFilters.contact = userDropdownData(data, true);
    }

    if (!nextFilters.account && nextFilters.contact) {
      const { data: { results } } = await accountApi.list({
        filters: {
          search: nextFilters.contact.value,
        },
      });
      const selectedAccount = results[0];

      if (selectedAccount) {
        nextFilters.account = buildAccountValue(selectedAccount);
      }
    }

    const hasFetchedNewFilters = !isEqual(filters, nextFilters);

    if (hasFetchedNewFilters) {
      dispatch({
        type: QuoteViewActions.SET_FILTERS,
        payload: nextFilters,
      });
    } else {
      dispatch({ type: QuoteViewActions.STOP_UPDATING_FILTERS });
    }
  }
}
