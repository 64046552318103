import { useEffect, useMemo } from 'react';
import { StoreModel } from 'models/store';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { isValidFile } from 'helpers/file';
import storeApi from 'api/store';
import { validationRules } from './validations';

const defaultStore: StoreModel = {
  nameLegal: '',
  address: '',
  creditCardAuthForm: null,
  currencyCode: '',
  domain: '',
  email: '',
  interactEmail: '',
  location: '',
  name: '',
  logoUrl: '',
  phone: '',
  taxNumber: null,
  logo: null,
};

const getPayload = (store: StoreModel) => ({
  ...store,
  logo: store.logoUrl && isValidFile(store.logoUrl[0]) ? store.logoUrl[0] : undefined,
  creditCardAuthForm:
    store.creditCardAuthForm && isValidFile(store.creditCardAuthForm[0]) ? store.creditCardAuthForm[0] : undefined,
});

const getFormData = (store: StoreModel) => {
  return store || defaultStore;
};

export default function useStoreFormState(storeUrl: number) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: storeApi,
    eventChannel: EventChannelList.STORE_SETTING_CHANGED,
    param: storeUrl,
    getPayload,
    getFormData,
    validationRules
  }), [storeUrl]);

  const {
    state: {
      formData: store,
      errors,
      saving,
      errorFetchingEntity: errorFetchingStore,
      fetchingEntity: fetchingStore,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<StoreModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingStore) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingStore,
      });
    }
  }, [errorFetchingStore]);

  return {
    state: {
      store,
      savingStore: saving,
      fetchingFormData: fetchingStore,
      errors,
    },
    actions: {
      setFormValue,
      saveStore: saveEntity,
      setErrors,
    },
  };
}
