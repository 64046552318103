import { useEffect, useMemo, useCallback, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import optionGroupsApi from 'api/option-group';
import useGetList from 'hooks/base/get-list';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { resolveApiErrorMessage } from 'api/base';
import CustomProductReducer, { initialOrder } from './reducer';
import {
  addProducts,
  addRow,
  deleteRow,
  saveRow,
  readExcel,
  handleNewCustomOption,
  getTemplate,
} from './actions';

export default function useCustomProductState(customProductForm) {
  const { groups, quote, discount } = customProductForm;
  const { goBack } = useHistory();
  const {
    fetching: fetchingOptionGroups,
    data: optionGroups,
    error: optionGroupsError
  } = useGetList(optionGroupsApi);

  const [state, dispatch] = useReducer(
    CustomProductReducer,
    {
      data: [initialOrder],
      errors: {},
      adding: false,
      reading: false,
      getting: false,
      excel: null,
      rowError: [],
    },
  );
  const { data } = state;
  const [groupForSelect, setGroupForSelect] = useState(groups);

  const setNewGroup = (newGroup) => {
    setGroupForSelect([...new Set([...groupForSelect, newGroup.group])]);
  };

  useEffect(() => {
    if (optionGroupsError) {
      notification({ type: NotificationType.ERROR, message: resolveApiErrorMessage(optionGroupsError) });
    }
  }, [optionGroupsError]);

  const handleCustomOption = useCallback(handleNewCustomOption(dispatch, data), [data]);
  useEventCenterUpdate(EventChannelList.QUOTE_PAGE_NEW_GROUP, setNewGroup);
  useEventCenterUpdate(
    EventChannelList.QUOTE_PAGE_NEW_CUSTOM_OPTION,
    handleCustomOption,
  );

  return {
    state: {
      ...state,
      fetching: fetchingOptionGroups,
      options: useMemo(() => (groupForSelect.map(group => ({ value: group, label: group === '' ? 'None' : group }))), [groupForSelect]),
    },
    actions: {
      handleAdd: useCallback(addRow(dispatch, data), [data]),
      handleDelete: useCallback(deleteRow(dispatch, data), [data]),
      handleSave: useCallback(saveRow(dispatch, data, quote, optionGroups, discount), [data, quote, optionGroups]),
      handleOnSubmit: useCallback(addProducts(dispatch, data, goBack), [data]),
      onChangeExcel: useCallback(readExcel(dispatch, data, quote, optionGroups, discount), [data, optionGroups, quote]),
      getTemplate: useCallback(getTemplate(dispatch), []),
    },
  };
}
