import { validateEntity } from 'helpers/form-validations';
import { ThreeMorphFormModel } from '../reducer';
import { morphValidationRules } from '../validations';

export default function validateMorphs(
  morphs: {[index: string]: ThreeMorphFormModel }
) {
  const morphsHaveErrors = Object.keys(morphs)
    .some(morph => validateEntity(morphs[morph], morphValidationRules).hasErrors);

  return {
    morphsHaveErrors,
    errors: {
      morphs: morphsHaveErrors ? 'Some morphs are missing values' : undefined
    },
  };
}
