import { useCallback, useEffect, useReducer } from 'react';
import projectCoordinatorApi from 'api/project-coordinator';
import useCallApiAction from 'hooks/base/call-api-action';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import setFormValue from './actions/set-form-value';
import setErrors from './actions/set-errors';
import onSave from './actions/handle-on-save';
import handleOnChange from './actions/handle-on-change';
import prepareState from './actions/prepare-state';
import handleOnetime from './actions/handle-onetime-form';
import notesFormReducer, { TaskFormModel } from './reducer';

export { validationRules } from './validation-rules';
export const initialTask: TaskFormModel = {
  details: '',
  priority: '0',
  endingStage: undefined,
  startingStage: undefined,
};

export default function useNotesState(orderId) {
  const [state, dispatch] = useReducer(
    notesFormReducer,
    {
      formData: initialTask,
      errors: undefined,
      saving: false,
      task: [],
      taskDone: [],
      onetimeForm: false
    },
  );

  const { formData, task, taskDone } = state;

  const {
    data,
    fetching,
    error,
  } = useCallApiAction(projectCoordinatorApi.getTask, orderId, []);

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (data.length > 0) {
      prepareState(dispatch, data);
    }
  }, [data]);

  useEffect(() => {
    if (task.length > 0) {
      const taskCompletePercentage = taskDone.length / task.length;
      notifyEventChannel(EventChannelList.PROJECT_COORDINATOR_UPDATE, { id: orderId, taskCompletePercentage });
    }
  }, [taskDone, task, orderId]);

  return ({
    state: {
      ...state,
      fetching
    },
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      handleOnetimeForm: useCallback(handleOnetime(dispatch), []),
      onSave: useCallback(onSave(dispatch, formData, task, orderId), [formData, task, orderId]),
      handleOnChange: useCallback(handleOnChange(dispatch, taskDone), [taskDone]),
    },
  });
}
