import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import categoriesApi from 'api/category';
import { mapTreeSelectOptionSimple } from 'helpers/dropdown-options';
import { CategoryFormActions } from '../reducer';

export function loadTreeSelectData(dispatch) {
  return async (treeNode, actionType: CategoryFormActions) => {
    try {
      const { id, label } = treeNode.props;
      const params = {
        filters: {
          parent: label,
        }
      };
      const response = await categoriesApi.list(params);
      const treeData = mapTreeSelectOptionSimple(response.data.results, id, 'categories');
      dispatch({ type: actionType, payload: treeData });
    } catch (error) {
      notification({ type: NotificationType.ERROR, message: resolveApiErrorMessage((error as any).response) });
    }
  };
}
