import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { PaymentFormNavigationState } from '../../../PaymentForm/state';

export default function openReceivePaymentModal(push, search) {
  return (order: any) => {
    push(`${search}${ModalRouteHash.ReceivePayment}`, {
      paymentForm: {
        data: {
          orderId: order.id,
          companyName: order.companyName,
          jobTitle: order.jobTitle,
          totalWithTax: order.totalWithTax,
        },
      },
    } as PaymentFormNavigationState);
  };
}
