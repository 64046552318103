import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import useGetOne from 'hooks/base/get-one';
import quoteApi from 'api/quote';
import purchaseOrderApi from 'api/purchase-order';
import sendEmail from './actions/send-email';
import setRecipients from './actions/set-recipients';
import setErrors from './actions/set-errors';
import getInitialState from './actions/get-initial-state';
import unselectS3Files from './actions/unselect-s3file';
import onUpload from './actions/on-upload';
import getFormData from './actions/get-form-data';
import sendEmailFormReducer from './reducer';

export enum EmailSender {
  Quote = 'Quote',
  QuoteProposal = 'QuoteProposal',
  QuoteQuestionnaire = 'QuoteQuestionnaire',
  PurchaseOrder = 'PurchaseOrder',
  PurchaseOrderFromProjectCoordinator = 'PurchaseOrderFromProjectCoordinator',
  Order = 'Order',
  Invoice = 'Invoice',
  ProformaInvoice = 'ProformaInvoice'
}

const getTemplateMap: { [sender: string]: any } = {
  [EmailSender.Quote]: quoteApi.emailTemplate,
  [EmailSender.QuoteProposal]: quoteApi.emailProposalTemplate,
  [EmailSender.PurchaseOrderFromProjectCoordinator]: purchaseOrderApi.emailTemplate,
};

export default function useSendEmailFormState() {
  const { location: { state: navigationState }, goBack } = useHistory();
  const [state, dispatch] = useReducer(
    sendEmailFormReducer,
    navigationState,
    getInitialState,
  );
  const { email: { s3Attachments }, fetchingData } = state;
  const id = (navigationState as any)?.sendEmailForm?.id;
  const emailSender = (navigationState as any)?.sendEmailForm?.from;
  const fromProjectCoordinator = emailSender === EmailSender.PurchaseOrderFromProjectCoordinator;
  const { data } = useGetOne(
    purchaseOrderApi,
    id,
    { preventAutoFetch: !fromProjectCoordinator }
  );
  const quoteId = fromProjectCoordinator ? data?.order?.quote?.id : id;

  useEffect(() => {
    getFormData(id, emailSender, getTemplateMap, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state: {
      ...state,
      emailSender,
      quoteId,
      fetching: fetchingData,
      title: useMemo<EmailSender>(() => {
        return (navigationState as any)?.sendEmailForm?.title || 'Send Email';
      }, [navigationState]),
      s3Files: useMemo(() => s3Attachments.map(file => {
        const slashIndex = file.lastIndexOf('/');
        const index = slashIndex > 0 ? slashIndex + 1 : 0;
        return {
          path: file,
          name: file.slice(index),
        };
      }), [s3Attachments]),
    },
    actions: {
      setRecipients: useCallback(setRecipients(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      unselectS3Files: useCallback(
        unselectS3Files(dispatch, s3Attachments),
        [s3Attachments],
      ),
      onUpload: useCallback(onUpload(dispatch, s3Attachments), [s3Attachments]),
      sendEmail: useCallback(
        sendEmail(dispatch, emailSender, quoteId, state.email, goBack),
        [state.email],
      ),
    },
  };
}
