import getEventValue from 'helpers/get-event-value';
import { userDropdownData } from 'helpers/dropdown-options';

export default function handleAccountChange(event, setFields, filters) {
  const { account: oldAccount } = filters;
  const nextAccount = getEventValue(event);

  if (oldAccount?.value !== nextAccount?.value) {
    const nextContact = nextAccount && nextAccount.allUsersDetails.length > 0
      ? userDropdownData(nextAccount.allUsersDetails[0], true)
      : null;

    setFields({
      account: nextAccount,
      contact: nextContact,
      project: null,
      quote: null,
    });
  }
}
