import { useEffect, useMemo } from 'react';
import installerBookingApi from 'api/installer-booking';
import projectApi from 'api/projects';
import { InstallerBookingModel } from 'models/installer-booking';
import { EventChannelList } from 'helpers/event-center';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import useCallApiAction from 'hooks/base/call-api-action';
import notification, { NotificationType } from 'helpers/notification';
import { ProjectModel } from 'models/project';

const useTableStateParams: UseTableStateParams = {
  api: installerBookingApi,
  deleteModalTitle: 'Delete Installer Booking',
  deleteModalContent: (installerToDelete: InstallerBookingModel) => `Are you sure that you want to delete the Booking for the Installer ${installerToDelete ? installerToDelete.installer?.companyName : ''}?`,
  eventChannel: EventChannelList.INSTALLER_BOOKING_LIST_CHANGE,
  defaultSortField: '-installation_date'
};

export default function useInstallerBookingState() {
  const { state, actions } = useTableState<InstallerBookingModel>(useTableStateParams);
  const { data: installerBooking, fetching: fetchingInstaller } = state;

  const ProjectsParams = useMemo(() => {
    const projectIds = installerBooking.map(ins => ins.shipment.project);
    const projectsIDsSet = [...new Set(projectIds)];
    const queryTemplate = projectsIDsSet.reduce((template, projectId) => `${template}${projectId},`, '');
    return {
      filters: {
        id__in: queryTemplate
      }
    };
  }, [installerBooking]);

  const {
    fetching: fetchingProjects,
    data: { results: projects },
    error: projectError,
  } = useCallApiAction<{ results: ProjectModel[] }>(
    projectApi.list,
    ProjectsParams,
    { results: [] },
    fetchingInstaller || installerBooking.length === 0,
  );

  useEffect(() => {
    if (projectError) {
      notification({
        type: NotificationType.ERROR,
        message: projectError,
      });
    }
  }, [projectError]);

  return {
    state: {
      ...state,
      fetching: fetchingInstaller || fetchingProjects,
      data: useMemo(() => {
        if (projects.length > 0 && projects && installerBooking.length > 0) {
          return installerBooking.map((installer) => {
            const project = projects.find((i) => i.id === installer.shipment.project);
            if (project) {
              return {
                ...installer,
                jobTitle: project.jobTitle,
              };
            }
            return installer;
          });
        }

        return installerBooking;
      }, [projects, installerBooking]),
    },
    actions
  };
}
