import { EditOptionsFromModel } from 'components/pages/Quotes/Quote/Form/EditOptions/state';

export enum ProductFormActions {
  SET_FORM_VALUE = 'SET_FORM_VALUE',
}

interface ProductFormState {
  formData: EditOptionsFromModel;
}

export default function productFormReducer(state: ProductFormState, action): ProductFormState {
  const { type, payload } = action;

  switch (type as ProductFormActions) {
    case ProductFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    default:
      return state;
  }
}
