const In2M = 2.54 / 100;

// left pedestal cases
export const SCENARIO_LP1_0 = {
  id: 'f59937fc-512a-11ec-bf63-0242ac130002',
  marginFront: 0.1 * In2M,
};

export const SCENARIO_LP1_1 = {
  id: 'f59937fc-512a-11ec-bf63-0242ac130002',
  marginFront: 12 * In2M,
};

export const SCENARIO_LP1_2 = {
  id: 'f59937fc-512a-11ec-bf63-0242ac130002',
  morph: [
    {
      label: 'Width',
      index: 1,
      min: 18,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'width',
        delta: {
          default: 2,
          target: '1e229b32-512b-11ec-bf63-0242ac130002',
          field: 'depth',
          delta: 1.1,
        },
      },
    },
    {
      label: 'Depth',
      index: 2,
      followMaxValue: true,
      min: 20,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'depth',
        delta: 1.2,
      },
    },
  ],
};

export const SCENARIO_LP1_3 = {
  id: 'f59937fc-512a-11ec-bf63-0242ac130002',
  morph: [
    {
      label: 'Width',
      index: 1,
      min: 18,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'width',
        delta: {
          default: 2,
          target: '1e229b32-512b-11ec-bf63-0242ac130002',
          field: 'depth',
          delta: 1.1,
        },
      },
    },
    {
      label: 'Depth',
      index: 2,
      followMaxValue: true,
      min: 20,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'depth',
        delta: 13.2,
      },
    },
  ],
};

export const SCENARIO_LP1_4 = {
  id: 'f59937fc-512a-11ec-bf63-0242ac130002',
  morph: [
    {
      label: 'Width',
      index: 1,
      min: 18,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'width',
        delta: {
          default: 2,
          target: '1e229b32-512b-11ec-bf63-0242ac130002',
          field: 'depth',
          delta: 1.1,
        },
      },
    },
    {
      label: 'Depth',
      index: 2,
      followMaxValue: true,
      min: 20,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'depth',
        delta: 7.2,
      },
    },
  ],
};

// right pedestal 1 cases
export const SCENARIO_RP1_0 = {
  id: '08cdc612-512b-11ec-bf63-0242ac130002',
  hidden: true,
};

export const SCENARIO_RP1_1 = {
  id: '08cdc612-512b-11ec-bf63-0242ac130002',
  hidden: false,
};

export const SCENARIO_RP1_2 = {
  id: '08cdc612-512b-11ec-bf63-0242ac130002',
  morph: [
    {
      label: 'Width',
      index: 1,
      min: 18,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'width',
        delta: 2,
      },
    },
    {
      label: 'Depth',
      index: 2,
      followMaxValue: true,
      min: 20,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'depth',
        delta: 1.2,
      },
    },
  ],
};

export const SCENARIO_RP1_3 = {
  id: '08cdc612-512b-11ec-bf63-0242ac130002',
  morph: [
    {
      label: 'Width',
      index: 1,
      min: 18,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'width',
        delta: 2,
      },
    },
    {
      label: 'Depth',
      index: 2,
      followMaxValue: true,
      min: 20,
      max: {
        target: '9dda8ac0-512a-11ec-bf63-0242ac130002',
        field: 'depth',
        delta: 7.2,
      },
    },
  ],
};

// right return
export const SCENARIO_RR_0 = {
  id: '1e229b32-512b-11ec-bf63-0242ac130002',
  morph: [
    { label: 'Width', index: 1, min: 60, max: 108 },
    { label: 'Depth', index: 2, min: 24, max: 24 },
  ],
};

export const SCENARIO_RR_1 = {
  id: '1e229b32-512b-11ec-bf63-0242ac130002',
  morph: [
    { label: 'Width', index: 1, min: 60, max: 108 },
    { label: 'Depth', index: 2, min: 24, max: 48 },
  ],
};

export const SCENARIO_RR_2 = {
  id: '1e229b32-512b-11ec-bf63-0242ac130002',
  includes: { RTN: ['RR'] },
};

export const SCENARIO_RR_3 = {
  id: '1e229b32-512b-11ec-bf63-0242ac130002',
  includes: { RTN: ['WR'] },
};

// right pedestal 2 cases
export const SCENARIO_RP2_0 = {
  id: '521fc342-512b-11ec-bf63-0242ac130002',
  hidden: true,
};

export const SCENARIO_RP2_1 = {
  id: '521fc342-512b-11ec-bf63-0242ac130002',
  hidden: false,
};

const CASES = [
  {
    id: '9dda8ac0-512a-11ec-bf63-0242ac130002',
    product_names: ['DESK-RFF', 'DESK-RFFLHG', 'DESK-RRF'],
    changes: [SCENARIO_LP1_0, SCENARIO_RR_1],
  },
  {
    id: '9dda8ac0-512a-11ec-bf63-0242ac130002',
    product_names: ['DESK-RFF', 'DESK-RFFLHG'],
    changes: [SCENARIO_LP1_2, SCENARIO_RP1_2],
  },
  {
    id: '9dda8ac0-512a-11ec-bf63-0242ac130002',
    product_names: ['DESK-RRF', ''],
    changes: [SCENARIO_LP1_4, SCENARIO_RP1_3],
  },
  {
    id: '9dda8ac0-512a-11ec-bf63-0242ac130002',
    product_names: ['DESK-ECUL', 'DESK-RCR', 'DESK-WVL'],
    changes: [SCENARIO_LP1_1, SCENARIO_LP1_3, SCENARIO_RP1_2, SCENARIO_RR_0],
  },
  {
    id: '9dda8ac0-512a-11ec-bf63-0242ac130002',
    product_names: [
      'DESK-ECUL',
      'DESK-RCR',
      'DESK-RFF',
      'DESK-RFFLHG',
      'DESK-RRF',
    ],
    changes: [SCENARIO_RR_2],
  },
  {
    id: '9dda8ac0-512a-11ec-bf63-0242ac130002',
    product_names: ['DESK-WVL', ''],
    changes: [SCENARIO_RR_3],
  },
  {
    id: '1e229b32-512b-11ec-bf63-0242ac130002',
    product_names: ['*'],
    changes: [SCENARIO_RP1_0, SCENARIO_RP2_1],
  },
  {
    id: '1e229b32-512b-11ec-bf63-0242ac130002',
    product_names: [null],
    changes: [SCENARIO_RP1_1, SCENARIO_RP2_0],
  },
];

export default CASES;
