import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { ProductModel } from 'models/product';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';
import productApi from 'api/product';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import { BuilderFormModel } from '../../pages/Builder/state';
import './styles.scss';

interface Props {
  selectedProduct: BuilderFormModel;
}

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/category/`;

const ImageScene: FunctionComponent<Props> = (props) => {
  const { selectedProduct } = props;
  const model = useMemo(() => {
    if (selectedProduct) {
      const { modelDescription } = selectedProduct;
      return Object.keys(modelDescription).reduce((category, property) => {
        if (typeof modelDescription[property] === 'number') {
          return category;
        }
        return category.concat((modelDescription[property] as any)?.value || modelDescription[property]);
      }, []).join('-');
    }
    return undefined;
  }, [selectedProduct]);

  const {
    data,
    fetching,
    error,
  } = useGetOne<ProductModel>(productApi, `${endpoint}${model}`, { preventAutoFetch: !selectedProduct });

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: error,
      });
    }
  }, [error]);

  return (
    <div className="add-product-3D__image-scene-wrapper">
      <RenderIf isTrue={!fetching && !!data} fallback={<Spinner />}>
        <img className="add-product-3D__image-scene-image" src={data?.image} alt={model} />
      </RenderIf>
    </div>
  );
};

export default ImageScene;
