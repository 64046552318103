import React, { FunctionComponent } from 'react';
import { Radio } from 'antd';

interface Props {
  value: any;
  label: any;
}

export const Option: FunctionComponent<Props> = (props) => {
  const { value, label } = props;

  return (
    <Radio value={value}>{label}</Radio>
  );
};
