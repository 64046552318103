import { validateEntity } from 'helpers/form-validations';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { validationRules } from '../../validations';
import { SaveDesignFormActions } from '../reducer';

export default function onSubmit(dispatch, formData, goBack, products) {
  // eslint-disable-next-line consistent-return
  return () => {
    const { hasErrors, errors: validatedErrors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: SaveDesignFormActions.SET_ERRORS,
        payload: validatedErrors,
      });
    }
    const flowPlanner = {
      client: formData.client?.value,
      projectName: formData.project,
      quoteId: null,
      staffLocked: false,
      account: formData.account?.value,
    };
    notifyEventChannel(EventChannelList.BUILDER_REQ_SAVE_JSON, { products, flowPlanner });
    goBack();
  };
}
