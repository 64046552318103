import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AccountApplicationBusinessType,
  AccountApplicationModel,
  AccountApplicationStatus
} from 'models/account-application';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import accountApplicationApi from 'api/account-application';
import { EventChannelList } from 'helpers/event-center';
import validationsRules from './validations';
import getFormData from './actions/get-form-data';
import getPayload from './actions/get-payload';

export interface AccountApplicationFormModel {
  businessTradeName: string;
  status: AccountApplicationStatus;
  ownerFirstName: string;
  ownerLastName: string;
  ownerEmail: string;
  businessLegalName: string;
  website: string;
  businessType: AccountApplicationBusinessType;
  businessPhone: string;
  businessAddress: string;
  businessCity: string;
  businessStateProvince: string;
  businessPostCode: string;
  businessEmail: string;
  businessNumber: string;
  irsGstNumber: string;
  yearsInBusiness: string;
  creditAmount: string;
  apName: string;
  apEmail: string;
  taxExempt: any;
  bankName: string;
  accountNumber: string;
  bankAddress: string;
  bankCity: string;
  bankStateProvince: string;
  bankPostCode: string;
  bankPhone: string;
  ref1BusinessName: string;
  ref1ContactEmail: string;
  ref1ContactPhone: string;
  ref2BusinessName: string;
  ref2ContactEmail: string;
  ref2ContactPhone: string;
  ref3BusinessName: string;
  ref3ContactEmail: string;
  ref3ContactPhone: string;
}

export default function useAccountApplicationFormState() {
  const history = useHistory();
  const onSuccess = useCallback(() => history.push('/login'), [history]);
  const useFormStateParams: UseFormStateParams = useMemo(() => {
    return {
      api: accountApplicationApi,
      eventChannel: EventChannelList.ACCOUNT_APPLICATIONS_LIST_CHANGED,
      validationRules: validationsRules,
      getFormData,
      getPayload,
      onSuccess,
    };
  }, [onSuccess]);

  const {
    state: {
      formData: accountApplication,
      errors,
      saving,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<AccountApplicationModel>(useFormStateParams);

  return {
    state: {
      accountApplication,
      savingAccountApplication: saving,
      errors,
    },
    actions: {
      setFormValue,
      setErrors,
      saveAccountApplication: saveEntity,
    },
  };
}
