import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import { setFormValue, copyQuote, setValidationErrors } from './actions';
import copyQuoteFormReducer from './reducer';

export default function useCopyQuote(id) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    copyQuoteFormReducer,
    {
      formData: {
        comments: ''
      },
      errors: {},
      coping: false,
    },
  );
  const { formData } = state;
  return {
    state: {
      ...state
    },
    actions: {
      handleValueChange: useCallback(setFormValue(dispatch), []),
      setValidationErrors: useCallback(setValidationErrors(dispatch), []),
      handleOnSubmit: useCallback(copyQuote(dispatch, formData, id, goBack), [formData, id]),
    },
  };
}
