import React, { FunctionComponent } from 'react';
import ErrorBoundary from 'components/base-components/ErrorBoundry';
import Header from '../WorkOrder/Header';
import { errorScreen, fetchingScreen, noDataScreen } from '../WorkOrder/screens';
import OrderInfo from './OrderInfo';
import ProductsList from './ProductsList';
import useThirdPartyPaintState from './state';
import './styles.scss';

const ThirdPartyPaint: FunctionComponent = () => {
  const {
    noData,
    fetching,
    quote,
    contact,
    products,
    error,
  } = useThirdPartyPaintState();

  if (noData) {
    return noDataScreen;
  }

  if (error) {
    return errorScreen;
  }

  if (fetching) {
    return fetchingScreen;
  }

  const { project: { jobTitle }, orders } = quote;
  const [order] = orders;
  const { id: workOrder } = order;

  return (
    <ErrorBoundary errorMessage={errorScreen}>
      <section className="third-party-paint">
        <Header title="Third Party Paint" workOrder={workOrder} jobTitle={jobTitle} />
        <OrderInfo order={order} contact={contact} />
        <ProductsList products={products} />
      </section>
    </ErrorBoundary>
  );
};

export default ThirdPartyPaint;
