import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { TaskModel } from 'models/task';
import taskApi from 'api/task';
import taskUserApi from 'api/task-user';
import { getLoggedUserId } from 'helpers/get-logged-user';
import useGetOne from 'hooks/base/get-one';
import useGetList from 'hooks/base/get-list';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import calendarReducer, { CalendarActions } from './reducer';
import generateSchedule from './actions/generate-schedule';
import openEditTaskModal from './actions/open-edit-task';

export default function useCalendarState() {
  const { goBack } = useHistory();
  const loggedUserId = useMemo(() => getLoggedUserId(), []);
  const [state, dispatch] = useReducer(calendarReducer, {
    schedule: undefined,
    generatingSchedule: true,
    task: null,
    isShowTaskForm: false,
  });

  const {
    fetching: loadingInstance,
    data: taskUser,
  } = useGetOne(taskUserApi, loggedUserId);

  const options = useMemo(() => ({
    queryParams: {
      filters: {
        assignee: loggedUserId
      }
    },
    preventAutoFetch: !taskUser,
  }), [loggedUserId, taskUser]);

  const {
    fetching: fetchingTasks,
    data: tasks,
    error: tasksError,
    fetchList,
  } = useGetList<TaskModel>(taskApi, options);

  const handleUpdates = useCallback(() => fetchList(options.queryParams), [fetchList, options.queryParams]);

  useEventCenterUpdate(EventChannelList.TASK_PAGE_LIST_CHANGED, handleUpdates);

  useEffect(() => {
    if (!fetchingTasks && !loadingInstance) {
      generateSchedule(dispatch, tasks);
    }
  }, [tasks, fetchingTasks, loadingInstance]);

  useEffect(() => {
    if (tasksError) {
      notification({
        type: NotificationType.ERROR,
        message: tasksError,
      });
    }
  }, [tasksError]);

  return {
    state: {
      ...state,
    },
    actions: {
      goBack: useCallback(goBack, []),
      onClose: useCallback(() => dispatch({ type: CalendarActions.CLOSE_TASK_FORM }), []),
      openEditTaskModal: useCallback(openEditTaskModal(dispatch), []),
    }
  };
}
