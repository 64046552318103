import { BuilderFormModel } from 'components/pages/Builder/state';

export enum CategoryImageFormActions {
  SET_IMAGE = 'SET_IMAGE',
  START_FETCHING_PRODUCT = 'START_FETCHING_PRODUCT',
  STOP_FETCHING_PRODUCT = 'START_FETCHING_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  SET_PRODUCT = 'SET_PRODUCT',
  START_SAVING = 'START_SAVING',
  FINISH_SAVING = 'FINISH_SAVING',
}

interface CategoryImageFormState {
  image: string;
  style: {
    url: string;
    name: string;
  };
  selectedProduct: BuilderFormModel;
  fetchingProductData: boolean;
  errors: any;
  saving: boolean;
}

export const initialState: CategoryImageFormState = {
  image: undefined,
  style: undefined,
  errors: false,
  selectedProduct: undefined,
  fetchingProductData: false,
  saving: false,
};

export default function categoryImageFormReducer(state: CategoryImageFormState, action): CategoryImageFormState {
  const { type, payload } = action;

  switch (type as CategoryImageFormActions) {
    case CategoryImageFormActions.SET_IMAGE:
      return { ...state, image: payload };
    case CategoryImageFormActions.START_FETCHING_PRODUCT:
      return { ...state, fetchingProductData: true };
    case CategoryImageFormActions.STOP_FETCHING_PRODUCT:
      return { ...state, fetchingProductData: false, };
    case CategoryImageFormActions.SET_PRODUCT: {
      return {
        ...state,
        selectedProduct: payload.product,
        style: payload.style,
        image: payload.image,
        fetchingProductData: false,
      };
    }
    case CategoryImageFormActions.UPDATE_PRODUCT:
      return { ...state, selectedProduct: payload };
    case CategoryImageFormActions.START_SAVING:
      return { ...state, saving: true };
    case CategoryImageFormActions.FINISH_SAVING:
      return { ...state, saving: false };
    default:
      return state;
  }
}
