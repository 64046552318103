import { useCallback, useMemo, useState } from 'react';

export default function usePOItemsState() {
  const [formState, setFormState] = useState({ purchaseOrder: undefined });
  const [purchaseOrderId, setPurchaseOrderId] = useState('');

  return {
    state: {
      purchaseOrder: useMemo(() => formState.purchaseOrder?.dropdownItem, [formState]),
      purchaseOrderId,
      formState
    },
    actions: {
      handleOnChange: useCallback((e) => {
        setFormState(e);
        setPurchaseOrderId(e.purchaseOrder.value);
      }, []),
    }
  };
}
