import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import partApi from 'api/part';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { PartModel } from 'models/part';
import { EventChannelList } from 'helpers/event-center';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import Help from 'components/forms/CommonHelp';
import { PartFormHistorySateModel } from '../Form';
import { PartDetailsHistorySateModel } from './Details';
import { permissions } from './permissions';

const useTableStateParams: UseTableStateParams = {
  api: partApi,
  deleteModalTitle: 'Delete Part',
  deleteModalContent: (partToDelete: PartModel) => `Are you sure that you want to delete the Part ${partToDelete ? partToDelete.name : ''}?`,
  searchFields: ['name', 'mpn', 'manufacturer'],
  eventChannel: EventChannelList.PARTS_LIST_CHANGE,
  defaultSortField: '-id'
};

const PartsList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useTableState<PartModel>(useTableStateParams);

  const openPartFormModal = (part?: PartModel | any) => {
    const historyLocationState: PartFormHistorySateModel = {
      PartForm: {
        partId: part ? part.id : undefined,
      },
    };
    push(ModalRouteHash.PartForm, historyLocationState);
  };

  const openPartDetailModal = (part: PartModel) => {
    const { description, image } = part;
    const historyLocationState: PartDetailsHistorySateModel = {
      partDetail: {
        image,
        description
      },
    };
    push(ModalRouteHash.PartDetail, historyLocationState);
  };

  return (
    <Page title="Parts">
      <Help view="parts" title="Parts List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addParts}>
          <Button
            onClick={openPartFormModal}
            label="Add Part"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="mpn" columnKey="mpn" title="Mpn" sorter />
        <DataTableColumn dataIndex="description" columnKey="description" title="Name" sorter />
        <DataTableColumn dataIndex="name" columnKey="name" title="TFG Name" sorter />
        <DataTableColumn dataIndex="units" columnKey="units" title="Units" sorter />
        <DataTableColumn
          dataIndex="price"
          columnKey="price"
          title="Price"
          sorter
          isCurrency
          showBreakpoint={733}
        />
        <DataTableColumn
          dataIndex="manufacturer"
          columnKey="manufacturer"
          title="Manufacturer"
          sorter
          showBreakpoint={1144}
        />
        <DataTableColumn
          dataIndex="standardOrder"
          columnKey="standardOrder"
          title="Standard"
          showBreakpoint={1237}
        />
        <DataTableColumn
          dataIndex="minOrder"
          columnKey="minOrder"
          title="Min"
          showBreakpoint={1294}
        />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editParts}
          deletePermissions={permissions.deleteParts}
          viewPermissions={permissions.viewParts}
          onShowDetails={openPartDetailModal}
          onEdit={openPartFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default PartsList;
