import { Rule, RuleType } from 'helpers/form-validations';

const requiredRule: Rule = { type: RuleType.Required, message: 'This field is required' };
const emailRule: Rule = { type: RuleType.Email, message: 'The email must be a valid email' };

const validationsRules = {
  businessTradeName: [requiredRule],
  ownerFirstName: [requiredRule],
  ownerLastName: [requiredRule],
  ownerEmail: [requiredRule, emailRule],
  businessLegalName: [requiredRule],
  website: [
    requiredRule,
    { type: RuleType.WebSite, message: 'The site url must be a valid url' },
  ],
  businessPhone: [requiredRule],
  businessAddress: [requiredRule],
  businessCity: [requiredRule],
  businessStateProvince: [requiredRule],
  businessPostCode: [requiredRule],
  businessEmail: [requiredRule, emailRule],
  businessNumber: [requiredRule],
  irsGstNumber: [requiredRule],
  yearsInBusiness: [requiredRule],
  apName: [requiredRule],
  apEmail: [requiredRule, emailRule],
  bankName: [requiredRule],
  accountNumber: [requiredRule],
  bankAddress: [requiredRule],
  bankCity: [requiredRule],
  bankStateProvince: [requiredRule],
  bankPostCode: [requiredRule],
  bankPhone: [requiredRule],
  ref1BusinessName: [requiredRule],
  ref1ContactEmail: [requiredRule, emailRule],
  ref1ContactPhone: [requiredRule],
};

export default validationsRules;
