import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import orderProductApi from 'api/order-product';
import { formatCurrency } from 'helpers/formatting';
import hasPermissions from 'helpers/permissons';
import { QuoteModel } from 'models/quote';
import { ShipmentDTO } from 'models/shipment';
import { AccountTaxModel } from 'models/account';
import { ExtraChargeModel } from 'models/extra-charge';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTable from 'components/base-components/DataTableV2';
import Select from 'components/base-components/Select';
import NumberInput from 'components/base-components/NumberInput';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import renderContent from './templates/render-content';
import totalTemplate from './templates/total-template';
import modelTemplate from './templates/model-template';
import productTemplate from './templates/product-template';
import rowClassName from './row-class-name';
import statusTemplate from './templates/status-template';
import { EditOptionsHistorySateModel } from '../Form/EditOptions';
import { DeficiencyItemFormHistorySateModel } from '../../../DeficiencyItems/Form';
import useQuoteProductsListState from './state';
import unitPriceTemplate from './templates/unit-price-template';
import QuoteProductsListSummary from './QuoteProductsListSummary';
import './styles.scss';
import { permissions } from '../permissions';
import { AccountDropdownModel } from '../state/reducer';

interface Props {
  quote: QuoteModel;
  loading: boolean;
  groupOptions: DropdownOptionModel[];
  isQuote?: boolean;
  shipment: ShipmentDTO;
  taxes: AccountTaxModel[];
  updateDataQuoteManually: any;
  reFetchLeadTime: any;
  charges: ExtraChargeModel[];
  rowSelection: any;
  isDealer: boolean;
  account: AccountDropdownModel;
}

const getColumns = (cantEditProduct, isDealer) => {
  if (cantEditProduct) {
    return 5;
  }
  if (isDealer) {
    return 6;
  }
  return 7;
};

const QuoteProductsList: FunctionComponent<Props> = (props) => {
  const {
    quote,
    loading,
    groupOptions,
    isQuote,
    shipment,
    taxes,
    updateDataQuoteManually,
    reFetchLeadTime,
    charges,
    rowSelection,
    isDealer,
    account
  } = props;
  const { push } = useHistory();

  const {
    state: {
      expandedRowKeys,
      hasOrder,
      quoteProducts,
      orderTotal,
      shippingPrice,
      extraCharges,
      deletingExtraChargeIndex,
    },
    actions: {
      handleChangeProductInline,
      handleDeleteRow,
      handleExtraChargeChanged,
      handleAddExtraCharge,
      handleRemoveExtraCharge,
      getPricePayload
    }
  } = useQuoteProductsListState(
    quote,
    shipment,
    updateDataQuoteManually,
    charges,
    reFetchLeadTime,
  );

  const cantEditProduct = useMemo(() => !isQuote && !hasOrder, [isQuote, hasOrder]);

  const isOrderAndDealer = useMemo(() => !isQuote && isDealer, [isQuote, isDealer]);

  const hasEditPermission = useMemo(() => hasPermissions(permissions.editQuote), []);

  const onEdit = (rowData) => {
    const historyLocationState: EditOptionsHistorySateModel = {
      editOptionsForm: {
        order: rowData,
        discount: account?.groupDetail?.discount
          ? parseFloat(account.groupDetail.discount)
          : 0,
      },
    };
    push(ModalRouteHash.EditOptions, historyLocationState);
  };

  const handleDisabled = (rowData) => rowData.isDeficient;

  const openDeficiencyFormModal = (rowData) => {
    const historyLocationState: DeficiencyItemFormHistorySateModel = {
      deficiencyItemForm: {
        orderProductId: rowData.id
      },
    };
    push(ModalRouteHash.DeficiencyItemForm, historyLocationState);
  };

  const hideActions = (rowData) => {
    if (rowData && typeof rowData.id === 'string') {
      if (rowData.group || rowData.subtotal) {
        return rowData.id.includes('group') ? 0 : 1;
      }
      return 0;
    }
    return 1;
  };
  const handleOnEdit = isQuote ? onEdit : undefined;
  const handleOnDelete = isQuote ? handleDeleteRow : undefined;
  const handleHideDeficientAction = useCallback(() => isQuote || isDealer, [isQuote, isDealer]);
  const getColspanSummary = useCallback(() => {
    const IfAnyColumnHidden = window?.innerWidth < 1444 ? 1 : 0;
    if (cantEditProduct) {
      return 4 - IfAnyColumnHidden;
    }
    if (isOrderAndDealer) {
      return 5 - IfAnyColumnHidden;
    }
    if (isQuote) {
      return 7 - IfAnyColumnHidden;
    }
    return 6 - IfAnyColumnHidden;
  }, [cantEditProduct, isQuote, isOrderAndDealer]);

  const [colspanSummary, setColspanSummary] = useState(getColspanSummary());

  const summary = useCallback(() => {
    return (
      <QuoteProductsListSummary
        orderTotal={orderTotal}
        shippingPrice={shippingPrice}
        taxes={taxes}
        colSpan={colspanSummary}
        extraCharges={extraCharges}
        handleAddExtraCharge={handleAddExtraCharge}
        handleRemoveExtraCharge={handleRemoveExtraCharge}
        handleExtraChargeChanged={handleExtraChargeChanged}
        deletingExtraChargeIndex={deletingExtraChargeIndex}
        isDealer={isDealer}
      />
    );
  }, [
    orderTotal,
    shippingPrice,
    taxes,
    colspanSummary,
    extraCharges,
    handleAddExtraCharge,
    handleRemoveExtraCharge,
    handleExtraChargeChanged,
    deletingExtraChargeIndex,
    isDealer
  ]);

  const handleModelTemplate = useCallback((row) => {
    return modelTemplate(row, getColumns(cantEditProduct, isOrderAndDealer), isQuote);
  }, [cantEditProduct, isQuote, isOrderAndDealer]);

  const handleTotalTemplate = useCallback((row) => {
    return totalTemplate(row, cantEditProduct || isOrderAndDealer);
  }, [cantEditProduct, isOrderAndDealer]);

  const handleActionRender = useCallback((rowData) => {
    if (rowData && typeof rowData.id === 'string' && rowData.id.includes('subtotal')) {
      return {
        children: formatCurrency(rowData.subtotal),
        props: {
          align: 'right',
          colSpan: hideActions ? hideActions(rowData) : 1,
          className: 'quote-product-list__subtotal quote-product-list__totals',
        },
      };
    }
    return null;
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const updateColspan = () => setColspanSummary(getColspanSummary);
    if (window) {
      window.addEventListener('resize', updateColspan);

      return () => {
        window.removeEventListener('resize', updateColspan);
      };
    }
  }, [getColspanSummary]);
  useEffect(() => setColspanSummary(getColspanSummary()), [getColspanSummary]);

  return (
    <DataTable
      rowKey="id"
      loading={loading}
      dataSource={quoteProducts}
      rowClassName={rowClassName}
      pagination={false}
      onSubmit={orderProductApi.update}
      onSubmitSuccess={handleChangeProductInline}
      childrenColumnName="products"
      indentSize={0}
      expandIconColumnIndex={-1}
      expandedRowKeys={expandedRowKeys}
      summary={summary}
      rowSelection={rowSelection}
    >
      <DataTableColumn
        columnKey="model"
        title="Model"
        render={handleModelTemplate}
        className="quote-product-list__model-column"
      />
      <DataTableColumn
        dataIndex="name"
        columnKey="name"
        title="Product"
        render={productTemplate}
        showBreakpoint={1444}
      />
      <DataTableColumn
        dataIndex="quantity"
        columnKey="quantity"
        title="Quantity"
        render={renderContent}
        component={NumberInput}
        editable={isQuote && hasEditPermission}
        width={90}
      />
      <DataTableColumn
        dataIndex="priceWithOptions"
        columnKey="priceWithOptions"
        title="Unit Price"
        render={unitPriceTemplate}
        component={NumberInput}
        editable={isQuote && hasEditPermission && !isDealer}
        getPayload={getPricePayload}
        width={110}
        allowDecimals
        isCurrency
      />
      <DataTableColumn
        columnKey="total"
        title="Total"
        render={handleTotalTemplate}
        className="quote-product-list__subtotal"
        isCurrency
      />
      <DataTableColumn
        dataIndex="group"
        columnKey="group"
        title="Group"
        component={Select}
        options={groupOptions}
        placeholder="Select group"
        editable={isQuote}
        alwaysShowInput
        render={renderContent}
        hideColumn={!isQuote}
        disabled={!hasEditPermission}
      />
      <DataTableColumn
        dataIndex="isDeficient"
        columnKey="status"
        title="Status"
        render={statusTemplate}
        hideColumn={cantEditProduct || isQuote}
      />
      <DataTableColumn
        isActionColumn
        onEdit={handleOnEdit}
        editPermissions={permissions.editOrderProducts}
        deletePermissions={permissions.deleteOrderProducts}
        onDelete={handleOnDelete}
        hideActions={hideActions}
        hideColumn={cantEditProduct || isOrderAndDealer}
        render={handleActionRender}
      >
        <DataTableAction
          onClick={openDeficiencyFormModal}
          disabled={handleDisabled}
          label="Make Deficiency"
          hide={handleHideDeficientAction}
          permissions={permissions.addDeficiencyItems}
        />
      </DataTableColumn>
    </DataTable>
  );
};

export default QuoteProductsList;
