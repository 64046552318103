import purchaseOrderApi from 'api/purchase-order';
import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { PurchaseOrderListModel } from '..';

export default function printPDF(po: PurchaseOrderListModel) {
  const { id } = po;
  purchaseOrderApi.getPDF(id)
    .catch((error) => {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage((error as any).response),
      });
    });
}
