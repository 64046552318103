import { OptionModel } from 'models/option';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { BuilderFormModel } from 'components/pages/Builder/state';

export enum Actions {
  FINISH_LOADING_PAGE = 'FINISH_LOADING_PAGE',
  LOAD_PREVIEW_MODEL = 'LOAD_PREVIEW_MODEL',
  SET_TEXTURE = 'SET_TEXTURE',
  LOAD_TEXTURE_IMAGE = 'LOAD_TEXTURE_IMAGE',
  START_LOADING_IMAGE = 'START_LOADING_IMAGE',
  START_CALLING_API = 'START_CALLING_API',
  FINISH_CALLING_API = 'FINISH_CALLING_API',
  SET_ERRORS = 'SET_ERRORS',
}

export interface OptionDropdownModel extends DropdownOptionModel {
  image: string;
  textureHeight: number;
  textureWidth: number;
  dropdownItem: OptionModel;
}

export interface TextureModel {
  option: OptionDropdownModel;
  width: number;
  height: number;
  widthRatio: number;
}

export interface TextureSettingsState {
  texture: TextureModel;
  model: BuilderFormModel;
  loading: boolean;
  imageLoaded: boolean;
  callingAPI: boolean;
  errors: any;
}

export default function textureSettingsReducer(
  state: TextureSettingsState,
  action,
): TextureSettingsState {
  const { type, payload } = action;

  switch (type as Actions) {
    case Actions.FINISH_LOADING_PAGE:
      return { ...state, loading: false };
    case Actions.LOAD_PREVIEW_MODEL:
      return { ...state, loading: false, model: payload };
    case Actions.SET_TEXTURE:
      return { ...state, texture: payload };
    case Actions.LOAD_TEXTURE_IMAGE:
      return {
        ...state,
        imageLoaded: true,
        texture: {
          ...state.texture,
          widthRatio: payload,
        },
      };
    case Actions.START_LOADING_IMAGE:
      return { ...state, imageLoaded: false };
    case Actions.START_CALLING_API:
      return { ...state, callingAPI: true };
    case Actions.FINISH_CALLING_API:
      return { ...state, callingAPI: false };
    case Actions.SET_ERRORS:
      return { ...state, errors: payload, callingAPI: false };
    default:
      return state;
  }
}
