import React, { FunctionComponent } from 'react';
import { TermModel } from 'models/term';
import paymentTermsApi from 'api/term';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import Page from 'components/base-components/Page';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Button from 'components/base-components/Button';
import AccessControl from 'components/base-components/AccessControl';
import useQuickCreateProjectState from './state';
import validationsRules from './state/validations';
import './styles.scss';
import { permissions } from './permissions';

export function mapPaymentTermsOptionsByQuick(paymentTerms: TermModel[]) {
  return mapGenericDropdownOptions(paymentTerms, { fields: { value: 'termsName', label: 'termsName' } });
}

const CreateProject: FunctionComponent = () => {
  const {
    state: {
      formData,
      creating,
      errors,
    },
    actions: {
      setFormValue,
      setValidationErrors,
      createQuickAccount,
    },
  } = useQuickCreateProjectState();

  return (
    <Page title="Create Project">
      <Form
        state={formData}
        onChange={setFormValue}
        errors={errors}
        onError={setValidationErrors}
        rules={validationsRules}
      >
        <Field
          id="companyName"
          name="companyName"
          label="Account Name"
          required
          placeholder="Enter a account name"
        />
        <Field
          id="firstName"
          name="firstName"
          label="First Name"
          required
          placeholder="Enter First Name"
        />
        <Field
          id="lastName"
          name="lastName"
          label="Last Name"
          required
          placeholder="Enter Last Name"
        />
        <Field
          id="email"
          name="email"
          label="Email"
          placeholder="Enter Email"
          required
        />
        <Field
          component={AsyncSelectV2}
          name="paymentTerms"
          id="paymentTerms"
          label="Payment Term"
          listAction={paymentTermsApi.list}
          mapFunction={mapPaymentTermsOptionsByQuick}
          required
        />
        <Field
          id="jobTitle"
          name="jobTitle"
          label="Job Title"
          required
          placeholder="Enter Title for New Project"
        />
      </Form>
      <div className="wrap-button">
        <AccessControl permissions={permissions.createProject}>
          <Button onClick={createQuickAccount} loading={creating}>
            Create
          </Button>
        </AccessControl>
      </div>
    </Page>
  );
};

export default CreateProject;
