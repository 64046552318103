import { useCallback, useMemo, useReducer } from 'react';
import reportApi from 'api/report';
import reportReducer from './reducer';
import { setIncomeCustomerValue } from './actions/set-forms-value';
import {
  initialState,
  getIncomeCustomerParams,
} from '../../common';

const axisY = [
  { title: 'total', borderColor: '#01b6f5' },
  { title: 'orders', borderColor: '#fe4849' },
];

export const incomeCustomerParams = {
  action: reportApi.incomeCustomer,
  label: 'name',
  axisY,
};

export default function useIncomeCustomer() {
  const [state, dispatch] = useReducer(
    reportReducer,
    {
      formIncomeCustomerData: initialState,
    },
  );

  const { formIncomeCustomerData } = state;

  return {
    state: {
      ...state,
      incomeCustomerParams: useMemo(() => ({
        ...incomeCustomerParams,
        queryParams: getIncomeCustomerParams(formIncomeCustomerData)
      }), [formIncomeCustomerData]),
    },
    actions: {
      setIncomeCustomerValue: useCallback(setIncomeCustomerValue(dispatch), []),
    }
  };
}
