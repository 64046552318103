import React, {
  FunctionComponent,
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';

interface HelpState {
  modalList: string[];
  activeModal: string;
}

interface HelpContext {
  state: HelpState;
  actions: {
    registerModal: (modal: string) => void;
    removeModal: (modal: string) => void;
    openModal: () => void;
    closeModal: () => void;
  };
}

const HelpContext = createContext<HelpContext>(undefined);

const HelpProvider: FunctionComponent = (props) => {
  const { children } = props;
  const [state, setState] = useState<HelpState>({ modalList: [], activeModal: '' });

  const registerModal = useCallback((nextModal: string) => {
    setState((old) => ({
      ...old,
      modalList: old.modalList.concat(nextModal),
    }));
  }, []);

  const removeModal = useCallback((modalToRemove: string) => {
    setState((old) => ({
      ...old,
      modalList: old.modalList.filter(modal => modal !== modalToRemove),
    }));
  }, []);

  const closeModal = useCallback(() => {
    setState((old) => ({
      ...old,
      activeModal: '',
    }));
  }, []);

  const openModal = useCallback(() => {
    setState((old) => ({
      ...old,
      activeModal: old.modalList.slice(-0).pop(),
    }));
  }, []);

  const context = useMemo<HelpContext>(() => ({
    state,
    actions: {
      registerModal,
      removeModal,
      closeModal,
      openModal,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [state]);

  return (
    <HelpContext.Provider value={context}>
      {children}
    </HelpContext.Provider>
  );
};

export const useHelp = () => useContext(HelpContext);
export const useHelpActions = () => useContext(HelpContext).actions;

export default HelpProvider;
