import React from 'react';
import DataTableActionColumnRender from './ActionColumnRender';
import DataTableCurrencyColumnRender from './CurrencyColumnRender';
import DataTableDateColumnRender from './DateColumnRender';
import TrimAtColumnRender from './TrimAtColumnRender';
import LinkColumnRender from './LinkColumnRender';

export default function getColumnProps(props) {
  const {
    isActionColumn,
    viewPermissions,
    onShowDetails,
    editPermissions,
    onEdit,
    deletePermissions,
    onDelete,
    hideActions,
    isCurrency,
    isDate,
    trimAt,
    link,
    children: columnChildren,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editable,
    ...rest
  } = props;

  if (isActionColumn) {
    const actionColumnRenderProps = {
      viewPermissions,
      deletePermissions,
      editPermissions,
      onDelete,
      onEdit,
      onShowDetails,
      hideActions,
    };

    const actionsRender = (rowData) => {
      if (rest.render && rest.render(rowData)) {
        return rest.render(rowData);
      }
      return {
        children: (
          <DataTableActionColumnRender
            data={rowData}
            {...actionColumnRenderProps}
          >
            {columnChildren}
          </DataTableActionColumnRender>
        ),
        props: {
          colSpan: hideActions ? hideActions(rowData) : 1,
          align: 'center',
        }
      };
    };

    return {
      width: 170,
      ...rest,
      render: actionsRender,
    };
  }

  if (isCurrency) {
    return {
      render: DataTableCurrencyColumnRender,
      align: 'right',
      ...rest,
    };
  }

  if (isDate) {
    return {
      render: DataTableDateColumnRender,
      ...rest,
    };
  }

  if (trimAt) {
    return {
      render: TrimAtColumnRender(trimAt),
      ...rest,
    };
  }

  if (link) {
    return {
      render: LinkColumnRender(link),
      ...rest,
    };
  }

  return rest;
}
