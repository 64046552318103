import { useCallback, useEffect, useState } from 'react';

export interface QueryResults {
  [query: string]: boolean;
}

function matQueries(queries: string[], queryBuilder) {
  if (!queries || queries.length === 0) {
    return {};
  }

  return queries.reduce((results, query) => ({
    ...results,
    [query]: window?.matchMedia(queryBuilder(query)).matches,
  }), {});
}

function areDifferentResults(prevResult, nextResult) {
  return !!Object.keys(prevResult).find(key => prevResult[key] !== nextResult[key]);
}

export default function useMediaQueries(
  queries: string[],
  queryBuilder = (query) => query,
): QueryResults {
  const [results, setResults] = useState<QueryResults>(
    () => matQueries(queries, queryBuilder)
  );

  const handleQueryMatch = useCallback(() => {
    const nextResults = matQueries(queries, queryBuilder);
    if (areDifferentResults(results, nextResults)) {
      setResults(nextResults);
    }
  }, [results, queries, queryBuilder]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (window && queries?.length > 0) {
      window.addEventListener('resize', handleQueryMatch);

      return () => {
        window.removeEventListener('resize', handleQueryMatch);
      };
    }
  }, [queries, handleQueryMatch]);

  return results;
}
