import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import manufacturerApi from 'api/manufacturer';
import orderApi from 'api/orders';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import DatePicker from 'components/base-components/DatePicker';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import Checkbox from 'components/base-components/Checkbox';
import validationRules from './state/validations';
import usePurchaseOrderFormState, { mapManufacturersOptions, mapOrderOptions } from './state';

export interface PurchaseOrderFormHistorySateModel {
  purchaseOrderForm: {
    id: string;
    orderId?: string;
    fromProjectCoordinator?: boolean;
  };
}

export default function PurchaseOrderForm() {
  const { goBack, location: { state } } = useHistory();

  const { purchaseOrderForm: { id, orderId, fromProjectCoordinator } } = state as PurchaseOrderFormHistorySateModel;

  const {
    state: {
      purchaseOrder,
      fetchingFormData,
      savingPurchaseOrder,
      errors,
    },
    actions: {
      setFormValue,
      savePurchaseOrder,
      setErrors,
    },
  } = usePurchaseOrderFormState(id, orderId, fromProjectCoordinator);

  const formModalTitle = useMemo(() => id ? 'Edit Purchase Order' : 'Create Purchase Order', [id]);

  return (
    <Modal
      visible
      onCancel={goBack}
      okText="Submit"
      title={formModalTitle}
      onOk={savePurchaseOrder}
      confirmLoading={savingPurchaseOrder}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={purchaseOrder}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            component={AsyncSelectV2}
            id="manufacturer"
            name="manufacturer"
            label="Manufacturer"
            placeholder="Select a manufacturer"
            listAction={manufacturerApi.list}
            mapFunction={mapManufacturersOptions}
            required
          />
          <RenderIf isTrue={!!id}>
            <Field
              component={DatePicker}
              name="dateSent"
              id="dateSent"
              label="Date sent"
            />
          </RenderIf>
          <Field
            component={Checkbox}
            name="paintShop"
            id="paintShop"
            label="Paint Shop"
          />
          <Field
            name="tag"
            id="tag"
            label="Tag"
            required
          />
          <Field
            component={DatePicker}
            name="supplierReadyDate"
            id="supplierReadyDate"
            label="Supplier Ready Date"
          />
          <RenderIf isTrue={!orderId}>
            <Field
              component={AsyncSelectV2}
              name="order"
              id="order"
              placeholder="Select an order"
              label="Order"
              listAction={orderApi.list}
              mapFunction={mapOrderOptions}
              preventAutoFetch={orderId}
            />
          </RenderIf>
        </Form>
      </RenderIf>
    </Modal>
  );
}
