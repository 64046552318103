import React, { FunctionComponent } from 'react';
import './styles.scss';

const InputRow: FunctionComponent = (props) => {
  const { children } = props;

  return (
    <div className="input__row">
      {children}
    </div>
  );
};

export default InputRow;
