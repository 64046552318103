import React from 'react';
import Tag from 'components/base-components/Tag';

export default function statusTemplate(isConverted: boolean) {
  if (isConverted) {
    return (
      <Tag label="Converted" color="green" />
    );
  }

  return null;
}
