import { ThreeFormActions } from '../reducer';

export default function removeMorph(dispatch) {
  return (index) => {
    dispatch({
      type: ThreeFormActions.REMOVE_MORPH,
      payload: index,
    });
  };
}
