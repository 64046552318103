import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { BuilderFormModel } from '../types';
import { BuilderActions } from '../reducer';

function resolveOptionValues(
  product: BuilderFormModel,
  updatedOptions,
) {
  const { sceneOptions } = product;
  const updatedOption = (
    Object.keys(sceneOptions)
      .find((option) => {
        const oldValue = sceneOptions[option];
        const updatedValue = updatedOptions[option];
        return oldValue !== updatedValue;
      })
  );

  if (!updatedOption) {
    return { hasUpdates: false };
  }

  return {
    hasUpdates: true,
    updatedOption: {
      name: updatedOption,
      value: updatedOptions[updatedOption],
    },
  };
}

export default function updateSceneOptions(dispatch, product: BuilderFormModel) {
  return (sceneOptions) => {
    const {
      hasUpdates,
      updatedOption,
    } = resolveOptionValues(product, sceneOptions);

    if (hasUpdates) {
      notifyEventChannel(
        EventChannelList.BUILDER_SCENE_OPTION_CHANGED,
        updatedOption,
      );
    }

    dispatch({
      type: BuilderActions.UPDATE_PRODUCT,
      payload: { ...product, sceneOptions },
    });
  };
}
