import { ProvinceModel } from 'models/province';
import { DropdownOptionModel } from 'helpers/dropdown-options';

export default function filterProvinces(
  provinces: ProvinceModel[],
  selectedCountry: DropdownOptionModel,
) {
  if (!selectedCountry) {
    return provinces;
  }

  return provinces.filter((province) => province.country.id === selectedCountry.value);
}
