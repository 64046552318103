import { flatDropdownOptions } from 'helpers/dropdown-options';
import { UserModel } from 'models/user';
import { isValidFile } from 'helpers/file';

export default function getPayload(user: UserModel) {
  const { groups: newGroups, userPermissions: newUserPermissions, image, ...rest } = user;
  return {
    ...rest,
    groups: flatDropdownOptions(newGroups),
    userPermissions: flatDropdownOptions(newUserPermissions),
    image: image && isValidFile(image[0]) ? image : undefined,
  };
}
