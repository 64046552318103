import { AxiosResponse } from 'axios';
import { ProformaInvoiceModel } from 'models/proforma-invoice';
import { ApiContentType, ApiModel, post } from './base';
import getApi from './base/get-api';
import { getEmailPayload } from './base/get-email-payload';
import downloadBlob from './base/download-blob';

const { REACT_APP_TRANSACTIONS_API } = process.env;
const paymentEndpoint = `${REACT_APP_TRANSACTIONS_API}/proforma-invoice/`;

interface InvoiceProformaApiModel extends ApiModel<ProformaInvoiceModel> {
  sendEmail: (invoice: number, recipients: string[]) => Promise<AxiosResponse>;
  print: (invoice: number) => Promise<void>;
}

const invoiceProformaApi: InvoiceProformaApiModel = {
  ...getApi(paymentEndpoint, ApiContentType.MULTIPART, true),
  sendEmail(proformaInvoice: number, recipients: string[]) {
    const payload = getEmailPayload(recipients);
    return post(`${paymentEndpoint}${proformaInvoice}/email/`, payload);
  },
  print(proformaInvoice: number) {
    return downloadBlob(
      `${paymentEndpoint}${proformaInvoice}/view/`,
      `Proforma Invoice ${proformaInvoice}.pdf`
    );
  }
};

export default invoiceProformaApi;
