import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { loginUser } from 'store/actions';
import { validateEntity, Rule, RuleType } from 'helpers/form-validations';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Checkbox from 'components/base-components/Checkbox';
import Button from 'components/base-components/Button';
import PasswordInput from 'components/base-components/PasswordInput';
import profile from 'assets/images/profile-img.png';
import chairLogo from 'assets/images/tfg-chair-logo.png';

const requiredRule: Rule = {
  type: RuleType.Required,
  message: 'This field is required',
};

const validationRules = {
  username: [requiredRule],
  password: [requiredRule],
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, loading, isAuthenticated } = useSelector(
    (state: any) => state.login,
  );

  const [loginParams, setLoginParams] = useState({
    username: '',
    password: '',
    rememberMe: false
  });
  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { hasErrors, errors } = validateEntity(loginParams, validationRules);

    if (hasErrors) {
      setFormErrors(errors);
    } else {
      dispatch(loginUser(loginParams, history));
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to ERP.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={chairLogo}
                          alt="chair logo"
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    {error && <Alert color="danger">{error}</Alert>}
                    <Form
                      state={loginParams}
                      onChange={setLoginParams}
                      errors={formErrors}
                      onError={setFormErrors}
                    >
                      <Field
                        name="username"
                        label="Username"
                        placeholder="Enter your username"
                        rules={validationRules.username}
                        required
                      />
                      <Field
                        name="password"
                        label="Password"
                        placeholder="Enter your password"
                        component={PasswordInput}
                        rules={validationRules.password}
                        required
                      />
                      <Field
                        name="rememberMe"
                        component={Checkbox}
                        label="Remember Me"
                      />
                      <div className="mt-3">
                        <Button
                          id="login-button"
                          block
                          label="Log In"
                          onClick={handleSubmit}
                          loading={loading}
                        />
                      </div>
                    </Form>
                    <div className="mt-4 text-center">
                      <Link to="/account-application" className="text-muted">
                        <i className="mdi mdi-account mr-1" />
                        Account Application
                      </Link>
                    </div>
                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock mr-1" />
                        Forgot your password?
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
