export const commonTextures = [
  {
    label: 'Seamless 1',
    texture:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/seamless-01.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'Seamless 2',
    texture:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/seamless-02.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'Oak',
    texture:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/163 Aged Oak.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'Mahogany',
    texture:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/Mahogany-w365.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'W155',
    texture:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/W155.jpg',
    width: 35,
    height: 35,
  },
];

export const metalTextures = [
  {
    label: 'Silver',
    texture:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/silver.jpg',
    width: 100,
    height: 100,
  },
  {
    label: 'Black',
    texture:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/black.jpg',
    width: 100,
    height: 100,
  },
];

export const commonModestyStyles = [
  {
    label: '12m',
    name_in_model: 'desk-modesty-12m',
  },
  {
    label: '18m',
    name_in_model: 'desk-modesty-18m',
  },
  {
    label: '28m',
    name_in_model: 'desk-modesty-28m',
  },
];

export const commonGrommetStyles = [
  {
    label: 'NG',
    name_in_model: '',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-grommet-ng.jpg',
  },
  {
    label: 'LG',
    name_in_model: 'grommet-lg',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-grommet-lg.jpg',
  },
  {
    label: 'CG',
    name_in_model: 'grommet-cg',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-grommet-cg.jpg',
  },
  {
    label: 'RG',
    name_in_model: 'grommet-rg',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-grommet-rg.jpg',
  },
  {
    label: 'LRG',
    name_in_model: 'grommet-lrg',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-grommet-lrg.jpg',
  },
];

export const cogGrommetStyles = [
  {
    label: 'NG',
    name_in_model: '',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-grommet-ng.jpg',
  },
  {
    label: 'COG',
    name_in_model: 'grommet-cog',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-grommet-cog.jpg',
  },
];

export const handleStyles = [
  { label: 'Bar 5" Pull Nickel', name_in_model: 'handle-5"-bar-pull-nickel' },
  { label: 'Loop 5" Black', name_in_model: 'handle-5"-loop-black' },
  { label: 'Loop 5" Nickel', name_in_model: 'handle-5"-loop-nickel' },
  { label: 'Square 5" Nickel', name_in_model: 'handle-5"-square-nickel' },
  {
    label: 'Strait 5" Pull Black',
    name_in_model: 'handle-5"-strait-pull-black',
  },
  {
    label: 'Strait 5" Pull Silver',
    name_in_model: 'handle-5"-strait-pull-silver',
  },
  { label: 'Waterfall 5" Black', name_in_model: 'handle-5"-waterfall-black' },
];

export const doorStyles = [
  {
    label: 'Panel Doors',
    name_in_model: 'doors-panel-door',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-door-panel.jpg',
  },
  {
    label: 'Glass Doors',
    name_in_model: 'doors-glass-door',
    thumbnail:
      'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/textures/thumb-door-glass.jpg',
  },
];

export const lockStyles = [
  { label: 'Lock', name_in_model: 'lock' },
  { label: 'No Lock', name_in_model: '' },
];
