import React, { FunctionComponent, useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { changeSidebarType, initStyles } from './utils';
import './styles.scss';

const Layout: FunctionComponent = (props) => {
  const [leftSideBarType, setLeftSideBarType] = useState('default');

  useEffect(() => {
    initStyles();
  }, []);

  const toggleMenuCallback = () => {
    const nextType = leftSideBarType === 'default' ? 'condensed' : 'default';
    changeSidebarType(nextType);
    setLeftSideBarType(nextType);
  };

  const { children } = props;

  return (
    <div id="layout-wrapper">
      <Header
        toggleMenu={toggleMenuCallback}
      />
      <Sidebar />
      <div className="main-content">{children}</div>
    </div>
  );
};

export default Layout;
