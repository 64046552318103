import { TablePaginationConfig } from 'antd/lib/table';
import snakeCase from 'lodash.snakecase';
import { TableActions } from '../reducer';

export function handleOnChange(dispatch) {
  return (
    pagination: TablePaginationConfig,
    columnFilter: any,
    sorter: any,
  ) => {
    const { current } = pagination;
    const { column, order } = sorter;
    const newOrder = order === 'descend' ? '-' : '';
    let newSortField = '';
    if (column) {
      const { key } = column;
      newSortField = key ? key.split('/')[0] : '';
    }

    dispatch({
      type: TableActions.HANDLE_ON_CHANGE,
      payload: {
        page: current,
        columnFilter: Object.keys(columnFilter).reduce((filters, key) => ({
          ...filters,
          [`${snakeCase(key.split('/')[0])}__in`]: columnFilter[key]?.reduce((formattedValue, value) => `${formattedValue}${value},`, '').slice(0, -1),
        }), {}),
        sortField: `${newOrder}${newSortField}`,
      },
    });
  };
}
