import React, { FunctionComponent } from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { BreadcrumbItemProps } from 'antd/lib/breadcrumb';

const BreadcrumbItem: FunctionComponent<BreadcrumbItemProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <AntBreadcrumb.Item {...rest}>
      {children}
    </AntBreadcrumb.Item>
  );
};

export default BreadcrumbItem;
