import { useEffect, useMemo } from 'react';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import userGroupApi from 'api/user-group';
import { EventChannelList } from 'helpers/event-center';
import { UserGroupModel } from 'models/user-group';
import notification, { NotificationType } from 'helpers/notification';
import getPayload from './actions/get-payload';
import getFormData from './actions/get-form-data';
import validationRules from './validations';

export function mapPermissionsOptions(data: any[]) {
  return mapGenericDropdownOptions(data, { fields: { value: 'url', label: 'name' } });
}

export default function useUserGroupFormState(userGroupUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: userGroupApi,
    eventChannel: EventChannelList.USERS_GROUP_LIST_CHANGED,
    param: userGroupUrl,
    getPayload,
    getFormData,
    validationRules,
  }), [userGroupUrl]);

  const {
    state: {
      formData: userGroup,
      errors,
      saving,
      errorFetchingEntity: errorFetchingUserGroup,
      fetchingEntity: fetchingUserGroup,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<UserGroupModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingUserGroup) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingUserGroup,
      });
    }
  }, [errorFetchingUserGroup]);

  return {
    state: {
      userGroup,
      savingUserGroup: saving,
      errors,
      fetchingFormData: fetchingUserGroup,
    },
    actions: {
      setFormValue,
      saveUserGroup: saveEntity,
      setErrors,
    },
  };
}
