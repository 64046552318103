import { resolveApiErrorMessage } from 'api/base';
import accountApi from 'api/account';
import notification, { NotificationType } from 'helpers/notification';
import { validateEntity } from 'helpers/form-validations';
import { createAll as createProject } from 'components/pages/Quotes/Quote/Form/CreateProject/state/actions/create-project';
import { CreateQuickProjectFormActions, QuickProjectModel } from '../reducer';
import validationRules from '../validations';
import { getPayload, getQuickAccountPayload } from './get-payload';

async function createAll(formData: QuickProjectModel) {
  const { jobTitle, ...quickAccountData } = formData;
  const quickAccountPayload = getQuickAccountPayload(quickAccountData);
  const { data: account } = await accountApi.quickCreate(quickAccountPayload);
  const projectPayload = getPayload(account, jobTitle);
  await createProject(projectPayload);
}

export function createQuickProject(
  dispatch,
  formData: QuickProjectModel,
) {
  return () => {
    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: CreateQuickProjectFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    dispatch({ type: CreateQuickProjectFormActions.START_CREATING });

    const onSuccess = () => {
      const message = 'Project Successfully Created';
      notification({ type: NotificationType.SUCCESS, message });
      dispatch({ type: CreateQuickProjectFormActions.CLEAR_STATE });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: CreateQuickProjectFormActions.FINISH_CREATING });
    };

    return createAll(formData)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
