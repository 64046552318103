const commonTextures = [
  {
    label: 'Oak',
    texture: 'Blueprint3D-assets/textures/163 Aged Oak.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'Mahogany',
    texture: 'Blueprint3D-assets/textures/Mahogany-w365.jpg',
    width: 35,
    height: 35,
  },
  {
    label: 'W155',
    texture: 'Blueprint3D-assets/textures/W155.jpg',
    width: 35,
    height: 35,
  },
];

const commonModestyStyles = [
  {
    label: '12m',
    name_in_model: 'desk-modesty-12m',
  },
  {
    label: '18m',
    name_in_model: 'desk-modesty-18m',
  },
  {
    label: '28m',
    name_in_model: 'desk-modesty-28m',
  },
];

const cogGrommetStyles = [
  {
    label: 'NG',
    name_in_model: '',
  },
  {
    label: 'COG',
    name_in_model: 'grommet-cog',
  },
];

const data = {
  category: 'RTN',
  styles: [
    {
      name: 'RTN-BML',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-bml.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/RTN-BML.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'RTN-BMR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-bmr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/RTN-BMR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'RTN-RL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-rl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/RTN-RL.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 24, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'RTN-RR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-rr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/RTN-RR.glb',
      type: '1',
      stackontop: true,
      morph: [
        { label: 'Width', index: 1, min: 60, max: 108 },
        { label: 'Depth', index: 2, min: 24, max: 48 },
      ],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'RTN-WL',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-wl.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/RTN-WL.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
    {
      name: 'RTN-WR',
      image:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/thumbnails/rtn-wr.jpg',
      model:
        'https://blueprint3d-react-dist.netlify.app/Blueprint3D-assets/models/glb/RTN-WR.glb',
      type: '1',
      stackontop: true,
      morph: [{ label: 'Width', index: 1, min: 60, max: 108 }],
      materials: [
        { label: 'Desk Top', name_in_model: 'desk-top', types: commonTextures },
        {
          label: 'Desk Modesty',
          name_in_model: 'desk-modesty',
          types: commonTextures,
        },
        {
          label: 'Desk Gable',
          name_in_model: 'desk-gable',
          types: commonTextures,
        },
      ],
      styles: [
        {
          label: 'Modesty Style',
          name_in_model: 'modesty',
          types: commonModestyStyles,
        },
        {
          label: 'Grommet Style',
          name_in_model: 'grommet',
          types: cogGrommetStyles,
        },
      ],
    },
  ],
};

export default data;
