import { getLoggedUser } from 'helpers/get-logged-user';

export function getEmailPayload(recipients: string[], html?: string) {
  const activeUser = getLoggedUser();
  const senderEmail = activeUser.email.endsWith('@thefurnitureguys.ca')
    ? activeUser.email
    : 'non-reply@thefurnitureguys.ca';

  return {
    sender: senderEmail,
    recipients,
    html: html ? html.replace(/"/g, "'") : undefined
  };
}
