import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { ProjectCoordinatorModel } from 'models/project-coordinator';
import orderStatusApi from 'api/orde-status';
import useTableState from 'hooks/ui/table-state';
import useGetList from 'hooks/base/get-list';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import notification, { NotificationType } from 'helpers/notification';
import hasPermissions from 'helpers/permissons';
import openReceivePaymentModal from './actions/open-receive-payment';
import onSubmit from './actions/handle-on-submit';
import openNotesModal from './actions/open-notes-form';
import openTaskModal from './actions/open-tasks-form';
import openFileViewerModal from './actions/open-file-viewer';
import printQuestionnaire from './actions/print-questionnaire';
import openPOItemsProd from '../../ProductionManager/state/actions/open-purchase-order';
import printShopOrder from '../../ProductionManager/state/actions/print-shop-order';
import printInstallerOrder from '../../ProductionManager/state/actions/print-installer-order';
import { mapOptions, tableStateParams } from '../constants';
import { productionStatusOptions } from '../../ProductionManager/constants';

export default function useProjectCoordinator() {
  const { push } = useHistory();
  const [showCalender, setShowCalendar] = useState(false);
  const [showPO, setShowPO] = useState({ order: null, visible: false });
  const {
    state,
    actions,
  } = useTableState<ProjectCoordinatorModel>(tableStateParams);

  const { data } = state;
  const { updateDataManually } = actions;

  const {
    fetching: fetchingStatus,
    data: status,
    error: errorStatus
  } = useGetList(orderStatusApi);

  useEffect(() => {
    if (errorStatus) {
      notification({
        type: NotificationType.ERROR,
        message: errorStatus,
      });
    }
  }, [errorStatus]);

  const updateDataTable = useCallback((response) => {
    const payload = response.data || response;
    updateDataManually(data.map(item => {
      if (item.id === payload.id) {
        return {
          ...item,
          ...payload,
        };
      }
      return item;
    }));
  }, [data, updateDataManually]);

  useEventCenterUpdate(EventChannelList.PROJECT_COORDINATOR_UPDATE, updateDataTable);
  useEventCenterUpdate(EventChannelList.UPDATE_NOTE_COUNT, updateDataTable);

  return {
    state: {
      ...state,
      showPO,
      showCalender,
      orderStatusOptions: useMemo(() => mapGenericDropdownOptions(status, mapOptions), [status]),
      fetching: state.fetching || fetchingStatus,
      filtersOrderStatus: useMemo(() => status.map(opt => (
        { value: opt.id, text: opt.status }
      )), [status]),
      filtersProductOption: useMemo(() => productionStatusOptions.map(opt => (
        { value: opt.value, text: opt.label }
      )), []),
      linkToOrder: useMemo(() => hasPermissions(['Sales-Orders', 'Dealer', 'Sales']) ? (order) => `quote/${order.quoteId}` : undefined, []),
    },
    actions: {
      ...actions,
      handleShowCalendar: useCallback(() => setShowCalendar((oldState) => !oldState), []),
      openReceivePaymentModal: useCallback(openReceivePaymentModal(push), []),
      openFileViewerModal: useCallback(openFileViewerModal(push), []),
      openNotesModal: useCallback(openNotesModal(push), []),
      openTaskModal: useCallback(openTaskModal(push), []),
      openPOItemsProd: useCallback(openPOItemsProd(push), []),
      handleShowPO: (order?) => setShowPO({ order, visible: !!order }),
      onSubmit,
      printInstallerOrder,
      printShopOrder,
      printQuestionnaire,
    }
  };
}
