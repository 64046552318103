import { AppPermissions } from 'constants/permissions';

export const permissions = {
  addOrder: [AppPermissions.add_purchaseorder],
  editOrder: [AppPermissions.change_purchaseorder],
  deleteOrder: [AppPermissions.delete_purchaseorder],
  viewPOItems: [AppPermissions.view_poitem],
  addBill: [AppPermissions.add_bill],
  deleteBill: [AppPermissions.delete_bill],
};
