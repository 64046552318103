export enum GenerateProductsActions {
  SET_DATA = 'SET_DATA',
  SET_FORM_DATA = 'SET_FORM_DATA',
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  START_ADDING = 'START_ADDING',
  FINISH_ADDING = 'FINISH_ADDING',
}

export interface Rate {
  name: string;
  value: any;
}

interface GenerateProductsState {
  rates: any[];
  rateForm: Rate;
  errors: any;
  adding: boolean;
}

export default function generateProductsFormReducer(state: GenerateProductsState, action): GenerateProductsState {
  const { type, payload } = action;

  switch (type as GenerateProductsActions) {
    case GenerateProductsActions.SET_DATA:
      return {
        ...state,
        rates: payload,
      };
    case GenerateProductsActions.SET_FORM_DATA:
      return {
        ...state,
        rateForm: payload,
      };
    case GenerateProductsActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, adding: false };
    case GenerateProductsActions.START_ADDING:
      return { ...state, adding: true };
    case GenerateProductsActions.FINISH_ADDING:
      return { ...state, adding: false };
    default:
      return state;
  }
}
