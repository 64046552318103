import React from 'react';
import { useHistory } from 'react-router';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import useCreateQuote from './state';
import validationRules from './state/validations';

export default function CopyQuote() {
  const { goBack, location: { state } } = useHistory();

  const {
    state: {
      formData,
      creating,
      errors,
    },
    actions: {
      handleValueChange,
      handleOnSubmit,
      setValidationErrors,
    },
  } = useCreateQuote(state);

  return (
    <Modal
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={handleOnSubmit}
      confirmLoading={creating}
      title="Create Quote"
    >
      <Form
        state={formData}
        onChange={handleValueChange}
        errors={errors}
        onError={setValidationErrors}
        rules={validationRules}
      >
        <Field
          type="text"
          id="comments"
          name="comments"
          label="Comment"
          placeholder="Enter comment for New Quote"
          required
        />
      </Form>
    </Modal>
  );
}
