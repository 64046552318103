import { BuilderOptionGroup, BuilderProperties, } from 'models/builder';
import { DropdownOptionModel } from 'helpers/dropdown-options';

export interface StyleModel {
  url: string;
  name: string;
  image: string;
  initialData?: any;
  leveDescription?: string;
}

export interface PropertyDropdownModel extends DropdownOptionModel {
  nameInModel: string;
}

export interface OptionDropdownModel extends DropdownOptionModel {
  image: string;
  textureHeight: number;
  textureWidth: number;
  nameInModel: string;
  group: {
    mode: string;
    nameInModel: string;
  };
}

export interface BuilderFormModelDescription {
  [property: string]: number | PropertyDropdownModel;
}

export interface BuilderFormModelBuilderOptions {
  [property: string]: BuilderProperties;
}

export interface BuilderFormModelOptions {
  [x: string]: OptionDropdownModel;
}

export interface BuilderFormModel {
  id: string;
  model: string;
  category: string;
  threeModel: string;
  position: {
    index: number;
    description: string;
  };
  modelDescription: BuilderFormModelDescription;
  builderOptions: BuilderFormModelBuilderOptions;
  optionGroups: BuilderOptionGroup[];
  options?: BuilderFormModelOptions;
  sceneOptions: {
    lockInPlace: boolean;
    stackable: boolean;
    stackontop: boolean;
    overlapable: boolean;
  };
  stackable: boolean;
  stackontop: boolean;
  overlapable: boolean;
  initialData?: any;
  loaded?: boolean;
  isFromSet?: boolean;
}

export interface TextureModel {
  name: string;
  image: string;
  textureHeight: number;
  textureWidth: number;
}

export interface WallModel {
  height: number;
  length: number;
  locked: boolean;
  texture?: string;
}

export interface FloorModel {
  texture: string;
}

export enum StyleProviders {
  Category,
  Product,
}
