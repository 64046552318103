import React from 'react';
import { formatDisplayDate } from 'helpers/formatting';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import Button from 'components/base-components/Button';
import { ConfirmReadyDateHistorySateModel } from 'components/pages/ProductionManager/ConfirmReadyDate';
import Tag from 'components/base-components/Tag';

export default function readyDateRender(push, owner) {
  return (data) => {
    const historyLocationState = {
      confirmReadyDate: {
        orderId: data.id,
        owner,
      }
    } as ConfirmReadyDateHistorySateModel;

    const onClick = () => push(ModalRouteHash.ConfirmReadyDate, historyLocationState);
    const dateFormatted = formatDisplayDate(data.readyDate);

    return (
      <Button className="button-cell__button" type="text" onClick={onClick}>
        <Tag color={data?.statuses?.readyDate}>
          {dateFormatted}
        </Tag>
      </Button>
    );
  };
}
