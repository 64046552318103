import questionnaireApi from 'api/questionnaire';
import { AnswersModel } from '../../types';
import { getAnswersPayload } from './get-payload';

export default async function saveAnswersInfo(token, answers: AnswersModel) {
  const payload = getAnswersPayload(answers);
  const action = answers.id
    ? questionnaireApi.updateAnswers
    : questionnaireApi.storeAnswers;

  const response = await action(token, payload);
  return response ? response.data.id : payload.id;
}
