import { commonRules } from 'helpers/form-validations';

const { required, email } = commonRules;

const validationRules = {
  jobTitle: [required],
  companyName: [required],
  firstName: [required],
  lastName: [required],
  paymentTerms: [required],
  email: [required, email],
};

export default validationRules;
