import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { CategoryDTOModel } from 'models/category';
import categoryApi from 'api/category';
import useGetOne from 'hooks/base/get-one';
import notification, { NotificationType } from 'helpers/notification';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import './styles.scss';

interface CategoryProps{
  levelDescription: string;
  onSelectStyle: (style) => void;
  categories?: CategoryDTOModel[];
  name: string;
  url: string;
  onSelectCategory?: () => any;
  showChildren?: boolean;
}

interface StyleProps{
  onSelectStyle: (style) => void;
  style: any;
}

interface ChildrenWrapperProps{
  childrenAreStyle: boolean;
}

const Style: FunctionComponent<StyleProps> = ({ onSelectStyle, style }) => (
  <li
    key={style.name}
    onClick={() => onSelectStyle(style)}
    draggable
    onDragStart={(e) => {
      e.dataTransfer.setData('info', JSON.stringify(style));
    }}
    className="builder__styles__style"
  >
    <div className="builder__styles__wrap">
      <img
        className="builder__styles__style-image"
        src={style.image || 'desk.png'}
        alt={style.name}
      />
      <p className="builder__styles__name-style">{style.name}</p>
    </div>
  </li>
);

const ChildrenWrapper: FunctionComponent<ChildrenWrapperProps> = ({ childrenAreStyle, children }) => (
  childrenAreStyle
    ? <ul className="builder__styles__list">{children}</ul>
    : <div className="builder__category-panel__list__property">{children}</div>
);

const Category: FunctionComponent<CategoryProps> = (props) => {
  const { levelDescription, onSelectStyle, name, url, categories, onSelectCategory, showChildren } = props;
  const [open, setOpen] = useState(false);
  const [isFetchingDone, SetIsFetchingDone] = useState(false);
  const internalShowChildren = useMemo(() => showChildren !== undefined ? showChildren : open, [showChildren, open]);
  const onPropertySelect = useCallback(() => {
    SetIsFetchingDone(true);
    setOpen(prevState => !prevState);
    if (!!onSelectCategory) {
      onSelectCategory();
    }
  }, [onSelectCategory]);
  const childrenAreStyle = useMemo(
    () => !levelDescription.includes('style') ? true : levelDescription.split(',').indexOf('style') === name.split('-').length,
    [levelDescription, name]
  );

  const {
    data: category,
    fetching: fetchingCategory,
    error: categoryError,
  } = useGetOne(categoryApi, url, { preventAutoFetch: !!categories || !isFetchingDone });

  const subCategories = categories || category?.categories || [];

  const Children = useMemo(() => subCategories.map(
    cat => childrenAreStyle
      ? <Style style={cat} onSelectStyle={onSelectStyle} />
      : <Category levelDescription={cat.levelDescription} onSelectStyle={onSelectStyle} name={cat.name} url={cat.url} />
  ), [subCategories, childrenAreStyle, onSelectStyle]);

  useEffect(() => {
    if (categoryError) {
      notification({ type: NotificationType.ERROR, message: categoryError });
    }
  }, [categoryError]);

  return (
    <div key={name} className="builder__category-panel__list__group">
      <li
        className="builder__category-panel__list__item"
        onClick={onPropertySelect}
      >
        <span>{name}</span>
        <RenderIf isTrue={internalShowChildren}>
          <i className="fa fa-angle-right" />
        </RenderIf>
      </li>
      <RenderIf isTrue={internalShowChildren}>
        <ChildrenWrapper childrenAreStyle={childrenAreStyle}>
          <RenderIf isTrue={!fetchingCategory} fallback={<Spinner />}>
            {Children}
          </RenderIf>
        </ChildrenWrapper>
      </RenderIf>
    </div>
  );
};

export default Category;
