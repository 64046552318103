import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Button from 'components/base-components/Button';
import TextArea from 'components/base-components/TextArea';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Empty from 'components/base-components/Empty';
import NoteItem from './NoteItem';
import useNotesState, { validationRules } from './state';
import './styles.scss';

export interface NotesHistorySateModel {
  notesForm: {
    orderId: number;
    jobTitle: string;
  };
}

const NotesForm: FunctionComponent = () => {
  const { goBack, location: { state: stateNavigation } } = useHistory();
  const {
    notesForm: { orderId, jobTitle },
  } = stateNavigation as NotesHistorySateModel;

  const {
    state: {
      fetching,
      data,
      formData,
      saving,
      errors,
    },
    actions: {
      setFormValue,
      setErrors,
      handleOnDelete,
      onSave,
    }
  } = useNotesState(orderId);

  const renderItem = useMemo(() => data.map((note) => {
    return (
      <NoteItem
        key={note.id}
        data={note}
        handleOnDelete={handleOnDelete}
      />
    );
  }), [handleOnDelete, data]);

  return (
    <Modal
      title={`${orderId} - ${jobTitle} - Notes`}
      visible
      onCancel={goBack}
      footer={false}
    >
      <div className="notes__container-list">
        <RenderIf isTrue={fetching}>
          <div className="notes__loading-container">
            <Spinner size="small" centered={false} />
          </div>
        </RenderIf>
        <RenderIf isTrue={!data.length && !fetching}>
          <Empty />
        </RenderIf>
        <dl className="notes__list">
          {renderItem}
        </dl>
      </div>
      <Form
        state={formData}
        onChange={setFormValue}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <Field
          id="text"
          name="text"
          label="New Note"
          placeholder="Enter Text"
          component={TextArea}
        />
      </Form>
      <Button onClick={onSave} loading={saving} label="Add Note" />
    </Modal>
  );
};

export default NotesForm;
