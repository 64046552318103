import { resolveApiErrorMessage } from 'api/base';
import projectCoordinatorApi from 'api/project-coordinator';
import { validateEntity } from 'helpers/form-validations';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { NotesFormActions, NotesFormModel } from '../reducer';
import { validationRules, initialNote } from '..';

export default function onSave(dispatch, formData: NotesFormModel, oldData: NotesFormModel[], orderId) {
  return () => {
    dispatch({ type: NotesFormActions.START_SAVING });

    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: NotesFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    const onSuccess = ({ data }) => {
      const message = 'Note Successfully Saved';
      notification({ type: NotificationType.SUCCESS, message });

      const payload = formData.id
        ? oldData.map(note => formData.id === note.id ? data : note)
        : [data, ...oldData];

      dispatch({ type: NotesFormActions.SET_DATA, payload });
      dispatch({ type: NotesFormActions.SET_FORM_VALUE, payload: initialNote });
      notifyEventChannel(EventChannelList.UPDATE_NOTE_COUNT, { id: orderId, noteCount: payload.length });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: NotesFormActions.FINISH_SAVING });
    };

    return projectCoordinatorApi.addNotes(orderId, formData)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
