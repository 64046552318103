import { useEffect, useMemo } from 'react';
import { TaskUserModel } from 'models/task-user';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import notification, { NotificationType } from 'helpers/notification';
import { getLoggedUserId } from 'helpers/get-logged-user';
import taskUserApi from 'api/task-user';

const defaultTaskUser: TaskUserModel = {
  email: '',
  mobile: '',
  emailNotifications: false,
  textNotifications: false,
};

const getPayload = (taskUser: TaskUserModel) => {
  return {
    ...taskUser
  };
};

const getFormData = (taskUser: TaskUserModel) => {
  return taskUser || defaultTaskUser;
};

export default function useTaskUserFormState() {
  const param = useMemo(() => getLoggedUserId(), []);
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: taskUserApi,
    noGoBackOnSuccess: true,
    pK: 'user',
    param,
    getPayload,
    getFormData,
    defaultSortField: '-id'
  }), [param]);

  const {
    state: {
      formData: taskUser,
      errors,
      saving,
      errorFetchingEntity: errorFetchingTaskUser,
      fetchingEntity: fetchingTaskUser,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<TaskUserModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingTaskUser) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingTaskUser,
      });
    }
  }, [errorFetchingTaskUser]);

  return {
    state: {
      taskUser,
      savingTaskUser: saving,
      fetchingFormData: fetchingTaskUser,
      errors,
    },
    actions: {
      setFormValue,
      saveTaskUser: saveEntity,
      setErrors,
    },
  };
}
