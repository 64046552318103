import { UserModel } from 'models/user';
import { GroupModel } from 'models/group';
import { QuoteModel } from 'models/quote';
import { AccountTaxModel } from 'models/account';
import { CreateOrderErrors } from 'api/orders';
import { DropdownOptionModel, userDropdownData } from 'helpers/dropdown-options';

export enum QuoteViewActions {
  SET_FILTERS = 'SET_FILTERS',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  START_UPDATING_FILTERS = 'START_UPDATING_FILTERS',
  STOP_UPDATING_FILTERS = 'STOP_UPDATING_FILTERS',
  UPDATE_ACCOUNT_FILTER = 'UPDATE_ACCOUNT_FILTER',
  UPDATE_CONTACT_FILTER = 'UPDATE_CONTACT_FILTER',
  TOGGLE_GROUP_MODAL = 'TOGGLE_GROUP_MODAL',
  TOGGLE_CUSTOM_OPTION_MODAL = 'TOGGLE_CUSTOM_OPTION_MODAL',
  TOGGLE_MANUFACTURER_MODAL = 'TOGGLE_MANUFACTURER_MODAL',
  PUSH_NEW_GROUP = 'PUSH_NEW_GROUP',
  START_CONVERTING_TO_ORDER = 'START_CONVERTING_TO_ORDER',
  STOP_CONVERTING_TO_ORDER = 'STOP_CONVERTING_TO_ORDER',
  TOGGLE_CONVERT_MODAL = 'TOGGLE_CONVERT_MODAL',
  CONVERT_TO_ORDER = 'CONVERT_TO_ORDER',
  SET_ORDER_MISSING_VALUES = 'SET_ORDER_MISSING_VALUES',
  RESET_ORDER_STATES = 'RESET_ORDER_STATES',
  START_FETCHING_HASH = 'START_FETCHING_HASH',
  STOP_FETCHING_HASH = 'STOP_FETCHING_HASH',
  START_PRINTING = 'START_PRINTING',
  STOP_PRINTING = 'STOP_PRINTING',
  SET_SELECTED_ROWS = 'SET_SELECTED_ROWS',
  START_BULK_DELETE = 'START_BULK_DELETE',
  STOP_BULK_DELETE = 'STOP_BULK_DELETE',
}

export interface AccountDropdownModel extends DropdownOptionModel {
  businessTradeName: string;
  allUsersDetail?: UserModel[];
  groupDetail: GroupModel;
  taxDetails: AccountTaxModel[];
  businessAddress: string;
  businessCity: string;
  businessPostCode: string;
}

export interface ProjectDropdownModel extends DropdownOptionModel {
  client: string;
}

export interface QuoteDropdownModel extends DropdownOptionModel {
  project: number;
  data: QuoteModel;
}

export interface QuoteViewFilters {
  account?: AccountDropdownModel;
  contact?: DropdownOptionModel;
  project?: ProjectDropdownModel;
  quote?: QuoteDropdownModel;
}

export interface QuoteViewState {
  updatingFilters: boolean;
  filters: QuoteViewFilters;
  groups: string[];
  customOptionParent?: string;
  customOptionRow?: number;
  showGroupModal: boolean;
  showCustomOptionModal: boolean;
  showManufacturerModal: boolean;
  isIncompleteOrder: boolean;
  isConvertedToOrder: boolean;
  showConvertModal: boolean;
  convertingToOrder: boolean;
  missingFields: CreateOrderErrors;
  fetchingHash: boolean;
  printingQuote: boolean;
  selectedRows: number[];
  bulkDeleting: boolean;
}

export default function quoteViewReducer(state: QuoteViewState, action): QuoteViewState {
  const { type, payload } = action;

  switch (type as QuoteViewActions) {
    case QuoteViewActions.SET_FILTERS:
      return { ...state, filters: payload, updatingFilters: false };
    case QuoteViewActions.UPDATE_FILTERS:
      return { ...state, filters: { ...state.filters, ...payload } };
    case QuoteViewActions.START_UPDATING_FILTERS:
      return { ...state, updatingFilters: true };
    case QuoteViewActions.STOP_UPDATING_FILTERS:
      return { ...state, updatingFilters: false, };
    case QuoteViewActions.UPDATE_ACCOUNT_FILTER:
      return {
        ...state,
        filters: {
          account: payload,
          contact: payload.allUsersDetails.length > 0
            ? userDropdownData(payload.allUsersDetails[0], true)
            : null,
          project: undefined,
          quote: undefined,
        },
      };
    case QuoteViewActions.UPDATE_CONTACT_FILTER:
      return payload?.update
        ? {
          ...state,
          filters: {
            ...state.filters,
            contact: payload,
          },
        }
        : {
          ...state,
          filters: {
            ...state.filters,
            contact: payload,
            project: undefined,
            quote: undefined,
          },
        };
    case QuoteViewActions.TOGGLE_GROUP_MODAL:
      return { ...state, showGroupModal: !state.showGroupModal };
    case QuoteViewActions.PUSH_NEW_GROUP:
      return { ...state, groups: state.groups.concat(payload) };
    case QuoteViewActions.TOGGLE_CONVERT_MODAL:
      return {
        ...state,
        showConvertModal: !state.showConvertModal,
        convertingToOrder: false,
        isIncompleteOrder: false,
        isConvertedToOrder: false,
        missingFields: undefined,
      };
    case QuoteViewActions.START_CONVERTING_TO_ORDER:
      return { ...state, convertingToOrder: true };
    case QuoteViewActions.STOP_CONVERTING_TO_ORDER:
      return { ...state, convertingToOrder: false };
    case QuoteViewActions.CONVERT_TO_ORDER:
      return {
        ...state,
        showConvertModal: false,
        isConvertedToOrder: true,
        isIncompleteOrder: false,
        convertingToOrder: false,
        missingFields: undefined,
      };
    case QuoteViewActions.SET_ORDER_MISSING_VALUES:
      return {
        ...state,
        missingFields: payload,
        isIncompleteOrder: true,
        convertingToOrder: false,
      };
    case QuoteViewActions.RESET_ORDER_STATES:
      return {
        ...state,
        showConvertModal: false,
        convertingToOrder: false,
        isIncompleteOrder: false,
        isConvertedToOrder: false,
        missingFields: undefined,
      };
    case QuoteViewActions.START_FETCHING_HASH:
      return { ...state, fetchingHash: true };
    case QuoteViewActions.STOP_FETCHING_HASH:
      return { ...state, fetchingHash: false };
    case QuoteViewActions.START_PRINTING:
      return { ...state, printingQuote: true };
    case QuoteViewActions.STOP_PRINTING:
      return { ...state, printingQuote: false };
    case QuoteViewActions.SET_SELECTED_ROWS:
      return { ...state, selectedRows: payload };
    case QuoteViewActions.START_BULK_DELETE:
      return { ...state, bulkDeleting: true };
    case QuoteViewActions.STOP_BULK_DELETE:
      return { ...state, bulkDeleting: false, selectedRows: [] };
    case QuoteViewActions.TOGGLE_CUSTOM_OPTION_MODAL:
      return {
        ...state,
        customOptionParent: payload?.parent,
        customOptionRow: payload?.row,
        showCustomOptionModal: !state.showCustomOptionModal,
      };
    case QuoteViewActions.TOGGLE_MANUFACTURER_MODAL:
      return {
        ...state,
        showManufacturerModal: !state.showManufacturerModal,
      };
    default:
      return state;
  }
}
