import React, { FunctionComponent } from 'react';
import { Dropdown } from 'antd';
import { DropdownButtonProps } from 'antd/lib/dropdown';

const DropdownButton: FunctionComponent<DropdownButtonProps> = (props) => {
  const { children, icon: iconProp, ...rest } = props;

  const icon = typeof iconProp === 'string' ? <i className={iconProp} /> : iconProp;

  return (
    <Dropdown.Button icon={icon} {...rest}>
      {children}
    </Dropdown.Button>
  );
};

export default DropdownButton;
