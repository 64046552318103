import { useCallback, useMemo, useReducer } from 'react';
import { CategoryModel } from 'models/category';
import { BuilderCategory } from 'models/builder';
import builderCategoriesApi from 'api/builder-category';
import categoriesApi from 'api/category';
import useCallApiAction from 'hooks/base/call-api-action';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import selectCategory from '../../Builder/state/actions/select-category';
import handleStyleChange from '../../Builder/state/actions/handle-style-change';
import updateOptions from '../../Builder/state/actions/update-options';
import { BuilderState, StyleModel, StyleProviders } from '../../Builder/state';
import ProductViewerReducer from './reducer';
import notifyPropertiesToScene from '../../Builder/state/actions/notify-properties-to-scene';
import updateProperties from '../../Builder/state/actions/update-properties';

const initialState: BuilderState = {
  products: [],
  selectedProduct: undefined,
  selectedCategory: undefined,
  viewMode: '3d',
  fetchingProductData: false,
};

export default function useProductViewerState() {
  const [state, dispatch] = useReducer(ProductViewerReducer, initialState);
  const {
    selectedCategory,
    styleProvider,
    selectedProduct,
    error,
  } = state;

  const {
    fetching: fetchingCategories,
    data: categories,
    error: categoriesError,
  } = useCallApiAction<CategoryModel[]>(categoriesApi.getTopLevel, '', []);

  const {
    fetching: fetchingBuilderCategories,
    data: builderCategories,
    error: builderCategoriesError,
  } = useCallApiAction<BuilderCategory[]>(builderCategoriesApi.getTopLevel, '', []);

  const handleNewProductLoaded = useCallback(
    notifyPropertiesToScene(state.products),
    [state.products],
  );

  useEventCenterUpdate(
    EventChannelList.BUILDER_LOADED_PRODUCT,
    handleNewProductLoaded,
  );

  return {
    state: {
      ...state,
      categories,
      builderCategories,
      fetchingCategories: fetchingBuilderCategories || fetchingCategories,
      styles: useMemo<StyleModel[]>(
        () => (
          styleProvider === StyleProviders.Product
            ? (selectedProduct?.builderOptions.style as any).styles
            : selectedCategory?.categories
        ),
        [selectedProduct, styleProvider, selectedCategory],
      ),
      error: error || categoriesError || builderCategoriesError,
      loadingStage: state.fetchingProductData,
    },
    actions: {
      updateProperties: useCallback(
        updateProperties(dispatch, selectedProduct),
        [selectedProduct],
      ),
      updateOptions: useCallback(
        updateOptions(dispatch, selectedProduct),
        [selectedProduct],
      ),
      selectCategory: useCallback(selectCategory(dispatch), []),
      handleStyleChange: useCallback(
        handleStyleChange(dispatch, styleProvider, selectedProduct),
        [styleProvider],
      ),
    },
  };
}
