import { resolveApiErrorMessage } from 'api/base';
import userGroupApi from 'api/user-group';
import notification, { NotificationType } from 'helpers/notification';
import { ViewsPermissionsListActions } from '../reducer';

export default function updatePermissions(dispatch) {
  return (view) => {
    dispatch({ type: ViewsPermissionsListActions.ADD_UPDATING_VIEW, payload: view.groupname });

    const onSuccess = () => {
      const message = 'View\'s Permissions Successfully updated';
      notification({ type: NotificationType.SUCCESS, message });
    };

    const onError = (error) => {
      notification({
        message: resolveApiErrorMessage((error as any).response),
        type: NotificationType.ERROR,
      });
    };

    const onFinally = () => {
      dispatch({ type: ViewsPermissionsListActions.REMOVE_UPDATING_VIEW, payload: view.groupname });
    };

    return userGroupApi.updatePermissions(view)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
