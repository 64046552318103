import React, { FunctionComponent } from 'react';
import { Radio } from 'antd';

interface Props {
  value: any;
  label: string;
}

const RadioButton: FunctionComponent<Props> = (props) => {
  const { value, label } = props;

  return (
    <Radio.Button value={value}>
      {label}
    </Radio.Button>
  );
};

export default RadioButton;
