import { QboSyncModel } from 'models/qbo-sync';
import qboSyncApi from 'api/qbo-sync';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import useCallApiAction from 'hooks/base/call-api-action';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { useCallback, useMemo } from 'react';

const useTableStateParams: UseTableStateParams = {
  api: qboSyncApi,
  eventChannel: EventChannelList.QBO_SYNC_LIST_CHANGED,
};

const getOptionInitialValue = {
  actions: {
    POST: {
      action: { choices: [] },
      status: { choices: [] },
    }
  }
};

export default function useQBOSyncList() {
  const { state, actions } = useTableState<QboSyncModel>(useTableStateParams);
  const {
    data: tokenTypes,
    fetching: fetchingTokenTypes,
  } = useCallApiAction(qboSyncApi.getTokenTypes, undefined, { types: [] });

  const {
    data: options,
    fetching: fetchingOptions,
  } = useCallApiAction(qboSyncApi.getOption, undefined, getOptionInitialValue);

  return {
    state: {
      ...state,
      fetching: fetchingTokenTypes || fetchingOptions || state.fetching,
      tokenTypes: useMemo(() => tokenTypes.types.map(type => (
        { value: type, text: type })), [tokenTypes]),
      actionsFilters: useMemo(() => options.actions.POST.action.choices.map(action => (
        { value: action.value, text: action.display_name })), [options]),
      statusFilters: useMemo(() => options.actions.POST.status.choices.map(status => (
        { value: status.value, text: status.display_name })), [options]),
      labels: useMemo(() => {
        const actionLabels = options.actions.POST.action.choices
          .reduce((object, action) => ({ ...object, [action.value]: action.display_name }), {});
        const statusLabels = options.actions.POST.status.choices
          .reduce((object, status) => ({ ...object, [status.value]: status.display_name }), {});
        return { actionLabels, statusLabels };
      }, [options])
    },
    actions: {
      ...actions,
      reFetch: useCallback(() => notifyEventChannel(EventChannelList.QBO_SYNC_LIST_CHANGED), [])
    }
  };
}
