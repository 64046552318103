import React from 'react';
import { DeficiencyItemModel } from 'models/order';

export default function modelTemplate(data: DeficiencyItemModel) {
  const options = data.orderProduct.options?.map((option) => (
    <li key={`${option.optionType}-${data.id}`}>
      {`${option.optionType}: ${option.code ? `${option.code}-` : ''}${option.optionSelected}`}
    </li>
  ));

  return (
    <>
      <span className="text-muted">{data.orderProduct.model}</span>
      <ul>
        {options}
      </ul>
    </>
  );
}
