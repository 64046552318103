import { PurchaseOrderItemModel } from 'models/order';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/poitems/`;

const purchaseOrderItemApi: ApiModel<PurchaseOrderItemModel> =
  getApi<PurchaseOrderItemModel>(endpoint, ApiContentType.MULTIPART, true);

export default purchaseOrderItemApi;
