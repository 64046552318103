import React, { FunctionComponent } from 'react';
import CategoryPanel from '../Builder/CategoryPanel';
import Stage from './Stage';
import ProductPanel from './ProductPanel';
import StylesPanel from '../Builder/StylesPanel';
import useProductViewerState from './state';
import '../Builder/styles.scss';

const ProductViewer: FunctionComponent = () => {
  const {
    state: {
      products,
      fetchingProductData,
      selectedProduct,
      fetchingCategories,
      categories,
      builderCategories,
      selectedCategory,
      styles,
      loadingStage,
    },
    actions: {
      selectCategory,
      handleStyleChange,
      updateProperties,
      updateOptions,
    },
  } = useProductViewerState();

  return (
    <article className="builder">
      <section className="builder__content">
        <CategoryPanel
          blockUI={fetchingProductData}
          fetching={fetchingCategories}
          roomObjects={builderCategories}
          products={categories}
          selectedCategory={selectedCategory}
          onSelectCategory={selectCategory}
        />
        <Stage
          blockUI={loadingStage}
          models={products}
        />
        <ProductPanel
          blockUI={fetchingProductData}
          product={selectedProduct}
          onPropertiesChange={updateProperties}
          onOptionsChange={updateOptions}
        />
      </section>
      <StylesPanel
        styles={styles}
        onSelect={handleStyleChange}
      />
    </article>
  );
};

export default ProductViewer;
