import React, { FunctionComponent, useCallback } from 'react';
import { OrderModel } from 'models/order';
import { formatDisplayDate } from 'helpers/formatting';
import Tag from 'components/base-components/Tag';
import Popover from 'components/base-components/Popover';
import FlexBox from 'components/base-components/FlexBox';
import { Text, Link } from 'components/base-components/Typography';
import RenderIf from 'components/base-components/RenderIf';
import Button from 'components/base-components/Button';
import Tooltip from 'components/base-components/Tooltip';
import printInstallerOrder from './actions/print-installer-order';
import printShopOrder from './actions/print-shop-order';

interface Props {
  item: OrderModel;
  hasOrderPermission?: boolean;
}

const textStyle = { marginBottom: '8px' };

const OrderEvent: FunctionComponent<Props> = (props) => {
  const { item, hasOrderPermission } = props;
  const { id, jobTitle, statuses, readyDate, companyName, quote: { id: quoteId } } = item;
  const printInstaller = useCallback(() => printInstallerOrder(id), [id]);
  const printShop = useCallback(() => printShopOrder(id), [id]);

  const content = (
    <FlexBox direction="column">
      <Text strong>Order</Text>
      <RenderIf isTrue={!!id && hasOrderPermission}>
        <Link style={textStyle} href={`/quote/${quoteId}`}>{id}</Link>
      </RenderIf>
      <RenderIf isTrue={!id || !hasOrderPermission}>
        <Text style={textStyle}>N/A</Text>
      </RenderIf>
      <Text strong>Company Name</Text>
      <Text style={textStyle}>{companyName}</Text>
      <Text strong>Job Title</Text>
      <Text style={textStyle}>{jobTitle}</Text>
      <Text strong>Ready Date</Text>
      <Text>{formatDisplayDate(readyDate)}</Text>
    </FlexBox>
  );

  const title = (
    <FlexBox justify="space-between">
      <Text strong>Order Ready Date</Text>
      <RenderIf isTrue={!!id}>
        <Tooltip title="Print Installer">
          <Button size="small" type="text" shape="circle" onClick={printInstaller} icon="fa fa-tools" mL />
        </Tooltip>
        <Tooltip title="Print Shop">
          <Button size="small" type="text" shape="circle" onClick={printShop} icon="fa fa-shopping-bag" />
        </Tooltip>
      </RenderIf>
    </FlexBox>
  );

  return (
    <Popover
      content={content}
      overlayClassName="production-calendar__popovers"
      title={title}
      placement="bottomLeft"
      trigger="hover"
    >
      <Tag
        draggable
        className="production-calendar__tag"
        id={id.toString(10)}
        color={statuses?.readyDate ? statuses.readyDate : 'blue'}
      >
        <i className="fa fa-shopping-bag" />
        <span style={{ marginLeft: '8px', fontSize: '14px' }}>
          {`${id} - ${jobTitle}`}
        </span>
      </Tag>
    </Popover>
  );
};

export default OrderEvent;
