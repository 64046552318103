import { useMemo } from 'react';
import { QuoteModel } from 'models/quote';
import { ShipmentAddressModel, ShipmentContactModel, ShipmentDTO } from 'models/shipment';
import { ExtraChargeModel } from 'models/extra-charge';
import shippingAddressApi from 'api/shipping-address';
import shipmentContactApi from 'api/shipment-contact';
import extraChargeApi from 'api/extra-charge';
import useGetOne from 'hooks/base/get-one';
import useGetList from 'hooks/base/get-list';
import accountApi from 'api/account';
import { AccountModel } from 'models/account';

interface PrintoutInfo {
  quote: QuoteModel;
  shipment: ShipmentDTO;
}

function loadData() {
  const data = localStorage.getItem('quote-report-data');

  if (data) {
    return JSON.parse(data);
  }

  return {};
}

export default function usePrintoutState() {
  const { quote, shipment } = useMemo<PrintoutInfo>(loadData, []);

  const {
    fetching: fetchingContact,
    data: contact,
    error: contactError,
  } = useGetOne<ShipmentContactModel>(shipmentContactApi, shipment?.contact);

  const {
    fetching: fetchingAddress,
    data: address,
    error: addressError,
  } = useGetOne<ShipmentAddressModel>(shippingAddressApi, shipment?.shippingAddress);

  const extraChargeParams = useMemo(() => {
    return { filters: { quote: quote?.id } };
  }, [quote]);

  const {
    fetching: fetchingExtraCharges,
    data: extraCharges,
    error: chargesError,
  } = useGetList<ExtraChargeModel>(extraChargeApi, { queryParams: extraChargeParams });

  const accountParams = useMemo(() => ({
    filters: {
      search: quote?.project.client,
    },
  }), [quote]);

  const {
    fetching: fetchingAccounts,
    data: accounts,
    error: accountsError,
  } = useGetList<AccountModel>(accountApi, { queryParams: accountParams });

  return {
    noData: !quote && !shipment,
    fetching: fetchingContact || fetchingAddress || fetchingExtraCharges || fetchingAccounts,
    error: contactError || addressError || chargesError || accountsError,
    quote,
    shipment,
    contact: useMemo(() => (contact || {} as any), [contact]),
    address: useMemo(() => (address || { province: {} } as any), [address]),
    extraCharges,
    account: accounts ? accounts[0] : undefined,
  };
}
