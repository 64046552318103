import { DropdownOptionModel } from 'helpers/dropdown-options';
import { getPrevValue } from '..';
import { SelectState } from '../reducer';

export default function handleNewValue(
  state: SelectState,
  newValue: DropdownOptionModel[] | DropdownOptionModel,
): SelectState {
  const { internalOptions, prevValue } = state;
  const value = getPrevValue(newValue);
  const difference = value.filter(opt => !prevValue?.some(x => x.value === opt.value));
  const newOptions = difference.filter(val => !internalOptions?.some(x => x.value === val.value));
  return {
    internalOptions: [...newOptions, ...internalOptions],
    prevValue: value,
  };
}
