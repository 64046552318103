import { Rule, RuleType } from 'helpers/form-validations';

const requiredRule: Rule = { type: RuleType.Required, message: 'This field is required' };

const validationRules = {
  name: [requiredRule],
  model: [requiredRule],
  mpn: [requiredRule],
  price: [requiredRule],
};

export default validationRules;
