import { useCallback, useEffect, useReducer } from 'react';
import { QuoteModel } from 'models/quote';
import { setFormValue, editSpecialInstructions, setValidationErrors } from './actions';
import SpecialInstructionsFormReducer, { SpecialInstructionsFormActions } from './reducer';

export default function useSpecialInstructionsState(quote: QuoteModel, updateDataQuoteManually) {
  const [state, dispatch] = useReducer(
    SpecialInstructionsFormReducer,
    {
      formData: {
        specialInstructions: '',
      },
      errors: {},
      editing: false,
    },
  );
  const { formData } = state;
  useEffect(() => {
    if (quote) {
      dispatch({
        type: SpecialInstructionsFormActions.SET_FORM_VALUE,
        payload: {
          specialInstructions: quote.specialInstructions,
        }
      });
    }
  }, [quote]);
  return {
    state: {
      ...state
    },
    actions: {
      handleValueChange: useCallback(setFormValue(dispatch), []),
      setValidationErrors: useCallback(setValidationErrors(dispatch), []),
      handleOnSubmit: useCallback(
        editSpecialInstructions(dispatch, formData, quote, updateDataQuoteManually),
        [formData, quote]
      ),
    },
  };
}
