/* eslint-disable */
import React from 'react';
import SceneManager from './app';
import { EventChannelList, listenToEventChannel } from 'helpers/event-center';
import './style.scss';

import iconMouseDrag from './icon-mousedrag.png';
interface iProps {
  data: Object;
}

interface iState {
  tipVisible: boolean,
  cameraPosition: number,
}

interface IPropertyChangeEvent {
  name: string;
  value: number;
  useMorphs: boolean;
  morphLabel: string;
  morphIndex: number;
  groupNameInModel: string;
  nameInModel: string;
}

interface IOptionChangeEvent {
  name: string;
  mode: string;
  image: string;
  value: string;
  nameInModel: string;
  textureWidth: string;
  textureHeight: string;
  groupNameInModel: string;
}

const cameraPositionPresets = [
  { x: 0, y: 0, z: -1 },
  { x: -1, y: 0, z: 0 },
  { x: 0, y: 1, z: 0.01 },
  { x: 1, y: 0, z: 0 },
  { x: 0, y: 0, z: 1 },
  { x: 0.5773, y: 0.5773, z: 0.5773 },
]

export default class Blueprint3DProductViewer extends React.Component<iProps, iState> {
  refContainer = null;
  sceneManager = null;
  selectedItem = null;

  constructor(props: iProps) {
    super(props);
    this.state = {
      tipVisible: true,
      cameraPosition: -1,
    }

    listenToEventChannel(
      EventChannelList.BUILDER_PRODUCT_PROPERTY_CHANGED,
      (data: IPropertyChangeEvent) => {
        if (data.useMorphs) {
          this.setMorph(data.morphIndex, data.value);
        } else {
          this.updateStyle(data.groupNameInModel, data.nameInModel);
        }
      },
    );

    listenToEventChannel(
      EventChannelList.BUILDER_PRODUCT_OPTION_CHANGED,
      (data: IOptionChangeEvent) => {
        console.log(data)
        if (data.mode === 'texture') {
          this.updateMaterial(
            data.groupNameInModel,
            { texture: data.image },
            data.textureWidth,
            data.textureHeight,
          );
        } else if (data.mode === 'hide') {
          this.updateStyle(data.groupNameInModel, data.nameInModel);
        }
      },
    );
  }

  componentDidMount = () => {
    this.sceneManager = new SceneManager(this.refContainer);
    this.sceneManager.itemSelectedCallbacks.push(this.handleItemSelected);
  }

  addItem = (item) => {
    console.log('add item', item)
    const defaultStyles = {};
    const defaultMorph = {};
    const defaultTextures = {};

    // fetch default styles && textures
    for (const key in item.builderOptions) {
      // noinspection JSUnfilteredForInLoop
      const optionGroup = item.builderOptions[key];
      const shouldSetDefault = (
        optionGroup.propertyType === 'select' &&
        optionGroup.options.length
      );

      if (shouldSetDefault) {
        defaultStyles[optionGroup.nameInModel] = optionGroup.options[0].nameInModel;
      }
    }

    Array.isArray(item.optionGroups) && item.optionGroups.forEach(optionGroup => {
      optionGroup.mode === 'hide' && optionGroup.options.length && (
        defaultStyles[optionGroup.nameInModel] = optionGroup.options[0].nameInModel
      );

      optionGroup.mode === 'texture' && optionGroup.options.length && (
        defaultTextures[optionGroup.nameInModel] = {
          material: {
            texture: optionGroup.options[0].image,
          },
          size: {
            w: optionGroup.options[0].textureWidth / 100,
            h: optionGroup.options[0].textureHeight / 100,
          }
        }
      );
    });

    // fetch default morph
    (() => {
      const shouldSetMinHeight = (
        item.builderOptions &&
        item.builderOptions.height &&
        item.builderOptions.height.minValue
      );
      const shouldSetMinWidth = (
        item.builderOptions &&
        item.builderOptions.width &&
        item.builderOptions.width.minValue
      );
      const shouldSetMinDepth = (
        item.builderOptions &&
        item.builderOptions.depth &&
        item.builderOptions.depth.minValue
      );

      if (shouldSetMinHeight) {
        defaultMorph[0] = item.builderOptions.height.minValue;
        if (item.modelDescription && item.modelDescription.height) defaultMorph[0] = item.modelDescription.height;
      }
      if (shouldSetMinWidth) {
        defaultMorph[1] = item.builderOptions.width.minValue;
        if (item.modelDescription && item.modelDescription.width) defaultMorph[1] = item.modelDescription.width;
      }
      if (shouldSetMinDepth) {
        defaultMorph[2] = item.builderOptions.depth.minValue;
        if (item.modelDescription && item.modelDescription.depth) defaultMorph[2] = item.modelDescription.depth;
      }

      for (const i in defaultMorph) {
        defaultMorph[i] = (defaultMorph[i] - 5) / 295;
      }
    })();

    try {
      this.selectedItem && this.selectedItem.remove();
      let type = 1;
      if (item.position && item.position.index) type = item.position.index;
      this.sceneManager.addItem(
        type,
        item.threeModel,
        { ...item },
        null,
        null,
        {
          styles: defaultStyles,
          morph: defaultMorph,
          textures: defaultTextures,
        }
      );
    } catch (_) {
      console.log(_);
    }
  }

  onChangeSize = (clientHeight) => {
    this.sceneManager.onChangeSize(clientHeight)
  }

  updateMaterial = (target, material, textureWidth, textureHeight) => {
    console.log('material', target, material, textureWidth, textureHeight)
    if (!this.selectedItem) return;

    const size = {
      w: textureWidth / 100,
      h: textureHeight / 100,
    };

    this.selectedItem.updateMaterial(
      target,
      material,
      size,
      () => setTimeout(this.update, 100),
    );
  };

  updateStyle = (hide_name, show_name) => {
    console.log('style', hide_name, show_name)
    this.selectedItem &&
      this.selectedItem.updateStyle(
        hide_name,
        show_name,
        () => setTimeout(this.update, 100),
      );
  };

  setMorph = (index, value) => {
    console.log('morph', index, value);
    this.selectedItem && this.selectedItem.setMorph(index, value);
    setTimeout(this.update, 100);
  };

  update = () => {

  }

  handleItemSelected = (item) => {
    this.selectedItem = item;
  };

  handleItemUnselected = () => {
    this.selectedItem = null;
  };

  setCameraPosition = (cameraPosition: number) => {
    this.setState({ cameraPosition });
    if (cameraPosition >= 0 && cameraPosition < cameraPositionPresets.length) {
      this.sceneManager.setCameraPositionPreset(cameraPositionPresets[cameraPosition]);
    }
  }

  render() {
    const { tipVisible, cameraPosition } = this.state;
    return (
      <div
        className="scene-container"
        ref={ref => this.refContainer = ref}
        onPointerDown={() => this.setState({ tipVisible: false })}
        onTouchStart={() => this.setState({ tipVisible: false })}
      >
        <div className="tip-container" hidden={!Boolean(tipVisible)}>
          <img src={iconMouseDrag} alt="mouse-drag" />
        </div>
        <div className="camera-controls-container">
          <div>
            <div style={{ display: 'block' }}>
              <div className={"camera-control-button" + (cameraPosition == 5 ? " active" : "")} onClick={() => this.setCameraPosition(5)}><span>3D</span></div>
            </div>
            <div style={{ display: 'block' }}>
              <div>
                <div className={"camera-control-button" + (cameraPosition == 0 ? " active" : "")} onClick={() => this.setCameraPosition(0)}><span>Back</span></div>
              </div>
              <div>
                <div className={"camera-control-button" + (cameraPosition == 1 ? " active" : "")} onClick={() => this.setCameraPosition(1)}><span>Left</span></div>
                <div className={"camera-control-button" + (cameraPosition == 2 ? " active" : "")} onClick={() => this.setCameraPosition(2)}><span>Top</span></div>
                <div className={"camera-control-button" + (cameraPosition == 3 ? " active" : "")} onClick={() => this.setCameraPosition(3)}><span>Right</span></div>
              </div>
              <div>
                <div className={"camera-control-button" + (cameraPosition == 4 ? " active" : "")} onClick={() => this.setCameraPosition(4)}><span>Front</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
