import { commonRules } from 'helpers/form-validations';

const { required: requiredRule } = commonRules;

export const modelValidationRules = {
  threeModel: [requiredRule],
  modelName: [requiredRule],
  modelPosition: [requiredRule],
};

export const morphValidationRules = {
  morphIndex: [requiredRule],
  morphLabel: [requiredRule],
  property: [requiredRule],
};
