import { Actions } from '../reducer';

export default function setTexture(dispatch) {
  return (texture) => {
    dispatch({
      type: Actions.SET_TEXTURE,
      payload: texture,
    });
  };
}
