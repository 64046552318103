import React from 'react';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import Button from 'components/base-components/Button';
import Tag from 'components/base-components/Tag';
import { MaterialsDetailsHistorySateModel } from '../MaterialsDetails';

const formatValue = (value) => value % 1 !== 0 ? `${parseFloat(value).toFixed(2)}%` : `${value}%`;

export default function materialsRender(title: string, push) {
  return (data) => {
    const dataIndex = title.toLowerCase();
    const material = data.materials[dataIndex];
    const historyLocationState = {
      details: {
        title,
        material,
      }
    } as MaterialsDetailsHistorySateModel;
    const value = isNaN(material?.percentage) ? material?.percentage : formatValue(material?.percentage);

    const onClick = () => push(ModalRouteHash.MaterialsDetails, historyLocationState);
    const color = data?.statuses?.materials?.[dataIndex] !== 'FFFFFF' ? data?.statuses?.materials?.[dataIndex] : undefined;

    return (
      <Button className="button-cell__button" type="text" onClick={onClick}>
        <Tag color={color}>
          {value}
        </Tag>
      </Button>
    );
  };
}
