import { InvoiceModel } from 'models/invoice';
import { ModalRouteHash } from 'components/base-components/ModalRouter';
import { PaymentFormNavigationState } from '../../../Orders/PaymentForm/state';

export default function openReceivePaymentModal(push) {
  return (invoice: InvoiceModel) => {
    push(ModalRouteHash.ReceivePayment, {
      paymentForm: {
        data: {
          orderId: invoice.orderId,
          companyName: invoice.account,
          jobTitle: invoice.jobName,
          totalWithTax: invoice.total,
        },
      },
    } as PaymentFormNavigationState);
  };
}
