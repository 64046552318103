import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiModel } from 'api/base';
import { EventChannelList } from 'helpers/event-center';
import { ValidationRules } from 'helpers/form-validations';
import useGetOne from 'hooks/base/get-one';
import {
  SubmitFormParams,
  setErrors,
  setFormValue,
  handleOnSubmit,
} from './actions';
import formReducer, { FormActions } from './reducer';

export interface UseFormStateParams<T = any> {
  api: ApiModel<T>;
  eventChannel?: EventChannelList;
  getPayload: (entity: T) => any;
  getFormData: (entity?: T) => any;
  param?: string | number;
  validationRules?: ValidationRules;
  onSuccess?: (response?) => void;
  noGoBackOnSuccess?: boolean;
  clearOnSuccess?: boolean;
  replaceAction?: (payload?: any) => any;
  replaceGoBack?: () => any;
  pK?: string;
}

export default function useFormState<T>(params: UseFormStateParams<T>) {
  const { goBack } = useHistory();
  const {
    api,
    param,
    eventChannel,
    getPayload,
    getFormData,
    validationRules,
    onSuccess,
    noGoBackOnSuccess,
    clearOnSuccess,
    pK,
    replaceAction,
    replaceGoBack
  } = params;
  const [state, dispatch] = useReducer(
    formReducer,
    {
      formData: getFormData(),
      errors: {},
      saving: false,
    },
  );

  const {
    data: entity,
    error: errorFetchingEntity,
    fetching: fetchingEntity,
    fetchOne,
  } = useGetOne<T>(api, param, { preventAutoFetch: true });

  useEffect(() => {
    if (param) {
      fetchOne();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  useEffect(() => {
    if (entity) {
      dispatch({ type: FormActions.SET_FORM_VALUE, payload: getFormData(entity) });
    }
  }, [entity, getFormData]);

  const { formData } = state;

  const submitFormParams: SubmitFormParams = useMemo(
    () => ({
      api,
      formData,
      dispatch,
      getPayload,
      eventChannel,
      validationRules,
      onSaveSuccess: onSuccess,
      noGoBackOnSuccess,
      clearOnSuccess,
      pK,
      replaceAction,
      goBack: replaceGoBack || goBack,
      getFormData,
    }),
    [dispatch, api, goBack, eventChannel, getPayload, formData, replaceGoBack, getFormData,
      validationRules, onSuccess, noGoBackOnSuccess, pK, replaceAction, clearOnSuccess],
  );

  return {
    state: {
      ...state,
      fetchingEntity,
      errorFetchingEntity,
    },
    actions: {
      reFetchEntity: fetchOne,
      setFormValue: useCallback(setFormValue(dispatch), []),
      setErrors: useCallback(setErrors(dispatch), []),
      saveEntity: useCallback(handleOnSubmit(submitFormParams), [submitFormParams]),
    },
  };
}
