import React, { FunctionComponent, useCallback } from 'react';
import Page from 'components/base-components/Page';
import DataTable, { DataTableColumn } from 'components/base-components/DataTableV2';
import Select, { OptGroup, Option } from 'components/base-components/Select';
import DatePicker from 'components/base-components/DatePicker';
import Form, { Field } from 'components/base-components/Form';
import Help from 'components/forms/CommonHelp';
import BackTop from 'components/base-components/BackTop';
import batchTemplate from './templates/batch-template';
import groupTemplate from './templates/group-template';
import repTemplate from './templates/rep-template';
import totalTemplate from './templates/total-template';
import FilterDropdown from './FilterDropdown';
import Summary from './Summary';
import useSalesReportState, { GroupBy } from './state';
import './styles.scss';

const SalesReport: FunctionComponent = () => {
  const {
    state: {
      fetching,
      data,
      totalSortDirections,
      filters,
      tableFilters,
      accountOptions,
      groupOptions,
      repOptions,
    },
    actions: {
      setFilters,
      sortByTotal,
      handleGroupByChange,
      filterAccounts,
      filterGroups,
      filterReps,
      setAccountFilters,
      setGroupFilters,
      setRepFilters,
    },
  } = useSalesReportState();

  const { groupBy } = filters;

  const renderFilter = useCallback((groupByTarget: GroupBy, filter) => {
    if (!groupBy || groupBy.value === groupByTarget) {
      return filter;
    }
    return undefined;
  }, [groupBy]);

  const accountFilterDropdown = useCallback((props) => (
    <FilterDropdown
      {...props as any}
      options={accountOptions}
      setFilters={setAccountFilters}
    />
  ), [accountOptions, setAccountFilters]);

  const groupFilterDropdown = useCallback((props) => (
    <FilterDropdown
      {...props as any}
      options={groupOptions}
      setFilters={setGroupFilters}
    />
  ), [groupOptions, setGroupFilters]);

  const repFilterDropdown = useCallback((props) => (
    <FilterDropdown
      {...props as any}
      options={repOptions}
      setFilters={setRepFilters}
    />
  ), [repOptions, setRepFilters]);

  const summary = useCallback(() => (
    <Summary
      data={data}
      tableFilters={tableFilters}
      filterAccounts={filterAccounts}
      filterGroups={filterGroups}
      filterReps={filterReps}
      grouped={!!groupBy}
    />
  ), [data, groupBy, tableFilters, filterAccounts, filterGroups, filterReps]);

  return (
    <Page title="Sales Report" contentClassName="sales-report__page">
      <Help view="sales-reports" title="Reports" />
      <section className="sales-report__container">
        <Form
          state={filters}
          onChange={setFilters}
          className="sales-report__table-top"
        >
          <Field
            id="startDate"
            name="startDate"
            label="Start Date"
            component={DatePicker}
            className="sales-report__table-top__date-picker"
            mL
          />
          <Field
            id="endDate"
            name="endDate"
            label="End Date"
            component={DatePicker}
            className="sales-report__table-top__date-picker"
            mL
          />
          <Field
            id="groupBy"
            name="groupBy"
            label="Group By"
            component={Select}
            onChange={handleGroupByChange}
            className="sales-report__table-top__group-by"
            allowClear
          >
            <OptGroup label="Sales">
              <Option value={GroupBy.ACCOUNT}>Account</Option>
              <Option value={GroupBy.GROUP}>Group</Option>
              <Option value={GroupBy.REP}>Sales Rep</Option>
            </OptGroup>
            <OptGroup label="Date">
              <Option value={GroupBy.CREATED_DATE}>Created Date</Option>
            </OptGroup>
          </Field>
        </Form>
        <DataTable
          loading={fetching}
          dataSource={data}
          pagination={false}
          childrenColumnName="batch"
          summary={summary}
        >
          <DataTableColumn
            columnKey="account"
            title="Account"
            render={batchTemplate}
            filterDropdown={renderFilter(GroupBy.ACCOUNT, accountFilterDropdown)}
            onFilter={filterAccounts}
          />
          <DataTableColumn
            columnKey="accountGroup"
            title="Group"
            render={groupTemplate}
            filterDropdown={renderFilter(GroupBy.GROUP, groupFilterDropdown)}
            onFilter={filterGroups}
          />
          <DataTableColumn
            columnKey="rep"
            title="Sales Rep"
            render={repTemplate}
            filterDropdown={renderFilter(GroupBy.REP, repFilterDropdown)}
            onFilter={filterReps}
          />
          <DataTableColumn
            columnKey="total"
            title="Total"
            render={totalTemplate}
            sorter={sortByTotal}
            sortDirections={totalSortDirections}
          />
        </DataTable>
      </section>
      <BackTop />
    </Page>
  );
};

export default SalesReport;
