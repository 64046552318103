import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Typography } from 'antd';
import Drawer from 'components/base-components/Drawer';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import DatePicker from 'components/base-components/DatePicker';
import Button from 'components/base-components/Button';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import useConfirmReadyDateFormState, { Owner } from './state';

export { Owner } from './state';

export interface ConfirmReadyDateHistorySateModel {
  confirmReadyDate: {
    orderId: number;
    owner: Owner;
  };
}

const ConfirmReadyDate: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { confirmReadyDate: { orderId, owner } } = state as ConfirmReadyDateHistorySateModel;
  const {
    state: {
      order,
      savingOrder,
      fetchingFormData,
      shipmentType,
    },
    actions: {
      saveOrder,
      setFormValue,
    }
  } = useConfirmReadyDateFormState(orderId, owner);

  const { readyDateConfirmation, quote } = order;
  const labelButton = useMemo(() => readyDateConfirmation ? 'Unconfirm Ready Date' : 'Confirm Ready Date', [readyDateConfirmation]);

  return (
    <Drawer
      title={`Ready Date Confirmation- Order ${orderId}`}
      visible
      onClose={goBack}
      width="30vw"
    >
      <RenderIf isTrue={!!quote && !!shipmentType} fallback={<Spinner />}>
        <dl>
          <div>
            <dd>
              <Typography.Text strong>
                {'Company: '}
              </Typography.Text>
              {quote?.project?.companyInfo?.businessLegalName}
            </dd>
            <dd>
              <Typography.Text strong>
                {'Customer Contact: '}
              </Typography.Text>
              {`${quote?.project?.clientInfo?.firstName} ${quote?.project?.clientInfo?.firstName}`}
            </dd>
            <dd>
              <Typography.Text strong>
                {'Tel: '}
              </Typography.Text>
              {quote?.project?.companyInfo?.businessPhone}
            </dd>
            <dd>
              <Typography.Text strong>
                {'Email: '}
              </Typography.Text>
              {quote?.project?.clientInfo?.email}
            </dd>
            <dd>
              <Typography.Text strong>
                {'Shipping Method: '}
              </Typography.Text>
              {shipmentType}
            </dd>
          </div>
        </dl>
      </RenderIf>
      <Form onChange={setFormValue} state={order}>
        <Field
          component={DatePicker}
          name="readyDate"
          id="readyDate"
          label="ReadyDate"
          disabled={readyDateConfirmation || fetchingFormData}
        />
      </Form>
      <Button label={labelButton} onClick={saveOrder} disabled={fetchingFormData} loading={savingOrder} />
    </Drawer>
  );
};

export default ConfirmReadyDate;
