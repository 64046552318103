import { QuoteModel } from 'models/quote';
import { getLoggedUserId } from 'helpers/get-logged-user';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { ReuseQuoteHistorySateModel } from '../../ReuseQuoteForm';

export default function openCopyQuoteModal(push, search) {
  return ({ id }: QuoteModel) => {
    const historyLocationState: ReuseQuoteHistorySateModel = {
      reuseQuoteForm: {
        repOwner: getLoggedUserId(),
        quoteId: id,
      },
    };
    push(`${search}${ModalRouteHash.ReuseQuote}`, historyLocationState);
  };
}
