import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { TransferProps } from 'antd/lib/transfer';
import difference from 'lodash/difference';
import { CncOrderModel } from 'models/cnc-order';
import cncOrderApi from 'api/cnc-order';
import deficiencyItemApi from 'api/deficiency-item';
import orderApi from 'api/orders';
import quoteApi from 'api/quote';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import Transfer from 'components/base-components/Transfer';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Tag from 'components/base-components/Tag';

interface TabProps {
  type: 'deficiency' | 'order' | 'quote';
  targetKeys: string[];
  onChange: (nextTargetKeys: any[], dataRow: any [], direction: string, moveKey: string[]) => void;
  dataRow: any[];
}

interface TableTransferProps extends TransferProps<any> {
  fetching: boolean;
  count: number;
  page: number;
  handleOnChange: any;
  forceData: any[];
}

const tabStateParams: {[x: string]: UseTableStateParams} = {
  deficiency: {
    overrideListFunction: cncOrderApi.listDeficiencies,
    api: deficiencyItemApi,
    pageSize: 10,
    eventChannel: EventChannelList.CNC_ORDER_LIST_CHANGED
  },
  order: {
    overrideListFunction: cncOrderApi.listOrders,
    api: orderApi,
    pageSize: 10,
    eventChannel: EventChannelList.CNC_ORDER_LIST_CHANGED
  },
  quote: {
    overrideListFunction: cncOrderApi.listQuotes,
    api: quoteApi,
    pageSize: 10,
    eventChannel: EventChannelList.CNC_ORDER_LIST_CHANGED
  }
};

const tagColor = {
  deficiency: 'green',
  order: 'blue',
  quote: 'cyan',
};

const renderType = (type) => (<Tag color={tagColor[type]}>{type}</Tag>);

const TableTransfer: FunctionComponent<TableTransferProps> = (
  { fetching, page, count, handleOnChange, forceData, ...restProps }
) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
    }) => {
      const hideColumn = (direction === 'right');
      const dataSource = hideColumn ? filteredItems : [...filteredItems, ...forceData];
      const internalPage = !hideColumn ? page : undefined;
      const internalCount = !hideColumn ? count : undefined;
      const internalOnChange = !hideColumn ? handleOnChange : undefined;
      const rowSelection = {
        getCheckboxProps: item => ({ disabled: item.key.includes('force') }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <DataTable
          page={internalPage}
          loading={fetching}
          onChange={internalOnChange}
          totalRecords={internalCount}
          rowSelection={rowSelection}
          dataSource={dataSource}
          rowKey="key"
          size="small"
          onRow={({ key }) => ({
            onClick: () => {
              if (key.includes('force')) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        >
          <DataTableColumn dataIndex="id" columnKey="id" title="Id" />
          <DataTableColumn dataIndex="cncItemsCount" columnKey="cnc_items_count" hideColumn={hideColumn} title="CNC" />
          <DataTableColumn dataIndex="jobName" columnKey="job_name" title="Job Name" />
          <DataTableColumn dataIndex="account" columnKey="account" hideColumn={hideColumn} title="account" />
          <DataTableColumn dataIndex="total" columnKey="total" hideColumn={hideColumn} title="total" isCurrency />
          <DataTableColumn dataIndex="type" columnKey="type" title="Type" hideColumn={!hideColumn} render={renderType} />
        </DataTable>
      );
    }}
  </Transfer>
);

const TabInstance: FunctionComponent<TabProps> = ({ type, targetKeys, onChange, dataRow }) => {
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleOnChange,
    }
  } = useTableState<CncOrderModel>(tabStateParams[type]);
  const dataSource = useMemo(() => [
    ...data.map(e => ({ ...e, key: `${type}-${e.id}`, type })),
    ...dataRow.filter(i => (!data.find(d => (d.id === i.id && i.source.sourceType === d.source.sourceType))))
  ], [data, type, dataRow]);
  const forceData = useMemo(() => dataRow
    .filter(i => (i.type === type && !!data.find(d => d.id === i.id))).map(e => ({ ...e, key: `force-${e.key}` })),
  [dataRow, type, data]);
  const handleOnnChangeTransfer = useCallback((target: string[], direction, moveKeys) => {
    onChange(target, dataSource.filter(e => target.includes(e.key)), direction, moveKeys);
  }, [onChange, dataSource]);

  return (
    <TableTransfer
      dataSource={dataSource}
      targetKeys={targetKeys}
      onChange={handleOnnChangeTransfer}
      count={count}
      page={page}
      fetching={fetching}
      handleOnChange={handleOnChange}
      showSelectAll={false}
      forceData={forceData}
    />
  );
};

export default TabInstance;
