import {
  CategoryForProduct,
  ManufacturerForProduct,
  ProductModel,
} from 'models/product';
import { getModel } from 'helpers/builder-util';

export default function getPayload(product, quote, priceData: any[], staticForm?) {
  const { modelDescription, options } = product;
  const model = getModel(modelDescription);
  const optionDataPrice = priceData.find((e) => e.model === model)?.options;
  const flatOptions = Object.keys(options).reduce((opt, optionType) => {
    const option = options[optionType]?.dropdownItem;
    const subOption = options[`sub-${optionType}`];
    if (optionType.includes('sub')) {
      return opt;
    }
    return opt.concat({
      option_type: optionType,
      option_selected: subOption?.value
        ? `${option.name}_${subOption?.value}`
        : option.name,
      description: option.description,
      description_fr: option.descriptionFr,
      code: subOption?.dropdownItem ? subOption.dropdownItem.code : option.code,
      brand: option.brand,
      user_input: option.userInput,
      price: optionDataPrice[optionType]?.price || option.price,
      cost: option.cost,
      user_comment: option.userComment || null,
      additional_cost: option.additionalCost || null,
    });
  }, []);

  return {
    model,
    quote,
    options: flatOptions,
    group: staticForm?.group?.value || '',
    quantity: staticForm?.quantity || 1,
  };
}

export function getProductPayload(
  product: ProductModel,
  discount: number,
  modalPayload,
) {
  const {
    category,
    manufacturer,
    price,
  } = product;

  const { outsidePaint, name, cnc } = category as CategoryForProduct;
  const { companyName } = manufacturer as ManufacturerForProduct;

  return {
    ...product,
    outsidePaint,
    cnc,
    category: name,
    manufacturer: companyName,
    price: discount ? (price - (price * (discount / 100))).toFixed(2) : price,
    recommendedPrice: price,
    ...modalPayload,
  };
}
