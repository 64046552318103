import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router';
import { InstallerBookingDTO } from 'models/project-coordinator';
import installerApi from 'api/installer';
import Drawer from 'components/base-components/Drawer';
import { validationRules } from 'components/pages/InstallersBooking/Form/validations';
import { mapInstallerOptions } from 'components/pages/InstallersBooking/Form';
import Field from 'components/base-components/Form/Field';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import DatePicker from 'components/base-components/DatePicker';
import Form from 'components/base-components/Form';
import Button from 'components/base-components/Button';
import RenderIf from 'components/base-components/RenderIf';
import PopConfirm from 'components/base-components/PopConfirm';
import InstallerItem from './InstallerItem';
import useSetInstaller from './state';
import './styles.scss';

export interface SetInstallerHistorySateModel {
  setInstaller: {
    jobName: string;
    projectId: number;
    installers: InstallerBookingDTO[];
    orderId: number;
  };
}

const SetInstaller: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { setInstaller: { installers, jobName, projectId, orderId } } = state as SetInstallerHistorySateModel;
  const {
    state: {
      installersBooking,
      errors,
      saving,
      formData,
      installerSelected
    },
    actions: {
      setErrors,
      setFormValue,
      onChange,
      handleOnDelete,
      onSave,
    }
  } = useSetInstaller(installers, projectId, orderId);

  const InstallerMapped = useMemo(() => installersBooking.map((data) => {
    const isSelected = installerSelected === data.id;
    return (
      <InstallerItem
        key={data.id}
        data={data}
        handleOnDelete={handleOnDelete}
        handleOnSelect={onChange}
        isSelected={isSelected}
      />
    );
  }), [installersBooking, installerSelected, handleOnDelete, onChange]);
  const actionLabel = formData.id ? 'Edit' : 'Add';

  return (
    <Drawer
      title={`Set Installer for ${jobName}`}
      visible
      onClose={goBack}
      width="40vw"
    >
      <div className="project-coordinator__container">
        <dl>
          {InstallerMapped}
        </dl>
      </div>
      <RenderIf isTrue={!!formData.id}>
        <PopConfirm title="Sure to discard changes?" onConfirm={onChange}>
          <Button label="Add New" mB />
        </PopConfirm>
      </RenderIf>
      <Form
        state={formData}
        onChange={setFormValue}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <Field
          component={AsyncSelectV2}
          name="installer"
          id="installer"
          placeholder="Select an Installer"
          label="Installer"
          listAction={installerApi.list}
          mapFunction={mapInstallerOptions}
        />
        <Field
          component={DatePicker}
          name="installationDate"
          id="installationDate"
          label="Installation Date"
          showTime
        />
      </Form>
      <div className="project-coordinator__button-wrapper">
        <Button onClick={onSave} loading={saving} label={actionLabel} />
      </div>
    </Drawer>
  );
};

export default SetInstaller;
