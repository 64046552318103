import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import Divider from 'components/base-components/Divider';
import RenderIf from 'components/base-components/RenderIf';
import NumberInput from 'components/base-components/NumberInput';
import DatePicker from 'components/base-components/DatePicker';
import TextArea from 'components/base-components/TextArea';
import Upload from 'components/base-components/Upload';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTable from 'components/base-components/DataTableV2';
import Tab, { TabPane } from 'components/base-components/Tab';
import Spinner from 'components/base-components/Spinner';
import usePaymentFormState from './state';
import { paymentRules } from './state/validation-rules';
import PaymentSummary from './PaymentSummary';

const ReceivePaymentForm: FunctionComponent = () => {
  const { goBack } = useHistory();
  const {
    state: {
      data,
      payment,
      summary,
      fetchingData,
      fetchingPaymentMethods,
      paymentMethods,
      errors,
      savingPayment,
    },
    actions: {
      setFormValue,
      setErrors,
      savePayment,
    },
  } = usePaymentFormState();

  const { type: { label: paymentType } } = payment;

  const amountLabel = useMemo(() => (
    (paymentType !== 'Cheque' && paymentType !== 'Check')
      ? 'Amount of Cash'
      : 'Amount on Cheque'
  ), [paymentType]);

  return (
    <Modal
      visible
      title="Transactions"
      okText="Process"
      onOk={savePayment}
      onCancel={goBack}
      confirmLoading={savingPayment}
    >
      <RenderIf isTrue={!fetchingData} fallback={<Spinner />}>
        <Tab defaultActiveKey="1">
          <TabPane tab="Transaction Summary" key="1">
            <DataTable
              dataSource={[data]}
              pagination={false}
              summary={() => <PaymentSummary dataSummary={summary} />}
            >
              <DataTableColumn title="Order" dataIndex="orderId" />
              <DataTableColumn
                title="Job Title"
                dataIndex="jobTitle"
                columnKey="job_title"
              />
              <DataTableColumn
                title="Company"
                dataIndex="companyName"
                columnKey="company_name"
              />
              <DataTableColumn
                isCurrency
                title="Total"
                dataIndex="totalWithTax"
                columnKey="totalWithTax"
              />
            </DataTable>
          </TabPane>
          <TabPane tab="Receive Payment" key="2">
            <Form
              onChange={setFormValue}
              state={payment}
              errors={errors}
              onError={setErrors}
              rules={paymentRules}
            >
              <Field
                name="type"
                label="Payment Type"
                component={Select}
                options={paymentMethods}
                loading={fetchingPaymentMethods}
              />
              <Divider type="horizontal" />
              <RenderIf isTrue={paymentType === 'Cheque' || paymentType === 'Check'}>
                <Field name="name" label="Name on Cheque" />
              </RenderIf>
              <Field
                id="amount"
                name="amount"
                label={amountLabel}
                component={NumberInput}
                allowDecimals
              />
              <Field
                component={DatePicker}
                name="receivedDate"
                id="receivedDate"
                label="Received Date"
              />
              <RenderIf isTrue={paymentType === 'Cheque' || paymentType === 'Check'}>
                <Field name="number" label="Cheque Number" component={NumberInput} />
                <Field
                  component={DatePicker}
                  name="checkDate"
                  id="checkDate"
                  label="Date on Cheque"
                />
                <Field name="front" label="Front" component={Upload} />
                <Field name="back" label="Back" component={Upload} />
              </RenderIf>
              <Field name="comments" label="Comments" component={TextArea} />
            </Form>
          </TabPane>
        </Tab>
      </RenderIf>
    </Modal>
  );
};

export default ReceivePaymentForm;
