import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from 'routes';
import AppRoute from 'routes/route';
import './assets/scss/theme.scss';

const App = () => {
  return (
    <Router>
      <Switch>
        {publicRoutes.map((route, idx) => (
          <AppRoute
            path={route.path}
            component={route.component}
            key={idx}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            isAuthProtected
            {...route}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default App;
