import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import RenderIf from 'components/base-components/RenderIf';
import classnames from 'classnames';
import './styles.scss';

interface Props {
  danger: boolean;
  disabled: boolean;
  icon: ReactNode | string;
  key: string;
  label: string;
}

export const MenuItem: FunctionComponent<Props> = (props) => {
  const { children, icon: iconProp, label, ...rest } = props;

  const icon = useMemo(() => typeof iconProp === 'string' ? <i className={iconProp} /> : iconProp, [iconProp]);

  const content = useMemo(() => {
    if (children) {
      return children;
    }

    const labelClassName = classnames({ 'menu-item__label': !!icon });
    return (
      <>
        {icon}
        <RenderIf isTrue={!!label}>
          <span className={labelClassName}>{label}</span>
        </RenderIf>
      </>
    );
  }, [icon, label, children]);

  return (
    <Menu.Item {...rest}>
      {content}
    </Menu.Item>
  );
};
