/* eslint-disable */
import WallItem from './wall_item';

export default class InWallItem extends WallItem {
  constructor(model, metadata, meshList, position, rotation, options) {
    super(model, metadata, meshList, position, rotation, options);
    this.addToWall = true;
  }

  /** */
  getWallOffset() {
    // fudge factor so it saves to the right wall
    return -this.currentWallEdge.offset + 0.005;
  }
}
