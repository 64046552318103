/* eslint-disable */
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Core from "../core";
import Items from "../items";

// import { EventChannelList, notifyEventChannel } from '../../../../helpers/event-center';

export default class Scene {
  /** The associated ThreeJS scene. */
  scene;

  /** */
  items = [];

  /** */
  needsUpdate = false;

  /** The Json loader. */
  loader;

  /** */
  itemLoadingCallbacks = [];

  /** Item */
  itemLoadedCallbacks = [];

  /** Item */
  itemRemovedCallbacks = [];

  /**
   * Constructs a scene.
   * @param model The associated model.
   * @param textureDir The directory from which to load the textures.
   */
  constructor(model, textureDir) {
    this.model = model;
    this.textureDir = textureDir;
    this.scene = new THREE.Scene();

    this.GLTFLoader = new GLTFLoader();
    this.GLTFLoader.crossOrigin = "";
  }

  /** Adds a non-item, basically a mesh, to the scene.
   * @param mesh The mesh to be added.
   */
  add(mesh) {
    this.scene.add(mesh);
  }

  /** Removes a non-item, basically a mesh, from the scene.
   * @param mesh The mesh to be removed.
   */
  remove(mesh) {
    this.scene.remove(mesh);
    Core.Utils.removeValue(this.items, mesh);
  }

  /** Gets the scene.
   * @returns The scene.
   */
  getScene() {
    return this.scene;
  }

  /** Gets the items.
   * @returns The items.
   */
  getItems() {
    return this.items;
  }

  /** Gets the count of items.
   * @returns The count.
   */
  itemCount() {
    return this.items.length;
  }

  /** Removes all items. */
  clearItems() {
    // var items_copy = this.items;
    const scope = this;
    this.items.forEach((item) => {
      if (item.dimensionHelper) scope.remove(item.dimensionHelper);
      scope.removeItem(item, true);
    });
    this.items = [];
  }

  /**
   * Removes an item.
   * @param item The item to be removed.
   * @param dontRemove If not set, also remove the item from the items list.
   */
  removeItem(item, dontRemove) {
    dontRemove = dontRemove || false;
    // use this for item meshes
    // this.itemRemovedCallbacks.fire(item);
    this.itemRemovedCallbacks.forEach(
      (cb) => typeof cb === "function" && cb(item)
    );
    item.removed();
    this.scene.remove(item);
    if (!dontRemove) {
      Core.Utils.removeValue(this.items, item);
    }
  }

  /**
   * Creates an item and adds it to the scene.
   * @param itemType The type of the item given by an enumerator.
   * @param fileName The name of the file to load.
   * @param metadata TODO
   * @param position The initial position.
   * @param rotation The initial rotation around the y axis.
   * @param options The initial options.
   */
  async addItem(
    itemType,
    fileName,
    metadata,
    position,
    rotation,
    options,
    autoSelect = true
  ) {
    return new Promise((resolve) => {
      itemType = itemType || 1;
      const scope = this;
      // this.itemLoadingCallbacks.fire();
      this.itemLoadingCallbacks.forEach(
        (cb) => typeof cb === "function" && cb()
      );
      this.GLTFLoader.load(
        fileName,
        (gltf) => {
          const meshList = [];

          const morphHeight = {};
          const morphWidth = {};
          const morphDepth = {};

          gltf.scene.traverse((node) => {
            if (node.isMesh) {
              if (!node.name.includes("morph-")) {
                meshList.push(node);
              }

              if (node.name.includes("morph-height")) {
                const name = node.name.replace("-morph-height", "");
                const tmp = [];
                node.geometry.attributes.uv.array.forEach((v) => tmp.push(v));
                morphHeight[name] = tmp;
              } else if (node.name.includes("morph-width")) {
                const name = node.name.replace("-morph-width", "");
                const tmp = [];
                node.geometry.attributes.uv.array.forEach((v) => tmp.push(v));
                morphWidth[name] = tmp;
              } else if (node.name.includes("morph-depth")) {
                const name = node.name.replace("-morph-depth", "");
                const tmp = [];
                node.geometry.attributes.uv.array.forEach((v) => tmp.push(v));
                morphDepth[name] = tmp;
              }
            }
          });
          const morphUVs = [morphHeight, morphWidth, morphDepth];
          const item = new (Items.Factory.getClass(itemType))(
            scope.model,
            { ...metadata, morphUVs },
            meshList,
            position,
            rotation,
            options
          );

          item.initObject(meshList, position, rotation, options);

          scope.items.push(item);
          scope.add(item);
          console.log(item);
          // scope.itemLoadedCallbacks.fire(item);
          autoSelect &&
            scope.itemLoadedCallbacks.forEach(
              (cb) => typeof cb === "function" && cb(item)
            );
          // setTimeout(() => {
          //   notifyEventChannel(
          //     EventChannelList.BUILDER_LOADED_PRODUCT,
          //     metadata.id,
          //   );
          // }, 100);
          resolve(item);
        },
        undefined,
        () => {
          resolve(null);
        }
      );
    });
  }

  async importSetFromBuilder(metadata, items, position, rotation, options) {
    if (!Array.isArray(items) || !items.length) return;
    console.log("import set from builder");
    const itemType = 101;
    const scope = this;

    // this.itemLoadingCallbacks.fire();
    scope.itemLoadingCallbacks.forEach(
      (cb) => typeof cb === "function" && cb()
    );

    const setItem = new (Items.Factory.getClass(itemType))(
      scope.model,
      { ...metadata },
      [],
      position,
      rotation,
      options
    );
    for (const item of items) {
      let position = null;
      let rotation = null;
      const initialData = item.initialData;
      if (initialData) {
        options = initialData.options;
        rotation = initialData.rotation;
        position = new THREE.Vector3(
          initialData.position.x,
          initialData.position.y,
          initialData.position.z
        )
      }
      const model = await scope.addItem(
        item.type || item.item_type,
        item.threeModel || item.modelUrl || item.model_url || item.model,
        item.metadata,
        new THREE.Vector3()
          .copy(setItem.position)
          .add(new THREE.Vector3(item.xpos, item.ypos, item.zpos)),
        item?.initialData?.rotation,
        item.initialData?.options
      );

      setItem.addLinkedItem(model);
    }
    scope.items.push(setItem);
    scope.add(setItem);

    setItem.initObject();
    setItem.calculateHalfSize();
    setItem.dimensionHelper.update();
    // scope.itemLoadedCallbacks.fire(item);
    scope.itemLoadedCallbacks.forEach(
      (cb) => typeof cb === "function" && cb(setItem)
    );
  }
  /**
   * Create a set item
   * @param {Object} metadata
   * @param {Array} items
   */
  async addSet(metadata, items, position, rotation, options) {
    console.log("add set to scene", metadata, items);

    const itemType = 101;
    const scope = this;

    // this.itemLoadingCallbacks.fire();
    scope.itemLoadingCallbacks.forEach(
      (cb) => typeof cb === "function" && cb()
    );

    const setItem = new (Items.Factory.getClass(itemType))(
      scope.model,
      { ...metadata },
      [],
      position,
      rotation,
      options
    );

    for (var info of items) {
      const item = info.product;
      const offset = new THREE.Vector3(
        info.offset.x,
        info.offset.y,
        info.offset.z
      );

      const defaultMorph = {};
      if (Array.isArray(item.morph)) {
        for (var morph of item.morph) {
          defaultMorph[morph.index] = (morph.min - 5) / (300 - 5);
        }
      }

      const defaultStyles = {};
      if (Array.isArray(item.styles)) {
        for (var style of item.styles) {
          defaultStyles[style.name_in_model] = style.types[0].name_in_model;
        }
      }
      const metadata = {
        ...item,
        itemName: item.name,
        modelUrl: item.model,
        itemType: item.type,
      };
      const model = await scope.addItem(
        item.type,
        item.model,
        metadata,
        new THREE.Vector3().copy(setItem.position).add(offset),
        null,
        {
          styles: defaultStyles,
          morph: defaultMorph,
          stackable: item.stackable,
          stackontop: item.stackontop,
          overlappable: item.overlappable,
        }
      );

      setItem.addLinkedItem(model);
    }

    scope.items.push(setItem);
    scope.add(setItem);

    setItem.initObject();
    setItem.calculateHalfSize();
    setItem.dimensionHelper.update();

    console.log(setItem);
    // scope.itemLoadedCallbacks.fire(item);
    scope.itemLoadedCallbacks.forEach(
      (cb) => typeof cb === "function" && cb(setItem)
    );
  }

  collectOptionsFromItem = (item) => {
    // const defaultMorph = {}
    // if (Array.isArray(item.morph)) {
    //   for (var morph of item.morph) {
    //     defaultMorph[morph.index] = (morph.min - 5) / (300 - 5);
    //   }
    // }

    // const defaultStyles = {};
    // if (Array.isArray(item.styles)) {
    //   for (var style of item.styles) {
    //     defaultStyles[style.name_in_model] = style.types[0].name_in_model;
    //   }
    // }
    // return {
    //   morph: defaultMorph,
    //   styles: defaultStyles,
    // }

    const defaultStyles = {};
    const defaultMorph = {};
    const defaultTextures = {};

    // fetch default styles && textures
    for (const key in item.builderOptions) {
      // noinspection JSUnfilteredForInLoop
      const optionGroup = item.builderOptions[key];
      const shouldSetDefault =
        optionGroup.propertyType === "select" && optionGroup.options.length;

      if (shouldSetDefault) {
        defaultStyles[optionGroup.nameInModel] =
          optionGroup.options[0].nameInModel;
      }
    }

    Array.isArray(item.optionGroups) &&
      item.optionGroups.forEach((optionGroup) => {
        optionGroup.mode === "hide" &&
          optionGroup.options.length &&
          (defaultStyles[optionGroup.nameInModel] =
            optionGroup.options[0].nameInModel);

        optionGroup.mode === "texture" &&
          optionGroup.options.length &&
          (defaultTextures[optionGroup.nameInModel] = {
            material: {
              texture: optionGroup.options[0].image,
            },
            size: {
              w: optionGroup.options[0].textureWidth / 100,
              h: optionGroup.options[0].textureHeight / 100,
            },
          });
      });

    // fetch default morph
    (() => {
      const shouldSetMinHeight =
        item.builderOptions &&
        item.builderOptions.height &&
        item.builderOptions.height.minValue;
      const shouldSetMinWidth =
        item.builderOptions &&
        item.builderOptions.width &&
        item.builderOptions.width.minValue;
      const shouldSetMinDepth =
        item.builderOptions &&
        item.builderOptions.depth &&
        item.builderOptions.depth.minValue;

      if (shouldSetMinHeight) {
        defaultMorph[0] = item.builderOptions.height.minValue;
        if (item.modelDescription && item.modelDescription.height)
          defaultMorph[0] = item.modelDescription.height;
      }
      if (shouldSetMinWidth) {
        defaultMorph[1] = item.builderOptions.width.minValue;
        if (item.modelDescription && item.modelDescription.width)
          defaultMorph[1] = item.modelDescription.width;
      }
      if (shouldSetMinDepth) {
        defaultMorph[2] = item.builderOptions.depth.minValue;
        if (item.modelDescription && item.modelDescription.depth)
          defaultMorph[2] = item.modelDescription.depth;
      }

      for (const i in defaultMorph) {
        defaultMorph[i] = (defaultMorph[i] - 5) / 295;
      }
    })();

    return {
      morph: defaultMorph,
      styles: defaultStyles,
      textures: defaultTextures,
    };
  };
}
