import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import { setFormValue, createQuote, setValidationErrors } from './actions';
import createQuoteFormReducer from './reducer';

export interface CreateQuoteHistorySateModel {
  createQuoteForm: {
    project: string;
  };
}

const initialState = {
  formData: {
    comments: ''
  },
  errors: {},
  creating: false,
};

export default function useCreateQuoteState(historyState) {
  const { createQuoteForm: { project } } = historyState as CreateQuoteHistorySateModel;
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    createQuoteFormReducer,
    initialState,
  );
  const { formData } = state;
  return {
    state: {
      ...state
    },
    actions: {
      handleValueChange: useCallback(setFormValue(dispatch), []),
      setValidationErrors: useCallback(setValidationErrors(dispatch), []),
      handleOnSubmit: useCallback(createQuote(dispatch, formData, project, goBack), [formData, project]),
    },
  };
}
