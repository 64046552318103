import snakeCaseKeys from 'snakecase-keys';
import { FlowPlannerModel } from 'models/flow-planner';
import { ApiContentType, ApiModel, barePatch } from './base';
import getApi from './base/get-api';

const { REACT_APP_FLOWPLANNER_API } = process.env;

const endpoint = `${REACT_APP_FLOWPLANNER_API}/flowplanner/`;

const flowPlannerApi: ApiModel<FlowPlannerModel> = {
  ...getApi<FlowPlannerModel>(endpoint, ApiContentType.JSON, true),
  patch(payload) {
    const body = snakeCaseKeys(payload, { deep: false }) as any;
    return barePatch(`${endpoint}${body.id}/`, body);
  },
};

export default flowPlannerApi;
