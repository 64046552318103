import React, { FunctionComponent } from 'react';
import { Text } from 'components/base-components/Typography';
import HelpModal from 'components/experience/HelpModal';

const Help: FunctionComponent = () => {
  return (
    <HelpModal title="Account Form" name="account-form">
      <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse
        cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat non proident,
        sunt in culpa qui officia deserunt mollit anim id est laborum.
      </Text>
    </HelpModal>
  );
};

export default Help;
