import { resolveApiErrorMessage } from 'api/base';
import filesViewerApi from 'api/file-viewer';
import notification, { NotificationType } from 'helpers/notification';
import { AssignFilesFormActions } from '../reducer';

export function updateAssignedFiles(dispatch, files, quoteId, goBack) {
  return () => {
    dispatch({ type: AssignFilesFormActions.START_UPDATING });

    const onSuccess = () => {
      const message = 'Assigned Files are Successfully Updated';
      notification({ type: NotificationType.SUCCESS, message });
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: AssignFilesFormActions.FINISH_UPDATING });
    };

    return filesViewerApi.processChanges(quoteId, { files })
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
