import { SubOption } from 'models/option';
import { resolveApiErrorMessage } from 'api/base';
import subOptionApi from 'api/sub-option';
import { validateEntity } from 'helpers/form-validations';
import notification, { NotificationType } from 'helpers/notification';
import { SubOptionFormActions, SubOptionFormModel } from '../reducer';
import { validationRules } from '../validation-rules';

export default function onSave(dispatch, formData: SubOptionFormModel, oldData: SubOption[]) {
  return () => {
    dispatch({ type: SubOptionFormActions.START_SAVING });

    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: SubOptionFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    const onSuccess = ({ data }) => {
      const message = 'SubOption Successfully Saved';
      notification({ type: NotificationType.SUCCESS, message });

      const payload = formData.url
        ? oldData.map(subOpt => formData.url === subOpt.url ? data : subOpt)
        : [data, ...oldData];

      dispatch({ type: SubOptionFormActions.SET_DATA, payload });
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: SubOptionFormActions.FINISH_SAVING });
    };

    const action = !!formData.url ? subOptionApi.update : subOptionApi.create;

    return action({ ...formData, brand: formData.brand?.value })
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
