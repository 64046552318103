import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import rateApi from 'api/rate';
import categoriesApi from 'api/category';
import useGetList from 'hooks/base/get-list';
import useDebounce from 'hooks/ui/use-debounce';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import useCallApiAction from 'hooks/base/call-api-action';
import notification, { NotificationType } from 'helpers/notification';
import generateProductsFormReducer, { GenerateProductsActions } from './reducer';
import onFilter from './actions/handle-on-filter';
import setFormValue from './actions/set-form-data';
import setValidationErrors from './actions/set-validation-errors';
import addRate from './actions/add-rate';
import { validationGeneratingRules } from './validation-rules';

const getFormData = () => ({ category: undefined, optionsUpdate: false });
const getPayload = (data) => ({ ...data, category: data.category?.value });
const formStateParams: UseFormStateParams = {
  api: categoriesApi,
  getFormData,
  getPayload,
  replaceAction: categoriesApi.generate,
  validationRules: validationGeneratingRules,
  noGoBackOnSuccess: true,
};

export default function useGenerateProducts() {
  const [filter, setFilter] = useState('');
  const [state, dispatch] = useReducer(generateProductsFormReducer, {
    rates: [],
    adding: false,
    errors: undefined,
    rateForm: {
      name: '',
      value: '0.00',
    },
  });
  const { rateForm } = state;
  const { debounceCall } = useDebounce(500);

  const {
    data,
    fetching,
    error,
    updateDataManually,
  } = useGetList(rateApi);

  const {
    state: {
      formData: generatingForm,
      errors: generatingError,
      saving: generating,
    },
    actions: {
      setFormValue: setGeneratingFormValue,
      setErrors: setGeneratingErrors,
      saveEntity: generate,
    }
  } = useFormState(formStateParams);

  const {
    fetching: fetchingCategories,
    data: { data: categories },
    error: fetchingCategoriesErrors,
  } = useCallApiAction(categoriesApi.getTopLevelList, '', { data: [] });

  const handleFilter = useCallback((event) => {
    setFilter(event.target.value);
    if (event.persist) {
      event.persist();
    }
    debounceCall(() => onFilter(dispatch, data, event.target.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      notification({ type: NotificationType.ERROR, message: error });
    }
  }, [error]);

  useEffect(() => {
    if (fetchingCategoriesErrors) {
      notification({ type: NotificationType.ERROR, message: fetchingCategoriesErrors });
    }
  }, [fetchingCategoriesErrors]);

  useEffect(() => {
    if (data.length > 0) {
      dispatch({
        type: GenerateProductsActions.SET_DATA,
        payload: data,
      });
    }
  }, [data]);

  return {
    state: {
      ...state,
      generatingForm,
      generatingError,
      generating,
      fetching,
      filter,
      fetchingCategories,
      categories: useMemo(() => categories.map(cat => ({ value: cat, label: cat })), [categories])
    },
    actions: {
      setGeneratingFormValue,
      setGeneratingErrors,
      handleFilter,
      generate,
      setRateFormValue: useCallback(setFormValue(dispatch), []),
      setRateValidationErrors: useCallback(setValidationErrors(dispatch), []),
      addRate: useCallback(addRate(rateForm, data, updateDataManually, setFilter, dispatch), [rateForm, data]),
    }
  };
}
