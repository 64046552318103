import React, { FunctionComponent, useCallback, useState } from 'react';
import { Row } from 'antd';
import { Paragraph } from 'components/base-components/Typography';
import Button from 'components/base-components/Button';
import PopConfirm from 'components/base-components/PopConfirm';
import Divider from 'components/base-components/Divider';
import { NotesFormModel } from './state/reducer';
import './styles.scss';

interface Props {
  data: NotesFormModel;
  handleOnDelete: (setDeleting, note: NotesFormModel) => any;
}

const NoteItem: FunctionComponent<Props> = (props) => {
  const { handleOnDelete, data } = props;
  const [deleting, setDeleting] = useState(false);
  const onDelete = useCallback(() => handleOnDelete(setDeleting, data), [handleOnDelete, data]);

  return (
    <dd>
      <Row justify="space-between">
        <Paragraph className="notes-item__text">{data.text}</Paragraph>
        <PopConfirm title="Sure to delete?" onConfirm={onDelete}>
          <Button size="small" danger loading={deleting} icon="fa fa-trash" />
        </PopConfirm>
      </Row>
      <Divider className="notes-item__divider" />
    </dd>
  );
};

export default NoteItem;
