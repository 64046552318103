import { FileViewerFormActions } from '../reducer';

export default function openDirectory(actualParams: string, dispatch) {
  const separator = actualParams ? '/' : '?directory=';

  return (value) => {
    const params = `${actualParams}${separator}${value}`;
    dispatch({ type: FileViewerFormActions.SET_PARAMS, payload: params });
  };
}
