import React, { FunctionComponent } from 'react';
import paymentTermsApi from 'api/term';
import Field from 'components/base-components/Form/Field';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import { mapPaymentTermsOptionsByQuick } from './state';

const QuickForm: FunctionComponent = () => {
  return (
    <>
      <Field
        id="companyName"
        name="companyName"
        label="Account Name"
        placeholder="Enter a account name"
        required
      />
      <Field
        id="firstName"
        name="firstName"
        label="First Name"
        placeholder="Enter First Name"
        required
      />
      <Field
        id="lastName"
        name="lastName"
        label="Last Name"
        placeholder="Enter Last Name"
        required
      />
      <Field
        id="email"
        name="email"
        label="Email"
        placeholder="Enter Email"
        required
      />
      <Field
        component={AsyncSelectV2}
        name="paymentTerms"
        id="paymentTerms"
        label="Payment Term"
        listAction={paymentTermsApi.list}
        mapFunction={mapPaymentTermsOptionsByQuick}
        required
      />
    </>
  );
};

export default QuickForm;
