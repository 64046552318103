import React from 'react';

export default function modelTemplate(row) {
  if (typeof row.id === 'string' && row.id.includes('group')) {
    return {
      children: row.group,
      props: {
        colSpan: row.group ? 4 : 0,
      },
    };
  }

  const options = row.options.map((option) => (
    <li key={`${option.optionType}-${row.id}`}>
      {`${option.optionType}: ${option.optionSelected}`}
    </li>
  ));

  return (
    <>
      <span className="text-muted">{row.model}</span>
      <ul>
        {options}
      </ul>
    </>
  );
}
