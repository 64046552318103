import { ShipmentContactModel } from 'models/shipment';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { AnswersModel } from '../types';

export enum QuestionnaireActions {
  INIT_FORM = 'INIT_FORM',
  SET_CONTACT_INFO = 'SET_CONTACT_INFO',
  SET_ADDRESS_INFO = 'SET_ADDRESS_INFO',
  SET_ANSWERS = 'SET_ANSWERS',
  SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED',
  SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY',
  START_SENDING_ANSWERS = 'START_SENDING_ANSWERS',
  STOP_SENDING_ANSWERS = 'STOP_SENDING_ANSWERS',
  SET_ANSWERS_SENT = 'SET_ANSWERS_SENT',
  SET_CONTACT_ERRORS = 'SET_CONTACT_ERRORS',
  SET_ADDRESS_ERRORS = 'SET_ADDRESS_ERRORS',
  SET_ANSWER_ERRORS = 'SET_ANSWER_ERRORS',
}

export interface FormAddressModel {
  id: number;
  locationName: string;
  streetNumber: number;
  streetName: string;
  unitNumber: number;
  postalCode: string;
  city: string;
  province: DropdownOptionModel;
}

export interface QuestionnaireState {
  contactInfo: ShipmentContactModel;
  contactErrors: any;
  addressInfo: FormAddressModel;
  addressErrors: any;
  answers: AnswersModel;
  answersErrors: any;
  selectedCountry: DropdownOptionModel;
  editedContactInfo: boolean;
  editedAddressInfo: boolean;
  termsAccepted: boolean;
  loadingFormData: boolean;
  sendingAnswers: boolean;
  answersSent: boolean;
  hasValidationErrors: boolean;
}

export default function questionnaireReducer(
  state: QuestionnaireState,
  action,
): QuestionnaireState {
  const { type, payload } = action;

  switch (type as QuestionnaireActions) {
    case QuestionnaireActions.INIT_FORM:
      return { ...state, ...payload, loadingFormData: false };
    case QuestionnaireActions.SET_CONTACT_INFO:
      return { ...state, contactInfo: payload, editedContactInfo: true };
    case QuestionnaireActions.SET_ADDRESS_INFO:
      return { ...state, addressInfo: payload, editedAddressInfo: true };
    case QuestionnaireActions.SET_ANSWERS:
      return { ...state, answers: payload };
    case QuestionnaireActions.SET_TERMS_ACCEPTED:
      return { ...state, termsAccepted: payload };
    case QuestionnaireActions.SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: payload,
      };
    case QuestionnaireActions.START_SENDING_ANSWERS:
      return { ...state, sendingAnswers: true };
    case QuestionnaireActions.STOP_SENDING_ANSWERS:
      return { ...state, sendingAnswers: false };
    case QuestionnaireActions.SET_ANSWERS_SENT:
      return {
        ...state,
        sendingAnswers: false,
        answersSent: true,
        hasValidationErrors: false,
      };
    case QuestionnaireActions.SET_CONTACT_ERRORS:
      return {
        ...state,
        contactErrors: payload,
        sendingAnswers: false,
        hasValidationErrors: true,
      };
    case QuestionnaireActions.SET_ADDRESS_ERRORS:
      return {
        ...state,
        addressErrors: payload,
        sendingAnswers: false,
        hasValidationErrors: true,
      };
    case QuestionnaireActions.SET_ANSWER_ERRORS:
      return {
        ...state,
        answersErrors: payload,
        sendingAnswers: false,
        hasValidationErrors: true,
      };
    default:
      return state;
  }
}
