import { AccountTaxModel } from 'models/account';
import { ExtraChargeModel } from 'models/extra-charge';

export function calculateTax(shippingPrice, orderTotal, taxPercentage) {
  return (shippingPrice + orderTotal) * taxPercentage;
}

export function calculateSubTotal(orderTotal, extraCharges: ExtraChargeModel[]) {
  const totalExtraCharges = extraCharges && extraCharges.length > 0
    ? extraCharges.reduce((total, extraCharge) => total + parseFloat(extraCharge.amount), 0)
    : 0;
  return orderTotal + totalExtraCharges;
}

export function calculateTotal(taxes: AccountTaxModel[], shippingPrice, orderTotal, extraCharges: ExtraChargeModel[]) {
  const totalExtraCharges = extraCharges && extraCharges.length > 0
    ? extraCharges.reduce((total, extraCharge) => total + parseFloat(extraCharge.amount), 0)
    : 0;
  const totalTax = taxes && taxes.length > 0
    ? taxes.reduce((total, tax) => total + calculateTax(shippingPrice, orderTotal, tax.taxPercentage), 0)
    : 0;
  return orderTotal + shippingPrice + totalTax + totalExtraCharges;
}
