export enum NotesFormActions {
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  SET_DATA = 'SET_DATA',
  UPDATE_DATA = 'UPDATE_DATA',
  SET_FORM_VALUE = 'SET_FORM_VALUE',
  START_SAVING = 'START_SAVING',
  FINISH_SAVING = 'FINISH_SAVING',
}

export interface NotesFormModel {
  text: string;
  id?: number;
}

interface NotesFormState {
  formData: NotesFormModel;
  notes: NotesFormModel[];
  saving: boolean;
  errors: any;
}

export default function notesFormReducer(
  state: NotesFormState,
  action
): NotesFormState {
  const { type, payload } = action;

  switch (type as NotesFormActions) {
    case NotesFormActions.SET_FORM_VALUE:
      return { ...state, formData: payload };
    case NotesFormActions.SET_VALIDATION_ERRORS:
      return { ...state, errors: payload, saving: false };
    case NotesFormActions.SET_DATA:
      return { ...state, notes: payload };
    case NotesFormActions.UPDATE_DATA:
      return { ...state, notes: state.notes.concat(payload) };
    case NotesFormActions.START_SAVING:
      return { ...state, saving: true };
    case NotesFormActions.FINISH_SAVING:
      return { ...state, saving: false };
    default:
      return state;
  }
}
