import { AxiosResponse } from 'axios';
import { resolveApiErrorMessage } from 'api/base';
import rateApi from 'api/rate';
import { validateEntity } from 'helpers/form-validations';
import notification, { NotificationType } from 'helpers/notification';
import { Rate, GenerateProductsActions } from '../reducer';
import { validationRateRules } from '../validation-rules';

export default function addRate(formData: Rate, currentRates: Rate[], updateManually, setFilters, dispatch) {
  return () => {
    dispatch({ type: GenerateProductsActions.START_ADDING });

    const { hasErrors, errors } = validateEntity(formData, validationRateRules);

    if (hasErrors) {
      return dispatch({
        type: GenerateProductsActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    const onSuccess = (response: AxiosResponse<Rate>) => {
      const { data } = response;
      const message = 'Rate Successfully Created';
      notification({ type: NotificationType.SUCCESS, message });
      updateManually([data, ...currentRates]);
      dispatch({ type: GenerateProductsActions.SET_FORM_DATA, payload: { name: '', value: '0.00' } });
      setFilters('');
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: GenerateProductsActions.FINISH_ADDING });
    };

    return rateApi.create(formData)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
