import { DropdownOptionModel } from 'helpers/dropdown-options';
import uniqueId from 'helpers/unique-id';

export enum ThreeFormActions {
  SET_MODEL = 'SET_MODEL',
  SET_ERRORS = 'SET_ERRORS',
  START_SAVING_MODELS = 'START_SAVING_MODELS',
  FINISH_SAVING_MODELS = 'FINISH_SAVING_MODELS',
  ADD_MORPH = 'ADD_MORPH',
  CHANGE_MORPH = 'CHANGE_MORPH',
  REMOVE_MORPH = 'REMOVE_MORPH',
}

export interface ThreeMorphFormModel {
  id?: number;
  morphLabel: string;
  morphIndex: number;
  property: DropdownOptionModel;
}

export interface ThreeFormModel {
  id?: number;
  url?: string;
  threeModel: File[];
  modelName: string;
  modelPosition: DropdownOptionModel;
  morphs: {[index: string]: ThreeMorphFormModel };
  threeFileChanged: boolean;
  initialMorphs: number[];
}

export interface ThreeFormState {
  threeModel: ThreeFormModel;
  fetchingFormData: boolean;
  savingModel: boolean;
  errors: any;
}

export default function threeModelFormReducer(
  state: ThreeFormState,
  action,
): ThreeFormState {
  const { type, payload } = action;

  switch (type as ThreeFormActions) {
    case ThreeFormActions.SET_MODEL:
      return { ...state, threeModel: payload, fetchingFormData: false };
    case ThreeFormActions.SET_ERRORS:
      return { ...state, errors: payload, savingModel: false };
    case ThreeFormActions.START_SAVING_MODELS:
      return { ...state, savingModel: true };
    case ThreeFormActions.FINISH_SAVING_MODELS:
      return { ...state, savingModel: false };
    case ThreeFormActions.ADD_MORPH:
      return {
        ...state,
        threeModel: {
          ...state.threeModel,
          morphs: {
            ...state.threeModel.morphs,
            [uniqueId('empty-morph')]: {} as any,
          },
        },
      };
    case ThreeFormActions.CHANGE_MORPH: {
      const { index, morph } = payload;

      return {
        ...state,
        threeModel: {
          ...state.threeModel,
          morphs: {
            ...state.threeModel.morphs,
            [index]: morph,
          },
        },
      };
    }
    case ThreeFormActions.REMOVE_MORPH: {
      const { threeModel: { morphs } } = state;
      const reducedMorphs = Object.keys(morphs)
        .reduce((acc, morph) => {
          if (morph === payload) {
            return acc;
          }
          return { ...acc, [morph]: morphs[morph] };
        }, {});

      return {
        ...state,
        threeModel: {
          ...state.threeModel,
          morphs: reducedMorphs,
        },
      };
    }
    default:
      return state;
  }
}
