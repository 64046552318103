export const DESK = {
  url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/products/DESK-RCR-36-36-36-COG-12M/',
  model: 'DESK-RCR-36-36-36-COG-12M',
  category: 'DESK-RCR-12-COG',
  overlapable: false,
  stackable: false,
  stackontop: true,
  modelDescription: {
    partType: 'DESK',
    style: 'RCR',
    height: '36',
    depth: '36',
    width: '36',
    grommet: 'COG',
    modesty: '12M'
  },
  builderOptions: {
    partType: {
      propertyType: 'product_range',
      partType: [
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/BC/',
          name: 'BC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/BR/',
          name: 'BR',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/BS/',
          name: 'BS',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/BSC/',
          name: 'BSC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/CB/',
          name: 'CB',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/CC/',
          name: 'CC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK/',
          name: 'DESK',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/FILING/',
          name: 'FILING',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/GBL/',
          name: 'GBL',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH/',
          name: 'HUTCH',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/KIT/',
          name: 'KIT',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/LBC/',
          name: 'LBC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/LST/',
          name: 'LST',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/LTB/',
          name: 'LTB',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/ML/',
          name: 'ML',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/MOD/',
          name: 'MOD',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/PDESK/',
          name: 'PDESK',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/PED/',
          name: 'PED',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/PST/',
          name: 'PST',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/RTN/',
          name: 'RTN',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/SC/',
          name: 'SC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/SSC/',
          name: 'SSC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/TB/',
          name: 'TB',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/TT/',
          name: 'TT',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/WS/',
          name: 'WS',
          image: null
        }
      ],
      morphIndex: null,
      morph_label: null
    },
    style: {
      propertyType: 'style',
      styles: [
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-BF/',
          name: 'DESK-BF',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-ECUL24C24E/',
          name: 'DESK-ECUL24C24E',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-ECUL24C30E/',
          name: 'DESK-ECUL24C30E',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-ECUL30C24E/',
          name: 'DESK-ECUL30C24E',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-ECUL30C30E/',
          name: 'DESK-ECUL30C30E',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-ECUR24C24E/',
          name: 'DESK-ECUR24C24E',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-ECUR24C30E/',
          name: 'DESK-ECUR24C30E',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-ECUR30C24E/',
          name: 'DESK-ECUR30C24E',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-ECUR30C30E/',
          name: 'DESK-ECUR30C30E',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-FMB/',
          name: 'DESK-FMB',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-FMR/',
          name: 'DESK-FMR',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-RCL/',
          name: 'DESK-RCL',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-RCR/',
          name: 'DESK-RCR',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-RFF/',
          name: 'DESK-RFF',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-RFFLHG/',
          name: 'DESK-RFFLHG',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-RFFLRHG/',
          name: 'DESK-RFFLRHG',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-RFFRHG/',
          name: 'DESK-RFFRHG',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-RRF/',
          name: 'DESK-RRF',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-WVL/',
          name: 'DESK-WVL',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK-WVR/',
          name: 'DESK-WVR',
          image: null
        }
      ],
      morphIndex: null,
      morph_label: null
    },
    height: {
      propertyType: 'range',
      min_value: 36,
      max_value: 36,
      increment: 1,
      morphIndex: 0,
      morph_label: 'height'
    },
    depth: {
      propertyType: 'range',
      min_value: 36,
      max_value: 60,
      increment: 1,
      morphIndex: 2,
      morph_label: 'depth'
    },
    width: {
      propertyType: 'range',
      min_value: 36,
      max_value: 60,
      increment: 1,
      morphIndex: 1,
      morph_label: 'width'
    },
    grommet: {
      propertyType: 'select',
      name_in_model: 'grommet',
      options: [
        {
          name: 'COG',
          name_in_model: 'grommet-cog'
        },
        {
          name: 'NG',
          name_in_model: 'grommet-ng'
        }
      ],
      values: [
        'COG',
        'NG'
      ],
      morphIndex: null,
      morph_label: null
    },
    modesty: {
      propertyType: 'select',
      name_in_model: 'modesty',
      options: [
        {
          name: '12M',
          name_in_model: 'modesty-12m'
        },
        {
          name: '18M',
          name_in_model: 'modesty-18m'
        },
        {
          name: '28M',
          name_in_model: 'modesty-28m'
        }
      ],
      values: [
        '12M',
        '18M',
        '28M'
      ],
      morphIndex: null,
      morph_label: null
    }
  },
  threeModel: 'https://tfg-erp-static-develop.s3.amazonaws.com/static/products-api-develop/3d/DESK-RCR.glb',
  textureMapping: '[]',
  position: {
    index: 1,
    description: 'FloorItem'
  },
  optionGroups: [
    {
      name: 'Desk Gable',
      name_in_model: 'desk-gable',
      mode: 'texture',
      options: [
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:49:27.454842Z',
          name: 'Aged Oak',
          name_fr: '',
          code: 'W163',
          description: 'Low Presure Laminate',
          description_fr: '',
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W163.jpg',
          texture_height: 150,
          texture_width: 75,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Ash',
          name_fr: null,
          code: 'W150',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W150-Absolute-Acajou-.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Black',
          name_fr: null,
          code: 'S405',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/Black.png',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:05:11.507466Z',
          name: 'Caramel',
          name_fr: null,
          code: 'W200',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W200.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Cherry',
          name_fr: null,
          code: 'W144',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w144-WILD-CHERRY.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Chocolate',
          name_fr: null,
          code: 'W292',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w292-Chocolate.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Fog Grey',
          name_fr: null,
          code: 'S551',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/S551-FOG_GREY.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Geneva',
          name_fr: null,
          code: 'AA60',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/PFA60-NEGOTIATING-IN-GENEVA.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:37.673774Z',
          name: 'Mahogany',
          name_fr: null,
          code: 'W365',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W365.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Maple',
          name_fr: null,
          code: 'W256',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:35.425409Z',
          name: 'Tuxedo',
          name_fr: null,
          code: 'W155',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W155.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Walnut',
          name_fr: null,
          code: 'W393',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W393-CORPORATE-WALNUT.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White',
          name_fr: null,
          code: 'S645',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White Chocolate',
          name_fr: null,
          code: 'W221',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w221-white_chocolate.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        }
      ],
      defaultOption: 'White',
      optionMargin: '3.00',
      optionUnit: 'square foot'
    },
    {
      name: 'Desk Modesty',
      name_in_model: 'desk-modesty',
      mode: 'texture',
      options: [
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:49:27.454842Z',
          name: 'Aged Oak',
          name_fr: '',
          code: 'W163',
          description: 'Low Presure Laminate',
          description_fr: '',
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W163.jpg',
          texture_height: 150,
          texture_width: 75,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Ash',
          name_fr: null,
          code: 'W150',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W150-Absolute-Acajou-.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Black',
          name_fr: null,
          code: 'S405',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/Black.png',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:05:11.507466Z',
          name: 'Caramel',
          name_fr: null,
          code: 'W200',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W200.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Cherry',
          name_fr: null,
          code: 'W144',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w144-WILD-CHERRY.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Chocolate',
          name_fr: null,
          code: 'W292',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w292-Chocolate.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Fog Grey',
          name_fr: null,
          code: 'S551',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/S551-FOG_GREY.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Geneva',
          name_fr: null,
          code: 'AA60',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/PFA60-NEGOTIATING-IN-GENEVA.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:37.673774Z',
          name: 'Mahogany',
          name_fr: null,
          code: 'W365',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W365.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Maple',
          name_fr: null,
          code: 'W256',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:35.425409Z',
          name: 'Tuxedo',
          name_fr: null,
          code: 'W155',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W155.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Walnut',
          name_fr: null,
          code: 'W393',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W393-CORPORATE-WALNUT.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White',
          name_fr: null,
          code: 'S645',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White Chocolate',
          name_fr: null,
          code: 'W221',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w221-white_chocolate.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        }
      ],
      defaultOption: 'White',
      optionMargin: '3.00',
      optionUnit: 'square foot'
    },
    {
      name: 'Desk Top',
      name_in_model: 'desk-top',
      mode: 'texture',
      options: [
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:49:27.454842Z',
          name: 'Aged Oak',
          name_fr: '',
          code: 'W163',
          description: 'Low Presure Laminate',
          description_fr: '',
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W163.jpg',
          texture_height: 150,
          texture_width: 75,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Ash',
          name_fr: null,
          code: 'W150',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W150-Absolute-Acajou-.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Black',
          name_fr: null,
          code: 'S405',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/Black.png',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:05:11.507466Z',
          name: 'Caramel',
          name_fr: null,
          code: 'W200',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W200.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Cherry',
          name_fr: null,
          code: 'W144',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w144-WILD-CHERRY.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Chocolate',
          name_fr: null,
          code: 'W292',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w292-Chocolate.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Fog Grey',
          name_fr: null,
          code: 'S551',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/S551-FOG_GREY.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Geneva',
          name_fr: null,
          code: 'AA60',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/PFA60-NEGOTIATING-IN-GENEVA.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'HPL High Gloss',
          name_fr: null,
          code: 'HPL-HG',
          description: 'High Presure Laminate',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 15,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'HPL Matte',
          name_fr: null,
          code: 'HPL-MT',
          description: 'High Presure Laminate',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 10,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:37.673774Z',
          name: 'Mahogany',
          name_fr: null,
          code: 'W365',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W365.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Maple',
          name_fr: null,
          code: 'W256',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:35.425409Z',
          name: 'Tuxedo',
          name_fr: null,
          code: 'W155',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W155.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Walnut',
          name_fr: null,
          code: 'W393',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W393-CORPORATE-WALNUT.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White',
          name_fr: null,
          code: 'S645',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White Chocolate',
          name_fr: null,
          code: 'W221',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w221-white_chocolate.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        }
      ],
      defaultOption: 'White',
      optionMargin: '3.00',
      optionUnit: 'square foot'
    }
  ],
  levelProperties: [
    'part_type',
    'style',
    'height',
    'depth',
    'width',
    'grommet',
    'modesty'
  ]
};

export const HUTCH = {
  url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/products/HUTCH-DM-2HD-GD-37-15-36/',
  model: 'HUTCH-DM-2HD-GD-37-15-36',
  category: 'HUTCH-DM-2HD',
  overlapable: false,
  stackable: false,
  stackontop: true,
  modelDescription: {
    partType: 'HUTCH',
    group: 'DM',
    style: '2HD',
    door: 'GD',
    height: '37',
    depth: '15',
    width: '36'
  },
  builderOptions: {
    partType: {
      propertyType: 'product_range',
      partType: [
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/BC/',
          name: 'BC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/BR/',
          name: 'BR',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/BS/',
          name: 'BS',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/BSC/',
          name: 'BSC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/CB/',
          name: 'CB',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/CC/',
          name: 'CC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/DESK/',
          name: 'DESK',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/FILING/',
          name: 'FILING',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/GBL/',
          name: 'GBL',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH/',
          name: 'HUTCH',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/KIT/',
          name: 'KIT',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/LBC/',
          name: 'LBC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/LST/',
          name: 'LST',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/LTB/',
          name: 'LTB',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/ML/',
          name: 'ML',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/MOD/',
          name: 'MOD',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/PDESK/',
          name: 'PDESK',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/PED/',
          name: 'PED',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/PST/',
          name: 'PST',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/RTN/',
          name: 'RTN',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/SC/',
          name: 'SC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/SSC/',
          name: 'SSC',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/TB/',
          name: 'TB',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/TT/',
          name: 'TT',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/WS/',
          name: 'WS',
          image: null
        }
      ],
      morphIndex: null,
      morph_label: null
    },
    group: {
      propertyType: 'group',
      morphIndex: null,
      morph_label: null
    },
    style: {
      propertyType: 'style',
      styles: [
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH-DM-2HD/',
          name: 'HUTCH-DM-2HD',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH-DM-2HDOL/',
          name: 'HUTCH-DM-2HDOL',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH-DM-2HDOR/',
          name: 'HUTCH-DM-2HDOR',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH-DM-2SO/',
          name: 'HUTCH-DM-2SO',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH-DM-3HD/',
          name: 'HUTCH-DM-3HD',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH-DM-3SO/',
          name: 'HUTCH-DM-3SO',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH-DM-4HD/',
          name: 'HUTCH-DM-4HD',
          image: null
        },
        {
          url: 'https://7miarhw0s6.execute-api.ca-central-1.amazonaws.com/develop/category/HUTCH-DM-4SO/',
          name: 'HUTCH-DM-4SO',
          image: null
        }
      ],
      morphIndex: null,
      morph_label: null
    },
    door: {
      propertyType: 'select',
      name_in_model: 'door',
      options: [
        {
          name: 'GD',
          name_in_model: 'door-gd'
        },
        {
          name: 'PD',
          name_in_model: 'door-pd'
        }
      ],
      values: [
        'GD',
        'PD'
      ],
      morphIndex: null,
      morph_label: null
    },
    height: {
      propertyType: 'range',
      min_value: 37,
      max_value: 43,
      increment: 1,
      morphIndex: 0,
      morph_label: 'height'
    },
    depth: {
      propertyType: 'range',
      min_value: 15,
      max_value: 15,
      increment: 1,
      morphIndex: 2,
      morph_label: 'depth'
    },
    width: {
      propertyType: 'range',
      min_value: 36,
      max_value: 42,
      increment: 1,
      morphIndex: 1,
      morph_label: 'width'
    }
  },
  threeModel: 'https://tfg-erp-static-develop.s3.amazonaws.com/static/products-api-develop/3d/HUTCH-DM-2HD.glb',
  textureMapping: '[]',
  position: {
    index: 1,
    description: 'FloorItem'
  },
  optionGroups: [
    {
      name: 'Cabinet Body',
      name_in_model: 'cabinet-body',
      mode: 'texture',
      options: [
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:49:27.454842Z',
          name: 'Aged Oak',
          name_fr: '',
          code: 'W163',
          description: 'Low Presure Laminate',
          description_fr: '',
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W163.jpg',
          texture_height: 150,
          texture_width: 75,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Ash',
          name_fr: null,
          code: 'W150',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W150-Absolute-Acajou-.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Black',
          name_fr: null,
          code: 'S405',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/Black.png',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:05:11.507466Z',
          name: 'Caramel',
          name_fr: null,
          code: 'W200',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W200.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Cherry',
          name_fr: null,
          code: 'W144',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w144-WILD-CHERRY.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Chocolate',
          name_fr: null,
          code: 'W292',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w292-Chocolate.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Fog Grey',
          name_fr: null,
          code: 'S551',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/S551-FOG_GREY.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Geneva',
          name_fr: null,
          code: 'AA60',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/PFA60-NEGOTIATING-IN-GENEVA.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:37.673774Z',
          name: 'Mahogany',
          name_fr: null,
          code: 'W365',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W365.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Maple',
          name_fr: null,
          code: 'W256',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:35.425409Z',
          name: 'Tuxedo',
          name_fr: null,
          code: 'W155',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W155.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Walnut',
          name_fr: null,
          code: 'W393',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W393-CORPORATE-WALNUT.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White',
          name_fr: null,
          code: 'S645',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White Chocolate',
          name_fr: null,
          code: 'W221',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w221-white_chocolate.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        }
      ],
      defaultOption: 'White',
      optionMargin: '3.00',
      optionUnit: 'square foot'
    },
    {
      name: 'Cabinet Face',
      name_in_model: 'cabinet-face',
      mode: 'texture',
      options: [
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:49:27.454842Z',
          name: 'Aged Oak',
          name_fr: '',
          code: 'W163',
          description: 'Low Presure Laminate',
          description_fr: '',
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W163.jpg',
          texture_height: 150,
          texture_width: 75,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Amazon',
          name_fr: null,
          code: 'HG45',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Anthracite',
          name_fr: null,
          code: 'HG32',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Antique Bronze',
          name_fr: null,
          code: 'YO6',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Ash',
          name_fr: null,
          code: 'W150',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W150-Absolute-Acajou-.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Black',
          name_fr: null,
          code: 'S405',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/Black.png',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Black HG',
          name_fr: null,
          code: 'HG08',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Black Zebra',
          name_fr: null,
          code: 'HG25',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:05:11.507466Z',
          name: 'Caramel',
          name_fr: null,
          code: 'W200',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W200.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Cedar',
          name_fr: null,
          code: 'HG42',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Cherry',
          name_fr: null,
          code: 'W144',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w144-WILD-CHERRY.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Chocolate',
          name_fr: null,
          code: 'W292',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w292-Chocolate.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Fog Grey',
          name_fr: null,
          code: 'S551',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/S551-FOG_GREY.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Geneva',
          name_fr: null,
          code: 'AA60',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/PFA60-NEGOTIATING-IN-GENEVA.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Inox',
          name_fr: null,
          code: 'HG48',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Latin Walnut',
          name_fr: null,
          code: 'HG16',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:37.673774Z',
          name: 'Mahogany',
          name_fr: null,
          code: 'W365',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W365.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Maple',
          name_fr: null,
          code: 'W256',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Metallic Elm Tree',
          name_fr: null,
          code: 'HG85',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Oak',
          name_fr: null,
          code: 'HG20',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Royal Pearl',
          name_fr: null,
          code: 'HG19',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Sugar Maple',
          name_fr: null,
          code: 'HG60',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:35.425409Z',
          name: 'Tuxedo',
          name_fr: null,
          code: 'W155',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W155.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Walnut',
          name_fr: null,
          code: 'W393',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W393-CORPORATE-WALNUT.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White',
          name_fr: null,
          code: 'S645',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White Chocolate',
          name_fr: null,
          code: 'W221',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w221-white_chocolate.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White HG MDF',
          name_fr: null,
          code: 'HG41',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White Stripe',
          name_fr: null,
          code: 'HG80',
          description: 'High Gloss MDF',
          description_fr: null,
          manufacturer_id: 'Future Design And Decor',
          user_input: false,
          price: 7,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        }
      ],
      defaultOption: 'White',
      optionMargin: '3.00',
      optionUnit: 'square foot'
    },
    {
      name: 'Cabinet Top',
      name_in_model: 'cabinet-top',
      mode: 'texture',
      options: [
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:49:27.454842Z',
          name: 'Aged Oak',
          name_fr: '',
          code: 'W163',
          description: 'Low Presure Laminate',
          description_fr: '',
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W163.jpg',
          texture_height: 150,
          texture_width: 75,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Ash',
          name_fr: null,
          code: 'W150',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W150-Absolute-Acajou-.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Black',
          name_fr: null,
          code: 'S405',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/Black.png',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-03-14T19:05:11.507466Z',
          name: 'Caramel',
          name_fr: null,
          code: 'W200',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W200.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Cherry',
          name_fr: null,
          code: 'W144',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w144-WILD-CHERRY.jpg',
          texture_height: 150,
          texture_width: 300,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Chocolate',
          name_fr: null,
          code: 'W292',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w292-Chocolate.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Fog Grey',
          name_fr: null,
          code: 'S551',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/S551-FOG_GREY.jpg',
          texture_height: 100,
          texture_width: 200,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Geneva',
          name_fr: null,
          code: 'AA60',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/PFA60-NEGOTIATING-IN-GENEVA.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:37.673774Z',
          name: 'Mahogany',
          name_fr: null,
          code: 'W365',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W365.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Maple',
          name_fr: null,
          code: 'W256',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-16T22:04:35.425409Z',
          name: 'Tuxedo',
          name_fr: null,
          code: 'W155',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W155.jpg',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Walnut',
          name_fr: null,
          code: 'W393',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/W393-CORPORATE-WALNUT.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White',
          name_fr: null,
          code: 'S645',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'White Chocolate',
          name_fr: null,
          code: 'W221',
          description: 'Low Presure Laminate',
          description_fr: null,
          manufacturer_id: "McFadden's Inc",
          user_input: false,
          price: 0,
          cost: 0,
          image: 'https://tfg-erp-static-develop.s3.amazonaws.com/media/images/options/w221-white_chocolate.jpg',
          texture_height: 175,
          texture_width: 350,
          suboptions: []
        }
      ],
      defaultOption: 'White',
      optionMargin: '3.00',
      optionUnit: 'square foot'
    },
    {
      name: 'Lock',
      name_in_model: 'lock',
      mode: 'hide',
      options: [
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'Lock',
          name_fr: null,
          code: 'LOCK',
          description: 'Lock',
          description_fr: null,
          manufacturer_id: 'Workspace Plus',
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          name_in_model: 'lock-lock',
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: 'No Lock',
          name_fr: null,
          code: 'NLOCK',
          description: 'Lock',
          description_fr: null,
          manufacturer_id: 'Workspace Plus',
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          name_in_model: 'lock-no-lock',
          suboptions: []
        }
      ],
      defaultOption: 'Lock',
      optionMargin: '3.00',
      optionUnit: ''
    },
    {
      name: 'STD Handle',
      name_in_model: 'std-handle',
      mode: 'texture',
      options: [
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: '18" Bar Pull',
          name_fr: null,
          code: 'BP457',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: '24" Bar Pull',
          name_fr: null,
          code: 'BP609',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: '30" Bar Pull',
          name_fr: null,
          code: 'BP762',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: '36" Bar Pull',
          name_fr: null,
          code: 'BP914',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-11T03:03:37.353756Z',
          modified_date: '2022-02-11T03:03:37.353781Z',
          name: '5" Bar Loop Black',
          name_fr: null,
          code: 'LB',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-11T03:03:37.453638Z',
          modified_date: '2022-02-11T03:03:37.453662Z',
          name: '5" Bar Loop Nickel',
          name_fr: null,
          code: 'LN',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-03T20:53:24.052316Z',
          modified_date: '2022-02-03T20:53:24.093025Z',
          name: '5" Bar Pull',
          name_fr: null,
          code: 'BP128',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 0,
          cost: 0,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-11T03:03:37.269944Z',
          modified_date: '2022-02-11T03:03:37.269969Z',
          name: '5" Bar Pull Nickel',
          name_fr: null,
          code: 'BPN',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-11T03:03:37.533423Z',
          modified_date: '2022-02-11T03:03:37.533447Z',
          name: '5" Square Nickel',
          name_fr: null,
          code: 'SN',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-11T03:03:37.689508Z',
          modified_date: '2022-02-11T03:03:37.689533Z',
          name: '5" Strait Pull Black',
          name_fr: null,
          code: 'SPB',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-11T03:03:37.771187Z',
          modified_date: '2022-02-11T03:03:37.771213Z',
          name: '5" Strait Pull Silver',
          name_fr: null,
          code: 'SPS',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        },
        {
          created_date: '2022-02-11T03:03:37.850851Z',
          modified_date: '2022-02-11T03:03:37.850875Z',
          name: '5" Waterfall Black',
          name_fr: null,
          code: 'WB',
          description: 'Handle',
          description_fr: null,
          manufacturer_id: 'Hafele Canada Inc',
          user_input: false,
          price: 6,
          cost: 1.21,
          image: '',
          texture_height: 10,
          texture_width: 10,
          suboptions: []
        }
      ],
      defaultOption: '5" Bar Pull',
      optionMargin: '3.00',
      optionUnit: ''
    }
  ],
  levelProperties: [
    'part_type',
    'group',
    'style',
    'door',
    'height',
    'depth',
    'width'
  ]
};
