import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import useCreateQuickAccountFormState, { validationRules } from './state';

export interface QuickAccountHistorySateModel {
  quickAccountForm: {
    salesRep: string;
  };
}

const CreateQuickAccountForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { quickAccountForm: { salesRep } } = state as QuickAccountHistorySateModel;

  const {
    state: {
      formData,
      creating,
      errors,
    },
    actions: {
      setFormValue,
      createQuickAccount,
      setValidationErrors,
    },
  } = useCreateQuickAccountFormState(salesRep);

  return (
    <Modal
      visible
      title="Create Account"
      onCancel={goBack}
      okText="Submit"
      onOk={createQuickAccount}
      confirmLoading={creating}
    >
      <Form
        state={formData}
        onChange={setFormValue}
        errors={errors}
        onError={setValidationErrors}
        rules={validationRules}
      >
        <Field
          id="companyName"
          name="companyName"
          label="Account Name"
          placeholder="Enter a account name"
        />
        <Field
          id="firstName"
          name="firstName"
          label="First Name"
          placeholder="Enter First Name"
        />
        <Field
          id="lastName"
          name="lastName"
          label="Last Name"
          placeholder="Enter Last Name"
        />
        <Field
          id="email"
          name="email"
          label="Email"
          placeholder="Enter Email"
        />
      </Form>
    </Modal>
  );
};

export default CreateQuickAccountForm;
