import React, { FunctionComponent, useMemo } from 'react';
import Board from '@lourenci/react-kanban';
import { Skeleton } from 'antd';
import '@lourenci/react-kanban/dist/styles.css';
import RenderIf from '../RenderIf';
import Divider from '../Divider';
import Card, { CardBag } from './Card';
import './styles.scss';

export interface Card{
  id: number;
  title: string;
  description: string;
}
export interface Column{
  id: number;
  title: string;
  cards: Card[];
  [x: string]: any;
}
interface Props{
  columns?: Column[];
  openEdit: (item) => any;
  loading?: boolean;
  [x: string]: any;
}

const columnSkeleton = () => [...new Array(3)].map((index) => (
  <div style={{ width: '20vw' }} key={index}>
    <Skeleton.Button className="skeleton-title" shape="round" active size="small" />
    <Divider />
    <div className="mb-4">
      <Skeleton paragraph active />
    </div>
    <Skeleton paragraph active />
  </div>
));

const KanbanBoard: FunctionComponent<Props> = (props) => {
  const { columns, openEdit, loading, ...rest } = props;
  const renderCard = useMemo(() => (card: Card, cardBag: CardBag) => Card(card, cardBag, openEdit), [openEdit]);
  const SkeletonBoard = useMemo(columnSkeleton, []);
  return (
    <>
      <RenderIf isTrue={loading}>
        <div className="d-flex justify-content-around">
          {SkeletonBoard}
        </div>
      </RenderIf>
      <RenderIf isTrue={!loading && !!columns.length}>
        <Board
          disableColumnDrag
          renderCard={renderCard}
          {...rest}
        >
          {{ columns }}
        </Board>
      </RenderIf>
    </>
  );
};

export default KanbanBoard;
