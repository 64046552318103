import React, { FunctionComponent, useMemo } from 'react';
import { OrderProductModel } from 'models/order';
import subOptionApi from 'api/sub-option';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import { Text } from 'components/base-components/Typography';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import useProductFormState from './state';

interface Props {
  product: OrderProductModel;
  errors: { [option: string]: string };
  missing: string[];
  onUpdateProduct: (productId: number, options) => void;
}

const optionMapDropdown = {
  fields: {
    value: 'name',
    label: 'name',
  },
  copyFullItem: true,
};

const mapSubOptions = (subOptions) => (
  mapGenericDropdownOptions(subOptions, optionMapDropdown)
);

const ProductForm: FunctionComponent<Props> = (props) => {
  const { product, errors, onUpdateProduct: updateProduct, missing } = props;
  const {
    state: {
      fetchingOptionGroups,
      productOptions,
      formData,
    },
    actions: {
      setFormValue,
    },
  } = useProductFormState(product, updateProduct);

  const productOptionsFields = useMemo(() => (
    productOptions && productOptions.map(({ name, option }) => {
      if (missing.includes(name)) {
        const hasSubOptions = formData[name]?.dropdownItem?.haveSuboptions;
        const filters = {
          filters: {
            option__name: formData[name]?.value,
          }
        };
        return (
          <>
            <Field
              component={Select}
              key={name}
              id={name}
              name={name}
              label={name}
              options={mapGenericDropdownOptions(option, optionMapDropdown)}
            />
            <RenderIf isTrue={hasSubOptions}>
              <Field
                component={AsyncSelectV2}
                listAction={subOptionApi.list}
                mapFunction={mapSubOptions}
                preventAutoFetch={!hasSubOptions}
                filters={filters}
                name={`sub-${name}`}
                id={`sub-${name}`}
                label={`${formData[name]?.label} Options`}
              />
            </RenderIf>
          </>
        );
      }
      return null;
    })
  ), [productOptions, formData, missing]);

  return (
    <div data-el="product-options">
      <Text>
        {product.model}
      </Text>
      <Form
        state={formData}
        onChange={setFormValue}
        errors={errors}
        mT
      >
        <RenderIf isTrue={!fetchingOptionGroups} fallback={<Spinner />}>
          {productOptionsFields}
        </RenderIf>
      </Form>
    </div>
  );
};

export default ProductForm;
