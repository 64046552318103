import React, { FunctionComponent } from 'react';
import { Badge as AntBadge } from 'antd';
import { BadgeProps } from 'antd/lib/badge';

const Badge: FunctionComponent<BadgeProps> = (props) => (
  <AntBadge {...props} />
);

export const badgeColors = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
];

export default Badge;
