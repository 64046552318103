import { AxiosResponse } from 'axios';
import { OrderDTO } from 'models/order';
import cncCalcApi, { CutListResponse } from 'api/cnc-calc';
import { resolveApiErrorMessage } from 'api/base';
import orderApi from 'api/orders';
import notification, { NotificationType } from 'helpers/notification';
import { CNCListActions } from '../reducer';

export default function calculateBulkCNC(
  dispatch,
  orders: OrderDTO[],
  selectedOrdersKeys: number[],
) {
  return () => {
    dispatch({ type: CNCListActions.START_CALCULATING_BULK });
    const selectedOrders = orders.filter(
      (order) => selectedOrdersKeys.some(
        (key) => key === order.id
      )
    );

    const onSuccess = (response: AxiosResponse<CutListResponse>) => {
      const { data: { xlsx: fileUrl } } = response;
      window.open(fileUrl);
      dispatch({ type: CNCListActions.FINISH_CALCULATING_BULK });
    };

    const onError = (error) => {
      notification({
        message: resolveApiErrorMessage((error as any).response),
        type: NotificationType.ERROR,
      });
      dispatch({ type: CNCListActions.FINISH_CALCULATING_BULK });
    };

    const getRequests = selectedOrders.map(order => orderApi.get(order.id));

    return Promise.all(getRequests)
      .then(responses => responses.map(response => response.data))
      .then(extendedOrders => cncCalcApi.calculate({ orders: extendedOrders }))
      .then(onSuccess)
      .catch(onError);
  };
}
