import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import RenderIf from 'components/base-components/RenderIf';
import Select from 'components/base-components/Select';
import Upload from 'components/base-components/Upload';
import MorphInputs from './MorphInputs';
import useTermFormState, { modelValidationRules } from './state';
import './styles.scss';

export default function ThreeModelForm() {
  const { goBack } = useHistory();

  const {
    state: {
      fetchingFormData,
      threeModel,
      positions,
      properties,
      savingModel,
      errors,
    },
    actions: {
      handleFileChange,
      setModel,
      addMorph,
      changeMorph,
      removeMorph,
      saveThreeModel,
      setErrors,
    },
  } = useTermFormState();

  const modalTitle = useMemo(() => (
    threeModel.id ? 'Edit 3D Model' : 'Create 3D Model'
  ), [threeModel]);

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveThreeModel}
      confirmLoading={savingModel}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={threeModel}
          onChange={setModel}
          errors={errors}
          onError={setErrors}
          rules={modelValidationRules}
        >
          <Field
            id="model"
            name="threeModel"
            label="Model file"
            component={Upload}
            accept=".gltf, .glb"
            onChange={handleFileChange}
          />
          <Field name="modelName" label="Model Name" />
          <Field
            id="position"
            name="modelPosition"
            label="Position on Scene"
            component={Select}
            options={positions}
          />
          <MorphInputs
            properties={properties}
            morphs={threeModel.morphs}
            onAdd={addMorph}
            onChange={changeMorph}
            onRemove={removeMorph}
            savingModel={savingModel}
            error={errors.morphs}
          />
        </Form>
      </RenderIf>
    </Modal>
  );
}
