import React from 'react';
import Tag from 'components/base-components/Tag';
import Button from 'components/base-components/Button';

export default function progressTemplate(handleShow) {
  return (data) => {
    const value = data.taskCompletePercentage * 100;
    const onClick = () => handleShow(data);
    const valueFormatted = (value && value % 1 !== 0) ? parseInt(value.toString(), 10) : value;
    const color = value === 100 ? '#139272' : '#f60605';
    return (
      <Button className="button-cell__button" type="text" onClick={onClick}>
        <Tag color={color}>
          {`${valueFormatted}%`}
        </Tag>
      </Button>
    );
  };
}
