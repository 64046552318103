/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LoggedUserDetailsModel } from '../models/user';
import hasPermissions from './permissons';

const key = 'userDetails';

export function getLoggedUser(): LoggedUserDetailsModel {
  return JSON.parse(localStorage.getItem(key)) || {};
}

export function getLoggedUserId(): string {
  return JSON.parse(localStorage.getItem(key)).id!;
}

export function getLoggedUserName(): string {
  return JSON.parse(localStorage.getItem(key)).username!;
}

export function getLoggedUserPermissions(): string[] {
  return JSON.parse(localStorage.getItem(key)).permissions!;
}

export function isDealerLoggedUser(onlyId?: boolean): LoggedUserDetailsModel | boolean | string {
  const user = getLoggedUser();
  if (!user.isSuperuser && !user.isStaff && hasPermissions(['Dealer'])) {
    return onlyId ? user.id : user;
  }
  return undefined;
}
