import productionManagerApi from 'api/production-manger';
import getPayload from './get-payload';

const actions = {
  cncPercentage: productionManagerApi.setCncPercentage,
  fabricationPercentage: productionManagerApi.setFabricationPercentage,
  readyDate: productionManagerApi.setReadyDate,
  readyDateConfirmation: productionManagerApi.setReadyDateConfirmation,
  productionStatus: productionManagerApi.setProductionStatus,
};

export default function onSubmit(data, dataIndex) {
  const payload = getPayload(data, dataIndex);
  const action = actions[dataIndex];
  return action(data.id, payload);
}
