import React, { FunctionComponent, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Input from 'components/base-components/Input';
import { validationRules } from './validations';
import useStoreFormState from './state';
import Upload from '../../../base-components/Upload';

export interface StoreFormHistorySateModel {
  storeForm: {
    storeUrl: number;
  };
}

const StoreForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { storeForm: { storeUrl } } = state as StoreFormHistorySateModel;

  const {
    state: {
      savingStore,
      store,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveStore,
      setErrors,
    },
  } = useStoreFormState(storeUrl);

  const modalTitle = useMemo(
    () => storeUrl ? 'Edit Store' : 'Create Store',
    [storeUrl],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveStore}
      confirmLoading={savingStore}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={store}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="name"
            name="name"
            label="Name"
            placeholder="Enter Name"
          />
          <Field
            id="nameLegal"
            name="nameLegal"
            label="Name Legal"
            placeholder="Enter Name Legal"
          />
          <Field
            name="email"
            id="email"
            label="Email"
            placeholder="Enter Email"
          />
          <Field
            name="interactEmail"
            id="interactEmail"
            label="interactEmail"
            placeholder="Enter Interact Email"
          />
          <Field
            name="location"
            id="location"
            label="Location"
            placeholder="Enter Location"
          />
          <Field
            name="domain"
            id="domain"
            label="Domain"
            placeholder="Enter Domain"
          />
          <Field
            name="address"
            id="address"
            label="Address"
            placeholder="Enter Address"
          />
          <Field
            name="phone"
            id="phone"
            label="phone"
            placeholder="Enter Phone"
            mask="(999) 999-9999"
            maskChar="-"
            tag={[Input, InputMask]}
          />
          <Field
            name="currencyCode"
            id="currencyCode"
            label="Currency Code"
            placeholder="Enter Currency Code"
          />
          <Field
            type="number"
            name="taxNumber"
            id="taxNumber"
            label="Tax Number"
            placeholder="Enter Tax Number"
          />
          <Field
            component={Upload}
            name="logo"
            id="logo"
            label="Choose Image for Logo"
            accept="image/png, .jpeg, .jpg"
          />
          <Field
            component={Upload}
            name="creditCardAuthForm"
            id="creditCardAuthForm"
            label="Choose Credit Card Auth Form File"
            accept=".pdf, .doc, .docx"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default StoreForm;
