import React from 'react';
import Tag from 'components/base-components/Tag';

export default function progressTemplate(dataIndex: string) {
  return (value, data) => {
    const valueFormatted = (value && value % 1 !== 0) ? (value).toFixed(2) : value;
    const color = data?.statuses ? data?.statuses[dataIndex] : undefined;
    return (<Tag color={color}>{`${valueFormatted}%`}</Tag>);
  };
}
