export interface DropdownOptionModel {
  label: string;
  value: any;
  key?: any;
  [x: string]: any;
}
export interface TreeSelectOptionModel {
  id: string;
  value: string;
  label: string;
  pId: string | number;
  isLeaf: boolean;
  selectable?: boolean;
  item?: any;
}

export function mapDropdownOptions(data, extra?): DropdownOptionModel[] {
  if (!!data && Array.isArray(data)) {
    return data.map((item) => {
      let option = {
        label: (
          item.name ||
          item.fullName ||
          item.businessTradeName ||
          item.companyName ||
          item.jobTitle
        ),
        value: item.url || item.id,
        key: item.url || item.id,
      };
      if (extra && Array.isArray(extra)) {
        const extraFields = extra.map((e) => ({ [e]: item[e] }));
        extraFields.forEach((field) => {
          option = {
            ...option,
            ...field,
          };
        });
      }
      return option;
    });
  }

  return undefined;
}

export function userDropdownData(user, forCognito) {
  return {
    value: forCognito ? user.cognitoId : user.url || user.id,
    label: `${user.firstName} ${user.lastName}`,
  };
}

export function mapUserDropdownOptions(
  users,
  forCognito?: boolean,
): DropdownOptionModel[] {
  return users.map((user) => (userDropdownData(user, forCognito)));
}

export function quoteDropdownData(quote) {
  return {
    value: quote.id,
    key: quote.id,
    label: `#${quote.id} - ${quote.comments}`,
    project: typeof quote.project === 'object' ? quote.project.id : quote.project,
    data: quote,
  };
}

export function mapQuotesDropdownOptions(quotes): DropdownOptionModel[] {
  if (quotes && Array.isArray(quotes)) {
    return quotes.map((quote) => quoteDropdownData(quote));
  }
  return undefined;
}

export function flatDropdownOptions(items) {
  if (items && Array.isArray(items)) {
    return items.map((item) => item.url || item.value);
  }
  return undefined;
}

export function flatDropdownOption(item) {
  if (item) {
    return item.url || item.value;
  }
  return undefined;
}

type fieldType = string | ((item) => any);

export interface GenericMapOptions {
  fields: {
    value: fieldType;
    label: fieldType;
  };
  extra?: string[];
  copyFullItem?: boolean;
}

const defaultMapOptions: GenericMapOptions = {
  fields: { value: 'id', label: 'name' },
  copyFullItem: false,
};

export function mapGenericDropdownOptions(
  data: any[],
  options: GenericMapOptions = defaultMapOptions,
) {
  const {
    fields: {
      value: valueField,
      label: labelField,
    },
    copyFullItem,
    extra,
  } = options;

  return data.map((item) => {
    let option = {
      key: typeof valueField === 'string' ? item[valueField] : valueField(item),
      value: typeof valueField === 'string' ? item[valueField] : valueField(item),
      label: typeof labelField === 'string' ? item[labelField] : labelField(item),
      dropdownItem: copyFullItem ? item : undefined,
    };

    if (extra && Array.isArray(extra)) {
      const extraFields = extra.map((e) => ({ [e]: item[e] }));
      extraFields.forEach((field) => {
        option = {
          ...option,
          ...field,
        };
      });
    }

    return option;
  });
}

export function mapGenericDropdownData(
  data: any,
  options: GenericMapOptions = defaultMapOptions,
) {
  const { fields: { value: valueField, label: labelField }, copyFullItem } = options;

  if (data) {
    return {
      key: typeof valueField === 'string' ? data[valueField] : valueField(data),
      value: typeof valueField === 'string' ? data[valueField] : valueField(data),
      label: typeof labelField === 'string' ? data[labelField] : labelField(data),
      dropdownItem: copyFullItem ? data : undefined,
    };
  }

  return undefined;
}

export function flatTreeSelectLabel(label: string) {
  const separatorIndex = label.indexOf('-');
  const child = separatorIndex ? label.slice(0, Math.max(0, separatorIndex)) : label;
  const parent = separatorIndex ? label.slice(Math.max(0, separatorIndex + 1)) : '';
  return { child, parent };
}

export function treeSelectOption(
  name: string,
  isLeaf: boolean,
  pId = 0 as string | number,
  repeatedValues = false,
  selectable = true,
  item?: any,
): TreeSelectOptionModel {
  const value = repeatedValues ? `${name}-${pId}` : name;
  return {
    id: value,
    value,
    label: name,
    pId,
    isLeaf,
    selectable,
    item
  };
}

export function mapTreeSelectOptionSimple(
  items,
  pId,
  areLeaf: boolean | string,
  repeatedValues = false,
  copyFullItem = false
) {
  if (items && Array.isArray(items)) {
    return items.map((item) => (treeSelectOption(
      item.name,
      typeof areLeaf === 'boolean' ? areLeaf : item[areLeaf].length === 0,
      pId,
      repeatedValues,
      true,
      copyFullItem ? item : undefined,
    )));
  }
  return undefined;
}
