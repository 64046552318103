import { DropdownOptionModel } from 'helpers/dropdown-options';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { QuickUserHistorySateModel } from '../../Form/CreateUser';

export default function openCreatUserModal(push, account: DropdownOptionModel) {
  return () => {
    const historyLocationState: QuickUserHistorySateModel = {
      quickUserForm: {
        account: account.value,
      },
    };
    push(ModalRouteHash.QuickUserForm, historyLocationState);
  };
}
