import { Moment } from 'moment';
import { DropdownOptionModel } from 'helpers/dropdown-options';

export enum GroupBy {
  ACCOUNT = 'account',
  GROUP = 'account_group',
  REP = 'rep_owner',
  CREATED_DATE = 'created_date',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export interface GroupByOptionModel extends DropdownOptionModel {
  value: GroupBy;
}

export interface Filters {
  groupBy?: GroupByOptionModel;
  startDate?: Moment;
  endDate?: Moment;
}

export interface TableFilters {
  accounts?: string[];
  groups?: string[];
  reps?: string[];
}
