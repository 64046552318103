import React, { FunctionComponent } from 'react';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import { useHistory } from 'react-router-dom';
import useCreateUserFormState, { validationRules } from './state';

export interface QuickUserHistorySateModel {
  quickUserForm: {
    account: string;
  };
}

const CreateQuickUserForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { quickUserForm: { account } } = state as QuickUserHistorySateModel;

  const {
    state: {
      formData,
      creating,
      errors,
    },
    actions: {
      setFormValue,
      createQuickUser,
      setValidationErrors,
    },
  } = useCreateUserFormState(account);

  return (
    <Modal
      visible
      title="Create User"
      onCancel={goBack}
      okText="Submit"
      onOk={createQuickUser}
      confirmLoading={creating}
    >
      <Form
        state={formData}
        onChange={setFormValue}
        errors={errors}
        onError={setValidationErrors}
        rules={validationRules}
      >
        <Field
          id="firstName"
          name="firstName"
          label="First Name"
          placeholder="Enter First Name"
        />
        <Field
          id="lastName"
          name="lastName"
          label="Last Name"
          placeholder="Enter Last Name"
        />
        <Field
          id="email"
          name="email"
          label="Email"
          placeholder="Enter Email"
        />
      </Form>
    </Modal>
  );
};

export default CreateQuickUserForm;
