import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { CopyProductHistorySateModel } from '../../Form/CopyOrderProducts';

export default function openCopyProductModal(push, quote: QuoteModel, productsSelected: number[], groups: string[]) {
  return () => {
    const historyLocationState: CopyProductHistorySateModel = {
      copyProductForm: {
        quote,
        productsSelected,
        groups,
      },
    };
    push(ModalRouteHash.CopyOrderProducts, historyLocationState);
  };
}
