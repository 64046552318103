import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { ProjectModel } from 'models/project';
import { CountryModel } from 'models/country';
import { ProvinceModel } from 'models/province';
import { ShippingZoneModel } from 'models/shipment';
import countriesApi from 'api/country';
import projectsApi from 'api/projects';
import provincesApi from 'api/province';
import shippingZoneApi from 'api/shipping-zone';
import useGetList from 'hooks/base/get-list';
import {
  GenericMapOptions,
  mapDropdownOptions,
  mapGenericDropdownOptions,
} from 'helpers/dropdown-options';
import { ConvertToOrderProps } from '..';
import setProductValues from './actions/set-product-values';
import resolveProducts from './actions/resolve-products';
import resolveShipmentFields from './actions/resolve-shipment-fields';
import setAddressInfo from './actions/set-address-info';
import filterProvinces from './actions/filter-provinces';
import handleCountryChange from './actions/handle-country-change';
import setProject from './actions/set-project';
import setZone from './actions/set-zone';
import openShipmentDQ from './actions/open-shipment-dq';
import setErrors from './actions/set-errors';
import resolveProductValues from './actions/resolve-product-values';
import handleOnSubmit from './actions/handle-submit';
import convertToOrderReducer, {
  ConvertToOrderActions,
  ConvertToOrderState,
  FormAddressModel,
} from './reducer';

export * from './validations';

const countryMapOptions: GenericMapOptions = {
  fields: { value: 'id', label: 'country' },
};
const shippingZoneMapOptions: GenericMapOptions = {
  fields: { value: 'id', label: 'zoneName' },
};
const projectsMapOptions: GenericMapOptions = {
  fields: { value: 'id', label: 'jobTitle' },
};

const initialState: ConvertToOrderState = {
  productsFormData: {},
  shipmentFormData: {
    project: undefined,
    zone: undefined,
    address: {} as FormAddressModel,
  },
  selectedCountry: undefined,
  fetchingHash: false,
  productErrors: {},
  addressErrors: {},
  projectError: undefined,
  zoneError: undefined,
  updatingQuoteFields: false,
};

export default function useConvertToOrderState(props: ConvertToOrderProps) {
  const { quote, missingFields, shipment, convertingToOrder } = props;
  const [state, dispatch] = useReducer(convertToOrderReducer, initialState);
  const {
    fetching: fetchingCountries,
    data: countries,
    error: countryError,
  } = useGetList<CountryModel>(countriesApi, { preventAutoFetch: !props.isVisible });
  const {
    fetching: fetchingProvinces,
    data: provinces,
    error: provinceError,
  } = useGetList<ProvinceModel>(provincesApi, { preventAutoFetch: !props.isVisible });
  const {
    fetching: fetchingZones,
    data: shippingZones,
    error: zonesError,
  } = useGetList<ShippingZoneModel>(shippingZoneApi, { preventAutoFetch: !props.isVisible });
  const {
    fetching: fetchingProjects,
    data: projects,
    error: projectsError,
  } = useGetList<ProjectModel>(projectsApi, { preventAutoFetch: !props.isVisible });

  useEffect(() => {
    if (!props.isVisible) {
      dispatch({ type: ConvertToOrderActions.RESET_STATE, payload: initialState });
    }
  }, [props.isVisible]);

  useEffect(() => {
    const productValues = resolveProductValues(quote, missingFields);
    dispatch({
      type: ConvertToOrderActions.INITIALIZE_PRODUCTS_VALUES,
      payload: productValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingFields]);

  return {
    state: {
      ...state,
      missingOptions: useMemo(() => missingFields?.productOptions?.missing, [missingFields]),
      showProductsSection: useMemo(() => (
        !!missingFields?.productOptions &&
        !!missingFields?.productOptions.missing &&
        Object.keys(missingFields.productOptions.missing).length > 0
      ), [missingFields]),
      showShipmentSection: useMemo(() => (
        !!missingFields?.shipping &&
        !!missingFields?.shipping.missing &&
        Object.keys(missingFields.shipping.missing).length > 0
      ), [missingFields]),
      productsToFill: useMemo(
        () => resolveProducts(quote, missingFields),
        [quote, missingFields],
      ),
      shipmentFields: useMemo(
        () => resolveShipmentFields(missingFields),
        [missingFields],
      ),
      countries: useMemo(() => (
        mapGenericDropdownOptions(countries, countryMapOptions)
      ), [countries]),
      provinces: useMemo(() => (
        mapDropdownOptions(filterProvinces(provinces, state.selectedCountry))
      ), [provinces, state.selectedCountry]),
      shippingZones: useMemo(() => (
        mapGenericDropdownOptions(shippingZones, shippingZoneMapOptions)
      ), [shippingZones]),
      projects: useMemo(() => (
        mapGenericDropdownOptions(projects, projectsMapOptions)
      ), [projects]),
      fetchingShipmentData: useMemo(
        () => fetchingCountries || fetchingProvinces || fetchingProjects || fetchingZones,
        [fetchingCountries, fetchingProvinces, fetchingProjects, fetchingZones],
      ),
      error: useMemo(
        () => countryError || provinceError || projectsError || zonesError,
        [countryError, provinceError, projectsError, zonesError],
      ),
      convertingToOrder: useMemo(
        () => convertingToOrder || state.updatingQuoteFields,
        [convertingToOrder, state.updatingQuoteFields],
      )
    },
    actions: {
      handleUpdateProduct: useCallback(setProductValues(dispatch), []),
      setAddressInfo: useCallback(setAddressInfo(dispatch), []),
      handleCountryChange: useCallback(handleCountryChange(dispatch), []),
      setProject: useCallback(setProject(dispatch), []),
      setZone: useCallback(setZone(dispatch), []),
      setAddressErrors: useCallback(
        setErrors(dispatch, ConvertToOrderActions.SET_ADDRESS_ERRORS),
        [],
      ),
      openShipmentDQ: useCallback(
        openShipmentDQ(dispatch, shipment, props),
        [shipment, props],
      ),
      handleOnSubmit: useCallback(
        handleOnSubmit(dispatch, state, props),
        [state, props],
      )
    },
  };
}
