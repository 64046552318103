import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { OrderDTO, OrderModel, OrderStatus, OrderTotal } from 'models/order';
import orderApi from 'api/orders';
import invoiceApi from 'api/invoice';
import orderStatusApi from 'api/orde-status';
import { EventChannelList } from 'helpers/event-center';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import notification, { NotificationType } from 'helpers/notification';
import { isDealerLoggedUser } from 'helpers/get-logged-user';
import useGetList from 'hooks/base/get-list';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import useCallApiAction from 'hooks/base/call-api-action';
import openOrderDetails from './actions/open-order-detail';
import openReceivePaymentModal from './actions/open-receive-payment';
import getPayload from './actions/get-payload';
import openSendEmailModal from './actions/open-send-email';
import printOrder from './actions/print-order';
import printDeficiencyReport from './actions/print-deficiency-report';
import printMaterial from './actions/print-materials';
import onChangeStatusSuccess from './actions/on-change-status-success';
import { orderStatusMapOptions, orderStatusParams } from '../constants';

export default function useOrderTableState() {
  const { push, location: { search } } = useHistory();
  const [showAll, setShowAll] = useState(false);
  const isDealer = useMemo(() => isDealerLoggedUser(true), []);

  const orderTableStateParams = useMemo<UseTableStateParams>(() => ({
    overrideListFunction: showAll ? undefined : orderApi.listIncompletes,
    api: orderApi,
    queryParams: isDealer ? { quote__project__client: isDealer } : undefined,
    eventChannel: EventChannelList.ORDERS_LIST_CHANGED,
    searchFields: ['quote', 'order_status'],
    deleteModalTitle: 'Delete Order',
    deleteModalContent: (order: OrderModel) => (
      `Are you sure that you want to delete the order
     for the project ${order?.quote?.project?.jobTitle || ''}?`
    ),
    defaultSortField: '-id',
    reference: 'order',
  }), [showAll, isDealer]);
  const { state, actions } = useTableState<OrderDTO>(orderTableStateParams);

  const { fetching: fetchingOrders, data: orders } = state;

  const orderTotalsParams = useMemo(() => {
    const orderIds = !fetchingOrders
      ? orders.map((order) => order.id)
      : [];

    return {
      filters: {
        orderId: orderIds,
      }
    };
  }, [fetchingOrders, orders]);

  const {
    fetching: fetchingTotals,
    data: orderTotals,
    error: orderTotalsError,
  } = useCallApiAction<OrderTotal[]>(
    invoiceApi.getOrderTotals,
    orderTotalsParams,
    [],
    fetchingOrders || orders.length === 0,
  );

  const {
    fetching: fetchingOrderStatus,
    data: orderStatuses,
    error: orderStatusError,
  } = useGetList<OrderStatus>(orderStatusApi.list, orderStatusParams);

  useEffect(() => {
    if (orderTotalsError) {
      notification({
        type: NotificationType.ERROR,
        message: orderTotalsError
      });
    }
  }, [orderTotalsError]);

  useEffect(() => {
    if (orderStatusError) {
      notification({
        type: NotificationType.ERROR,
        message: orderStatusError,
      });
    }
  }, [orderStatusError]);

  return {
    state: {
      ...state,
      showAll,
      fetchingOrderStatus,
      data: useMemo(() => {
        if (orders.length > 0 && orderTotals && orderTotals.length > 0) {
          return orders.map((order) => {
            const total = orderTotals.find((t) => t.orderId === order.id);

            if (total) {
              const { balance } = total;
              return {
                ...order,
                balance: parseFloat(balance) || '0.00',
              };
            }
            return order;
          });
        }

        return orders;
      }, [orders, orderTotals]),
      fetching: fetchingOrders || fetchingTotals || fetchingOrderStatus,
      orderStatuses: useMemo(
        () => mapGenericDropdownOptions(orderStatuses, orderStatusMapOptions),
        [orderStatuses],
      ),
      filtersOrderStatus: useMemo(() => orderStatuses.map(opt => (
        { value: opt.id, text: opt.status }
      )), [orderStatuses]),
    },
    actions: {
      ...actions,
      printOrder,
      printMaterial,
      printDeficiencyReport,
      hiddenAction: useCallback(() => !!isDealer, [isDealer]),
      toggleShowAll: useCallback(() => setShowAll(!showAll), [showAll]),
      openOrderDetails: useCallback(openOrderDetails(push), [push]),
      openSendEmailModal: useCallback(openSendEmailModal(push, search), [push, search]),
      openReceivePaymentModal: useCallback(openReceivePaymentModal(push, search), [push, search]),
      getPayload,
      onChangeStatusSuccess: useCallback(
        onChangeStatusSuccess(showAll, orderStatuses),
        [showAll, orderStatuses],
      ),
    },
  };
}
