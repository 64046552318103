export const filesIcons = [
  { type: 'pdf', icon: 'la la-file-pdf' },
  { type: 'png', icon: 'la la-file-image' },
  { type: 'excel', icon: 'la la-file-excel' },
  { type: 'word', icon: 'la la-file-word' },
  { type: 'powerpoint', icon: 'la la-file-powerpoint' },
  { type: 'csv', icon: 'la la-file-csv' },
  { type: 'text', icon: 'la la-file-alt' },
  { type: 'video', icon: 'la la-file-video' },
  { type: 'audio', icon: 'la la-file-audio' },
  { type: 'zip', icon: 'la la-file-archive' },
];

export function getFileIcon(type: string) {
  const fileIcon = filesIcons.find((item) => type.includes(item.type));
  return fileIcon ? fileIcon.icon : 'la la-file';
}

export function convertFileSize(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 Byte';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
}

export function isValidFile(file) {
  return file instanceof File && !!file.size;
}

export function mapFileFromUrl(fileUrl: string) {
  return fileUrl ? [new File([], fileUrl.split('/').pop())] : undefined;
}
