import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { BuilderActions, BuilderFormModel, OptionDropdownModel } from '..';

const defaultValue = { value: '', label: 'None' };

function resolveOptionValues(
  product: BuilderFormModel,
  updatedOptions,
) {
  const { options } = product;
  const updatedOption = (
    Object.keys(options)
      .find((option) => {
        const oldValue = options[option]?.value;
        const updatedValue = updatedOptions[option]?.value;
        return oldValue !== updatedValue;
      })
  );

  if (!updatedOption) {
    return { hasUpdates: false };
  }

  const {
    value,
    textureHeight,
    textureWidth,
    image,
    nameInModel,
    group: {
      nameInModel: groupNameInModel,
      mode,
    }
  } = updatedOptions[updatedOption] as OptionDropdownModel;

  return {
    hasUpdates: true,
    updatedOption: {
      name: updatedOption,
      mode,
      image,
      value,
      nameInModel,
      textureWidth,
      textureHeight,
      groupNameInModel,
    },
  };
}

export default function updateOptions(dispatch, product: BuilderFormModel) {
  return (value) => {
    const {
      hasUpdates,
      updatedOption,
    } = resolveOptionValues(product, value);

    if (hasUpdates) {
      notifyEventChannel(
        EventChannelList.BUILDER_PRODUCT_OPTION_CHANGED,
        updatedOption,
      );
    }

    const suboption = value[updatedOption?.name].dropdownItem?.suboptions || [];
    const options = value[updatedOption?.name].dropdownItem?.suboptions?.length > 0
      ? {
        ...value,
        [`sub-${updatedOption.name}`]: defaultValue,
        [updatedOption.name]: {
          ...value[updatedOption.name],
          suboption
        }
      }
      : value;

    dispatch({
      type: BuilderActions.UPDATE_PRODUCT,
      payload: { ...product, options },
    });
  };
}
