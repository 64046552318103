import { InstallerBookingDTO } from 'models/project-coordinator';
import { InstallerBookingModel } from 'models/installer-booking';
import { formatDateTimeToString } from 'helpers/formatting';
import { InstallerFormModel } from '../reducer';

export default function getPayload(formData: InstallerFormModel) {
  return {
    ...formData,
    installationDate: formatDateTimeToString(formData.installationDate),
    installer: formData.installer.value
  } as InstallerBookingDTO;
}

export function installerBookingToDTO(data: InstallerBookingModel): InstallerBookingDTO {
  const { shipment: { project }, orderNumber: _orderNumber, ...rest } = data;
  return {
    ...rest,
    projectId: project
  };
}
