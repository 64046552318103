import { StoreModel } from 'models/store';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';

const { REACT_APP_ACCOUNTS_API } = process.env;
const endpoint = `${REACT_APP_ACCOUNTS_API}/stores/`;

const storeApi: ApiModel<StoreModel> = getApi(endpoint, ApiContentType.MULTIPART);

export default storeApi;
