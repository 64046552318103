import { InvoiceReportModel } from 'models/invoice-report';

export const filterAccounts = (value, record) => (
  value === 'None' ? !record.account : record.account === value
);

export const filterGroups = (value, record) => {
  return (
    value === 'None'
      ? !record.accountGroup
      : record.accountGroup === value
  );
};

export const filterRepOwners = (value, record: InvoiceReportModel) => {
  const { repOwnerData } = record;

  if (value === 'None') {
    return !repOwnerData;
  }

  if (!repOwnerData) {
    return false;
  }

  const ownerName = `${repOwnerData.firstName} ${repOwnerData.lastName}`;
  return value === ownerName;
};

export const filterBatches = (value, record) => {
  const { label } = record;
  return value === 'None' ? !label : label === value;
};
