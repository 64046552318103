import { resolveApiErrorMessage } from 'api/base';
import notification, { NotificationType } from 'helpers/notification';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { OrderOption } from 'models/order-option';
import quoteApi from 'api/quote';
import categoryApi from 'api/category';
import optionApi from 'api/option';
import { getPayload, getCustomOptionPricePayload } from './get-payload';
import { ApplyOptionsFormActions } from '../reducer';

async function apply(quoteId: number, nameCategories: string[], options: OrderOption[], groups: string[]) {
  const { data: categories } = await categoryApi.getOptionsByCategory({ categories: nameCategories });
  await quoteApi.applyOptions(
    quoteId,
    {
      categories,
      groups,
      options,
    },
  );
}

export function applyOptions(
  dispatch,
  formData: {[x: string]: DropdownOptionModel},
  groups: string[], categories: string[],
  quoteId: number,
  goBack
) {
  return () => {
    dispatch({ type: ApplyOptionsFormActions.START_EDITING });

    const customOptionPricePayload = getCustomOptionPricePayload(formData);
    const onSuccess = () => {
      notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED);
      const message = 'Options Successfully Applied';
      notification({ type: NotificationType.SUCCESS, message });
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: ApplyOptionsFormActions.FINISH_EDITING });
    };
    const getCustomOptionPrice = customOptionPricePayload.length > 0
      ? optionApi.getCustomOptionPrice(customOptionPricePayload)
      : new Promise((resolve) => resolve({ data: [] })) as any;

    return getCustomOptionPrice
      .then(({ data }) => apply(quoteId, categories, getPayload(formData, data), groups))
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
