import React, { FunctionComponent } from 'react';
import { Drawer as AntDrawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

const Drawer: FunctionComponent<DrawerProps> = (props) => {
  return (
    <AntDrawer {...props} />
  );
};

Drawer.defaultProps = {
  width: '70vw',
  zIndex: 1045,
  placement: 'right',
};

export default Drawer;
