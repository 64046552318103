import React, { FunctionComponent } from 'react';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import Drawer from 'components/base-components/Drawer';
import PODataTable from 'components/pages/POItems/List/PODataTable';
import RenderIf from 'components/base-components/RenderIf';
import DatePicker from 'components/base-components/DatePicker';
import Button from 'components/base-components/Button';
import onSubmit from './onSubmit';
import useMaterialsForm from './state';
import { PurchaseOrderListModel } from '../../PurchaseOrders/List/state';
import { permissions } from '../../PurchaseOrders/List/permissions';
import '../styles.scss';

interface Props {
  visible: boolean;
  orderId: number;
  onClose: () => any;
}

const hideIfNoManufacturer = (po: PurchaseOrderListModel) => !po.manufacturer;
const hideIfUnBilled = (po: PurchaseOrderListModel) => !po.bill;
const hideIfBilled = (po: PurchaseOrderListModel) => !!po.bill;
const hiddenExtra = ['search', 'edit'];
const pagination = { pageSize: 3 };

const PurchaseOrderPC: FunctionComponent<Props> = (props) => {
  const { visible, orderId, onClose } = props;
  const {
    state: {
      data,
      fetching,
      count,
      rowSelection,
      page,
      selectedRow,
      purchaseOrderFetching,
      purchaseOrder,
    },
    actions: {
      deleteBill,
      createBill,
      openSendEmailModal,
      handleOnChange,
      printPDF,
      openFormModalPOItems,
      openFormModalPO,
      handleOnClose
    }
  } = useMaterialsForm(orderId, onClose);

  return (
    <Drawer
      title={`Purchase Order - ${orderId}`}
      visible={visible}
      onClose={handleOnClose}
      width="925px"
    >
      <Button onClick={openFormModalPO} label="Add PO" mB />
      <DataTable
        id="project-coordinator-data-table"
        rowKey="id"
        dataSource={data}
        loading={fetching}
        page={page}
        totalRecords={count}
        rowSelection={rowSelection}
        onChange={handleOnChange}
        onSubmit={onSubmit}
        pagination={pagination}
      >
        <DataTableColumn
          title="PO Number"
          dataIndex="id"
          columnKey="id"
          sorter
        />
        <DataTableColumn
          title="Manufacturer"
          dataIndex={['manufacturer', 'companyName']}
          columnKey="manufacturer"
          sorter
        />
        <DataTableColumn
          title="Supplier Ready Date"
          dataIndex="supplierReadyDate"
          columnKey="supplier_ready_date"
          isDate
          sorter
        />
        <DataTableColumn
          title="Date Sent to Supplier"
          dataIndex="dateSent"
          columnKey="date_sent"
          editable
          component={DatePicker}
          sorter
          alwaysShowInput
          allowClear
        />
        <DataTableColumn
          isActionColumn
        >
          <DataTableAction
            hide={hideIfNoManufacturer}
            onClick={openSendEmailModal}
            icon="fa fa-paper-plane"
            permissions={permissions.editOrder}
          />
          <DataTableAction
            hide={hideIfNoManufacturer}
            onClick={printPDF}
            label="Print"
            icon="fa fa-print"
          />
          <DataTableAction
            hide={hideIfBilled}
            onClick={createBill}
            label="Convert to Bill"
            icon="fa fa-receipt"
            permissions={permissions.addBill}
          />
          <DataTableAction
            hide={hideIfUnBilled}
            onClick={deleteBill}
            label="Delete Bill"
            icon="fa fa-receipt"
            permissions={permissions.deleteBill}
            danger
          />
        </DataTableColumn>
      </DataTable>
      <RenderIf isTrue={!!selectedRow.length}>
        <Button onClick={openFormModalPOItems} label="Add PO item" mB />
        <PODataTable
          purchaseOrderId={selectedRow[0]}
          fetchingPurchaseOrder={purchaseOrderFetching}
          purchaseOrder={purchaseOrder}
          hiddenExtra={hiddenExtra}
          pageSize={5}
        />
      </RenderIf>
    </Drawer>
  );
};

export default PurchaseOrderPC;
