/* eslint-disable no-console */
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Affix, Col, Row, Typography } from 'antd';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import RenderIf from 'components/base-components/RenderIf';
import Card from 'components/base-components/Card';
import Spinner from 'components/base-components/Spinner';
import CategoryPanel from './CategoryPanel';
import Stage from './Stage';
import ProductPanel from './PropertiesPanel';
import useBuilderState from './state';
import LeftToolbar from './Toolbar/LeftToolbar';
import FloatProductToolbar from './Toolbar/FloatProductToolbar';
import SaveFlowPlannerForm from './SaveFlowPlanner';
import LoadFlowPlannerForm from './LoadFlowPlanner';
import './styles.scss';

export interface FlowPlannerHistorySateModel {
  flowPlannerForm: {
    flowPlanner: any;
  };
}

const Builder: FunctionComponent = () => {
  const { location: { state } } = useHistory();
  const [showDimensions, setShowDimensions] = useState(true);
  const [snapEnabled, setSnapEnabled] = useState(true);
  const [xRayEnabled, setXRayEnabled] = useState(false);

  const [showCategories, setShowCategories] = useState(false);
  // const [, setShowImportModal] = useState(false);

  const [activeModel, setActiveModel] = useState(null);

  const {
    state: {
      products,
      fetchingProductData,
      selectedProduct,
      fetchingCategories,
      categories,
      builderCategories,
      selectedCategory,
      styles,
      viewMode,
      hiddenPanelContent,
      readOnly,
      loadingStage,
      floors,
      walls,
      wall,
      // floor,
      unit,
      showSaveForm,
      showLoadForm,
      sceneLocked,
      hiddenAction,
      hiddenAddToCart,
      fetchingProductPrice,
      priceCardContent,
      priceCard,
    },
    actions: {
      setSceneLocked,
      toggleShowSaveFlowPlanner,
      toggleShowLoadFlowPlanner,
      selectCategory,
      handleStyleChange,
      selectProduct,
      updateProperties,
      updateOptions,
      removeProduct,
      setViewMode,
      onUnitChange,
      handleSelectWall,
      handleSelectFloor,
      handleWallChange,
      updateSceneOptions,
      openProductListFrom,
      handleFlowPlanner,
      onSave,
      openAddToCartForm,
      onImportFromConfiguratorClicked,
      // handleFloorChange,
    },
  } = useBuilderState((state as any)?.flowPlannerForm?.flowPlanner);

  const stageClassName = useMemo(() => classnames('builder__stage', { loadingStage }), [loadingStage]);

  return (
    <article className="builder">
      <section className="builder__content">
        <Affix offsetTop={10} style={{ position: 'absolute', top: 0, left: 500 }}>
          <Card className="builder_price-card">
            <Col>
              <Row>
                <Typography.Text strong>
                  {priceCardContent.title}
                </Typography.Text>
                <Typography.Text type={priceCardContent.type} className="builder_price-card__item">
                  {priceCardContent.projectName}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text strong>Price:</Typography.Text>
                <Typography.Text className="builder_price-card__item">{` ${priceCard}$`}</Typography.Text>
                <RenderIf isTrue={fetchingProductPrice}>
                  <Spinner size="small" centered={false} className="builder_price-card__item" />
                </RenderIf>
              </Row>
            </Col>
          </Card>
        </Affix>
        <LeftToolbar
          viewMode={viewMode}
          onShow2DPlanner={() => setViewMode('2d')}
          onShow3DViewClicked={() => setViewMode('3d')}
          onShowDimensionsToggled={(visible) => setShowDimensions(visible)}
          onLockSceneToggled={(locked) => setSceneLocked(locked)}
          onSnapToggled={(enabled) => setSnapEnabled(enabled)}
          onXRayToggled={(xRay) => setXRayEnabled(xRay)}
          onAddProductClicked={() => setShowCategories(!showCategories)}
          onImportFromConfiguratorClicked={onImportFromConfiguratorClicked}
          onSaveClicked={onSave}
          onLoadClicked={toggleShowLoadFlowPlanner}
          // onSnapshotClicked={handleSnapshot}
          onUnitChanged={(newUnit) => onUnitChange(newUnit)}
          onSelectStyle={handleStyleChange}
          openAddToCartForm={openAddToCartForm}
          locked={hiddenAction}
          hiddenAddToCart={hiddenAddToCart}
          loading={loadingStage}
        />
        <main className={stageClassName}>
          <RenderIf isTrue={!hiddenAction && showCategories}>
            <CategoryPanel
              blockUI={fetchingProductData}
              fetching={fetchingCategories}
              products={categories}
              roomObjects={builderCategories}
              selectedCategory={selectedCategory}
              onSelectCategory={selectCategory}
              styles={styles}
              onSelectStyle={handleStyleChange}
              hiddenContent={hiddenPanelContent}
            />
          </RenderIf>
          <Stage
            blockUI={loadingStage}
            viewMode={viewMode}
            sceneLocked={sceneLocked}
            showDimensions={showDimensions}
            snapEnabled={snapEnabled}
            xRayEnabled={xRayEnabled}
            models={products}
            onDragDropFinished={handleStyleChange}
            onSelect={(id, rawModel = null) => {
              selectProduct(id);
              setActiveModel(rawModel);
            }}
            onDelete={removeProduct}
            readOnly={readOnly}
            walls={walls}
            floors={floors}
            measureUnit={unit}
            onSelectWall={handleSelectWall}
            onSelectFloor={handleSelectFloor}
            onUnitChange={onUnitChange}
            openProductListFrom={openProductListFrom}
          />
        </main>
        <aside className={`builder__right-container ${selectedProduct || wall ? 'opened' : ''}`}>
          <ProductPanel
            blockUI={fetchingProductData || loadingStage}
            product={selectedProduct}
            onPropertiesChange={updateProperties}
            onOptionsChange={updateOptions}
            hiddenContent={hiddenPanelContent}
            unit={unit}
            wallTextures={walls}
            floorTextures={floors}
            wall={wall}
            onWallChange={handleWallChange}
            onSceneOptionsChange={updateSceneOptions}
          />
          {selectedProduct && <FloatProductToolbar info={activeModel} />}
        </aside>
        <SaveFlowPlannerForm
          products={products}
          visible={showSaveForm}
          goBack={toggleShowSaveFlowPlanner}
        />
        <LoadFlowPlannerForm visible={showLoadForm} submit={handleFlowPlanner} goBack={toggleShowLoadFlowPlanner} />
      </section>
    </article>
  );
};

export default Builder;
