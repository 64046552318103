import { AxiosResponse } from 'axios';
import { OrderDTO } from 'models/order';
import { resolveApiErrorMessage } from 'api/base';
import cncCalcApi, { CutListResponse } from 'api/cnc-calc';
import orderApi from 'api/orders';
import notification, { NotificationType } from 'helpers/notification';
import { CNCListActions } from '../reducer';

export default function calculateCNC(dispatch) {
  return (order: OrderDTO) => {
    dispatch({ type: CNCListActions.ADD_PROCESSING_ORDER, payload: order.id });

    const onSuccess = (response: AxiosResponse<CutListResponse>) => {
      const { data: { xlsx: fileUrl } } = response;
      window.open(fileUrl);
    };

    const onError = (error) => {
      notification({
        message: resolveApiErrorMessage((error as any).response),
        type: NotificationType.ERROR,
      });
    };

    const onFinally = () => {
      dispatch({ type: CNCListActions.REMOVE_PROCESSING_ORDER, payload: order.id });
    };

    return orderApi.get(order.id)
      .then(response => response.data)
      .then(extendedOrder => cncCalcApi.calculate({ orders: [extendedOrder] }))
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
