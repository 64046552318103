import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Label from 'components/base-components/Label';
import { Option, RadioGroup } from 'components/base-components/RadioGroup';
import '../styles.scss';
import classnames from 'classnames';

interface RadioAvatarProps {
  options: any[];
  value: any;
  onChange: () => void;
  square?: boolean;
}

interface RadioAvatarLabelProps {
  item: any;
  square: boolean;
}

const RadioAvatarLabel: FunctionComponent<RadioAvatarLabelProps> = (props) => {
  const { item, square } = props;
  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState(item?.image || 'solid_blue.png');
  const className = useMemo(() => classnames('builder__product-panel__radio-avatar__image', { square, loading }), [loading, square]);
  const onLoad = useCallback(() => setLoading(false), []);
  const onError = useCallback(() => {
    setSrc('solid_blue.png');
  }, []);

  return (
    <div className="builder__product-panel__radio-avatar__label">
      <img
        className={className}
        src={src}
        crossOrigin="anonymous"
        alt={item.label}
        onLoad={onLoad}
        onError={onError}
      />
      <Label text={item.label} />
    </div>
  );
};

const RadioAvatar: FunctionComponent<RadioAvatarProps> = (props) => {
  const { options, value, onChange, square } = props;
  const renderOptions = useMemo(() => options.map(opt => {
    const label = (<RadioAvatarLabel item={opt} square={square} />);
    return (<Option key={opt.name} value={opt} label={label} />);
  }), [options, square]);
  const internalValue = useMemo(() => options.find(e => e.value === value.value), [value, options]);

  return (
    <RadioGroup
      className="builder__product-panel__radio-avatar"
      value={internalValue}
      onChange={onChange}
    >
      {renderOptions}
    </RadioGroup>
  );
};

export default RadioAvatar;
