import { TutorialModel } from 'models/tutorial';
import { ApiContentType } from './base';
import getApi from './base/get-api';

const { REACT_APP_TUTORIAL_API } = process.env;
const endpoint = `${REACT_APP_TUTORIAL_API}/tutorial-video/`;

const tutorialsApi = {
  ...getApi<TutorialModel>(endpoint, ApiContentType.MULTIPART, true),
};

export default tutorialsApi;
