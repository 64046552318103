import React from 'react';
import Button from 'components/base-components/Button';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import './style.scss';

const AddOptionRender = (parent, row) => {
  const onAdd = () => {
    notifyEventChannel(EventChannelList.QUOTE_PAGE_OPEN_CUSTOM_OPTION_MODAL, { parent, row });
  };

  return (
    <Button
      type="link"
      size="middle"
      label="Add a Custom Option "
      onClick={onAdd}
      className="custom-product__add-custom-option"
    />
  );
};

export default AddOptionRender;
