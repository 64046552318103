import { useEffect, useMemo } from 'react';
import moment from 'moment';
import shipmentApi from 'api/shipment';
import installerBookingApi from 'api/installer-booking';
import { EventChannelList } from 'helpers/event-center';
import { formatDateTimeToString } from 'helpers/formatting';
import notification, { NotificationType } from 'helpers/notification';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { validationRules } from './validations';

const defaultInstaller = {
  project: null,
  installer: null,
  installationDate: (
    moment()
      .utcOffset('z')
      .set('hour', 9)
      .set('minutes', 0)
      .set('seconds', 0)
  ),
};

const handleFormData = (installerBooking) => {
  return () => {
    if (installerBooking) {
      return {
        id: installerBooking.id,
        project: {
          value: installerBooking.shipment.project,
          label: installerBooking.jobTitle,
        },
        installer: {
          value: installerBooking.installer.id,
          label: installerBooking.installer.companyName
        },
        installationDate: moment(installerBooking.installationDate),
      };
    }
    return defaultInstaller;
  };
};

const getPayload = (installer) => {
  return {
    id: installer.id,
    project: installer.project.value,
    installer: installer.installer.value,
    installationDate: formatDateTimeToString(installer.installationDate)
  };
};

function onSave(payload) {
  const { project, ...rest } = payload;
  const queryParams = {
    filters: {
      project,
    }
  };

  return shipmentApi.list(queryParams)
    .then((response) => {
      const action = rest.id
        ? installerBookingApi.update
        : installerBookingApi.create;

      return action({
        ...rest,
        shipment: response?.data.results[0].id,
      });
    });
}

export default function useInstallerBookingFormState(installerBookingEntity) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: installerBookingApi,
    eventChannel: EventChannelList.INSTALLER_BOOKING_LIST_CHANGE,
    getPayload,
    getFormData: handleFormData(installerBookingEntity),
    validationRules,
    replaceAction: onSave,
  }), [installerBookingEntity]);

  const {
    state: {
      formData: installerBooking,
      errors,
      saving,
      errorFetchingEntity: errorFetchingInstaller,
      fetchingEntity: fetchingInstallerBooking,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState(useFormStateParams);

  useEffect(() => {
    if (errorFetchingInstaller) {
      notification({
        type: NotificationType.ERROR,
        message: errorFetchingInstaller,
      });
    }
  }, [errorFetchingInstaller]);

  return {
    state: {
      installerBooking,
      savingInstallerBooking: saving,
      fetchingFormData: fetchingInstallerBooking,
      errors,
    },
    actions: {
      setFormValue,
      saveInstallerBooking: saveEntity,
      setErrors,
    },
  };
}
