import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Spinner from 'components/base-components/Spinner';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import NumberInput from 'components/base-components/NumberInput';
import Select from 'components/base-components/Select';
import { validationRules } from './validations';
import usePropertyFormState from './state';

export interface PropertyFormHistorySateModel {
  propertyForm: {
    propertyUrl: string;
  };
}

const propertyTypesOptions = [
  { value: 'product_range', label: 'product range' },
  { value: 'range', label: 'range' },
  { value: 'select', label: 'select' },
  { value: 'style', label: 'style' },
  { value: 'group', label: 'group' },
];

const PropertyForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { propertyForm: { propertyUrl } } = state as PropertyFormHistorySateModel;

  const {
    state: {
      saving,
      formData,
      fetchingEntity,
      errors,
    },
    actions: {
      setFormValue,
      saveEntity,
      setErrors,
    },
  } = usePropertyFormState(propertyUrl);

  const modalTitle = useMemo(
    () => propertyUrl ? 'Edit Property' : 'Create Property',
    [propertyUrl],
  );

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveEntity}
      confirmLoading={saving}
    >
      <RenderIf isTrue={!fetchingEntity} fallback={<Spinner />}>
        <Form
          state={formData}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            id="name"
            name="name"
            label="Name"
            placeholder="Enter Name"
            disabled={propertyUrl}
          />
          <Field
            id="propertyTypes"
            name="propertyTypes"
            label="Property Types"
            placeholder="Enter Property Types"
            component={Select}
            options={propertyTypesOptions}
          />
          <Field
            name="increment"
            id="increment"
            label="Increment"
            component={NumberInput}
            allowDecimals
            min={0}
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default PropertyForm;
