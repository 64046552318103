import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'components/base-components/ModalV2';

export interface PartDetailsHistorySateModel {
  partDetail: {
    image: string;
    description: string;
  };
}

const PartDetails: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();
  const { partDetail: { description, image } } = state as PartDetailsHistorySateModel;

  return (
    <Modal
      title="Part Detail"
      visible
      onCancel={goBack}
      footer={null}
      width="70%"
    >
      <div className="table__details">
        <img src={image} alt="product" />
      </div>
      <p>{description}</p>
    </Modal>
  );
};

export default PartDetails;
