const randomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export function buildDatasets(data, label, axisY, pieFrame, colors, setColor) {
  const isBar = !!axisY;
  const isPie = !!pieFrame;
  const labels = [];
  const pieValues = [];
  const pieColors = [];
  const hasColors = colors.length > 0;
  const newColors = [];
  const barDataSets = isBar
    ? axisY.map(({ borderColor, title }) => ({
      borderColor,
      title,
      values: [],
    }))
    : undefined;

  data.forEach((item, dataIndex) => {
    labels.push(item[label]);
    if (isBar) {
      axisY.forEach(({ title }, index) => {
        barDataSets[index].values = barDataSets[index].values.concat(item[title]);
      });
    }
    if (isPie) {
      pieValues.push(item[pieFrame]);
      const color = hasColors ? colors[dataIndex] : randomColor();
      pieColors.push(color);
      if (!hasColors) {
        newColors.push(color);
      }
    }
  });

  if (!hasColors) {
    setColor(newColors);
  }

  return {
    labels,
    barDataSets,
    pieDataset: {
      pieValues,
      pieColors,
    },
  };
}
