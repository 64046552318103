import { useEffect, useMemo } from 'react';
import { DropdownOptionModel, mapGenericDropdownData, mapGenericDropdownOptions } from 'helpers/dropdown-options';
import { OptionModel } from 'models/option';
import useFormState, { UseFormStateParams } from 'hooks/ui/form-state';
import { EventChannelList } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import optionApi from 'api/option';
import { isValidFile, mapFileFromUrl } from 'helpers/file';
import { ManufacturerModel } from 'models/manufacturer';

interface OptionFormModel {
  name: string;
  nameFr: string;
  description: string;
  descriptionFr: string;
  code: string;
  brand: DropdownOptionModel;
  manufacturer: DropdownOptionModel;
  userInput: boolean;
  price: string;
  cost: string;
  image: File[];
  textureHeight: string;
  textureWidth: string;
}

const defaultOption: OptionFormModel = {
  name: '',
  nameFr: '',
  description: '',
  descriptionFr: '',
  code: '',
  brand: undefined,
  manufacturer: undefined,
  userInput: false,
  price: '0',
  cost: '0',
  image: [],
  textureHeight: '0',
  textureWidth: '0',
};

const getPayload = (option: OptionFormModel) => {
  const { price, cost, brand, manufacturer, image, ...rest } = option;
  return {
    ...rest,
    price: parseFloat(price),
    cost: parseFloat(cost),
    manufacturer: manufacturer ? manufacturer.value : undefined,
    brand: brand ? brand.value : undefined,
    image: image && isValidFile(image[0]) ? image : undefined,
  };
};

const getFormData = (option: OptionModel) => {
  if (option) {
    const { brand, manufacturer, image, ...rest } = option;

    return {
      ...rest,
      brand: mapGenericDropdownData(brand, { fields: { value: 'brand', label: 'brand' } }),
      manufacturer: mapGenericDropdownData(manufacturer, { fields: { value: 'url', label: 'companyName' } }),
      image: mapFileFromUrl(image),
    };
  }

  return defaultOption;
};

export function mapManufacturersOptions(manufacturers: ManufacturerModel[]) {
  return mapGenericDropdownOptions(manufacturers, { fields: { value: 'url', label: 'companyName' } });
}

export default function useOptionFormState(optionUrl: string) {
  const useFormStateParams: UseFormStateParams = useMemo(() => ({
    api: optionApi,
    eventChannel: EventChannelList.OPTIONS_LIST_CHANGED,
    param: optionUrl,
    getPayload,
    getFormData,
  }), [optionUrl]);

  const {
    state: {
      formData: option,
      errors,
      saving,
      errorFetchingEntity: errorFetchingOption,
      fetchingEntity: fetchingOption,
    },
    actions: {
      setFormValue,
      setErrors,
      saveEntity,
    }
  } = useFormState<OptionModel>(useFormStateParams);

  useEffect(() => {
    if (errorFetchingOption) {
      notification({ type: NotificationType.ERROR, message: errorFetchingOption });
    }
  }, [errorFetchingOption]);

  return {
    state: {
      option,
      savingOption: saving,
      fetchingFormData: fetchingOption,
      errors,
    },
    actions: {
      setFormValue,
      saveOption: saveEntity,
      setErrors,
    },
  };
}
