import React from 'react';
import { useHistory } from 'react-router';
import { Row } from 'antd';
import DateTable from 'components/base-components/DataTableV2';
import DateTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import Upload from 'components/base-components/Upload';
import PopConfirm from 'components/base-components/PopConfirm';
import Modal from 'components/base-components/ModalV2';
import useCustomProductState from './state';
import EditableRow from './editabeRow';
import EditableCell from './editableCell';
import './style.scss';

export interface CustomProductHistorySateModel {
  customProductForm: {
    quote: number;
    groups: any[];
    discount: number;
  };
}
const scroll = { x: '100%' };
const width = { width: 150 };
const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

export default function CustomProduct() {
  const { goBack, location: { state } } = useHistory();

  const { customProductForm } = state as CustomProductHistorySateModel;
  const {
    state: {
      data,
      excel,
      adding,
      reading,
      rowError,
      options,
      getting,
    },
    actions: {
      handleOnSubmit,
      handleAdd,
      handleSave,
      handleDelete,
      onChangeExcel,
      getTemplate,
    },
  } = useCustomProductState(customProductForm);

  const renderActions = (_text, record) => data.length >= 1 ? (
    <PopConfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
      <Button type="default" icon="fas fa-trash" />
    </PopConfirm>
  ) : null;

  const onCellModel = record => ({
    record,
    editable: true,
    dataIndex: 'model',
    title: 'Model',
    type: 'input',
    handleSave,
  });
  const onCellName = record => ({
    record,
    editable: true,
    dataIndex: 'name',
    title: 'Name',
    type: 'inputTextArea',
    handleSave,
  });
  const onCellMPN = record => ({
    record,
    editable: true,
    dataIndex: 'mpn',
    title: 'MPN',
    type: 'input',
    handleSave,
  });
  const onCellOptions = record => ({
    record,
    editable: true,
    dataIndex: 'options',
    title: 'Options',
    type: 'treeSelect',
    handleSave,
  });
  const onCellManufacturer = record => ({
    record,
    editable: true,
    dataIndex: 'manufacturer',
    title: 'Manufacturer',
    type: 'asyncSelect',
    handleSave,
  });
  const onCellCnc = record => ({
    record,
    editable: true,
    dataIndex: 'cnc',
    title: 'cnc',
    type: 'checkbox',
    handleSave,
  });
  const onCellOutsidePaint = record => ({
    record,
    editable: true,
    dataIndex: 'outsidePaint',
    title: 'Op',
    type: 'checkbox',
    handleSave,
  });
  const onCellPrice = record => ({
    record,
    editable: true,
    dataIndex: 'price',
    title: 'Price',
    type: 'inputNumber',
    handleSave,
  });
  const onCellCost = record => ({
    record,
    editable: true,
    dataIndex: 'cost',
    title: 'Cost',
    type: 'inputNumber',
    handleSave,
  });
  const onCellQty = record => ({
    record,
    editable: true,
    dataIndex: 'quantity',
    title: 'Qty',
    type: 'inputNumber',
    handleSave,
  });
  const onCellGroup = record => ({
    record,
    editable: true,
    dataIndex: 'group',
    title: 'Group',
    type: 'select',
    options,
    handleSave,
  });
  return (
    <Modal
      title="Add Product"
      visible
      okText="Submit"
      onOk={handleOnSubmit}
      confirmLoading={adding}
      width="90vw"
      onCancel={goBack}
      maskClosable={false}
    >
      <Row justify="space-between">
        <Button onClick={handleAdd} icon="fa fa-plus" label="Add a row" mB />
        <Row>
          <Button onClick={getTemplate} icon="fa fa-print" loading={getting} label="Get Template" mB mR />
          <div style={width}>
            <Upload
              onChange={onChangeExcel}
              value={excel}
              accept=".xls"
              loading={reading}
            />
          </div>
        </Row>
      </Row>
      <DateTable
        components={components}
        dataSource={data}
        size="small"
        tableLayout="fixed"
        scroll={scroll}
        rowClassName={(record) => rowError.includes(record.key) ? 'table-row-error-validations' : null}
        pagination={{ defaultPageSize: 5 }}
      >
        <DateTableColumn
          title="Model"
          dataIndex="model"
          width={220}
          onCell={onCellModel}
        />
        <DateTableColumn
          title="Name"
          dataIndex="name"
          width={230}
          onCell={onCellName}
        />
        <DateTableColumn
          title="Options"
          dataIndex="options"
          width={200}
          onCell={onCellOptions}
        />
        <DateTableColumn
          title="MPN"
          dataIndex="mpn"
          width={150}
          onCell={onCellMPN}
        />
        <DateTableColumn
          title="Manufacturer"
          dataIndex="manufacturer"
          width={200}
          onCell={onCellManufacturer}
        />
        <DateTableColumn
          title="cnc"
          dataIndex="cnc"
          align="center"
          width={50}
          onCell={onCellCnc}
        />
        <DateTableColumn
          title="Op"
          dataIndex="outsidePaint"
          width={50}
          align="center"
          onCell={onCellOutsidePaint}
        />
        <DateTableColumn
          title="Price"
          dataIndex="price"
          width={60}
          onCell={onCellPrice}
        />
        <DateTableColumn
          title="Cost"
          dataIndex="cost"
          width={60}
          onCell={onCellCost}
        />
        <DateTableColumn
          title="Qty"
          dataIndex="quantity"
          width={50}
          onCell={onCellQty}
        />
        <DateTableColumn
          title="Group"
          dataIndex="group"
          width={150}
          onCell={onCellGroup}
        />
        <DateTableColumn
          title="Action"
          dataIndex="operation"
          render={renderActions}
          width={60}
        />
      </DateTable>
    </Modal>
  );
}
