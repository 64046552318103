import React, { FunctionComponent, useMemo } from 'react';
import { Table } from 'antd';
import { AxiosResponse } from 'axios';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import useMediaQueries from 'hooks/ui/use-media-queries';
import RenderIf from 'components/base-components/RenderIf';
import DataTableHeader from './Header';
import getColumns from './Column/get-columns';
import TrimAtColumnRender from './Column/TrimAtColumnRender';
import EditableCell from './EditableCell';
import DataTableColumn from './Column';
import DataTableAction from './Column/Action';
import { gatherColumnQueries, queryBuilder } from './utils';
import './styles.scss';

export const { Summary } = Table;
export { DataTableColumn, DataTableAction, TrimAtColumnRender };

interface Props extends TableProps<any>{
  totalRecords?: number;
  page?: number;
  onFilter?: (filter: string) => void;
  onSubmit?: (payload, dataIndex?) => Promise<AxiosResponse>;
  onSubmitSuccess?: (payload) => void;
  loading?: boolean;
  defaultFilter?: string;
}

const bodyDefinition = { body: { cell: EditableCell } };
const showPaginationTotal = (total, range) => (
  `${range[0]}-${range[1]} of ${total} items`
);

const DataTable: FunctionComponent<Props> = (props) => {
  const {
    children,
    totalRecords,
    pagination,
    onFilter,
    page,
    onSubmit,
    onSubmitSuccess,
    loading,
    defaultFilter,
    ...restProps
  } = props;

  const mediaQueryPoints = useMemo(() => gatherColumnQueries(children), [children]);
  const queryResults = useMediaQueries(mediaQueryPoints, queryBuilder);

  const paginationOptions: TablePaginationConfig = {
    total: totalRecords,
    showTotal: showPaginationTotal,
    current: page,
    showSizeChanger: false,
    ...pagination,
  };

  const paginationProp = totalRecords || page ? paginationOptions : pagination;

  return (
    <div className="data-table__wrapper">
      <RenderIf isTrue={!!onFilter}>
        <DataTableHeader onFilter={onFilter} loading={loading} defaultValue={defaultFilter} />
      </RenderIf>
      <Table
        pagination={paginationProp}
        components={bodyDefinition}
        loading={loading}
        {...restProps}
      >
        {getColumns(children, onSubmit, onSubmitSuccess, queryResults)}
      </Table>
    </div>
  );
};

DataTable.defaultProps = {
  bordered: true,
  scroll: {
    x: 'max-content',
  },
  rowKey: 'id',
};

export default DataTable;
