import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CutListDTOModel } from 'models/cut-list';
import cutListApi from 'api/cut-list';
import useTableState, { UseTableStateParams } from 'hooks/ui/table-state';
import { EventChannelList } from 'helpers/event-center';
import Help from 'components/forms/CommonHelp';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import { CutListFormHistorySateModel } from '../Form';
import { permissions } from './permissions';
import downloadCutSheet from './download-cut-sheet';
import uploadCutSheet from './upload-cut-sheet';

const useTableStateParams: UseTableStateParams = {
  api: cutListApi,
  deleteModalTitle: 'Delete Cutlist',
  deleteModalContent: (cutListToDelete: CutListDTOModel) => `Are you sure that you want to delete the Cutlist ${cutListToDelete ? cutListToDelete.name : ''}?`,
  searchFields: ['name'],
  eventChannel: EventChannelList.CUT_LIST_CHANGED,
};

const percentageRender = (value) => value % 1 !== 0 ? `${parseFloat(value || 0).toFixed(2)}%` : `${value}%`;

const CutList: FunctionComponent = () => {
  const { push } = useHistory();
  const inputFilesRef = useRef(undefined);
  const [rowToUpLoad, setRowToUpLoad] = useState(null);
  const [exelValue, setExelValue] = useState('');
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleOnChange,
      handleFilter,
      handleDeleteRow,
    }
  } = useTableState<CutListDTOModel>(useTableStateParams);

  const openCutListFormModal = (cutList?: CutListDTOModel | any) => {
    const historyLocationState: CutListFormHistorySateModel = {
      cutListForm: {
        cutListId: cutList ? cutList.id : undefined,
      },
    };
    push(ModalRouteHash.CutListFrom, historyLocationState);
  };
  const handleSelectFiles = useCallback(uploadCutSheet(rowToUpLoad, setExelValue, setRowToUpLoad), [rowToUpLoad]);

  const uploadCutsheet = useCallback((dataRow) => {
    setRowToUpLoad(dataRow.id);
    inputFilesRef.current.click();
  }, []);

  const goToDetails = useCallback((cutlist: CutListDTOModel) => {
    push(`/cutlist/${cutlist.id}`);
  }, [push]);

  return (
    <Page title="Cutlist">
      <Help view="cutlist" title="CutList" />
      <PageActions primary>
        <AccessControl permissions={permissions.addCutList}>
          <Button
            onClick={openCutListFormModal}
            label="Add Cutlist"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="name" columnKey="name" title="Name" sorter />
        <DataTableColumn dataIndex="sourcesCount" columnKey="sources_count" title="Sources" sorter />
        <DataTableColumn dataIndex="partsCount" columnKey="parts_count" title="Parts" sorter />
        <DataTableColumn dataIndex="percentageCut" columnKey="percentage_cut" title="Percentage" render={percentageRender} sorter />
        <DataTableColumn
          isActionColumn
          editPermissions={permissions.editCutList}
          deletePermissions={permissions.deleteCutList}
          viewPermissions={permissions.viewCutList}
          onShowDetails={goToDetails}
          onEdit={openCutListFormModal}
          onDelete={handleDeleteRow}
        >
          <DataTableAction
            onClick={downloadCutSheet}
            icon="fa fa-download"
            label="Download Cutsheet"
            permissions={permissions.editCutList}
          />
          <DataTableAction
            onClick={uploadCutsheet}
            icon="fa fa-upload"
            label="Upload Cutsheet"
            permissions={permissions.editCutList}
          />
        </DataTableColumn>
      </DataTable>
      <input
        id="upload-cut-list"
        style={{ display: 'none' }}
        type="file"
        ref={inputFilesRef}
        onChange={handleSelectFiles}
        accept=".xls, .xlsx"
        value={exelValue}
      />
    </Page>
  );
};

export default CutList;
