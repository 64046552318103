import React, { FunctionComponent } from 'react';
import { Paragraph } from 'components/base-components/Typography';
import HelpModal from 'components/experience/HelpModal';
import TutorialsTable from 'components/experience/TutorialsTable';

interface Props {
  isAnOrder: boolean;
}
const Help: FunctionComponent<Props> = (props) => {
  const { isAnOrder } = props;
  const view = `${isAnOrder ? 'order' : 'quote'}-details`;

  return (
    <HelpModal title="Quote Details" name={view} className="quote-view__help">
      <Paragraph>
        This page is used to manage all products related to a specific quote,
        as well as any charges or special instructions.
        The table below show a lists videos
        to walk you through the how-to of almost all
        the actions available in this page
      </Paragraph>
      <TutorialsTable view={view} />
    </HelpModal>
  );
};

export default Help;
