import { AccountApplicationTableActions } from '../reducer';

export function handleFilter(dispatch) {
  return (filter: any) => {
    dispatch({
      type: AccountApplicationTableActions.HANDLE_FILTER_CHANGE,
      payload: filter,
    });
  };
}
