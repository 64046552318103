import React, { FunctionComponent, useMemo } from 'react';
import { Summary } from 'components/base-components/DataTableV2';
import Button from 'components/base-components/Button';
import AccessControl from 'components/base-components/AccessControl';
import RenderIf from 'components/base-components/RenderIf';
import { formatCurrency } from 'helpers/formatting';
import { ExtraChargeModel } from 'models/extra-charge';
import { AccountTaxModel } from 'models/account';
import Taxes from './Taxes';
import { calculateSubTotal, calculateTotal } from './util';
import ExtraCharges from './ExtraCharges';
import { permissions } from '../../permissions';

interface Props {
  orderTotal: number;
  shippingPrice: number;
  taxes: AccountTaxModel[];
  colSpan: number;
  extraCharges: ExtraChargeModel[];
  handleAddExtraCharge: any;
  handleRemoveExtraCharge: any;
  handleExtraChargeChanged: any;
  deletingExtraChargeIndex: number;
  isDealer: boolean;
}

const QuoteProductsListSummary: FunctionComponent<Props> = (props) => {
  const {
    orderTotal,
    shippingPrice,
    taxes,
    colSpan,
    extraCharges,
    handleAddExtraCharge,
    handleRemoveExtraCharge,
    handleExtraChargeChanged,
    deletingExtraChargeIndex,
    isDealer
  } = props;

  const lastChargeIsEmpty = useMemo(
    () => (
      extraCharges.length &&
      !extraCharges[extraCharges.length - 1].chargeName
    ),
    [extraCharges],
  );

  const total = useMemo(() => {
    return calculateTotal(taxes, shippingPrice, orderTotal, extraCharges);
  }, [taxes, shippingPrice, orderTotal, extraCharges]);

  const subTotal = useMemo(
    () => calculateSubTotal(orderTotal, extraCharges, shippingPrice),
    [orderTotal, extraCharges, shippingPrice],
  );

  const totalColSpan = useMemo(() => isDealer ? colSpan + 1 : colSpan, [isDealer, colSpan]);

  return (
    <>
      <Summary.Row className="quote-products-table_group-header__row">
        <Summary.Cell index={0} colSpan={totalColSpan}>
          Total
        </Summary.Cell>
        <RenderIf isTrue={!isDealer}>
          <Summary.Cell
            index={1}
            align="center"
          >
            <AccessControl permissions={permissions.addExtraCharge}>
              <Button
                type="default"
                id="add-charge"
                label="Add Charge"
                onClick={handleAddExtraCharge}
                disabled={lastChargeIsEmpty}
              />
            </AccessControl>
          </Summary.Cell>
        </RenderIf>
      </Summary.Row>
      <Summary.Row className="quote-products-table__summary-row">
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          Products Total
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          {formatCurrency(orderTotal)}
        </Summary.Cell>
      </Summary.Row>
      <ExtraCharges
        extraCharges={extraCharges}
        colSpan={colSpan}
        removeCharge={handleRemoveExtraCharge}
        handleExtraChargeChanged={handleExtraChargeChanged}
        deletingExtraChargeIndex={deletingExtraChargeIndex}
      />
      <Summary.Row className="quote-products-table__summary-row">
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          Deliver & Install
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          {formatCurrency(shippingPrice)}
        </Summary.Cell>
      </Summary.Row>
      <Summary.Row
        className="quote-products-table__summary-row quote-product-list__totals"
      >
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          Sub-Total
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          {formatCurrency(subTotal)}
        </Summary.Cell>
      </Summary.Row>
      <Taxes
        orderTotal={orderTotal}
        shippingPrice={shippingPrice}
        taxes={taxes}
        colSpan={colSpan}
        extraCharges={extraCharges}
      />
      <Summary.Row
        className="quote-products-table__summary-row quote-product-list__totals"
      >
        <Summary.Cell index={0} colSpan={colSpan} align="right">
          Total
        </Summary.Cell>
        <Summary.Cell index={1} align="right">
          {formatCurrency(total)}
        </Summary.Cell>
      </Summary.Row>
    </>
  );
};

export default QuoteProductsListSummary;
