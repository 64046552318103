import { TaskSubscriptionModel } from 'models/task-subscription';
import { ApiContentType, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_TASKS_API } = process.env;
const endpoint = `${REACT_APP_TASKS_API}/subscription/`;

const taskSubscriptionApi: ApiModel<TaskSubscriptionModel> = {
  ...getApi(endpoint, ApiContentType.JSON, true),
};

export default taskSubscriptionApi;
