import { AxiosResponse } from 'axios';
import snakeCaseKeys from 'snakecase-keys';
import { PurchaseOrderModel } from 'models/order';
import { QueryParams } from 'helpers/query-params';
import { ApiContentType, ApiModel, barePatch, get, ListResponseModel, post } from './base';
import getApi from './base/get-api';
import { getEmailPayload } from './base/get-email-payload';
import downloadBlob from './base/download-blob';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/purchaseorders/`;

interface PurchaseOrderApiModel extends ApiModel<PurchaseOrderModel> {
  sendEmail: (purchaseOrderId, recipients, manufacturerData) => Promise<AxiosResponse>;
  getPDF: (purchaseOrderId) => Promise<void>;
  emailTemplate: (purchaseOrderId) => Promise<AxiosResponse>;
  listIncomplete: (params: QueryParams) => Promise<AxiosResponse<ListResponseModel<PurchaseOrderModel>>>;
}

const purchaseOrderApi: PurchaseOrderApiModel = {
  ...getApi<PurchaseOrderModel>(endpoint, ApiContentType.MULTIPART, true),
  patch(payload) {
    const body = snakeCaseKeys(payload, { deep: false }) as any;
    return barePatch(`${endpoint}${body.id}/`, body);
  },
  sendEmail(purchaseOrderId, recipients, manufacturerData) {
    const payload = getEmailPayload(recipients);
    return post(`${endpoint}${purchaseOrderId}/email/`, { ...payload, ...manufacturerData });
  },
  getPDF(purchaseOrderId: number) {
    return downloadBlob(
      `${endpoint}${purchaseOrderId}/view/`,
      `Purchase Order ${purchaseOrderId}.pdf`
    );
  },
  emailTemplate(purchaseOrderId: number) {
    return get(`${REACT_APP_ORDERS_API}/email/default-text/purchaseorders/${purchaseOrderId}/`);
  },
  listIncomplete(params) {
    return get(`${endpoint}incomplete/`, params);
  },
};

export default purchaseOrderApi;
