import React, { FunctionComponent } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PageHeader from '../PageHeader';
import './styles.scss';

export * from './PageActions';

interface Props {
  title?: string;
  href?: string;
  contentClassName?: string;
}

const contentSections = {
  content: [],
  mainActions: null,
  secondaryActions: null,
};

function resolveSections(children) {
  return React.Children.toArray(children).reduce(
    (sections: any, child: any) => {
      const {
        props: {
          isPageAction,
          primary,
          children: childContent,
        },
      } = child;

      if (isPageAction) {
        if (primary) {
          return { ...sections, mainActions: childContent };
        }
        return { ...sections, secondaryActions: childContent };
      }

      return { ...sections, content: sections.content.concat([child]) };
    },
    contentSections,
  );
}

const Page: FunctionComponent<Props> = (props) => {
  const { children, contentClassName, ...headerProps } = props;
  const { content, mainActions, secondaryActions } = resolveSections(children);

  return (
    <section className="page">
      <section className="page-content container-fluid">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <PageHeader
                  {...headerProps}
                  mainActions={mainActions}
                  secondaryActions={secondaryActions}
                />
                <main className={contentClassName}>
                  {content}
                </main>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default Page;
