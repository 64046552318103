import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import Page, { PageActions } from 'components/base-components/Page';
import Button from 'components/base-components/Button';
import Help from 'components/forms/CommonHelp';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import AccessControl from 'components/base-components/AccessControl';
import { clientInfoTemplate, salesPersonTemplate } from 'components/pages/Orders/List/template';
import useCNCListState from './state';
import { permissions } from './permissions';

const calcIcon = <i className="fa fa-calculator" />;
const helpDescription = 'This view is to get cutlists and the cnc list manually or from the system as a PDF file';

const CNCCalc: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      fetching,
      data,
      rowSelection,
      selectedRows,
      calculatingBulk,
      linkToOrder
    },
    actions: {
      handleOnChange,
      handleFilter,
      calculate,
      calculateBulk,
      checkIsProcessingOrder,
    },
  } = useCNCListState();

  const openCNCCalculatorModal = () => {
    push('#cnc-calc');
  };

  return (
    <Page title="CNC Calc">
      <Help view="cnc-calc" title="CNC Calc" description={helpDescription} />
      <PageActions primary>
        <AccessControl permissions={permissions.calculate}>
          <Button
            label="Calculate Manually"
            onClick={openCNCCalculatorModal}
            disabled={calculatingBulk}
          />
        </AccessControl>
        <AccessControl permissions={permissions.calculate}>
          <Button
            label="Generate CNC Cutlist"
            onClick={calculateBulk}
            loading={calculatingBulk}
            disabled={selectedRows.length === 0}
          />
        </AccessControl>
      </PageActions>
      <DataTable
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={data}
        loading={fetching}
        onChange={handleOnChange}
        onFilter={handleFilter}
      >
        <DataTableColumn columnKey="id" dataIndex="id" title="Order ID" link={linkToOrder} />
        <DataTableColumn columnKey="client" title="Client" render={clientInfoTemplate} />
        <DataTableColumn columnKey="companyName" dataIndex="companyName" title="Company" />
        <DataTableColumn
          columnKey="job-title"
          title="Job Title"
          dataIndex="jobTitle"
          showBreakpoint={762}
        />
        <DataTableColumn
          title="Sales Person"
          render={salesPersonTemplate}
          columnKey="rep-owner"
          showBreakpoint={1147}
        />
        <DataTableColumn
          columnKey="ready-date"
          title="Ready Date"
          dataIndex="readyDate"
          isDate
          showBreakpoint={1241}
        />
        <DataTableColumn
          title="CNC Items"
          dataIndex="cncItemCount"
          columnKey="cnc-count"
          showBreakpoint={1350}
        />
        <DataTableColumn isActionColumn>
          <DataTableAction
            onClick={calculate}
            icon={calcIcon}
            loading={checkIsProcessingOrder}
            permissions={permissions.calculate}
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default CNCCalc;
