import React, { FunctionComponent } from 'react';
import userApi from 'api/user';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import DatePicker from 'components/base-components/DatePicker';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import { mapUsersOptions } from './common';
import '../styles.scss';

interface Props{
  onChange: (value: any) => any;
  state: {};
  baseKey?: string;
}

const salesRepresentativeSelectFilter = { isStaff: true };

const FiltersForm: FunctionComponent<Props> = (props) => {
  const { state, onChange, baseKey } = props;
  return (
    <Form
      onChange={onChange}
      state={state}
      className="report-chart__filters"
    >
      <Field
        component={DatePicker}
        name="startDate"
        id={`${baseKey}StartDate`}
        label="Start Date"
      />
      <Field
        component={DatePicker}
        name="endDate"
        id={`${baseKey}EndDate`}
        label="End Date"
      />
      <Field
        component={AsyncSelectV2}
        name="repOwner"
        id={`${baseKey}RepOwner`}
        label="Rep Owner"
        listAction={userApi.list}
        mapFunction={mapUsersOptions}
        filters={salesRepresentativeSelectFilter}
        allowClear
      />
    </Form>
  );
};

export default FiltersForm;
