import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: undefined,
};

const Account = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    registerUserStart(state) {
      state.user = null;
      state.loading = true;
      state.registrationError = null;
    },
    registerUserSuccessful(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.registrationError = null;
    },
    registerUserFailed(state, action) {
      state.user = null;
      state.loading = false;
      state.registrationError = action.payload;
    }
  }
});

const { registerUserStart, registerUserSuccessful } = Account.actions;
export const { registerUserFailed } = Account.actions;

// Is user register successful then direct plot user in redux.
export const registerUser = (_user) => async dispatch => {
  try {
    dispatch(registerUserStart());
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      // TODO: this should be changed
      // const response = await fireBaseBackend.registerUser(user.email, user.password);
      dispatch(registerUserSuccessful(undefined));
    } else {
      // TODO: this should be changed
      // const response = await postRegister('/post-register', user);
      dispatch(registerUserSuccessful(undefined));
    }
  } catch (error) {
    dispatch(registerUserFailed(error));
  }
};

export default Account.reducer;
