import { GetListOptions } from 'hooks/base/get-list';

export const columnOptions = [
  { value: 'id', label: 'Order' },
  { value: 'job_title', label: 'Job Title' },
  { value: 'rep_owner', label: 'Sales Person' },
  { value: 'company', label: 'Company' },
  { value: 'client', label: 'Customer' },
  { value: 'order_status', label: 'Status' },
  { value: 'totalWithTax', label: 'Total' },
  { value: 'balance', label: 'Balance' },
  { value: 'created_date', label: 'Date Added' },
  { value: 'ready_date', label: 'Est. Ready Date' },
];

export const breakpoints = {
  id: 0,
  job_title: 0,
  rep_owner: 1211,
  company: 1393,
  client: 1497,
  order_status: 0,
  totalWithTax: 1604,
  created_date: 1735,
  balance: 838,
  ready_date: 1890,
};

export const parentColumns = {};

export const orderStatusMapOptions = { fields: { value: 'id', label: 'status' } };

export const orderStatusParams: GetListOptions = {
  queryParams: {
    filters: {
      ordering: 'sort_order',
    }
  },
};
