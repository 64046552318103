import React from 'react';
import { useHistory } from 'react-router';
import Modal from 'components/base-components/ModalV2';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import useCreateProject from './state';
import validationRules from './state/validations';

export default function CreateProject() {
  const { goBack, location: { state } } = useHistory();

  const {
    state: {
      formData,
      creating,
      errors,
    },
    actions: {
      handleValueChange,
      handleOnSubmit,
      setValidationErrors,
    },
  } = useCreateProject(state);

  return (
    <Modal
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={handleOnSubmit}
      confirmLoading={creating}
      title="Create Project"
    >
      <Form
        state={formData}
        onChange={handleValueChange}
        errors={errors}
        onError={setValidationErrors}
        rules={validationRules}
      >
        <Field
          id="jobTitle"
          name="jobTitle"
          label="Job Title"
          placeholder="Enter Title for New Project"
        />
      </Form>
    </Modal>
  );
}
