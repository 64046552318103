import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { TimePicker as AntTimePicker } from 'antd';
import Label from 'components/base-components/Label';
import './styles.scss';

interface Props {
  label: string;
  value: string;
  onChange: (event) => void;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  inputReadOnly?: boolean;
  showNow?: boolean;
  renderExtraFooter?: (mode: string) => ReactNode;
  mR?: boolean;
  className?: string;
  inputClassName?: string;
  format?: string;
}

const TimePicker: FunctionComponent<Props> = (props) => {
  const { label, mR, className, inputClassName, value, onChange, format, ...rest } = props;

  const datepickerClassNames = useMemo(
    () => classnames('time-picker', { mR }, className),
    [className, mR]
  );

  const antInputClassName = useMemo(
    () => classnames('time-picker__input', inputClassName),
    [inputClassName]
  );

  const parsedValue = useMemo(() => value ? moment(value, format) : null, [value, format]);

  const handleOnChange = useCallback(
    (date) => {
      onChange({
        target: {
          value: format ? date.format(format) : date,
        },
      });
    },
    [onChange, format]
  );

  return (
    <div className={datepickerClassNames}>
      <Label text={label} />
      <AntTimePicker
        className={antInputClassName}
        value={parsedValue}
        onChange={handleOnChange}
        {...rest}
      />
    </div>
  );
};

TimePicker.defaultProps = {
  format: 'HH:mm:ss',
};

export default TimePicker;
