import { AxiosPromise } from 'axios';
import { ShippingOptions } from 'models/shipment';
import { get } from './base';

const { REACT_APP_SHIPMENT_API } = process.env;

const endpoint = `${REACT_APP_SHIPMENT_API}/shipping-options/`;

const shippingOptionsApi = {
  list: (): AxiosPromise<ShippingOptions> => {
    return get(endpoint);
  },
};

export default shippingOptionsApi;
