import { useCallback, useEffect, useReducer, useMemo } from 'react';
import subOptionApi from 'api/sub-option';
import useGetList from 'hooks/base/get-list';
import notification, { NotificationType } from 'helpers/notification';
import setFormValue from './actions/set-form-value';
import setErrors from './actions/set-errors';
import onSave from './actions/handle-on-save';
import loadMore from './actions/handle-load-more';
import handleOnChange from './actions/handle-on-change';
import onDelete from './actions/handle-on-delete';
import subOptionFormReducer, { SubOptionFormActions, SubOptionFormModel } from './reducer';

export { validationRules } from './validation-rules';

export default function useSubOptionState(optionUrl, optionName) {
  const initialSubOption: SubOptionFormModel = {
    code: '',
    brand: undefined,
    name: '',
    option: optionUrl,
  };

  const [state, dispatch] = useReducer(
    subOptionFormReducer,
    {
      formData: initialSubOption,
      errors: undefined,
      subOptionSelected: undefined,
      saving: false,
      page: 1,
      subOptions: [],
    },
  );

  const { formData, page, subOptions } = state;

  const options = useMemo(() => ({
    queryParams: {
      filters: {
        option__name: optionName,
        page
      }
    }
  }), [page, optionName]);

  const {
    count,
    data,
    fetching,
    error,
  } = useGetList(subOptionApi, options);

  useEffect(() => {
    if (error) {
      notification({
        type: NotificationType.ERROR,
        message: error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (data.length > 0) {
      dispatch({
        type: SubOptionFormActions.UPDATE_DATA,
        payload: data,
      });
    }
  }, [data]);

  return ({
    state: {
      ...state,
      fetching,
      data: subOptions,
      optionUrl,
      hasMore: useMemo(() => (page * 10) < count, [page, count]),
    },
    actions: {
      setFormValue: useCallback(setFormValue(dispatch), []),
      onChange: useCallback(handleOnChange(dispatch, initialSubOption), []),
      setErrors: useCallback(setErrors(dispatch), []),
      onSave: useCallback(onSave(dispatch, formData, subOptions), [formData, subOptions]),
      handleOnDelete: useCallback(onDelete(dispatch, subOptions), [subOptions]),
      loadMore: useCallback(loadMore(dispatch), []),
    },
  });
}
