import React from 'react';
import { useHistory } from 'react-router';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Select from 'components/base-components/Select';
import RenderIf from 'components/base-components/RenderIf';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import InputRow from 'components/base-components/InputRow';
import NumberInput from 'components/base-components/NumberInput';
import Divider from 'components/base-components/Divider';
import Button from 'components/base-components/Button';
import { addressRules } from '../../ConvertToOrder/state';
import { flatRateRules } from './state/validations';
import { AccountAddress } from './state/reducer';
import useUpdateShipping from './state';
import './style.scss';

export interface UpdateShippingHistorySateModel {
  updateShippingForm: {
    shipmentId: number;
    accountAddress: AccountAddress;
  };
}
export default function UpdateShipping() {
  const { goBack, location: { state } } = useHistory();
  const {
    updateShippingForm: {
      shipmentId,
      accountAddress,
    },
  } = state as UpdateShippingHistorySateModel;
  const {
    state: {
      shipment,
      address,
      flatRate,
      fetchingShipmentData,
      updating,
      selectedCountry,
      shipmentTypes,
      provinces,
      shippingZones,
      countries,
      addressErrors,
      flatRateErrors,
    },
    actions: {
      handleShipmentChange,
      handleAddressChange,
      handleCountryChange,
      setAddressErrors,
      setFlatRate,
      handleOnSubmit,
      setFlatRateErrors,
      copyAddressFromAccount,
    },
  } = useUpdateShipping(shipmentId, accountAddress);

  return (
    <Modal
      title="Update Shipping"
      visible
      okText="Submit"
      onCancel={goBack}
      onOk={handleOnSubmit}
      confirmLoading={updating}
    >
      <RenderIf isTrue={!fetchingShipmentData} fallback={<Spinner centered />}>

        <Form state={shipment} onChange={handleShipmentChange}>
          <Field
            component={Select}
            name="shipmentType"
            id="shipmentType"
            label="Shipment Type"
            options={shipmentTypes}
          />
          <RenderIf isTrue={shipment?.shipmentType?.dropdownItem?.ui === 'delivery'}>
            <Field
              component={Select}
              name="shippingZone"
              id="shippingZone"
              label="Shipping Zone"
              options={shippingZones}
            />
          </RenderIf>
        </Form>
        <RenderIf isTrue={shipment.shipmentType?.dropdownItem?.ui === 'flat_rate'}>
          <Form
            state={flatRate}
            onChange={setFlatRate}
            errors={flatRateErrors}
            onError={setFlatRateErrors}
            rules={flatRateRules}
          >
            <Field
              type="number"
              name="price"
              label="Price"
              step={0.01}
              required
            />
          </Form>
        </RenderIf>
        <RenderIf isTrue={shipment?.shipmentType?.dropdownItem?.ui !== 'pickup'}>
          <div className="copy-from-account__div__wrap">
            <div className="divider-address">
              <Divider orientation="left" plain>Address</Divider>
            </div>
            <Button
              className="margin-button"
              onClick={copyAddressFromAccount}
              type="default"
            >
              Copy from Account
            </Button>
          </div>
          <Form
            state={address}
            onChange={handleAddressChange}
            errors={addressErrors}
            onError={setAddressErrors}
            rules={addressRules}
          >
            <Field name="locationName" label="Company" />
            <InputRow>
              <Field
                component={NumberInput}
                name="streetNumber"
                label="Street Number"
                className="questionnaire__address__street-number"
              />
              <Field name="streetName" label="Street Name" />
            </InputRow>
            <Field name="unitNumber" label="Unit" />
            <InputRow>
              <Field name="city" label="City" mR />
              <Field name="postalCode" label="Post Code" />
            </InputRow>
            <Select
              id="country"
              name="country"
              label="Country"
              options={countries}
              value={selectedCountry}
              onChange={handleCountryChange}
            />
            <Field
              name="province"
              label="Province"
              component={Select}
              options={provinces}
            />
          </Form>
        </RenderIf>
      </RenderIf>
    </Modal>
  );
}
