import Amplify from '@aws-amplify/core';

export default function initAWSCognito() {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_COGNITO_AWS_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_AUDIENCE
    }
  });
}
