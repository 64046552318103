import { CalendarEventModel } from 'models/calendar-event';
import { ApiContentType, ApiModel } from 'api/base';
import getApi from 'api/base/get-api';
import axios, { AxiosResponse } from 'axios';

const { REACT_APP_ORDERS_API } = process.env;
const endpoint = `${REACT_APP_ORDERS_API}/calendar_event/`;

interface CalendarEventApiModel extends ApiModel<CalendarEventModel> {
  getOptions: () => Promise<AxiosResponse | void>;
}

const calendarEventApi: CalendarEventApiModel = {
  ...getApi<CalendarEventModel>(endpoint, ApiContentType.JSON, true),
  getOptions() {
    return axios.options(endpoint);
  }
};

export default calendarEventApi;
