import { InstallerFormState } from '../reducer';

export default function getState({ installers, initialInstaller }) {
  return {
    installerSelected: undefined,
    installersBooking: installers,
    errors: undefined,
    saving: undefined,
    formData: initialInstaller
  } as InstallerFormState;
}
