import { resolveApiErrorMessage } from 'api/base';
import orderProductApi from 'api/order-product';
import orderOptionsApi from 'api/order-option';
import { EventChannelList, notifyEventChannel } from 'helpers/event-center';
import notification, { NotificationType } from 'helpers/notification';
import { validateEntity } from 'helpers/form-validations';
import { getOrderPayload, getOptionPayload } from './get-payload';
import { AddProductFormActions, AddProductFormModel } from '../reducer';
import { validationRules } from '../validation-rules';

async function saveProduct(data) {
  const orderPayload = getOrderPayload(data);
  const order = await orderProductApi.create(orderPayload);
  // eslint-disable-next-line no-restricted-syntax
  for (const option of orderPayload.productOptions) {
    if (orderPayload[option.name]?.value) {
      const optionPayload = getOptionPayload(
        orderPayload[option.name],
        option.name,
        order.data.id,
        orderPayload[`sub-${option.name}`],
      );
      // eslint-disable-next-line no-await-in-loop
      await orderOptionsApi.create(optionPayload);
    }
  }
}

export function addProduct(
  dispatch,
  formData: AddProductFormModel,
  quote: number,
  goBack,
  priceData,
) {
  return () => {
    dispatch({ type: AddProductFormActions.START_ADDING });

    const { hasErrors, errors } = validateEntity(formData, validationRules);

    if (hasErrors) {
      return dispatch({
        type: AddProductFormActions.SET_VALIDATION_ERRORS,
        payload: errors,
      });
    }

    const onSuccess = () => {
      notifyEventChannel(EventChannelList.QUOTES_LIST_CHANGED, true);
      const message = 'Products Successfully added';
      notification({ type: NotificationType.SUCCESS, message });
      goBack();
    };

    const onError = (error) => {
      const message = resolveApiErrorMessage((error as any).response);
      notification({ type: NotificationType.ERROR, message });
    };

    const onFinally = () => {
      dispatch({ type: AddProductFormActions.FINISH_ADDING });
    };

    const data = { ...formData, quote };

    Object.keys(priceData?.options || []).forEach(e => {
      data[e] = {
        ...data[e],
        dropdownItem: {
          ...data[e]?.dropdownItem,
          price: priceData.options[e]?.price || data[e]?.price || 0
        }
      };
    });

    return saveProduct(data)
      .then(onSuccess)
      .catch(onError)
      .finally(onFinally);
  };
}
