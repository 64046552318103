import { DeficiencyItemModel } from 'models/order';
import { DeficiencyItemFormModel } from '..';

export default function getFormData(
  deficiencyItem: DeficiencyItemModel,
  orderProductId: number
): DeficiencyItemFormModel {
  if (deficiencyItem) {
    const { comment, corrected, quantity, id } = deficiencyItem;
    return {
      id,
      orderProduct: orderProductId,
      comment,
      corrected,
      quantity,
    };
  }

  return {
    comment: undefined,
    quantity: undefined,
    corrected: false,
    orderProduct: orderProductId,
  };
}
