import { useCallback, useEffect, useReducer, useMemo } from 'react';
import { useHistory } from 'react-router';
import optionGroupsApi from 'api/option-group';
import { resolveApiErrorMessage } from 'api/base';
import useGetList from 'hooks/base/get-list';
import notification, { NotificationType } from 'helpers/notification';
import { DropdownOptionModel } from 'helpers/dropdown-options';
import { EventChannelList, useEventCenterUpdate } from 'helpers/event-center';
import editOptionsFormReducer from './reducer';
import {
  getState,
  setFormValue,
  completeState,
  editOptions,
  openCustomOptionModal,
  handleNewCustomOption
} from './actions';

export interface EditOptionsFromModel {
  [x: string]: DropdownOptionModel;
}

export default function useEditOptionsState(product, discount?) {
  const { goBack } = useHistory();
  const [state, dispatch] = useReducer(
    editOptionsFormReducer,
    {
      formData: getState(product),
      errors: {},
      editing: false,
    },
  );
  const { formData } = state;
  const handleCustomOption = useCallback(handleNewCustomOption(dispatch), []);
  const queryParams = useMemo(() => product.category === 'custom' ? {} : { filters: { category: product.category } }, [product]);
  const {
    fetching: fetchingOptionGroups,
    data: productOptions,
    error: optionGroupsError,
  } = useGetList(optionGroupsApi, { queryParams });

  useEffect(() => {
    if (optionGroupsError) {
      notification({
        type: NotificationType.ERROR,
        message: resolveApiErrorMessage(optionGroupsError),
      });
    }
  }, [optionGroupsError]);

  useEffect(() => {
    if (productOptions) {
      completeState(dispatch, formData, productOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOptions]);

  useEventCenterUpdate(
    EventChannelList.QUOTE_PAGE_NEW_CUSTOM_OPTION,
    handleCustomOption,
  );

  return {
    state: {
      ...state,
      productOptions,
      fetchingOptionGroups,
    },
    actions: {
      openCustomOptionModal,
      handleValueChange: useCallback(setFormValue(dispatch), []),
      handleOnSubmit: useCallback(
        editOptions(dispatch, formData, product, productOptions, goBack, discount),
        [formData, product, productOptions, discount],
      ),
    },
  };
}
