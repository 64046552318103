import { CalendarEventModel } from 'models/calendar-event';
import { CalendarEvent, EventItemType } from '../reducer';

export default function buildCustomEventSchedule(
  items: CalendarEventModel[],
): CalendarEvent[] {
  return items.map((item) => {
    const {
      id,
      eventType,
      title,
      scheduled
    } = item;

    return {
      id: `custom-event-${id.toString(10)}`,
      title: `${title} - ${eventType}`,
      start: new Date(scheduled),
      extendedProps: {
        type: EventItemType.CUSTOM,
        item,
      },
    } as CalendarEvent;
  });
}
