import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import { AddProductFormActions } from '../reducer';

const optionMapDropdown = { fields: { value: 'name', label: 'name' }, copyFullItem: true };
const noneOption = { value: null, label: 'None' };

export function prepareOptionsGroups(dispatch, productOptions) {
  const formOptions = {};
  productOptions.forEach(productOption => {
    const defaultOptionName = productOption.defaultOption?.name;
    const defaultOption = { value: defaultOptionName || null, label: defaultOptionName || 'None', dropdownItem: productOption.defaultOption };
    formOptions[productOption.name] = productOption.showDefault ? defaultOption : noneOption;
  });
  const productOptionsSelect = productOptions.map(item => ({
    name: item.name,
    options: mapGenericDropdownOptions(item.option, optionMapDropdown)
  }));
  dispatch({
    type: AddProductFormActions.SET_OPTIONS,
    payload: { ...formOptions, productOptions: productOptionsSelect }
  });
}
