import { QuoteModel } from 'models/quote';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import { CopyQuoteHistorySateModel } from '../../Form/CopyQuote';

export default function openCopyQuoteModal(push, quote: QuoteModel) {
  return () => {
    const historyLocationState: CopyQuoteHistorySateModel = {
      copyQuoteForm: {
        quoteId: quote.id,
      },
    };
    push(ModalRouteHash.CopyQuote, historyLocationState);
  };
}
