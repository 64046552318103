import { PurchaseOrderDTO } from 'models/order';
import { ManufacturerModel } from 'models/manufacturer';
import { PurchaseOrderListModel } from '..';

export default function getData(
  purchaseOrders: PurchaseOrderDTO[],
  manufacturers: ManufacturerModel[]
): PurchaseOrderListModel[] {
  return purchaseOrders.map((purchaseOrder) => {
    const { manufacturer: poManufacturer, ...rest } = purchaseOrder;
    const manufacturer = manufacturers.find((m) => m.companyName === poManufacturer);

    return { ...rest, manufacturer };
  });
}
