import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { InstallerBookingModel } from 'models/installer-booking';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import Help from 'components/forms/CommonHelp';
import { ModalRouteHash } from 'components/base-components/ModalRouter/modal-route-hash';
import Page, { PageActions } from 'components/base-components/Page';
import AccessControl from 'components/base-components/AccessControl';
import { InstallerBookingFormHistorySateModel } from '../Form';
import { permissions } from './permissions';
import useInstallerBookingState from './state';

const InstallerBookingList: FunctionComponent = () => {
  const { push } = useHistory();
  const {
    state: {
      data,
      fetching,
      count,
      page,
    },
    actions: {
      handleDeleteRow,
      handleOnChange,
      handleFilter,
    }
  } = useInstallerBookingState();

  const openInstallerBookingFormModal = useCallback((installerBooking?: InstallerBookingModel | any) => {
    const historyLocationState: InstallerBookingFormHistorySateModel = {
      installerBookingForm: {
        installerBookingEntity: !!installerBooking.id ? installerBooking : undefined,
      },
    };
    push(ModalRouteHash.InstallerBookingForm, historyLocationState);
  }, [push]);

  return (
    <Page title="Installers Booking">
      <Help view="installer-booking" title="Installers Booking List" />
      <PageActions primary>
        <AccessControl permissions={permissions.addInstallerBooking}>
          <Button
            onClick={openInstallerBookingFormModal}
            label="Add Installer Booking"
          />
        </AccessControl>
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn
          dataIndex={['installer', 'companyName']}
          columnKey="companyName"
          title="Installer"
          sorter
        />
        <DataTableColumn
          dataIndex="installationDate"
          columnKey="installation_date"
          title="Installation Date"
          isDate
          sorter
        />
        <DataTableColumn
          dataIndex="jobTitle"
          columnKey="jobTitle"
          title="Job Title"
        />
        <DataTableColumn
          isActionColumn
          deletePermissions={permissions.deleteInstallerBooking}
          editPermissions={permissions.editInstallerBooking}
          onEdit={openInstallerBookingFormModal}
          onDelete={handleDeleteRow}
        />
      </DataTable>
    </Page>
  );
};

export default InstallerBookingList;
