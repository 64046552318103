import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import Tag from 'components/base-components/Tag';
import { AccountApplicationModel } from 'models/account-application';

interface Props {
  accountApplication: AccountApplicationModel;
}

enum statusColors {
  pending = 'warning',
  approved = 'success',
  denied = 'error',
}

const Details: FunctionComponent<Props> = (props) => {
  const { accountApplication } = props;

  if (accountApplication) {
    const {
      ref1BusinessName,
      ref1ContactEmail,
      ref1ContactPhone,
      ref2BusinessName,
      ref2ContactEmail,
      ref2ContactPhone,
      ref3BusinessName,
      ref3ContactEmail,
      ref3ContactPhone,
      status,
      taxExempt,
      ...rest
    } = accountApplication;

    const taxExemptFileName = taxExempt ? taxExempt.split('/').pop() : '-';

    const restEntries = Object.entries(rest);

    const restData = restEntries.reduce((entries, element) => {
      if (element[0] !== 'businessTradeName' && element[0] !== 'url') {
        const fieldKey = element[0]
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (str) => str.toUpperCase());

        const filedValue = element[1] || '-';

        return entries.concat((
          <div
            key={`account-application-detail-${element[0]}`}
            className="mt-2"
          >
            <span className="mr-1">
              {fieldKey}
              :
            </span>
            <span>{filedValue}</span>
          </div>
        ));
      }
      return entries;
    }, []);

    return (
      <div>
        <div className="d-flex">
          <span className="mr-1 d-flex">Status:</span>
          <Tag color={statusColors[status]}>
            {status}
          </Tag>
        </div>
        {restData}
        <div className="mt-2">
          <span className="mr-1">Tax Exempt:</span>
          <a href={taxExempt} rel="noopener noreferrer" target="_blank">{taxExemptFileName}</a>
        </div>
        <div className="border rounded-top bg-light p-2 mt-2">
          References
        </div>
        <div
          className="account-app-references-wrapper border border-light rounded-bottom p-1 mb-4"
        >
          <Row form className="border-bottom mb-1">
            <Col>Name</Col>
            <Col>Email</Col>
            <Col>Phone</Col>
          </Row>
          <Row form className="border-bottom mb-1 pb-1">
            <Col>
              <span>{ref1BusinessName}</span>
            </Col>
            <Col>
              <span>{ref1ContactEmail}</span>
            </Col>
            <Col>
              <span>{ref1ContactPhone}</span>
            </Col>
          </Row>
          <Row form className="border-bottom mb-1 pb-1">
            <Col>
              <span>{ref2BusinessName}</span>
            </Col>
            <Col>
              <span>{ref2ContactEmail}</span>
            </Col>
            <Col>
              <span>{ref2ContactPhone}</span>
            </Col>
          </Row>
          <Row form>
            <Col>
              <span>{ref3BusinessName}</span>
            </Col>
            <Col>
              <span>{ref3ContactEmail}</span>
            </Col>
            <Col>
              <span>{ref3ContactPhone}</span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return null;
};

export default Details;
