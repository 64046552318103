import { TutorialFormActions } from '../reducer';

export default function setTutorial(dispatch) {
  return (model) => {
    dispatch({
      type: TutorialFormActions.SET_TUTORIAL,
      payload: model
    });
  };
}
