export enum TutorialFormActions {
  SET_TUTORIAL = 'SET_TUTORIAL',
  SET_ERRORS = 'SET_ERRORS',
  START_SAVING_TUTORIAL = 'START_SAVING_TUTORIAL',
  FINISH_SAVING_TUTORIAL = 'FINISH_SAVING_TUTORIAL',
}

export interface TutorialFormModel {
  id?: number;
  url?: string;
  video: File[];
  videoName: string;
  videoChanged?: boolean;
}

export interface TutorialFormState {
  tutorial: TutorialFormModel;
  fetchingFormData: boolean;
  savingTutorial: boolean;
  errors: any;
}

export default function tutorialFormReducer(
  state: TutorialFormState,
  action,
): TutorialFormState {
  const { type, payload } = action;

  switch (type as TutorialFormActions) {
    case TutorialFormActions.SET_TUTORIAL:
      return { ...state, tutorial: payload, fetchingFormData: false };
    case TutorialFormActions.SET_ERRORS:
      return { ...state, errors: payload, savingTutorial: false };
    case TutorialFormActions.START_SAVING_TUTORIAL:
      return { ...state, savingTutorial: true };
    case TutorialFormActions.FINISH_SAVING_TUTORIAL:
      return { ...state, savingTutorial: false };
    default:
      return state;
  }
}
