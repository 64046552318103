import React from 'react';
import RenderIf from 'components/base-components/RenderIf';

export default function mpnTemplate(row) {
  const options = row?.orderProduct?.options?.map((option) => (
    <li key={`${option.optionType}-${row.id}`}>
      {`${option.optionType}: ${option.optionSelected} - ${option.code}`}
    </li>
  ));

  return (
    <>
      <span className="text-muted">{row.mpn}</span>
      <RenderIf isTrue={!!options}>
        <ul>
          {options}
        </ul>
      </RenderIf>
    </>
  );
}
