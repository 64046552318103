import { notification as antdNotification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

export enum NotificationType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

interface Props extends ArgsProps{
  type: NotificationType;
}

export default function notification(props: Props) {
  const { type, ...rest } = props;
  antdNotification[type]({ ...rest, style: { zIndex: 1111 } });
}
