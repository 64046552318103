import { AccountApplicationModel } from 'models/account-application';

export enum AccountApplicationTableActions {
  HANDLE_ON_CHANGE = 'HANDLE_ON_CHANGE',
  HANDLE_FILTER_CHANGE = 'HANDLE_FILTER_CHANGE',
  HANDLE_ACCOUNT_APPROVAL = 'HANDLE_ACCOUNT_APPROVAL',
  START_SAVING = 'START_SAVING',
  FINISH_SAVING = 'FINISH_SAVING',
}

export interface AccountApplicationTableStateModel {
  page: number;
  filter: any;
  sortField: string;
  accountApplicationChanged: AccountApplicationModel;
  saving: boolean;
}

export default function tableReducer(
  state: AccountApplicationTableStateModel,
  action,
): AccountApplicationTableStateModel {
  const { type: actionType, payload } = action;
  switch (actionType as AccountApplicationTableActions) {
    case AccountApplicationTableActions.HANDLE_FILTER_CHANGE:
      return { ...state, page: 1, filter: payload };
    case AccountApplicationTableActions.HANDLE_ACCOUNT_APPROVAL:
      return {
        ...state,
        accountApplicationChanged: payload,
      };
    case AccountApplicationTableActions.HANDLE_ON_CHANGE:
      return { ...state, ...payload };
    case AccountApplicationTableActions.START_SAVING:
      return { ...state, saving: true };
    case AccountApplicationTableActions.FINISH_SAVING:
      return { ...state, saving: false };
    default:
      return state;
  }
}
