import React, { FunctionComponent, useCallback } from 'react';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Page, { PageActions } from 'components/base-components/Page';
import DataTableAction from 'components/base-components/DataTableV2/Column/Action';
import Button from 'components/base-components/Button';
import { permissions } from './permissions';
import useQBOSyncList from './state';
import resend from './resend-action';

const labelRender = (labels) => {
  return (value) => labels[value];
};

const QBOSyncList: FunctionComponent = () => {
  const {
    state: {
      data,
      fetching,
      count,
      page,
      tokenTypes,
      statusFilters,
      actionsFilters,
      labels: { actionLabels, statusLabels },
    },
    actions: {
      handleOnChange,
      handleFilter,
      reFetch
    }
  } = useQBOSyncList();
  const actionsRender = useCallback(labelRender(actionLabels), [actionLabels]);
  const statusRender = useCallback(labelRender(statusLabels), [statusLabels]);

  return (
    <Page title="QBO Sync">
      <PageActions primary>
        <Button
          onClick={reFetch}
          label="Update Data"
        />
      </PageActions>
      <DataTable
        page={page}
        totalRecords={count}
        loading={fetching}
        dataSource={data}
        onChange={handleOnChange}
        onFilter={handleFilter}
        rowKey="id"
      >
        <DataTableColumn dataIndex="id" columnKey="id" title="Id" sorter />
        <DataTableColumn dataIndex="objectId" columnKey="object_id" title="Object" sorter />
        <DataTableColumn dataIndex="type" columnKey="type" title="Type" sorter filters={tokenTypes} />
        <DataTableColumn dataIndex="status" columnKey="status" title="Status" sorter render={statusRender} filters={statusFilters} />
        <DataTableColumn dataIndex="action" columnKey="action" title="Action" sorter render={actionsRender} filters={actionsFilters} />
        <DataTableColumn dataIndex="note" columnKey="note" title="Note" trimAt={150} />
        <DataTableColumn isActionColumn>
          <DataTableAction
            permissions={permissions.editQBOSync}
            onClick={resend}
            icon="fa fa-paper-plane"
            label="Resend"
          />
        </DataTableColumn>
      </DataTable>
    </Page>
  );
};

export default QBOSyncList;
