import React from 'react';
import { formatCurrency } from 'helpers/formatting';
import { Text, Title } from 'components/base-components/Typography';
import isEdgeRow from './is-edge-row';

export default function totalTemplate(row) {
  const { type, total, percent } = row;

  if (isEdgeRow(type)) {
    return {
      children: (
        <span className="sales-report__table__group__header_total">
          <Text>{`${percent}%`}</Text>
          <Title level={5} className="sales-report__table__group__header">
            {`${formatCurrency(total)}`}
          </Title>
        </span>
      ),
      props: { colSpan: 1, align: 'left' },
    };
  }

  return {
    children: formatCurrency(total),
    props: { align: 'right' },
  };
}
