import { mapTreeSelectOptionSimple, treeSelectOption } from 'helpers/dropdown-options';
import AddOptionRender from '../../AddCustomOption';
import { initialOrder } from '../reducer';

const makeTreeSelectOptions = (optionGroups, row) => {
  const options = [];
  optionGroups.forEach(optionsGroup => {
    options.push(treeSelectOption(optionsGroup.name, false, 0, false, false));
    options.push({
      id: `custom-${optionsGroup.name}`,
      value: undefined,
      selectable: false,
      isLeaf: true,
      pId: optionsGroup.name,
      label: AddOptionRender(optionsGroup.name, row)
    });
    options.push(treeSelectOption('TBD', true, optionsGroup.name, true));
    options.push(...mapTreeSelectOptionSimple(optionsGroup.option, optionsGroup.name, true, true, true));
  });
  return options;
};

export async function buildRow(
  { group: groupForRow, product, optionGroups, ...row }, optionsGroupList, quote, discount = 0
) {
  const group = typeof groupForRow === 'string' ? { value: groupForRow, label: groupForRow } : groupForRow;
  const parseObject = {} as {model: string};
  if (row.productCode) {
    parseObject.model = row.productCode;
  }
  let options = optionGroups || optionsGroupList;
  if (product && !optionGroups) {
    options = product?.category?.optionGrp;
  }
  const productOptions = makeTreeSelectOptions(options, row.key);

  if (product) {
    const totalPrice = product.price - (product.price * (discount / 100));

    return {
      ...row,
      model: product.model,
      category: product.category.name,
      name: product.name,
      manufacturer: { value: product.manufacturer.companyName, label: product.manufacturer.companyName },
      cnc: product.category.cnc,
      outsidePaint: product.category.outsidePaint,
      price: (totalPrice)?.toFixed(2) || '0.00',
      recommendedPrice: totalPrice || '0.00',
      cost: product?.cost?.toFixed(2) || '0.00',
      mpn: product.mpn,
      productOptions,
      disabled: true,
      product,
      quote,
      group
    };
  }
  if (row?.category !== 'custom' && !row?.productCode) {
    return {
      ...row,
      product: {},
      quote,
      recommendedPrice: row.price || '0.00',
      price: row.price || '0.00',
      cost: row.cost || '0.00',
      category: 'custom',
      description: '{"ul_list": [], "paragraph": ""}',
      disabled: false,
      group,
      ...parseObject,
      ...initialOrder,
      key: row.key,
      model: row.model,
      productOptions,
    };
  }

  return {
    ...row,
    product: {},
    quote,
    productOptions,
    recommendedPrice: row.price || '0.00',
    price: row.price || '0.00',
    cost: row.cost || '0.00',
    category: 'custom',
    description: '{"ul_list": [], "paragraph": ""}',
    disabled: false,
    group,
    ...parseObject,
    productCode: undefined,
  };
}
