import React, { FunctionComponent } from 'react';
import { Col, Row } from 'antd';
import { ProductModel } from 'models/product';
import productApi from 'api/product';
import { mapGenericDropdownOptions } from 'helpers/dropdown-options';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import DataTable from 'components/base-components/DataTableV2';
import DataTableColumn from 'components/base-components/DataTableV2/Column';
import Button from 'components/base-components/Button';
import NumberInput from 'components/base-components/NumberInput';
import AsyncAutoComplete from 'components/base-components/AsyncAutoComplete';
import useManualCalcFormState, { validationRules } from './state';
import './styles.scss';

function mapProducts(products: ProductModel[]) {
  return mapGenericDropdownOptions(
    products,
    { fields: { label: 'model', value: 'url' } },
  );
}

const CNCManualCalculatorFormAsPage: FunctionComponent = () => {
  const {
    state: {
      product,
      productList,
      errors,
      calculating,
    },
    actions: {
      setProduct,
      addProduct,
      deleteProduct,
      setErrors,
      calculateWithoutGoBack,
    }
  } = useManualCalcFormState();

  return (
    <section className="cnc-calc__as-page">
      <Form
        state={product}
        onChange={setProduct}
        errors={errors}
        onError={setErrors}
        rules={validationRules}
      >
        <Row>
          <Col span={12}>
            <Field
              component={AsyncAutoComplete}
              name="model"
              id="model"
              label="Model"
              listAction={productApi.list}
              mapFunction={mapProducts}
              allowIncompleteValue
            />
          </Col>
          <Col span={12}>
            <Field
              name="quantity"
              id="quantity"
              label="Quantity"
              component={NumberInput}
            />
          </Col>
        </Row>
        <div className="cnc-calc__actions">
          <Button label="Add" onClick={addProduct} id="addProduct" mR />
          <Button
            label="Calculate"
            onClick={calculateWithoutGoBack}
            loading={calculating}
            disabled={productList.length === 0}
          />
        </div>
      </Form>
      <DataTable dataSource={productList} pagination={false}>
        <DataTableColumn key="model" dataIndex={['model', 'label']} title="Model" />
        <DataTableColumn key="quantity" dataIndex="quantity" title="Quantity" />
        <DataTableColumn isActionColumn onDelete={deleteProduct} />
      </DataTable>
    </section>
  );
};

export default CNCManualCalculatorFormAsPage;
