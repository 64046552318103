import { useMemo } from 'react';
import useMediaQueries from 'hooks/ui/use-media-queries';

const dimensions = {
  '(min-width: 1300px)': '80vw',
  '(min-width: 769px)': '90vw',
  '(min-width: 0px)': '100vw',
};

const queries = Object.keys(dimensions);

export default function useResponsiveWidth() {
  const results = useMediaQueries(queries);

  return useMemo(() => {
    const activeQuery = Object.keys(results).find((query) => results[query]);
    return dimensions[activeQuery];
  }, [results]);
}
