import { AxiosResponse } from 'axios';
import { BuilderCategory } from 'models/builder';
import { ApiContentType, get, ApiModel } from './base';
import getApi from './base/get-api';

const { REACT_APP_PRODUCTS_API } = process.env;
const endpoint = `${REACT_APP_PRODUCTS_API}/builder-category`;

interface BuilderApiModel extends ApiModel<BuilderCategory>{
  getTopLevel: () => Promise<AxiosResponse<BuilderCategory>>;
}

const builderCategoriesApi: BuilderApiModel = {
  ...getApi<BuilderCategory>(endpoint, ApiContentType.JSON, true),
  getTopLevel() {
    return get(`${endpoint}/toplevel/`);
  },
};

export default builderCategoriesApi;
