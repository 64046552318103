import { AppPermissions } from 'constants/permissions';

export const modalPermissions = {
  copyQuote: [AppPermissions.add_quotes],
  user: [AppPermissions.add_user, AppPermissions.change_user],
  account: [AppPermissions.add_account, AppPermissions.change_account],
  terms: [AppPermissions.add_terms, AppPermissions.change_terms],
  product: [AppPermissions.add_product, AppPermissions.change_product],
  productDetails: [AppPermissions.view_product],
  options: [AppPermissions.add_options, AppPermissions.change_options],
  optionGroup: [AppPermissions.add_optiongroup, AppPermissions.change_optiongroup],
  manufacturer: [AppPermissions.add_manufacturer, AppPermissions.change_manufacturer],
  category: [AppPermissions.add_category, AppPermissions.change_category],
  accountGroup: [AppPermissions.add_accountgroup, AppPermissions.change_accountgroup],
  accountApplicationDetails: [AppPermissions.view_accountapplication],
  deficiencyOrder: [AppPermissions.add_orders, AppPermissions.change_orders],
  optionsDetails: [AppPermissions.view_options],
  isSuperuser: [AppPermissions.frontend_is_superuper],
  userGroup: [AppPermissions.add_group, AppPermissions.change_group],
  quickAccount: [AppPermissions.add_account, AppPermissions.add_user],
  editQuote: [AppPermissions.change_quotes, AppPermissions.add_orderproducts, AppPermissions.change_orderproducts],
  purchaseOrder: [AppPermissions.add_purchaseorder, AppPermissions.change_purchaseorder],
  pOItems: [AppPermissions.add_poitem, AppPermissions.change_poitem],
  project: [AppPermissions.add_projects],
  shipping: [
    AppPermissions.add_shipment, AppPermissions.add_shippingaddress,
    AppPermissions.change_shippingaddress, AppPermissions.change_shipment,
  ],
  receivePayment: [AppPermissions.change_payment, AppPermissions.add_payment],
  threeModel: [AppPermissions.add_threemodel, AppPermissions.change_threemodel],
  store: [AppPermissions.add_store, AppPermissions.change_store],
  shippingZone: [AppPermissions.add_shippingzones, AppPermissions.change_shippingzones],
  productionManager: [AppPermissions.view_orders, AppPermissions.change_orders],
  installer: [AppPermissions.add_installer, AppPermissions.change_installer],
  installerBooking: [AppPermissions.add_installerbooking, AppPermissions.change_installerbooking],
  part: [AppPermissions.add_parts, AppPermissions.change_parts],
  viewPart: [AppPermissions.view_parts],
  propertySetting: [AppPermissions.add_propertysetting, AppPermissions.change_propertysetting],
  inventory: [AppPermissions.add_inventory, AppPermissions.change_inventory],
  cutList: [AppPermissions.add_cutlist, AppPermissions.change_cutlist],
};
