import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RenderIf from 'components/base-components/RenderIf';
import Form from 'components/base-components/Form';
import Field from 'components/base-components/Form/Field';
import Modal from 'components/base-components/ModalV2';
import Spinner from 'components/base-components/Spinner';
import Checkbox from 'components/base-components/Checkbox';
import Upload from 'components/base-components/Upload';
import AsyncSelectV2 from 'components/base-components/AsyncSelectV2';
import categoryApi from 'api/category';
import manufacturerApi from 'api/manufacturer';
import validationRules from './state/validations';
import useProductFormState, { mapCategoriesOptions, mapManufacturersOptions } from './state';
import { ProductHistorySateModel } from '../List';

const ProductForm: FunctionComponent = () => {
  const { goBack, location: { state } } = useHistory();

  const { product: { productUrl } } = state as ProductHistorySateModel;

  const {
    state: {
      product,
      savingProduct,
      fetchingFormData,
      errors,
    },
    actions: {
      setFormValue,
      saveProduct,
      setErrors,
    },
  } = useProductFormState(productUrl);

  const modalTitle = useMemo(() => productUrl ? 'Edit Product' : 'Create Product', [productUrl]);

  return (
    <Modal
      title={modalTitle}
      visible
      onCancel={goBack}
      okText="Submit"
      onOk={saveProduct}
      confirmLoading={savingProduct}
    >
      <RenderIf isTrue={!fetchingFormData} fallback={<Spinner />}>
        <Form
          state={product}
          onChange={setFormValue}
          errors={errors}
          onError={setErrors}
          rules={validationRules}
        >
          <Field
            type="text"
            id="name"
            name="name"
            label="Name"
            placeholder="Enter Product name"
          />
          <Field
            type="text"
            id="nameFr"
            name="nameFr"
            label="Name fr"
            placeholder="Enter Product fr name"
          />
          <Field
            type="text"
            id="model"
            name="model"
            label="Model"
            placeholder="Enter Product model"
            disabled={product.url}
          />
          <Field
            type="text"
            id="mpn"
            name="mpn"
            label="Mpn"
            placeholder="Enter Product mpn"
          />
          <Field
            type="number"
            id="price"
            name="price"
            label="Price"
            placeholder="Enter Product price"
          />
          <Field
            type="number"
            id="cost"
            name="cost"
            label="Cost"
            placeholder="Enter Product cost"
          />
          <Field
            type="number"
            id="weight"
            name="weight"
            label="Weight"
            placeholder="Enter Product weight"
          />
          <Field
            type="text"
            id="description"
            name="description"
            label="Description"
            placeholder="Enter description"
          />
          <Field
            type="text"
            id="descriptionFr"
            name="descriptionFr"
            label="Description fr"
            placeholder="Enter description fr"
          />
          <Field id="status" component={Checkbox} name="status" label="Status" />
          <Field
            component={AsyncSelectV2}
            id="category"
            name="category"
            label="Category"
            listAction={categoryApi.list}
            mapFunction={mapCategoriesOptions}
          />
          <Field
            component={AsyncSelectV2}
            id="manufacturer"
            name="manufacturer"
            label="Manufacturer"
            listAction={manufacturerApi.list}
            mapFunction={mapManufacturersOptions}
          />
          <Field
            component={Upload}
            name="image"
            id="image"
            label="Choose Image"
            accept="image/png, .jpeg, .jpg"
          />
        </Form>
      </RenderIf>
    </Modal>
  );
};

export default ProductForm;
