import { ApiContentType, ApiModel, del, get, patch, post, put } from 'api/base';

export default function getApi<T = any>(
  endpoint: string,
  apiContentType?: ApiContentType,
  byId = false as boolean | string,
): ApiModel<T> {
  return {
    get(param) {
      return get(byId ? `${endpoint}${param}/` : param);
    },
    list(params?) {
      return get(endpoint, params);
    },
    create(entity) {
      return post(endpoint, entity, apiContentType);
    },
    update(entity) {
      const pK = typeof byId === 'string' ? byId : 'id';
      return put(
        byId ? `${endpoint}${entity[pK]}/` : entity.url,
        entity,
        apiContentType,
      );
    },
    patch(entity) {
      const pK = typeof byId === 'string' ? byId : 'id';
      const url = byId ? `${endpoint}${entity[pK]}/` : entity.url;
      return patch(url, entity, apiContentType);
    },
    delete(param) {
      return del(byId ? `${endpoint}${param}/` : param);
    }
  };
}
